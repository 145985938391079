<ng-select [(ngModel)]="id"
           [items]="filteredItems"
           [virtualScroll]="true"
           [loading]="loading"
           bindLabel="name"
           bindValue="id"
           placeholder="Купонное правило"
           name="selectCouponRuleComponent"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (search) = "onSearch($event)"
           (change) = "onChange()"
           (clear) = "clear()"
           (close) = "clear()"
           [disabled]="disabled"
           [required]="required"
           [class.ng-invalid]="!isValid"

>

  <ng-template ng-label-tmp let-item="item">

    <div class="hbox hbox-auto">

      <div class="col">

       <span [ngSwitch]="item.state">
             <span *ngSwitchCase="'draft'">      <i
               class="fa fa-barcode text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'suspended'">  <i
               class="fa fa-barcode text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'unsuspend'">  <i
               class="fa fa-barcode text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'planned'">    <i
               class="fa fa-barcode text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'started'">    <i
               class="fa fa-barcode text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'active'">     <i
               class="fa fa-barcode text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'completed'">  <i
               class="fa fa-barcode text-info m-r-xs"></i>     </span>
             <span *ngSwitchCase="'archive'">    <i
               class="fa fa-barcode text-danger m-r-xs"></i>   </span>
        </span>

      </div>

      <div class="col w-full">
        <div class="clearfix">
          {{ item.name }}
        </div>



      </div>

    </div>

  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index">

    <div class="hbox hbox-auto">

      <div class="col">

       <span [ngSwitch]="item.state">
             <span *ngSwitchCase="'draft'">      <i
               class="fa fa-barcode text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'suspended'">  <i
               class="fa fa-barcode text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'unsuspend'">  <i
               class="fa fa-barcode text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'planned'">    <i
               class="fa fa-barcode text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'started'">    <i
               class="fa fa-barcode text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'active'">     <i
               class="fa fa-barcode text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'completed'">  <i
               class="fa fa-barcode text-info m-r-xs"></i>     </span>
             <span *ngSwitchCase="'archive'">    <i
               class="fa fa-barcode text-danger m-r-xs"></i>   </span>
        </span>

      </div>

      <div class="col w-full">
        <div class="clearfix">
          <search-highlight
            [model]="lastSearchText || ''"
            [text]="item.name"
          ></search-highlight>
        </div>

        <div class="clearfix text-muted f-s-8"
             ng-if="item.description"
        >
          {{item.description}}
        </div>

      </div>

    </div>

  </ng-template>
</ng-select>

<input type="hidden" ng-required="!isValid" ng-init="dummy" ng-model="dummy">

const valueField = 'hasLoyaltyCard';

export class OfflineHasLoyaltyCard {

  // Передаваемые параметры в компонент
  public model : any;

  public configOnly: string;

  public isOnlyEnabled: string;

  get use() {
    return typeof this.model[valueField] !== "undefined";
  }

  set use( value: boolean ) {

    if (value) {
      this.model[valueField] = true;
    } else {
      delete this.model[valueField];
    }

    this.checkVisible();
    this.clearFields();
  }


  get value() {
    return this.model[valueField];
  }

  set value( value: string ) {
    this.model[valueField] = value;
  }


  get isEnabled() {
    return this.use;
  }

  static $inject = ['$scope', '$element'];

  constructor( public $scope: any, public $element: any ) { }

  public $onInit() {

    // Для вжух кнопки установим __proto__
    this.$scope.mechanic           = this.$scope.$parent.mechanic;
    this.$scope.unconfiguredFields = this.$scope.$parent.unconfiguredFields;
    //  Object.setPrototypeOf(this.$scope, this.$scope.$parent);
    //   Проверка на существование фильтров в списке
    //   $scope.$watch( ( _: any ) => this.model && this.model.$search, this.checkIsEnabled.bind(this) );

    this.checkVisible();
    this.setDefaults();

  }

  private setDefaults() {

  }

  public  isRequired = true;
  private offWatchVisible: Function;
  private checkVisible() {

    if ( this.offWatchVisible ) {
      this.offWatchVisible();
    }

    if ( this.use ) {

      this.isRequired = true ;
      setTimeout(() => {
        const input = this.$element.find('input[ng-model="$ctrl.value"]');
        this.offWatchVisible = this.$scope.$watch(() => input.is(':not(:hidden)'), (val: boolean) => {
          this.isRequired = val;
        });
      });

    } else {

      this.offWatchVisible = undefined;
      this.isRequired = true ;

    }

  }

  // очистка лишних полей в Json
  private clearFields() {

    if ( !this.use  ) {
      delete this.model[valueField] ;
    }

  }

}


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";
import {SettingsService} from "../../../../../shared/service/api/Settings/Settings.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {
  CampaignRowViewComponent
} from "../../../../campaign/campaigns/shared/components/campaign-row-view/campaign-row-view.component";
import {
  COUPON_ISSUING_TYPES_NAMES,
  COUPON_KIND_TYPES_NAMES,
} from "../../../../../shared/service/api/Coupon/Coupon.values";
import {CAMPAIGN_STATE_NAMES} from "../../../../../shared/service/api/Campaign/Campaign.values";
import {
  COUPON_APPLY_NAMES,
  COUPON_RULE_STATE_COLORS,
  COUPON_RULE_STATE_NAMES,
  COUPON_RULE_STATES,
  CUSTOM_PERIOD_NAMES,
  CUSTOM_PERIOD_TYPE_NAMES,
  ISSUING_TYPES,
  MAX_REWARD_LIMIT_MEASURE_TYPE_NAMES,
  MAX_REWARD_LIMIT_MEASURE_TYPES,
  MAX_REWARD_LIMIT_MEASURE_VALUES,
  MAX_REWARD_LIMIT_SUBTYPES_NAMES,
  MAX_REWARD_LIMIT_TYPES_SPLIT,
  REWARD_IGNORE_LIMIT_TYPES_NAMES,
  REWARD_MEASURES_TYPES,
  REWARD_MEASURES_TYPES_VALUES, REWARD_ONGOOD_REWTYPE_TYPES_NAMES,
  REWARD_REWTYPE_TYPES_NAMES,
  VALID_DAYS,
  VALID_DAYS_NAMES
} from "../../../../../shared/service/api/CouponRule/CouponRule.values";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {
  CustomPeriodTypes,
  IssuingType,
  StartPeriodTypes,
  StopPeriodTypes
} from "../../../../../shared/service/api/CouponRule/IssuingTypes/IssuingType";
import {CouponKind} from "../../../../../shared/service/api/CouponRule/couponKinds/CouponKind";
import {timeWords} from "../class/utils.class";
import {daysOfWeekDict} from "../../../../campaign/campaigns/shared/values/campaign.values";
import {GoodsWatcherService} from "../../../../../shared/component/goods-tree/service/goods-watcher.service";
import {CouponService} from "../../../../../shared/service/api/Coupon/Coupon.service";
import {CouponRuleRootClass} from "../class/coupon-rule-root.class";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import importController from "../../../../../../js/pages/coupons/rules/view/importController";
import * as importView from "../../../../../../js/pages/coupons/rules/view/views/import.html";
import {FileUploaderService} from "../../../../../shared/service/api/FileUploader/FileUploader.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CouponRuleImportComponent} from "../coupon-rule-import/coupon-rule-import.component";
import {getNg1Service} from "../../../../../shared/class/utils/angularjs.utils";

@Component({
  selector: 'coupon-rule-view',
  templateUrl: './coupon-rule-view.component.html',
  styleUrls: [
    `./coupon-rule-view.component.scss`
  ],
  providers: [
    CouponRuleService,
    SettingsService,
    PartnerService,
    CampaignCategoryService,
    GoodsWatcherService,
    CouponService,
    ngCurrencyL10nService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CouponRuleViewComponent
  extends CouponRuleRootClass
  implements OnInit, OnChanges {

  @Input() couponRuleItem: any;

  @Output() anchorList = new EventEmitter()
  public anchorListDict = {
    anchorI1: 'Купонное правило',
    anchorI2: 'Параметры',
    anchorI3: 'Тиражи',
    anchorI4: 'Вознаграждение',
    anchorI5: 'Настройка бонусов',
    anchorI6: 'Импорт набора купонов',
    anchorI7: 'Внешняя система лояльности',
    anchorImagesI8: 'Прикреплённые изображения'
  }

  public loader = new LoaderHandlerClass();


  @ViewChildren(CampaignRowViewComponent)
  public campaignRowViewComponents: QueryList<CampaignRowViewComponent>;

  public preparedFields = new Map();
  public settingsObj:any = {
    viewIndex: 1,
    hideZeroResourceRow: true,
    hideZeroClientSellingRow: true,
  }

  public fileImportResult;

  public hasRuleImportedCoupons = false;
  @Output() hasRuleImportedCouponsChange = new EventEmitter()

  constructor(
    public couponRuleService: CouponRuleService,
    public settingsService: SettingsService,
    public partnerService: PartnerService,
    public campaignCategoryService: CampaignCategoryService,
    public goodsWatcherService: GoodsWatcherService,
    public couponService: CouponService,
    public ngCurrencyL10nService : ngCurrencyL10nService,
    public fileUploaderService: FileUploaderService,
    public ngbModal: NgbModal,
  ) {
    super(ngCurrencyL10nService);
  }

  get externalLoyaltyFlag() {
    return !!this?.settingsObj?.externalLoyalty
  }

  get isExternalLoyalty() {
    return this.externalLoyaltyFlag || !!this.couponRuleItem?.elSettings?.externalCode;
  }


  get showApplyEvery() {

    if  ( [ 'eCoupon', 'couponWithoutBarcode','referralCouponWithBarcode', 'importedCouponForPrint'].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.couponKind ) >= 0 )
      return true;

    return this.couponRuleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcode' &&
      ['pos', 'uniqueCouponGen'].indexOf(this.couponRuleItem?.couponRule?.issueSettings?.issuingType) >= 0;

  }

  public getSystemSettings() {

    this.settingsService.getByKey$('externalLoyalty')
      .subscribe({
        next: r => {
          this.settingsObj.externalLoyalty = r
          if (!this.isExternalLoyalty) {
            this.anchorListDict.anchorI6 = undefined;
            this.anchorList.emit(this.anchorListDict)
          }
        }
      })
  }

  ngOnInit() {

    this.anchorList.emit(this.anchorListDict)

    this.getSystemSettings();

    this.subscribeCampaignsRowChange();

  }

  ngOnChanges(changes) {
    if (changes['couponRuleItem']) {
      this.settingsObj.viewIndex = 1
      this.updateIssueSettings();
      this.checkFile();
    }
  }

  getPartnerById(id) {

    if ( this.preparedFields.has('partner' +id) ) {
      return this.preparedFields.get('partner' +id);
    }

    this.preparedFields.set('partner' +id, '');
    this.partnerService.get$(id)
      .pipe(
        this.loader.waitLoading('partner'),
      )
      .subscribe({
        next: p => {
          this.preparedFields.set('partner' +id, p?.name);
        }
      })

    return  '';
  }

  getCampaignCategoryById(id) {

    if ( this.preparedFields.has('campaignCategory' + id) ) {
      return this.preparedFields.get('campaignCategory' + id);
    }

    this.preparedFields.set('campaignCategory' + id, {});
    this.campaignCategoryService.get(id)
      .pipe(
        this.loader.waitLoading('campaignCategory'),
      )
      .subscribe({
        next: p => {
          this.preparedFields.set('campaignCategory' + id, p);
        },
        error: err => {
          err?.stopPopupError();
          this.preparedFields.set('campaignCategory' + id, {
            name: 'Группа кампании с ID: '+ id,
          });
        }

      })

    return  '';
  }

  subscribeCampaignsRowChange() {
    setTimeout(() => {

      this.campaignRowViewComponents?.forEach( (i, index) => {
        i.setIndex(index +1);
      });

      this.campaignRowViewComponents
        .changes
        .subscribe( {
          next: (result: QueryList<CampaignRowViewComponent>) => {
            result.forEach( (i, index) => {
              i.setIndex(index +1);
            })
          }
        })
    })
  }

  updateIssueSettings()  {

    this.currentIssueType  = null;
    this.currentCouponKind = null;

    let issuingType = this?.couponRuleItem?.couponRule?.issueSettings?.issuingType

    if (!issuingType || !ISSUING_TYPES[issuingType])
      return;

    this.currentIssueType = new ISSUING_TYPES[issuingType]( this?.couponRuleItem, true );
    if (this?.couponRuleItem?.couponRule && this?.couponRuleItem?.couponRule?.issueSettings && this?.couponRuleItem?.couponRule?.issueSettings?.couponKind )
      this.currentIssueType.changeCouponKindById(this?.couponRuleItem?.couponRule?.issueSettings?.couponKind);

    this.currentCouponKind = this.currentIssueType.getCurrentCouponKind();

  }

  viewGoodsPopup( options: any ) {
    this.goodsWatcherService.open({
      list: options.viewGoodsPopup,
      forPartner: options.forPartner,
      listType: options.listType,
    }).catch(() => {});
  }

  firstLetterCapitalize(str: string) {
    return (!!str) ? str.charAt(0).toUpperCase() + str.substr(1) : '';
  }

  selectFile() {

    this.fileUploaderService
      .uploadFile$()
      .subscribe(fileUrl => {

        let modalInstance = this.ngbModal.open(
          CouponRuleImportComponent,
          {
            windowClass: 'modal-dialog-height modal-dialog-w1010',
          }
        );
        modalInstance.componentInstance.modalInit(fileUrl, this.couponRuleItem);

        modalInstance.result.then( ( result )=> {
          this.fileImportResult = result;
          this.checkFile();
        }, ( result ) => {

          if ( angular.isObject(result) && result.error ) {
            getNg1Service('toaster')
              .error( 'Ошибка импорта', result.error );
          }

        })

      });

  }

  closeFileImportResult() {
    this.fileImportResult = undefined;
  }

  notUndefined( filed ) {
    return filed!== undefined && filed!== null && filed!== '';
  }

  checkFile()  {
    this.couponService
      .hasImportedCoupons$(this.couponRuleItem?.couponRule?.id)
      .subscribe(res => {
        this.hasRuleImportedCoupons = res;
        this.hasRuleImportedCouponsChange.emit(this.hasRuleImportedCoupons);
      } )
  }

}

import {BrowserModule} from "@angular/platform-browser";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule, NgbModule, NgbNavModule,
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {TranslocoRootModule} from "../../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {SearchInputModule} from "../../../shared/component/search-input/search-input.module";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";
import {CommunicationsIndexPageComponent} from "./index/communications-index-page.component";
import {
  FilterCommunicationPeriodComponent
} from "./shared/components/filter-communication-period/filter-communication-period.component";
import {CommunicationPreviewComponent} from "./shared/components/communication-preview/communication-preview.component";
import {HighchartsChartModule} from "highcharts-angular";
import {CommunicationsViewPageComponent} from "./view/communications-view-page.component";
import {CommunicationEditComponent} from "./shared/components/communication-edit/communication-edit.component";
import {ImageUploaderModule} from "../../../shared/modules/imageUploader/imageUploader.module";
import {CommunicationViewComponent} from "./shared/components/communication-view/communication-view.component";
import {CommunicationRowViewComponent} from "./shared/components/communication-row-view/communication-row-view.component";
import {CommunicationCircleGraphComponent} from "./shared/components/communication-circle-graph/communication-circle-graph.component";
import {
  CommunicationMechanicComponent
} from "./shared/components/communication-mechanic/communication-mechanic.component";
import {
  CommunicationMechanicConditionsModule
} from "./shared/components/communication-mechanic/communication-mechanic.conditions";
import {CampaignsModule} from "../campaigns/campaigns.module";


@NgModule({
    declarations: [
        CommunicationsIndexPageComponent,
        FilterCommunicationPeriodComponent,
        CommunicationsViewPageComponent,
        CommunicationPreviewComponent,
        CommunicationEditComponent,
        CommunicationViewComponent,
        CommunicationRowViewComponent,
        CommunicationCircleGraphComponent,
        CommunicationMechanicComponent
    ],
    imports: [
        BrowserModule,
        UpgradeModule,
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        HttpClientModule,
        TranslocoRootModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        NgbModule,
        NgbNavModule,
        FiltersComponentsModule,
        SearchInputModule,
        HighchartsChartModule,
        ImageUploaderModule,
        CommunicationMechanicConditionsModule,
        CampaignsModule
    ],
    providers: [],
    bootstrap: [],
    exports: [
        CommunicationRowViewComponent,
    ],
    entryComponents: []
})
export class CommunicationsModule {

  constructor() {}

}

<div class="modal-header wrapper-xs padder bg-light">
    <h4 class="modal-title">Подтвердите удаление купона</h4>
</div>
<form name="deleteCouponReason" #deleteCouponReason="ngForm">
    <div class="modal-body">
        <div class="no-padder">Укажите причину удаления купона</div>
        <div class="no-padder m-t-sm">
            <input type="text"
                   class="form-control form-control-edittable"
                   [(ngModel)]="reasonCoupon"
                   name="reasonCoupon"
                   placeholder="Причина удаления купона"
                   [required]="true"
                   [maxlength]="100"
                   [required]="true">
        </div>
    </div>
</form>
<div class="modal-footer b-t bg-light dk ">

    <button class="btn btn-warning" type="button" (click)="cancelEvent()">Отмена</button>
    <button class="btn btn-danger" [disabled]="deleteCouponReason.invalid || !reasonCoupon" type="button"
            (click)="okEvent()">Удалить
    </button>

</div>

<div class="card m-l m-r m-t">
  <div class="card-body"
       id="anchorI1"
  >
    <div class="d-flex">

      <div class="col-auto padder-sm">
        <i class="fa fa-rocket fa-2x"
           [ngClass]=" 'text-' + COMMUNICATION_CAMPAIGN_STATES_COLORS[communicationItem?.campaign?.state]"
        ></i>
      </div>

      <div class="col">

        <h4 class=" overflow-wrap-break m-b-none" data-testid="communication.name">
          {{ communicationItem.campaign.name || '' }}
        </h4>

        <div class="small m-b-xs">

          <span [innerText]="COMMUNICATION_CAMPAIGN_STATE_NAMES[communicationItem?.campaign?.state] || communicationItem?.campaign?.state"
                [ngClass]=" 'text-' + COMMUNICATION_CAMPAIGN_STATES_COLORS[communicationItem?.campaign?.state]"
                data-testid="communication.state"></span>
        </div>

        <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-xs"></div>

        <div class="text-muted text-small overflow-wrap-break" data-testid="communication.description">
          {{ communicationItem.campaign.description || '' }}
        </div>

      </div>

      <div class="col-auto  no-padder text-right font-thin text-muted text-small"
           data-testid="communication.id"
           *ngIf="communicationItem?.campaign?.id>=0"
      >
          ID: <span class="">{{ communicationItem.campaign.id }}</span>
      </div>

    </div>
  </div>
</div>


<div class=" w-full padding">
  <div class="row communication-column-row">
    <div class="col col-communication-column">
      <div class="card">
        <div class="card-body p-t-none p-b-none">

            <communication-row-view
              name="Партнер"
            >
              <i class="fa fa-spin fa-spinner text-warning"
                 *ngIf="loader.isLoading('partner')"
              ></i>
              <span data-testid="communication.partner"
                    [innerText]="getPartnerById(communicationItem?.campaign?.partnerId)"
              ></span>
            </communication-row-view>


            <communication-row-view
              *ngIf="!!communicationItem?.locationGroup?.length"
              name="Локации"
            >
               <span *ngFor="let locationGroup of communicationItem.locationGroup ;  let last = last;" data-testid="communication.locationGroup">
                 {{ locationGroup?.name || '' }} {{ last ? '' : ','}}
                </span>
            </communication-row-view>

            <communication-row-view
              *ngIf="!!communicationItem?.location?.length"
              name="Точки продаж"
            >
               <span *ngFor="let location of communicationItem.location | slice:0:3;  let last = last;" data-testid="communication.location">
                {{ location?.name || '' }} {{ last ? '' : ','}}
              </span>

              <div *ngIf="communicationItem?.location?.length>3"
                   class="pos-rlt table-big-link">


                <ng-template #locationTemplate >

                  <div style="max-height:300px; min-width:200px;"
                       class="mini-scroll scroll-y  ">

                    <div class="text-word-break  " *ngFor="let t of communicationItem.location " data-testid="communication.popup.location">
                      {{t.name }}
                    </div>

                  </div>

                </ng-template>


                <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                   href="javascript:void(0)"

                   [ngbPopover]="locationTemplate"
                   [popoverTitle]="'Для магазинов'"
                   [placement]="'bottom'"
                   data-testid="communication.location.more"

                >
                  + ещё {{communicationItem.location.length-3}}
                </a>

              </div>


            </communication-row-view>

            <communication-row-view
              *ngIf="!!communicationItem?.exceptLocationGroup?.length"
              name="Исключённые локации"
            >
                 <span *ngFor="let locationGroup of communicationItem.exceptLocationGroup ;  let last = last;" data-testid="communication.exceptLocationGroup">
                   {{ locationGroup?.name || '' }} {{ last ? '' : ','}}
                  </span>
            </communication-row-view>

            <communication-row-view
              *ngIf="!!communicationItem?.exceptLocation?.length"
              name="Исключённые точки продаж"
            >
                 <span *ngFor="let location of communicationItem.exceptLocation | slice:0:3;  let last = last;" data-testid="communication.exceptLocation">
                  {{ location?.name || '' }} {{ last ? '' : ','}}
                </span>

              <div *ngIf="communicationItem?.exceptLocation?.length>3"
                   class="pos-rlt table-big-link">


                <ng-template #exceptLocationTemplate >

                  <div style="max-height:300px; min-width:200px;"
                       class="mini-scroll scroll-y  ">

                    <div class="text-word-break  " *ngFor="let t of communicationItem.exceptLocation " data-testid="communication.popup.exceptLocation">
                      {{t.name }}
                    </div>

                  </div>

                </ng-template>


                <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                   href="javascript:void(0)"

                   [ngbPopover]="exceptLocationTemplate"
                   [popoverTitle]="'Для магазинов'"
                   [placement]="'bottom'"
                   data-testid="communication.exceptLocation.more"

                >
                  + ещё {{communicationItem.exceptLocation.length-3}}
                </a>

              </div>


            </communication-row-view>

            <communication-row-view
            *loyaPermitIf="{path:'segments', permitEnabled: ''}"
            name="Целевая аудитория"
            data-testid="communication.targetAudience"
          >
            <span *ngIf="communicationItem.segment" data-testid="segment.name">сегмент - {{communicationItem?.segment?.name}}</span>
            <span *ngIf="communicationItem.group" data-testid="group.name">группа - {{communicationItem?.group?.name}}</span>
            <span *ngIf="!communicationItem?.segment && !communicationItem?.group" data-testid="segment.group.all">Все</span>

          </communication-row-view>

          <communication-row-view
            *loyaPermitIf="{path:'segments', permitDisabled: ''}"
            name="Целевая аудитория"
            data-testid="communication.targetAudience"
          >

            <span *ngIf="communicationItem?.group?.name==='Все УПЛ'">Участники ПЛ</span>
            <span *ngIf="communicationItem?.group?.name==='Все неУПЛ'">Не участники ПЛ</span>
            <span *ngIf="communicationItem?.group && communicationItem?.group?.name!=='Все неУПЛ' && communicationItem?.group?.name!=='Все УПЛ'" data-testid="group.name">группа - {{communicationItem?.group?.name}}</span>

            <span *ngIf="!communicationItem?.group">Все</span>

          </communication-row-view>

        </div>
      </div>
    </div>

    <div class="col col-communication-column">
      <div class="card">
        <div class="card-body p-t-none p-b-none">


          <communication-row-view
            *loyaPermitIf="{path:'campaign.settings.exceptionAudience', permitEnabled: ''}"
            name="Ограничения целевой аудитории"
            data-testid="campagin.exceptionAudience"
          >

            <span *ngIf="communicationItem?.exceptSegment" data-testid="exceptSegment.name">исключая сегмент - {{ communicationItem?.exceptSegment?.name }}</span>
            <span *ngIf="communicationItem?.exceptGroups">
                <span class="text-muted">исключая группы:</span>
                <div class="text-right clearfix" >
                  <span class="clearfix text-break" *ngFor="let gr of communicationItem.exceptGroups"  data-testid="exceptGroup.name">
                    {{ gr.name }}
                  </span>
                </div>
              </span>
            <span *ngIf="!communicationItem?.exceptSegment && !communicationItem?.exceptGroups" data-testid="exceptSegment.exceptGroup.all">Без ограничений</span>

          </communication-row-view>

            <communication-row-view
              name="Период действия"
            >
              <span data-testid="communicationTime.period">
                 с {{ communicationItem?.campaign?.campaignTime?.startDate | loyaDate }} по {{ communicationItem?.campaign?.campaignTime?.stopDate | loyaDate }}
              </span>
            </communication-row-view>
<!--

            <communication-row-view
              name="Рассылка за"
            >
              <span data-testid="campaignTime.informationDate">
                {{ communicationItem?.campaign?.campaignTime?.informationDate || 0}} дней
              </span>
            </communication-row-view>
-->

            <communication-row-view
              name="Время действия"
            >
              <div *ngFor="let period of communicationItem?.campaign?.campaignTime?.periods"
                   data-testid="period in communicationTime.periods"

              >

                <div class="clearfix text-right">

                   <span data-testid="communicationTime.time.period">
                    с {{ period.startTime | loyaTime:'HH:mm' }} по {{ period.stopTime  | loyaTime:'HH:mm' }}
                  </span>

                  <div class="f-s-9 text-nowrap">
                    <i class="fa fa-circle  m-r-xs"
                       *ngFor="let day of daysOfWeekDict"
                       data-testid="day in daysOfWeek"
                       [ngClass]="{'text-muted': period.daysOfWeek.indexOf(day.key)===-1 , 'text-info': period.daysOfWeek.indexOf(day.key)>=0 }"
                       [ngbPopover]="day.key"
                       [triggers]="'mouseenter:mouseleave'"
                       [container]="'body'"
                    >
                    </i>
                  </div>

                </div>

                <div class="clearfix m-b-xs m-t-none"
                     *ngIf="period.daysOfMonth && !!period.daysOfMonth?.length"
                >
                  <div class="clearfix text-muted">День месяца</div>

                  <span *ngFor="let monthDayItem of daysOfMonthDics"
                        data-testid="monthDayItem in daysOfMonth">

                      <span class="text-nowrap f-s-9">
                        <i class="fa fa-circle text-success m-r-xs"
                           [ngClass]="{'text-muted': period.daysOfMonth.indexOf(monthDayItem.key)===-1 }"
                           [ngbPopover]="monthDayItem.name"
                           triggers="mouseenter:mouseleave"
                           [container]="'body'"
                        >
                        </i>
                      </span>

                      <br *ngIf="monthDayItem.key % 7 === 0" />

                   </span>

                </div>

              </div>

            </communication-row-view>



        </div>
      </div>

    </div>
  </div>
</div>

<div class="card  m-l m-r m-t">
  <div class="card-header"  id="anchorI2">
    Информирование
  </div>
  <div class="card-body">

    <form-container>
      <div [form-item-row] = "'Шаблон сообщений'"
           [form-item-row-icon]="'fa-files-o'"
           [form-item-row-input]="true"
      >
        <a class="btn btn-xs btn-success"
           href="javascript:void(0)"
           [uiSref]="'frontend.campaign.campaigns.templates.view({id:' + communicationItem?.campaign?.templateId + '})'"
           data-testid="communication.messageTemplate"
        >
          <i class="fa fa-list-alt m-r-xs "></i>
          {{ communicationItem.msgTemplateName || 'Темплейт с ID:' + communicationItem?.campaign?.templateId}}
        </a>

      </div>


      <div data-testid="communicationChannelName"
           [form-item-row] = "'Канал информирования'"
           [form-item-row-icon]="'fa-bell'"
           [form-item-row-input]="true"
      >
        {{ CHANNEL_TYPES_NAMES[communicationItem?.campaign?.channel] || CHANNEL_TYPES_NAMES[CHANNEL_TYPES.BOTH]}}
      </div>


    </form-container>
  </div>
</div>


<div class="card  m-l m-r m-t ">
  <div class="card-header"
       id="anchorI6"
  >
    Условия срабатывания
  </div>
  <div class="card-body" data-testid="communicationMechanic"
  >

    <form-container>
      <communication-mechanic
        [communicationItem]="communicationItem"
        [isEdit]= "false"
      >
      </communication-mechanic>
    </form-container>

  </div>
</div>

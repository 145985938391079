import {RoleType} from "module/permissions/conf/roles";
import {IMenuItem} from "./typings";

export let RECEIPT_ROLES = [  RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager ];

export const DIRECTORY_DUB_ARRAY : Array<IMenuItem>  = [
            {
                name:   "Пользователи",
                uiSref: "frontend.config.directory.users.index"
            },
            {
                name:   "Точки продаж",
                uiSref: "frontend.config.directory.locations.index"
            },
            {
                name:   "Локации",
                uiSref: "frontend.config.directory.locationsGroup.index"
            },
            {
                name:   "Типы механик",
                uiSref: "frontend.config.directory.mechaniccategory.index"
            },
            {
                name:   "Группы кампаний",
                uiSref: "frontend.config.directory.campaigncategory.index"
            },
            {
                name:   "Коллизии групп кампаний",
                uiSref: "frontend.config.directory.campaigncategorylimitations.index"
            },
            {
                name:   "Ограничения вознаграждений кампаний",
                uiSref: "frontend.config.directory.campaignLimitations.index"
            },
            {
                name:   "Категории клиентов",
                uiSref: "frontend.config.directory.clientcategory.index",
                permit: "global.clients",
            },
            {
                name:   "Бренды",
                uiSref: "frontend.config.directory.brand.index"
            },
            {
                name:   "Списки товаров",
                uiSref: "frontend.config.directory.goodgroup.index"
            },
            {
                name:   "Ограничения вознаграждений товаров",
                uiSref: "frontend.config.directory.bonusRestrict.index"
            }
        ];

export const SETTINGS_SUB_ARRAY : Array<IMenuItem> = [
            /*       {
                         name:   "Модули",
                         uiSref: "frontend.system.settings.client.modules",
                         permit: 'modules'
                     },*/
            {
                name:   "Настройки системы",
                uiSref: "frontend.system.settings.index"
            },
            {
                name:   "Задачи",
                uiSref: "frontend.system.settings.externalTask.index",
                permit: "system.settings.externalTask",
            },
            {
                name:   "Компоненты",
                uiSref: "frontend.system.modules.index",
                permit: 'modules.components'
            }

        ];

export const MENU_ARRAY : Array<IMenuItem>  = [
            {
                icon: 'fa fa-plus  fa-lg',
                name: 'Анкета',
                uiSref: "frontend.crm.clients.view({id:'worksheet'})",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator, RoleType.InputOperator],
                permit: "global.clients",
            },
            {
                icon: 'fa fa-user  fa-lg',
                name: 'Клиенты',
                uiSref: "frontend.crm.clients.index",
                ngClass: "{active:$state.includes('frontend.crm') && !$state.includes('frontend.crm.clients.addition.edit')}",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator,  RoleType.InputOperator, RoleType.Infocenter ],
                permit: "global.clients",
                subMenu:[
                    {
                        name: 'Клиенты',
                        ngClass: "{active:$state.includes('frontend.crm.clients.index') || $state.includes('frontend.crm.clients.view') }",
                        uiSref: "frontend.crm.clients.index",
                        permit: "global.clients",
                        permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator, RoleType.InputOperator, RoleType.Infocenter ]

                    },
                    {
                        name: 'Карты',
                        ngClass: "{active:$state.includes('frontend.crm.cards')}",
                        uiSref: "frontend.crm.cards.index",
                        permit: "global.clients",
                        permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator],

                    },
                    {
                        name: 'Счета',
                        ngClass: "{active:$state.includes('frontend.crm.accounts')}",
                        uiSref: "frontend.crm.accounts.index",
                        permit: "crm.accounts",
                        permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator],

                    }
                ]

            },
            {
                icon: 'fa fa-rocket  fa-lg',
                name: 'Кампании',
                ngClass: "{active:$state.includes('frontend.campaign')}",
                uiSref: "frontend.campaign.campaigns.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager],
                subMenu:[
                    {
                        name:   "Кампании",
                        uiSref: "frontend.campaign.campaigns.index"/*,
                                active: [
                                    new RegExp('campaign\.campaigns\.view','ig')
                                ]*/

                    },
                    {
                      name:   "Коммуникации",
                      uiSref: "frontend.campaign.communications.index"/*,
                                  active: [
                                      new RegExp('campaign\.campaigns\.view','ig')
                                  ]*/

                    },
                    {
                        name:    "Шаблоны механик",
                        ngClass: "{active:$state.includes('frontend.campaign.mechanics')}",
                        uiSref:  "frontend.campaign.mechanics.index"

                    },
                    {
                        name:    'Шаблоны сообщений',
                        uiSref:  "frontend.campaign.campaigns.templates.index"

                    },
                    {
                        name: 'Журнал рассылки',
                        /*ngClass: "{active:$state.includes('frontend.campaign.gate')}",*/
                        uiSref: "frontend.campaign.gate.index",
                        permit:  'channel.type.email'
                    }
                ]

            },
            /*                {
                                icon: 'fa fa-object-group  fa-lg',
                                name: 'Цепочки',
            //                  ngClass: "{active:$state.includes('frontend.campaign')}",
                                uiSref: "frontend.triggers.trigger.index",
                                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager],
                                subMenu:[
                                    {
                                        name:   "Цепочки",
                                        uiSref: "frontend.triggers.trigger.index"

                                    }
                                ]

                            },*/
            {
                icon: 'fa fa-link  fa-lg',
                name: 'Цепочки',
                //                  ngClass: "{active:$state.includes('frontend.campaign')}",
                uiSref: "frontend.triggers.trigger.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager],
                permit:'triggers',
                subMenu:[
                    {
                        name:   "Цепочки",
                        uiSref: "frontend.triggers.trigger.index"

                    }
                ]

            },
            {
                icon: 'fa fa-barcode  fa-lg',
                name: 'Купоны',
                ngClass: "{active:$state.includes('frontend.coupons')}",
                uiSref: "frontend.coupons.rules.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator],
                subMenu:[
                    {
                        name:   "Купонные правила",
                        uiSref: "frontend.coupons.rules.index",
                        permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager]

                    },
                    {
                        name:   "Купоны",
                        uiSref: "frontend.coupons.coupons.index",
                        permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator]

                    }
                ]

            },
            {
              icon: 'fa fa-gift  fa-lg',
              name: 'Подарочные сертификаты',
              ngClass: "{active:$state.includes('frontend.editions')}",
              uiSref: "frontend.editions.editions.index",
              permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator],
              permit:'editions',
              subMenu:[
                {
                  name:   "Тиражи",
                  uiSref: "frontend.editions.editions.index",
                  permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator]

                },
                {
                  name:   "Сертификаты",
                  uiSref: "frontend.editions.certificate.index",
                  permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator,  RoleType.Operator]

                }
              ]

            },
            {
                icon: 'fa fa-pie-chart  fa-lg',
                name: 'Сегментация',
                ngClass: "{active:$state.includes('frontend.segments')}",
                uiSref: "frontend.segments.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager],
                permit:'segments',
                subMenu:[
                    {
                        name:   "Сегменты",
                        ngClass:"{active:$state.includes('frontend.segments.index') || $state.includes('frontend.segments.view') }",
                        uiSref: "frontend.segments.index",
                        permit: 'segments'

                    },
                    {
                        name:    "Группы",
                        ngClass: "{active:$state.includes('frontend.segments.groups')}",
                        uiSref:  "frontend.segments.groups.index",
                        permit:  'segments.groups'

                    }
                ]
            },
            {
                icon: 'fa fa-bar-chart-o fa-lg  ',
                name: 'Отчёты',
                ngClass: "{active:$state.includes('frontend.reports')}",
                uiSref: "frontend.reports.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager,  RoleType.TopManager ],
                subMenu:[
                    {
                        name:   "Отчёты",
                        ngClass:"{active:$state.includes('frontend.reports.index')}",
                        uiSref: "frontend.reports.index({id: ''})"

                    }
                ]


            },
            {
                icon: 'fa fa-book fa-lg ',
                name: 'Справочники',
                ngClass: "{active:$state.includes('frontend.config')}",
                uiSref: "frontend.config.directory.users.index",
                permissions: [ RoleType.Administrator,  RoleType.MarketingManager, RoleType.GiftMarketingManager],
                subMenu: DIRECTORY_DUB_ARRAY

            },
            {
                icon: 'fa fa-file-text fa-lg ',
                name: 'Чеки',
                ngClass: "{active:$state.includes('frontend.receipt')}",
                uiSref: "frontend.receipt.index",
                permissions: RECEIPT_ROLES,
                subMenu:[
                    {
                        name:   "Поиск чеков",
                        ngClass:"{active:$state.includes('frontend.receipt.index')}",
                        uiSref: "frontend.receipt.index"

                    }
                ]

            },
            {
                icon: 'fa fa-sliders  fa-lg',
                name: 'Настройки',
                ngClass: "{active:$state.includes('frontend.system')}",
                uiSref: "frontend.system.settings.client.modules",
                permissions: [ RoleType.Administrator  ],
                subMenu: SETTINGS_SUB_ARRAY

            }

        ];

<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
>

  <additional-menu
    [hasFilter]="true"
    [filterValue]="searchString"
    (filterValueChange)="onSearchChange($event)"
    (onAddClick)="onAddClick($event)"
    [showAddButton]="true"
    (onScroll)="onScroll()"
  >
    <div menuBlock>
      <ul class="nav nav-scroll flex-column"
      >
        <li *ngFor="let locationGroup of listLocationGroups" class="nav-item" data-testid="locationGroup.item in list">
          <a (click)="selectLocationGroup(locationGroup)"
             [ngClass]="{ 'nav-active':selectedLocationGroup?.id==locationGroup?.id }"
             class="overflow-wrap-break nav-link"
          >
            <span [innerText]="locationGroup?.name" data-testid="locationGroupItem.name"></span>
          </a>
        </li>
      </ul>

      <div class="m-t-sm text-center"
           *ngIf="loader.isLoading('listLocationGroups')">
        <i class="fa fa-spin fa-spinner text-warning fa-2x"></i>
      </div>

    </div>

    <div selectedBlock>

      <span class="h4"
            [innerText]="selectedLocationGroup ? selectedLocationGroup?.name : '- Выбрать -'">
      </span>

    </div>

    <div contentBlock>

      <location-group-index-view-page
        [locationGroupId]="selectedLocationGroup?.id"
        [selectedPartner]="selectedPartner"
        [createEvent]="createCounter"
        (onDelete)="onDelete($event)"
        (onCreate)="onCreate($event)"
        (onUpdate)="onUpdate($event)"
      >
      </location-group-index-view-page>

    </div>

  </additional-menu>

  <scroll-up></scroll-up>

</partner-menu>

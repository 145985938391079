import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
    NgbAlertModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {downgradeComponent} from "@angular/upgrade/static";
import {CouponsRulesIndexPageComponent} from "./index/coupons-rules-index-page.component";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {SearchInputModule} from "../../../shared/component/search-input/search-input.module";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";
import {CouponsRulesViewPageComponent} from "./view/coupons-rules-view-page.component";
import {CouponRuleViewComponent} from "./components/coupon-rule-view/coupon-rule-view.component";
import {CampaignsModule} from "../../campaign/campaigns/campaigns.module";
import {CirculationCouponRule} from "./components/circulation-coupon-rule/circulation-coupon-rule.component";
import {ImageUploaderModule} from "../../../shared/modules/imageUploader/imageUploader.module";
import {CouponRuleEditComponent} from "./components/coupon-rule-edit/coupon-rule-edit.component";
import {CouponRuleImportComponent} from "./components/coupon-rule-import/coupon-rule-import.component";
import {ScrollUpModule} from "../../../shared/component/scroll-up/scroll-up.module";


@NgModule({
  declarations: [
    CouponsRulesIndexPageComponent,
    CouponsRulesViewPageComponent,
    CouponRuleViewComponent,
    CirculationCouponRule,
    CouponRuleEditComponent,
    CouponRuleImportComponent
  ],
    imports: [
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        HttpClientModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        NgbNavModule,
        NgbPopoverModule,
        SearchInputModule,
        FiltersComponentsModule,
        CampaignsModule,
        ImageUploaderModule,
        NgbAlertModule,
        ScrollUpModule,
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class CouponsRulesPageModule {

  constructor() {}

}

angular.module('loya.builder')
  .directive('couponsRulesIndexPage' , downgradeComponent({ component: CouponsRulesIndexPageComponent  }) )
  .directive('couponsRulesViewPage' , downgradeComponent({ component: CouponsRulesViewPageComponent  }) )


'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {downgradeComponent} from "@angular/upgrade/static";
import {CommunicationsIndexPageComponent} from "./communications-index-page.component";
let routePath   = module.name + '.campaign.communications.index';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )

        .template(  '<communications-index-page [search]="search"></communications-index-page>', true )
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.search = $stateParams.search; } ])

        .params('search', null)

        .permissions( {
          only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],

        })

        .breadcrumbs([ "Коммуникации", "Просмотр" ]);

    }])

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CouponApiService} from "../../../../api/CouponApi/services";
import {CouponRuleQueryParam} from "../../../../api/CouponRuleApi/models/coupon-rule-query-param";
import CouponRuleFilters from "../../../../../js/models/CouponRuleModel/filters";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {COUPON_STATES} from "./Coupon.values";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";

let prepareParams = (params: any) => {
  let flatParams = {};
  Object.keys(params || {})
    .forEach(
      rootKey => {
        if ( typeof params[rootKey] !== 'object' || Array.isArray(params[rootKey]) ) {
          flatParams[rootKey] = params[rootKey];
          return;
        }

        Object.keys(params[rootKey] || {} )
          .forEach(
            childKey => {
              if (!params[rootKey][childKey] && params[rootKey][childKey] !== 0)
                return;

              flatParams[`${rootKey}.${childKey}`] =
                Array.isArray(params[rootKey][childKey]) ? params[rootKey][childKey].join(',') : params[rootKey][childKey];
            }
          )
      }
    )
  return flatParams;
}

@Injectable()
export class CouponService {

  constructor(
    private httpClient: HttpClient,
    private couponApiService: CouponApiService,
  ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "sentence"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.couponKind',
        id: 'couponKind',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.issuingType',
        id: 'issuingType',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.clientId',
        id: 'clientId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.couponRuleId',
        id: 'couponRuleId',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public transactionsParams  = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 200
    },
    filters: [
      {
        field: 'id',
        id: 'id',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public query$(params: any ){

    if (!!params.sentence)
      return this.search$(params);

    return this.httpClient.get('/coupon', {
      params: prepareParams(params)
    })
  }

  public get$(id: number ){

    return this.httpClient.get('/coupon/' + id, {
      responseType: 'json'
    })

  }

  public getOrCreate$(id: number ){

    if ( !id )
      return of ({

      })

    return this.get$(id);

  }

  public update$(id: number, body: any ){

    return this.httpClient.post('/coupon/' + id, body,{
      responseType: 'json'
    })

  }

  public delete$(id: number, body: any){

    return this.httpClient.post('/coupon/' + id + '/delete', body,{
      responseType: 'json',
    })

  }


  public search$(params: any ){

    return this.httpClient.get('/coupon/search', {
      params: prepareParams(params)
    })
  }

  public hasImportedCoupons$( couponRuleId: number ){
    return this.query$({
      'pager.drop'  : 0,
      'pager.limit' : 1,
      'filter.couponRuleId' : couponRuleId,
      'filter.states' : Object.values( COUPON_STATES )?.join(',')
    }).pipe(
      map( (res: any) => res.length > 0 ),
      catchError( () => of(false) )
    )
  }

  public transactions$(params: CouponApiService.TransactionsParams) {
    return this.couponApiService
               .transactions(params);
  }

  public getCouponIssueInfo$(id: number) {
    return this.httpClient.get('/coupon/couponIssueInfo/' + id, {
      responseType: 'json'
    })
  }

}


import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../../shared/service/api/Settings/Settings.service";
import {daysOfMonthDics, daysOfWeekDict} from "../../values/campaign.values";
import {InformingCampaignProtocol} from "../../../../../../api/CommunicationCampaignApi/models/informing-campaign-protocol";
import {CommunicationTime} from "../../../../../../api/CommunicationCampaignApi/models/communication-time";
import {InformingCampaignTargetInfo} from "../../../../../../api/CommunicationCampaignApi/models/informing-campaign-target-info";
import {
  CommunicationCampaignService
} from "../../../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.service";
import {
  CHANNEL_TYPES_NAMES
} from "../../../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.values";

@Component({
  selector: 'communication-edit',
  templateUrl: './communication-edit.component.html',
  styleUrls: [
    `./communication-edit.component.scss`
  ],
  providers: [
    CommunicationCampaignService,
    LocationsDialogService,
    GroupService,
    SettingsService,
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationEditComponent implements OnInit, OnChanges {

  @Input() communicationItem: InformingCampaignProtocol;

  @Output() anchorList = new EventEmitter()

  public currentMechanic;

  public loader = new LoaderHandlerClass();
  public settingsObj:any = {}
  public subscribers:any = {}

  public showDays = [];

  public daysOfWeekDict = daysOfWeekDict;
  public daysOfMonthDics = daysOfMonthDics;
  public targetDist = [
    { key: 'all', name :'Все' },
    { key: 'segment', name :'Сегмент' },
    { key: 'group', name :'Группа' }
  ];

  public targetAudience = 'all';
  public exceptionAudience = 'all';

  public targetGroupInfo: InformingCampaignTargetInfo = <any>{};
  public campaignTimeCount: CommunicationTime = <any>{};

  public CHANNEL_TYPES_NAMES = Object.keys(CHANNEL_TYPES_NAMES).map(i => ({
      key: i,name: CHANNEL_TYPES_NAMES[i]
  }));

  constructor(
    public communicationCampaignService: CommunicationCampaignService,
    public locationsDialogService: LocationsDialogService,
    public groupService: GroupService,
    public settingsService: SettingsService
  ) {
  }

  ngOnInit() {

    this.prepareTargetDict();

    this.anchorList.emit({
      anchorI1: 'Параметры',
      anchorI2: 'Период и место действия',
      anchorI3: 'Аудитория',
      anchorI8: 'Условия срабатывания',
      anchorI4: 'Информирование',
    })

  }

  ngOnChanges(changes) {
    if (changes['communicationItem']) {
      this.calcTargetValues();
      this.recalcAudience();
      this.recalcCampaignTime();
    }
  }

  get isCommunicationStarted() {
    return !!this.communicationItem?.campaign?.id && this.communicationItem?.campaign?.state !== "draft"
  }

  get isSuspend() {
    return this.communicationItem?.campaign?.state === 'suspended'
  }

  get isPartnerSelected() {
    return !!this.communicationItem?.campaign?.partnerId
  }

  private locationGroupsArray = [];
  get locationGroupsId() {
    this.locationGroupsArray.splice(0, this.locationGroupsArray?.length);
    this.communicationItem?.locationGroup?.forEach( i => this.locationGroupsArray.push(i?.groupId ) );
    return this.locationGroupsArray;
  }

  set locationGroupsId(value) {

    this.communicationItem = this.communicationItem || <any>{};

    if (!value || !Array.isArray(value) ) {
      delete this.communicationItem.locationGroup;
      return
    }

    this.communicationItem.locationGroup = value.map( i => ({ groupId: i}) ) || [];
  }

  private expectLocationGroupsArray = [];
  get expectLocationGroupsId() {
    this.expectLocationGroupsArray.splice(0, this.expectLocationGroupsArray?.length);
    this.communicationItem?.exceptLocationGroup?.forEach( i => this.expectLocationGroupsArray.push(i?.groupId ) );
    return this.expectLocationGroupsArray;
  }

  set expectLocationGroupsId(value) {

    this.communicationItem = this.communicationItem || <any>{};

    if (!value || !Array.isArray(value) ) {
      delete this.communicationItem.exceptLocationGroup;
      return
    }

    this.communicationItem.exceptLocationGroup = value.map( i => ({ groupId: i}) ) || [];
  }

  hasSelectedLocation(type = 'location') {
    return !!this.communicationItem?.[ type + 'Group']?.length  || this.communicationItem?.[type]?.length;
  }

  get isFrontalCampaign() {
    return !(
      // Если выбрана целевая аудитория
      typeof this.communicationItem.campaign.groupId   !== 'undefined' ||
      typeof this.communicationItem.campaign.segmentId !== 'undefined' ||
      this.targetAudience           !== 'all' ||

      // Если выбрано ограничение целевой аудитории
      this.exceptionAudience              !== 'all' ||
      typeof this.communicationItem.campaign.exceptSegmentId !== 'undefined' ||
      typeof this.communicationItem.campaign.exceptGroupIds   !== 'undefined'
    )
  }

  public isEnabledInArray = ( key, array) => Array.isArray(array) && array?.indexOf(key)>=0;

  public changeWeekDay = ( isEnabled, key, array) => {

    if ( isEnabled ) {

      if ( !this.isEnabledInArray(key, array) ) {
        array.push(key);
      }

    } else {

      let index = array.indexOf(key);
      if ( index>=0 ) {
        array.splice(index,1);
      }

    }

  };

  public changeMonthDay = ( isEnabled, key, period) => {

    if ( isEnabled ) {

      if ( !this.isEnabledInArray(key, period.daysOfMonth) ) {
        period.daysOfMonth = period.daysOfMonth || [];
        period.daysOfMonth.push(key);
      }

    } else {

      if ( Array.isArray(period.daysOfMonth) ) {
        let index = period.daysOfMonth.indexOf(key);
        if ( index>=0 ) {
          period.daysOfMonth.splice(index,1);
        }

        if (!period.daysOfMonth?.length)
          delete period.daysOfMonth;

      }

    }

  };

  public createCampaignPeriod() {

    this.communicationItem.campaign.campaignTime.periods = this.communicationItem.campaign.campaignTime.periods || [];
    this.communicationItem.campaign.campaignTime.periods.push( {

      "startTime": "00:00:00",
      "stopTime": "23:59:59",
      "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]

    } );

  };

  public isDaysOfMonthChanged = (period) => {
    if ( Array.isArray(period) )
      return !!period?.length

    return !!period?.daysOfMonth?.length;
  }

  public selectLocations(type = 'location') {
    if (!this.isPartnerSelected)
      return;

    this.locationsDialogService.open({
      forPartner: this.communicationItem?.campaign?.partnerId,
      canCheckAll: false,
      initSelectedItems: this.communicationItem?.[type]?.map( i => i.merchantId),
      resultDirective: () => {}
    }).then( result => {

      this.communicationItem[type] = result?.locations?.map( i => ({
        merchantId: i?.id,
        name: i?.name
      }))

      if (!this.communicationItem?.[type]?.length)
        delete this.communicationItem[type];

    }, () => {
      if (!this.communicationItem?.[type]?.length)
        delete this.communicationItem[type];
    })
  }

  public removeLocation(location, type = 'location' ) {
    this.communicationItem[type] = this.communicationItem[type] || [];
    this.communicationItem[type] = this.communicationItem?.[type]?.filter( i => i !== location)
  }

  public prepareTargetDict() {
    const loyaPermissions = getNg1Service('loyaPermissions')
    if ( loyaPermissions.byPath('segments').disabled ) {

      this.targetDist = [
        { key: 'all', name :'Все' }
      ];

      if (loyaPermissions.byPath('segments.groups').enabled) {
        this.targetDist.push( { key: 'upl', name :'Участники ПЛ' } );
        this.targetDist.push( { key: 'noupl', name :'Не участники ПЛ' } );
      }

    }

  }

  public calcTargetValues() {

    const loyaPermissions = getNg1Service('loyaPermissions')

    if ( loyaPermissions.byPath('segments').enabled ) {
      this.targetAudience = !!this.communicationItem?.campaign?.segmentId? 'segment' : !!this.communicationItem?.campaign?.groupId? 'group' : 'all';
    } else {
      switch (true) {

        case this.communicationItem?.group?.name.toLowerCase() === 'все упл':
          this.targetAudience = 'upl';
        break;

        case this.communicationItem?.group?.name.toLowerCase() === 'все неупл':
          this.targetAudience = 'nupl';
        break;

        default:
          this.targetAudience = 'all';
      }
    }

    this.exceptionAudience =
      !!this.communicationItem?.campaign?.exceptSegmentId ? 'segment' :
        !!this.communicationItem?.campaign?.exceptGroupIds ? 'group' :
          'all';

  }

  public onChangeTarget(isExceptionChange?) {

    if (!isExceptionChange)
      switch (this.targetAudience) {
        case 'all':
          delete this.communicationItem.campaign.segmentId;
          delete this.communicationItem.campaign.groupId;
          break;

        case 'group':
          delete this.communicationItem.campaign.segmentId;
          break;

        case 'segment':
          delete this.communicationItem.campaign.groupId;
          break;

        case 'upl':
          delete this.communicationItem.campaign.segmentId;
          delete this.communicationItem.campaign.groupId;

          this.groupService.getList$({
            search: "Все УПЛ"
          }).subscribe({
            next: result => {
              let findedGroup = result.find( i => i.group?.name?.toLowerCase() === 'все упл');
              if (findedGroup)
                this.communicationItem.campaign.groupId = findedGroup?.group?.id;
            }
          })
          break;

        case 'nupl':
          delete this.communicationItem.campaign.segmentId;
          delete this.communicationItem.campaign.groupId;

          this.groupService.getList$({
            search: "Все неУПЛ"
          }).subscribe({
            next: result => {
              let findedGroup = result.find( i => i.group?.name?.toLowerCase() === 'все неупл');
              if (findedGroup)
                this.communicationItem.campaign.groupId = findedGroup?.group?.id;
            }
          })
          break;

      }

    if (isExceptionChange)
      switch (this.exceptionAudience) {

        case 'all':
          delete this.communicationItem.campaign.exceptSegmentId;
          delete this.communicationItem.campaign.exceptGroupIds;
          break;

        case 'group':
          delete this.communicationItem.campaign.exceptSegmentId;
          this.communicationItem.campaign.exceptGroupIds = this.communicationItem.campaign.exceptGroupIds || [];
          break;
        case 'segment':
          delete this.communicationItem.campaign.exceptGroupIds;
          break;
      }

    this.recalcAudience()

  }

  public mechanicFilterForComponent = (mechanic) => {

    if (!mechanic)
      return false;

    if ( mechanic.$isCurrent)
      return  true;

    return ( mechanic.status === 'template' && angular.isUndefined(mechanic.campaignId) );
  };

  public recalcAudience() {

    setTimeout(() => {

      if ( typeof this.subscribers?.campaignTargetInfo?.unsubscribe === "function" ) {
        this.subscribers?.campaignTargetInfo?.unsubscribe();
      }

      this.subscribers.campaignTargetInfo =
        this.communicationCampaignService.campaignTargetInfo$({
          includeSegmentId: this.communicationItem?.campaign?.segmentId || undefined,
          includeGroupId: this.communicationItem?.campaign?.groupId || undefined,
          excludeSegmentId: this.communicationItem?.campaign?.exceptSegmentId || undefined,
          excludeGroupIds: this.communicationItem?.campaign?.exceptGroupIds || undefined
        })
          .pipe(
            this.loader.waitLoading('campaignTargetInfo')
          )
          .subscribe({
          next: result => {
            this.targetGroupInfo = result;
          }
        })

    })

  }

  public recalcCampaignTime() {

      return;

  }

  public recalcCouponUntil() {

  }

  public onChangePartner() {
    this.locationGroupsId = undefined;
    if (this.communicationItem) {
      this.communicationItem.location = [];
    }

  }


  protected readonly unescape = unescape;
}


import {AccountApiModule} from "src/app/api/AccountApi/account-api.module";
import {BrandApiModule} from "src/app/api/BrandApi/brand-api.module";
import {CampaignCategoryMatrixApiModule} from "src/app/api/CampaignCategoryMatrixApi/campaign-category-matrix-api.module";
import {CampaignCategoryApiModule} from "src/app/api/CampaignCategoryApi/campaign-category-api.module";
import {CampaignLimitationApiModule} from "src/app/api/CampaignLimitationApi/campaign-limitation-api.module";
import {CampaignApiModule} from "src/app/api/CampaignApi/campaign-api.module";
import {CardApiModule} from "src/app/api/CardApi/card-api.module";
import {CategoryfullpathApiModule} from "src/app/api/CategoryfullpathApi/categoryfullpath-api.module";
import {CategoryApiModule} from "src/app/api/CategoryApi/category-api.module";
import {ClientCategoryApiModule} from "src/app/api/ClientCategoryApi/client-category-api.module";
import {ComponentApiModule} from "src/app/api/ComponentApi/component-api.module";
import {CouponRuleApiModule} from "src/app/api/CouponRuleApi/coupon-rule-api.module";
import {DashboardApiModule} from "src/app/api/DashboardApi/dashboard-api.module";
import {GoodGroupLinkApiModule} from "src/app/api/GoodGroupLinkApi/good-group-link-api.module";
import {GoodGroupApiModule} from "src/app/api/GoodGroupApi/good-group-api.module";
import {GoodApiModule} from "src/app/api/GoodApi/good-api.module";
import {GroupApiModule} from "src/app/api/GroupApi/group-api.module";
import {HashtagDictionaryApiModule} from "src/app/api/HashtagDictionaryApi/hashtag-dictionary-api.module";
import {InformingTemplateApiModule} from "src/app/api/InformingTemplateApi/informing-template-api.module";
import {CommunicationCampaignApiModule} from "src/app/api/CommunicationCampaignApi/communication-campaign-api.module";
import {InternalMethodsApiModule} from "src/app/api/InternalMethodsApi/internal-methods-api.module";
import {MechanicApiModule} from "src/app/api/MechanicApi/mechanic-api.module";
import {ProcessingProxyApiModule} from "src/app/api/ProcessingProxyApi/processing-proxy-api.module";
import {SegmentationApiModule} from "src/app/api/SegmentationApi/segmentation-api.module";
import {TriggersApiModule} from "src/app/api/TriggersApi/triggers-api.module";
import {UserApiModule} from "src/app/api/UserApi/user-api.module";
import {GoodGroupLinkBrandsApiModule} from "src/app/api/GoodGroupLinkBrandsApi/good-group-link-brands-api.module";
import {GoodGroupLinkCategoriesApiModule} from "src/app/api/GoodGroupLinkCategoriesApi/good-group-link-categories-api.module";
import {ClientTransactionsApiModule} from "src/app/api/ClientTransactionsApi/client-transactions-api.module";
import {GroupExportSettingsApiModule} from "src/app/api/GroupExportSettingsApi/group-export-settings-api.module";
import {ExternalTaskApiModule} from "src/app/api/ExternalTaskApi/external-task-api.module";
import {MechanicCategoryApiModule} from "src/app/api/MechanicCategoryApi/mechanic-category-api.module";
import {PartnerApiModule} from "src/app/api/PartnerApi/partner-api.module";
import {BrandGoodLinkApiModule} from "src/app/api/BrandGoodLinkApi/brand-good-link-api.module";
import {ClientApiModule} from "src/app/api/ClientApi/client-api.module";
import {FrontendSettingsApiModule} from "src/app/api/FrontendSettingsApi/frontend-settings-api.module";
import {EditionApiModule} from "src/app/api/EditionApi/edition-api.module";
import {GiftCertificateApiModule} from "src/app/api/GiftCertificateApi/gift-certificate-api.module";
import {TransactionApiModule} from "src/app/api/TransactionApi/transaction-api.module";
import {CouponApiModule} from "src/app/api/CouponApi/coupon-api.module";
import {NewsletterApiModule} from "src/app/api/NewsletterApi/newsletter-api.module";
import {LocationGroupApiModule} from "src/app/api/LocationGroupApi/location-group-api.module";
import {LocationApiModule} from "src/app/api/LocationApi/location-api.module";
import {LocationGroupLinkApiModule} from "src/app/api/LocationGroupLinkApi/location-group-link-api.module";
import {FileUploadApiModule} from "src/app/api/FileUploadApi/file-upload-api.module";
import {CampaignCommonApiModule} from "src/app/api/CampaignCommonApi/campaign-common-api.module";
import {ProfileTemplateApiModule} from "src/app/api/ProfileTemplateApi/profile-template-api.module";
import {ClientTemplateApiModule} from "src/app/api/ClientTemplateApi/client-template-api.module";
import {CreditAccountModule} from "src/app/api/CreditAccount/credit-account.module";
import {SearchCheckApiModule} from "src/app/api/SearchCheckApi/search-check-api.module";
import {SocialApiModule} from "src/app/api/SocialApi/social-api.module";
import {AddressApiModule} from "src/app/api/AddressApi/address-api.module";
import {PromotionApiModule} from "src/app/api/PromotionApi/promotion-api.module";
import {PreferenceApiModule} from "src/app/api/PreferenceApi/preference-api.module";
import {CommunicationApiModule} from "src/app/api/CommunicationApi/communication-api.module";
import {BehaviorApiModule} from "src/app/api/BehaviorApi/behavior-api.module";
import {DibsListMechanicApiModule} from "src/app/api/DibsListMechanicApi/dibs-list-mechanic-api.module";
import {CountersApiModule} from "src/app/api/CountersApi/counters-api.module";
import {ClientChangelogApiModule} from "src/app/api/ClientChangelogApi/client-changelog-api.module";
import {FavoriteGoodsByPriorityApiModule} from "src/app/api/FavoriteGoodsByPriorityApi/favorite-goods-by-priority-api.module";
import {AccPastYearApiModule} from "src/app/api/AccPastYearApi/acc-past-year-api.module";
import {FavoriteGoodsMechanicApiModule} from "src/app/api/FavoriteGoodsMechanicApi/favorite-goods-mechanic-api.module";
import {FavoriteGoodsProfileApiModule} from "src/app/api/FavoriteGoodsProfileApi/favorite-goods-profile-api.module";
import {RewardWithAmountApiModule} from "src/app/api/RewardWithAmountApi/reward-with-amount-api.module";
import {RewardWithAmountAfterDiscountsApiModule} from "src/app/api/RewardWithAmountAfterDiscountsApi/reward-with-amount-after-discounts-api.module";
import {AccPastMonthInformingApiModule} from "src/app/api/AccPastMonthInformingApi/acc-past-month-informing-api.module";
import {ClientWalletApiModule} from "src/app/api/ClientWalletApi/client-wallet-api.module";
import {LevelByChecksPluginApiModule} from "src/app/api/LevelByChecksPluginApi/level-by-checks-plugin-api.module";
import {HousewarmingApiModule} from "src/app/api/HousewarmingApi/housewarming-api.module";
import {AccumulationsForBonusesModule} from "src/app/api/AccumulationsForBonuses/accumulations-for-bonuses.module";
import {ThresholdPriceApiModule} from "src/app/api/ThresholdPriceApi/threshold-price-api.module";
import {MechanicPriceListApiModule} from "src/app/api/MechanicPriceListApi/mechanic-price-list-api.module";
import {DiscountOnPurchaseApiModule} from "src/app/api/DiscountOnPurchaseApi/discount-on-purchase-api.module";
import {ReferrerApiModule} from "src/app/api/ReferrerApi/referrer-api.module";
import {ReportSkuDiscountsApiModule} from "src/app/api/ReportSkuDiscountsApi/report-sku-discounts-api.module";


export const API_SERVICE_MODULES = [
     AccountApiModule ,
     BrandApiModule ,
     CampaignCategoryMatrixApiModule ,
     CampaignCategoryApiModule ,
     CampaignLimitationApiModule ,
     CampaignApiModule ,
     CardApiModule ,
     CategoryfullpathApiModule ,
     CategoryApiModule ,
     ClientCategoryApiModule ,
     ComponentApiModule ,
     CouponRuleApiModule ,
     DashboardApiModule ,
     GoodGroupLinkApiModule ,
     GoodGroupApiModule ,
     GoodApiModule ,
     GroupApiModule ,
     HashtagDictionaryApiModule ,
     InformingTemplateApiModule ,
     CommunicationCampaignApiModule ,
     InternalMethodsApiModule ,
     MechanicApiModule ,
     ProcessingProxyApiModule ,
     SegmentationApiModule ,
     TriggersApiModule ,
     UserApiModule ,
     GoodGroupLinkBrandsApiModule ,
     GoodGroupLinkCategoriesApiModule ,
     ClientTransactionsApiModule ,
     GroupExportSettingsApiModule ,
     ExternalTaskApiModule ,
     MechanicCategoryApiModule ,
     PartnerApiModule ,
     BrandGoodLinkApiModule ,
     ClientApiModule ,
     FrontendSettingsApiModule ,
     EditionApiModule ,
     GiftCertificateApiModule ,
     TransactionApiModule ,
     CouponApiModule ,
     NewsletterApiModule ,
     LocationGroupApiModule ,
     LocationApiModule ,
     LocationGroupLinkApiModule ,
     FileUploadApiModule ,
     CampaignCommonApiModule ,
     ProfileTemplateApiModule ,
     ClientTemplateApiModule ,
     CreditAccountModule ,
     SearchCheckApiModule ,
     SocialApiModule ,
     AddressApiModule ,
     PromotionApiModule ,
     PreferenceApiModule ,
     CommunicationApiModule ,
     BehaviorApiModule ,
     DibsListMechanicApiModule ,
     CountersApiModule ,
     ClientChangelogApiModule ,
     FavoriteGoodsByPriorityApiModule ,
     AccPastYearApiModule ,
     FavoriteGoodsMechanicApiModule ,
     FavoriteGoodsProfileApiModule ,
     RewardWithAmountApiModule ,
     RewardWithAmountAfterDiscountsApiModule ,
     AccPastMonthInformingApiModule ,
     ClientWalletApiModule ,
     LevelByChecksPluginApiModule ,
     HousewarmingApiModule ,
     AccumulationsForBonusesModule ,
     ThresholdPriceApiModule ,
     MechanicPriceListApiModule ,
     DiscountOnPurchaseApiModule ,
     AccumulationsForBonusesModule ,
     ThresholdPriceApiModule ,
     MechanicPriceListApiModule ,
     ReferrerApiModule ,
     ReportSkuDiscountsApiModule ,

];

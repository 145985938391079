import {
  Directive,
  ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy,
  OnInit, Output, SimpleChanges,
} from "@angular/core";
import {ngCurrencyL10nService} from "../../service/ngCurrencyL10n/ngCurrencyL10n.service";
import {NgModel} from "@angular/forms";

export enum GoodDimensionTypes {
  piece = 'piece',
  weight = 'weight'
}

@Directive({
  selector: '[ngGoodDimensionName]',
  providers: [ngCurrencyL10nService]
})
export class NgGoodDimensionNameDirective implements OnChanges {

  @Input() ngGoodDimensionName: string;

  constructor(
    private el: ElementRef,
  ) {
  }

  ngOnChanges( changes:SimpleChanges ) {

    if ( changes['ngGoodDimensionName'] ) {
      this.el.nativeElement.innerText =
          changes['ngGoodDimensionName'].currentValue === GoodDimensionTypes.piece ? 'шт.' :
            changes['ngGoodDimensionName'].currentValue === GoodDimensionTypes.weight ? 'мерн.' : '';
    }

  }

}

@Directive({
  selector: '[ngGoodDimension]',
  providers: [ngCurrencyL10nService]
})
export class NgGoodDimensionDirective implements OnDestroy {

  @Input() ngGoodDimension: string;
  @Output() ngGoodDimensionChange = new EventEmitter<string>();

  private subscriber: any;

  private isFirstChange = true;

  constructor(
    private el: ElementRef,
    private ngModel: NgModel
  ) {

    this.subscriber =
      this?.ngModel?.valueChanges?.subscribe( (value) => {
        if ( this.isFirstChange ) {

          if (this.ngGoodDimension === "weight" && /^-?[0-9]+$/.test(value) ) {
            this.el.nativeElement.value = this?.ngModel?.value + '.0';
            this.isFirstChange = false;
            return;
          }

          this.isFirstChange = false;
        }
        this.check( value );
      });

  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
   this.check(value);
  }

  check(value) {
    if ( /^-?[0-9]+\.[0-9]+$/.test(value) || /^-?[0-9]+\.[0-9]+$/.test(value) ) {
      this.ngGoodDimension = 'weight';
    } else {
      this.ngGoodDimension = 'piece';
    }
    this.ngGoodDimensionChange.emit( this.ngGoodDimension );
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }
}

import {EventEmitter, Injectable} from "@angular/core";
import {ProcessingService} from "./processing.service";
import {CheckItemClass} from '../class/check-item.class'
import {Observable, of, Subject} from "rxjs";
import {CheckDiscountClass} from "../class/check-discount.class";
import {CheckBonusClass} from "../class/check-bonus.class";
import {CheckGiftClass} from "../class/check-gift.class";
import {debounceTime, switchMap} from "rxjs/operators";

type CashBoxEvent = {
  type: CashBoxEvents;
  value: any;
};

export enum CashBoxEvents {
  CREATE,
  RESET,

  CHANGE_ITEMS,

  CHANGE_PARTNER,
  CHANGE_MERCHANT,

  PRECHECK_START,
  PRECHECK_END,

  PAYMENTTRY_START,
  PAYMENTTRY_END,

  CHECK_CONFIRM_START,
  CHECK_CONFIRM_END,

  RECALCULATE
}

export enum CheckState {
  precheck,
  paymenttry,
  checkconfirm
}

@Injectable()
export class CashBoxService {

  public currentCheck: CheckItemClass;
  public discount: CheckDiscountClass;
  public bonus: CheckBonusClass;
  public gift: CheckGiftClass

  public events: Observable<CashBoxEvent>;
  private _eventsSubject = new Subject<CashBoxEvent>();

  private variables = {
    partnerId: undefined,
    isProcessing: false,
    updateDebounce: new Subject<any>()
  }

  private unsubscribers = {
    precheck: undefined
  };

  public state: CheckState;

  get partnerId() {
    return this.variables.partnerId;
  }

  set partnerId(value: string | number) {

    if (!value || (!!value && this.variables?.partnerId?.toString() === value?.toString() ))
      return;

    this.variables.partnerId = value.toString();
    this.currentCheck.items = [];

    this._eventsSubject.next({
      type:CashBoxEvents.CHANGE_PARTNER,
      value: this.variables.partnerId
    });
  }

  get merchantId() {
    return this.currentCheck.merchant;
  }

  set merchantId(value: string | number) {
    this.currentCheck.merchant = value?.toString() || undefined;
    this._eventsSubject.next({
      type:CashBoxEvents.CHANGE_MERCHANT,
      value: this.currentCheck
    });
  }

  get items() {
    if ( !this.currentCheck || !Array.isArray(this.currentCheck?.items) )
      return undefined;

    return this.currentCheck.items;
  }

  get totalForPay() {
    return ( this.currentCheck?.total - this.discount?.totalUsedDiscount() - this?.bonus?.usedBonusesInCurrency ) < 0 ? 0 :
          this.currentCheck?.total - this.discount?.totalUsedDiscount() - this?.bonus?.usedBonusesInCurrency;
  }

  get total() {
    return parseFloat(this.currentCheck?.total) || 0;
  }

  get isProcessing() {
    return !!this.variables?.isProcessing;
  }

  constructor(
    private processingService: ProcessingService
  ) {

    this.events = this._eventsSubject.asObservable();

    this.processingService.setAuth(
      'ukm@servplus.ru',
      'e5e9fa1ba31ecd1ae84f75caaa474f3a663f05f4',
      'ac7bb72d7419d4ac8533b25a8f5e9a89b69800ae'
    )

    this.subscribeOnEvents();

    this.discount = new CheckDiscountClass(this);
    this.discount.useDiscount(true);

    this.bonus = new CheckBonusClass(this);
    this.gift = new CheckGiftClass(this);

  }

  private async precheck() {

    if (!this.currentCheck?.validate())
      return await this.currentCheck.json();

    this._eventsSubject.next({
      type:CashBoxEvents.PRECHECK_START,
      value: this.currentCheck
    });

    return  new Promise( async (resolve, reject) => {

      this.unsubscribers?.precheck?.unsubscribe();

      this.unsubscribers.precheck = this.processingService
        .preCheck$( await this.currentCheck.json() )
        .subscribe(r => {
          this.currentCheck = new CheckItemClass(r, this._eventsSubject);
          this._eventsSubject.next({
            type: CashBoxEvents.PRECHECK_END,
            value: this.currentCheck
          });
          resolve();
        }, err => {
          err?.stopPopupError();
          resolve();
        });

    });
  }

  private async peymenttry() {

    if (!this.currentCheck.validate())
      return await this.currentCheck.json();

    this._eventsSubject.next({
      type:CashBoxEvents.PAYMENTTRY_START,
      value: this.currentCheck
    });

    return await this.processingService
      .paymentTry$( await this.currentCheck.json() )
      .toPromise()
      .then( async (r) => {
        this.currentCheck = new CheckItemClass(r, this._eventsSubject);

        this._eventsSubject.next({
          type: CashBoxEvents.PAYMENTTRY_END,
          value: this.currentCheck
        });

      }, err =>{
       // err?.stopPopupError();
      });
  }

  private async checkconfirm() {

    if (!this.currentCheck.validate())
      return await this.currentCheck.json();

    this._eventsSubject.next({
      type:CashBoxEvents.CHECK_CONFIRM_START,
      value: this.currentCheck
    });

    let checkClone = new CheckItemClass(
      JSON.parse(await this.currentCheck.json())
      , this._eventsSubject);

    return await this.processingService
      .checkConfirm$( await this.currentCheck.json() )
      .toPromise()
      .then(r => {

        this._eventsSubject.next({
          type: CashBoxEvents.CHECK_CONFIRM_END,
          value: checkClone
        });

      }, err =>{
        // err?.stopPopupError();
      });
  }

  private subscribeOnEvents() {
    this.events.subscribe( even => {

      const checkStates = {
        [CashBoxEvents.PRECHECK_END]: CheckState.precheck,
        [CashBoxEvents.PAYMENTTRY_END]: CheckState.paymenttry,
        [CashBoxEvents.CHECK_CONFIRM_END]: CheckState.checkconfirm,
      }

      if (checkStates.hasOwnProperty(even.type)) {
        this.state = checkStates[even.type];
      }

      if ( [
            CashBoxEvents.CREATE,
            CashBoxEvents.CHANGE_ITEMS,
            CashBoxEvents.CHANGE_PARTNER,
            CashBoxEvents.CHANGE_MERCHANT
          ].includes(even.type)
      ) {
        this.updateCheck();
      }


    })

    this.variables.updateDebounce
      .pipe(
        debounceTime(300)
      ).subscribe( async () => {
        this.variables.isProcessing = true;
        await this.precheck();
        this.variables.isProcessing = false;
      });

  }

  public async updateCheck() {

    this.variables.updateDebounce.next({});


  }

  async createNewCheck(defaultCheck = {}) {
    this.currentCheck = new CheckItemClass(defaultCheck, this._eventsSubject);
    this._eventsSubject.next({
      type: CashBoxEvents.CREATE,
      value: this.currentCheck
    });
  }

  public addItems( items: any[] ) {
    const maxId = () => this.currentCheck?.items?.reduce((acc, i) => acc < i.id? i.id : acc, 0) || 0;

    this.currentCheck.items = this.currentCheck.items || [];

    items.forEach(item =>{
      const price = Math.round( 100 + Math.random() * 400 );
      this.currentCheck.items.push( {
        "id": maxId() + 1,
        "sku": item?.sku || item?.id,
        "quantity": 1,
        "price": price,
        "weight": item?.dimension,
        "total": price
      });
    })

    this._eventsSubject.next({
      type:CashBoxEvents.CHANGE_ITEMS,
      value: this.currentCheck
    });

  }

  public removeItems( items: any[] ) {

    if (!items)
      return;

    if (!Array.isArray(items)) {
      items = [items];
    }

    items.forEach(item => {
      this.currentCheck.items.splice(this.currentCheck.items.indexOf(item), 1);
    })

    this._eventsSubject.next({
      type:CashBoxEvents.CHANGE_ITEMS,
      value: this.currentCheck
    });

    this.reCalculate();

  }

  public calcTotalForItem(item) {
    let total = parseFloat(item?.price) * item?.quantity
    if (!total)
      return 0;

    total = this.discount.calcTotalForItem(item, total);
    total = this.bonus.calcTotalForItem(item, total);

    return total < 0  ? 0 : total;
  }

  public async addClient(clientId: number | false, cardNumbers?: string[], phoneNumber?: string) {

    if (!clientId) {
      this.currentCheck.cardNumber = null;
      this.currentCheck.client = null;
      await this.reCalculate();
      return true;
    }

    if ( Array.isArray(cardNumbers) && cardNumbers.length > 0 ) {
      this.currentCheck.cardNumber = cardNumbers?.[0];
      this.currentCheck.client = null;
      await this.reCalculate();
      return true;
    }

    if (phoneNumber) {
      this.currentCheck.phoneNumber = phoneNumber;
      this.currentCheck.client = null;
      await this.reCalculate();
      return true;
    }

    return true;

  }

  public async reCalculate() {

    await this.currentCheck?.recalc();

    this._eventsSubject.next({
      type: CashBoxEvents.RECALCULATE,
      value: this.currentCheck
    });

    await this.updateCheck();

  }

  async reset(withCreate = false) {

    await this.currentCheck.reset();

    if ( !!withCreate ) {
      await this.createNewCheck(this.currentCheck.getCheck());
    } else {
      this._eventsSubject.next({
        type:CashBoxEvents.RESET,
        value: this.currentCheck
      });
    }

  }

  async process() {

    this.variables.isProcessing = true;

    try {

      await this.precheck();
      await this.peymenttry();
      await this.checkconfirm();

      await this.reset();

    } catch (error) {
      await this.reset();
    }

    this.variables.isProcessing = false;

  }

  destroy() {
    this._eventsSubject.complete();
  }


}


import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {CouponService} from "../../../../../shared/service/api/Coupon/Coupon.service";
import {CKEditorBuilder} from "class/CKEditorBuilder/CKEditorBuilder";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";

@Component({
  selector: 'coupon-edit',
  templateUrl: './coupon-edit.component.html',
  providers: [
    CouponService,
    CouponRuleService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CouponEditComponent implements OnInit, OnChanges {

  @Input() coupon: any;
  @Input() clientItem: any;
  @Input() clientId: any;

  couponRuleList = [];
  couponRuleItem;
  dummy;

  hasClient = false;

  public settingsObj: any = {};
  public ckeBuilder = new CKEditorBuilder()
    .setMonospace()
    .addRowColumnCounter();

  public ckeOptions = {
    filterHtml: undefined,
    getCountFields: undefined,
    getCountChars: undefined,
    maxDescriptionValidator: undefined,
    optionsDescription: undefined,
  }


  constructor(
    private couponService: CouponService,
    private couponRuleService: CouponRuleService,
  ) {
  }

  ngOnInit() {
    this.buildCkeBuilder();
    this.getRulesList();
  }

  buildCkeBuilder() {
    this.ckeOptions.filterHtml     = this.ckeBuilder.filterHtml.bind(this.ckeBuilder);
    this.ckeOptions.getCountFields = this.ckeBuilder.getCountFields.bind(this.ckeBuilder);
    this.ckeOptions.getCountChars  = this.ckeBuilder.getCountChars.bind(this.ckeBuilder);
    this.ckeOptions.maxDescriptionValidator = this.ckeBuilder.getMaxlenValidator(2000);
    this.ckeOptions.optionsDescription = this.ckeBuilder.getConfig();
  }

  ngOnChanges(changes:SimpleChanges) {

    if (changes['coupon']) {
      this.coupon.issueDate = this.coupon.issueDate || new Date();
    }

    if (changes['clientId']) {
      if (this.coupon && !!parseInt(this.clientId)) {
        this.coupon.clientId = parseInt(this.clientId);
      }
      this.hasClient = !!this.clientId;
    }

  }

  get isRuleDateExpired() {

    if (!this?.coupon?.couponRuleId)
      return false;

    const couponRuleId = this.couponRuleList.find( i => i.id == this.coupon.couponRuleId);

    if (
        !couponRuleId?.rule?.couponRule?.issueSettings?.issueUntil
      )
      return false;

    let tmp = new Date( Date.parse(couponRuleId.rule.couponRule.issueSettings.issueUntil));
    tmp.setDate(tmp.getDate() + 1)
    return  tmp < new Date();

  };

  customCkeValidator = ($event)=> {
    // ckeOptions.maxDescriptionValidator
    return true;
  }

  getRulesList() {

    this.couponRuleService.query$({
      filter: {
        states: ['active'],
        issuingType: ['manual']
      },
      pager: {
        drop: 0,
        limit: 1000
      }
    })
      .subscribe({
        next: (couponRulesList) => {
          this.couponRuleList = couponRulesList;
        },
        error: (error) => {
          console.error(error);
        }
      })
  }

}

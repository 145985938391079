import {Component, OnInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter} from "@angular/core";
import {CashBoxService} from "../../service/cash-box.service";

@Component({
  selector: 'cash-box-items-row',
  templateUrl: "./cash-box-items-row.component.html",
  styleUrls: ['./cash-box-items-row.component.scss'],
  providers: [
  ]
})
 export class CashBoxItemsRowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public item: any = {};
  @Input() public index: any;
  @Input() public label: string;

  @Output() public onDelete = new EventEmitter<any>();

  @Input() showAdditionalInfo = false;
  @Output() showAdditionalInfoChange = new EventEmitter<any>();
  public editable = false;
  public priceEditable = false;
  public showedCollisions = false;


  private storage = {
    initQuantity: 0,
    initPrice: 0
  }

  constructor(
    public  cashBoxService : CashBoxService,
  )
  {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {

  }

  hasAdditionalInfo() {
    return !!this.item?.results;
  }

  switchAdditionalInfo() {
    this.showAdditionalInfo =!this.showAdditionalInfo;
    this.showAdditionalInfoChange.emit(this.showAdditionalInfo);

    if (this.showAdditionalInfo) {
      this.showedCollisions = false;
    }
  }

  getItemName(item) {
    return this.label || item?.name || item?.sku || '';
  }

  switchEditable() {
    if (!this.editable) {
      this.storage.initQuantity = this.item.quantity;
    }

    this.editable = !this.editable;
  }

  countDown() {
    if (this.item.quantity > 1) {
      this.item.quantity--;
      this.cashBoxService.reCalculate();
    }
  }

  countAdd() {
    this.item.quantity++;
    this.cashBoxService.reCalculate();
  }

  commitChangeQuantity() {
    if (this.item.quantity > 0 && this.getWeightPattern()?.test(this.item?.quantity?.toString() ) ) {
      this.editable = false;
      this.cashBoxService.reCalculate();
    }
  }

  getWeightPattern() {
    const weightPattern = /^\d{1,10}((\.|\,)\d{1,3})?$/;
    const piecePattern = /^\d{1,10}$/;

    return ( this.item && this.item.weight === 'weight' ) ? weightPattern : piecePattern
  }

  switchPriceEditable() {
    this.priceEditable = !this.priceEditable;
  }

  commitChangePrice() {

    if (this.item.price > 0 &&  /^\d{1,10}((\.|\,)\d{1,2})?$/.test(this.item?.price?.toString() ) ) {
      this.priceEditable = false;
      this.cashBoxService.reCalculate();
    }

  }

  deleteItem() {
    this.onDelete.emit(this.item);
  }

  totalForPay() {
    return this.cashBoxService.calcTotalForItem(this.item);
  }

  precheckGetter = () =>  {
    return this.cashBoxService.currentCheck.getCheck();
  }

  get discountForItem() {
    return this.cashBoxService.discount.discountForItem(this.item) +
        this.cashBoxService.discount.discountOnChangeForItem(this.item);
  }

}


import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../../shared/service/api/Settings/Settings.service";
import {daysOfMonthDics, daysOfWeekDict} from "../../values/campaign.values";
import {InformingCampaignProtocol} from "../../../../../../api/CommunicationCampaignApi/models/informing-campaign-protocol";
import {InformingCampaignTargetInfo} from "../../../../../../api/CommunicationCampaignApi/models/informing-campaign-target-info";
import {
  CommunicationCampaignService
} from "../../../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.service";
import {MECHANIC_EVENTS} from "./communication-mechanic.events";

@Component({
  selector: 'communication-mechanic',
  templateUrl: './communication-mechanic.component.html',
  styleUrls: [
    `./communication-mechanic.component.scss`
  ],
  providers: [
    CommunicationCampaignService,
    LocationsDialogService,
    GroupService,
    SettingsService,
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicComponent implements OnInit, OnChanges {

  @Input() communicationItem: InformingCampaignProtocol;
  @Input() isEdit = false;

  public MECHANIC_EVENTS = MECHANIC_EVENTS;
  private nameCache = new WeakMap<any, string>();

  get mechanicItem(): any {
    return this.communicationItem.mechanic.extension;
  }

  get hasConditions() {
    return true;
  }

  constructor(
    public communicationCampaignService: CommunicationCampaignService,
  ) {
  }

  ngOnInit() {
    this.communicationItem.mechanic.mechType = "Designer2";
    this.communicationItem.mechanic.extension = this.communicationItem.mechanic.extension || {};

    this.initMechanicStructure();
  }

  initMechanicStructure() {
    this.mechanicItem.conditionList = this.mechanicItem.conditionList || [];
/*
    this.mechanicItem.rewardList = this.mechanicItem.rewardList || [];


    if ( !this.mechanicItem.rewardList?.length) {
      this.mechanicItem.rewardList.push({
          "reward": {
            "id": 1,
            "description": "Вознаграждение 1",
            "value": "0",
            "rewType": "total",
            "what": "discount",
            "measure": "money"
          },
          "onTotal": {
            "freqType": "all",
            "rewType": "total",
            "value": 0,
            "valueOver": 0,
            "id": 1
          }
      });
    }
*/

    if ( !this.mechanicItem.conditionList?.length) {
      this.addCondition();
    }

  }

  addCondition() {

    if ( this.mechanicItem.conditionList?.length > 19) {
      return
    }

    let id = this.mechanicItem.conditionList.reduce((prev, curr) => prev > curr.condition.id ? prev : curr.condition.id, 0) + 1;
    let position = this.mechanicItem.conditionList.reduce((prev, curr) => prev > curr.condition.position ? prev : curr.condition.position, 0) + 1;

    this.mechanicItem.conditionList.push({
      "condition": {
        "id": id,
        "position": position,
        "description": "Набор условий ",
        // "rewardId": 1
      },
      "eventList": [{}]
    });
  }

  addEvent(conditionItem: any) {
    conditionItem?.eventList?.push({});
  }

  deleteEvent(eventItem, conditionItem) {
    if (!conditionItem.eventList?.length)
      return;

    conditionItem.eventList = conditionItem.eventList.filter(i => i !== eventItem);

    if ( !conditionItem.eventList?.length &&  this.mechanicItem.conditionList?.length >=2) {
      this.mechanicItem.conditionList = this.mechanicItem.conditionList.filter(i => i !== conditionItem);
    } else {
      if ( !conditionItem?.eventList?.length ) {
        this.addEvent(conditionItem);
      }
    }

  }

  getEvent(eventItem) {

    if (!eventItem || Object.keys(eventItem)?.length === 0 )
      return undefined;

    return this.MECHANIC_EVENTS.filter(i =>
      i.categoryId === eventItem.event.categoryId &&
      eventItem[i?.eventField]?.eventType === i.id
    )?.[0] || undefined;

  }

  setEvent(value, eventItem) {

    eventItem.event = {
      description: '',
      categoryId: value.categoryId
    };

    Object.keys(eventItem)
          .filter(i => i !== 'event')
          .forEach(i => {
            if ( !eventItem?.fieldsForSave?.includes(i) )
              delete eventItem[i];
          });

    eventItem[value.eventField] = eventItem[value.eventField] || {};
    eventItem[value.eventField].eventType = value.id

  }


  getNameEvent(eventItem) {
    if (!this.nameCache.has(eventItem)) {
      this.nameCache.set(eventItem, 'MechItem _ ' + Math.random().toString(36).substr(2, 9) );
    }

    return this.nameCache.get(eventItem);
  }

  ngOnChanges(changes) {

  }

}

<div class="pos-abt bg-light dker text-center scrollable mini-scroll cursor"
     style="top: 0px; left: 0px; right: 0px; min-height: 100%; z-index: 2; font-family: 'Courier New' "
     (click)="reset()"
     *ngIf="!!finalCheck"
     data-testid="cashbox.finalCheck.container"
>

  <div class="text-left inline m-t-sm bg-white box-shadow-lg padding"
       style="   z-index: 2; width: 310px;  "
  >
    <div class="padding">

      <div class=" text-center h3">
        ЧЕК НА ПРОДАЖУ
      </div>

      <div class="clearfix">
        <span class="pull-left" data-testid="finalCheck.pos">Терминал № {{ finalCheck.pos }}</span>
        <span class="pull-right">{{ finalCheck.date }}</span>
      </div>

      <div class="clearfix">
        <span class="pull-left" data-testid="finalCheck.number">Чек {{ finalCheck.number }}</span>
      </div>

      <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

      <div class="clearfix" *ngFor="let item of finalCheck.items"
           data-testid="cashbox.finalCheck.items.row"
      >
          <span class="w-full" data-testid="finalCheck.item">{{item?.sku || ''}} {{ item?.$name || ''}} {{ item?.quantity || '' }}*{{ item?.price || '' }}
            <span class="pull-right">{{item.total  | loyaCurrency}}</span>
          </span>
      </div>

      <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

      <div class="clearfix">
        <span class="pull-right" data-testid="finalCheck.items.length">ТОВАРОВ В ЧЕКЕ: {{ finalCheck?.items?.length || 0 | loyaNumber}}</span>
      </div>
      <div class="clearfix">
        <span class="pull-right" data-testid="finalCheck.total">К ОПЛАТЕ: {{ finalCheck.total  | loyaCurrency}}</span>
      </div>

      <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

      <div class="clearfix text-right">
        <span class="h4 font-normal" data-testid="totalResult">ИТОГО К ОПЛАТЕ ={{ totalResult  | loyaCurrency}}</span>
      </div>

      <div class="clearfix text-right">
        <span class=" h5" data-testid="totalResultCash">НАЛИЧНЫЕ ={{ totalResult  | loyaCurrency}}</span>
      </div>

      <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

      <div class="clearfix" *ngIf="finalCheck.client">
        <span class="pull-left h5" data-testid="finalCheck.client">{{ finalCheck?.client?.surname || '' }} {{ finalCheck?.client?.name || '' }} {{ finalCheck?.client?.patronymic || '' }}</span>
      </div>

      <div class="clearfix" *ngIf="finalCheck.client">
        <span class="pull-left">Номер счёта</span>
        <span class="pull-right" data-testid="finalCheck.client.account.number">{{ finalCheck?.client?.account?.number || '' }}</span>
      </div>

      <div class="clearfix m-b-md" *ngIf="!!finalCheck?.client?.cardNumber">
        <span class="pull-left">Номер карты</span>
        <span class="pull-right" data-testid="finalCheck.client.cardNumber">{{ finalCheck?.client?.cardNumber }}</span>
      </div>



      <div class="clearfix" *ngIf="finalCheck.awards">
        <span class="pull-left">Дополнительно начислено: </span>
        <span class="pull-right" data-testid="finalCheck.awards.awardBonus">{{ finalCheck.awards?.awardBonus || '0' }}</span>
      </div>

      <div class="m-b-sm">

        <ng-container
          *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '' }"
        >
          <div class="clearfix m-t-sm"
               *ngIf="finalCheck?.client?.account && (finalCheck?.client?.account?.balance || finalCheck?.client?.account?.appliedAward || finalCheck?.client?.account?.active )"
          >
            <span class="pull-left">Aктивные бонусы: </span>
          </div>

          <div class="clearfix"
               *ngIf="!!finalCheck?.client?.account?.balance"
          >
            <span class="pull-left m-l-sm">Начальный баланс: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.balanceInCurrency">{{ finalCheck?.client?.account?.balanceInCurrency || 0 }}</span>
          </div>

          <div class="clearfix"  *ngIf="!!finalCheck?.client?.account?.appliedAward">
            <span class="pull-left m-l-sm">Начислено: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.appliedAwardInCurrency">{{ finalCheck?.client?.account?.appliedAwardInCurrency || 0 }}</span>
          </div>

          <div class="clearfix"  *ngIf="!!finalCheck?.client?.account?.active">
            <span class="pull-left m-l-sm">Итоговый баланс: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.activeInCurrency">{{ finalCheck?.client?.account?.activeInCurrency || 0}}</span>
          </div>

          <div class="clearfix m-t-sm"  *ngIf="finalCheck?.client?.account && (finalCheck?.client?.account?.balanceInactive || finalCheck?.client?.account?.appliedInactiveAward ||  finalCheck?.client?.account?.inactive)">
            <span class="pull-left">Неактивные бонусы: </span>
          </div>

          <div class="clearfix"  *ngIf="finalCheck?.client?.account?.balanceInactive">
            <span class="pull-left m-l-sm">Начальный баланс: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.balanceInactiveInCurrency">{{ finalCheck?.client?.account?.balanceInactiveInCurrency }}</span>
          </div>

          <div class="clearfix"  *ngIf="finalCheck?.client?.account?.appliedInactiveAward">
            <span class="pull-left m-l-sm">Начислено: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.appliedInactiveAwardInCurrency">{{ finalCheck?.client?.account?.appliedInactiveAwardInCurrency || 0 }}</span>
          </div>

          <div class="clearfix"  *ngIf="finalCheck?.client?.account?.inactive">
            <span class="pull-left m-l-sm">Итоговый баланс: </span>
            <span class="pull-right" data-testid="finalCheck.client.account.inactiveInCurrency">{{ finalCheck?.client?.account?.inactiveInCurrency || 0 }}</span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="!!finalCheck?.informing?.length">

        <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

        <ng-container *ngFor="let inform of finalCheck.informing('check')">
          <div>
            <pre class="no-bg no-border no-padder mini-scroll" [innerHTML]="inform.info"></pre>
          </div>
        </ng-container>

      </div>

      <div *ngIf="coupons.eCoupon" class="m-b-sm">

        <ng-container *ngFor="let coupon of coupons.eCoupon" >
          <div *ngIf="coupon.ctype === 'award' "
               data-testid="cashbox.coupons.award"
          >

            <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

            <div class="text-left clearfix">Вам выдан купон <span class="pull-right" data-testid="coupon.barcode">{{coupon.barcode }}</span></div>
            <div class="text-left clearfix m-l-sm" data-testid="coupon.name">{{ coupon.name }}</div>
            <div class="text-left clearfix m-l-sm m-r-sm">
              <pre class="no-bg no-border no-padder mini-scroll">{{ coupon.desc }}</pre>
            </div>

            <div class="text-left clearfix m-l-sm" data-testid="coupon.from">Дата начала {{coupon.from }}</div>
            <div class="text-left clearfix m-l-sm" data-testid="coupon.till">Дата окончания {{coupon.till }}</div>

          </div>
        </ng-container>

      </div>


      <div class="line line-dashed b-b line-lg pull-in  m-b-sm" *ngIf="finalCheck.informing.length>0"></div>

      <div class=" text-center">
        СПАСИБО ЗА ПОКУПКУ!
      </div>
    </div>
  </div>

  <ng-container *ngFor="let coupon of coupons?.couponWithBarcode || [] ">
   <div class="text-left inline m-t-sm bg-white box-shadow-lg padding"
       *ngIf="coupon.ctype === 'award' "
       style=" z-index: 2; width: 310px; "
       (click)="$event.stopPropagation()"
        data-testid="cashbox.finalCheck.coupons-award.row"
  >
    <div class="padding">

      <div class=" text-center h3">
        КУПОН
      </div>

      <div class="m-b-sm">

        <div class="" >

          <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

          <div class="text-left clearfix">Вам выдан купон <span class="pull-right" data-testid="coupon.barcode">{{coupon?.barcode }}</span></div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.name">{{ coupon?.name }}</div>
          <div class="text-left clearfix m-l-sm m-r-sm">
            <pre class="no-bg no-border no-padder mini-scroll" data-testid="coupon.desc">{{ coupon?.desc }}</pre>
          </div>

          <div class="text-left clearfix m-l-sm" data-testid="coupon.from">Дата начала {{coupon?.from }}</div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.till">Дата окончания {{coupon?.till }}</div>

          <div class="text-center m-t">
            <barcode-view [number]="coupon.barcode" [options]="barcodeOptions"></barcode-view>
          </div>

        </div>

      </div>


      <div class="line line-dashed b-b line-lg pull-in  m-b-sm" *ngIf="finalCheck.informing.length>0"></div>

      <div class=" text-center">
        СПАСИБО ЗА ПОКУПКУ!
      </div>
    </div>
  </div>
  </ng-container>
  <ng-container
    *ngFor="let coupon of coupons?.couponRuleBarcode ">

    <div class="text-left inline m-t-sm bg-white box-shadow-lg padding"
       *ngIf="coupon.ctype === 'award' "
       style=" z-index: 2; width: 310px; "
       (click)="$event.stopPropagation()"
  >
    <div class="padding">

      <div class=" text-center h3">
        КУПОН
      </div>

      <div class="m-b-sm">

        <div class="" >

          <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

          <div class="text-left clearfix">Вам выдан купон <span class="pull-right" data-testid="coupon.barcode">{{coupon.barcode }}</span></div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.name">{{ coupon.name }}</div>
          <div class="text-left clearfix m-l-sm m-r-sm" data-testid="coupon.desc">
            <pre class="no-bg no-border no-padder mini-scroll">{{ coupon.desc }}</pre>
          </div>

          <div class="text-left clearfix m-l-sm" data-testid="coupon.from">Дата начала {{coupon.from }}</div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.till">Дата окончания {{coupon.till }}</div>

          <div class="text-center m-t">
            <barcode-view [number]="coupon.barcode" [options]="barcodeOptions"></barcode-view>
          </div>

        </div>

      </div>


      <div class="line line-dashed b-b line-lg pull-in  m-b-sm" *ngIf="finalCheck.informing.length>0"></div>

      <div class=" text-center">
        СПАСИБО ЗА ПОКУПКУ!
      </div>
    </div>
  </div>

  </ng-container>

  <ng-container
    *ngFor="let coupon of coupons?.couponWithoutBarcode ">
    <div class="text-left inline m-t-sm bg-white box-shadow-lg padding"
       *ngIf=" coupons.ctype === 'award'"

       style=" z-index: 2; width: 310px; "
       (click)="$event.stopPropagation()"
  >
    <div class="padding">

      <div class=" text-center h3">
        КУПОН
      </div>

      <div class="m-b-sm">

        <div class="" >

          <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

          <div class="text-left clearfix">Вам выдан купон </div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.name">{{ coupon.name }}</div>
          <div class="text-left clearfix m-l-sm m-r-sm">
            <pre class="no-bg no-border no-padder mini-scroll">{{ coupon.desc }}</pre>
          </div>

          <div class="text-left clearfix m-l-sm" data-testid="coupon.from">Дата начала {{coupon.from }}</div>
          <div class="text-left clearfix m-l-sm" data-testid="coupon.till">Дата окончания {{coupon.till }}</div>

        </div>

      </div>


      <div class="line line-dashed b-b line-lg pull-in  m-b-sm" *ngIf="finalCheck?.informing?.length>0"></div>

      <div class=" text-center">
        СПАСИБО ЗА ПОКУПКУ!
      </div>
    </div>
  </div>
  </ng-container>

  <div class="text-left inline m-t-sm bg-white box-shadow-lg padding"
       *ngIf=" coupons.virtualRuleBarcode"
       style=" z-index: 2; width: 310px; "
       ng-click="$event.stopPropagation()"
  >
    <div class="padding">

      <div class=" text-center h3">
        Выдача
      </div>

      <div *ngFor="let item of groupVirtualRule(coupons.virtualRuleBarcode) | keyvalue" class="row">

        <div class="line line-dashed b-b line-lg pull-in m-t-sm m-b-sm"></div>

        <div class="text-left clearfix " data-testid="coupon.name">Выдать {{ item.value.name }} <span class="pull-right" data-testid="coupon.count"> {{ item.value.$count }} шт.</span></div>
        <div class="text-left clearfix m-l-sm m-r-sm" data-testid="coupon.desc">
          <pre class="no-bg no-border no-padder mini-scroll">{{ item.value.desc }}</pre>
        </div>

      </div>

      <div class=" text-center">

        <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

        СПАСИБО ЗА ПОКУПКУ!
      </div>
    </div>
  </div>

  <div class="cleafix m-t-sm "></div>

</div>

import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {CouponRuleService} from "../../../../shared/service/api/CouponRule/CouponRule.service";
import {CouponRuleSearchResult} from "../../../../api/CouponRuleApi/models/coupon-rule-search-result";
import {
  COUPON_RULE_STATE_NAMES,
  COUPON_RULE_STATES
} from "../../../../shared/service/api/CouponRule/CouponRule.values";

import './coupons-rules-index-page.legacy'
import {ChannelTypeNames} from "../../../../shared/service/api/Newsletter/Newsletter.variables";
import {CAMPAIGN_STATES} from "../../../../shared/service/api/Campaign/Campaign.values";
import {COUPON_ISSUING_TYPES_NAMES, COUPON_KIND_TYPES_NAMES} from "../../../../shared/service/api/Coupon/Coupon.values";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";

@Component({
  selector: 'coupons-rules-index-page',
  templateUrl: './coupons-rules-index-page.component.html',
  providers:[
    CouponRuleService
  ]
})
export class CouponsRulesIndexPageComponent implements OnInit{

  searchString: string;
  listCouponRules: Array<CouponRuleSearchResult> =[]
  rolesList = {};

  public loader = new LoaderHandlerClass();

  public COUPON_RULE_STATES = COUPON_RULE_STATES;
  public COUPON_RULE_STATE_NAMES = COUPON_RULE_STATE_NAMES;
  public COUPON_KIND_TYPES_NAMES = COUPON_KIND_TYPES_NAMES;
  public COUPON_ISSUING_TYPES_NAMES = COUPON_ISSUING_TYPES_NAMES;

  constructor(
    public couponRuleService: CouponRuleService
  ) {
  }

  ngOnInit() {
    this.couponRuleService.queryParams.setFilterValue('states',
      Object.values(COUPON_RULE_STATES)
        .filter( i => i !== COUPON_RULE_STATES.archive)
    );
    this.couponRuleService.queryParams.setFilterValue(
      'couponKind',
      Object.keys(COUPON_KIND_TYPES_NAMES)
    );
    this.couponRuleService.queryParams.setFilterValue(
      'issuingType',
      Object.keys(COUPON_ISSUING_TYPES_NAMES)
    );

    this.couponRuleService.queryParams.reset();
    this.getList(this.couponRuleService.queryParams.params);
  }

  getList(params) {
    this.couponRuleService.query$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.listCouponRules = this.listCouponRules.concat(result);
      });
  }


  onScroll() {

    if ( this.loader.isLoading('list') )
      return;

    this.couponRuleService.queryParams.next();
    this.getList(this.couponRuleService.queryParams.params)
  }

  reset() {
    this.couponRuleService.queryParams.reset();
    this.listCouponRules=[];
    this.getList(this.couponRuleService.queryParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.couponRuleService.queryParams.search(this.searchString);
    this.reset();
  }

  changeFilter( $event ) {
    this.couponRuleService.queryParams.setFilterValue( $event.target.name, $event.target.value);
    this.reset();

  }

  protected readonly ChannelTypeNames = ChannelTypeNames;
}

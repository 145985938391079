<ng-select [(ngModel)]="id"
           [items]="filteredItems"
           [virtualScroll]="true"
           [loading]="loading"
           bindLabel="name"
           bindValue="id"
           name="selectCampaignComponent"
           data-testid="campaignGroups.input"
           [placeholder]="placeholder"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (search) = "onSearch($event)"
           (change) = "onChange()"
           (clear) = "clear()"
           (close) = "clear()"
           [disabled]="disabled"
           [required]="required"
           [class.ng-invalid]="!isValid || (isEmpty && required)"

>

  <ng-template ng-label-tmp let-item="item">

    <div class="hbox hbox-auto">

      <div class="col">

       <span [ngSwitch]="item.state">
             <span *ngSwitchCase="'draft'">      <i
               class="fa fa-rocket text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'suspended'">  <i
               class="fa fa-rocket text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'unsuspend'">  <i
               class="fa fa-rocket text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'planned'">    <i
               class="fa fa-rocket text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'started'">    <i
               class="fa fa-rocket text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'active'">     <i
               class="fa fa-rocket text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'completed'">  <i
               class="fa fa-rocket text-info m-r-xs"></i>     </span>
             <span *ngSwitchCase="'archive'">    <i
               class="fa fa-rocket text-danger m-r-xs"></i>   </span>
        </span>

      </div>

      <div class="col w-full">
        <div class="clearfix">
          {{ item.name }}

          <div class="d-inline-block m-l-sm text-muted f-s-8">
            ( <span [innerHTML]="item.startDate | loyaDate"></span> -
            <span [innerHTML]="item.stopDate | loyaDate"></span> )
          </div>

        </div>



      </div>

    </div>

  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index">

    <div class="hbox hbox-auto">

      <div class="col">

       <span [ngSwitch]="item.state">
             <span *ngSwitchCase="'draft'">      <i
               class="fa fa-rocket text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'suspended'">  <i
               class="fa fa-rocket text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'unsuspend'">  <i
               class="fa fa-rocket text-primary m-r-xs"></i>  </span>
             <span *ngSwitchCase="'planned'">    <i
               class="fa fa-rocket text-warning m-r-xs"></i>  </span>
             <span *ngSwitchCase="'started'">    <i
               class="fa fa-rocket text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'active'">     <i
               class="fa fa-rocket text-success m-r-xs"></i>  </span>
             <span *ngSwitchCase="'completed'">  <i
               class="fa fa-rocket text-info m-r-xs"></i>     </span>
             <span *ngSwitchCase="'archive'">    <i
               class="fa fa-rocket text-danger m-r-xs"></i>   </span>
        </span>

      </div>

      <div class="col w-full">
        <div class="clearfix">
          <search-highlight
            [model]="lastSearchText || ''"
            [text]="item.name"
          ></search-highlight>
        </div>

        <div class="clearfix text-muted f-s-8">
          ( <span [innerHTML]="item.startDate | loyaDate"></span> -
          <span [innerHTML]="item.stopDate | loyaDate"></span> )

          <span ng-if="!!showDescription">{{item.description}}</span>
        </div>

      </div>

    </div>

  </ng-template>
</ng-select>

<input type="hidden" [required]="required" [name]="'selectCampaignDummy'" [(ngModel)]="id">

<div *ngIf="showArchiveBlock"
     class=" m-t-sm f-s-8 "
>
  <label class="i-checks i-checks-sm ">
    <input type="checkbox"
           [(ngModel)]="showArchive"
           (ngModelChange)="changeShowArchive()"
    >
    <i></i>
    Включая архивные кампании
  </label>

</div>

import {CashBoxEvents, CashBoxService} from "../service/cash-box.service";


export class CheckBonusClass {

  private enabled: boolean = false;

  constructor(
    private cashBoxService: CashBoxService
  ) {
    this.subscribeOnEvents();
  }

  get allowBonuses() {
    return !!this.cashBoxService?.currentCheck?.awards?.allowBonus ?
      parseFloat(this.cashBoxService?.currentCheck.awards.allowBonus) : 0;
  };

  get allowBonusesInCurrency(){
    return !!this.cashBoxService?.currentCheck?.awards?.allowBonusInCurrency ?
      parseFloat(this.cashBoxService?.currentCheck?.awards?.allowBonusInCurrency) : 0;
  };

  get usedBonuses() {

    if (!this.cashBoxService?.currentCheck?.items?.length)
      return 0;

    return this.cashBoxService?.currentCheck?.items?.reduce((acc, item) => {
      if (!item?.usedAwards?.usedBonusInCurrency)
        return acc;

      if (item?.usedAwards?.usedBonusInCurrency === item?.awards?.allowBonusInCurrency) {
        return acc + item?.awards?.allowBonus;
      }

      return acc;
    }, 0)
  };

  get usedBonusesInCurrency(){
    if (!this.cashBoxService?.currentCheck?.items?.length)
      return 0;

    return this.cashBoxService?.currentCheck?.items?.reduce((acc, item) =>
      acc + (parseFloat(item?.usedAwards?.usedBonusInCurrency) || 0 ) , 0)

  };

  private subscribeOnEvents() {
    this.cashBoxService?.events?.subscribe(event => {

      if ([
        CashBoxEvents.RECALCULATE,
        CashBoxEvents.CREATE,
        CashBoxEvents.CHANGE_ITEMS,
        CashBoxEvents.PRECHECK_END,
      ].indexOf(event.type) >=0) {
        this.recalcBonus();
      }

    });
  }

  private recalcBonus() {
    if (this.enabled) {
      this.useBonus(true);
    }
  }

  public async useBonus(flag) {

    this.enabled = !!flag;

    if (flag) {

      this.cashBoxService?.currentCheck?.items?.forEach(item => {

        if (!item?.awards?.allowBonusInCurrency)
          return;

        item.usedAwards = item.usedAwards || {};
        item.usedAwards.usedBonusInCurrency = item.awards.allowBonusInCurrency;

      });

    } else {

      this.cashBoxService?.currentCheck?.items?.forEach(item => {
        if ( item?.usedAwards?.usedBonusInCurrency) {
          delete item?.usedAwards?.usedBonusInCurrency;
        }
      });

    }


  }

  public calcTotalForItem(item, total) {
    return total;
  }

}

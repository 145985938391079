'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import ckeditor_loader from 'agModules/ckeditor';

let routePath   = module.name + '.coupons.rules.view';

export default
module
  .config(
    ['routeBuilderProvider', (routeBuilderProvider : any) => {

      routeBuilderProvider
        .route( routePath )
        .url('/:id')

        .template(  '<coupons-rules-view-page [id]="id"></coupons-rules-view-page>', true )
        .controller(['$scope', '$stateParams', function($scope, $stateParams) { $scope.id = $stateParams.id; } ])
        .resolve('$ckePrepare', ckeditor_loader)

        .permissions( {
          only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager, 'TopManager'],
        })

        .breadcrumbs(["Купоны", "Купонные правила", "Инфо о правиле"])

    }])


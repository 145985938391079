<div class="card m-l m-r m-t">
  <div class="card-body"
       id="anchorI1"
  >
    <div class="d-flex">

      <div class="col-auto padder-sm">
        <i class="fa fa-rocket fa-2x"
           [ngClass]=" 'text-' + COUPON_RULE_STATE_COLORS[couponRuleItem?.couponRule?.state]"
        ></i>
      </div>

      <div class="col">

        <h4 class=" overflow-wrap-break m-b-none" data-testid="cRule.name">
          {{ couponRuleItem?.couponRule?.name || '' }}
        </h4>

        <div class="small m-b-xs">

          <span [innerText]="COUPON_RULE_STATE_NAMES[couponRuleItem?.couponRule?.state] || couponRuleItem?.couponRule?.state"
                [ngClass]=" 'text-' + COUPON_RULE_STATE_COLORS[couponRuleItem?.couponRule?.state]"
                data-testid="cRule.state"></span>
        </div>

        <div class="line line-dashed b-b line-lg pull-in  m-t-sm m-b-xs"></div>

        <div class="text-muted text-small overflow-wrap-break" data-testid="cRule.description">
          {{ couponRuleItem?.couponRule?.description || '' }}
        </div>

      </div>

      <div class="col-auto  no-padder text-right font-thin text-muted text-small"
           data-testid="cRule.id"
           *ngIf="couponRuleItem?.couponRule?.id>=0"
      >
          ID: <span class="">{{ couponRuleItem?.couponRule?.id }}</span>
      </div>

    </div>
  </div>
</div>

<div class="card m-l m-r m-t">
  <div class="card-body">

    <div class="d-flex">

      <div class="col-auto padder-sm">
        <i class="fa fa-lg fa-barcode text-success"></i>
      </div>

      <div class="col">

        <h5 class=" overflow-wrap-break m-b-sm"
            data-testid="cRule.coupon.name"
        >
          {{ couponRuleItem?.couponRule?.issueSettings?.couponName || '' }}
        </h5>

        <div class="text-muted text-small overflow-wrap-break">
          <pre class="w-full mini-scroll"
               [innerText]="couponRuleItem?.couponRule?.issueSettings?.couponDescription"
               data-testid="cRule.coupon.description"></pre>
        </div>

      </div>

    </div>

    <div class="d-flex"
         *ngIf="!!couponRuleItem?.warning"
         data-testid="cRule.warning">

      <div class="col-auto padder-sm">
        <i class="fa fa-warning  fa-lg  "
           [ngClass]="{' text-danger':couponRuleItem?.warning?.alarmLevel==='red', ' text-warning':couponRuleItem?.warning?.alarmLevel==='yellow' }"
        ></i>
      </div>
      <div class="col">

        <div class="text-md m-b-xs">
            Внимание
        </div>

        <div class="overflow-wrap-break">
            Количество купонов подходит к максимуму ({{couponRuleItem?.warning?.couponCount}}/{{couponRuleItem?.warning?.maxCoupon}})
        </div>

      </div>


    </div>
  </div>
</div>
<div class=" w-full p-l p-r">
  <div class="row campaign-column-row"
       id="anchorI2"
  >
    <div class="col col-campaign-column">

      <div class="card">
        <div class="card-body p-t-none p-b-none">

          <campaign-row-view
            name="Группа кампаний"
          >
            <i class="fa fa-spin fa-spinner text-warning"
               *ngIf="loader.isLoading('campaignCategory')"
            ></i>
            <span *ngIf="!loader.isLoading('campaignCategory')"
                  data-testid="cRule.campaignGroup"
                  [innerText]="getCampaignCategoryById(couponRuleItem?.couponRule?.categoryId)?.name || ''"
            ></span>
          </campaign-row-view>

          <campaign-row-view
            name="Тип выдачи купонов"
          >
            <span
              data-testid="cRule.issuingType"
              [innerText]="COUPON_ISSUING_TYPES_NAMES[couponRuleItem?.couponRule?.issueSettings?.issuingType] || couponRuleItem?.couponRule?.issueSettings?.issuingType || '' "
            ></span>
          </campaign-row-view>

          <campaign-row-view
            name="Тип купона"
          >
            <span
              data-testid="cRule.couponType"
              [innerText]="COUPON_KIND_TYPES_NAMES[couponRuleItem?.couponRule?.issueSettings?.couponKind] || couponRuleItem?.couponRule?.issueSettings?.couponKind || '' "
            ></span>
          </campaign-row-view>

          <campaign-row-view
            *ngIf="currentCouponKind.hasPrefix && !!couponRuleItem?.couponRule?.prefix"
            name="Префикс"
          >
            <span
              data-testid="cRule.prefix"
              [innerText]="couponRuleItem?.couponRule?.prefix || '' "
            ></span>
          </campaign-row-view>

          <campaign-row-view
            *ngIf="currentCouponKind.hasGenereteBarcode || currentCouponKind.hasBarcode"
            name="Штрихкод"
          >
            <span
              data-testid="cRule.barcode"
              [innerText]="couponRuleItem?.couponRule?.barcode || '' "
            ></span>
          </campaign-row-view>

          <campaign-row-view
            [name]="isStartScheduled ? 'Время после выдачи' : 'Начало действия купонов' "
            *ngIf="!couponRuleItem?.couponRule?.issueSettings?.customPeriod && couponRuleItem?.couponRule?.issueSettings?.startPeriod && !!currentIssueType.getStartPeriod().length"
          >
             <span [ngSwitch]="couponRuleItem?.couponRule?.issueSettings?.startPeriod" data-testid="cRule.startPeriod">
                <span *ngSwitchCase=" StartPeriodTypes.START_PERIOD_FORM_ISSUE ">с даты выдачи</span>
                <span *ngSwitchCase=" StartPeriodTypes.START_PERIOD_FORM_DATE ">с
                  {{ couponRuleItem?.couponRule?.issueSettings?.startDate | loyaDate }}
                </span>
                <span *ngSwitchCase=" StartPeriodTypes.START_PERIOD_SCHEDULED ">через
                    {{ couponRuleItem?.couponRule?.issueSettings?.startScheduled?.value }}
                    {{ timeWords(couponRuleItem?.couponRule?.issueSettings?.startScheduled?.dimension,couponRuleItem?.couponRule?.issueSettings?.startScheduled?.value) }}
                </span>
             </span>

          </campaign-row-view>

          <campaign-row-view
            [name]="isStopScheduled ? 'Период действия, включая дату начала действия купонов' : 'Окончание действия купонов' "
            *ngIf="!couponRuleItem?.couponRule?.issueSettings?.customPeriod && couponRuleItem?.couponRule?.issueSettings?.stopPeriod && !!currentIssueType.getStopPeriod().length"
          >
            <span [ngSwitch]="couponRuleItem?.couponRule?.issueSettings?.stopPeriod" data-testid="cRule.stopPeriod">

              <span *ngSwitchCase=" StopPeriodTypes.STOP_PERIOD_FORM_DATE ">
                  по {{couponRuleItem?.couponRule?.issueSettings?.stopDate | loyaDate}} включительно
                </span>

                <span *ngSwitchCase=" StartPeriodTypes.START_PERIOD_SCHEDULED ">
                  {{ couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.value }}
                  {{ timeWords(couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.dimension,couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.value) }}
                </span>

             </span>

          </campaign-row-view>

          <campaign-row-view
            [name]="'Тип периода действия' "
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.customPeriod"
          >
            <span [innerText]="CUSTOM_PERIOD_NAMES[!!couponRuleItem?.couponRule?.issueSettings?.customPeriod]" data-testid="cRule.periodTime"></span>
          </campaign-row-view>

          <campaign-row-view
            [name]="'Период действия' "
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.customPeriodType"
          >
            {{ CUSTOM_PERIOD_TYPE_NAMES[couponRuleItem?.couponRule?.issueSettings?.customPeriodType] || couponRuleItem?.couponRule?.issueSettings?.customPeriodType }}

            <span *ngIf="couponRuleItem?.couponRule?.issueSettings?.customPeriodType === CustomPeriodTypes.CLIENT_BIRTHDAY" class="pull-right text-right">

              <span [innerText]="couponRuleItem?.couponRule?.issueSettings?.customValue1"
                    data-testid="cRule.customPeriodType.before"></span> дня до ДР
              <br/>
              <span [innerText]="couponRuleItem?.couponRule?.issueSettings?.customValue2"
                    data-testid="cRule.customPeriodType.after"></span> дня после ДР

            </span>

          </campaign-row-view>

          <campaign-row-view
            [name]="'Дата окончания выдачи купонов' "
          >
            <span [innerText]="couponRuleItem?.couponRule?.issueSettings?.issueUntil | loyaDate"></span>
          </campaign-row-view>

          <campaign-row-view
            [name]="'Выдавать' "
            *ngIf="currentCouponKind.hasSameClientCount"
          >
            не больше
            <span [innerText]="couponRuleItem?.couponRule?.issueSettings?.sameClientCount" data-testid="cRule.sameClientCount"></span>
            раз
          </campaign-row-view>

          <campaign-row-view
            [name]="'Количество купонов' "
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.generatedCount"
          >
            <span [innerText]="couponRuleItem?.couponRule?.issueSettings?.generatedCount" data-testid="cRule.generatedCount"></span>
            шт
          </campaign-row-view>

          <campaign-row-view
            [name]="'Передача 3-им лицам' "
            *ngIf="currentCouponKind.hasUsable3rdParties && !usable3rdPartiesDisabled"
          >
            <span [innerText]="couponRuleItem?.couponRule?.usingSettings?.usable3rdParties ? 'возможна' : 'невозможна' "> </span>
          </campaign-row-view>

        </div>
      </div>

    </div>

    <div class="col col-campaign-column">
      <div class="card">
        <div class="card-body p-t-none p-b-none">

          <campaign-row-view
            name="Продажа"
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.canBeSold"
          >

             <ng-container *ngIf="couponRuleItem?.couponRule?.issueSettings?.priceInCurrency"
             >
                 {{couponRuleItem?.couponRule?.issueSettings?.priceInCurrency}}
                  <span ngCurrencyNameLoya></span>
            </ng-container>

            <ng-container  *ngIf="couponRuleItem?.couponRule?.issueSettings?.priceInBonus"
            >
              {{couponRuleItem?.couponRule?.issueSettings?.priceInBonus}} бонус(ов)
            </ng-container>

          </campaign-row-view>

          <campaign-row-view
            name="Параметры приёма"
          >
            <span [innerText]="COUPON_APPLY_NAMES[couponRuleItem?.couponRule?.applySettings?.couponApply] || couponRuleItem?.couponRule?.applySettings?.couponApply" data-testid="cRule.couponApply"></span>
          </campaign-row-view>

          <campaign-row-view
            name="Мин. вознаграждение"
            *ngIf="couponRuleItem?.couponRule?.applySettings?.couponApply==='applyInFirstSuitedCheck' && couponRuleItem?.couponRule?.applySettings?.minRewardInCurrency !== undefined"
          >
            {{couponRuleItem?.couponRule?.applySettings?.minRewardInCurrency}} <span ngCurrencyNameLoya></span>
          </campaign-row-view>

          <campaign-row-view
            name="Дни приёма купона"
          >

            <div class="f-s-9 text-nowrap" style="white-space:nowrap" data-testid="cRule.couponApply.days">
              <i class="fa fa-circle  m-r-xs"
                 *ngFor="let day of VALID_DAYS"
                 data-testid="day in daysOfWeek"
                 [ngClass]="{
                    'text-muted': couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf(day)<0 ,
                    'text-info': couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf(day)>=0 }"
                 [ngbPopover]="VALID_DAYS_NAMES[day]"
                 [triggers]="'mouseenter:mouseleave'"
                 [container]="'body'"
              >
              </i>
            </div>

          </campaign-row-view>

          <campaign-row-view
            name="Время приёма купона"
          >
            c
            <span [innerText]="couponRuleItem?.couponRule?.applySettings?.validTimeFrom | loyaTime" data-testid="cRule.timeFrom"></span>
            по
            <span [innerText]="couponRuleItem?.couponRule?.applySettings?.validTimeTill | loyaTime" data-testid="cRule.timeTill"></span>
          </campaign-row-view>

          <campaign-row-view
            name="Локации"
            *ngIf="!!couponRuleItem?.locationGroups?.length "
          >

              <span class="clearfix"
                    *ngFor="let loc of couponRuleItem?.locationGroups"
                    data-testid="cRule.loc in locationGroups"
                    [innerText]="loc.name"></span>

          </campaign-row-view>

          <campaign-row-view
            name="Приём в точках продаж"
            *ngIf="!!couponRuleItem?.locations?.length "
          >
                <span class="clearfix"
                      *ngFor="let loc of couponRuleItem?.locations"
                      data-testid="cRule.loc in locations"
                      [innerText]="loc.name"></span>


          </campaign-row-view>

          <campaign-row-view
            name="Количество использований"
            *ngIf="notUndefined(couponRuleItem?.couponRule?.usingSettings?.useCount) && useCountEnabled"
          >
            {{ couponRuleItem?.couponRule?.usingSettings?.useCount }}
          </campaign-row-view>

          <campaign-row-view
            name="Ограничение приёма по сумме"
            *ngIf="notUndefined(couponRuleItem?.couponRule?.applySettings?.applyEvery) && showApplyEvery"
          >
            <span data-testid="cRule.applyEvery">
               <span >на каждые</span>
               {{couponRuleItem?.couponRule?.applySettings?.applyEvery || 0}}
               <span ngCurrencyNameLoya></span>
            </span>

            <div class="clearfix text-right "
                 *ngIf="couponRuleItem?.couponRule?.applySettings?.applyEvery > 0 && couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType"
            >

              Товары участвующие в ограничении:

              <span
                *ngIf=" !!couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList?.length"
                (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList,
                     forPartner:couponRuleItem?.couponRule?.partnerId,
                     listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType
                  })"
                >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

              <span
                *ngIf=" !!couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList?.length"
                (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList,
                     forPartner:couponRuleItem?.couponRule?.partnerId,
                     listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType
                  })"
                >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

            </div>

            <div class="clearfix text-right "
                 *ngIf="couponRuleItem?.couponRule?.applySettings?.applyEvery > 0 && !!couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType"
            >
              Товары исключенные из ограничения:

              <span
                *ngIf=" !!couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList?.length "
                (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList,
                     forPartner:couponRuleItem?.couponRule?.partnerId,
                     listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType
                  })"
                >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

              <span
                *ngIf=" !!couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList?.length "
                (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList,
                     forPartner:couponRuleItem?.couponRule?.partnerId,
                     listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType
                  })"
              >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

            </div>

          </campaign-row-view>


          <campaign-row-view
            name="Ограничение приёма в чеке"
            *ngIf="notUndefined(couponRuleItem?.couponRule?.applySettings?.maxRewardValue) && showMaxRewardValues"
          >

            <span data-testid="cRule.maxRewardLimit">
              {{ MAX_REWARD_LIMIT_SUBTYPES_NAMES[maxRewardLimitValues.type] || '' }}
              {{ couponRuleItem?.couponRule?.applySettings?.maxRewardValue }}
              {{ MAX_REWARD_LIMIT_MEASURE_VALUES[maxRewardLimitValues.type + maxRewardLimitValues.measure] || '' }}
            </span>

          </campaign-row-view>

          <campaign-row-view
            name="Мин. сумма чека"
            *ngIf="notUndefined(couponRuleItem?.couponRule?.applySettings?.minCheckTotal)"
          >

            <span data-testid="cRule.minCheckTotal">
                {{ couponRuleItem?.couponRule?.applySettings?.minCheckTotal }}
            </span>

          </campaign-row-view>



        </div>
      </div>
    </div>
  </div>
</div>

<div class="card  m-l m-r m-t"
     *ngIf="isCouponGeneratorRule"
>

  <div class="card-header"  id="anchorI3">
    Тиражи
  </div>
  <div class="card-body">

    <circulation-coupon-rule
      *ngIf="couponRuleItem?.couponRule?.state === 'active' || couponRuleItem?.couponRule?.state === 'archive' "
      [ruleId]="couponRuleItem?.couponRule?.id"
      [ruleState]="couponRuleItem?.couponRule?.state"
    ></circulation-coupon-rule>

    <div class=" text-center "
         *ngIf="couponRuleItem?.couponRule?.state === 'draft'"
    >
      <h5 class="text-muted f-s-12  ">Генерация купонов возможна после запуска купонного правила</h5>
    </div>

  </div>
</div>

<div class="card  m-l m-r m-t">
  <div class="card-header"  id="anchorI4">
    Вознаграждение
  </div>
  <div class="card-body">

      <div class="padder-md "
           *ngIf="currentCouponKind.hasReferalReward"
           data-testid="cRule.hasReferalReward"
      >

        <div class="row m-b-none">
          <div class="m-l-lg">

            Реферу выдаётся бонус

            <span>
              в
              <span>
                размере
                <span class="font-bold m-r-xxs" [innerHtml]="couponRuleItem.couponRule.reward.referalProgramData.referReward.value"></span>
              </span>

              <span class="font-bold m-l-xs"
                    *ngIf="couponRuleItem.couponRule.reward.referalProgramData.referReward.measure !== REWARD_MEASURES_TYPES.MONEY"
                    [innerHtml]="REWARD_MEASURES_TYPES_VALUES[couponRuleItem.couponRule.reward.referalProgramData.referReward.measure]"></span>
              <span class="font-bold m-l-xs"
                    *ngIf="couponRuleItem.couponRule.reward.referalProgramData.referReward.measure === REWARD_MEASURES_TYPES.MONEY"
                    ngCurrencyNameLoya
              ></span>
            </span>


          </div>

        </div>

        <div class="row m-b-none">
          <div class="m-l-lg">
            Вознаграждение реферала
            <span class="font-bold" [innerHtml]="couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal ? 'Включено' : 'Выключено'"></span>
          </div>
        </div>

      </div>

      <div  *ngIf="currentCouponKind.hasReferalReward && couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal" data-testid="cRule.referralReward.title">

        <div class=" h4 f-s-12   ">
          <i class="fa fa-gift text-success-lter m-l-xs m-r-xs inline"></i>
          Вознаграждение реферала
        </div>

      </div>

    <ng-container *ngIf="!(currentCouponKind.hasReferalReward && !couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal)">
          <div class=" m-l-lg   " *ngIf="currentCouponKind.hasRewardWhat" data-testid="cRule.referralReward">
            <div class="m-l-xs">

              Выдаётся

              <span [ngSwitch]="couponRuleItem.couponRule.reward.reward.what"
                    data-testid="cRule.reward.what"
                    class="font-bold"
              >
                <span *ngSwitchCase="'discount'">скидка</span>
                <span *ngSwitchCase="'bonus'">бонус</span>
                <span *ngSwitchCase="'gift'">подарки</span>
                <span *ngSwitchCase="'fixprice'">фиксированная цена</span>
              </span>

              <span
                class=""
                *ngIf="couponRuleItem.couponRule.reward.reward.what==='gift'"
                  (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem.couponRule.reward.giftList,
                     forPartner:couponRuleItem.couponRule.partnerId,
                     listType:'sku'
                  })"
                >
                  <i class="fa fa-eye text-success"></i>
             </span>


              <span *ngIf=" couponRuleItem.couponRule.reward.reward.what!=='gift' && !couponRuleItem.couponRule.reward.reward.fromMechanic">
                в
                <span *ngIf="couponRuleItem.couponRule.issueSettings.couponKind !== 'importedCoupon'">
                  размере
                  <span class="font-bold" [innerHtml]="couponRuleItem.couponRule.reward.reward.value" data-testid="cRule.reward.value"></span>
                </span>

                <span class="font-bold"
                     *ngIf="couponRuleItem.couponRule.reward.reward.measure !== REWARD_MEASURES_TYPES.MONEY"
                     [innerHtml]="REWARD_MEASURES_TYPES_VALUES[couponRuleItem.couponRule.reward.reward.measure]"></span>
                <span class="font-bold m-l-xs"
                      *ngIf="couponRuleItem.couponRule.reward.reward.measure === REWARD_MEASURES_TYPES.MONEY"
                      ngCurrencyNameLoya
                ></span>

                <span *ngIf=" couponRuleItem.couponRule.reward.reward.what==='fixprice'"
                      data-testid="cRule.reward.for.kg"
                >
                  за {{ couponRuleItem.couponRule.reward.onGood.dimension === 'weight' ? 'кг.' : 'шт.'  }}
                </span>
              </span>

              <span *ngIf="couponRuleItem.couponRule.reward.reward.fromMechanic">
                из шаблона механики
              </span>

            </div>
          </div>

          <div class=" m-l-lg font-thin  " *ngIf="couponRuleItem.couponRule.reward.reward.what">
            <div class="m-l-xs">

              <span [innerHtml]="firstLetterCapitalize(REWARD_IGNORE_LIMIT_TYPES_NAMES[couponRuleItem.couponRule.reward.reward.what])" data-testid="cRule.reward.ignoreLimits.type"></span>

              <span class="font-bold"
                    *ngIf="REWARD_IGNORE_LIMIT_TYPES_NAMES[couponRuleItem.couponRule.reward.reward.what] !== undefined " data-testid="cRule.reward.ignoreLimits">
                {{ couponRuleItem.couponRule.reward.ignoreLimits ? 'игнорируются' : 'не игнорируются' }}
              </span>

            </div>
          </div>

          <div class="m-l-lg font-thin  " *ngIf="currentCouponKind.hasRewardRewType">

            <div class="m-l-xs">

              Вознаграждение даётся

             <span class="font-bold"
                   [innerHtml]="REWARD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.reward?.rewType]" data-testid="cRule.reward.rewType"></span>

              <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType == 'goods' || couponRuleItem?.couponRule?.reward?.reward?.rewType==='setOfGoods' || couponRuleItem?.couponRule?.reward?.reward?.rewType === 'position'">

               <span
                 class=""
                 *ngIf=" couponRuleItem?.couponRule?.reward?.reward?.rewType==='setOfGoods' "
                 (click)="viewGoodsPopup({
                     viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList,
                     forPartner:couponRuleItem?.couponRule?.partnerId,
                     listType:couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
                  })">
                    <i class="fa fa-eye text-success"></i>
               </span>


                <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType==='setOfGoods'">на все в чеке</span>
                <span class=""
                      *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType==='goods' || couponRuleItem?.couponRule?.reward?.reward?.rewType === 'position'">

                  <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'every'">
                    {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}
                    <span class="font-bold"
                          [innerHtml]="couponRuleItem?.couponRule?.reward?.onGood?.quantity" data-testid="cRule.reward.onGood.quantity"></span>
                    <span class="m-l-xs"
                          [innerHtml]="couponRuleItem?.couponRule?.reward?.onGood?.dimension === 'weight' ? 'мерн.' : 'шт.' " data-testid="cRule.reward.onGood.measure"></span>
                  </span>

                  <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'count' "
                        class="m-l-xs"
                  >

                       <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.itemsSorting"
                             [ngSwitch]="couponRuleItem?.couponRule?.reward?.onGood?.itemsSorting" data-testid="cRule.reward.onGood.sorting">
                        <span *ngSwitchCase="'priceAsc'">наименьшие по цене</span>
                        <span *ngSwitchCase="'priceDesc'">наибольшие по цене</span>
                      </span>

                    {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}
                    <span class="font-bold"
                          [innerHtml]="couponRuleItem?.couponRule?.reward?.onGood?.quantity" data-testid="cRule.reward.onGood.quantity"></span>
                    <span class="m-l-xs"
                          [innerHtml]="couponRuleItem?.couponRule?.reward?.onGood?.dimension === 'weight' ? 'мерн.' : 'шт.' " data-testid="cRule.reward.onGood.measure"></span>

                  </span>

                  <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'all' ">
                    {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}
                  </span>

                  <a
                    class="font-bold"
                    *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0"
                    (click)="viewGoodsPopup({
                       viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList,
                       forPartner:couponRuleItem?.couponRule?.partnerId,
                       listType:couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
                    })">
                     из списка
                   </a>

                  <a
                    class="font-bold"
                    *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0 || couponRuleItem?.couponRule?.reward?.goodList?.whiteListType === 'favourite' "
                    (click)="viewGoodsPopup({
                       viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList,
                       forPartner:couponRuleItem?.couponRule?.partnerId,
                       listType:couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
                    })"
                  >
                     из
                       <span  [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.whiteListType" data-testid="cRule.reward.goodList.whiteListType">
                          <span *ngSwitchCase="'category'">
                            категорий
                          </span>
                          <span *ngSwitchCase="'brand'">
                            брендов
                          </span>
                          <span *ngSwitchCase="'favourite'">
                            списка любимых товаров
                          </span>
                          <span *ngSwitchCase="'custom'">
                            списка товаров
                          </span>
                       </span>

                   </a>

                   <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0 || couponRuleItem?.couponRule?.reward?.goodList?.blackIdList?.length>0 || couponRuleItem?.couponRule?.reward?.goodList?.blackListType === 'favourite' ">
                     исключая
                     <a
                       class="font-bold"
                       *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0"
                       (click)="viewGoodsPopup({
                         viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList,
                         forPartner:couponRuleItem?.couponRule?.partnerId,
                         listType:couponRuleItem?.couponRule?.reward?.goodList?.blackListType
                      })">
                       товары
                     </a>

                    <a
                      class="font-bold"
                      *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.blackIdList?.length>0 || couponRuleItem?.couponRule?.reward?.goodList?.blackListType === 'favourite'"
                      (click)="viewGoodsPopup({
                         viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.blackIdList,
                         forPartner:couponRuleItem?.couponRule?.partnerId,
                         listType:couponRuleItem?.couponRule?.reward?.goodList?.blackListType
                      })">
                        <span [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.blackListType">
                          <span *ngSwitchCase="'category'">
                            категории
                          </span>
                          <span *ngSwitchCase="'brand'">
                            бренды
                          </span>
                          <span *ngSwitchCase="'favourite'">
                            любимые товары
                          </span>
                          <span *ngSwitchCase="'custom'">
                            списки товаров
                          </span>
                        </span>

                     </a>

                   </span>

                </span>

              </span>

            </div>

          </div>
    </ng-container>



  </div>
</div>


<div class="card  m-l m-r m-t"
     *ngIf="(couponRuleItem.couponRule.reward.reward.what ==='bonus' && !currentCouponKind.hasReferalReward ) || (couponRuleItem.couponRule.reward.reward.what ==='bonus' && currentCouponKind.hasReferalReward && couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal)"
>
  <div class="card-header"  id="anchorI5">
    Настройка бонусов
  </div>
  <div class="card-body">

    <bonus-mechanic-config-ng1
      [model]="couponRuleItem.couponRule.reward || {}"
      [fieldPath]="'bonusSettings'"
      [readOnly]="true"
      [configOnly]="true"
      [enabled]="(couponRuleItem.couponRule.reward.reward.what ==='bonus' && !currentCouponKind.hasReferalReward ) || (couponRuleItem.couponRule.reward.reward.what ==='bonus' && currentCouponKind.hasReferalReward && couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal)"
    ></bonus-mechanic-config-ng1>

  </div>
</div>

<div class="card  m-l m-r m-t"
     *ngIf="currentCouponKind.hasImportCoupons"
>
  <div class="card-header"  id="anchorI6">
    Импорт набора купонов
  </div>
  <div class="card-body">

    <div class="font-thin m-l-lg"
         *ngIf="currentCouponKind.canImportCoupons"
    >
      <div class="m-l-xs">

        <button class="btn m-t-xs w btn-warning btn-sm db-new btn-hide btn-addon  "
                (click)="selectFile()"
        >
          <i class="fa" [ngClass]="{' fa-warning text-danger':!hasRuleImportedCoupons, 'fa-upload text-white':hasRuleImportedCoupons}"></i>
          Импорт из файла
        </button>

        <input type="file"
               nv-file-select=""
               uploader="uploader"
               accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               class="none" id="excelUploader"/>

        <div class="clearfix m-t-sm pointer" (click)="closeFileImportResult()"
             *ngIf="fileImportResult">

          <div class="card b-a m-b-none">
            <div class="card-header bg-light pos-rlt">
              <span class="arrow arrow-light top pull-left  "></span>

              <div class="clearfix">
                <span class="font-bold m-r-xs">Добавлено купонов:</span>
                <span data-testid="fileImportResult.success"
                [innerHTML]="fileImportResult.success"></span>
              </div>

              <div class="clearfix">
                <span class="font-bold m-r-xs">Дубликаты:</span>
                <span data-testid="fileImportResult.duplicates"
                [innerHTML]="fileImportResult.duplicates"></span>
                <div class="clearfix"></div>
                <a [href]="fileImportResult.duplicatesCSV"
                   *ngIf="!!fileImportResult.duplicates"
                   (click)="$event.stopPropagation()"
                   target="_blank"
                   class="m-l-xs">
                  <i class="fa fa-external-link f-s-8"></i>
                  Скачать
                </a>
              </div>

              <div class="clearfix">
                <span class="font-bold m-r-xs">С ошибкой:</span>
                <span data-testid="fileImportResult.errors"
                [innerHTML]="fileImportResult.errors"></span>
                <div class="clearfix"></div>
                <a [href]="fileImportResult.errorsCSV"
                   *ngIf="!!fileImportResult.errors"
                   (click)="$event.stopPropagation()"
                   target="_blank"
                   class="m-l-xs">
                  <i class="fa fa-external-link f-s-8"></i>
                  Скачать
                </a>
              </div>

              <div class="clearfix">
                <span class="font-bold m-r-xs">Всего в файле:</span>
                <span data-testid ="fileImportResult.total"
                [innerHTML]="fileImportResult.total"></span>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="clearfix text-danger padder m-l-md f-s-9 m-t-xs "
         *ngIf="!hasRuleImportedCoupons && couponRuleItem.couponRule.state === 'draft'">
      *Внимание! Перед запуском купонного правила, требуется импортировать набор купонов
    </div>

    <div class="clearfix padder m-t-xs  "
         *ngIf="!!hasRuleImportedCoupons">
      <a class="m-l-md"
         [uiSref]="'frontend.coupons.coupons.index({couponRuleId: ' + couponRuleItem.couponRule.id + '})'"
         data-testid="cRule.show-coupons.link"
      >
        <i class="fa fa-eye text-success"></i>
        Посмотреть список загруженных купонов
      </a>
    </div>


  </div>
</div>

<div class="card  m-l m-r m-t"
     *ngIf="isExternalLoyalty"
>
  <div class="card-header"  id="anchorI7">
    Внешняя система лояльности
  </div>
  <div class="card-body long-descriptions">

    <div class="form-item">
      <div class="form-label">
        <i class="fa fa-gears">
        </i>
        Запретить списание бонусов на товары по этой акции во внешней системе
      </div>
      <div class="form-value">
        <div >
          <span *ngIf="!!couponRuleItem?.elSettings?.disableBonusUsed">Да</span>
          <span *ngIf="!couponRuleItem?.elSettings?.disableBonusUsed">Нет</span>
        </div>
      </div>
    </div>

    <div class="form-item">
      <div class="form-label">
        <i class="fa fa-gears">
        </i>
        Запретить начисление бонусных баллов на товары по этой акции во внешней системе
      </div>
      <div class="form-value">
        <div >
          <span *ngIf="!!couponRuleItem?.elSettings?.disableBonusAward">Да</span>
          <span *ngIf="!couponRuleItem?.elSettings?.disableBonusAward">Нет</span>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="card  m-l m-r m-t ">
  <div class="card-header"
       id="anchorImagesI8"
  >
    Прикреплённые изображения
  </div>

  <div class="card-body">

    <image-carousel-uploader
      [couponRuleId]="couponRuleItem?.campaign?.id"
    >
    </image-carousel-uploader>

  </div>
</div>

import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import {CouponRuleService} from "../../../../shared/service/api/CouponRule/CouponRule.service";

import './coupons-view-page.legacy'
import {
  COUPON_ISSUING_TYPES_NAMES,
  COUPON_KIND_TYPES_NAMES,
  COUPON_STATE_NAMES, COUPON_STATES
} from "../../../../shared/service/api/Coupon/Coupon.values";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {CouponService} from "../../../../shared/service/api/Coupon/Coupon.service";
import {ClientService} from "../../../../shared/service/api/Client/Client.service";
import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {CAMPAIGN_STATE_NAMES, CAMPAIGN_STATES_COLORS} from "../../../../shared/service/api/Campaign/Campaign.values";
import {CARD_STATE_NAMES} from "../../../../shared/service/api/Card/Card.values";
import {CardService} from "../../../../shared/service/api/Card/Card.service";
import {CardState} from "../../../../api/CardApi/models/card-state";
import {
  COUPON_KIND_NAMES,
  ISSUING_TYPE_NAMES, REWARD_MEASURES_TYPES,
  REWARD_MEASURES_TYPES_VALUES, REWARD_ONGOOD_REWTYPE_TYPES_NAMES, REWARD_REWTYPE_TYPES_NAMES
} from "../../../../shared/service/api/CouponRule/CouponRule.values";
import {ISSUING_TYPES} from "../../../../../js/pages/coupons/rules/view/issuingTypes";
import {GoodsWatcherService} from "../../../../shared/component/goods-tree/service/goods-watcher.service";
import {getNg1Service} from "../../../../shared/class/utils/angularjs.utils";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as deleteTemplate from './coupons-view-page-delete.component.html';
import {ngCurrencyL10nService} from "../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";

@Component({
  selector: 'coupons-view-delete',
  templateUrl: './coupons-view-page-delete.component.html'
})
export class CouponsViewDeletePageComponent {

  public reasonCoupon = '';

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  okEvent() {
    this.activeModal.close(this.reasonCoupon);
  }

  cancelEvent() {
    this.activeModal.dismiss();
  }
}


@Component({
  selector: 'coupons-view-page',
  templateUrl: './coupons-view-page.component.html',
  styles: [
    `
      .m-h-100 { min-height: 100%; }
    `
  ],
  providers:[
    CouponRuleService,
    CouponService,
    ClientService,
    StatesChangerService,
    GoodsWatcherService,
    ngCurrencyL10nService
  ]
})
export class CouponsViewPageComponent implements OnInit, OnChanges {

  coupon: any;
  clientItem: any;
  couponRuleItem: any;
  couponIssueInfo: any;
  cards: any;

  public loader = new LoaderHandlerClass();

  @Input() public id;
  @Input() public clientId;

  public COUPON_STATE_NAMES = COUPON_STATE_NAMES;
  public COUPON_ISSUING_TYPES_NAMES = COUPON_ISSUING_TYPES_NAMES;
  public COUPON_KIND_TYPES_NAMES = COUPON_KIND_TYPES_NAMES;
  public CARD_STATE_NAMES = CARD_STATE_NAMES;
  public ISSUING_TYPE_NAMES = ISSUING_TYPE_NAMES;
  public COUPON_KIND_NAMES = COUPON_KIND_NAMES;
  public REWARD_MEASURES_TYPES_VALUES =  Object.assign({},REWARD_MEASURES_TYPES_VALUES);
  public REWARD_REWTYPE_TYPES_NAMES = REWARD_REWTYPE_TYPES_NAMES;
  public REWARD_ONGOOD_REWTYPE_TYPES_NAMES = REWARD_ONGOOD_REWTYPE_TYPES_NAMES;

  public $state = getNg1Service('$state');

  constructor(
    public couponRuleService: CouponRuleService,
    public couponService: CouponService,
    public clientService: ClientService,
    public cardService: CardService,
    public statesChangerService: StatesChangerService,
    public goodsWatcherService: GoodsWatcherService,
    private ngbModal: NgbModal,
    public ngCurrencyL10nService : ngCurrencyL10nService,
  ) {
    ngCurrencyL10nService.getShortName().then( (fullName: string) => {
      this.REWARD_MEASURES_TYPES_VALUES[REWARD_MEASURES_TYPES.MONEY] = fullName;
    })
  }

  get canBeDeleted() {
      let data= [
        this?.coupon?.id,
        this.couponRuleItem?.couponRule?.issueSettings?.issuingType,
        this?.coupon?.state,
        this?.coupon?.couponType
      ]

      if ( !data[0] ||
           !data[1] ||
           !data[2] ||
            data[3] === 'archive'
      ) {
        return false;
      }

      return true;

  }

  ngOnInit() {
    this.bindButtons();

    this.statesChangerService.changeState(
      !!this.coupon && !this.coupon?.id ? 'edit' : 'view'
    );

  }

  bindButtons() {
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['id']) {
      this.getCoupon();
    }
  }

  getCoupon() {
    this.couponService
        .getOrCreate$(this.id)
        .pipe(
          this.loader.waitLoading('coupon')
        )
        .subscribe((result) => {
          this.coupon = result;

          if (!this.coupon?.id) {
            this.statesChangerService.changeState('edit');
          }

          if (!!this.coupon?.clientId) {
            this.getClient();
            this.getCards();
          }

          if (!!this.coupon?.couponRuleId)
            this.getCouponRule();

          if (!!this.coupon?.id)
            this.getCouponIssue();

        })
  }

  getClient() {

    if (!this.coupon?.clientId && !this.clientId) {
      this.clientItem = undefined;
      return;
    }

    this.clientService
        .getClient$(this.coupon?.clientId || this.clientId)
        .pipe(
          this.loader.waitLoading('client')
        )
        .subscribe((result) => {
          this.clientItem = result;
        })
  }

  getCards() {

    if (!this.coupon?.clientId) {
      this.cards = undefined;
      return;
    }

    this.cardService.query$({
      filterClientId: this.coupon?.clientId,
      filterStates: <any>Object.keys( CARD_STATE_NAMES )
    })
      .pipe(
        this.loader.waitLoading('cards')
       )
      .subscribe((result) => {
        this.cards = result;
      })

  }

  getCouponRule() {

    if (!this.coupon?.couponRuleId) {
      this.couponRuleItem = undefined;
      return;
    }

    this.couponRuleService.get$(
      this.coupon?.couponRuleId
    )
      .pipe(
        this.loader.waitLoading('couponRule')
      )
      .subscribe((result) => {
        this.couponRuleItem = result;

        let currentIssueType = new ISSUING_TYPES[this.couponRuleItem?.couponRule?.issueSettings?.issuingType ]( this.couponRuleItem );
        currentIssueType.changeCouponKindById(this.couponRuleItem?.couponRule?.issueSettings?.couponKind);
        this.couponRuleItem.$currentCouponKind = currentIssueType.getCurrentCouponKind();
      })

  }

  getCouponIssue() {

    this.couponService
      .getCouponIssueInfo$(this.coupon?.id)
      .pipe(
        this.loader.waitLoading('couponIssue')
      )
      .subscribe((result) => {
        this.couponIssueInfo = result;
      })

  }

  onDelete() {
    this.ngbModal
        .open(CouponsViewDeletePageComponent)
        .result
          .then(
            (reason) => {
              this.couponService
                .delete$(this.coupon?.id, { reason })
                .subscribe(
                  () => {
                    this.$state.go('^.index');
                  }
                )
          }, () => {})
  }

  onSave() {

    this.couponRuleService.generate$(
        this.coupon?.couponRuleId,
        {
        "clientId"    : this.coupon.clientId,
        "name"        : this.coupon.name,
        "description" : this.coupon.description,
        "issueDate"   :
          this.coupon.issueDate.getFullYear() + '-' +
          ('0' + (this.coupon.issueDate.getMonth() + 1 ) ).slice(-2) + '-' +
          ('0' + this.coupon.issueDate.getDate() ).slice(-2) + ' 00:00:00'

    }).subscribe((result) => {
      this.$state.go('^.view', {id: result.id}, {reload: true});
    })
  }

  onCancel() {
    this.$state.go('^.view', {id: this.coupon.id}, {reload: true});
  }

  viewGoodsPopup( options: any ) {
    this.goodsWatcherService.open({
      list: options.viewGoodsPopup,
      forPartner: options.forPartner,
      listType: options.listType,
    }).catch(() => {});
  }

  protected readonly CAMPAIGN_STATES_COLORS = CAMPAIGN_STATES_COLORS;
  protected readonly CAMPAIGN_STATE_NAMES = CAMPAIGN_STATE_NAMES;
  protected readonly COUPON_STATES = COUPON_STATES;
}

import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import {CouponRuleService} from "../../../../shared/service/api/CouponRule/CouponRule.service";
import {CouponRuleSearchResult} from "../../../../api/CouponRuleApi/models/coupon-rule-search-result";
import {
  COUPON_RULE_STATE_NAMES,
  COUPON_RULE_STATES
} from "../../../../shared/service/api/CouponRule/CouponRule.values";

import './coupons-index-page.legacy'
import {ChannelTypeNames} from "../../../../shared/service/api/Newsletter/Newsletter.variables";
import {CAMPAIGN_STATES} from "../../../../shared/service/api/Campaign/Campaign.values";
import {
  COUPON_ISSUING_TYPES_NAMES,
  COUPON_KIND_TYPES_NAMES,
  COUPON_STATE_NAMES, COUPON_STATES
} from "../../../../shared/service/api/Coupon/Coupon.values";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {CouponService} from "../../../../shared/service/api/Coupon/Coupon.service";
import {ClientService} from "../../../../shared/service/api/Client/Client.service";

@Component({
  selector: 'coupons-index-page',
  templateUrl: './coupons-index-page.component.html',
  providers:[
    CouponRuleService,
    CouponService,
    ClientService
  ]
})
export class CouponsIndexPageComponent implements OnInit, OnChanges {

  searchString: string;
  listCoupons: Array<any> =[];
  clientItem: any;
  couponRuleItem: any;

  public loader = new LoaderHandlerClass();

  @Input() public clientId;
  @Input() public couponRuleId;

  public COUPON_STATE_NAMES = COUPON_STATE_NAMES;
  public COUPON_ISSUING_TYPES_NAMES = COUPON_ISSUING_TYPES_NAMES;
  public COUPON_KIND_TYPES_NAMES = COUPON_KIND_TYPES_NAMES;

  constructor(
    public couponRuleService: CouponRuleService,
    public couponService: CouponService,
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.couponService.queryParams.setFilterValue('states',
      Object.keys(COUPON_STATE_NAMES)
        .filter(i => i!== COUPON_STATES.MANUAL_DELETED )
    );

    this.couponService.queryParams.reset();
    this.getList(this.couponService.queryParams.params);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['couponRuleId']?.currentValue) {
      this.couponRuleService.get$(this.couponRuleId)
        .pipe(
          this.loader.waitLoading('couponRule')
        )
        .subscribe(result => {
          this.couponRuleItem = result;
        });
      this.couponService.queryParams.setFilterValue('couponRuleId', changes['couponRuleId']?.currentValue )
    }

    if (changes['clientId']?.currentValue) {
      this.clientService.getClient$(this.clientId)
        .pipe(
          this.loader.waitLoading('client')
        )
        .subscribe(result => {
          this.clientItem = result;
        });

      this.couponService.queryParams.setFilterValue('clientId', changes['clientId']?.currentValue)
    }
  }

  getList(params) {
    this.couponService.query$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.listCoupons = this.listCoupons.concat(result);
      });
  }

  onScroll() {

    if ( this.loader.isLoading('list') )
      return;

    this.couponService.queryParams.next();
    this.getList(this.couponService.queryParams.params)
  }

  reset() {
    this.couponService.queryParams.reset();
    this.listCoupons=[];
    this.getList(this.couponService.queryParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.couponService.queryParams.search(this.searchString);
    this.reset();
  }

}

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren, HostListener, SimpleChanges
} from "@angular/core";
import {ObjectUtils} from "../../class/utils/object.utils";


@Component({
  selector: 'scroll-up',
  template: `
    <div class="scroll-up-table">
      <button class="btn btn-default btn-rounded scroll-up-btn"
              (click)="scrollUp()"
              [class.disabled]="!isEnabled || !this.element"
              [disabled]="!isEnabled || !this.element"
      >
        <i class="fa fa-chevron-up"></i>
      </button>
    </div>
  `,
  styleUrls: [`./scroll-up.component.scss`]

})
export  class ScrollUpComponent implements OnInit, OnChanges {

    @Input() elementSelector = '#app-container';

    public isEnabled = false;
    public element: HTMLElement;

    private checkTimerId: any;
    private initHeight: number = 0;

    constructor()
    {
    }

    @HostListener( 'window:scroll', ['$event']) // for window scroll events
    onScrollBody(event) {

      clearTimeout(this.checkTimerId);
      this.checkTimerId = setTimeout(this.checkScroll.bind(this), 100);

    }

    private checkScroll() {
      if (!this.isElementExists()) {
        return;
      }
      this.isEnabled = this.element.scrollTop + this.element.clientHeight > this.initHeight;
    }

    ngOnInit() {
      this.isElementExists();
      this.initHeight = ( this.element?.clientHeight || window?.innerHeight || 500 ) * 1.8;
      this.checkScroll();
    }

    ngOnChanges(changes:SimpleChanges) {
      if ( changes['elementSelector']) {
        this.element = document.querySelector(changes['elementSelector'].currentValue);
      }
    }

    isElementExists() {
      if (!!this.element)
        return true;

      this.element = document.querySelector(this.elementSelector);
      return!!this.element;
    }

    scrollUp() {

      if (!this.isElementExists()) {
        return;
      }

      this.element
        .scroll({
          behavior:'smooth',
          top:0,
          left:0,
        });
    }

}

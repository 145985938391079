import {BrowserModule, enableDebugTools} from '@angular/platform-browser';
import {enableProdMode, NgModule} from '@angular/core';
import {downgradeComponent, UpgradeModule} from "@angular/upgrade/static";
import "@angular/compiler"

import ModuleLoader from './../js/ag.modules/moduleLoader';

import "./upgrade";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { API_SERVICE_MODULES } from  "./api/api.modules"

import Highcharts     from 'highcharts/highcharts.src';
import HighchartsMore from 'highcharts/highcharts-more.src';
import Highcharts3d   from 'highcharts/highcharts-3d.src';



import {
  NgbAlertModule,
  NgbProgressbarModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPopoverModule,
  NgbModalModule, NgbNavModule, NgbModule
} from '@ng-bootstrap/ng-bootstrap';

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {preLoad, TranslocoRootModule} from './transloco-root.module';

// Pages

import {MechaniccategoryIndexPageComponent} from "./pages/config/directory/mechaniccategory/index/mechaniccategory-index-page.component";
import {MechaniccategoryViewPageComponent} from "./pages/config/directory/mechaniccategory/view/mechaniccategory-view-page.component";
import {UsersIndexPageComponent} from "./pages/config/directory/users/index/users-index-page.component";
import {UsersViewPageComponent} from "./pages/config/directory/users/view/users-view-page.component";
import {ClientcategoryIndexPageComponent} from "./pages/config/directory/clientcategory/index/clientcategory-index-page.component";
import {ClientcategoryViewPageComponent} from "./pages/config/directory/clientcategory/view/clientcategory-view-page.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CampaigncategoryIndexPageComponent} from "./pages/config/directory/campaigncategory/index/campaigncategory-index-page.component";
import {CampaigncategoryViewPageComponent} from "./pages/config/directory/campaigncategory/view/campaigncategory-view-page.component";

import {BrandIndexPageComponent} from "./pages/config/directory/brand/index/brand-index-page.component";
import {BrandViewPageComponent} from "./pages/config/directory/brand/view/brand-view-page.component";
import {TriggersTransactionsPageComponent} from "./pages/triggers/trigger/transactions/triggers-transactions-page.component";
import {GoodgroupIndexPageComponent} from "./pages/config/directory/goodgroup/index/goodgroup-index-page.component";
import {GoodgroupViewPageComponent} from "./pages/config/directory/goodgroup/view/goodgroup-view-page.component";


import {PipesComponentsModule} from "./shared/pipes/pipes.components.module";
import {ReferrerModule} from "./pages/referrer/referrer.module";

import {DirectiveModule} from "./shared/directive/directive.module";
import {SharedComponentsModule} from "./shared/component/shared.components.module";
import {CampaigncategorylimitationsIndexPageComponent} from "./pages/config/directory/campaigncategorylimitations/index/campaigncategorylimitations-index-page.component";
import {СampaignCategoryRuleComponent} from "./pages/config/directory/campaigncategorylimitations/index/shared/component/campaignCategoryRule/campaignCategoryRule-index-page.component";
import {HighchartsChartModule} from "highcharts-angular";
import {TransactionModule} from "./pages/crm/accaunts/tansaction/transaction.module";
import {DashboardPageComponent} from "./pages/dashboard/view/dashboard-page.component";
import {DashboardWidgetsModule} from "./pages/dashboard/widgets/dashboard-widgets.module";
import {
  CampaignlimitationsIndexPageComponent
} from "./pages/config/directory/campaignlimitations/index/campaignlimitations-index-page.component";
import {
  CampaignlimitationsViewPageComponent
} from "./pages/config/directory/campaignlimitations/view/campaignlimitations-view-page.component";
import {GoodsTreeModule} from "./shared/component/goods-tree/goods-tree.module";
import {SearchInputModule} from "./shared/component/search-input/search-input.module";
import {GoodTreeGetterService} from "./shared/component/goods-tree/service/good-tree-getter.service";
import {LocationsDialogModule} from "./shared/component/locations-dialog/locations-dialog.module";
import {PartnersIndexPageComponent} from "./pages/config/directory/partners/index/partners-index-page.component";
import {LocationsIndexPageComponent} from "./pages/config/directory/locations/index/locations-index-page.component";
import {LocationsViewPageComponent} from "./pages/config/directory/locations/view/locations-view-page.component";
import {ImportFromFileModule} from "./shared/component/import-from-file/import-from-file.module";
import {DynamicReportsModule} from "./pages/reports/dynamic/dynamic-reports.module";
import {AuthPageModule} from "./pages/auth/auth-page.module";
import {HttpErrorCatchingInterceptor} from "./shared/interceptors/http-error-catching.interceptor";
import {CardsIndexPageComponent} from "./pages/crm/cards/index/cards-index-page.component";
import {CardsViewPageComponent} from "./pages/crm/cards/view/cards-view-page.component";
import {FiltersComponentsModule} from "./shared/component/filters/filters.components.module";
import {SegmentsIndexPageComponent} from "./pages/segments/index/segments-index-page.component";
import {SegmentsViewPageComponent} from "./pages/segments/view/segments-view-page.component";
import {
  SegmentGraphViewComponent
} from "./pages/segments/shared/component/segmentGraphView/segment-graph-view.component";
import {SegmentsResegmentPageComponent} from "./pages/segments/resegment/segments-resegment-page.component";
import {
  SegmentRfmCustomComponent
} from "./pages/segments/shared/component/segmentRfmCustom/segment-rfm-custom.component";
import {GateModule} from "./pages/campaign/gate/gate.module";
import {
  LocationsGroupIndexPageComponent
} from "./pages/config/directory/locationsGroup/index/locations-group-index-page.component";
import {
  LocationGroupIndexViewPageComponent
} from "./pages/config/directory/locationsGroup/index/shared/locationGroupIndexView/location-group-index-view-page.component";
import {
  BonusRestrictIndexPageComponent
} from "./pages/config/directory/bonusrestrict/index/bonus-restrict-index-page.component";
import {AccauntsPageModule} from "./pages/crm/accaunts/accaunts-page.module";
import {CampaignsModule} from "./pages/campaign/campaigns/campaigns.module";
import {ReceiptIndexPageComponent} from "./pages/receipt/index/receipt-index-page.component";
import {ReceiptIndexPageModule} from "./pages/receipt/index/receipt-index-page.module";
import {SystemIndexPageModule} from "./pages/system/index/system-index-page.module";
import {SystemModulesIndexPageModule} from "./pages/system/modules/index/system-modules-index-page.module";
import {SystemModulesConfigPageModule} from "./pages/system/modules/config/system-modules-config-page.module";
import {CouponsRulesPageModule} from "./pages/coupons/rules/coupons-rules-page.module";
import {CouponsPageModule} from "./pages/coupons/coupons/coupons-page.module";
import {CashboxPageModule} from "./pages/cashbox/cashbox-page.module";
import {ScrollUpModule} from "./shared/component/scroll-up/scroll-up.module";
import {CommunicationsModule} from "./pages/campaign/communications/communications.module";
import {DndModule} from "ngx-drag-drop";



@NgModule({
  declarations: [
    // Pages
    UsersIndexPageComponent,
    UsersViewPageComponent,
    MechaniccategoryIndexPageComponent,
    MechaniccategoryViewPageComponent,
    ClientcategoryIndexPageComponent,
    ClientcategoryViewPageComponent,
    CampaigncategoryIndexPageComponent,
    CampaigncategoryViewPageComponent,
    BrandIndexPageComponent,
    BrandViewPageComponent,
    TriggersTransactionsPageComponent,
    GoodgroupIndexPageComponent,
    GoodgroupViewPageComponent,
    CampaigncategorylimitationsIndexPageComponent,
    СampaignCategoryRuleComponent,
    CampaignlimitationsIndexPageComponent,
    CampaignlimitationsViewPageComponent,
    DashboardPageComponent,
    PartnersIndexPageComponent,
    LocationsIndexPageComponent,
    LocationsViewPageComponent,
    LocationsGroupIndexPageComponent,
    LocationGroupIndexViewPageComponent,
    CardsIndexPageComponent,
    CardsViewPageComponent,
    SegmentsIndexPageComponent,
    SegmentsViewPageComponent,
    SegmentGraphViewComponent,
    SegmentsResegmentPageComponent,
    SegmentRfmCustomComponent,
    BonusRestrictIndexPageComponent,

  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    NgSelectModule,
    FormsModule,
    NgbAlertModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbModalModule,
    NgbNavModule,
    NgbModule,
    HttpClientModule,
    HighchartsChartModule,
    ...API_SERVICE_MODULES,
    TranslocoRootModule,
    InfiniteScrollModule,
    ReferrerModule,
    TransactionModule,
    SharedComponentsModule,
    PipesComponentsModule,
    DirectiveModule,

    //
    GoodsTreeModule,
    LocationsDialogModule,
    ImportFromFileModule,
    // PAGES

    DashboardWidgetsModule,
    SearchInputModule,
    DynamicReportsModule,
    AuthPageModule,
    FiltersComponentsModule,
    GateModule,
    AccauntsPageModule,
    CampaignsModule,
    CommunicationsModule,
    ReceiptIndexPageModule,
    SystemIndexPageModule,
    SystemModulesIndexPageModule,
    SystemModulesConfigPageModule,
    CashboxPageModule,
    CouponsRulesPageModule,
    CouponsPageModule,
    ScrollUpModule,
    DndModule,
  ],
  providers: [
    preLoad,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorCatchingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [],
  entryComponents:[
  ]
})

export class AppModule {

  static $inject = ['upgrade'];

  constructor(private upgrade: UpgradeModule ) {}
  ngDoBootstrap() {


    (<any>window).Highcharts = Highcharts;
      HighchartsMore(Highcharts);
      Highcharts3d(Highcharts);

    ModuleLoader
      .prepare()
      .then( ref => {
         this.upgrade.bootstrap(document.getElementById('root_view'),['frontend'], { strictDi: true } )
        }
      );

  }

}

import {Component, OnChanges, OnInit, Optional, SimpleChanges} from "@angular/core";
import {PartnerService} from "../../../../service/api/Partner/Partner.service";
import {VIEW_TYPES} from "./../../goods-tree.variables";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../../service/api/CategoryModel/CategoryModel.service";
import {BrandService} from "../../../../service/api/Brand/Brand.service";
import {GoodgroupService} from "../../../../service/api/Goodgroup/Goodgroup.service";

@Component({
  selector: 'goods-watch',
  templateUrl: "./goods-watch.component.html",
  styleUrls: ['./goods-watch.component.scss'],
  providers: [
    PartnerService,
  ]
})
 export class GoodsWatchComponent implements OnInit, OnChanges{

  public forPartner: number | string;
  public canDelete?: boolean | string;
  public listType: VIEW_TYPES;

  public VIEW_TYPES = VIEW_TYPES;

  public externalList  = [];
  public items: any[] = [];

  public isLoading = false;

  public categoriesPath = {};


  private pager = {
    drop: 0,
    limit: 20,
  }

  constructor(
      private partnerService: PartnerService,
      private goodModelService: GoodModelService,
      private categoryModelService: CategoryModelService,
      private brandService: BrandService,
      private goodgroupService: GoodgroupService,
      @Optional() private activeModal: NgbActiveModal
  )
  {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {


  }

  close() {
    this.activeModal.dismiss(false);
  }

  async onScroll() {
    this.isLoading = true;
    this.items.push(
      ... await this.getNames( this.externalList.slice(this.items.length, this.items.length + this.pager.limit) )
    );
    this.isLoading = false;
  }

  async getNames(items: any[]) {

    let promises =[];

    switch (this.listType) {

      case VIEW_TYPES.SKU:
        promises = items.map( i =>
          this.goodModelService.get$(i.sku || i, this.forPartner)
            .toPromise()
            .catch( () => Promise.resolve({
              "partnerId":this.forPartner,
              "sku": i.sku || i,
              "categoryId":-1,
              "name":"Товар удалён",
              "margin":0.00,
              "dimension":"piece",
              "limits":{},
              "state":"active"
            }) )
          );
          break;

      case VIEW_TYPES.CATEGORY:

        promises = items.map( i =>
          this.categoryModelService
              .get$(i, this.forPartner)
              .toPromise()
              .catch( error => Promise.resolve( {
                  "partnerId"  : this.forPartner,
                  "categoryId" : i,
                  "parentId"   : -1,
                  "name"       : `Категория удалена ( id:${i} )`,
                  "limits":{},
                  "state":"active"
              })
              .then( item => {
                this.categoryModelService.getFullPathByCategory$( i, this.forPartner)
                  .toPromise()
                  .then( ( result: any ) =>  {
                    if (result && Array.isArray(result.path))
                      this.categoriesPath[i] = result.path.slice(0, -1);

                    return item;
                }, () => Promise.resolve(item));
              })
           )
        );
        break;

      case VIEW_TYPES.BRAND:

        promises = items.map( i =>
          this.brandService
            .getBrand$(i)
            .toPromise()
        );

        break;

      case VIEW_TYPES.GOODGROUP_SKU:
      case VIEW_TYPES.GOODGROUP:
      case 'custom':

        promises = items.map( i =>
          this.goodgroupService
            .getGoodgroup$(i, <string>this.forPartner)
            .toPromise()
        );

      break;

    }

    return  await Promise.all(promises);

  }

  removeSkuById(skyItem, index) {
    this.items.splice(index, 1);
    this.externalList.splice(index, 1);
  }

  setList( list: any[]) {
    this.pager.drop  =  0;
    this.externalList = list;
    this.onScroll();
  }





}

import {Component, ElementRef, Input, OnChanges, OnInit} from "@angular/core";
import {PartnerService} from "../../service/api/Partner/Partner.service";

const MAX_NUMBER_OF_CHARACTERS = 56;


@Component({
  selector: 'pos-printer-template',
  templateUrl: './pos-printer-template.component.html',
  styleUrls: ['./pos-printer-template.component.scss'],
  providers: [
  ]
})
export class PosPrinterTemplateComponent implements OnInit, OnChanges {

    // Передаваемые параметры в компонент
    @Input() public message    : string;

    public messagePrepared : string;

    private preContainer : HTMLElement;
    private tmpDiv : HTMLElement;
    public isPrepared: boolean;

    constructor(
        private elementRef: ElementRef

    ) { }


    public ngOnInit() {
        this.preContainer = this.elementRef.nativeElement?.querySelector('.s_check pre');

        this.isPrepared = false;
        this.checkLoadImage('/assets/img/components/printer-blank.png')
            .then(() => {
                this.isPrepared = true;
                setTimeout( this.checkWidth.bind(this)  );
            } );
    }

    private checkLoadImage = function( src : string ) {

        return new Promise( (resolve : any, reject : any) => {

            if (!src) {
                return;
            }

            let testImage = new Image();
            testImage.onload = () => {
                resolve(src);
            };

            testImage.onerror = reject;
            testImage.onabort = reject;

            testImage.src = src;

        });
    };


    public htmlToText = ( input: string) : string => {

        if (!input) {
            return '';
        }

        if (!this.tmpDiv) {
            this.tmpDiv = document.createElement("DIV");
        }

        this.tmpDiv.innerHTML = input || '';
        return ( this.tmpDiv.textContent || this.tmpDiv.innerText || "" )
            .split(/\n/).map( i => i.substr(0,MAX_NUMBER_OF_CHARACTERS) ).join("\n")
            .replace(/[\s\uFEFF\xA0]+$/g, '');
    };

    public ngOnChanges( changes: any ) {

        if ( changes['message']) {
            this.messagePrepared = this.htmlToText(changes['message'].currentValue);
            setTimeout( this.checkWidth.bind(this) );
        }

    }

    private checkWidth() {

        let fontSize = 1;
        this.preContainer.style.fontSize = '1em';

        while( this.preContainer.clientWidth < this.preContainer.scrollWidth && fontSize >= 0.45 ) {
            this.preContainer.style.fontSize = fontSize + 'em';
            fontSize -= 0.05;
        }


        if ( this.preContainer.clientHeight === this.preContainer.scrollHeight ) {
            // Размер окна может быть меньше
            let cssHeight = this.preContainer.style.height;
            this.preContainer.style.height = "auto";

            let clientHeight = this.preContainer.clientHeight;
            let scrollHeight = this.preContainer.scrollHeight;

            // для анимации 2 раза задаём
            this.preContainer.style.height = cssHeight;
            setTimeout(() => this.preContainer.style.height = (scrollHeight < clientHeight ? clientHeight : scrollHeight ) + 'px', 100 );

        } else {
            this.preContainer.style.height = this.preContainer.scrollHeight + 'px';
        }

    }


};

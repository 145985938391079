<div class="fixed-tool-panel">

  <filter-checkbox

    class="m-t-xs m-r-sm"

    [name]         = "'Статус'"
    [canSelectAll] = "true"
    [showClearButton] = "false"
    [inputValues]  = "CARD_STATE_NAMES"
    [selectedValues] = "cardService.queryParams.getFilterValue('states')"
    (selectedValuesChange) = "cardService.queryParams.setFilterValue(
                    'states',
                    $event
                  );
                  reset();"
  ></filter-checkbox>


  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view'"
          data-testid="card.add()"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

  <div class="wrapper stick-search-box">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="№ Карты/ФИО клиента/Тел. клиента/E-mail клиента"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>
           <sort-view
             column="number"
             [params]="cardService.queryParams"
             (onChange)="reset()"
             [disabled]="isSearchEnabled"
           >
             Номер карты
           </sort-view>
         </th>
         <th>
            Статус
         </th>

         <ng-container
           *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
         >
           <th>

             <sort-view
               column="clientFio"
               [params]="cardService.queryParams"
               (onChange)="reset()"
               [disabled]="isSearchEnabled"
             >
               ФИО клиента
             </sort-view>

           </th>

         </ng-container>

         <th>

           <sort-view
             column="createDate"
             [params]="cardService.queryParams"
             (onChange)="reset()"
             [disabled]="isSearchEnabled"
           >
             Дата создания
           </sort-view>

         </th>
         <th>

           <sort-view
             column="userName"
             [params]="cardService.queryParams"
             (onChange)="reset()"
             [disabled]="isSearchEnabled"
           >
             Пользователь
           </sort-view>

         </th>
       </tr>
     </thead>

      <tbody>
        <ng-container  *ngFor="let item of listCards ">

          <tr data-testid="card in cards">
            <td class="table-big-link"
                data-testid="card.number"
            >

              <a [uiSref]="'^.view({id:'+item.card.id+'})'" >
                <text-highlight
                  [text]="item.card?.number"
                  [search]="searchString"
                ></text-highlight>
              </a>

            </td>

            <td
                data-testid="card.state"
            >
              <span
                 [innerText]="CARD_STATE_NAMES[item.card?.state]"
              >
              </span>
            </td>

            <ng-container
              *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
            >

              <td data-testid="card.client.fio"
                  class="table-big-link"
              >
                <a [uiSref]="'frontend.crm.clients.view({id:'+item.card.clientId+'})'">

                  <text-highlight
                    [class.m-r-sm]="!!item?.client?.surname"
                    [text]="item.client.surname"
                    [search]="searchString"
                  ></text-highlight>

                  <text-highlight
                    [text]="item.client.name"
                    [search]="searchString"
                  ></text-highlight>

                </a>
              </td>

            </ng-container>

            <td data-testid="card.createDate">
              <span [innerHTML]="item.card?.createDate | loyaDate "></span>
            </td>

            <td
                data-testid="card.user.name"
            >
                <span [innerHTML]="item.user?.name "></span>
            </td>
          </tr>

        </ng-container>
      </tbody>
   </table>
 </responsive-table>

  <div class="text-center text-warning m-t-lg m-b-lg"
       *ngIf="isLoading"
  >
    <div class="">
      <i class="fa fa-3x fa-spin fa-spinner"></i>
    </div>

  </div>


<scroll-up></scroll-up>

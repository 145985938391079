
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {GoodsSelectorService, legacyDirectiveParams} from "../../goods-tree/service/goods-selector.service";
import {LocationsDialogService} from "../../locations-dialog/service/locations-dialog.service";
import {LocationsService} from "../../../service/api/Locations/Locations.service";

@Component({
  selector: 'filter-locations',
  templateUrl: './filter-locations.component.html',
  providers: [
  ]
})
export class FilterLocationsComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public partnerId;
  @Output() public partnerIdChange  = new EventEmitter();
            private changedPartnerId;

  @Input() public max;

  @Input() public isPending = false;

  @Input() public initList;

  public list = [];

  get hasSelectedGoods() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private locationsDialogService: LocationsDialogService,
    private locationsService: LocationsService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (
      changes['partnerId'] &&
      !!changes['partnerId']?.currentValue &&
      !!this.changedPartnerId &&
      changes['partnerId']?.currentValue !== this.changedPartnerId
    ) {
      this.clear();
    }

    if ( !changes['initList']?.previousValue && !!changes['initList']?.currentValue ) {
      this.changeInitList(changes['initList'].currentValue);
    }

  }

  openDialog() {
    this.locationsDialogService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})
  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};

    if (this.partnerId) {
      options.initPartner = this.partnerId;
    }

    if (!!this.max) {
      options.max = this.max;
    }

    return options;
  }

  public changeResult(result) {

    this.partnerId = result.partnerId;
    this.changedPartnerId = result.partnerId;
    this.partnerIdChange.emit( this.partnerId );

    this.list = result.locations;

    this.emitModel();

  }

  private emitModel() {
    this.modelChange.emit(!this.list?.length ? undefined : this.list.map(i => i.id));
  }

  public getName(item) {
    return typeof  item === "object" ? item?.name : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }


  private changeInitList(list) {

    if (!Array.isArray(list)) {
      return
    }

    let checkArray = list.slice();

    const hasUniqIds = this.list.some( item => {
      if (!checkArray.includes(item.id))
        return true;

      checkArray = checkArray.filter( i => i !== item.id);
      return false;
    })

    if ( checkArray.length || hasUniqIds ) {
      this.populateNewList(list);
    }

  }

  private populateNewList(newList) {

    this.list = newList.map( id => ({id, name: 'ТО с ID: ' + id, description: ''}) );

    newList.map( id => {
      this.locationsService.get$(id).subscribe( res => {
        let finded = this.list.find( item => item.id === id);
        finded.name = res.name;
        finded.description = '';
      }, error =>  error?.stopPopupError())
    });

  }


}

import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule, NgbModalModule,
  NgbNavModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";

import {CommonModule} from "@angular/common";
import {downgradeComponent} from "@angular/upgrade/static";
import {SharedComponentsModule} from "../../../shared/component/shared.components.module";
import {PipesComponentsModule} from "../../../shared/pipes/pipes.components.module";
import {DirectiveModule} from "../../../shared/directive/directive.module";
import {SearchInputModule} from "../../../shared/component/search-input/search-input.module";
import {FiltersComponentsModule} from "../../../shared/component/filters/filters.components.module";
import {CampaignsModule} from "../../campaign/campaigns/campaigns.module";
import {CouponsIndexPageComponent} from "./index/coupons-index-page.component";
import {CouponsViewDeletePageComponent, CouponsViewPageComponent} from "./view/coupons-view-page.component";
import {CouponTransactionsComponent} from "./components/coupon-transactions/coupon-transactions.component";
import {CouponEditComponent} from "./components/coupon-edit/coupon-edit.component";
import {ScrollUpModule} from "../../../shared/component/scroll-up/scroll-up.module";


@NgModule({
  declarations: [
    CouponsIndexPageComponent,
    CouponsViewPageComponent,
    CouponTransactionsComponent,
    CouponsViewDeletePageComponent,
    CouponEditComponent
  ],
    imports: [
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        HttpClientModule,
        InfiniteScrollModule,
        CommonModule,
        SharedComponentsModule,
        PipesComponentsModule,
        DirectiveModule,
        NgbNavModule,
        NgbPopoverModule,
        SearchInputModule,
        FiltersComponentsModule,
        CampaignsModule,
        NgbModalModule,
        ScrollUpModule
    ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class CouponsPageModule {

  constructor() {}

}

angular.module('loya.builder')
  .directive('couponsIndexPage' , downgradeComponent({ component: CouponsIndexPageComponent  }) )
  .directive('couponsViewPage' , downgradeComponent({ component: CouponsViewPageComponent  }) )


<div
    class="clearfix text-right "
    *ngIf="isEnabled"
>

    <div class="f-s-8" *ngIf="!enableInput" >

        <a href="javascript:void(0)"
           *ngIf="!getSuccess()"
           class="text-primary"
           (click)="enableInput = true" >
            <i class="fa fa-barcode m-r-xxs"></i> Ввести ШК купона-наклейки
        </a>


        <div class=" clearfix " *ngIf="getSuccess()">

            <i class="fa fa-check-circle text-success"></i>
            Введён ШК
            <span class="text-success">{{getAward().value}}</span>
            для купона-наклейки
            <span class="text-success" > {{getSuccess().description}}</span>

            <div class=" clearfix">
                <a href="javascript:void(0)" class="text-danger pull-right m-l-xs" (click)="removeAward()">удалить</a>
            </div>

        </div>


    </div>

    <div *ngIf="enableInput"
         class="b-t p-t-xs p-b-xs "
    >

        <div class="col-lg-6 col-sm-8 col-md-12 col-sm-12 pull-right text-left" style="max-width: 350px;">

            <div class=" m-t-sm f-s-9 hidden-lg">Введите ШК купона-наклейки</div>

            <div class="input-group pull-right w-full clearfix m-t-xs m-b-xs">

                <input class="form-control form-control-sm"
                       [(ngModel)]="inputCode">

                <span class="input-group-btn">
                    <button class="btn btn-success btn-sm"
                            type="button"
                            [disabled]="!inputCode"
                            (click)="addToAwards()">
                        Отправить
                    </button>

                     <button class="btn btn-danger btn-sm"
                             type="button"
                             (click)="cancelInput()">
                        <i class="fa fa-times"></i>
                    </button>

                </span>

            </div>

        <div class="clearfix"></div>

           <div class=" clearfix text-left f-s-9" *ngIf="getError() || getSuccess()">

               <i class="fa"
                  [ngClass]=" { 'fa-check-circle text-success': getSuccess(), 'fa-times-circle text-danger': getError() }"
               ></i>

               <span *ngIf="getSuccess()" class=" text-success">{{getAward().value}} {{getSuccess().description}}</span>
              <span *ngIf="getError()"   class=" text-danger" >{{getAward().value}} {{getError().description}}</span>

            </div>

        </div>


        <div class=" text-right pull-right m-t-sm visible-lg ">Введите ШК купона-наклейки</div>

    </div>

</div>


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../../shared/service/api/Settings/Settings.service";
import {
  CHANNEL_TYPES,
  CHANNEL_TYPES_NAMES,
  COMMUNICATION_CAMPAIGN_COMPLETENESS,
  COMMUNICATION_CAMPAIGN_STATE_NAMES,
  COMMUNICATION_CAMPAIGN_STATES_COLORS
} from "../../../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.values";
import {map} from "rxjs/operators";
import {PartnerService} from "../../../../../../shared/service/api/Partner/Partner.service";
import {daysOfMonthDics, daysOfWeekDict} from "../../values/campaign.values";
import {CommunicationRowViewComponent} from "../communication-row-view/communication-row-view.component";
import {InformingCampaignProtocol} from "../../../../../../api/CommunicationCampaignApi/models/informing-campaign-protocol";

@Component({
  selector: 'communication-view',
  templateUrl: './communication-view.component.html',
  styleUrls: [
    `./communication-view.component.scss`
  ],
  providers: [
    CampaignService,
    LocationsDialogService,
    GroupService,
    SettingsService,
    PartnerService,
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationViewComponent implements OnInit, OnChanges {

  @Input() communicationItem: InformingCampaignProtocol;
  @Input() campaignResources;

  @Output() anchorList = new EventEmitter()
  public anchorListDict = {
    anchorI1: 'Параметры',
    anchorI2: 'Информирование',
    anchorI6: 'Условия срабатывания',
  }

  public loader = new LoaderHandlerClass();

  @ViewChildren(CommunicationRowViewComponent)
  public campaignRowViewComponents: QueryList<CommunicationRowViewComponent>;

  public COMMUNICATION_CAMPAIGN_STATES_COLORS = COMMUNICATION_CAMPAIGN_STATES_COLORS;
  public COMMUNICATION_CAMPAIGN_STATE_NAMES = COMMUNICATION_CAMPAIGN_STATE_NAMES;

  public CHANNEL_TYPES_NAMES = CHANNEL_TYPES_NAMES;
  public CHANNEL_TYPES = CHANNEL_TYPES;

  public preparedFields = new Map();
  public settingsObj:any = {
    viewIndex: 1,
    hideZeroResourceRow: true,
    hideZeroClientSellingRow: true,
  }

  public daysOfWeekDict = daysOfWeekDict;
  public daysOfMonthDics = daysOfMonthDics;
  public LOYA_CONFIG = LOYA_CONFIG;

  public communicationsConfig = {
    title: false,

    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      backgroundColor: null,
      plotShadow: false,
      borderWidth: 0,
      type: 'pie',
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      width: 110,
      height: 110,
      style: {
        overflow: 'visible',
        margin: '0 auto'
      },

      skipClone: true
    },

    credits: {
      enabled: false
    },

    subtitle: {
      useHTML: true,
      text: '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        fontWeight:700,
        fontSize: '1.2em'
      },
      y: 5
    },

    legend: {
      enabled: false
    },

    tooltip:false,

    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: 'pointer',
        dataLabels: false
      }
    },
    colors: [LOYA_CONFIG.color.warning, LOYA_CONFIG.color.info],
    series: [
      {
        enableMouseTracking: false,
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: []
      }
    ]
  };
  public Highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;

  constructor(
    public campaignService: CampaignService,
    public locationsDialogService: LocationsDialogService,
    public groupService: GroupService,
    public settingsService: SettingsService,
    private partnerService: PartnerService,
  ) {
  }

  get isMechanicNotConfigured() {
    return true; // TODO this.communicationItem?.mechanic?.status=='template'
  }

  get isInformingNotConfigured() {
    return false;
  }

  get campaignRowIndex() {
    return this.settingsObj.viewIndex++;
  }

  ngOnInit() {

    this.anchorList.emit(this.anchorListDict)

    this.subscribeCampaignsRowChange();

  }

  ngOnChanges(changes) {
    if (changes['communicationItem']) {
      this.settingsObj.viewIndex = 1
    }
  }

  getPartnerById(id) {

    if ( this.preparedFields.has('partner' + id) ) {
      return this.preparedFields.get('partner' + id);
    }

    this.preparedFields.set('partner' + id, '');
    this.partnerService.get$(id)
      .pipe(
        this.loader.waitLoading('partner'),
      )
      .subscribe({
        next: p => {
          this.preparedFields.set('partner' + id, p?.name);
        }
      })

    return  '';
  }

  subscribeCampaignsRowChange() {
    setTimeout(() => {
      this.campaignRowViewComponents
        .changes
        .subscribe( {
          next: (result: QueryList<CommunicationRowViewComponent>) => {
            result.forEach( (i, index) => {
              i.setIndex(index +1);
            })
          }
        })
    })
  }

}

import {Component, Input, NgModule, OnChanges, OnInit} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {ControlContainer, FormsModule, NgForm} from "@angular/forms";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgSelectModule} from "@ng-select/ng-select";
import {CommonModule} from "@angular/common";
import {MECHANIC_EVENTS} from "./communication-mechanic.events";
import {DirectiveModule} from "../../../../../../shared/directive/directive.module";
import {
  goodSelectorParams,
  GoodsSelectorService
} from "../../../../../../shared/component/goods-tree/service/goods-selector.service";
import {GoodsWatcherService} from "../../../../../../shared/component/goods-tree/service/goods-watcher.service";




class EventRootClass {
  constructor(
    public goodsSelectorService?: GoodsSelectorService,
    public goodsWatcherService?: GoodsWatcherService
  ) {}

  protected eventItem: any;

  public moneyPattern = /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/;
  private nameCache = new Map<any, string>();

  protected compareValues: any = [{id:'eq', name:'Равно'},{id:'lt', name:'Меньше'},{id:'le', name:'Меньше или равно'},{id:'gt', name:'Больше'},{id:'ge', name:'Больше или равно'}];
  getCompareName(id) {
    return this.compareValues.find(item => item.id == id)?.name;
  }

  protected periodTypeValues: any = [{id:'year', name:'Год'},{id:'month', name:'Месяц'},{id:'day', name:'День'}]
  getPeriodTypeName(id) {
    return this.periodTypeValues.find(item => item.id == id)?.name;
  }

  selectGoods(options: goodSelectorParams, type = '') {
    if (!this.goodsSelectorService)
      return;

    this.goodsSelectorService.open(options)
      .then( result => {


        switch (type) {

          case 'whiteGoods':
            if (Array.isArray(result.list) && result?.list?.length === 0  && result.type !== "favourite" ) {
              return;
            }

            this.eventItem.goodList = this.eventItem.goodList || {};

            if ( result.type === undefined) {
              delete this.eventItem.goodList.whiteListType ;
              delete this.eventItem.goodList.whiteIdList;
              delete this.eventItem.goodList.whiteSKUList;
              return;
            }

            if ( this.eventItem.goodList.whiteListType !== result.type ) {
              this.eventItem.goodList.whiteListType = result.type;

              switch( this.eventItem.goodList.whiteListType ) {

                case 'sku':
                  this.eventItem.goodList.whiteSKUList = [];
                  delete this.eventItem.goodList.whiteIdList;
                  break;

                default:
                  this.eventItem.goodList.whiteIdList = [];
                  delete this.eventItem.goodList.whiteSKUList;
                  break;
              }
            }

            result?.list?.forEach( ( item )=> {
              switch( this.eventItem.goodList.whiteListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.eventItem.goodList.whiteSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.eventItem.goodList.whiteIdList);
                  break;
              }

            });
            break;

          case 'blackGoods':
            if (Array.isArray(result.list) && result?.list?.length === 0  && result.type !== "favourite") {
              return;
            }

            this.eventItem = this.eventItem || {};
            this.eventItem.goodList = this.eventItem.goodList || {};

            if ( result.type === undefined) {
              delete this.eventItem.goodList.blackListType;
              delete this.eventItem.goodList.blackIdList;
              delete this.eventItem.goodList.blackSKUList;
              return;
            }

            if ( this.eventItem.goodList.blackListType !== result.type ) {

              this.eventItem.goodList.blackListType = result.type;

              switch( this.eventItem.goodList.blackListType ) {

                case 'sku':
                  this.eventItem.goodList.blackSKUList = [];
                  delete this.eventItem.goodList.blackIdList ;
                  break;

                default:
                  this.eventItem.goodList.blackIdList = [];
                  delete this.eventItem.goodList.blackSKUList ;
                  break;
              }

            }

            result.list.forEach( ( item ) => {
              switch( this.eventItem.goodList.blackListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.eventItem.goodList.blackSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.eventItem.goodList.blackIdList);
                  break;
              }

            });

            break;

        }

      }, () => {})
  }

  addIfNotExist = function (value, array) {

    if (!Array.isArray(array))
      array = [];

    for (var i in array) {
      if (JSON.stringify(array[i]) === JSON.stringify(value))
        return false;
    }

    array.push(value);
    return array;
  };

  viewGoodsPopup( options: any ) {
    this.goodsWatcherService.open({
      list: options.viewGoodsPopup,
      forPartner: options.forPartner,
      listType: options.listType,
    }).catch(() => {});
  }

  deleteField(obj, field) {
    if ( obj && typeof obj) {
      delete obj[field];
    }
  }

  getNameEvent(nameStr) {
    if (!this.nameCache.has(nameStr)) {
      this.nameCache.set(nameStr, nameStr + '_' + Math.random().toString(36).substr(2, 9) );
    }

    return this.nameCache.get(nameStr);
  }


}


@Component({
  selector: 'communication-mechanic-daysfromregistry',
  template: `
    <div class="input-group " *ngIf="isEdit">
      <span class="input-group-addon">в первые</span>
      <input type="number"
             class="form-control w-xs inline"
             [name]="getNameEvent('communication-mechanic-daysfromregistry-count1')"
             [(ngModel)]="eventItem.clientEvent.count1"
             pattern="^[0-9]+$"
             [required]="true"
             min="0">
      <span class="input-group-addon">дней после регистрации</span>
    </div>
    <div class="input-group " *ngIf="!isEdit">в первые {{eventItem.clientEvent.count1}} дней после регистрации</div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicDaysFromRegistryComponent extends EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  constructor() { super(); }
}

@Component({
  selector: 'communication-mechanic-daysfromlast',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
              [(ngModel)]="eventItem.clientEvent.compare"
              [items]="compareValues"
              bindLabel="name"
              bindValue="id"
              [name]="getNameEvent('communication-mechanic-daysfromlast-compare')"
               [clearable]="false"
      >
      </ng-select>

      <div class="input-group">
          <input type="number" class="form-control w-xs inline "
                 [(ngModel)]="eventItem.clientEvent.count1"
                 [required]="true"
                 pattern="/^[0-9]+$/"
                 min="0"
                 [name]="getNameEvent('communication-mechanic-daysfromlast-count1')"
          >
          <span class="input-group-addon">дней</span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.count1}} дней
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicDaysFromLastComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-maxcheck',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-maxcheck-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class="input-group  pull-left ">

        <input type="number" class="form-control w-xs inline   "
               [(ngModel)]="eventItem.clientEvent.total"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-maxcheck-total')"
        >
        <span class="input-group-addon" ngCurrencyNameLoya></span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.total}} <span ngCurrencyNameLoya></span>
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicMaxCheckComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-total',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-total-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class="input-group pull-left">

        <input type="number" class="form-control w-xs inline   "
               [(ngModel)]="eventItem.clientEvent.total"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-total-total')"
        >
        <span class="input-group-addon" ngCurrencyNameLoya></span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.total}} <span ngCurrencyNameLoya></span>
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicTotalComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-lifevalue',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-lifevalue-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class="input-group pull-left">

        <input type="number" class="form-control w-xs inline   "
               [(ngModel)]="eventItem.clientEvent.total"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-lifevalue-total')"
        >
        <span class="input-group-addon" ngCurrencyNameLoya></span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.total}} <span ngCurrencyNameLoya></span>
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicLifevalueComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-profilestate',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class=" w-full "
                 [(ngModel)]="eventItem.clientEvent.isFull"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-profilestate-compare')"
                 [clearable]="false"
      >
      </ng-select>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.isFull)}}
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicProfileStateComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  public compareValues = [{id:true, name:'Анкета заполнена'},{id:false, name:'Экспресс'}];

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.isFull = !!this.eventItem.clientEvent.isFull;
  }

}

@Component({
  selector: 'communication-mechanic-availablechannel',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class=" w-full "
                 [(ngModel)]="eventItem.clientEvent.channel"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-availablechannel-channel')"
                 [clearable]="false"
      >
      </ng-select>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.channel)}}
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicAvailableChannelComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  public compareValues = [{id:'email', name:'E-mail'},{id:'sms', name:'SMS'}];

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.channel = this.eventItem.clientEvent.channel || 'email';
  }

}

@Component({
  selector: 'communication-mechanic-disablechannel',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class=" w-full "
                 [(ngModel)]="eventItem.clientEvent.channel"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-disablechannel-channel')"
                 [clearable]="false"
      >
      </ng-select>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.channel)}}
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicDisableChannelComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  public compareValues = [{id:'email', name:'E-mail'},{id:'sms', name:'SMS'}];

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.channel = this.eventItem.clientEvent.channel || 'email';
  }

}

@Component({
  selector: 'communication-mechanic-afterfullregistration',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-afterfullregistration-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class=" w-full">

        <input type="number" class="form-control w-full inline   "
               [(ngModel)]="eventItem.clientEvent.count1"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-afterfullregistration-total')"
        >
      </div>

      <ng-select class="w-sm col-auto p-r-none"
                 [(ngModel)]="eventItem.clientEvent.periodType"
                 [items]="periodTypeValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-afterfullregistration-periodType')"
                 [clearable]="false"
      >
      </ng-select>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.count1}} {{ getPeriodTypeName(eventItem.clientEvent.periodType)}}
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicAfterFullRegistrationComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
    this.eventItem.clientEvent.periodType = this.eventItem.clientEvent.periodType || 'day';
  }

}

@Component({
  selector: 'communication-mechanic-afterexpressregistration',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-afterexpressregistration-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class=" w-full">

        <input type="number" class="form-control w-full inline   "
               [(ngModel)]="eventItem.clientEvent.count1"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-afterexpressregistration-total')"
        >
      </div>

      <ng-select class="w-sm col-auto p-r-none"
                 [(ngModel)]="eventItem.clientEvent.periodType"
                 [items]="periodTypeValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-afterexpressregistration-periodType')"
                 [clearable]="false"
      >
      </ng-select>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.count1}} {{ getPeriodTypeName(eventItem.clientEvent.periodType)}}
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicAfterExpressRegistrationComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
    this.eventItem.clientEvent.periodType = this.eventItem.clientEvent.periodType || 'day';
  }

}

@Component({
  selector: 'communication-mechanic-totalPerSeason',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.clientEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-total-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class="input-group pull-left">

        <input type="number" class="form-control w-xs inline   "
               [(ngModel)]="eventItem.clientEvent.total"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-total-total')"
        >
        <span class="input-group-addon" ngCurrencyNameLoya></span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.clientEvent.compare)}} {{eventItem.clientEvent.total}} <span ngCurrencyNameLoya></span>
    </div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicTotalPerSeasonComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-birthday',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <div class="input-group">
        <span class="input-group-addon">за</span>
          <input type="number" class="form-control w-xs inline "
                 [(ngModel)]="eventItem.clientEvent.count1"
                 [required]="true"
                 pattern="/^[0-9]+$/"
                 min="0"
                 [name]="getNameEvent('communication-mechanic-daysfromlast-count1')"
          >
          <span class="input-group-addon">дней</span>
      </div>

      <div class="input-group">
        <span class="input-group-addon">и после</span>
        <input type="number" class="form-control w-xs inline "
               [(ngModel)]="eventItem.clientEvent.count2"
               [required]="true"
               pattern="/^[0-9]+$/"
               min="0"
               [name]="getNameEvent('communication-mechanic-daysfromlast-count2')"
        >
        <span class="input-group-addon">дней</span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      за {{eventItem.clientEvent.count1}} дней и после {{eventItem.clientEvent.count2}} дней
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicBirthdayComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem.clientEvent.compare = this.eventItem.clientEvent.compare || 'lt';
  }

}

@Component({
  selector: 'communication-mechanic-common',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <div class=" m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Выбор товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: eventItem?.goodList?.whiteListType || undefined  },
              forPartner: partnerId
            }, 'whiteGoods')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!partnerId"
          >
                <span [ngSwitch]="eventItem?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault >
                  <span>Выбраны все товары</span>
                </span>
              </span>

            <span *ngIf="eventItem?.goodList?.whiteIdList?.length>0">
                ( {{ eventItem?.goodList?.whiteIdList?.length }} )
              </span>

            <span *ngIf="eventItem?.goodList?.whiteSKUList?.length>0">
                ( {{ eventItem?.goodList?.whiteSKUList?.length }} )
              </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.whiteListType  "
            (click)="
            deleteField(eventItem?.goodList, 'whiteListType' );
            deleteField(eventItem?.goodList, 'whiteIdList' );
            deleteField(eventItem?.goodList, 'whiteSKUList' );
          ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Исключение товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
            initSelectedItems: { type: eventItem?.goodList?.blackListType || undefined  },
            forPartner: partnerId
          }, 'blackGoods')"
            [disabled]="!partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                    <span [ngSwitch]="eventItem?.goodList?.blackListType">
                        <span *ngSwitchCase="'sku'">
                        Исключено товаров
                      </span>
                      <span *ngSwitchCase="'category'">
                        Исключено категорий
                      </span>
                      <span *ngSwitchCase="'brand'">
                        Исключён бренд
                      </span>
                      <span *ngSwitchCase="'favourite'">
                        Исключены любимые товары
                      </span>
                      <span *ngSwitchCase="'custom'">
                        Исключены списки
                      </span>
                      <span *ngSwitchDefault>
                        Без исключений
                      </span>
                    </span>

            <span *ngIf="eventItem?.goodList?.blackIdList?.length>0">
                      ( {{ eventItem?.goodList?.blackIdList?.length }} )
                    </span>

            <span *ngIf="eventItem?.goodList?.blackSKUList?.length>0">
                      ( {{ eventItem?.goodList?.blackSKUList?.length }} )
                    </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.blackListType  "
            (click)="
            deleteField(eventItem?.goodList, 'blackListType' );
            deleteField(eventItem?.goodList, 'blackIdList' );
            deleteField(eventItem?.goodList, 'blackSKUList' );
          ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.whiteListType"
      >

        Товары, участвующие в ограничении:

        <span
          *ngIf=" !!eventItem?.goodList?.whiteSKUList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteSKUList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

        <span
          *ngIf=" !!eventItem?.goodList?.whiteIdList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteIdList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.whiteListType"
      >
        Для всех товаров
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.blackListType"
      >
        Товары, исключенные из ограничения:

        <span
          *ngIf=" !!eventItem?.goodList?.blackSKUList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackSKUList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

        <span
          *ngIf=" !!eventItem?.goodList?.blackIdList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackIdList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.blackListType"
      >
        Без исключений
      </div>
    </div>

  `,
  providers: [GoodsSelectorService, GoodsWatcherService],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicCommonComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  @Input() partnerId: any;

  constructor(
    public goodsSelectorService: GoodsSelectorService,
    public goodsWatcherService: GoodsWatcherService
  ) {
    super(goodsSelectorService, goodsWatcherService);
  }
  ngOnInit() {}

}

@Component({
  selector: 'communication-mechanic-listQuantity',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <div class=" m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Выбор товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: eventItem?.goodList?.whiteListType || undefined  },
              forPartner: partnerId,
              showCategories: ['custom']
            }, 'whiteGoods')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!partnerId"
          >
                <span [ngSwitch]="eventItem?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault >
                  <span>Выбраны все товары</span>
                </span>
              </span>

            <span *ngIf="eventItem?.goodList?.whiteIdList?.length>0">
                ( {{ eventItem?.goodList?.whiteIdList?.length }} )
              </span>

            <span *ngIf="eventItem?.goodList?.whiteSKUList?.length>0">
                ( {{ eventItem?.goodList?.whiteSKUList?.length }} )
              </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.whiteListType  "
            (click)="
            deleteField(eventItem?.goodList, 'whiteListType' );
            deleteField(eventItem?.goodList, 'whiteIdList' );
            deleteField(eventItem?.goodList, 'whiteSKUList' );
          ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Исключение товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
            initSelectedItems: { type: eventItem?.goodList?.blackListType || undefined  },
            forPartner: partnerId
          }, 'blackGoods')"
            [disabled]="!partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                    <span [ngSwitch]="eventItem?.goodList?.blackListType">
                        <span *ngSwitchCase="'sku'">
                        Исключено товаров
                      </span>
                      <span *ngSwitchCase="'category'">
                        Исключено категорий
                      </span>
                      <span *ngSwitchCase="'brand'">
                        Исключён бренд
                      </span>
                      <span *ngSwitchCase="'favourite'">
                        Исключены любимые товары
                      </span>
                      <span *ngSwitchCase="'custom'">
                        Исключены списки
                      </span>
                      <span *ngSwitchDefault>
                        Без исключений
                      </span>
                    </span>

            <span *ngIf="eventItem?.goodList?.blackIdList?.length>0">
                      ( {{ eventItem?.goodList?.blackIdList?.length }} )
                    </span>

            <span *ngIf="eventItem?.goodList?.blackSKUList?.length>0">
                      ( {{ eventItem?.goodList?.blackSKUList?.length }} )
                    </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.blackListType  "
            (click)="
            deleteField(eventItem?.goodList, 'blackListType' );
            deleteField(eventItem?.goodList, 'blackIdList' );
            deleteField(eventItem?.goodList, 'blackSKUList' );
          ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.whiteListType"
      >

        Товары, участвующие в ограничении:

        <span
          *ngIf=" !!eventItem?.goodList?.whiteSKUList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteSKUList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

        <span
          *ngIf=" !!eventItem?.goodList?.whiteIdList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteIdList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.whiteListType"
      >
        Для всех товаров
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.blackListType"
      >
        Товары, исключенные из ограничения:

        <span
          *ngIf=" !!eventItem?.goodList?.blackSKUList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackSKUList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

        <span
          *ngIf=" !!eventItem?.goodList?.blackIdList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackIdList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.blackListType"
      >
        Без исключений
      </div>
    </div>

  `,
  providers: [GoodsSelectorService, GoodsWatcherService],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicListQuantityComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  @Input() partnerId: any;

  constructor(
    public goodsSelectorService: GoodsSelectorService,
    public goodsWatcherService: GoodsWatcherService
  ) {
    super(goodsSelectorService, goodsWatcherService);
  }
  ngOnInit() {}

}


@Component({
  selector: 'communication-mechanic-commonQuantity',
  template: `

    <div class="input-group" *ngIf="isEdit">
      <input type="number" class="form-control inline "
             [(ngModel)]="eventItem.goodEvent.quantity"
             [name]="getNameEvent('communication-mechanic-commonQuantity-quantity')"
             [min]="0.001"
             [max]="999.999"
             [(ngGoodDimension)]="eventItem.goodEvent.dimension"
             [required]="true"
             data-testid="good.value"
      >
      <span class="input-group-addon ng-scope"
            data-testid="good.measure"
            [ngGoodDimensionName]="eventItem.goodEvent.dimension"
      >шт.</span>
    </div>

    <div class="d-flex" *ngIf="isEdit">

      <div class=" m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Выбор товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: eventItem?.goodList?.whiteListType || undefined  },
              forPartner: partnerId,
              filterByParams: {'filter.dimensions': eventItem.goodEvent.dimension}
            }, 'whiteGoods')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!partnerId"
          >
                <span [ngSwitch]="eventItem?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault >
                  <span>Выбраны все товары</span>
                </span>
              </span>

            <span *ngIf="eventItem?.goodList?.whiteIdList?.length>0">
                ( {{ eventItem?.goodList?.whiteIdList?.length }} )
              </span>

            <span *ngIf="eventItem?.goodList?.whiteSKUList?.length>0">
                ( {{ eventItem?.goodList?.whiteSKUList?.length }} )
              </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.whiteListType  "
            (click)="
            deleteField(eventItem?.goodList, 'whiteListType' );
            deleteField(eventItem?.goodList, 'whiteIdList' );
            deleteField(eventItem?.goodList, 'whiteSKUList' );
          ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Исключение товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
            initSelectedItems: { type: eventItem?.goodList?.blackListType || undefined  },
            forPartner: partnerId,
            filterByParams: {'filter.dimensions': eventItem.goodEvent.dimension}
          }, 'blackGoods')"
            [disabled]="!partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                    <span [ngSwitch]="eventItem?.goodList?.blackListType">
                        <span *ngSwitchCase="'sku'">
                        Исключено товаров
                      </span>
                      <span *ngSwitchCase="'category'">
                        Исключено категорий
                      </span>
                      <span *ngSwitchCase="'brand'">
                        Исключён бренд
                      </span>
                      <span *ngSwitchCase="'favourite'">
                        Исключены любимые товары
                      </span>
                      <span *ngSwitchCase="'custom'">
                        Исключены списки
                      </span>
                      <span *ngSwitchDefault>
                        Без исключений
                      </span>
                    </span>

            <span *ngIf="eventItem?.goodList?.blackIdList?.length>0">
                      ( {{ eventItem?.goodList?.blackIdList?.length }} )
                    </span>

            <span *ngIf="eventItem?.goodList?.blackSKUList?.length>0">
                      ( {{ eventItem?.goodList?.blackSKUList?.length }} )
                    </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.blackListType  "
            (click)="
            deleteField(eventItem?.goodList, 'blackListType' );
            deleteField(eventItem?.goodList, 'blackIdList' );
            deleteField(eventItem?.goodList, 'blackSKUList' );
          ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">


      <div class="w-full ">
        Количество: {{eventItem.goodEvent.quantity}} <span [ngGoodDimensionName]="eventItem.goodEvent.dimension">шт.</span>
      </div>


      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.whiteListType"
      >

        Товары, участвующие в ограничении:

        <span
          *ngIf=" !!eventItem?.goodList?.whiteSKUList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteSKUList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

        <span
          *ngIf=" !!eventItem?.goodList?.whiteIdList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteIdList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.whiteListType"
      >
        Для всех товаров
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.blackListType"
      >
        Товары, исключенные из ограничения:

        <span
          *ngIf=" !!eventItem?.goodList?.blackSKUList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackSKUList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

        <span
          *ngIf=" !!eventItem?.goodList?.blackIdList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackIdList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.blackListType"
      >
        Без исключений
      </div>

    </div>
  `,
  providers: [GoodsSelectorService, GoodsWatcherService],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicCommonQuantityComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  @Input() partnerId: any;

  constructor(
    public goodsSelectorService: GoodsSelectorService,
    public goodsWatcherService: GoodsWatcherService
  ) {
    super(goodsSelectorService, goodsWatcherService);
  }
  ngOnInit() {}

}


@Component({
  selector: 'communication-mechanic-commonAmount',
  template: `

    <div class="input-group "  *ngIf="isEdit">

      <input type="number" class="form-control w-xs inline   "
             [(ngModel)]="eventItem.goodEvent.total"
             [required]="true"
             [pattern]="moneyPattern"
             [min]="0"
             [name]="getNameEvent('communication-mechanic-commonAmount-total')"
      >
      <span class="input-group-addon" ngCurrencyNameLoya></span>
    </div>

    <div class="d-flex" *ngIf="isEdit">

      <div class=" m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Выбор товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: eventItem?.goodList?.whiteListType || undefined  },
              forPartner: partnerId
            }, 'whiteGoods')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!partnerId"
          >
                <span [ngSwitch]="eventItem?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault >
                  <span>Выбраны все товары</span>
                </span>
              </span>

            <span *ngIf="eventItem?.goodList?.whiteIdList?.length>0">
                ( {{ eventItem?.goodList?.whiteIdList?.length }} )
              </span>

            <span *ngIf="eventItem?.goodList?.whiteSKUList?.length>0">
                ( {{ eventItem?.goodList?.whiteSKUList?.length }} )
              </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.whiteListType  "
            (click)="
            deleteField(eventItem?.goodList, 'whiteListType' );
            deleteField(eventItem?.goodList, 'whiteIdList' );
            deleteField(eventItem?.goodList, 'whiteSKUList' );
          ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Исключение товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
            initSelectedItems: { type: eventItem?.goodList?.blackListType || undefined  },
            forPartner: partnerId
          }, 'blackGoods')"
            [disabled]="!partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                    <span [ngSwitch]="eventItem?.goodList?.blackListType">
                        <span *ngSwitchCase="'sku'">
                        Исключено товаров
                      </span>
                      <span *ngSwitchCase="'category'">
                        Исключено категорий
                      </span>
                      <span *ngSwitchCase="'brand'">
                        Исключён бренд
                      </span>
                      <span *ngSwitchCase="'favourite'">
                        Исключены любимые товары
                      </span>
                      <span *ngSwitchCase="'custom'">
                        Исключены списки
                      </span>
                      <span *ngSwitchDefault>
                        Без исключений
                      </span>
                    </span>

            <span *ngIf="eventItem?.goodList?.blackIdList?.length>0">
                      ( {{ eventItem?.goodList?.blackIdList?.length }} )
                    </span>

            <span *ngIf="eventItem?.goodList?.blackSKUList?.length>0">
                      ( {{ eventItem?.goodList?.blackSKUList?.length }} )
                    </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.blackListType  "
            (click)="
            deleteField(eventItem?.goodList, 'blackListType' );
            deleteField(eventItem?.goodList, 'blackIdList' );
            deleteField(eventItem?.goodList, 'blackSKUList' );
          ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">

      <div class="input-group ">
        Сумма: {{eventItem.goodEvent.total}} <span ngCurrencyNameLoya></span>
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.whiteListType"
      >

        Товары, участвующие в ограничении:

        <span
          *ngIf=" !!eventItem?.goodList?.whiteSKUList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteSKUList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

        <span
          *ngIf=" !!eventItem?.goodList?.whiteIdList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteIdList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.whiteListType"
      >
        Для всех товаров
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.blackListType"
      >
        Товары, исключенные из ограничения:

        <span
          *ngIf=" !!eventItem?.goodList?.blackSKUList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackSKUList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

        <span
          *ngIf=" !!eventItem?.goodList?.blackIdList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackIdList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.blackListType"
      >
        Без исключений
      </div>

    </div>
  `,
  providers: [GoodsSelectorService, GoodsWatcherService],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicCommonAmountComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  @Input() partnerId: any;

  constructor(
    public goodsSelectorService: GoodsSelectorService,
    public goodsWatcherService: GoodsWatcherService
  ) {
    super(goodsSelectorService, goodsWatcherService);
  }
  ngOnInit() {}

}


@Component({
  selector: 'communication-mechanic-list',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <div class=" m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Выбор товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: eventItem?.goodList?.whiteListType || undefined  },
              forPartner: partnerId
            }, 'whiteGoods')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!partnerId"
          >
                <span [ngSwitch]="eventItem?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault >
                  <span>Выбраны все товары</span>
                </span>
              </span>

            <span *ngIf="eventItem?.goodList?.whiteIdList?.length>0">
                ( {{ eventItem?.goodList?.whiteIdList?.length }} )
              </span>

            <span *ngIf="eventItem?.goodList?.whiteSKUList?.length>0">
                ( {{ eventItem?.goodList?.whiteSKUList?.length }} )
              </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.whiteIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.whiteListType
          })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.whiteListType  "
            (click)="
            deleteField(eventItem?.goodList, 'whiteListType' );
            deleteField(eventItem?.goodList, 'whiteIdList' );
            deleteField(eventItem?.goodList, 'whiteSKUList' );
          ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Исключение товаров
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
            initSelectedItems: { type: eventItem?.goodList?.blackListType || undefined  },
            forPartner: partnerId
          }, 'blackGoods')"
            [disabled]="!partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                    <span [ngSwitch]="eventItem?.goodList?.blackListType">
                        <span *ngSwitchCase="'sku'">
                        Исключено товаров
                      </span>
                      <span *ngSwitchCase="'category'">
                        Исключено категорий
                      </span>
                      <span *ngSwitchCase="'brand'">
                        Исключён бренд
                      </span>
                      <span *ngSwitchCase="'favourite'">
                        Исключены любимые товары
                      </span>
                      <span *ngSwitchCase="'custom'">
                        Исключены списки
                      </span>
                      <span *ngSwitchDefault>
                        Без исключений
                      </span>
                    </span>

            <span *ngIf="eventItem?.goodList?.blackIdList?.length>0">
                      ( {{ eventItem?.goodList?.blackIdList?.length }} )
                    </span>

            <span *ngIf="eventItem?.goodList?.blackSKUList?.length>0">
                      ( {{ eventItem?.goodList?.blackSKUList?.length }} )
                    </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" eventItem?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackSKUList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="eventItem?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
            viewGoodsPopup: eventItem?.goodList?.blackIdList,
            forPartner: partnerId,
            listType: eventItem?.goodList?.blackListType
          })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" eventItem?.goodList?.blackListType  "
            (click)="
            deleteField(eventItem?.goodList, 'blackListType' );
            deleteField(eventItem?.goodList, 'blackIdList' );
            deleteField(eventItem?.goodList, 'blackSKUList' );
          ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.whiteListType"
      >

        Товары, участвующие в ограничении:

        <span
          *ngIf=" !!eventItem?.goodList?.whiteSKUList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteSKUList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
                </span>

        <span
          *ngIf=" !!eventItem?.goodList?.whiteIdList?.length"
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.whiteIdList,
                     forPartner:partnerId,
                     listType: eventItem?.goodList?.whiteListType
                  })"
        >
                    <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.whiteListType"
      >
        Для всех товаров
      </div>

      <div class="clearfix  "
           *ngIf="!!eventItem?.goodList?.blackListType"
      >
        Товары, исключенные из ограничения:

        <span
          *ngIf=" !!eventItem?.goodList?.blackSKUList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackSKUList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
              </span>

        <span
          *ngIf=" !!eventItem?.goodList?.blackIdList?.length "
          (click)="viewGoodsPopup({
                     viewGoodsPopup:eventItem?.goodList?.blackIdList,
                     forPartner: partnerId,
                     listType: eventItem?.goodList?.blackListType
                  })"
        >
                  <i class="fa fa-eye text-success pointer"></i>
             </span>

      </div>

      <div class="clearfix  "
           *ngIf="!eventItem?.goodList?.blackListType"
      >
        Без исключений
      </div>

    </div>

  `,
  providers: [GoodsSelectorService, GoodsWatcherService],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicListComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;
  @Input() partnerId: any;

  constructor(
    public goodsSelectorService: GoodsSelectorService,
    public goodsWatcherService: GoodsWatcherService
  ) {
    super(goodsSelectorService, goodsWatcherService);
  }
  ngOnInit() {}

}



@Component({
  selector: 'communication-mechanic-uniq',
  template: `

    <div class="input-group " *ngIf="isEdit">
      <input type="number"
             class="form-control w-xs inline"
             [name]="getNameEvent('communication-mechanic-uniq-quantity')"
             [(ngModel)]="eventItem.goodEvent.quantity"
             [required]="true"
             pattern="[0-9]*"
             min="0"
             max="999"
      >
      <span class="input-group-addon">шт.</span>
    </div>
    <div class="input-group " *ngIf="!isEdit">{{eventItem.goodEvent.quantity}} шт</div>

  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicUniqComponent extends EventRootClass{

  @Input() eventItem: any;
  @Input() isEdit: any;
  constructor() { super(); }
}

@Component({
  selector: 'communication-mechanic-total-total',
  template: `
    <div class="d-flex" *ngIf="isEdit">

      <ng-select class="w-md col-auto p-l-none"
                 [(ngModel)]="eventItem.totalEvent.compare"
                 [items]="compareValues"
                 bindLabel="name"
                 bindValue="id"
                 [name]="getNameEvent('communication-mechanic-total-total-compare')"
                 [clearable]="false"
      >
      </ng-select>

      <div class="input-group  pull-left ">

        <input type="number" class="form-control w-xs inline   "
               [(ngModel)]="eventItem.totalEvent.total"
               [required]="true"
               [pattern]="moneyPattern"
               [min]="0"
               [name]="getNameEvent('communication-mechanic-total-total-total')"
        >
        <span class="input-group-addon" ngCurrencyNameLoya></span>
      </div>

    </div>

    <div class="input-group " *ngIf="!isEdit">
      {{ getCompareName(eventItem.totalEvent.compare)}} {{eventItem.totalEvent.total}} <span ngCurrencyNameLoya></span>
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicTotalTotalComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
    this.eventItem = this.eventItem || {};
    this.eventItem.totalEvent = this.eventItem.totalEvent || {};
    this.eventItem.totalEvent.compare = this.eventItem.totalEvent.compare || this.compareValues[0].id;
  }

}

@Component({
  selector: 'communication-mechanic-firstPurchase',
  template: `
    <div>
      <i *ngIf="isEdit" class="fa m-r-xs fa-info-circle text-info"></i> Данная покупка является первой у покупателя
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicFirstPurchaseComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
  }

}

@Component({
  selector: 'communication-mechanic-maxPurchase',
  template: `
    <div>
      <i  *ngIf="isEdit"  class="fa m-r-xs fa-info-circle text-info"></i> Данная покупка является максимальной
    </div>
  `,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CommunicationMechanicMaxPurchaseComponent extends  EventRootClass {

  @Input() eventItem: any;
  @Input() isEdit: any;

  constructor() { super(); }
  ngOnInit() {
  }

}




@NgModule({
  declarations: [
    CommunicationMechanicDaysFromRegistryComponent,
    CommunicationMechanicDaysFromLastComponent,
    CommunicationMechanicMaxCheckComponent,
    CommunicationMechanicTotalComponent,
    CommunicationMechanicLifevalueComponent,
    CommunicationMechanicProfileStateComponent,
    CommunicationMechanicAvailableChannelComponent,
    CommunicationMechanicDisableChannelComponent,
    CommunicationMechanicAfterFullRegistrationComponent,
    CommunicationMechanicAfterExpressRegistrationComponent,
    CommunicationMechanicTotalPerSeasonComponent,
    CommunicationMechanicDaysFromLastComponent,
    CommunicationMechanicBirthdayComponent,
    CommunicationMechanicCommonComponent,
    CommunicationMechanicListQuantityComponent,
    CommunicationMechanicCommonQuantityComponent,
    CommunicationMechanicCommonAmountComponent,
    CommunicationMechanicListComponent,
    CommunicationMechanicUniqComponent,
    CommunicationMechanicTotalTotalComponent,
    CommunicationMechanicFirstPurchaseComponent,
    CommunicationMechanicMaxPurchaseComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    DirectiveModule
  ],
  providers: [],
  bootstrap: [],
  exports: [
    CommunicationMechanicDaysFromRegistryComponent,
    CommunicationMechanicDaysFromLastComponent,
    CommunicationMechanicMaxCheckComponent,
    CommunicationMechanicTotalComponent,
    CommunicationMechanicLifevalueComponent,
    CommunicationMechanicProfileStateComponent,
    CommunicationMechanicAvailableChannelComponent,
    CommunicationMechanicDisableChannelComponent,
    CommunicationMechanicAfterFullRegistrationComponent,
    CommunicationMechanicAfterExpressRegistrationComponent,
    CommunicationMechanicTotalPerSeasonComponent,
    CommunicationMechanicDaysFromLastComponent,
    CommunicationMechanicBirthdayComponent,
    CommunicationMechanicCommonComponent,
    CommunicationMechanicListQuantityComponent,
    CommunicationMechanicCommonQuantityComponent,
    CommunicationMechanicCommonAmountComponent,
    CommunicationMechanicListComponent,
    CommunicationMechanicUniqComponent,
    CommunicationMechanicTotalTotalComponent,
    CommunicationMechanicFirstPurchaseComponent,
    CommunicationMechanicMaxPurchaseComponent
  ],
  entryComponents: []
})
export class CommunicationMechanicConditionsModule {

  constructor() {}

}

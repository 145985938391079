/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CampaignCategoryApiConfiguration as __Configuration } from '../campaign-category-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CampaignCategory } from '../models/campaign-category';
import { CampaignCategoryQueryParam } from '../models/campaign-category-query-param';
import { CampaignCategoryLink } from '../models/campaign-category-link';
@Injectable({
  providedIn: 'root',
})
class CampaignCategoryApiService extends __BaseService {
  static readonly queryPath = '/campaigncategory';
  static readonly createPath = '/campaigncategory';
  static readonly getPath = '/campaigncategory/{id}';
  static readonly updatePath = '/campaigncategory/{id}';
  static readonly deletePath = '/campaigncategory/{id}';
  static readonly queryLinksPath = '/campaigncategory/{id}/links';
  static readonly putLinkPath = '/campaigncategory/{id}/links';
  static readonly postLinksPath = '/campaigncategory/{id}/links';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос списка групп кампаний
   *
   * Запрос списка групп кампаний
   * @param params Фильтры запроса
   * @return Список групп кампаний
   */
  queryResponse(params?: CampaignCategoryQueryParam): __Observable<__StrictHttpResponse<Array<CampaignCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaigncategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignCategory>>;
      })
    );
  }
  /**
   * Запрос списка групп кампаний
   *
   * Запрос списка групп кампаний
   * @param params Фильтры запроса
   * @return Список групп кампаний
   */
  query(params?: CampaignCategoryQueryParam): __Observable<Array<CampaignCategory>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignCategory>)
    );
  }

  /**
   * Создать группу кампаний
   *
   * Создать группу кампаний
   * @param campaignCategory Данные группы кампаний
   * @return Группа кампаний создана
   */
  createResponse(campaignCategory: CampaignCategory): __Observable<__StrictHttpResponse<CampaignCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = campaignCategory;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaigncategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignCategory>;
      })
    );
  }
  /**
   * Создать группу кампаний
   *
   * Создать группу кампаний
   * @param campaignCategory Данные группы кампаний
   * @return Группа кампаний создана
   */
  create(campaignCategory: CampaignCategory): __Observable<CampaignCategory> {
    return this.createResponse(campaignCategory).pipe(
      __map(_r => _r.body as CampaignCategory)
    );
  }

  /**
   * Запрос группы кампаний по ID
   *
   * Запрос группы кампаний по ID
   * @param id ID группы кампаний
   * @return Данные группы кампаний
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<CampaignCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignCategory>;
      })
    );
  }
  /**
   * Запрос группы кампаний по ID
   *
   * Запрос группы кампаний по ID
   * @param id ID группы кампаний
   * @return Данные группы кампаний
   */
  get(id: number): __Observable<CampaignCategory> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as CampaignCategory)
    );
  }

  /**
   * Обновить данные группы кампаний
   *
   * Обновить данные группы кампаний
   * @param params The `CampaignCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID группы кампаний
   *
   * - `campaignCategory`: Данные группы кампаний
   *
   * @return Данные группы кампаний обновлены
   */
  updateResponse(params: CampaignCategoryApiService.UpdateParams): __Observable<__StrictHttpResponse<CampaignCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.campaignCategory;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignCategory>;
      })
    );
  }
  /**
   * Обновить данные группы кампаний
   *
   * Обновить данные группы кампаний
   * @param params The `CampaignCategoryApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID группы кампаний
   *
   * - `campaignCategory`: Данные группы кампаний
   *
   * @return Данные группы кампаний обновлены
   */
  update(params: CampaignCategoryApiService.UpdateParams): __Observable<CampaignCategory> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as CampaignCategory)
    );
  }

  /**
   * Удалить группу кампаний по ID
   *
   * Удалить группу кампаний по ID
   * @param id ID группы кампаний
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удалить группу кампаний по ID
   *
   * Удалить группу кампаний по ID
   * @param id ID группы кампаний
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запрос привязок к группе кампаний
   *
   * Запрос привязок к группе кампаний
   * @param params The `CampaignCategoryApiService.QueryLinksParams` containing the following parameters:
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Список привязок к группе кампаний
   */
  queryLinksResponse(params: CampaignCategoryApiService.QueryLinksParams): __Observable<__StrictHttpResponse<Array<CampaignCategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(params.id))}/links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignCategoryLink>>;
      })
    );
  }
  /**
   * Запрос привязок к группе кампаний
   *
   * Запрос привязок к группе кампаний
   * @param params The `CampaignCategoryApiService.QueryLinksParams` containing the following parameters:
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Список привязок к группе кампаний
   */
  queryLinks(params: CampaignCategoryApiService.QueryLinksParams): __Observable<Array<CampaignCategoryLink>> {
    return this.queryLinksResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignCategoryLink>)
    );
  }

  /**
   * Сохранить призяку к группе кампаний
   *
   * Сохранить призяку к группе кампаний
   * @param params The `CampaignCategoryApiService.PutLinkParams` containing the following parameters:
   *
   * - `link`: Привязка к группе кампаний
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Привзяка сохранена
   */
  putLinkResponse(params: CampaignCategoryApiService.PutLinkParams): __Observable<__StrictHttpResponse<CampaignCategoryLink>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.link;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(params.id))}/links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CampaignCategoryLink>;
      })
    );
  }
  /**
   * Сохранить призяку к группе кампаний
   *
   * Сохранить призяку к группе кампаний
   * @param params The `CampaignCategoryApiService.PutLinkParams` containing the following parameters:
   *
   * - `link`: Привязка к группе кампаний
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Привзяка сохранена
   */
  putLink(params: CampaignCategoryApiService.PutLinkParams): __Observable<CampaignCategoryLink> {
    return this.putLinkResponse(params).pipe(
      __map(_r => _r.body as CampaignCategoryLink)
    );
  }

  /**
   * Изменить состав группы кампаний
   *
   * Заполнить состав группы кампаний
   * @param params The `CampaignCategoryApiService.PostLinksParams` containing the following parameters:
   *
   * - `links`: Список привязок к группе кампаний
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Состав группы кампаний изменен
   */
  postLinksResponse(params: CampaignCategoryApiService.PostLinksParams): __Observable<__StrictHttpResponse<Array<CampaignCategoryLink>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.links;

    if (params.pagerLimit != null) __params = __params.set('pager.limit', params.pagerLimit.toString());
    if (params.pagerDrop != null) __params = __params.set('pager.drop', params.pagerDrop.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/campaigncategory/${encodeURIComponent(String(params.id))}/links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CampaignCategoryLink>>;
      })
    );
  }
  /**
   * Изменить состав группы кампаний
   *
   * Заполнить состав группы кампаний
   * @param params The `CampaignCategoryApiService.PostLinksParams` containing the following parameters:
   *
   * - `links`: Список привязок к группе кампаний
   *
   * - `id`: ID группы кампаний
   *
   * - `pager.limit`: Сколько записей вернуть (по умолчанию 50)
   *
   * - `pager.drop`: Сколько записей опустить от начала списка (по умолчанию 0)
   *
   * @return Состав группы кампаний изменен
   */
  postLinks(params: CampaignCategoryApiService.PostLinksParams): __Observable<Array<CampaignCategoryLink>> {
    return this.postLinksResponse(params).pipe(
      __map(_r => _r.body as Array<CampaignCategoryLink>)
    );
  }
}

module CampaignCategoryApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID группы кампаний
     */
    id: number;

    /**
     * Данные группы кампаний
     */
    campaignCategory: CampaignCategory;
  }

  /**
   * Parameters for queryLinks
   */
  export interface QueryLinksParams {

    /**
     * ID группы кампаний
     */
    id: number;

    /**
     * Сколько записей вернуть (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей опустить от начала списка (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for putLink
   */
  export interface PutLinkParams {

    /**
     * Привязка к группе кампаний
     */
    link: CampaignCategoryLink;

    /**
     * ID группы кампаний
     */
    id: number;

    /**
     * Сколько записей вернуть (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей опустить от начала списка (по умолчанию 0)
     */
    pagerDrop?: number;
  }

  /**
   * Parameters for postLinks
   */
  export interface PostLinksParams {

    /**
     * Список привязок к группе кампаний
     */
    links: Array<CampaignCategoryLink>;

    /**
     * ID группы кампаний
     */
    id: number;

    /**
     * Сколько записей вернуть (по умолчанию 50)
     */
    pagerLimit?: number;

    /**
     * Сколько записей опустить от начала списка (по умолчанию 0)
     */
    pagerDrop?: number;
  }
}

export { CampaignCategoryApiService }

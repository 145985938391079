<div class="cash-box_root">

  <div class="cash-box_header bg-light b-b">
    <cash-box-header>
    </cash-box-header>
  </div>

  <div class="cash-box_content">

    <div class="cash-box_items">
      <cash-box-items>
      </cash-box-items>
    </div>

    <div class="cash-box_client b-l">
      <div class="cash-box_client-inner">
        <cash-box-client>
        </cash-box-client>

        <cash-box-operations>
        </cash-box-operations>

        <div class="cash-box_buttons-block">
          <div
            class="col wrapper-md padder-sm col-sm-8 bg-danger pointer btn btn-danger no-radius f-s-16  d-flex justify-content-center align-items-center"
            (click)="cashBoxService.reset()"
            *ngIf="!cashBoxService.isProcessing && cashBoxService.state == CheckState.precheck"
            data-testid="cashbox.reset"
          >
            <span >СБРОСИТЬ</span>

          </div>

          <div class="col  wrapper-xs padder-xs bg-success pointer btn btn-success no-radius f-s-15 d-flex justify-content-center align-items-center"

             [ngClass]="{ 'col-sm-4': !cashBoxService.isProcessing && cashBoxService.state == CheckState.precheck  }"

             *ngIf="cashBoxService.state == CheckState.precheck"
             (click)="cashBoxService.process()"
             [disabled]="cashBoxService.isProcessing"
             data-testid="cashbox.process"
          >

             <span *ngIf="!cashBoxService.isProcessing">
                ОПЛАТИТЬ:
                <span class="clearfix">
                   <span [innerText]=" cashBoxService.totalForPay | loyaCurrency"></span>
                   <i class="fa le" ngCurrencyLoya ></i>
                </span>
            </span>

            <span *ngIf="cashBoxService.isProcessing">
                <i class="fa fa-2x fa-spinner fa-spin"></i>
            </span>

          </div>

        </div>

        <cash-box-check>
        </cash-box-check>

      </div>
    </div>

  </div>
</div>

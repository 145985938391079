import {CustomPeriodTypes, IssuingType, StartPeriodTypes, StopPeriodTypes} from "../IssuingTypes/IssuingType";
import {Utils} from "../utils.class";

export const CouponRuleBarcodeId = 'couponRuleBarcode';
export const CouponWithBarcodeId = 'couponWithBarcode';
export const CouponWithoutBarcodeId = 'couponWithoutBarcode';
export const ECouponId = 'eCoupon';
export const ImportedCouponId = 'importedCoupon';
export const ImportedCouponForPrintId = 'importedCouponForPrint';
export const StickerId = 'sticker';
export const VirtualRuleBarcodeId = 'virtualRuleBarcode';
export const ReferralCouponWithBarcodeId = 'referralCouponWithBarcode';
export const CouponWithBarcodeWithReceptParamsId = 'couponWithBarcodeWithReceptParams';

export enum CouponApplyTypes {
    APPLY_ON_REQUEST  = 'applyOnRequest',
    APPLY_IN_FIRST_CHECK  = 'applyInFirstCheck',
    APPLY_IN_FIRST_SUITED_CHECK = 'applyInFirstSuitedCheck',
}

export enum RewardWhatTypes {
    DISCOUNT  = 'discount',
    BONUS     = 'bonus',
    GIFT      = 'gift',
    FIXPRICE  = 'fixprice',
}

export enum OnGoodRewType {
    EVERY  = 'every',
    COUNT     = 'count',
    ALL      = 'all',
}

export enum RewardMeasureTypes {
    PERCENT  = 'percent',
    MONEY    = 'money',
    COUNT    = 'count'
}

export class CouponKind {

    static id:string = '';
    static getId() {
        return this.id;
    }

    protected prefix  = false; // Ввод префикса
    protected barcode = false; // Ввод баркода
    protected genereteBarcode = false; // Ввод баркода

    protected sameClientCount = false; // Выдача - выдавать
    protected canBeSold = false; // Выдача - продажа
    protected generatedCount = false; // Выдача - ??
    protected usable3rdParties = false; // Выдача 3м лицам
    protected useCount = true; // Количество использований

    protected useRewardWhat = true; // Блок вознаграждения
    protected useRewardRewType = true; // Блок вознаграждения

    public uniqueCouponGen = false; // Тип для генерации купонов

    protected importCoupons = false; // загрузка купонов

    public hasReferalReward = false;

    protected startPeriodArray: Array<StartPeriodTypes> = [];
    protected stopPeriodArray: Array<StopPeriodTypes> = [];
    protected customPeriodTypes: Array<CustomPeriodTypes> = [];

    protected rewardWhatTypes: Array<RewardWhatTypes> = [
        RewardWhatTypes.BONUS,
        RewardWhatTypes.DISCOUNT,
        RewardWhatTypes.GIFT
    ];

    protected onGoodRewTypes: Array<OnGoodRewType> = [
        OnGoodRewType.ALL,
   //   OnGoodRewType.COUNT,
        OnGoodRewType.EVERY,
    ];

    static $inject = ['ruleItem', 'issuingType' ];

    constructor( protected ruleItem: any,
                 protected issuingType: IssuingType ) {
        this.$init();
    }

    get hasPrefix() : boolean {
        return this.prefix;
    }

    get hasBarcode() : boolean {
        return this.barcode;
    }

    get hasGenereteBarcode() : boolean {
        return this.genereteBarcode;
    }

    public getId() {
        const parent : any = this.constructor;
        return parent.getId();
    }

    get hasStartPeriod(): boolean {
        return Array.isArray( this.getStartPeriod() ) && !!this.getStartPeriod().length;
    }

    public getStartPeriod(): Array<StartPeriodTypes> {
        return this.startPeriodArray;
    }

    get hasStopPeriod(): boolean {
        return Array.isArray( this.getStopPeriod() ) && !!this.getStopPeriod().length;
    }

    public getStopPeriod(): Array<StopPeriodTypes> {
        return this.stopPeriodArray;
    }

    get hasCustomPeriod() : boolean {
        return Array.isArray( this.getCustomPeriodTypes() ) && !!this.getCustomPeriodTypes().length;
    }

    public getCustomPeriodTypes() : Array<CustomPeriodTypes> {
        return this.customPeriodTypes;
    }

    get hasSameClientCount() : boolean {
        return this.sameClientCount;
    }

    get hasCanBeSold() : boolean {
        return this.canBeSold;
    }

    get hasGeneratedCount() : boolean {
        return this.generatedCount;
    }

    get hasUsable3rdParties() : boolean {
        return this.usable3rdParties;
    }


    get isUniqueCouponGen() : boolean {
        return this.uniqueCouponGen;
    }

    get hasUseCount() : boolean {
        return this.useCount;
    }

    get hasRewardWhat() : boolean {
        return this.useRewardWhat;
    }

    get hasRewardRewType() : boolean {
        return this.useRewardRewType;
    }

    public getCouponApply(): Array<CouponApplyTypes> {
        return [ CouponApplyTypes.APPLY_ON_REQUEST ];
    }

    public getRewardWhat(): Array<RewardWhatTypes> {
        return this.rewardWhatTypes;
    }

    public getOnGoodRewType(): Array<OnGoodRewType> {
        return this.onGoodRewTypes;
    }


    public getRewardMeasureList(): Array<RewardMeasureTypes> {

        switch ( Utils.getField(this.ruleItem, 'couponRule.reward.reward.what') ) {
            case RewardWhatTypes.DISCOUNT:
                return [ RewardMeasureTypes.PERCENT, RewardMeasureTypes.MONEY ];

            case RewardWhatTypes.BONUS:
                return [ RewardMeasureTypes.PERCENT, RewardMeasureTypes.COUNT ];

            case RewardWhatTypes.GIFT:
                return [ RewardMeasureTypes.COUNT ];

            case RewardWhatTypes.FIXPRICE:
                return [ RewardMeasureTypes.MONEY ];
        }

        return [];
    }


    get hasImportCoupons(): boolean {
        return this.importCoupons;
    }

    get canImportCoupons(): boolean {
        return false;
    }

    public $init() {}
    public $destroy() {}
    public $save() {}

}

'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";
import templateCashboxMain from "plugin/plugins/cashbox/views/loya.cashbox.main.html";

let routePath   = module.name + '.cashbox';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )

            .template( '<cashbox-page></cashbox-page>', true)

            .breadcrumbs( [ "Демо касса" ] )

            .permissions( {
              only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
            })

      }]);


import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";

import {
  LocationsDialogService
} from "../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../shared/class/utils/angularjs.utils";
import {SettingsService} from "../../../../../shared/service/api/Settings/Settings.service";
import {CouponRuleRootClass} from "../class/coupon-rule-root.class";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {GoodsWatcherService} from "../../../../../shared/component/goods-tree/service/goods-watcher.service";
import {CouponService} from "../../../../../shared/service/api/Coupon/Coupon.service";
import {
  ISSUING_TYPES, MAX_REWARD_LIMIT_MEASURE_TYPES, MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE, MAX_REWARD_LIMIT_SUBTYPES,
  REWARD_MEASURES_TYPES, REWARD_REWTYPE_TYPES,
  REWARD_WHAT_TYPES
} from "../../../../../shared/service/api/CouponRule/CouponRule.values";
import {CKEditorBuilder} from "class/CKEditorBuilder/CKEditorBuilder";
import {
  goodSelectorParams,
  GoodsSelectorService
} from "../../../../../shared/component/goods-tree/service/goods-selector.service";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {OnGoodRewType} from "../../../../../shared/service/api/CouponRule/couponKinds/CouponKind";

@Component({
  selector: 'coupon-rule-edit',
  templateUrl: './coupon-rule-edit.component.html',
  styleUrls: [
    `./coupon-rule-edit.component.scss`
  ],
  providers: [
    CouponRuleService,
    SettingsService,
    PartnerService,
    CampaignCategoryService,
    GoodsWatcherService,
    CouponService,
    LocationsDialogService,
    GoodsSelectorService,
    ngCurrencyL10nService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CouponRuleEditComponent
  extends CouponRuleRootClass
  implements OnInit, OnChanges {

  @Input() couponRuleItem: any;
  @Input() campaignForm: any
  @Output() anchorList = new EventEmitter()

  public loader = new LoaderHandlerClass();
  public settingsObj:any = {
    maxRewardLimit: {
      type: '',
      measure: '',
      enabled: false
    },
    prefixTimeout: 0,
    prefixDummy: undefined
  }
  public subscribers:any = {}

  public ISSUING_TYPE_LIST =
    Object.keys(this.ISSUING_TYPE_NAMES)
      .map( key => ({key, name: this.ISSUING_TYPE_NAMES[key]}));

  public COUPON_KIND_LIST = [];

  private ckeBuilder = new CKEditorBuilder()
    .setMonospace()
    .addRowColumnCounter()
    .setPlaceholders( [{
      group: 'Клиент',
      placeholders: [
        {label: 'Используемая карта клиента', value: 'client.usableCardNumber'}
      ]
    }]);

  public ckeOptions = {
    filterHtml: undefined,
    getCountFields: undefined,
    getCountChars: undefined,
    maxDescriptionValidator: undefined,
    optionsDescription: undefined,
  }

  public events = {
    issuingType: new EventEmitter(),
    couponKind: new EventEmitter()
  }


  public barcodeFlag;
  public barcodeBackup;
  public initBarcode;
  public patternBarcodeInput;
  public isBarcodeIsInvalid = false;

  public today;
  public tomorrow;
  public yesterday;

  public onGoodDimension;

  private loyaPermissions = getNg1Service('loyaPermissions');

  constructor(
    public couponRuleService: CouponRuleService,
    public settingsService: SettingsService,
    public partnerService: PartnerService,
    public campaignCategoryService: CampaignCategoryService,
    public goodsWatcherService: GoodsWatcherService,
    public couponService: CouponService,
    public locationsDialogService: LocationsDialogService,
    public goodsSelectorService: GoodsSelectorService,
    public ngCurrencyL10nService : ngCurrencyL10nService
  ) {
    super(ngCurrencyL10nService);
  }

  ngOnInit() {

    this.loader.startLoading('init');

    this.getSystemSettings();

    this.anchorList.emit({
      anchorI1: 'Параметры',
      anchorI2: 'Период и место действия',
      anchorI3: 'Аудитория',
      anchorI8: 'Механика',
      anchorI4: 'Информирование',
      anchorI5: 'Внешняя система лояльности',
      anchorI10: 'Прикреплённые изображения'
    })


    this.events.issuingType.subscribe(() => {

      this.recalcKindList();
      this.recalcPeriods();
      this.recalcReward();
      this.recalcRewType();
      this.recalcApplyEvery();
    });

    this.events.couponKind.subscribe(() => {
      this.recalcPrefix();
      this.recalcPeriods();
      this.recalcReward();
      this.recalcRewType();
      this.recalcApplyEvery();
    });

    this.today = new Date();
    this.today = `${this.today.getFullYear()}-${ ('00' + (this.today.getMonth() + 1 ) ).slice(-2)}-${('00' + this.today.getDate()).slice(-2)}`;

    this.tomorrow = new Date();
    this.tomorrow.setHours(12, 0, 0, 0, 0);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.tomorrow = `${this.tomorrow.getFullYear()}-${ ('00' + (this.tomorrow.getMonth() + 1 ) ).slice(-2)}-${('00' + this.tomorrow.getDate()).slice(-2)}`;

    this.yesterday = new Date();
    this.yesterday.setHours(12, 0, 0, 0, 0);
    this.yesterday.setDate(this.yesterday.getDate() );
    this.yesterday = `${this.yesterday.getFullYear()}-${ ('00' + (this.yesterday.getMonth() + 1 ) ).slice(-2)}-${('00' + this.yesterday.getDate()).slice(-2)}`;


    this.buildCkeBuilder();

    this.initCouponRule();
  }

  initCouponRule() {
    this.barcodeFlag = this.initBarcodeFlag()
    this.initBarcode = ( this.couponRuleItem.couponRule && this.couponRuleItem.couponRule.barcode ) ? this.couponRuleItem.couponRule.barcode : '';

    this.updateIssueAndKind();
    this.recalcKindList();
    this.recalcRewType();
  }

  get externalLoyaltyFlag() {
    return !!this?.settingsObj?.externalLoyalty
  }

  get isExternalLoyalty() {
    return this.externalLoyaltyFlag || !!this.couponRuleItem?.elSettings?.externalCode;
  }

  get virtualStopDate() {
    let newVal = [
      this.couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.value,
      this.couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.dimension,
      this.couponRuleItem?.couponRule?.issueSettings?.startDate
    ]

    if ( (!newVal[0] && !newVal[1]) || !newVal[2]  ) {
      return undefined;
    }

    let tmpVirtualStopDate = new Date( typeof newVal[2] === 'string' ? new Date(newVal[2])?.getTime() : newVal[2]?.getTime() );
    if (!newVal[0])
      newVal[0] = 0;

    switch( newVal[1] ) {

      case "day":

        tmpVirtualStopDate.setDate(
          tmpVirtualStopDate.getDate() +
          newVal[0] - 1
        );

        break;
      case "week":

        tmpVirtualStopDate.setDate(
          tmpVirtualStopDate.getDate() +
          ( newVal[0] * 7 )
        );

        break;
      case "month":
        tmpVirtualStopDate.setMonth(
          tmpVirtualStopDate.getMonth() +
          newVal[0]
        );
        break;
      case "year":
        tmpVirtualStopDate.setFullYear(
          tmpVirtualStopDate.getFullYear() +
          newVal[0]
        );
        break;

    }

    return  !!tmpVirtualStopDate ? `${tmpVirtualStopDate.getFullYear()}-${ ('00' + (tmpVirtualStopDate.getMonth() + 1 ) ).slice(-2)}-${('00' + tmpVirtualStopDate.getDate()).slice(-2)}` : undefined;

  }

  get sameClientCountDisabled() {
    return ['virtualRuleBarcode', 'sticker'].indexOf(this.couponRuleItem?.couponRule?.issueSettings?.couponKind) >= 0;
  }

  get couponPriceType() {
    return !!this.couponRuleItem?.couponRule?.issueSettings?.priceInCurrency ? 'currency' :
           !!this.couponRuleItem?.couponRule?.issueSettings?.priceInBonus ? 'bonus' :
          'currency'
  }

  set couponPriceType( value ) {
    switch (value) {
      case 'currency':
        if ( !this.couponRuleItem?.issueSettings?.priceInCurrency ) {
          this.couponRuleItem.issueSettings.priceInCurrency = this.couponRuleItem?.couponRule?.issueSettings?.priceInBonus;
        }

        if (this.couponRuleItem?.couponRule?.issueSettings)
          delete this.couponRuleItem?.couponRule?.issueSettings?.priceInBonus;

        break;

      case 'bonus':
        if ( !this.couponRuleItem?.couponRule?.issueSettings?.priceInBonus )  {
          this.couponRuleItem.issueSettings.priceInBonus = parseFloat( this.couponRuleItem?.couponRule?.issueSettings?.priceInCurrency );
        }

        if (this.couponRuleItem?.couponRule?.issueSettings)
          delete this.couponRuleItem?.couponRule?.issueSettings?.priceInCurrency

        break;

    }
  }

  get locationGroupId() {
    return this.couponRuleItem?.locationGroups?.[0]?.id;
  }

  set locationGroupId(id) {
    this.couponRuleItem.locationGroups = [];
    if (id)
      this.couponRuleItem.locationGroups.push({
        id,
        partnerId: this.couponRuleItem?.couponRule?.partnerId,
        name: ''
      });
  }

  get isPartnerSelected() {
    return !!this.couponRuleItem?.couponRule?.partnerId
  }

  public getSystemSettings() {

    this.settingsService.getByKey$('externalLoyalty')
      .subscribe({
        next: r => {
          this.settingsObj.externalLoyalty = r
          if (this.settingsObj.externalLoyalty)
            this.couponRuleItem.elSettings = this.couponRuleItem.elSettings || {};
        }
      })
  }

  get hasSelectedLocation() {
    return !!this.couponRuleItem?.locations?.length  || this.couponRuleItem?.locationGroups?.length;
  }

  get useCountEnabled() {
    let newVal = [
        this.couponRuleItem?.couponRule?.reward?.reward?.what,
        this.couponRuleItem?.couponRule?.reward?.reward?.measure,
        this.couponRuleItem?.couponRule?.issueSettings?.issuingType,
        this.couponRuleItem?.couponRule?.issueSettings?.couponKind,
        this?.couponRuleItem?.couponRule?.applySettings?.couponApply,
        this.couponRuleItem?.couponRule?.reward?.onGood?.rewType,
      ]

    if ( (!newVal[0] || !newVal[1] ) && newVal[2] !== 'public' ) {

      if ( !!this.couponRuleItem?.couponRule?.usingSettings?.useCount )
        delete this.couponRuleItem.couponRule.usingSettings.useCount;

      return false;
    }

    if (  newVal[3] === 'sticker' || newVal[3] === 'couponWithBarcodeWithReceptParams' ) {

      if ( !!this.couponRuleItem?.couponRule?.usingSettings?.useCount )
        delete this.couponRuleItem.couponRule.usingSettings.useCount;

      return false;
    }

    let ret = false;

    if ( newVal[0] === "gift" )
      if ( this.couponRuleItem.couponRule.applySettings.couponApply === "applyInFirstSuitedCheck" ) {

        if ( !!this.couponRuleItem?.couponRule?.applySettings?.minRewardInCurrency )
          delete this.couponRuleItem.couponRule.applySettings.minRewardInCurrency;

      } else {
        return true;
      }

    switch (true) {

      case  newVal[5] === 'count' :
      case  newVal[3] === 'virtualRuleBarcode' :
      case  newVal[1] === 'money' || newVal[1] === 'count' || newVal[2] === 'public':

        ret = false;
        this.couponRuleItem.couponRule.usingSettings.useCount = 1;
        break;

      case  newVal[1] === 'percent' :
        ret = true;
        break;

    }

    return ret;
  };

  get showMaxRewardValues() {

    if (this.couponRuleItem?.couponRule?.issueSettings?.issuingType === 'public' &&
      (this.couponRuleItem?.couponRule?.issueSettings?.couponKind === 'couponRuleBarcode' || this.couponRuleItem?.couponRule?.issueSettings?.couponKind === 'couponWithoutBarcode'))
      return true

    return !(['public'].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.issuingType ) >= 0) &&
      !( this.couponRuleItem?.couponRule?.issueSettings?.issuingType === 'pos' && this.couponRuleItem?.couponRule?.issueSettings?.couponKind === 'couponWithoutBarcode' )

  }

  get showApplyEvery() {

    if  ( [ 'eCoupon', 'couponWithoutBarcode','referralCouponWithBarcode', 'importedCouponForPrint'].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.couponKind ) >= 0 )
      return true;

    return this.couponRuleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcode' &&
      ['pos', 'uniqueCouponGen'].indexOf(this.couponRuleItem?.couponRule?.issueSettings?.issuingType) >= 0;

  }

  get filterGoodByParams() {
    const newVal = [
      this.couponRuleItem?.couponRule?.reward?.onGood?.dimension,
      this.couponRuleItem?.couponRule?.reward?.onGood?.rewType
    ]

    if ( newVal[1] === "all" ) {
      return {};
    }

    if (!newVal[0]) {
      return {};
    } else {
      return { 'filter.dimensions': newVal[0] };
    }
  };

  get rewTypeValues() {

    this.settingsObj.rewTypeValues = this.settingsObj.rewTypeValues || {...this.REWARD_REWTYPE_TYPES};

    if ( this.couponRuleItem?.couponRule?.issueSettings?.couponKind  === 'sticker' ) {

      if ( Object.keys(this.settingsObj.rewTypeValues).length != 1 || !this.settingsObj.rewTypeValues['POSITION'] )
        this.settingsObj.rewTypeValues = {
          'POSITION': this.REWARD_REWTYPE_TYPES.POSITION
        };

    } else {
      if (
        !this.settingsObj.rewTypeValues ||
        Object.keys(this.settingsObj.rewTypeValues || {})?.length != Object.keys(this.REWARD_REWTYPE_TYPES)?.length -1 ||
        !Object.keys(this.settingsObj.rewTypeValues || {})?.length
      ) {
        this.settingsObj.rewTypeValues = {...this.REWARD_REWTYPE_TYPES};
        delete this.settingsObj.rewTypeValues['POSITION']
      }
    }

    return this.settingsObj.rewTypeValues;
  }

  get maxRewardLimitEnabled() {

    return !!this.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit &&
      typeof !!this.couponRuleItem?.couponRule?.applySettings?.maxRewardValue !== 'undefined';

  }

  set maxRewardLimitEnabled(val) {

      if (!!val) {

        this.maxRewardLimitType = this.maxRewardLimitType || this.MAX_REWARD_LIMIT_SUBTYPES.COUPON;
        this.maxRewardLimitMeasure = this.maxRewardLimitMeasure || this.MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT;

      } else {
        if (!!this.couponRuleItem?.couponRule?.applySettings?.maxRewardValue)
          delete this.couponRuleItem.couponRule.applySettings.maxRewardValue;

        if (!!this.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit)
          delete this.couponRuleItem.couponRule.applySettings.maxRewardLimit;
      }

  }

  get maxRewardLimitValues() {

    this.settingsObj.maxRewardLimitValues = this.settingsObj.maxRewardLimitValues || Object.assign({}, this.MAX_REWARD_LIMIT_MEASURE_VALUES);

    return this.settingsObj.maxRewardLimitValues;
  }

  get isRewardRewardRewTypeDisabled() {
    return this.couponRuleItem.couponRule.reward.reward.what === REWARD_WHAT_TYPES.FIXPRICE &&
      Object.values(this.rewTypeValues).indexOf(REWARD_REWTYPE_TYPES.GOODS) >=0 &&
      this.currentCouponKind.getOnGoodRewType().indexOf(OnGoodRewType.COUNT) >=0
  }

  get isRewardOnGoodRewTypeDisabled() {
    return this.couponRuleItem.couponRule.reward.reward.what === REWARD_WHAT_TYPES.FIXPRICE &&
      Object.values(this.rewTypeValues).indexOf(REWARD_REWTYPE_TYPES.GOODS) >=0 &&
      this.currentCouponKind.getOnGoodRewType().indexOf(OnGoodRewType.COUNT) >=0
  }

  get maxRewardLimitType() {

    let finded = Object.values(this.MAX_REWARD_LIMIT_SUBTYPES)
              .find( i => this.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit?.indexOf(i) === 0)

    return finded || this.MAX_REWARD_LIMIT_SUBTYPES.COUPON;
  }

  set maxRewardLimitType(value) {

    this.couponRuleItem.couponRule.applySettings = this.couponRuleItem.couponRule.applySettings || {};
    if (!this.couponRuleItem.couponRule.applySettings.maxRewardLimit) {
      this.couponRuleItem.couponRule.applySettings.maxRewardLimit = value + this.capitalize(MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[value][0]);
    }

    let type = Object
                ?.values(this.MAX_REWARD_LIMIT_SUBTYPES)
                ?.reduce( (acc, i ) => acc.replace(i,''), this.couponRuleItem.couponRule.applySettings.maxRewardLimit)
                ?.toLowerCase();

    if ( MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[value].includes(type) ) {
      this.couponRuleItem.couponRule.applySettings.maxRewardLimit = value + this.capitalize(type);
    } else {
      this.couponRuleItem.couponRule.applySettings.maxRewardLimit = value + this.capitalize(MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[value][0]);
    }

  }

  get maxRewardLimitMeasure() {

    let finded = Object.values(this.MAX_REWARD_LIMIT_MEASURE_TYPES)
      .find( i => this.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit?.indexOf(this.capitalize(i)) >= 0)

    return finded || this.MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[this.maxRewardLimitType][0] || this.MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT;
  }

  set maxRewardLimitMeasure(value) {

    this.couponRuleItem.couponRule.applySettings = this.couponRuleItem.couponRule.applySettings || {};
    if (!this.couponRuleItem.couponRule.applySettings.maxRewardLimit) {
      this.couponRuleItem.couponRule.applySettings.maxRewardLimit = this.maxRewardLimitType + this.capitalize(MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[this.maxRewardLimitType][0]);
    }

    let type = Object
      ?.values(this.MAX_REWARD_LIMIT_MEASURE_TYPES)
      ?.reduce( (acc, i ) => acc.replace(this.capitalize(i),''), this.couponRuleItem.couponRule.applySettings.maxRewardLimit)
      ?.toLowerCase();

    if ( MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[type].includes(value) ) {
      this.couponRuleItem.couponRule.applySettings.maxRewardLimit = type + this.capitalize(value);
    }

  }

  capitalize(v) {
    return v.charAt(0).toUpperCase() + v.slice(1)
  };

  ngOnChanges(changes) {
    if (changes['couponRuleItem'] && !changes['couponRuleItem'].firstChange) {
      this.initCouponRule();
    }
  }

  onChangeIssuingType() {
    this.updateIssueAndKind();
  }

  onChangeCouponKind() {
    this.updateIssueAndKind();
  }

  onChangeRewardRewardWhat(item) {
    this.couponRuleItem.couponRule.reward.reward.what=item;
    this.couponRuleItem.couponRule.reward.reward.measure =
      this.initAndReturn('couponRuleItem.couponRule.reward.reward.measure', this.currentCouponKind?.getRewardMeasureList() )

    this.recalcReward();

  }

  updateIssueAndKind() {
    if (
        !this.couponRuleItem?.couponRule?.issueSettings?.issuingType  ||
        !ISSUING_TYPES[ this.couponRuleItem?.couponRule?.issueSettings?.issuingType ]
      )
      return;

    let issuingType= false;
    let couponKind = false;

    if ( !(this.currentIssueType instanceof ISSUING_TYPES[ this.couponRuleItem?.couponRule?.issueSettings?.issuingType ]) ) {
      this.currentIssueType = new ISSUING_TYPES[this.couponRuleItem?.couponRule?.issueSettings?.issuingType](this.couponRuleItem, false);
      issuingType = true;
    }

    if ( !this.couponRuleItem?.couponRule?.issueSettings?.couponKind) {
      this.couponRuleItem.couponRule.issueSettings.couponKind = this.currentIssueType.couponKindIds()[0]
      this.currentIssueType.changeCouponKindById(this.couponRuleItem?.couponRule?.issueSettings?.couponKind);
      this.currentCouponKind = this.currentIssueType.getCurrentCouponKind();
      couponKind = true;
    } else {

      let couponKinds = this.currentIssueType.couponKindIds();

      if (this.currentIssueType.getCurrentCouponKind() !== this.couponRuleItem?.couponRule?.issueSettings?.couponKind) {

        if ( couponKinds.indexOf( this.couponRuleItem?.couponRule?.issueSettings?.couponKind) < 0 )
          this.couponRuleItem.couponRule.issueSettings.couponKind = this.currentIssueType.couponKindIds()[0];

        this.currentIssueType.changeCouponKindById(this.couponRuleItem?.couponRule?.issueSettings?.couponKind);
        this.currentCouponKind = this.currentIssueType.getCurrentCouponKind();
        couponKind = true;
      }

    }

    issuingType && this.events.issuingType.emit(this.couponRuleItem?.couponRule?.issueSettings?.issuingType);
    couponKind && this.events.couponKind.emit(this.couponRuleItem?.couponRule?.issueSettings?.couponKind);
  }

  buildCkeBuilder() {
    this.ckeOptions.filterHtml     = this.ckeBuilder.filterHtml.bind(this.ckeBuilder);
    this.ckeOptions.getCountFields = this.ckeBuilder.getCountFields.bind(this.ckeBuilder);
    this.ckeOptions.getCountChars  = this.ckeBuilder.getCountChars.bind(this.ckeBuilder);
    this.ckeOptions.maxDescriptionValidator = this.ckeBuilder.getMaxlenValidator(2000);
    this.ckeOptions.optionsDescription = this.ckeBuilder.getConfig();
  }

  recalcKindList() {
    if (!this.currentIssueType?.couponKindIds()?.length) {
      this.COUPON_KIND_LIST =[];
      return;
    }

    this.COUPON_KIND_LIST =
      Object.keys(this.COUPON_KIND_NAMES)
        .map( key => ({key, name: this.COUPON_KIND_NAMES[key]}))
        .filter(item => this.currentIssueType.couponKindIds().includes(item.key) );
  }

  recalcPeriods() {

    if (! this.currentIssueType )
      return;

    const currentStartPeriods = this.currentIssueType?.getStartPeriod();
    if ( !currentStartPeriods || (Array.isArray(currentStartPeriods) && !currentStartPeriods.length) ) {
      delete this.couponRuleItem.couponRule.issueSettings.startDate;
      delete this.couponRuleItem.couponRule.issueSettings.startScheduled;
    }

    if (currentStartPeriods.indexOf(this.couponRuleItem.couponRule?.issueSettings?.startPeriod) < 0 ) {
      this.couponRuleItem.couponRule.issueSettings.startPeriod = currentStartPeriods[0];
    }

    const currentStopPeriods = this.currentIssueType?.getStopPeriod();
    if ( !currentStopPeriods || (Array.isArray(currentStopPeriods) && !currentStopPeriods.length) ) {
      delete this.couponRuleItem.couponRule.issueSettings.stopDate;
      delete this.couponRuleItem.couponRule.issueSettings.stopScheduled;
    }

    if (currentStopPeriods.indexOf(this.couponRuleItem.couponRule?.issueSettings?.stopPeriod) < 0 ) {
      this.couponRuleItem.couponRule.issueSettings.stopPeriod = currentStopPeriods[0];
    }


  }

  onChangePeriod(type) {
    switch (type) {

      case "startPeriod":
        switch( this.couponRuleItem?.couponRule?.issueSettings?.startPeriod ) {

          case 'fromIssue':

            delete this.couponRuleItem.couponRule.issueSettings.startDate;
            delete this.couponRuleItem.couponRule.issueSettings.startScheduled;

            break;
          case 'fromDate':

            delete this.couponRuleItem.couponRule.issueSettings.startScheduled;

            break;
          case 'scheduled':

            delete this.couponRuleItem.couponRule.issueSettings.startDate;

            this.couponRuleItem.couponRule.issueSettings.startScheduled =
              this.couponRuleItem.couponRule.issueSettings.startScheduled ||
              {
                dimension: Object.keys( this.ISSUE_SETTINGS_START_SCHEDULED_NAMES )[0]
              }

            break;

        }
      break;

      case "stopPeriod":
        switch( this.couponRuleItem?.couponRule?.issueSettings?.stopPeriod ) {

          case 'fromDate':

            delete this.couponRuleItem.couponRule.issueSettings.stopScheduled;
            break;

          case 'scheduled':

            delete this.couponRuleItem.couponRule.issueSettings.stopDate;

            if ( !this.couponRuleItem?.couponRule?.issueSettings?.stopScheduled ) {

              this.couponRuleItem.couponRule.issueSettings.stopScheduled = {
                dimension: Object.keys( this.ISSUE_SETTINGS_STOP_SCHEDULED_NAMES )[0]
              };
            }

            break;

        }
        break;


    }
  }

  onChangeCustomPeriod() {

    if ( !!this.couponRuleItem?.couponRule?.issueSettings?.customPeriod ) {

      delete this.couponRuleItem.couponRule.issueSettings.startPeriod;
      delete this.couponRuleItem.couponRule.issueSettings.stopPeriod;

      if (!this.couponRuleItem?.couponRule?.issueSettings?.customPeriodType)
        this.couponRuleItem.couponRule.issueSettings.customPeriodType =
          this.currentIssueType.getCustomPeriodTypes()?.[0]

    } else {
      this.couponRuleItem.couponRule.issueSettings.customPeriod = false;
      delete this.couponRuleItem.couponRule.issueSettings.customPeriodType;
      delete this.couponRuleItem.couponRule.issueSettings.customValue1;
      delete this.couponRuleItem.couponRule.issueSettings.customValue2;

      if (
        !this.couponRuleItem.couponRule.issueSettings.startPeriod &&
        !!this.currentIssueType.getStartPeriod().length
      ) {
        this.couponRuleItem.couponRule.issueSettings.startPeriod = this.currentIssueType.getStartPeriod()[0]
      }

      if ( !this.couponRuleItem.couponRule.issueSettings.stopPeriod && !!this.currentIssueType.getStopPeriod().length ) {
        this.couponRuleItem.couponRule.issueSettings.stopPeriod = this.currentIssueType.getStopPeriod()[0]
      }

    }

  }

  // PREFIX
  recalcPrefix() {

    if ( this.currentCouponKind?.hasPrefix ) {

      if (!this.settingsObj.prefix) {

         this.settingsObj.prefix = {
           head: '',
           tmp: this.couponRuleItem?.couponRule?.prefix,
           maxLen: 4
         }

        switch (true) {
          case ( typeof this.settingsObj?.prefix?.tmp === "string" && /^2/.test(this.settingsObj?.prefix?.tmp)  ):
            this.settingsObj.prefix.tmp = this.settingsObj.prefix.tmp.replace( /^2/, '' );
            this.settingsObj.prefix.head = '2';
            break;

          case ( typeof this.settingsObj.prefix.tmp === "string" && /^99/.test(this.settingsObj.prefix.tmp)  ):
            this.settingsObj.prefix.tmp = this.settingsObj.prefix.tmp.replace( /^99/, '' );
            this.settingsObj.prefix.head = '99';
            break;

          default:
            this.settingsObj.prefix.tmp = undefined;
            this.settingsObj.prefix.head = '2';
        }
      }

    } else {

      if (this.couponRuleItem?.couponRule) {
        delete this.couponRuleItem.couponRule.prefix;
        delete this.settingsObj.prefix;
      }

    }
  }

  get maxLenPrefix() {
      return  typeof this.settingsObj.prefix.head === 'string' ?  6 - this.settingsObj.prefix.head.length : 6;
  }

  get prefixCouponRule() {
    return !!this.settingsObj?.prefix?.couponRule ? this.settingsObj.prefix.couponRule : false;
  }

  prefixPad( value ) {
    if (!value)
      return '';

    return value.tmp ? String( "0000").slice(0, value.tmp.length <= value.maxLen ? value.maxLen - value.tmp.length : 0  ): value.tmp;
  };

  checkCurrentPrefix = ( inPrefix ) => {

    if ( !!this.settingsObj?.prefixTimeout )
      clearTimeout( this.settingsObj.prefixTimeout );

    this.settingsObj.prefixTimeout =
      setTimeout(() => {

        this.settingsObj.prefix.checkInProgress = true;
        const prefix = !!inPrefix ? inPrefix : this.couponRuleItem.couponRule.prefix
        return this.couponRuleService.queryCouponRuleForCreationRequest$(
          prefix,
        )
          .toPromise()
          .then((result) => {

            this.settingsObj.prefix = this.settingsObj.prefix || {};
            this.settingsObj.prefix.couponRule = false;
            this.settingsObj.prefix.checkInProgress = false;

            if (!result ||
              !Object.keys(result).filter(i => i[0] !== '$').length ||
              (!!this.couponRuleItem?.couponRule?.id && result.id === this.couponRuleItem.couponRule.id)) {
              this.couponRuleItem.couponRule = this.couponRuleItem.couponRule || {};
              this.couponRuleItem.couponRule.prefix = prefix;
              return false
            }

            this.settingsObj.prefix.couponRule = result;
            return {
              couponRule: result
            }

          }, () => {
            this.settingsObj.prefix.checkInProgress = false;
          });

      }, 400 );

  };

  setActivePrefix() {
    setTimeout(function () {
      try {
        document.getElementById('prefix_tmp').focus();
      } catch(e) {}
    });
  };

  //----------

  initBarcodeFlag = () => {
    // Новое правило
    if ( !this.couponRuleItem?.couponRule?.id ) {
      return false;
    }

    // Автосгенерировано
    if ( this.isBarcodeGenerated( this.couponRuleItem?.couponRule?.barcode ) ) {
      return false;
    }

    return true;

  };

  checkBarcodeInput = ( flag ) => {

    if ( flag ) {

      if (this.barcodeBackup) {
        this.couponRuleItem.couponRule.barcode = this.barcodeBackup;
      }

    } else {
      this.barcodeBackup = this.couponRuleItem.couponRule.barcode;
      this.couponRuleItem.couponRule.barcode = this.initBarcode;
    }

    this.patternBarcodeInput = flag ? /^(?!22)[0-9]+$/ : /^.*$/ ;

    this.checkBarcode(this.couponRuleItem.couponRule.barcode);

  };

  private checkBarcodeTimeId;
  checkBarcode = ( barcode )=> {

    if (this.checkBarcodeTimeId) {
      clearTimeout(this.checkBarcodeTimeId);
    }

    this.checkBarcodeTimeId = setTimeout(()=> {
      if ( !barcode ) {
        this.isBarcodeIsInvalid = true;
        return;
      }

      let params : any = {
        'barcode': barcode
      };

      if (this.couponRuleItem.couponRule && this.couponRuleItem.couponRule.id) {
        params.id = this.couponRuleItem.couponRule.id;
      }

      return this.couponRuleService.checkBarcode$(params)
        .toPromise()
        .then( () => {
          setTimeout(()=>this.isBarcodeIsInvalid = false);
        }, ( error ) => {
          error?.stopPopupError();
          setTimeout(()=> this.isBarcodeIsInvalid = error.error && error.error.text ? error.error.text : error.data  );
        });
    }, 500);
  };

  barcodeValidator() {
    let that = this;
    return () =>{
      return !(that.isBarcodeIsInvalid !== false && that.isBarcodeIsInvalid !== true);
    }
  }

  timeWords = ( type, value ) => {

    var types = {

      'day'   : [ "день"  , "дня"   , "дней" ],
      'week'  : [ "неделя", "недели" , "недель" ],
      'month' : [ "месяц" , "месяца" , "месяцев" ],
      'year'  : [ "год"   , "года"   , "лет" ]

    };

    if (!value && value !== 0 ) {
      return types[type][0];
    }

    switch (true) {

      case ["11","12","13","14","15","16","17","18","19"].indexOf( value.toString().slice(-2) ) >= 0: return types[type][2];

      case ["0","5","6","7","8","9"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][2];

      case ["1"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][0];

      case ["2", "3", "4"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][1];

    }

  };

  maxDate = (date1, date2) => {

      switch (true) {
        case ( !date1 && !date2 ): return undefined;
        case ( !date1 &&  !!date2 ): return date2;
        case ( !!date1 && !date2 ): return date1;
      }

      return ( Date.parse(date1) > Date.parse(date2) ) ? date1 : date2;

    };

  getDayById = ( id ) => {
    return this.couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf(id) >=0;
  }

  setDayById = ( id, event ) => {
    if (!!event) {
      if (!this.getDayById(id))
        this.couponRuleItem?.couponRule?.applySettings?.validDays.push(id);
    } else {
      this.couponRuleItem.couponRule.applySettings.validDays =
        this.couponRuleItem?.couponRule?.applySettings?.validDays.filter(i => i !== id );
    }
  }

  selectLocations() {
    if (!this.isPartnerSelected)
      return;

    this.locationsDialogService.open({
      forPartner: this.couponRuleItem?.couponRule?.partnerId,
      canCheckAll: false,
      initSelectedItems: this.couponRuleItem?.locations?.map( i => i.id),
      resultDirective: () => {}
    }).then( result => {

      this.couponRuleItem.locations = result?.locations?.map( i => ({
        id: i?.id,
        name: i?.name,
        code: i?.code,
        partnerId: i?.partnerId,
      }))

      if (!this.couponRuleItem?.locations?.length)
        delete this.couponRuleItem.locations;

    }, () => {
      if (!this.couponRuleItem?.locations?.length)
        delete this.couponRuleItem.locations;
    })
  }

  removeLocation(location ) {
    this.couponRuleItem.locations = this.couponRuleItem.locations || [];
    this.couponRuleItem.locations = this.couponRuleItem.locations?.filter( i => i !== location)
  }

  numberPercentPattern = function ( type, what ) {

    let discountPercent = /^(100|\d{1,2}(\.\d{1,2})?)$/i;
    let percent   = /^\d{1,5}$/i;
    let currency  = /^\d{1,9}(\.\d{1,2})?$/i;
    let bonusPercent = /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/i;
    let bonusCur  = /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/i;
    let other     = /^\d{1,6}$/i;

    if ( what === 'discount' && type === 'percent') {
      return discountPercent;
    }

    if ( what === 'bonus') {
      return type === 'percent' ?  bonusPercent : bonusCur;
    }

    return type === 'percent' ?  percent :
      type === 'money' ? currency :
        other;
  };

  rewardWhatFilter = function( arr ) {

    if (
      !this.loyaPermissions.byPath('campaign.mechanics.bonus').enabled  &&
      arr.some(i=> i==='bonus')
    ){
      arr.splice(arr.indexOf('bonus'), 1);
    }

    return arr;

  };

  addIfNotExist = function (value, array) {

    if (!Array.isArray(array))
      array = [];

    for (var i in array) {
      if (JSON.stringify(array[i]) === JSON.stringify(value))
        return false;
    }

    array.push(value);
    return array;
  };

  selectGoods(options: goodSelectorParams, type = '') {
    this.goodsSelectorService.open(options)
      .then( result => {

        switch (type) {
          case 'giftList':
            this.couponRuleItem.couponRule.reward.giftList = this.couponRuleItem?.couponRule?.reward?.giftList || [];
            result?.list?.forEach(( item ) => {

              this.addIfNotExist({
                sku: item.id,
                "price": "0.00",
                "planCount": 0,
                "realCount": 0,
                "total": 0

              }, this.couponRuleItem?.couponRule?.reward?.giftList);

            });
            break;

          case 'whiteGoods':
            if (Array.isArray(result.list) && result.list.length === 0  && result.type !== "favourite" ) {
              return;
            }

            this.couponRuleItem.couponRule.reward = this.couponRuleItem.couponRule.reward || {};
            this.couponRuleItem.couponRule.reward.goodList = this.couponRuleItem?.couponRule?.reward?.goodList || {};

            if ( result.type === undefined) {
                delete this.couponRuleItem?.couponRule?.reward?.goodList.whiteListType ;
                delete this.couponRuleItem?.couponRule?.reward?.goodList.whiteIdList;
                delete this.couponRuleItem?.couponRule?.reward?.goodList.whiteSKUList;
              return;
            }

            if ( this.couponRuleItem?.couponRule?.reward?.goodList.whiteListType !== result.type ) {
              this.couponRuleItem.couponRule.reward.goodList.whiteListType = result.type;

              switch( this.couponRuleItem?.couponRule?.reward?.goodList.whiteListType ) {

                case 'sku':
                  this.couponRuleItem.couponRule.reward.goodList.whiteSKUList = [];
                  delete this.couponRuleItem?.couponRule?.reward?.goodList.whiteIdList;
                  break;

                default:
                  this.couponRuleItem.couponRule.reward.goodList.whiteIdList = [];
                  delete this.couponRuleItem?.couponRule?.reward?.goodList.whiteSKUList;
                  break;
              }
            }

            result?.list?.forEach( ( item )=> {
              switch( this.couponRuleItem?.couponRule?.reward?.goodList.whiteListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.reward?.goodList.whiteSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.reward?.goodList.whiteIdList);
                  break;
              }

            });
            break;

          case 'blackGoods':
            if (Array.isArray(result.list) && result.list.length === 0  && result.type !== "favourite") {
              return;
            }

            this.couponRuleItem.couponRule.reward = this.couponRuleItem.couponRule.reward || {};
            this.couponRuleItem.couponRule.reward.goodList = this.couponRuleItem?.couponRule?.reward?.goodList || {};

            if ( result.type === undefined) {
              delete this.couponRuleItem?.couponRule?.reward?.goodList.blackListType;
              delete this.couponRuleItem?.couponRule?.reward?.goodList.blackIdList;
              delete this.couponRuleItem?.couponRule?.reward?.goodList.blackSKUList;
              return;
            }

            if ( this.couponRuleItem?.couponRule?.reward?.goodList.blackListType !== result.type ) {

              this.couponRuleItem.couponRule.reward.goodList.blackListType = result.type;

              switch( this.couponRuleItem?.couponRule?.reward?.goodList.blackListType ) {

                case 'sku':
                  this.couponRuleItem.couponRule.reward.goodList.blackSKUList = [];
                  delete this.couponRuleItem?.couponRule?.reward?.goodList.blackIdList ;
                  break;

                default:
                  this.couponRuleItem.couponRule.reward.goodList.blackIdList = [];
                  delete this.couponRuleItem?.couponRule?.reward?.goodList.blackSKUList ;
                  break;
              }


            }


            result.list.forEach( ( item ) => {
              switch( this.couponRuleItem?.couponRule?.reward?.goodList.blackListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.reward?.goodList.blackSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.reward?.goodList.blackIdList);
                  break;
              }

            });

            break;

          case 'whiteGoodsApply':
            if (Array.isArray(result.list) && result.list.length === 0  && result.type !== "favourite" ) {
              return;
            }

            this.couponRuleItem.couponRule.applySettings.rawSettings = this.couponRuleItem.couponRule.applySettings.rawSettings || {};
            this.couponRuleItem.couponRule.applySettings.rawSettings.goodList = this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList || {};

            if ( result.type === undefined) {
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteListType ;
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList;
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList;
              return;
            }

            if ( this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType !== result.type ) {
              this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteListType = result.type;

              switch( this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList.whiteListType ) {

                case 'sku':
                  this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList = [];
                  delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList;
                  break;

                default:
                  this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList = [];
                  delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList;
                  break;
              }
            }

            result?.list?.forEach( ( item )=> {
              switch( this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList);
                  break;
              }

            });
            break;

          case 'blackGoodsApply':
            if (Array.isArray(result.list) && result.list.length === 0  && result.type !== "favourite") {
              return;
            }

            this.couponRuleItem.couponRule.applySettings.rawSettings = this.couponRuleItem.couponRule.applySettings.rawSettings || {};
            this.couponRuleItem.couponRule.applySettings.rawSettings.goodList = this.couponRuleItem.couponRule.applySettings.rawSettings.goodList || {};

            if ( result.type === undefined) {
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackListType;
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackIdList;
              delete this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList;
              return;
            }

            if ( this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType !== result.type ) {

              this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackListType = result.type;

              switch( this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType ) {

                case 'sku':
                  this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList = [];
                  delete this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList ;
                  break;

                default:
                  this.couponRuleItem.couponRule.applySettings.rawSettings.goodList.blackIdList = [];
                  delete this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList ;
                  break;
              }


            }


            result.list.forEach( ( item ) => {
              switch( this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType ) {

                case 'sku':
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList);
                  break;

                default:
                  this.addIfNotExist(item.id, this.couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList);
                  break;
              }

            });

            break;
        }

      })
  }

  viewGoodsPopup( options: any ) {
    this.goodsWatcherService.open({
      list: options.viewGoodsPopup,
      forPartner: options.forPartner,
      listType: options.listType,
    }).catch(() => {});
  }

  checkForDeleteApplyGoods() {
    if ( !this.couponRuleItem?.couponRule?.applySettings?.applyEvery || !( parseFloat(this.couponRuleItem?.couponRule?.applySettings?.applyEvery) > 0) ) {
      this.deleteField(this.couponRuleItem?.couponRule?.applySettings?.rawSettings, 'goodList');
      this.deleteField(this.couponRuleItem?.couponRule?.applySettings, 'applyEvery');
    }
  }

  deleteField(obj, field) {
    if ( obj && typeof obj) {
      delete obj[field];
    }
  }

  maxRewardLimitFilter = (items) => {

    if ( !this.loyaPermissions.byPath('campaign.mechanics.bonus').enabled ) {
      items = Object.assign({}, items);
      delete items.bonus;
    }

    if (this.couponRuleItem.couponRule.issueSettings.issuingType === 'public' &&
      (this.couponRuleItem.couponRule.issueSettings.couponKind === 'couponRuleBarcode'||
        this.couponRuleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode')
    ) {
      items = Object.assign({}, items);
      Object.keys(items)
        .filter(k => k !== 'coupon')
        .forEach(k => delete items[k])

    }

    return items;
  }


  getValidatesForMaxRewardLimit(id, type) {

    switch(id) {
      case MAX_REWARD_LIMIT_SUBTYPES.COUPON + this.capitalize(MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT): return type === 'max' ? 65535 : /^\d+$/i;

      case MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT + this.capitalize(MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT): return type === 'max' ? 100 : /^\d+$/i;
      case MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT + this.capitalize(MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE): return type === 'max' ? 10000000000 : /^\d+([\.|,]\d{1,2})?$/i;

      case MAX_REWARD_LIMIT_SUBTYPES.BONUS + this.capitalize(MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT): return type === 'max' ? 100 : /^\d+$/i;
      case MAX_REWARD_LIMIT_SUBTYPES.BONUS + this.capitalize(MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE): return type === 'max' ? 10000000000 : /^\d+$/i;
    }

  }

  createPath = (path) => {
    path?.split('.').reduce((acc, index) => {
      acc[index] = acc[index] || {};
      return acc[index];
    }, this)
  }

  recalcReward() {

    if (this.currentCouponKind?.hasReferalReward) {
      if ( !this.couponRuleItem?.couponRule?.reward?.referalProgramData)
        this.createPath('couponRuleItem.couponRule.reward.referalProgramData.referReward');

      this.couponRuleItem.couponRule.reward.referalProgramData.referReward.measure =
        this.couponRuleItem.couponRule.reward.referalProgramData.referReward.measure || this.REWARD_MEASURE_RELATION['bonus'][0]

    } else {

      if ( !!this.couponRuleItem?.couponRule?.reward?.referalProgramData)
        delete this.couponRuleItem?.couponRule?.reward?.referalProgramData;

    }

    if (!(this.currentCouponKind?.hasReferalReward && !this.couponRuleItem?.couponRule?.reward?.referalProgramData?.needRewardReferal)) {
      this.createPath('couponRuleItem.couponRule.reward.reward');

      this.couponRuleItem.couponRule.reward.reward.what =
        this.couponRuleItem.couponRule.reward.reward.what ||
        this.rewardWhatFilter( this.currentCouponKind?.getRewardWhat() )[0];

      this.couponRuleItem.couponRule.reward.reward.measure =
        this.couponRuleItem.couponRule.reward.reward.measure ||
        this.currentCouponKind?.getRewardMeasureList()[0];

    }

    if ( this.couponRuleItem?.couponRule?.reward?.reward?.what==='gift' ) {
      this.couponRuleItem.couponRule.reward.giftList =
        this.couponRuleItem.couponRule.reward.giftList || {};

      this.couponRuleItem.couponRule.reward.reward = this.couponRuleItem.couponRule.reward.reward || {};
      this.couponRuleItem.couponRule.reward.reward.value = 1;
      this.couponRuleItem.couponRule.reward.reward.measure = "count";

    } else {
      if ( this.couponRuleItem?.couponRule?.reward?.giftList )
        delete this.couponRuleItem.couponRule.reward.giftList;
    }

    if ( ['importedCoupon'].indexOf( this.couponRuleItem.couponRule.issueSettings.couponKind ) >= 0 ) {
      this.couponRuleItem.couponRule.reward = this.couponRuleItem.couponRule.reward || {};
      this.couponRuleItem.couponRule.reward.reward = this.couponRuleItem.couponRule.reward.reward || {};
      this.couponRuleItem.couponRule.reward.reward.value = 0;
    }

    if (this.currentCouponKind?.hasRewardRewType) {
      this.createPath('couponRuleItem.couponRule.reward.reward');
      this.couponRuleItem.couponRule.reward.reward.rewType =
        this.couponRuleItem.couponRule.reward.reward.rewType ||
        Object.keys(this.REWARD_REWTYPE_TYPES)[0];

     if ( this.couponRuleItem?.couponRule?.reward?.reward?.rewType == this.REWARD_REWTYPE_TYPES.GOODS ) {
       this.createPath('couponRuleItem.couponRule.reward.onGood')
       this.couponRuleItem.couponRule.reward.onGood.rewType =
         this.couponRuleItem.couponRule.reward.onGood.rewType || this.currentCouponKind?.getOnGoodRewType()[0]

     }

    }

    if ( !this.currentCouponKind?.hasRewardWhat ) {
      this.createPath('couponRuleItem.couponRule.reward.reward')
      this.couponRuleItem.couponRule.reward.reward.what = this.rewardWhatFilter( this.currentCouponKind.getRewardWhat() )[0];
      this.couponRuleItem.couponRule.reward.reward.value = 0;
    }

    if ( this.couponRuleItem?.couponRule?.reward?.reward?.what === "gift" && this.couponRuleItem?.couponRule?.applySettings?.couponApply === "applyInFirstSuitedCheck" ) {
      delete this.couponRuleItem?.couponRule?.applySettings?.minRewardInCurrency;
    }

    if (this.couponRuleItem?.couponRule?.applySettings?.couponApply !=='applyInFirstSuitedCheck' ) {
      delete this.couponRuleItem?.couponRule?.applySettings?.minRewardInCurrency;
    }

    if (!this.REWARD_IGNORE_LIMIT_TYPES_NAMES[this.couponRuleItem.couponRule.reward.reward.what] ) {
      delete this.couponRuleItem?.couponRule?.reward?.ignoreLimits;
    }

    if (this.couponRuleItem.couponRule.reward.reward.what === REWARD_WHAT_TYPES.FIXPRICE &&
      Object.values(this.rewTypeValues).indexOf(REWARD_REWTYPE_TYPES.GOODS) >=0 &&
      this.currentCouponKind.getOnGoodRewType().indexOf(OnGoodRewType.COUNT) >=0
    ) {
      this.couponRuleItem.couponRule.reward.reward.rewType = REWARD_REWTYPE_TYPES.GOODS;
      this.couponRuleItem.couponRule.reward.onGood = this.couponRuleItem.couponRule.reward.onGood || {};
      this.couponRuleItem.couponRule.reward.onGood.rewType = OnGoodRewType.COUNT;
    }

    if ( !this.currentCouponKind.hasUsable3rdParties ) {
      delete this.couponRuleItem?.couponRule?.usingSettings?.usable3rdParties;
    } else  {
      this.couponRuleItem.couponRule.usingSettings = this.couponRuleItem?.couponRule?.usingSettings || {};
      this.couponRuleItem.couponRule.usingSettings.usable3rdParties = !!this.couponRuleItem.couponRule?.usingSettings?.usable3rdParties;
    }

    if ( !this?.currentCouponKind?.hasSameClientCount ) {
      delete this.couponRuleItem?.couponRule?.issueSettings?.sameClientCount;
    } else if ( this.sameClientCountDisabled ) {
      this.couponRuleItem.couponRule.issueSettings = this.couponRuleItem.couponRule.issueSettings || {}
      this.couponRuleItem.couponRule.issueSettings.sameClientCount = 1;
    }


    if ( !this?.currentCouponKind?.hasCanBeSold) {
      delete this.couponRuleItem?.couponRule?.issueSettings?.canBeSold;
      delete this.couponRuleItem?.couponRule?.issueSettings?.priceInCurrency;
      delete this.couponRuleItem?.couponRule?.issueSettings?.priceInBonus;
    } else {
      this.couponRuleItem.couponRule.issueSettings = this.couponRuleItem.couponRule.issueSettings || {};
      this.couponRuleItem.couponRule.issueSettings.canBeSold = !!this.couponRuleItem.couponRule.issueSettings.canBeSold;
    }

    switch( this.couponRuleItem?.couponRule?.reward?.reward?.rewType ) {

      case 'total':

        delete this.couponRuleItem?.couponRule?.reward?.onGood;
        delete this.couponRuleItem?.couponRule?.reward?.onGood?.quantity;
        delete this.couponRuleItem?.couponRule?.reward?.reward?.extensionType;
        delete this.couponRuleItem?.couponRule?.reward?.goodList;

        this.couponRuleItem.couponRule.reward.onTotal = this.couponRuleItem.couponRule.reward.onTotal || {};
        this.couponRuleItem.couponRule.reward.onTotal.rewType = this.couponRuleItem.couponRule.reward.onTotal.rewType || "total";
        this.couponRuleItem.couponRule.reward.onTotal.freqType = this.couponRuleItem.couponRule.reward.onTotal.freqType || "all";
        this.couponRuleItem.couponRule.reward.onTotal.valueOver = this.couponRuleItem.couponRule.reward.onTotal.valueOver || 0;
        this.couponRuleItem.couponRule.reward.onTotal.value = this.couponRuleItem.couponRule.reward.onTotal.value || 0;

      break;

      case 'position':
        delete this.couponRuleItem?.couponRule?.reward?.onTotal;
        delete this.couponRuleItem?.couponRule?.reward?.extensionType;

        this.couponRuleItem.couponRule.reward.reward.extensionType = "all";
        this.couponRuleItem.couponRule.reward.onGood = this.couponRuleItem.couponRule.reward.onGood || {};
        this.couponRuleItem.couponRule.reward.onGood.rewType = this.couponRuleItem.couponRule.reward.onGood.rewType || "all";
        break;

      case 'goods':

        delete this.couponRuleItem?.couponRule?.reward?.onTotal;

        this.couponRuleItem.couponRule.reward.reward.extensionType = "all";

        this.couponRuleItem.couponRule.reward.onGood = this.couponRuleItem.couponRule.reward.onGood || {};
        this.couponRuleItem.couponRule.reward.onGood.rewType = this.couponRuleItem.couponRule.reward.onGood.rewType || "every";
        this.couponRuleItem.couponRule.reward.onGood.dimension = this.couponRuleItem.couponRule.reward.onGood.dimension || "piece";
      break;


      case 'setOfGoods':
        delete this.couponRuleItem?.couponRule?.reward?.onTotal;
        delete this.couponRuleItem?.couponRule?.reward?.onGood;

        this.couponRuleItem.couponRule.reward.reward.extensionType = "all";
        break;
    }

    switch( this.couponRuleItem.couponRule.reward.reward.what ) {

      case 'discount':
        delete this.couponRuleItem?.couponRule?.reward?.giftList;
      break;

      case 'bonus':
        delete this.couponRuleItem?.couponRule?.reward?.giftList;
        break;

      case 'gift':
        this.couponRuleItem.couponRule.reward.reward.value = 1;
        this.couponRuleItem.couponRule.reward.reward.measure = "count";
      break;
    }

  }

  recalcRewType() {

    if (!this.couponRuleItem?.couponRule?.reward?.reward?.rewType) {
      this.couponRuleItem.couponRule = this.couponRuleItem.couponRule || {};
      this.couponRuleItem.couponRule.reward = this.couponRuleItem.couponRule.reward || {};
      this.couponRuleItem.couponRule.reward.reward = this.couponRuleItem.couponRule.reward.reward || {};
    }

    if ( !Object.values(this.rewTypeValues).includes(this.couponRuleItem.couponRule.reward.reward.rewType) ) {
      this.couponRuleItem.couponRule.reward.reward.rewType = Object.values(this.rewTypeValues)[0];
    }

    if ( this.couponRuleItem?.couponRule?.reward?.reward?.rewType == this.REWARD_REWTYPE_TYPES.GOODS ) {

    }

  }

  recalcApplyEvery() {

    if (!this.showApplyEvery && !!this?.couponRuleItem?.couponRule?.applySettings?.applyEvery) {
      delete this.couponRuleItem.couponRule.applySettings.applyEvery;
    }
  }

  initAndReturn(objPath, values) {

    const path = objPath?.split('.');
    const index = path[path?.length - 1];
    const root = path?.slice(0,-1).reduce((acc, index) => {
      acc[index] = acc[index] || {};
      return acc[index];
    }, this)

    if (!!root[index]) {

      let valArray = Array.isArray(values) ? values : Object.values(values);
      if (!valArray.includes(root[index])) {
        root[index] = values[0];
      }

      return root[index];
    }

    if ( Array.isArray(values) ) {
      root[index] = values[0];
      return root[index];
    }

    if ( typeof values === 'object' ) {
      root[index] = Object.values(values)[0];
      return root[index];
    }

    return ;
  }

  onChangePartner() {

    this.locationGroupId = undefined;
    this.couponRuleItem.locations = [];

  }

}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CategoryApiService} from "../../../../api/CategoryApi/services";

import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {CategoryGoodDimension} from "../../../../api/CategoryApi/models/category-good-dimension";
import {Good} from "../../../../api/GoodApi/models/good";
import {Category} from "../../../../api/CategoryApi/models/category";

@Injectable({
  providedIn: "root"
})
export class CategoryModelService {

  constructor(
    private categoryApiService:CategoryApiService,
    private httpClient:HttpClient,
  ) {
  }

  public getTreeParams = new ParamsHandler({
    pager: {
      type: "string",
      dropField: "pagerDrop",
      limitField: "pagerLimit",
      limit: 200
    },
    filters: [
      {
        field: 'partnerId',
        currentValue: undefined
      },
      {
        field: 'sort',
        currentValue: undefined
      },
      {
        field: 'filterRootId',
        currentValue: undefined
      },
      {
        field: 'filterDimensions',
        currentValue: undefined
      }
    ],
  });

  getTree$( body: CategoryApiService.TreeParams ) {
    return this.categoryApiService.tree(body)
  }

  getSearchTree$( body: CategoryApiService.SearchParams ) {
    return this.categoryApiService.search(body)
  }

  get$( categoryId, partnerId ) {
    return this.categoryApiService.get({
      categoryId,
      partnerId
    })
  }

  update$( categoryId: number | Category, partnerId?: number, category?: Category) {

    return this.categoryApiService.update({
      categoryId: typeof categoryId === "object" ? categoryId.categoryId : categoryId,
      partnerId: typeof categoryId === "object" ? categoryId.partnerId : partnerId,
      category: typeof categoryId === "object" ? categoryId : category,
    })

  }

  getFullPathByCategory$(categoryId, partnerId ) {
    return this.httpClient.get(`/good/${partnerId}/${categoryId}/path`,{
      responseType: "json"
    })
  }

}

import {
  Directive,
  ElementRef,
  Injector,
  SimpleChanges,
  Input,
  Output,
  OnInit,
  Inject,
  OnDestroy,
  NgZone
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NgModel} from "@angular/forms";

// Polyfill setImmediate function.
const setImmediate = window && window.setImmediate ? window.setImmediate : function (fn) {
  setTimeout(fn, 0);
};

@Directive({
  selector: '[ckeditor]'
})
export class CkeditorDirective implements OnInit, OnDestroy {

  @Input('ckeditor') config  : any;

  public editorElement;
  public instance;
  public readyDeferred: any;
  public changesSubscriber: any;

  constructor(
    private elementRef: ElementRef,
    private ngModel: NgModel,
    public injector: Injector,
    private ngControl: NgControl,
    private ngZone: NgZone
  ) {
  }

  async ngOnInit() {
    this.editorElement = this.elementRef.nativeElement;
    await this.createEditor();
    this.bindEvents();

    this.onCKEvent('instanceReady', function() {
      this.readyDeferred.resolve(true);
    });

    this.changesSubscriber =
      this.ngControl.statusChanges
        .subscribe((status) => {
          this.updateStatus();
        });
  }

  bindEvents() {
    let that = this;

    ['dataReady', 'change', 'blur', 'saveSnapshot'].forEach( (event) => {
      this.onCKEvent(event, ()=> {

        const val = that.instance.getData() || '';

        this.ngZone.run(() => {
          that.ngControl.viewToModelUpdate(val);
          that.ngControl.valueAccessor.writeValue(val);
          that.ngControl.reset(val);
        });

      });
    });
  }

  onCKEvent(event, listener) {
    this.instance.on(event, asyncListener);

    function asyncListener() {
      var args = arguments;
      setImmediate(function () {
        applyListener.apply(null, args);
      });
    }

    function applyListener() {
      var args = arguments;
      setTimeout( () => {
        listener.apply(null, args);
      }, 0);
    }

    // Return the deregistration function
    return function $off() {
      this.instance.removeListener(event, applyListener);
    };
  }

  async createEditor() {
    // Create editor instance.
    if (this.editorElement.hasAttribute('contenteditable') &&
      this.editorElement.getAttribute('contenteditable').toLowerCase() == 'true') {
      this.instance = CKEDITOR.inline(this.editorElement, this.config);
    }
    else {
       this.instance = CKEDITOR.replace(this.editorElement, this.config);
    }

    await new Promise( r =>
      setTimeout(() => {
        this.instance.on('instanceReady', function() {
          r();
        });
      }, 0 )
    )

    this.instance.setReadOnly(!! this.editorElement.hasAttribute('disabled') );
    this.updateStatus();
  }

  updateStatus() {
    if (this.ngControl.invalid) {
      this.instance?.container?.$?.classList?.add('ng-invalid');
    } else {
      this.instance?.container?.$?.classList?.remove('ng-invalid');
    }
  }

  ngOnDestroy() {
    try {
      this?.instance?.destroy(false);
    } catch (e) {
    }

    this.changesSubscriber?.unsubscribe();
  }

}

<div class="fixed-tool-panel">

  <button
    class="btn btn-success btn-sm btn-addon pull-right"
    type="button"
    [uiSref]="'^.transaction'"
    *ngIf="canTransactions()"
    data-testid="account.report()"
  >
    <i class="fa fa-th-list "></i>
    <span class="btn-name ">Отчет по операторам</span>
  </button>


</div>

<!--  <div class="wrapper">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Никнейм/Промокод"
    ></search-input>
  </div>-->

 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>
           <sort-view
             column="account.number"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             № Счёта
           </sort-view>
         </th>
         <th class="text-right">
           <sort-view
             column="account.balance"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             Активный баланс
           </sort-view>
         </th>
         <th  class="text-right">
           <sort-view
             column="account.awardAmount"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             Начисленные бонусы
           </sort-view>
         </th>
         <th  class="text-right">
           <sort-view
             column="account.salesAmount"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             Списанные бонусы
           </sort-view>
         </th>
         <th>
           ФИО клиента
         </th>
         <th>
           <sort-view
             column="account.createDate"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             Дата создания
           </sort-view>
         </th>
         <th>
           <sort-view
             column="user.name"
             [params]="accountService.getListParams"
             (onChange)="reset()"
           >
             Имя пользователя
           </sort-view>
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listAccounts">
          <tr>

            <td class=" table-big-link">
              <a [uiSref]="'^.edit({id:' + item?.account?.id + ', client_id: '+ item?.clients[0]?.id + '})'"
                 data-testid="accountRow.account.number"
                 [innerText]="item?.account?.number || ''"
              >
              </a>
            </td>

            <td class="text-right"
                data-testid="accountRow.account.balance"
                [innerText]="item?.account?.balance || 0"
            ></td>

            <td class="text-right"
                data-testid="accountRow.account.awardAmount"
                [innerText]="item?.account?.awardAmount || 0"
            >
            </td>
            <td class="text-right"
                data-testid="accountRow.account.salesAmount"
                [innerText]="item?.account?.salesAmount || 0"
            >
            </td>
            <td class="table-big-link">
              <ng-container
                *ngIf="!!item?.clients?.length">
                <div class="" *ngFor="let clientRow of item.clients"
                     data-testid="clientRow in accountRow.clients">

                      <a [uiSref]="'frontend.crm.clients.view({id:' + clientRow.id +'})'"
                         data-testid="account.clientRow.fio"
                      >
                          {{clientRow.surname  || ''}} {{clientRow.name  || ''}} {{clientRow.patronymic  || ''}}
                        <span *ngIf="clientRow.state==='archive'" class="f-s-9 text-danger text-muted">(Клиент в архиве)</span>
                      </a>

                </div>
              </ng-container>
            </td>
            <td class=" table-big-link"
                data-testid="accountRow.account.createDate">
              {{item?.account?.createDate | loyaDate }}
            </td>
            <td class=" table-big-link"
                data-testid="accountRow.user.name">
              {{ item?.user?.name || ''}}
            </td>

          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

<div class="m-t-lg text-center"
     *ngIf="loader.isLoading('list')">
  <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
</div>

<scroll-up></scroll-up>


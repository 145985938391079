import {CouponKind} from "../couponKinds/CouponKind";

export const ExternalId = 'external';
export const ManualId = 'manual';
export const PosId = 'pos';
export const PublicId = 'public';
export const TriggerId = 'trigger';
export const UniqueCouponGenId = 'uniqueCouponGen';

const COUPON_KIND_PATH = 'issueSettings.couponKind';

export enum StartPeriodTypes {
    START_PERIOD_FORM_ISSUE = 'fromIssue',
    START_PERIOD_FORM_DATE  = 'fromDate',
    START_PERIOD_SCHEDULED  = 'scheduled'
}

export enum StopPeriodTypes {
    STOP_PERIOD_FORM_DATE  = 'fromDate',
    STOP_PERIOD_SCHEDULED  = 'scheduled'
}

export enum CustomPeriodTypes {
    CLIENT_BIRTHDAY= 'clientBirthday',
}

export class IssuingType {

    static couponKindsArray: Array<any>=[]
    static getCouponKinds(): Array<CouponKind> {
        return this.couponKindsArray;
    };

    static id:string = '';
    static getId() {
        return this.id;
    }

    ;

    protected currentCouponKind: CouponKind;
    protected startPeriodArray : Array<StartPeriodTypes> = [];
    protected stopPeriodArray  : Array<StopPeriodTypes>  = [];

    protected customPeriodsArray  : Array<CustomPeriodTypes>  = [];

    static $inject = ['ruleItem', 'viewState' ];

    constructor( protected ruleItem: any,
                 private viewState = false) {
        this.checkCouponKind();
    }

    public getId() {
        const parent : any = this.constructor;
        return parent.getId();
    }

    // помошник для быстрого доступа с обьекту правила
    private getPathFromRule( path:string ) {
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, '');           // strip a leading dot
        let obj = this.ruleItem;
        var a = path.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in obj) {
                obj = obj[k];
            } else {
                return;
            }
        }

        return obj;
    }

    public changeViewState( value: boolean ) {
        this.viewState = !!value;
    }

    get isViewState() {
        return this.viewState;
    }

    public getCurrentCouponKind() {
        return this.currentCouponKind;
    }

    public couponKinds(): Array<CouponKind> {
        // Хак для наследников
        const parent : any = this.constructor;
        return parent.getCouponKinds();
    }

    public couponKindIds(): Array<string> {
        // Хак для наследников
        const parent : any = this.constructor;
        return parent.couponKindsArray.map( (i:CouponKind) => i.getId() );
    }

    public changeCouponKindById( id: string ) : CouponKind | boolean {

        let findedCouponKinde : any = this.couponKinds()
            .find( (i :any ) => i.getId() === id);

        if ( !findedCouponKinde )
            return false;

        if (this.currentCouponKind && !(this.currentCouponKind instanceof findedCouponKinde))
            this.currentCouponKind.$destroy();

        this.currentCouponKind = new findedCouponKinde( this.ruleItem, this );
        return this.currentCouponKind ;
    }

    private checkCouponKind() {
        const currentCouponKindId = this.getPathFromRule( COUPON_KIND_PATH );

        if (
            this.currentCouponKind &&
            this.currentCouponKind.getId() === currentCouponKindId
        ) {
            return;
        }

        if ( !this.changeCouponKindById(currentCouponKindId) ) {
            this.changeCouponKindById(this.couponKinds()[0].getId());
        }

    }

    public getStartPeriod() {

        if ( this.currentCouponKind.hasStartPeriod ){
            return this.currentCouponKind.getStartPeriod();
        }

        return this.startPeriodArray;

    }

    public getCustomPeriodTypes() {

        if ( this.currentCouponKind.hasCustomPeriod ){
            return this.currentCouponKind.getCustomPeriodTypes();
        }

        return this.customPeriodsArray;

    }

    public getStopPeriod() {

        if ( this.currentCouponKind.hasStopPeriod ){
            return this.currentCouponKind.getStopPeriod();
        }

        return this.stopPeriodArray;

    }

    public $save() {
        this.currentCouponKind.$save();
    }

}

<div class="cash-box-header_date">
  <span class="f-s-12 text-info-dk line-dashed ">
    {{cashBoxService.currentCheck.date | loyaDateTime}}
  </span>

  <i class="fa fa-spin fa-spinner text-warning"
     *ngIf="!!cashBoxService.isProcessing"
  ></i>

</div>

<ng-container
  *ngIf="!loading.partner"
>
  <div class="cash-box-header_partner"
     ngbDropdown
       [container]="'body'"
>
  <div ngbDropdownToggle
       class="pointer"
       data-testid="cashbox.change.partner"
  >
    <span
        *ngIf="!hasPartner"
        class="text-danger"
      >
      <i class="fa fa-warning"></i>
      Выберите партнёра
    </span>

    <span
      *ngIf="hasPartner"
      class="text-primary-dk"
    >
      {{ getPartnerName( cashBoxService.partnerId ) }}
      <i class="caret m-l-xxs "></i>
    </span>
  </div>

  <div ngbDropdownMenu
       class="cash-box-header_partner-menu"
  >
    <ng-container
      *ngFor="let item of partnerList"
    >
      <button ngbDropdownItem
              class="d-flex"
              (click)="selectPartner(item)"
              data-testid="cashbox.partner_line.item"
      >
        <div class="col text-break"
             [innerText]="item.name">
        </div>

<!--
        <div class="col-auto text-right text-muted text-xs no-padder m-l-xs">
          ( ID: {{ item.id}} )
        </div>
-->

      </button>
    </ng-container>
  </div>

</div>
</ng-container>
<i
  *ngIf="loading.merchant || loading.partner"
  class="fa fa-spin fa-spinner text-warning m-l-md"></i>

<ng-container
  *ngIf="!loading.merchant && !loading.partner"
>
  <div class="cash-box-header_store">

    <span
      *ngIf="!hasMerchant"
      class="text-danger pointer"
      (click)="selectStore()"
      data-testid="cashbox.location.select"
    >
      <i class="fa fa-warning"></i>
      Выберите точку продажи
    </span>

    <span class="text-primary-dk pointer"
      *ngIf="hasMerchant"
          (click)="selectStore()"
          data-testid="cashbox.location.select"
    >
      {{ getMerchantName(cashBoxService.merchantId)  }}
    </span>

  </div>
</ng-container>



<!--

<span class=" " ng-if="options.showConfig">

        <div class="btn-group m-r-md dropdown"
             uib-dropdown>

            <span class=" text-primary-dk line-dashed no-shadow pointer" uib-dropdown-toggle>

               <span ng-if="!selectedPartner || !selectedPartner.name" class="text-danger text-sm pointer m-r-xs">
                 <i class="fa fa-warning"></i> Необходимо выбрать партнёра <span class="caret"></span>
               </span>

               <span ng-if="selectedPartner" class="text-ellipsis" style="max-width: 300px" >
                 <span ng-bind="selectedPartner.name" ></span>
                 <span class="caret"></span>
               </span>

            </span>


            <ul class="dropdown-menu dropdown-menu-right scroll-y"
                role="menu"
                aria-labelledby="single-button"
                style="max-height: 50vh;"
            >
                <li role="menuitem" ng-repeat="partner in partners">
                    <a href ng-bind="::partner.name" class="text-ellipsis" style="max-width:600px" ng-click="selectPartner(partner)"></a>
                </li>
            </ul>
        </div>

        <div class="btn-group m-r-md " ng-if="locations.length && selectedPartner" >

            <span ng-if=":: !hasLocationsPopup" class="dropdown" uib-dropdown>

                <span class=" text-primary-dk line-dashed no-shadow  pointer" uib-dropdown-toggle>

                   <span ng-if="!selectedLocation || !selectedLocation.name" class="text-danger text-sm  pointer m-r-xs">
                         <i class="fa fa-warning"></i> Необходимо выбрать точку продажи <span class="caret"></span>
                   </span>
                   <span ng-if="selectedLocation">
                     <span ng-bind="selectedLocation.name" class="text-ellipsis" style="max-width: 400px"></span>
                     <span class="caret"></span>
                   </span>

                </span>

                <ul class="dropdown-menu dropdown-menu-right scroll-y mini-scroll" role="menu" aria-labelledby="single-button"
                    style="max-height: 500px;">
                    <li role="menuitem" ng-repeat="location in locations">
                        <a href ng-bind="::location.name" ng-click="selectLocation(location)"></a>
                    </li>
                </ul>
            </span>

            <span ng-if=":: hasLocationsPopup">

               <span class=" text-primary-dk line-dashed no-shadow  pointer"
                     select-locations-popup="selectLocationsPopup"
                     init-selected-items="[selectedLocation]"
                     init-partner="selectedPartner.id"
                     check-one="true"
               >

                  <span ng-if="!selectedLocation || !selectedLocation.name" class="text-danger text-sm pointer">
                         <i class="fa fa-warning"></i> Необходимо выбрать точку продажи<span class="caret"></span>
                   </span>

                   <span ng-if="selectedLocation">
                      <span ng-bind="selectedLocation.name" class="text-ellipsis" style="max-width: 300px"></span>
                   </span>


                </span>
            </span>
        </div>

        <div class="btn-group m-r-md " ng-if="selectedPartner && (!locations || !locations.length)">

          <span class="text-sm text-danger pointer" ui-sref="frontend.config.directory.locations.index">
            <i class="fa fa-warning"></i>
            Заведите точку продажи
          </span>

      </div>



  &lt;!&ndash;div class="pull-right " ng-click="options.showConfig = !options.showConfig">
      <i class="fa fa-check text-success pointer f-s-14"></i>
  </div&ndash;&gt;

    </span>

-->

<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="partnerChange($event)"
>

  <div  id="CampaignCategoryLimitationsBox">
    <div class="fixed-tool-panel">

      <button type="button" class="btn btn-sm btn-danger m-l-none "
              *ngIf="!isEdit && hasCollisions()"
              data-testid="campaignCategoryLimit.delete()"
              (click)="confirmActionService.confirm('Вы действительно хотите удалить коллизии групп кампаний для партнёра ' + selectedPartner.name+' ?', scopeRemove.bind(this) )"
      >

        <i class=" m-r-xs fa fa-remove"></i>
        <span class="btn-name m-l-xs hidden-xxs">
                    Удалить
                 </span>
      </button>

      <button type="button" class="btn btn-sm btn-success pull-right"
              data-testid="campaignCategoryLimit.edit()"
              *ngIf="!isEdit"
              (click)="goEdit()">

        <i class=" fa fa-pencil">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">
                Редактировать
              </span>
      </button>


      <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
              *ngIf="isEdit"
              data-testid="campaignCategoryLimit.save()"
              (click)="scopeSave()"
      >
        <i class=" fa fa-check text-primary">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">Сохранить</span>
      </button>

      <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
              *ngIf="isEdit"
              data-testid="campaignCategoryLimit.cancel()"
              (click)="cancelEdit()">

        <i class=" fa fa-remove text-danger">
        </i>
        <span class="btn-name m-l-xs hidden-xxs">Отменить</span>
      </button>

    </div>

    <div class="">


      <div class=" padder m-t "
           *ngIf="isEdit"
           style="height: auto;"
           id="campaigncategorylimitationsheader"
      >

        <div class="m-t m-b-sm text-left">
          Группы кампаний для добавления в коллизии
        </div>

        <div class="w-full ">
          <div class="input-group t-fixed w-full">
            <ng-select
              [(ngModel)]="campaignCaterogyForAdd"
              data-testid="campaignCategoryLimit.search.item"
              placeholder="Выберите группу кампаний для добавления ..."
              theme="bootstrap">
              <ng-option *ngFor="let item of filterCategoryLisByName('') | callback:filterCategoryList.bind(this)"
                         [value]="item.id">
                <div class="w-full" data-testid="campaignCategoryLimit.item of campaignGroups">
                  <div class="text-ellipsis w-full"
                       [innerHTML]="item.name"></div>
                </div>
              </ng-option>
              <!--
              <ui-select-match placeholder="Выберите группу кампаний для добавления ...">
                {{$select.selected.value.name}}
              </ui-select-match>
              <ui-select-choices
                class="ui-group-choises"
                repeat="item.value.id as (key, item) in filterCategoryLisByName($select.search) | filter:filterCategoryList ">
                <div class="w-full">
                  <div class="text-ellipsis w-full"
                       ng-bind-html="item.value.name | highlight: $select.search"></div>
                </div>
              </ui-select-choices>
              -->
            </ng-select>

            <span class="input-group-btn" style="width:36px">
              <button (click)="matrix.addGroup(campaignCaterogyForAdd); campaignCaterogyForAdd = undefined;"
                      [disabled]="campaignCaterogyForAdd === undefined"
                      data-testid="campaignCategoryLimit.item.add()"
                      class="btn btn-defaultw-full">
                  <span class=" fa fa-plus"></span>
              </button>
            </span>

          </div>
        </div>

      </div>

      <div class="clearfix"></div>

      <div class="padder mini-scroll m-t"
           *ngIf="hasCollisions()"
           id="campaignCategoryCollisions">

        <div class="m-b-sm text-left">
          Коллизии групп кампаний для партнёра
          <span class="font-bold" data-testid="campaignCategoryLimit.partner" [innerText]="selectedPartner?.name"></span>
        </div>

        <table class="table"
               [ngClass]="{'editable': isEdit}"
        >
          <tr>
            <td class="bg-light group-header no-hover"></td>
            <td *ngFor="let groupColumnId of matrix.getGroupList(); let first = first; let index = index; let last = last;"
                class="bg-light group-header group-header-row no-hover"
                data-testid="campaignGroup.column"
            >
              <button class="up-group btn no-radius no-padder"
                      [disabled]="first"
                      data-testid="campaignGroup.column.up()"
                      (click)="matrix.groupUp(groupColumnId)"
              >
                <i class="fa fa-chevron-left text-muted f-s-7 "></i>
              </button>

              <div class="text-muted step f-s-7  pull-left" data-testid="campaignGroup.column.priority">
                Приоритет {{ index + 1}}
              </div>

              <span class="clearfix" data-testid="campaignGroup.column.name"
                    [title]="getGroupName(groupColumnId, true)"
                    [innerText]="getGroupName(groupColumnId, true)"></span>

              <span class="clearfix f-s-7 text-muted">
                                  (
                                  <span  [innerText]="getGroupCollisionType(groupColumnId)" data-testid="campaignGroup.column.collisionType"></span>
                                  )
                              </span>

              <span class="pointer text-danger remove-group " data-testid="campaignGroup.column.remove()"
                    (click)="confirmActionService.confirm('Вы действительно хотите удалить ' + getGroupName(groupColumnId) + ' из коллизий?',  matrix.deleteGroup.bind(matrix, groupColumnId) )"
              >удалить</span>

              <button class="down-group btn no-radius no-padder"
                      data-testid="campaignGroup.column.down()"
                      [disabled]="last"
                      (click)="matrix.groupDown(groupColumnId)"
              >
                <i class="fa fa-chevron-right text-muted f-s-7 "></i>
              </button>

              <!--<span class="pointer" (click)="matrix.deleteGroup(groupColumnId)">X</span>-->
            </td>
          </tr>

          <tr *ngFor="let groupRowId of matrix.getGroupList(); let first = first; let index = index; let last = last;">
            <td class="bg-light group-header group-header-column no-hover" data-testid="campaignGroup.row">

              <div class="text-muted step " data-testid="campaignGroup.row.priority">
                Приоритет {{ index + 1}}
              </div>

              <button class="up-group btn no-radius no-padder"
                      data-testid="campaignGroup.row.up()"
                      [disabled]="first"
                      *ngIf="isEdit"
                      (click)="matrix.groupUp(groupRowId)"
              >
                <i class="fa fa-chevron-up text-muted f-s-7 "></i>
              </button>

              <span class="clearfix" data-testid="campaignGroup.row.name">{{getGroupName(groupRowId, true)}}</span>
                <span class="clearfix f-s-7 text-muted">
                                  (
                                  <span  [innerText]="getGroupCollisionType(groupRowId)" data-testid="campaignGroup.row.collisionType"></span>
                                  )
                              </span>

                              <span class="pointer text-danger f-s-7" data-testid="campaignGroup.row.remove()"
                                    (click)="confirmActionService.confirm('Вы действительно хотите удалить ' + getGroupName(groupRowId) + ' из коллизий?',  matrix.deleteGroup.bind(matrix, groupRowId) )"
                                    *ngIf="isEdit"
                              >удалить</span>
                              <button class="down-group btn no-radius no-padder"
                                      [disabled]="last"
                                      data-testid="campaignGroup.row.down()"
                                      *ngIf="isEdit"
                                      (click)="matrix.groupDown(groupRowId)"
                              >
                                  <i class="fa fa-chevron-down text-muted f-s-7 "></i>
                              </button>


            </td>

            <td class="td"
                *ngFor=" let groupColumnId of matrix.getGroupList()"
                [ngClass]="{ 'bg-light lt empty no-hover': !matrix.hasRule(groupRowId, groupColumnId) }"
            >

              <campaign-category-rule-index-page
                *ngIf="matrix.hasRule(groupRowId, groupColumnId)"
                data-testid="matrix"
                [rule]="matrix.getValue(groupRowId, groupColumnId)"
                [groupColumn]="{ id: groupColumnId , name: getGroupName(groupColumnId) }"
                [groupRow]="{ id: groupRowId    , name: getGroupName(groupRowId)    }"
                [position]="matrix.getRulePosition(groupRowId, groupColumnId)"
                [editable]="isEdit"
              ></campaign-category-rule-index-page>

            </td>
          </tr>
        </table>
      </div>

      <div class="padder m-t-lg text-center text-md text-muted "
           id="campaignCategoryLimitationsMatrix"
           *ngIf="!hasCollisions()" data-testid="campaignCategoryLimit.partner.empty">
        Коллизии групп кампаний для партнёра
        <span class="font-bold"  [innerText]="selectedPartner?.name"></span>
        отсутствуют
      </div>
    </div>
  </div>

  <scroll-up></scroll-up>

</partner-menu>

<form
  name="campaignForm"
  #campaignForm="ngForm"
>

  <div class="fixed-tool-panel ">

    <button type="button"
            class="btn btn-sm btn-addon  btn-default pull-right"
            [disabled]="campaignForm.invalid"
            *ngIf=" statesChangerService.hasButton('save')"
            (click)="statesChangerService.click('save')"
            data-testid="coupon.save()">
      <i class=" fa fa-check text-primary">
      </i>
      <span class="btn-name m-l-xs hidden-xxs">Сохранить</span>
    </button>

    <button type="button"
            class="btn btn-sm btn-addon btn-default pull-right"
            *ngIf=" statesChangerService.hasButton('cancel')"
            (click)="statesChangerService.click('cancel')"
            data-testid="coupon.cancel()">
      <i class=" fa fa-remove text-danger">
      </i>
      <span class="btn-name  hidden-xxs">Отменить</span>
    </button>

    <button type="button"
            *ngIf=" !!clientItem?.client"
            [uiSref]="'frontend.crm.clients.view({id:' + clientItem?.client?.id + '})'"
            class="btn btn-sm btn-success pull-right"
            data-testid="coupon.toClient()">

      <i class="fa fa-arrow-left"></i>
      <span class="btn-name  hidden-xxs">
        К клиенту
      </span>
    </button>

    <ng-container
      *loyaPermitIf="{ path:'coupons.coupons.list', permitEnabled: ''}"
    >
        <button type="button"
                *ngIf="clientItem?.client"
                [uiSref]="'^.index({id: ' + clientItem?.client?.id + '})'"
                class="btn btn-sm btn-success pull-right"
                data-testid="coupon.toList()"
        >
            <i class="fa fa-arrow-left"></i>
            <span class="btn-name  hidden-xxs">
              К списку
            </span>
        </button>

        <button type="button"
          *ngIf="!clientItem?.client"
          class="btn btn-sm btn-success pull-right"
          [uiSref]="'^.index'"
          data-testid="coupon.toList()"
        >
            <i class="fa fa-arrow-left"></i>
            <span class="btn-name  hidden-xxs">
              К списку
            </span>
        </button>
    </ng-container>

    <ng-container
      *loyaPermitIf="{ path:'model.coupon', permitEnabled: '', permitType: 'delete'}"
    >

      <button type="button" class="btn btn-sm btn-danger btn-addon pull-left "
              *ngIf="
                statesChangerService.hasButton('delete') &&
                !statesChangerService.isActive &&
                canBeDeleted
              "
              (click)="statesChangerService.click('delete')"
              data-testid="coupon.delete()">
          <i class=" m-r-xs fa fa-remove"></i>
          <span class="btn-name  hidden-xxs">
              Удалить
          </span>
      </button>

    </ng-container>

  </div>

  <coupon-edit
    *ngIf="statesChangerService.isActive"
    [coupon]="coupon"
    [clientItem]="clientItem"
    [clientId]="clientId"
  ></coupon-edit>

  <ng-container
    *ngIf="!statesChangerService.isActive && coupon"
  >

    <div class="row m-t align-items-stretch">
      <div class=" col-sm-12 col-md no-padder ">
        <div class="card m-l-lg m-r p-l p-r p-t m-h-100">

          <h4 class=" overflow-wrap-break m-b-none" data-testid="coupon.name">
            {{ coupon?.name || '' }}
          </h4>

          <div class="clearfix"></div>

          <div class="small m-t-xs"
               [class.text-success]="[COUPON_STATES.PARTIAL_APPLY, COUPON_STATES.ACTIVE].indexOf(coupon?.state) >= 0"
               [class.text-danger]="[COUPON_STATES.PARTIAL_APPLY, COUPON_STATES.ACTIVE].indexOf(coupon?.state) < 0"
          >
            <span [innerText]=" COUPON_STATE_NAMES[coupon?.state] || ''"
                  data-testid="coupon.state"></span>
          </div>

          <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-sm"></div>

          <div class="hbox w-full  ">
            <div class="col mini-scroll  ">
              <pre class="w-full mini-scroll"
                   [innerText]="coupon?.description"
                   data-testid="coupon.description"></pre>
            </div>
          </div>

        </div>
      </div>

      <div class="col-sm-12 col-md-auto no-padder ">
        <div class="card m-r-lg p-l p-r p-t text-center m-h-100 d-flex align-items-center justify-content-center">
          <div>
            <barcode-view
              [number]="coupon?.barcode"
              [options]="{
                number: false,
                prefix: false,
                height: 60
              }"
            ></barcode-view>

            <div class="">
                  <h2 [innerText]="coupon?.barcode"
                        data-testid="coupon.barcode"
                  ></h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card m-l m-r m-t"
         *ngIf="coupon?.state==='manualDeleted' && coupon.removingReason">

      <div class="card-header">
        Причина удаления
      </div>

      <div class="card-body">
        <i class="fa fa-warning text-danger pull-left m-t-xxs m-b-n-xxs"></i>
        <span [innerText]="coupon?.removingReason"
              data-testid="coupon.removingReason"
              class="overflow-wrap-break"></span>
      </div>

    </div>

  </ng-container>

  <ng-container
    *ngIf="!statesChangerService.isActive && clientItem"
  >

    <div class="card m-l m-r m-t">

      <div class="card-header">
        Клиент
      </div>

      <div class="card-body">

        <div class="row">

          <div class="col-lg-6 col-sm-12 ">
            <div class="row">

              <div class="col-lg-6 m-b m-t-sm text-center clearfix pointer"
                   [uiSref]="'frontend.crm.clients.view({id:' + clientItem.client.id + '})'"
                   data-testid="coupon.client"
              >
                <div class="inline text-center ">
                  <div class="thumb-lg avatar"
                       style="margin:1px 0px 0 0">

                    <div style="border-radius: 500px; width:95px; height:95px; overflow: hidden; margin-top: 4px; ">
                      <img *ngIf="clientItem?.client?.systemParams?.photoUrl"
                           [src]="clientItem?.client?.systemParams?.photoUrl"
                           alt="..."
                           style="margin-top: -11px; width: 100%;">
                      <img *ngIf="!clientItem?.client?.systemParams?.photoUrl"
                           src='/assets/img/user-default.jpg'
                           style="width:95px; height:95px">
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-lg-6 text-center-xs text-center-sm text-center-md text-center padder">

                <a  [uiSref]="'frontend.crm.clients.view({id:' + clientItem.client.id + '})'"
                    class="f-s-12  block clearfix  m-t-xxs m-b-xxs"
                    *ngIf="clientItem?.client?.surname"
                    data-testid="coupon.client.surname"
                >
                  {{ clientItem?.client?.surname || ''}}
                </a>

                <a  [uiSref]="'frontend.crm.clients.view({id:' + clientItem.client.id + '})'"
                    class="f-s-12  block clearfix  m-t-xxs m-b-xxs"
                    *ngIf="clientItem?.client?.name"
                    data-testid="coupon.client.name">
                  {{ clientItem?.client?.name || '' }}
                </a>

                <a  [uiSref]="'frontend.crm.clients.view({id:' + clientItem.client.id + '})'"
                    class="f-s-12  block clearfix  m-t-xxs m-b-xxs"
                    *ngIf="clientItem?.client?.patronymic"
                    data-testid="coupon.client.patronymic"
                >
                  {{ clientItem?.client?.patronymic || '' }}
                </a>

                <div class="m-b-xs m-t-sm f-s-9"
                     *ngIf="clientItem?.client?.phone"
                     data-testid="coupon.client.phone"
                >
                  <i class=" fa fa-mobile m-r-xs text-muted">
                  </i>
                  {{ clientItem?.client?.phone || '' }}
                </div>

                <div class="m-b-xs f-s-9"
                     *ngIf="clientItem?.client?.email"
                     data-testid="coupon.client.email">
                  <i class="fa fa-envelope-o  m-r-xs text-muted">
                  </i>
                  {{ clientItem?.client?.email || '' }}
                </div>

              </div>

            </div>
          </div>

          <div class="col-lg-6 col-sm-12"
               *ngIf="!!cards && cards?.length"
          >
            <div class="card  no-border b-l b-r b-b">
              <table class="table m-b-none b-b">
                <thead>
                  <tr>
                    <th class="text-center">Номер карты</th>
                    <th class="w-lg text-center">Статус карты</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let card of cards"
                      data-testid="coupon.card in cards"
                  >
                    <td class=" text-left"
                        data-testid="coupon.card.number">
                      {{ card?.card?.number || ''}}
                    </td>
                    <td class="w-lg text-center"
                        data-testid="coupon.card.state">
                      {{ CARD_STATE_NAMES[card.card.state] || card.card.state || ''}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>

    </div>

  </ng-container>

  <ng-container
    *ngIf="!statesChangerService.isActive">

    <grid-container>

      <div class="grid-item"
           *ngIf="coupon?.fromDate || coupon?.tillDate"
           [grid-item-form] = "'Купон действует'"
           grid-item-form-icon = "fa-calendar"
      >
        с
        <span [innerText]="coupon?.fromDate | loyaDate" data-testid="coupon.fromDate"></span>
        по
        <span [innerText]="coupon?.tillDate | loyaDate" data-testid="coupon.tillDate"></span>
        включительно
      </div>

      <div class="grid-item"
           *ngIf="!!coupon?.issueDate"
           [grid-item-form] = "'Дата выдачи'"
           grid-item-form-icon = "fa-calendar"
      >
        <span class="text-bold"
              [innerText]="coupon?.issueDate | loyaDate"
              data-testid="coupon.issueDate"
        ></span>
      </div>

      <div class="grid-item"
           *ngIf="!!coupon?.usedDate"
           [grid-item-form] = "'Дата использования'"
           grid-item-form-icon = "fa-calendar"
      >
        <span class="text-bold"
              [innerText]="coupon.usedDate | loyaDate"
              data-testid="coupon.usedDate"
        ></span>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

      <ng-container
        *ngIf="!!couponRuleItem"
      >

        <div class="grid-item"
             [grid-item-form] = "'Купонное правило'"
             grid-item-form-icon = "fa-calendar"
        >

          <a class="clearfix overflow-wrap-break"
             [innerText]="couponRuleItem?.couponRule?.name || ''"
             [uiSref]="'^.^.rules.view({id: ' + couponRuleItem.couponRule.id + '})' "
             href="javascript:void(0)"
             data-testid="coupon.couponRule.name"
          ></a>

          <span class="clearfix text-muted overflow-wrap-break"
                [innerText]="couponRuleItem?.couponRule?.description || ''"
                data-testid="coupon.couponRule.description"></span>

        </div>

      </ng-container>

      <div class="grid-item"
           *ngIf="!!coupon?.restUseCount && !!coupon?.rewardSettings && !!coupon?.rewardSettings?.quantityForReward"
           [grid-item-form] = "'Остаток использования'"
           grid-item-form-icon = "fa-calendar"
      >

        <span [innerText]="coupon?.restUseCount || ''" data-testid="coupon.restUseCount"></span>
        <ng-container *ngIf="
            !!coupon?.rewardSettings?.initialValues?.restUseCount
        ">
          из <span [innerText]="coupon?.rewardSettings?.initialValues?.restUseCount || ''"></span>
        </ng-container>
        раз/шт

      </div>

      <div class="grid-item"
           *ngIf="!!coupon?.rewardSettings && !!coupon?.rewardSettings?.quantityForReward"
           [grid-item-form] = "'Остаток использования на кол-во товара'"
           grid-item-form-icon = "fa-calendar"
      >

        <span [innerText]="coupon?.rewardSettings?.quantityForReward || ''"></span>
        <span *ngIf="coupon?.rewardSettings?.initialValues?.quantityForReward >= 0">
                   из
         <span [innerText]="coupon?.rewardSettings?.initialValues?.quantityForReward"></span>
       </span>
        раз/шт

      </div>

      <div class="grid-item"
           [grid-item-form] = "'Тип выдачи купонов'"
           grid-item-form-icon = "fa-calendar"
      >

         <span
           [innerText]="ISSUING_TYPE_NAMES[couponRuleItem?.couponRule?.issueSettings?.issuingType]"
           data-testid="coupon.issuingType"
         ></span>

       </div>

      <div class="grid-item"
           [grid-item-form] = "'Тип купона'"
           grid-item-form-icon = "fa-calendar"
      >

         <span
           [innerText]="COUPON_KIND_NAMES[coupon?.couponKind] || ''"
           data-testid="coupon.couponKind"
         ></span>

      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

      <div class="grid-item"
           *ngIf="!couponRuleItem?.$currentCouponKind || !!couponRuleItem?.$currentCouponKind.hasRewardWhat"
           [grid-item-form] = "'Вознаграждение'"
           grid-item-form-icon = "fa-calendar"
      >

        <span [ngSwitch]="couponRuleItem?.couponRule?.reward?.reward?.what"
              data-testid="coupon.reward.what"
              class="m-r-xs"
        >
            <span *ngSwitchCase="'discount'">скидка</span>
            <span *ngSwitchCase="'bonus'">бонус</span>
            <span *ngSwitchCase="'gift'">подарки</span>
        </span>

        <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.what!=='gift' &&
                    !couponRuleItem?.couponRule?.reward?.reward?.fromMechanic
        "
              data-testid="coupon.reward.container"
        >

          <span *ngIf=" couponRuleItem?.couponRule?.reward?.reward?.what==='fixprice'"
                data-testid="coupon.reward.what"
          >
            Фиксированная цена
          </span>

          <span [innerText]="coupon?.rewardSettings && coupon?.rewardSettings?.rewardValue ? coupon?.rewardSettings?.rewardValue :  couponRuleItem?.couponRule?.reward?.reward?.value"
                data-testid="coupon.reward.value"></span>

          <span [innerText]="REWARD_MEASURES_TYPES_VALUES[couponRuleItem?.couponRule?.reward?.reward?.measure] || ''"
                class="m-l-xs"
                data-testid="coupon.reward.measure"></span>

      </span>

        <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.fromMechanic"
              class="clearfix"
              data-testid="coupon.reward.fromMechanic">
            из шаблона механики
        </span>

      </div>

      <div class="grid-item"
           *ngIf="couponRuleItem?.$currentCouponKind.getId() === 'couponWithBarcodeWithReceptParams'"
           data-testid="reward.container"
           [grid-item-form] = "'Скидка выдаётся'"
           grid-item-form-icon = "fa-calendar"
      >
        <div data-testid="coupon.couponWithBarcodeWithReceptParams">
          на SKU
          <span class="font-bold">{{ coupon?.rewardSettings?.sku}}</span>
          <span class="clearfix"></span>
          <span class="">в размере</span>
          <span class="font-bold m-l-xs">{{ coupon?.rewardSettings?.rewardValue}}%</span>

        </div>
      </div>

      <div class="grid-item"
           *ngIf="couponRuleItem?.$currentCouponKind && !!couponRuleItem?.$currentCouponKind?.hasRewardRewType"
           [grid-item-form] = "'Вознаграждение даётся'"
           grid-item-form-icon = "fa-calendar"
      >

        <span [innerText]="REWARD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.reward?.rewType] || '' | lowercase"
              data-testid="cRule.reward.rewType"></span>

        <ng-container *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType === 'goods' ||
                     couponRuleItem?.couponRule?.reward?.reward?.rewType ==='setOfGoods' ||
                     couponRuleItem?.couponRule?.reward?.reward?.rewType === 'position'"
        >

          <span
           *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType==='setOfGoods' "
           (click)="viewGoodsPopup( {
              viewGoodsPopup:couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList,
              forPartner: couponRuleItem?.couponRule?.partnerId,
              listType: couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
           })"
          >
              <i class="fa fa-eye text-success"></i>
          </span>

          <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType==='setOfGoods'">на все в чеке</span>

          <span *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType==='goods' ||
                       couponRuleItem?.couponRule?.reward?.reward?.rewType === 'position'">

            <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'every'">
              {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}
              <span class="font-bold "
                    [innerText]="couponRuleItem?.couponRule?.reward?.onGood?.quantity || 0 "></span>
              <span class="m-l-xs" [innerText]="couponRuleItem?.couponRule?.reward?.onGood?.dimension === 'weight' ? 'мерн.' : 'шт.' "></span>
            </span>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'count' ">
              <ng-container
                *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.itemsSorting"
              >
                <span [ngSwitch]="couponRuleItem?.couponRule?.reward?.onGood?.itemsSorting"
                      data-testid="cRule.reward.onGood.sorting">
                  <span *ngSwitchCase="'priceAsc'">наименьшие по цене</span>
                  <span *ngSwitchCase="'priceDesc'">наибольшие по цене</span>
                </span>
              </ng-container>


            {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}

            <span class="font-bold"
                  [innerText]="couponRuleItem?.couponRule?.reward?.onGood?.quantity || 0"
                  data-testid="cRule.reward.onGood.quantity"></span>
            <span [innerText]="couponRuleItem?.couponRule?.reward?.onGood?.dimension === 'weight' ? 'мерн.' : 'шт.' "
                  class="m-l-xs"
                  data-testid="cRule.reward.onGood.measure"></span>
           </span>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.onGood?.rewType === 'all' ">
              {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[couponRuleItem?.couponRule?.reward?.onGood?.rewType]}}
            </span>

            <a
              *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0"
              class="font-bold"
              href="javascript:void(0)"
              (click)="viewGoodsPopup({
                viewGoodsPopup: couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList,
                forPartner: couponRuleItem?.couponRule?.partnerId,
                listType: couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
              })"
            >
               из списка
           </a>

            <a
              href="javascript:void(0)"
              class="font-bold"
              *ngIf="
               couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0 ||
               couponRuleItem?.couponRule?.reward?.goodList?.whiteListType === 'favourite'
              "
              (click)="viewGoodsPopup({
                viewGoodsPopup: couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList,
                forPartner: couponRuleItem?.couponRule?.partnerId,
                listType: couponRuleItem?.couponRule?.reward?.goodList?.whiteListType
              })"
            >
               из
               <span [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.whiteListType"
                     data-testid="cRule.reward.goodList.whiteListType"
               >
                  <span *ngSwitchCase="'category'">
                    категорий
                  </span>
                  <span  *ngSwitchCase="'brand'">
                    брендов
                  </span>
                  <span  *ngSwitchCase="'favourite'">
                    списка любимых товаров
                  </span>
                  <span  *ngSwitchCase="'custom'">
                    списка товаров
                  </span>
              </span>

             </a>

           <span *ngIf="
              couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0 ||
              couponRuleItem?.couponRule?.reward?.goodList?.length>0 ||
              couponRuleItem?.couponRule?.reward?.goodList?.blackListType === 'favourite' "
           >
             исключая
             <a
               class="font-bold"
               *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0"
               (click)="viewGoodsPopup({
                  viewGoodsPopup: couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList,
                  forPartner: couponRuleItem?.couponRule?.partnerId,
                  listType: couponRuleItem?.couponRule?.reward?.goodList?.blackListType
                })">
               товары
             </a>

              <a
                class="font-bold"
                *ngIf="
                  couponRuleItem?.couponRule?.reward?.goodList?.blackIdList?.length>0 ||
                  couponRuleItem?.couponRule?.reward?.goodList?.blackListType === 'favourite'"
                (click)="viewGoodsPopup({
                    viewGoodsPopup: couponRuleItem?.couponRule?.reward?.goodList?.blackIdList,
                    forPartner: couponRuleItem?.couponRule?.partnerId,
                    listType: couponRuleItem?.couponRule?.reward?.goodList?.blackListType
                  })"
              >
                 <span [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.blackListType">
                    <span *ngSwitchCase="'category'">
                      категории
                    </span>
                    <span *ngSwitchCase="'brand'">
                      бренды
                    </span>
                    <span *ngSwitchCase="'favourite'">
                      любимые товары
                    </span>
                    <span *ngSwitchCase="'custom'">
                      списки товаров
                    </span>
                  </span>
               </a>

           </span>
        </span>
        </ng-container>

      </div>

      <div class="grid-item"
           *ngIf="!!coupon?.restUseCount && !coupon?.rewardSettings?.quantityForReward"
           [grid-item-form] = "'Остаток использования'"
           grid-item-form-icon = "fa-calendar"
      >

        <span [innerText]="coupon?.restUseCount"
              data-testid="coupon.restUseCount"
        ></span>

        <span *ngIf="
            coupon?.rewardSettings &&
            coupon?.rewardSettings?.initialValues &&
            coupon?.rewardSettings?.initialValues?.restUseCount
        ">
          из <span [innerText]="coupon?.rewardSettings?.initialValues?.restUseCount || ''"
                   data-testid="cRule.reward.initialValues.restUseCount"
            ></span>
        </span>
        раз/шт

      </div>

      <div class="grid-item"
           *ngIf="!!coupon?.rewardSettings && !!coupon?.rewardSettings?.quantityForReward"
           [grid-item-form] = "'Остаток использования на кол-во товара'"
           grid-item-form-icon = "fa-calendar"
      >
        <span [innerText]="coupon?.rewardSettings?.quantityForReward"></span>
        <span *ngIf="!!coupon?.rewardSettings?.initialValues && !!coupon?.rewardSettings?.initialValues?.quantityForReward">
          из
          <span [innerText]="coupon?.rewardSettings?.initialValues?.quantityForReward"></span>
        </span>
        раз/шт
      </div>

      <ng-container
        *ngIf="!!couponRuleItem"
      >

        <div class="grid-item"
             *ngIf="
               !!couponRuleItem?.couponRule?.reward?.referalProgramData?.referReward
             "
             [grid-item-form] = "'Вознаграждение рефера'"
             grid-item-form-icon = "fa-calendar"
             data-testid="reward.referal.container"
        >
          <span >бонус</span>
          <span [innerText]="couponRuleItem?.couponRule?.reward?.referalProgramData?.referReward?.value"></span>
          <span  class="m-l-xs" [innerText]="REWARD_MEASURES_TYPES_VALUES[couponRuleItem?.couponRule?.reward?.referalProgramData?.referReward?.measure]"></span>

        </div>


        <div class="grid-item grid-item_clean grid-item_clean_hr"></div>


        <div class="grid-item"
             [grid-item-form] = "'Дни приёма купона'"
             grid-item-form-icon = "fa-calendar"
        >
          <span class=" f-s-9" style="white-space:nowrap">
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Monday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Tuesday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Wednesday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Thursday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Friday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Saturday')<0">
            </i>
            <i class="fa fa-circle text-info m-r-xs"
               [class.text-muted]="couponRuleItem?.couponRule?.applySettings?.validDays?.indexOf('Sunday')<0">
            </i>
          </span>

        </div>

        <div class="grid-item"
             [grid-item-form] = "'Время приёма купона'"
             grid-item-form-icon = "fa-calendar"
        >
          c
          <span [innerText]="couponRuleItem?.couponRule?.applySettings?.validTimeFrom | loyaTime" data-testid="coupon.couponRule.timeFrom"></span>
          по
          <span [innerText]="couponRuleItem?.couponRule?.applySettings.validTimeTill | loyaTime" data-testid="coupon.couponRule.timeTill"></span>

        </div>

        <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             *ngIf="
               !!couponRuleItem?.locations?.length
             "
             [grid-item-form] = "'Приём в точках продаж'"
             grid-item-form-icon = "fa-calendar"
        >

          <div class="clearfix"
               *ngFor="let loc of couponRuleItem?.locations"
               [innerText]="loc?.name || ''"
               data-testid="coupon.couponRule.shop"></div>

        </div>

        <div class="grid-item"
             *ngIf="
               !!couponRuleItem?.locationGroups?.length
             "
             [grid-item-form] = "'Локации'"
             grid-item-form-icon = "fa-calendar"
        >

          <div class="clearfix"
               *ngFor="let loc of couponRuleItem?.locationGroups"
               [innerText]="loc?.name || ''"
               data-testid="coupon.couponRule.locationGroups"></div>

        </div>

      </ng-container>

      <div class="grid-item"
           *ngIf="!!couponIssueInfo"
           [grid-item-form] = "'Выдан в чеке'"
           grid-item-form-icon = "fa-calendar"
      >
        <a  [innerText]="couponIssueInfo?.checkNumber"
            [uiSref]="'frontend.receipt.index({number:' + couponIssueInfo.checkNumber + ', id: ' + couponIssueInfo.checkId + '})'"
            data-testid="coupon.issue.checkNumber"
            href="javascript:void(0)"
        ></a>
      </div>

      <div class="grid-item"
           *ngIf="!!couponIssueInfo"
           [grid-item-form] = "'Выдан клиенту'"
           grid-item-form-icon = "fa-calendar"
      >
        <a  *ngIf="couponIssueInfo?.checkNumber"
            [uiSref]="'frontend.crm.clients.view({id:' + couponIssueInfo.clientId + '})'"
            data-testid="coupon.issue.client"
            href="javascript:void(0)"
        >
          {{couponIssueInfo.clientFIO || ''}}
        </a>
      </div>

      <div class="grid-item"
           *ngIf="!!couponIssueInfo"
           [grid-item-form] = "'Выдан в ТО'"
           grid-item-form-icon = "fa-calendar"
      >
        <div class="clearfix"
           data-testid="coupon.issue.shop"
        >{{couponIssueInfo.merchantName }}</div>

      </div>

    </grid-container>

  </ng-container>

  <coupon-transactions
    *ngIf="!!coupon?.id"
    [id]="coupon?.id"
  ></coupon-transactions>

</form>

<div class="fixed-tool-panel">


  <button type="button" class="btn btn-sm btn-addon btn-danger "
          [hidden]="!statesChangerService.hasButton('delete')"
          (click)="confirmActionService.confirm('Вы действительно хотите удалить группу кампаний ' + currentCategory.name + ' ?', statesChangerService.click.bind(statesChangerService,['delete']) )"
          data-testid="campaignGroups.delete()"
  >
    <!-- ng-confirm-click="Вы действительно хотите удалить тип механики '{{ selectedMcategory.name }}' ?" -->
    <i class=" m-r-xs fa fa-remove"></i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.remove' | transloco }}
              </span>
  </button>



  <button type="button" class="btn btn-sm btn-success btn-addon pull-right"
          [hidden]="!statesChangerService.hasButton('edit')"
          (click)="statesChangerService.click('edit')"
          data-testid="campaignGroups.edit()"
  >

    <i class=" fa fa-pencil">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
               {{ 'interface.buttons.edit' | transloco }}
            </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('save')"
          (click)="statesChangerService.click('save')"
          [disabled]="ccategoryForm && ccategoryForm.form && ccategoryForm.form.invalid"
          data-testid="campaignGroups.save()"
  >
    <i class=" fa fa-check text-primary">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.save' | transloco }}
    </span>
  </button>

  <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
          *ngIf="statesChangerService.hasButton('cancel')"
          (click)="statesChangerService.click('cancel')"
          data-testid="campaignGroups.cancel()">

    <i class=" fa fa-remove text-danger">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.cancel' | transloco }}
    </span>
  </button>


  <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
          *ngIf="statesChangerService.hasButton('preview')"
          (click)="statesChangerService.click('preview')"

          [ngClass]=" { 'active': statesChangerService.state == 'preview' }  "
          [disabled]="ccategoryForm && ccategoryForm.form && ccategoryForm.form.invalid"
          data-testid="campaignGroups.preview()"
  >
    <i class=" fa fa-eye text-success">
    </i>
    <span class="btn-name m-l-xs hidden-xxs">
      {{ 'interface.buttons.preview' | transloco }}
    </span>
  </button>

  <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
          *ngIf="statesChangerService.state === 'view'"
          uiSref="^.index"
          data-testid="campaignGroups.back()"
  >

    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">
           {{ 'interface.buttons.to_list' | transloco }}
    </span>
  </button>

</div>


<div class="card w-100 no-back"
     *transloco="let t"
>

  <div class="card-body"
       *ngIf="currentCategory"
  >
    <form name="ccategoryForm" #ccategoryForm="ngForm" novalidate autocomplete="off">

      <grid-container>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignCategoryApi.id.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
        <span
          [innerHTML]="currentCategory.id || '-'" data-testid="campaignGroups.id"
        ></span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignCategoryApi.name.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentCategory.name"
              data-testid="campaignGroups.name"
        ></span>

        <span class="editable-rec"
              [hidden]="!statesChangerService.isActive"
        >
          <input
            type="text"
            class="form-control form-control-edittable"
            [(ngModel)]="currentCategory.name"
            data-testid="campaignGroups.input.name"
            name="name"
            [placeholder]="'models.CampaignCategoryApi.name.placeholder'| transloco"
            [required]="true"
            [maxlength]="254"
          >
        </span>
      </div>

      <div class="grid-item"
             [grid-item-form] = "'models.CampaignCategoryApi.description.name'| transloco"
             grid-item-form-icon = "fa-align-justify"
        >
        <span [hidden]="statesChangerService.isActive"
              [innerHTML]="currentCategory.description"
              data-testid="campaignGroups.description"
        ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
          <textarea
            class="form-control form-control-edittable"
            [(ngModel)]="currentCategory.description"
            data-testid="campaignGroups.input.description"
            name="description"
            [placeholder]="'models.CampaignCategoryApi.description.placeholder' | transloco"
            [required]="true"
            [maxlength]="254"
          ></textarea>
        </span>
      </div>

      <div class="grid-item"
           [grid-item-form] = "'models.CampaignCategoryApi.collision.name' | transloco"
           grid-item-form-icon = "fa-align-justify"
      >
          <span [hidden]="statesChangerService.isActive"
                [innerHTML]=" (!!currentCategory.collision ? 'models.CampaignCategoryApi.collision.items.' + currentCategory.collision  : '' ) | transloco"
                data-testid="campaignGroups.collisionType"
          ></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
             <select class=" form-control  inline v-middle"
                     name="collision"
                     [(ngModel)]="currentCategory.collision"
                     data-testid="campaignGroups.select.collisionType"
             >
               <option *ngFor="let item of collisionValues | keyvalue"
                       [ngValue]="item.key"
                       [innerHTML]="  'models.CampaignCategoryApi.collision.items.' + item.value | transloco"
                       data-testid="campaignGroups.select.item.collisionType"
               ></option>
            </select>
        </span>
      </div>

      <div class="grid-item grid-item_clean grid-item_clean_hr"></div>

        <div class="grid-item"
             *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"

             [grid-item-form] = " 'models.CampaignCategoryApi.allowBonusPayment.name' | transloco"
             grid-item-form-icon = "fa-gear"
        >
          <span [hidden]="statesChangerService.isActive"
                data-testid="campaignGroups.allowBonusPayment"
                [innerHTML]=" 'models.CampaignCategoryApi.allowBonusPayment.values.'+currentCategory.allowBonusPayment.toString() | transloco"></span>

          <span class="editable-rec"
                [hidden]="!statesChangerService.isActive"
          >
            <div class="checkbox inline m-t-none m-b-none">
              <label class="i-checks m-b-none">
                <input type="checkbox"
                       [(ngModel)]="currentCategory.allowBonusPayment"
                       name="allowBonusPayment"
                       data-testid="campaignGroups.check.allowBonusPayment"
                >
                <i></i>
              </label>
            </div>
          </span>


        </div>

        <ng-container *ngIf="currentCategory.allowBonusPayment">
          <div class="grid-item"
               *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"

               [grid-item-form] = " 'models.CampaignCategoryApi.awardBonusEvenWithPayment.name' | transloco"
               grid-item-form-icon = "fa-gear"
          >
            <span [hidden]="statesChangerService.isActive"
                  data-testid="campaignGroups.awardBonusEvenWithPayment"
                  [innerHTML]="'models.CampaignCategoryApi.awardBonusEvenWithPayment.values.'+currentCategory.awardBonusEvenWithPayment.toString() | transloco"></span>

            <span class="editable-rec"
                  [hidden]="!statesChangerService.isActive"
            >
              <div class="checkbox inline m-t-none m-b-none">
                <label class="i-checks m-b-none">
                  <input type="checkbox"
                         name="awardBonusEvenWithPayment"
                         [(ngModel)]="currentCategory.awardBonusEvenWithPayment"
                         data-testid="campaignGroups.check.awardBonusEvenWithPayment"
                  >
                  <i></i>
                </label>
              </div>
            </span>
          </div>
        </ng-container>



      </grid-container>
    </form>

  </div>

</div>

<div class="clearfix m-t m-b " *ngIf="!statesChangerService.isActive && priceFlag">

  <div class="col-sm-12 text-center text-danger">
    <i class="fa fa-warning text-danger m-r-xs" data-testid="campaignGroups.warning"></i>
    <span>В текущей группе присутствуют кампании с ценовыми механиками</span>
  </div>

</div>

<div class="card w-full no-back"
     *transloco="let t"
>
  <div class="card-header">
    <i class="fa fa-rocket text-success m-r-sm"></i>
    {{ 'pages.Config.Directory.Campaigncategory.table_header' | transloco }}
  </div>
  <div class="card-body no-padder"
       *ngIf="currentCategory"
  >

    <div class="wrapper padder-lg"
         *ngIf="statesChangerService.isActive"
    >

      <ng-container *ngIf="!isDisabledForEdit">
        <div class="row m-b-xs">
          <div class="col ">
            <span class="text-muted f-s-9">Кампания для добавления в группу:</span>
          </div>
        </div>

        <div class="row m-b-sm">
          <div class="col ">
            <select-campaign
              [(id)]="addCampaignId"
              [showDescription]="true"
            ></select-campaign>
          </div>
          <div class="col col-auto v-middle">

            <button type="button" class="btn  btn-success btn-addon pull-right m-l-sm"
                    (click)="addCampaign()"
                    [disabled]="campaignOutCategoryDisabled() || !addCampaignId"
                    data-testid="campaignGroups.add()"
            >

              <i class=" fa fa-plus">
              </i>
              <span class="btn-name m-l-xs hidden-xxs">
                   {{  'interface.buttons.add' | transloco }}
                </span>
            </button>

          </div>
        </div>

        <div class="row m-b-xs">
          <div class="col ">
            <span class="text-muted f-s-9">Купонное правило для добавления в группу:</span>
          </div>
        </div>

        <div class="row">
          <div class="col ">
            <select-coupon-rule
              [(id)]="addCouponRuleId"

            ></select-coupon-rule>
          </div>
          <div class="col col-auto v-middle">

            <button type="button" class="btn  btn-success btn-addon pull-right m-l-sm"
                    (click)="addCouponRule()"
                    [disabled]="campaignOutCategoryDisabled() || !addCouponRuleId"
                    data-testid="campaignGroups.add()"
            >

              <i class=" fa fa-plus">
              </i>
              <span class="btn-name m-l-xs hidden-xxs">
                   {{  'interface.buttons.add' | transloco }}
                </span>
            </button>

          </div>
        </div>
      </ng-container>
      <div class="w-full text-center padder text-muted"
           *ngIf="isDisabledForEdit"
      >
        <h5>В группе больше {{ MAX_ENABLED_COUNT }} кампаний и купонных правил. Редактирование запрещено</h5>
      </div>
    </div>

    <table class="table m-b-none b-b m-t-n-xxs table-customers "
           [ngClass]="{ 'table-dnd-process': dndProcess }"
           infiniteScroll
           [infiniteScrollDistance]="1"
           [infiniteScrollThrottle]="50"
           [scrollWindow]="false"
           [infiniteScrollContainer]="'#app-container'"
           [fromRoot]="true"
           (scrolled)="onScrollList()"
    >
      <thead>
      <tr>
        <th data-sort-ignore="true" [innerText]=" 'models.CampaignCategoryApi.links.campaign.name' | transloco "></th>
        <th data-sort-ignore="true" [innerText]=" 'models.CampaignCategoryApi.links.campaign.description' | transloco"></th>
        <th data-sort-ignore="true" [innerText]=" 'models.CampaignCategoryApi.links.campaign.state' | transloco"></th>
        <th data-sort-ignore="true" *ngIf="statesChangerService.isActive && currentCategory.collision == 'priority' && !isDisabledForEdit"
            [innerText]="'models.CampaignCategoryApi.links.campaign.priority' | transloco"
            class="text-right"
        >
        </th>
      </tr>
      </thead>
      <tbody >
        <ng-container  *ngFor="let campaign of listIncludedCampaings | callbackSort:sortByPriority; trackBy: trackForList">
          <tr
              [ngClass]="{ 'bg-light dk pointer':lastSwapedCampaign === campaign }"
              (click)="lastSwapedCampaign=false"
              data-testid="item of listIncludedCampaings"
              [dndDraggable]="campaign"
              [dndDisableDragIf]="!(statesChangerService.isActive && currentCategory.collision == 'priority')"
              [dndDisableDropIf]="!(statesChangerService.isActive && currentCategory.collision == 'priority')"
              dndType="listCampaigns"
              dndEffectAllowed="move"
              (dndStart)="dndProcess=true"
              (dndEnd)="dndProcess=false"
              [dndDropzone]="['listCampaigns']"
              (dndDrop)="onDropZone($event, campaign)"
              dndDragoverClass="drag-tr-row"
              dndDraggingClass="dragging-tr-row"
          >
            <td class="v-middle" data-testid="campaign.name">

              <i class="fa m-r-xs fa-lg m-t-xs m-b-n-xs pull-left text-danger fa-money"
                 *ngIf='campaign.campaignDetails && campaign.campaignDetails.priceFlag'
                 uib-popover="Кампания с ценовой механикой" popover-trigger="'mouseenter:mouseleave'"
              ></i>

              <i class="fa m-r-xs fa-lg m-t-xs m-b-n-xs pull-left"
                 [ngClass]="{ 'fa-barcode text-primary': campaign.source === 'couponRule', 'fa-rocket text-success': campaign.source === 'campaign'  }"></i>

              {{ campaign[campaign.source + 'Details' ].name }}
            </td>
            <td class="v-middle" data-testid="campaign.description">{{ campaign[campaign.source + 'Details' ].description }}</td>
            <td class="v-middle" data-testid="campaign.state">{{ 'models.CampaignApi.state.values.' + campaign[campaign.source + 'Details' ].state  | transloco }}</td>

            <td *ngIf="statesChangerService.isActive && currentCategory.collision == 'priority' && !isDisabledForEdit"
                class="text-right"
            >
              <button type="button" (click)="swapUp(campaign); $event.stopPropagation(); "
                      class="btn btn-sm btn-rounded btn-icon  m-t-sm m-b-sm bg-info"
                      [ngClass]="{ 'bg-warning':lastSwapedCampaign === campaign }"
                      data-testid="campaign.up()"

              >
                <i class="fa fa-arrow-up"></i>
              </button>

              <button type="button" (click)="swapDown(campaign); $event.stopPropagation();"
                      class="btn btn-sm btn-rounded btn-icon  m-t-sm m-b-sm m-l-sm bg-info"
                      [ngClass]="{ 'bg-warning':lastSwapedCampaign === campaign }"
                      data-testid="campaign.down()">
                <i class="fa fa-arrow-down"></i>
              </button>

            </td>
          </tr>
        </ng-container>
      </tbody>

    </table>

    <div class="text-center text-warning m-t-sm m-t-sm"
         *ngIf="!!linksPagerSettings.isLoadingCampaign"
    >
      <div class="">
        <i class="fa fa-3x fa-spin fa-spinner"></i>
      </div>

    </div>

  </div>

</div>

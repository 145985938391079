import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {UpgradeModule} from "@angular/upgrade/static";
import {CashBoxService} from "./service/cash-box.service";
import {ProcessingService} from "./service/processing.service";
import {CashBoxComponent} from "./component/cash-box/cash-box.component";
import {CashBoxHeaderComponent} from "./component/cash-box-header/cash-box-header.component";
import {CashBoxItemsComponent} from "./component/cash-box-items/cash-box-items.component";
import {CashBoxItemsRowComponent} from "./component/cash-box-items-row/cash-box-items-row.component";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";
import {DirectiveModule} from "../../directive/directive.module";
import {SharedComponentsModule} from "../../component/shared.components.module";
import {CashBoxClientComponent} from "./component/cash-box-client/cash-box-client.component";
import {SearchInputModule} from "../../component/search-input/search-input.module";
import {CashBoxOperationsComponent} from "./component/cash-box-operations/cash-box-operations.component";
import {CashBoxCheckComponent} from "./component/cash-box-check/cash-box-check.component";
import {
  CashboxInputWithPosition
} from "./component/cash-box-input-with-position/cash-box-input-with-position.component";
import {CashboxCollisionInfo} from "./component/cash-box-collision-info/cash-box-collision-info.component";


@NgModule({
  declarations: [
    CashBoxComponent,
    CashBoxHeaderComponent,
    CashBoxItemsComponent,
    CashBoxItemsRowComponent,
    CashBoxClientComponent,
    CashBoxOperationsComponent,
    CashBoxCheckComponent,
    CashboxInputWithPosition,
    CashboxCollisionInfo
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
    PipesComponentsModule,
    DirectiveModule,
    SharedComponentsModule,
    SearchInputModule,
  ],
  providers: [
    CashBoxService,
    ProcessingService
  ],
  exports:[
    CashBoxComponent
  ]
})
export class CashBoxModule {

  constructor() {}

}

'use strict';


import 'model/LocationGroupModel/resource';
import 'model/LocationModel/resource';

import 'model/CouponRule';
import 'model/CouponRuleModel/resource';
import 'model/Coupon';

import 'directive/locationsPopup/main';
import 'directive/goodDimension/main';
import {CKEditorBuilder} from 'class/CKEditorBuilder/CKEditorBuilder';

import 'component/bonusConfig/main';
import '../component/circulationCouponRule/main';

import importController from './importController';
import * as  importView from './views/import.html';
import {ImageUploaderHandler} from "module/imageUploader/imageUploaderHandler";


import { ISSUING_TYPES } from "./issuingTypes";
import { RewardWhatTypes, OnGoodRewType } from "./couponKinds/CouponKind";
import { StopPeriodTypes } from "./IssuingTypes/IssuingType";


export default ['$scope', '$state', '$uibModal', '$filter', '$interval', '$timeout', '$q', 'toaster', '$location', 'CouponRule', 'CouponRuleModel', 'Coupon', 'ruleItem', 'rawValues',  'FileUploader', '$sce', 'partnerList',  'CampaignCategoryList', 'loyaPermissions', 'isLoyaStart', 'LocationModel', 'LocationGroupModel', 'settingsList',
            function ($scope, $state, $uibModal, $filter, $interval, $timeout, $q, toaster, $location,  CouponRule, CouponRuleModel, Coupon, ruleItem, rawValues, FileUploader, $sce, partnerList, CampaignCategoryList, loyaPermissions, isLoyaStart, LocationModel, LocationGroupModel, settingsList) {

                $scope._t = new CouponRule();
                $scope.partnerList = partnerList;
                $scope.settingsList  = settingsList;

                $scope.today = new Date();
                $scope.today.setHours(0, 0, 0, 0, 0);

                $scope.tomorrow = new Date();
                $scope.tomorrow.setHours(0, 0, 0, 0, 0);
                $scope.tomorrow.setDate($scope.tomorrow.getDate() + 1);

                $scope.yesterday = new Date();
                $scope.yesterday.setHours(23, 59, 59, 0, 0);
                $scope.yesterday.setDate($scope.yesterday.getDate() - 1);

                $scope.CampaignCategoryList = CampaignCategoryList;

                const ckeBuilder = new CKEditorBuilder()
                                    .setMonospace()
                                    .addRowColumnCounter()
                                    .setPlaceholders( [{
                                        group: 'Клиент',
                                        placeholders: [
                                            {label: 'Используемая карта клиента', value: 'client.usableCardNumber'}
                                        ]
                                    }]);



                $scope.filterHtml     = ckeBuilder.filterHtml.bind(ckeBuilder);
                $scope.getCountFields = ckeBuilder.getCountFields.bind(ckeBuilder);
                $scope.getCountChars  = ckeBuilder.getCountChars.bind(ckeBuilder);
                $scope.maxDescriptionValidator = ckeBuilder.getMaxlenValidator(2000);
                $scope.optionsDescription = ckeBuilder.getConfig();

                var backupRule;

                var formStates = {

                    view: {
                        buttons: {
                            'edit': function () {
                                backupRule = angular.copy($scope.ruleItem);
                                $scope.setFormState('edit');
                            },
                            'remove': function () {
                                $scope.ruleItem.$delete({id: $scope.ruleItem.couponRule.id }, function() {
                                    $state.go('^.index');
                                });
                            }
                        },
                        activeForm: false
                    },

                    edit: {
                        buttons: {

                            'save': function () {

                                $scope.currentIssueType.$save();

                                if ( angular.isUndefined( $scope.ruleItem.couponRule.id ) ) {

                                    $scope.ruleItem.$create()
                                        .then( () => $scope.commitImages())
                                        .then( function (response) {

                                            $state.go('^.view', {id: $scope.ruleItem.couponRule.id}, {reload: true});

                                        });

                                } else {

                                    $scope.ruleItem.$update({id: $scope.ruleItem.couponRule.id}, function () {

                                        $scope.setFormState('view');

                                    }).then( () => $scope.commitImages());

                                }

                            },

                            'cancel': function () {

                                if ( angular.isUndefined( $scope.ruleItem.couponRule.id ) ) {
                                    $state.go('^.index');
                                } else {
                                    if (backupRule) {
                                        $scope.ruleItem = angular.copy(backupRule);
                                    }
                                    $scope.setFormState('view');
                                }

                            }
                        },
                        activeForm: true

                    }

                };

                $scope.setFormState = function (state) {

                    if (formStates[state] !== undefined) {
                        $scope.curState = formStates[state];
                        $scope.curState.stateName = state;


                    } else
                        $scope.curState = false;

                };

                $scope.unsetValue = function( path ) {

                    var findedPath = $scope;
                    path = path.split('.');

                    for ( var i = 0; i < path.length - 1; i++ ) {
                        if (  angular.isUndefined( findedPath[path[i]] ) ) {
                            return false;
                        }

                        findedPath = findedPath[path[i]];
                    }

                    if ( angular.isUndefined( findedPath[ path[ path.length - 1 ] ] ) ) {
                        return false;
                    } else {
                        delete findedPath[ path[ path.length - 1 ] ];
                        return true;
                    }

                };
                $scope.getValue = function( path ) {
                    try{
                        return $scope.$eval(path);
                    } catch(e) {
                        return undefined;
                    }
                };

                $scope.ruleItem = ruleItem;

                $scope.options = {
                    couponPriceType:  !angular.isUndefined( $scope.getValue('ruleItem.couponRule.issueSettings.priceInCurrency') ) ? 'currency' :
                                      !angular.isUndefined( $scope.getValue('ruleItem.couponRule.issueSettings.priceInBonus') ) ? 'bonus' :
                                      'currency'
                };

                backupRule = angular.copy($scope.ruleItem);

                $scope.values = angular.copy(rawValues);

                var updateValues = function( rawIndex ) {

                    return function(newVal, oldVal) {

                        if ( angular.isUndefined(newVal) ) {
                            $scope.values[rawIndex] = {};
                            return;
                        }

                        if ( newVal && angular.isObject( rawValues[rawIndex][newVal] ) ) {
                            $scope.values[rawIndex] = rawValues[rawIndex][newVal];

                            if ( Object.keys( $scope.values[rawIndex] ).length > 0 && Object.keys($scope.values[rawIndex]).indexOf( $scope.$eval('ruleItem.' + rawIndex) ) < 0 ) {
                                $scope.$eval('ruleItem.' + rawIndex + ' = value', { value:  Object.keys($scope.values[rawIndex])[0] } );
                            }

                        }

                    };

                };

                var updateValuesGroup = function( rawIndex ) {

                    return function(newVal, oldVal) {

                        if ( angular.isUndefined(newVal[0]) ) {
                            $scope.values[rawIndex] = {};
                            return;
                        }

                        if ( newVal.join('') && angular.isObject( rawValues[rawIndex][newVal.join('')] ) ) {
                            $scope.values[rawIndex] = rawValues[rawIndex][newVal.join('')];

                            if ( Object.keys( $scope.values[rawIndex] ).length > 0 && Object.keys($scope.values[rawIndex]).indexOf( $scope.$eval('ruleItem.' + rawIndex) ) < 0 ) {
                                $scope.$eval('ruleItem.' + rawIndex + ' = value', { value:  Object.keys($scope.values[rawIndex])[0] } );
                            }
                            return;
                        }

                        return updateValues(rawIndex)(newVal[0],oldVal[0]);

                    };

                };

                $scope.$watch('ruleItem.couponRule.issueSettings.issuingType',  updateValues('couponRule.issueSettings.couponKind') );

                $scope.$watchGroup(['ruleItem.couponRule.issueSettings.issuingType', 'ruleItem.couponRule.issueSettings.couponKind'], updateValuesGroup('couponRule.issueSettings.startPeriod') );

                $scope.$watch('ruleItem.couponRule.reward.reward.what',         updateValues('couponRule.reward.reward.measure') );

                $scope.$watchGroup(['ruleItem.couponRule.issueSettings.issuingType', 'ruleItem.couponRule.issueSettings.couponKind'], updateValuesGroup('couponRule.issueSettings.stopPeriod') );

                $scope.$watchGroup(
                    [ 'ruleItem.couponRule.reward.reward.what',
                      'ruleItem.couponRule.reward.reward.measure',
                      'ruleItem.couponRule.issueSettings.issuingType',
                      'ruleItem.couponRule.issueSettings.couponKind',
                      'ruleItem.couponRule.applySettings.couponApply',
                      'ruleItem.couponRule.reward.onGood.rewType',
                    ],
                    function(newVal, oldVal) {

                        if ( ( angular.isUndefined(newVal[0]) ||
                               angular.isUndefined(newVal[1]) ) &&
                               newVal[2] !== 'public'
                            ) {

                                $scope.options.useCountEnabled = false;
                                $scope.unsetValue('ruleItem.couponRule.usingSettings.useCount');
                                return;

                            }
/*
                        if ( newVal[2] === 'public' && newVal[3] === 'couponWithoutBarcode' ) {

                            $scope.options.useCountEnabled = false;
                            $scope.unsetValue('ruleItem.couponRule.usingSettings.useCount');
                            return;

                        }
*/
                        if (  newVal[3] === 'sticker' || newVal[3] === 'couponWithBarcodeWithReceptParams' ) {

                            $scope.options.useCountEnabled = false;
                            $scope.unsetValue('ruleItem.couponRule.usingSettings.useCount');
                            return;

                        }

                        $scope.options.useCountEnabled = false;

                        if ( newVal[0] === "gift" )
                            if ( $scope.ruleItem.couponRule.applySettings.couponApply === "applyInFirstSuitedCheck" ) {
                                $scope.unsetValue('ruleItem.couponRule.applySettings.minRewardInCurrency');
                            } else {
                                $scope.options.useCountEnabled = true;
                                return;
                            }

                        switch (true) {

                            case  newVal[5] === 'count' :
                            case  newVal[3] === 'virtualRuleBarcode' :
                            case  newVal[1] === 'money' || newVal[1] === 'count' || newVal[2] === 'public':

                                $scope.options.useCountEnabled = false;
                                $scope.ruleItem.couponRule.usingSettings.useCount = 1;
                                break;

                            case  newVal[1] === 'percent' :
                                $scope.options.useCountEnabled = true;
                                break;

                        }

                });

                $scope.$watch('ruleItem.couponRule.applySettings.couponApply', function (newVal, oldVal) {

                    if ( $scope.getValue('ruleItem.couponRule.reward.reward.what') === "gift" && newVal === "applyInFirstSuitedCheck" ) {
                        $scope.unsetValue('ruleItem.couponRule.applySettings.minRewardInCurrency');
                    }

                    if (newVal !=='applyInFirstSuitedCheck' ) {
                        $scope.unsetValue('ruleItem.couponRule.applySettings.minRewardInCurrency');
                    }

                });

                $scope.$watch('ruleItem.couponRule.issueSettings.startPeriod', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    switch( newVal ) {

                        case 'fromIssue':

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.startDate');
                            $scope.unsetValue('ruleItem.couponRule.issueSettings.startScheduled');

                            break;
                        case 'fromDate':

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.startScheduled');

                            break;
                        case 'scheduled':

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.startDate');

                            if (
                                $scope.getValue('ruleItem.couponRule.issueSettings') &&
                                angular.isUndefined( $scope.getValue('ruleItem.couponRule.issueSettings.startScheduled') )
                                ) {

                                $scope.ruleItem.couponRule.issueSettings.startScheduled = {
                                    dimension: Object.keys( $scope.values['couponRule.issueSettings.startScheduled.dimension'] )[0]
                                };
                            }

                            break;

                    }

                });

                $scope.$watch('ruleItem.couponRule.issueSettings.stopPeriod', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    switch( newVal ) {

                        case 'fromDate':

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.stopScheduled');
                            break;

                        case 'scheduled':

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.stopDate');

                            if ( $scope.ruleItem.couponRule &&
                                $scope.ruleItem.couponRule.issueSettings &&
                                !$scope.ruleItem.couponRule.issueSettings.stopScheduled ) {

                                $scope.ruleItem.couponRule.issueSettings.stopScheduled = {
                                    dimension: Object.keys( $scope.values['couponRule.issueSettings.stopScheduled.dimension'] )[0]
                                };
                            }

                            break;
/*
                        case StopPeriodTypes.STOP_PERIOD_DAY_ISSUE:

                            $scope.unsetValue('ruleItem.couponRule.issueSettings.stopDate');

                            if ( $scope.ruleItem.couponRule &&
                                $scope.ruleItem.couponRule.issueSettings ) {

                                $scope.ruleItem.couponRule.issueSettings.stopScheduled = {
                                    dimension: Object.keys( $scope.values['couponRule.issueSettings.stopScheduled.dimension'] )[0],
                                    value: 0
                                };
                            }

                            break;

*/
                    }

                });

                $scope.virtualStopDate = undefined;
                $scope.$watchGroup(['ruleItem.couponRule.issueSettings.stopScheduled.value','ruleItem.couponRule.issueSettings.stopScheduled.dimension', 'ruleItem.couponRule.issueSettings.startDate'], function(newVal, oldVal) {
                  if ( (!newVal[0] && !newVal[1]) || !newVal[2]  ) {
                    $scope.virtualStopDate = undefined;
                    return;
                  }


                  $scope.virtualStopDate = new Date( newVal[2].getTime() );

                  switch( newVal[1] ) {

                    case "day":

                      $scope.virtualStopDate.setDate(
                        $scope.virtualStopDate.getDate() +
                        newVal[0] - 1
                      );

                      break;
                    case "week":

                      $scope.virtualStopDate.setDate(
                        $scope.virtualStopDate.getDate() +
                        ( newVal[0] * 7 )
                      );

                      break;
                    case "month":
                      $scope.virtualStopDate.setMonth(
                        $scope.virtualStopDate.getMonth() +
                        newVal[0]
                      );
                      break;
                    case "year":
                      $scope.virtualStopDate.setFullYear(
                        $scope.virtualStopDate.getFullYear() +
                        newVal[0]
                      );
                      break;

                  }

                });

                $scope.$watch('ruleItem.couponRule.reward.reward.rewType', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    switch( newVal ) {

                        case 'total':

                            $scope.unsetValue('ruleItem.couponRule.reward.onGood');
                            $scope.unsetValue('ruleItem.couponRule.reward.onGood.quantity');
                            $scope.unsetValue('ruleItem.couponRule.reward.reward.extensionType');
                            $scope.unsetValue('ruleItem.couponRule.reward.goodList');

                            $scope.ruleItem.couponRule.reward.onTotal = $scope.ruleItem.couponRule.reward.onTotal || {};
                            $scope.ruleItem.couponRule.reward.onTotal.rewType = $scope.ruleItem.couponRule.reward.onTotal.rewType || "total";
                            $scope.ruleItem.couponRule.reward.onTotal.freqType = $scope.ruleItem.couponRule.reward.onTotal.freqType || "all";
                            $scope.ruleItem.couponRule.reward.onTotal.valueOver = $scope.ruleItem.couponRule.reward.onTotal.valueOver || 0;
                            $scope.ruleItem.couponRule.reward.onTotal.value = $scope.ruleItem.couponRule.reward.onTotal.value || 0;

                            break;

                        case 'position':
                            $scope.unsetValue('ruleItem.couponRule.reward.onTotal');
                            $scope.unsetValue('ruleItem.couponRule.reward.extensionType');

                            $scope.ruleItem.couponRule.reward.reward.extensionType = "all";

                            $scope.ruleItem.couponRule.reward.onGood = $scope.ruleItem.couponRule.reward.onGood || {};
                            $scope.ruleItem.couponRule.reward.onGood.rewType = $scope.ruleItem.couponRule.reward.onGood.rewType || "all";
                            break;

                        case 'goods':

                            $scope.unsetValue('ruleItem.couponRule.reward.onTotal');

                            $scope.ruleItem.couponRule.reward.reward.extensionType = "all";

                            $scope.ruleItem.couponRule.reward.onGood = $scope.ruleItem.couponRule.reward.onGood || {};
                            $scope.ruleItem.couponRule.reward.onGood.rewType = $scope.ruleItem.couponRule.reward.onGood.rewType || "every";
                            break;


                        case 'setOfGoods':
                            $scope.unsetValue('ruleItem.couponRule.reward.onTotal');
                            $scope.unsetValue('ruleItem.couponRule.reward.onGood');

                            $scope.ruleItem.couponRule.reward.reward.extensionType = "all";
                            break;
/*
                        case 'position':
                            $scope.unsetValue('ruleItem.couponRule.reward.onTotal');
                            $scope.unsetValue('ruleItem.couponRule.reward.onGood');
                            $scope.unsetValue('ruleItem.couponRule.reward.goodList');
                            $scope.unsetValue('ruleItem.couponRule.reward.extensionType');
                            break;*/
                    }

                });

                $scope.$watch('ruleItem.couponRule.reward.reward.what', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    switch( newVal ) {

                        case 'discount':
                            $scope.unsetValue('ruleItem.couponRule.reward.giftList');
                            if (oldVal === 'bonus') {
                                $scope.unsetValue('ruleItem.couponRule.reward.ignoreLimits');
                            }

                            break;

                        case 'bonus':
                            $scope.unsetValue('ruleItem.couponRule.reward.giftList');
                            if (oldVal === 'discount') {
                                $scope.unsetValue('ruleItem.couponRule.reward.ignoreLimits');
                            }
                            break;

                        case 'gift':
                            $scope.ruleItem.couponRule.reward.reward.value = 1;
                            $scope.ruleItem.couponRule.reward.reward.measure = "count";

                            // $scope.unsetValue('ruleItem.couponRule.reward.reward.value');
                            // $scope.unsetValue('ruleItem.couponRule.reward.reward.measure');
                            break;
                    }

                });

                $scope.$watchGroup(['isEC', 'isECwithPrint', 'isExternal', 'isOpenSource', 'isExternalEC', 'isExternalWithPrint', 'isUniqueCouponGen' ], function(newVal, oldVal) {

                    if (angular.isUndefined(newVal)) {
                        return;
                    }

                    if ( $scope.currentCouponKind.getCouponApply().length > 0 ) {

                        if ( $scope.currentCouponKind.getCouponApply().indexOf($scope.ruleItem.couponRule.applySettings.couponApply) < 0 ) {
                            $scope.ruleItem.couponRule.applySettings.couponApply =  $scope.currentCouponKind.getCouponApply()[0];
                        }

                    }

                    if (!angular.isUndefined(newVal[0]) && !angular.isUndefined(newVal[1]) && !angular.isUndefined(newVal[2]) && !newVal[0] && !newVal[1] && !newVal[2] && !$scope.isUnknown) {

                        $scope.unsetValue('ruleItem.couponRule.issueSettings.sameClientCount');

                    }


                });

                $scope.isRuleUnicCouponRule = function() {
                    return $scope.getValue('ruleItem.couponRule.state') !== 'draft' &&
                        [  'couponWithBarcode', 'importedCouponForPrint', 'importedCoupon' ].indexOf( $scope.getValue('ruleItem.couponRule.issueSettings.couponKind') ) >=0 &&
                        [  'pos', 'external', 'uniqueCouponGen'  ].indexOf( $scope.getValue('ruleItem.couponRule.issueSettings.issuingType') )  >=0;
                };

                $scope.validDaysObj = {};

                if ( angular.isArray( $scope.getValue('ruleItem.couponRule.applySettings.validDays') ) ) {

                    angular.forEach( $scope.ruleItem.couponRule.applySettings.validDays, function( item ) {
                        $scope.validDaysObj[item] = true;
                    });
                }

                $scope.$watch('validDaysObj', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    try {

                        while ( $scope.ruleItem.couponRule.applySettings.validDays.length ) {
                            $scope.ruleItem.couponRule.applySettings.validDays.pop();
                        }

                        angular.forEach(newVal, function( enabled, key) {
                            if (enabled) {
                                $scope.ruleItem.couponRule.applySettings.validDays.push(key);
                            }
                        });

                    } catch(e) {
                        $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                        $scope.ruleItem.couponRule.applySettings = $scope.ruleItem.couponRule.applySettings || {};
                        $scope.ruleItem.couponRule.applySettings.validDays = $scope.ruleItem.couponRule.applySettings.validDays || [];
                    }

                }, true);

                $scope.selectLocationList = function ( result ) {

                    //$scope.ruleItem.locations = angular.copy( result.ids );
                    $scope.ruleItem.locations = angular.copy( result.locations );
                };
                $scope.removeLocation = function( index ) {
                    $scope.ruleItem.locations.splice(index, 1);
                };

                $scope.getLocationsError = function () {
                    return ( !angular.isArray($scope.ruleItem.locationGroups) || !$scope.ruleItem.locationGroups.length ) &&
                        ( !angular.isArray($scope.ruleItem.locations) || !$scope.ruleItem.locations.length );

                };

                $scope.exportGenerated = function() {
                    window.location.href = $location.$$protocol + '://' + $location.$$host + ( $location.$$port !== 80 ? ':' + $location.$$port : '' ) +
                        `/coupon/rule/${$scope.ruleItem.couponRule.id}/exportGeneratedCoupons` ;
                };

                $scope.$watch('ruleItem.locationGroups', function (newVal) {

                    if (newVal && newVal[0]) {
                        $scope.selectedLocationGroupList = newVal[0].id;
                    } else {
                        $scope.selectedLocationGroupList = false;
                    }
                }, true);

                $scope.$watch('selectedLocationGroupList', function (newVal) {

                    if (newVal !== undefined) {

                        if (newVal === false) {
                            $scope.ruleItem.locationGroups = [];
                        } else
                            for (var i in $scope.LocationGroupList) {

                                if ($scope.LocationGroupList[i].id !== undefined && $scope.LocationGroupList[i].id === newVal) {

                                    $scope.ruleItem.locationGroups = [{
                                        id: newVal,
                                        partnerId: $scope.ruleItem.couponRule.partnerId,
                                        name: $scope.LocationGroupList[i].name
                                    }];

                                    return;
                                }
                            }
                    }

                });

                $scope.$watch('ruleItem.couponRule.partnerId', function(newVal, oldVal) {

                    if ( !angular.isUndefined(newVal) ) {

                        var params = {
                          filter : {
                            partnerId: newVal
                          },
                          pager: {
                            drop: 0,
                            limit: 99999,
                          }
                        };
                        $scope.LocationGroupList = LocationGroupModel.query(params, function (locations) {
                            if (angular.isArray(locations) && locations.length > 0)
                                locations.unshift({
                                    createdBy: "user",
                                    id: false,
                                    name: "--НЕТ--",
                                    partnerId: newVal
                                });

                            return locations;
                        });

                        $scope.LocationList = LocationModel.queryLocations(
                          {
                            filter: {
                              'partnerId': newVal,
                            },
                            'pager' : {
                              limit: 10
                            }
                          }
                        );

                        if (newVal !== oldVal) {

                            if ($scope.ruleItem && $scope.ruleItem.locations) {
                                delete $scope.ruleItem.locations;
                            }

                            if ($scope.ruleItem && $scope.ruleItem.locationGroups) {
                                delete $scope.ruleItem.locationGroups;
                            }

                        }
                    }


                });

                $scope.$watch('ruleItem.couponRule.issueSettings.canBeSold', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) || newVal === false ) {

                        $scope.unsetValue('ruleItem.couponRule.issueSettings.priceInCurrency');
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.priceInBonus');

                    }


                });

                $scope.$watch('options.couponPriceType', function(newVal, oldVal) {

                    switch (newVal) {
                        case 'currency':
                            if ( angular.isUndefined(ruleItem.couponRule.issueSettings.priceInCurrency) ) {
                                ruleItem.couponRule.issueSettings.priceInCurrency = $scope.getValue('ruleItem.couponRule.issueSettings.priceInBonus');
                            }
                            $scope.unsetValue('ruleItem.couponRule.issueSettings.priceInBonus');
                            break;

                        case 'bonus':
                            if ( angular.isUndefined(ruleItem.couponRule.issueSettings.priceInBonus) ) {
                                ruleItem.couponRule.issueSettings.priceInBonus = parseFloat( $scope.getValue('ruleItem.couponRule.issueSettings.priceInCurrency') );
                            }
                            $scope.unsetValue('ruleItem.couponRule.issueSettings.priceInCurrency');
                            break;

                    }


                });

                $scope.rewTypeValues = {};
                $scope.$watch('ruleItem.couponRule.issueSettings.couponKind',function(value) {
                    $scope.rewTypeValues = {};

                    Object.keys($scope.values['couponRule.reward.reward.rewType'])
                          .filter( id => ( value === 'sticker') ? id === 'position' : id !== 'position' )
                          .forEach( id => $scope.rewTypeValues[id] = $scope.values['couponRule.reward.reward.rewType'][id] );

                    if ( Object.keys($scope.rewTypeValues).indexOf($scope.ruleItem.couponRule.reward.reward.rewType) < 0 ) {
                        $scope.ruleItem.couponRule.reward.reward.rewType = Object.keys($scope.rewTypeValues)[0];
                    }

                });

                $scope.rewardWhatValues    =  $scope._t.getField('couponRule.reward.reward.what','values');
                $scope.rewardRewTypeValues =  $scope._t.getField('couponRule.reward.reward.rewType','values');

                var addIfNotExist = function (value, array) {
//ruleItem.couponRule.reward
                    if (angular.isUndefined(array))
                        array = [];

                    for (var i in array) {
                        if (angular.equals(array[i], value))
                            return false;
                    }

                    array.push(value);
                    return array;
                };

                $scope.selectGiftsList  = function( returnGifts )  {

                    $scope.ruleItem.couponRule.reward.giftList = $scope.ruleItem.couponRule.reward.giftList || [];
                    angular.forEach( returnGifts.list, function( item ) {

                        addIfNotExist({
                            sku: item.id,
                            "price": "0.00",
                            "planCount": 0,
                            "realCount": 0,
                            "total": 0

                        }, $scope.ruleItem.couponRule.reward.giftList);

                    });
                };

                $scope.removeGiftsList  = function()  {

                    $scope.unsetValue('ruleItem.couponRule.reward.giftList');

                };

                $scope.selectWhiteGoods = function ( returnGoods ) {

                    if (angular.isArray(returnGoods.list) && returnGoods.list.length === 0  && returnGoods.type !== "favourite" ) {
                        return;
                    }

                    $scope.ruleItem.couponRule.reward = $scope.ruleItem.couponRule.reward || {};
                    $scope.ruleItem.couponRule.reward.goodList = $scope.ruleItem.couponRule.reward.goodList || {};

                    if ( returnGoods.type === undefined) {
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.whiteListType');
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.whiteIdList');
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.whiteSKUList');
                        return;
                    }

                    if ( $scope.ruleItem.couponRule.reward.goodList.whiteListType !== returnGoods.type ) {
                         $scope.ruleItem.couponRule.reward.goodList.whiteListType = returnGoods.type;

                        switch( $scope.ruleItem.couponRule.reward.goodList.whiteListType ) {

                            case 'sku':
                                $scope.ruleItem.couponRule.reward.goodList.whiteSKUList = [];
                                $scope.unsetValue('ruleItem.couponRule.reward.goodList.whiteIdList');
                                break;

                            default:
                                $scope.ruleItem.couponRule.reward.goodList.whiteIdList = [];
                                $scope.unsetValue('ruleItem.couponRule.reward.goodList.whiteSKUList');
                                break;
                        }
                    }


                    angular.forEach( returnGoods.list, function( item ) {
                        switch( $scope.ruleItem.couponRule.reward.goodList.whiteListType ) {

                            case 'sku':
                                addIfNotExist(item.id, $scope.ruleItem.couponRule.reward.goodList.whiteSKUList);
                                break;

                            default:
                                addIfNotExist(item.id, $scope.ruleItem.couponRule.reward.goodList.whiteIdList);
                                break;
                        }

                    });

                };

                $scope.removeWhiteGoods = function()  {

                    if ($scope.ruleItem.couponRule.reward && $scope.ruleItem.couponRule.reward.goodList) {
                        delete $scope.ruleItem.couponRule.reward.goodList.whiteListType;
                        delete $scope.ruleItem.couponRule.reward.goodList.whiteIdList;
                        delete $scope.ruleItem.couponRule.reward.goodList.whiteSKUList;
                    }

                };

                $scope.selectBlackGoods = function ( returnGoods ) {

                    if (angular.isArray(returnGoods.list) && returnGoods.list.length === 0  && returnGoods.type !== "favourite") {
                        return;
                    }

                    $scope.ruleItem.couponRule.reward = $scope.ruleItem.couponRule.reward || {};
                    $scope.ruleItem.couponRule.reward.goodList = $scope.ruleItem.couponRule.reward.goodList || {};

                    if ( returnGoods.type === undefined) {
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.blackListType');
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.blackIdList');
                        $scope.unsetValue('ruleItem.couponRule.reward.goodList.blackSKUList');
                        return;
                    }

                    if ( $scope.ruleItem.couponRule.reward.goodList.blackListType !== returnGoods.type ) {

                        $scope.ruleItem.couponRule.reward.goodList.blackListType = returnGoods.type;

                        switch( $scope.ruleItem.couponRule.reward.goodList.blackListType ) {

                            case 'sku':
                                $scope.ruleItem.couponRule.reward.goodList.blackSKUList = [];
                                $scope.unsetValue('ruleItem.couponRule.reward.goodList.blackIdList');
                                break;

                            default:
                                $scope.ruleItem.couponRule.reward.goodList.blackIdList = [];
                                $scope.unsetValue('ruleItem.couponRule.reward.goodList.blackSKUList');
                                break;
                        }


                    }


                    angular.forEach( returnGoods.list, function( item ) {
                        switch( $scope.ruleItem.couponRule.reward.goodList.blackListType ) {

                            case 'sku':
                                addIfNotExist(item.id, $scope.ruleItem.couponRule.reward.goodList.blackSKUList);
                                break;

                            default:
                                addIfNotExist(item.id, $scope.ruleItem.couponRule.reward.goodList.blackIdList);
                                break;
                        }

                    });

                };

                $scope.removeBlackGoods = function()  {

                    if ($scope.ruleItem.couponRule.reward && $scope.ruleItem.couponRule.reward.goodList) {
                        delete $scope.ruleItem.couponRule.reward.goodList.blackListType;
                        delete $scope.ruleItem.couponRule.reward.goodList.blackIdList;
                        delete $scope.ruleItem.couponRule.reward.goodList.blackSKUList;
                    }

                };



              $scope.selectWhiteGoodsApply = function ( returnGoods ) {

                if (angular.isArray(returnGoods.list) && returnGoods.list.length === 0  && returnGoods.type !== "favourite" ) {
                  return;
                }


                $scope.ruleItem.couponRule.applySettings.rawSettings = $scope.ruleItem.couponRule.applySettings.rawSettings || {};
                $scope.ruleItem.couponRule.applySettings.rawSettings.goodList = $scope.ruleItem.couponRule.applySettings.rawSettings.goodList || {};

                if ( returnGoods.type === undefined) {
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType');
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList');
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList');
                  return;
                }

                if ( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType !== returnGoods.type ) {
                  $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType = returnGoods.type;

                  switch( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType ) {

                    case 'sku':
                      $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList = [];
                      $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList');
                      break;

                    default:
                      $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList = [];
                      $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList');
                      break;
                  }
                }


                angular.forEach( returnGoods.list, function( item ) {
                  switch( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType ) {

                    case 'sku':
                      addIfNotExist(item.id, $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList);
                      break;

                    default:
                      addIfNotExist(item.id, $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList);
                      break;
                  }

                });

              };

              $scope.removeWhiteGoodsApply = function()  {

                if ($scope.ruleItem.couponRule.applySettings.rawSettings && $scope.ruleItem.couponRule.applySettings.rawSettings.goodList) {
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteListType;
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteIdList;
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.whiteSKUList;
                }

              };

              $scope.selectBlackGoodsApply = function ( returnGoods ) {

                if (angular.isArray(returnGoods.list) && returnGoods.list.length === 0  && returnGoods.type !== "favourite") {
                  return;
                }

                $scope.ruleItem.couponRule.applySettings.rawSettings = $scope.ruleItem.couponRule.applySettings.rawSettings || {};
                $scope.ruleItem.couponRule.applySettings.rawSettings.goodList = $scope.ruleItem.couponRule.applySettings.rawSettings.goodList || {};

                if ( returnGoods.type === undefined) {
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType');
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.blackIdList');
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList');
                  return;
                }

                if ( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType !== returnGoods.type ) {

                  $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType = returnGoods.type;

                  switch( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType ) {

                    case 'sku':
                      $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList = [];
                      $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.blackIdList');
                      break;

                    default:
                      $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackIdList = [];
                      $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList');
                      break;
                  }


                }


                angular.forEach( returnGoods.list, function( item ) {
                  switch( $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType ) {

                    case 'sku':
                      addIfNotExist(item.id, $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList);
                      break;

                    default:
                      addIfNotExist(item.id, $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackIdList);
                      break;
                  }

                });

              };

              $scope.removeBlackGoodsApply = function()  {

                if ($scope.ruleItem.couponRule.applySettings.rawSettings && $scope.ruleItem.couponRule.applySettings.rawSettings.goodList) {
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackListType;
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackIdList;
                  delete $scope.ruleItem.couponRule.applySettings.rawSettings.goodList.blackSKUList;
                }

              };

              $scope.$watch('ruleItem.couponRule.applySettings.applyEvery', function(newVal, oldVal) {

                if ( !(newVal > 0) ) {
                  $scope.unsetValue('ruleItem.couponRule.applySettings.rawSettings.goodList');
                }

              });

              $scope.isCouponGeneratorRule = function() {
                    return $scope.getValue('ruleItem.couponRule.issueSettings.issuingType')  === 'uniqueCouponGen';
                };

                $scope.isEC          = false;
                $scope.isECwithPrint = false;
                $scope.isExternal    = false;
                $scope.isOpenSource  = false;
                $scope.isUnknown     = true;

                $scope.isUniqueCouponGen = false;

                $scope.isExternalEC  = false;
                $scope.isExternalWithPrint  = false;


                $scope.isPosType      = false;
                $scope.isExternalType = false;
                $scope.isOpenType     = false;
                $scope.isManualType   = false;

                $scope.$watchGroup( [
                    'ruleItem.couponRule.issueSettings.issuingType', // Тип выдачи купонов
                    'ruleItem.couponRule.issueSettings.couponKind'   // Тип купона
                ], function(newVal, oldVal) {

                    var issuingType = newVal[0], // Тип выдачи купонов
                        couponKind  = newVal[1]; // Тип купона

                    $scope.isEC          = [ 'eCoupon', 'couponWithoutBarcode' ].indexOf( couponKind ) >= 0 &&
                                           [ 'pos', 'trigger','manual'].indexOf( issuingType ) >= 0 ;

                    $scope.isECwithPrint = [ 'couponWithBarcode', 'importedCouponForPrint', 'virtualRuleBarcode' ].indexOf( couponKind ) >= 0 &&
                                           [ 'pos'  ].indexOf( issuingType ) >= 0      ;

                    $scope.isExternal    = [ 'couponWithBarcode', 'eCoupon', 'importedCoupon' ].indexOf( couponKind ) >= 0 &&
                                           [ 'external' ].indexOf( issuingType ) >= 0 ;

                    $scope.isOpenSource  = [ 'couponRuleBarcode', 'couponWithoutBarcode' ].indexOf( couponKind ) >= 0 &&
                                           [ 'public'  ].indexOf( issuingType ) >= 0   ;

                    $scope.isExternalEC         = $scope.isExternal && (couponKind === "eCoupon") ;
                    $scope.isExternalWithPrint  = $scope.isExternal && (couponKind === "couponWithBarcode") ;

                    $scope.isUniqueCouponGen   = [ 'couponWithBarcode' ].indexOf( couponKind ) >= 0 &&
                                                 [ 'uniqueCouponGen'  ].indexOf( issuingType ) >= 0   ;

                    $scope.isPosType      = ['pos'].indexOf( issuingType ) >= 0      ;
                    $scope.isExternalType = ['external'].indexOf( issuingType ) >= 0 ;
                    $scope.isOpenType     = ['public'].indexOf( issuingType ) >= 0   ;
                    $scope.isManualType   = ['manual'].indexOf( issuingType ) >= 0   ;

                 //   $scope.isRuleWithImport  = [ 'importedCoupon', 'importedCouponForPrint', 'couponWithBarcodeWithReceptParams'].indexOf( couponKind ) >= 0 ;

                });

                $scope.sameClientCountDisabled = false;
                $scope.usable3rdPartiesDisabled = false;

                $scope.$watch(  'ruleItem.couponRule.issueSettings.couponKind', function(newVal, oldVal) {

                    if ( angular.isUndefined(newVal) ) {
                        return;
                    }

                    if ( [ 'virtualRuleBarcode','sticker' ].indexOf( newVal ) < 0) {
                        $scope.sameClientCountDisabled = false;
                        $scope.usable3rdPartiesDisabled = false;
                    } else {

                        $scope.ruleItem.couponRule.issueSettings.sameClientCount = 1;
                        $scope.ruleItem.couponRule.usingSettings.usable3rdParties = true;
                        $scope.sameClientCountDisabled = true;
                        $scope.usable3rdPartiesDisabled = true;
/*

                        $scope.values['couponRule.issueSettings.startPeriod']  = {
                            fromDate: rawValues['couponRule.issueSettings.startPeriod'].manual.fromDate
                        }

                        $scope.ruleItem.couponRule.issueSettings.startPeriod = Object.keys($scope.values['couponRule.issueSettings.startPeriod'])[0];
*/

                    }

                    if ( newVal === "couponWithBarcode" && isLoyaStart )  {
                      $scope.ruleItem.couponRule.usingSettings.usable3rdParties = true;
                      $scope.usable3rdPartiesDisabled = true;
                    }

                    if (newVal === 'importedCoupon' &&  $scope.getValue('ruleItem.couponRule.reward.reward.what') === "gift" ) {
                        $scope.ruleItem.couponRule.reward.reward.what = 'discount';
                    }

                });


                $scope.$watch(  'currentCouponKind.hasPrefix', function(newVal, oldVal) {
                    if (newVal === false) {
                        $scope.unsetValue('ruleItem.couponRule.prefix');
                        delete $scope.prefix.tmp;
                    }
                });

                $scope.$watch(  'ruleItem.couponRule.issueSettings.couponKind', function(newVal, oldVal) {
                    if ( ['importedCoupon'].indexOf( newVal ) >= 0 ) {
                        $scope.ruleItem.couponRule.reward = $scope.ruleItem.couponRule.reward || {};
                        $scope.ruleItem.couponRule.reward.reward = $scope.ruleItem.couponRule.reward.reward || {};
                        $scope.ruleItem.couponRule.reward.reward.value = 0;
                    }
                });

                $scope.$watchGroup( [
                    'ruleItem.couponRule.issueSettings.issuingType', // Тип выдачи купонов
                    'ruleItem.couponRule.issueSettings.couponKind'   // Тип купона
                ], function(newVal, oldVal) {

                    if ( newVal[0] === 'public' ) {

                        if ( newVal[1] === 'couponRuleBarcode' )  {
                            $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                            $scope.ruleItem.couponRule.usingSettings = $scope.ruleItem.couponRule.usingSettings || {};
                            $scope.ruleItem.couponRule.usingSettings.usable3rdParties = true;
                        }

                        if ( newVal[1] === 'couponWithoutBarcode' )  {
                            $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                            $scope.ruleItem.couponRule.usingSettings = $scope.ruleItem.couponRule.usingSettings || {};
                            $scope.ruleItem.couponRule.usingSettings.usable3rdParties = false;
                        }

                    } else {

                        if ( ( !$scope.isECwithPrint && !$scope.isExternalWithPrint) && !$scope.usable3rdPartiesDisabled ) {
                            $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                            $scope.ruleItem.couponRule.usingSettings = $scope.ruleItem.couponRule.usingSettings || {};
                            $scope.ruleItem.couponRule.usingSettings.usable3rdParties = false;
                        }
                    }

                });

                // Prefix
                $scope.prefixPad = function( value ) {
                    return value.tmp ? String( "0000").slice(0, value.tmp.length <= value.maxLen ? value.maxLen - value.tmp.length : 0  ): value.tmp;
                };

                $scope.prefix = {
                    head   : '',
                    tmp    : $scope.getValue('ruleItem.couponRule.prefix'),
                    maxLen : 4
                };

                switch (true) {
                    case ( angular.isString($scope.prefix.tmp) && /^2/.test($scope.prefix.tmp)  ):
                        $scope.prefix.tmp = $scope.prefix.tmp.replace( /^2/, '' );
                        $scope.prefix.head = '2';
                    break;

                    case ( angular.isString($scope.prefix.tmp) && /^99/.test($scope.prefix.tmp)  ):
                        $scope.prefix.tmp = $scope.prefix.tmp.replace( /^99/, '' );
                        $scope.prefix.head = '99';
                    break;

                    default:
                        $scope.prefix.tmp = undefined;
                        $scope.prefix.head = '2';
                }

                $scope.setActivePrefix = function() {
                    $timeout(function () {
                        try {
                            document.getElementById('prefix_tmp').focus();
                        } catch(e) {}
                    });
                };

                $scope.$watch( 'prefix.head', function (newValue, oldValue) {

                    $scope.prefix.maxLen = typeof newValue === 'string'?  6 - newValue.length : 6;

                });

                $scope.$watch( 'prefix', function (newValue, oldValue) {

                    if ( !$scope.currentCouponKind || !$scope.currentCouponKind.hasPrefix ) {
                        return;
                    }

                    if (!newValue.tmp) {
                        $scope.ruleItem.couponRule.prefix = newValue.head;
                    } else {
                        $scope.ruleItem.couponRule.prefix = newValue.head + newValue.tmp;
                    }

                }, true);
                // Prefix


                $scope.setRuleState = function( state ) {

                    if (  Object.keys($scope.values['couponRule.state']).indexOf(state) <0 ) {
                        return false;
                    }

                    return $scope.ruleItem.$setState({ id: $scope.ruleItem.couponRule.id, state: state }).$promise;

                };

                $scope.duplicateRule = function() {

                    $scope.ruleItem = angular.copy( $scope.ruleItem );

                    $scope.unsetValue('ruleItem.couponRule.id');
                    $scope.ruleItem.couponRule.state = 'draft';
                    $scope.ruleItem.couponRule.name += " (копия)";

                    $scope.unsetValue('ruleItem.couponRule.id');
                    $scope.unsetValue('ruleItem.couponRule.barcode');
                    $scope.prefix.tmp = '';

                    if ( !$scope.isOpenSource ) {
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.generatedCount');
                    }

                    $scope.setFormState( 'edit' );

                };

                $scope.checkCurrentPrefix = function( inPrefix ) {

                    var prefix;

                    if ( !angular.isUndefined(inPrefix) ) {
                        prefix = inPrefix;
                    } else {
                        prefix = $scope.ruleItem.couponRule.prefix;
                    }

                    return CouponRule.queryCouponRuleForCreationRequest({
                        'prefix': prefix
                    }).$promise.then( function(result) {

                      if (!result || !Object.keys(result).filter(i=>i[0] !== '$').length || ( !angular.isUndefined( $scope.ruleItem.couponRule.id ) && result.id === $scope.ruleItem.couponRule.id ))
                        return false

                      return {
                        couponRule:result
                      }

                    });

                };

                $scope.isBarcodeNotInvalid = false;
                $scope.checkBarcode = function( barcode ) {

                    if ( !barcode ) {
                      $scope.isBarcodeNotInvalid = false;
                      return;
                    }

                    let params : any = {
                        'barcode': barcode
                    };

                    if ($scope.ruleItem.couponRule && $scope.ruleItem.couponRule.id) {
                        params.id = $scope.ruleItem.couponRule.id;
                    }

                    return CouponRule.checkbarcode(params)
                        .$promise
                        .then( () => { $timeout(()=>$scope.isBarcodeNotInvalid = false);  }, ( error ) => { $timeout(()=>$scope.isBarcodeNotInvalid = error.data && error.data.text ? error.data.text : error.data  ); });

                };

                let barcodeBackup = false;
                $scope.patternBarcodeInput = /^.*$/ ;
                let initBarcode =  ( $scope.ruleItem.couponRule && $scope.ruleItem.couponRule.barcode ) ? $scope.ruleItem.couponRule.barcode : '';
                $scope.checkBarcodeInput = function( flag ) {

                    if ( flag ) {

                        if (barcodeBackup) {
                            $scope.ruleItem.couponRule.barcode = barcodeBackup;
                        }

                    } else {
                        barcodeBackup = $scope.ruleItem.couponRule.barcode;
                        $scope.ruleItem.couponRule.barcode = initBarcode;
                    }

                    $scope.patternBarcodeInput = flag ? /^(?!22)[0-9]+$/ : /^.*$/ ;

                    $scope.checkBarcode($scope.ruleItem.couponRule.barcode);

                };

                $scope.barcodeValidator = () => $scope.isBarcodeNotInvalid === false;
                $scope.isBarcodeGenerated = (barcode) => typeof $scope.ruleItem.couponRule.id !== "undefined" && ( barcode && barcode.indexOf('22')=== 0 );
                $scope.initBarcodeFlag = () => {
                    // Новое правило
                    if ( typeof $scope.ruleItem.couponRule.id === "undefined" ) {
                        return false;
                    }

                    // Автосгенерировано
                    if ( $scope.isBarcodeGenerated( $scope.ruleItem.couponRule.barcode ) ) {
                        return false;
                    }

                    return true;

                };

                $scope.invalidPrefix = 0;
                $scope.ruleWithSomePrefix = false;
                $scope.$watch('ruleItem.couponRule.prefix', function( newVal) {

                    if (!newVal || newVal === '2' || newVal === '000000' ){
                        $scope.invalidPrefix = 0;
                        $scope.ruleWithSomePrefix = false;
                        return;
                    }

                    $scope.invalidPrefix = 1;
                    $scope.ruleWithSomePrefix = false;

                    $scope.checkCurrentPrefix().then(function( result ) {

                        if ( result === false ) {
                            $scope.invalidPrefix = 0;
                        } else {
                            $scope.invalidPrefix = 2;
                            $scope.ruleWithSomePrefix = result;
                        }

                    });


                });

                $scope.maxRewardLimit = {
                    type: '',
                    measure: '',
                    enabled: false
                };

                if ($scope.getValue('ruleItem.couponRule.applySettings.maxRewardLimit')) {

                    angular.forEach($scope.values['couponRule.applySettings.maxRewardLimit'], function (value, type) {

                        angular.forEach(value.measures, function (value, measure) {

                            if (value.value === $scope.getValue('ruleItem.couponRule.applySettings.maxRewardLimit')) {
                                $scope.maxRewardLimit.type = type;
                                $scope.maxRewardLimit.measure = measure;
                                $scope.maxRewardLimit.enabled = true;
                            }

                        });

                    });
                } else {
                    $scope.maxRewardLimit.enabled = false;
                    $scope.maxRewardLimit.type = 'coupon';
                }

                $scope.$watch('maxRewardLimit', function (newValue) {

                    if (angular.isUndefined(newValue)) {
                        return;
                    }

                    var values = $scope.values['couponRule.applySettings.maxRewardLimit'];

                    if (
                        angular.isObject(values[newValue.type]) &&
                        angular.isObject(values[newValue.type].measures)
                    ) {

                        if (Object.keys(values[newValue.type].measures).indexOf(newValue.measure) < 0) {
                            newValue.measure = Object.keys(values[newValue.type].measures)[0];
                        }

                    }

                    if (newValue.type === 'none' || newValue.enabled === false) {
                        $scope.unsetValue('ruleItem.couponRule.applySettings.maxRewardLimit');
                        $scope.unsetValue('ruleItem.couponRule.applySettings.maxRewardValue');
                        return;
                    }

                    if (
                        angular.isObject(values[newValue.type]) &&
                        angular.isObject(values[newValue.type].measures[newValue.measure])
                    ) {

                        $scope.ruleItem.couponRule.applySettings = ruleItem.couponRule.applySettings || {};
                        $scope.ruleItem.couponRule.applySettings.maxRewardLimit = values[newValue.type].measures[newValue.measure].value;

                    } else {
                        $scope.unsetValue('ruleItem.couponRule.applySettings.maxRewardLimit');
                        $scope.unsetValue('ruleItem.couponRule.applySettings.maxRewardValue');
                    }

                }, true);

                $scope.$watchGroup(
                    [
                        'isOpenType',
                        'ruleItem.couponRule.issueSettings.issuingType', // Тип выдачи купонов
                        'ruleItem.couponRule.issueSettings.couponKind'   // Тип купона
                    ], function (newValue) {

                        if ( !$scope.showMaxRewardValues() ) {
                            $scope.maxRewardLimit.enabled = false;
                        }

                });



                $scope.timeWords = function( type, value ) {

                    var types = {

                        'day'   : [ "день"  , "дня"   , "дней" ],
                        'week'  : [ "неделя", "недели" , "недель" ],
                        'month' : [ "месяц" , "месяца" , "месяцев" ],
                        'year'  : [ "год"   , "года"   , "лет" ]

                    };

                    if (!value && value !== 0 ) {
                        return types[type][0];
                    }

                    switch (true) {

                        case ["11","12","13","14","15","16","17","18","19"].indexOf( value.toString().slice(-2) ) >= 0: return types[type][2];

                        case ["0","5","6","7","8","9"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][2];

                        case ["1"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][0];

                        case ["2", "3", "4"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][1];

                    }

                };

                $scope.maxDate = function(date1, date2) {

                    switch (true) {
                        case ( !( date1 instanceof Date ) && !( date2 instanceof Date ) ): return 0;
                        case ( !( date1 instanceof Date ) &&  ( date2 instanceof Date ) ): return date2;
                        case (  ( date1 instanceof Date ) && !( date2 instanceof Date ) ): return date1;

                    }

                    return ( date1.getTime() > date2.getTime() ) ? date1 : date2;

                };

                (function() {
                    var discountPercent = /^(100|\d{1,2}(\.\d{1,2})?)$/i;
                    var percent   = /^\d{1,5}$/i;
                    var currency  = /^\d{1,9}(\.\d{1,2})?$/i;
                    var bonusPercent = /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/i;
                    var bonusCur  = /^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$/i;
                    var other     = /^\d{1,6}$/i;

                    $scope.numberPercentPattern = function ( type, what ) {

                        if ( what === 'discount' && type === 'percent') {
                            return discountPercent;
                        }

                        if ( what === 'bonus') {
                            return type === 'percent' ?  bonusPercent : bonusCur;
                        }

                        return type === 'percent' ?  percent :
                                type === 'money' ? currency :
                                  other;
                    };
                })();

                (function(){

                    var indexes = {};
                    var weightArray = [];

                    $scope.liIndex = function( key , weight) {

                        if ( angular.isUndefined( indexes[key] ) ) {

                            let weightItem = {
                                index: key,
                                weight: weight
                            };

                            weightArray.push( weightItem );
                            weightArray = weightArray.sort( (item1, item2) => item1.weight - item2.weight );
                            indexes[key] = weightItem;
                        }

                        return weightArray.indexOf(indexes[key]) + 1;
                    };

                })();

                (function(){

                    var tmpCouponRule : any = {};

                    var intervalId = $interval(function() {

                        if ( !$scope.curState.activeForm && $scope.getValue( 'ruleItem.couponRule.id') ) {

                            if ( angular.isFunction( tmpCouponRule.$cancelRequest ) ) {
                                tmpCouponRule.$cancelRequest();
                            }

                            tmpCouponRule = CouponRule.get( {id: $scope.getValue( 'ruleItem.couponRule.id')} );

                            tmpCouponRule.$promise.then( function( value ) {
                                $scope.ruleItem.couponRule.state = value.couponRule.state;

                                if ( value.couponRule.state === "archive" ) {
                                    $interval.cancel( intervalId );
                                }

                            });

                        }

                    },5000);

                    $scope.$on('$destroy', function() {
                        $interval.cancel( intervalId );
                    });

                })();

                $scope.filterGoodByParams = {};
                $scope.$watchGroup( [ 'ruleItem.couponRule.reward.onGood.dimension', 'ruleItem.couponRule.reward.onGood.rewType'], function( newVal, oldval ) {

                    if ( newVal[1] === "all" ) {
                        $scope.filterGoodByParams = {};
                        return;
                    }

                    if (!newVal[0]) {
                        $scope.filterGoodByParams = {};
                    } else {
                        $scope.filterGoodByParams['filter.dimensions'] = newVal[0];
                    }

                    if ( (  newVal[0] && oldval[0] ) && ( newVal[0] !== oldval[0] ) )  {
                        $scope.removeWhiteGoods();
                    }

                });

                $scope.setFormState( angular.isUndefined( $scope.getValue( 'ruleItem.couponRule.id') )  ? 'edit'  : 'view'  );

                let filtredRewardWhat = false;
                $scope.rewardWhatFilter = function( arr ) {

                    if (
                        !loyaPermissions.byPath('campaign.mechanics.bonus').enabled  &&
                        arr.some(i=> i==='bonus')
                    ){
                        arr.splice(arr.indexOf('bonus'), 1);
                    }

                    return arr;

                };

                $scope.firstLetterCapitalize=function( str ) {
                    return (!!str) ? str.charAt(0).toUpperCase() + str.substr(1) : '';
                };


                $scope.hasRuleImportedCoupons = false;

                $scope.checkImportedCoupons = () =>  Coupon.query( {
                    'pager.drop'  : 0,
                    'pager.limit' : 1,
                    'filter.couponRuleId' : $scope.ruleItem.couponRule.id,
                    'filter.states' : Object.keys( (new Coupon())?.getField('state','values') || {})?.join(',')
                }, res => $scope.hasRuleImportedCoupons = res && !!res.length);

                $scope.$watch('currentCouponKind.hasImportCoupons', function( newVal ) {

                    if (newVal) {
                        $scope.checkImportedCoupons();
                        $scope.unsetValue('ruleItem.couponRule.prefix');
                    }

                });

                $scope.isValidRule = function() {

                    if ($scope.currentCouponKind.hasImportCoupons && !$scope.hasRuleImportedCoupons) {
                        return false;
                    }

                    return true;
                };

                //---------------- TODO Вынести в компонент



                $scope.fileImportResult = false;
                $scope.closeFileImportResult = function() { $scope.fileImportResult = false; };

                $scope.uploader = new FileUploader({
                    url: '/files',
                    autoUpload: true
                });

                $scope.loadScreen = function( id ) {
                    let screens = {
                        LOAD     : 'importFile/load.html',
                        PROGRESS : 'importFile/progress.html',
                        CONFIG   : 'importFile/config.html',
                        IMPORT   : 'importFile/import.html',
                    };

                    $scope.currentScreen = screens[id];
                };

                $scope.uploader.onAfterAddingFile = function () {

                    $scope.uploader.uploadAll();
                    $scope.loadScreen('PROGRESS');

                    let modalInstance = $uibModal.open({

                        controller: importController,
                        size: 'auto',
                        scope: $scope,
                        windowClass: 'modal-dialog-height modal-dialog-w1010',
                        templateUrl: importView,

                        backdrop: 'static',
                        keyboard: false


                    });

                    modalInstance.result.then( function( result ) {

                        $scope.fileImportResult = result;
                        $scope.checkImportedCoupons();

                    }, function( result ) {

                        $scope.checkImportedCoupons();
                        if ( angular.isObject(result) && result.error ) {
                            toaster.error( 'Ошибка импорта', result.error );
                        }
                    })

                };

                $scope.uploader.onSuccessItem = function (item, response, status, headers) {

                    $scope.$broadcast('onSuccessItem',response);

                };

                $scope.uploader.onProgressItem = function(item, progress) {
                    $scope.loadScreen('PROGRESS');
                    $scope.progressPercent = progress;
                };

                $scope.selectFile = function() {

                    angular.element('#excelUploader').val('');
                    angular.element('#excelUploader').click();

                };


                /*********************************************/

                $scope.rewTypeNames = {
                    "every" : 'на каждые',
                    "count" : 'на количество',
                    "all" : 'на все'
                };


                $scope.issuingTypes = ISSUING_TYPES;

                $scope.currentIssueType  = null;
                $scope.currentCouponKind = null;

                $scope.$watch('ruleItem.couponRule.issueSettings.issuingType',  newId => {

                    if ( !$scope.issuingTypes[newId ] )
                        return;

                    $scope.currentIssueType = new $scope.issuingTypes[newId ]( $scope.ruleItem, !$scope.curState.activeForm );
                    if ($scope.ruleItem.couponRule && $scope.ruleItem.couponRule.issueSettings && $scope.ruleItem.couponRule.issueSettings.couponKind )
                      $scope.currentIssueType.changeCouponKindById($scope.ruleItem.couponRule.issueSettings.couponKind);

                    $scope.currentCouponKind = $scope.currentIssueType.getCurrentCouponKind();

                });

                $scope.$watch('ruleItem.couponRule.issueSettings.couponKind',  newId => {
                    $scope.currentIssueType.changeCouponKindById(newId);
                    $scope.currentCouponKind = $scope.currentIssueType.getCurrentCouponKind();
                });

                $scope.$watch('currentCouponKind.getRewardMeasureList()',  newArray => {

                    try {
                        if ( newArray.indexOf( $scope.ruleItem.couponRule.reward.reward.measure ) <0 ) {
                            $scope.ruleItem.couponRule.reward.reward.measure = newArray[0];
                        }
                    } catch (e) {}

                }, true);

                $scope.$watch('curState.activeForm', newVal => {
                    $scope.currentIssueType.changeViewState( !newVal );
                });

                $scope.$watch('currentCouponKind.getRewardWhat()', newVal => {
                    try {
                        if ( newVal.indexOf( $scope.ruleItem.couponRule.reward.reward.what ) <0 ) {
                            $scope.ruleItem.couponRule.reward.reward.what = newVal[0];
                        }
                    } catch (e) {}

                } , true);

                $scope.$watch('currentIssueType.getStartPeriod()', newVal => {

                    if ( !newVal || (Array.isArray(newVal) && !newVal.length) ) {
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.startDate');
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.startScheduled');
                        if (!$scope.getValue('ruleItem.couponRule.issueSettings.customPeriod')) {
                          $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                          $scope.ruleItem.couponRule.issueSettings = $scope.ruleItem.couponRule.issueSettings || {}
                          $scope.ruleItem.couponRule.issueSettings.startPeriod = 'fromDate';
                        }
                    }

                } , true);

                $scope.$watch('currentIssueType.getStartPeriod()', newVal => {

                    if ( !newVal || (Array.isArray(newVal) && !newVal.length) ) {
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.stopDate');
                        $scope.unsetValue('ruleItem.couponRule.issueSettings.stopScheduled');

                        if (!$scope.getValue('ruleItem.couponRule.issueSettings.customPeriod')) {
                          $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                          $scope.ruleItem.couponRule.issueSettings = $scope.ruleItem.couponRule.issueSettings || {}
                          $scope.ruleItem.couponRule.issueSettings.stopPeriod = 'fromDate';
                        }

                    }

                } , true);

                $scope.$watch('currentIssueType.getCustomPeriodTypes()', newVal => {

                    if ( !newVal || (Array.isArray(newVal) && !newVal.length) ) {
                        $scope.ruleItem.couponRule.issueSettings.customPeriod = false;
                    }

                } , true);

                // Хотелка Ларисы и Глеба
                $scope.$watchGroup(
                    [
                        'ruleItem.couponRule.reward.reward.what',
                        'ruleItem.couponRule.reward.reward.rewType',
                        'ruleItem.couponRule.reward.onGood.rewType',
                    ], ( newVal, oldVal ) => {

                    if ( newVal[0] === RewardWhatTypes.FIXPRICE && !!$scope.rewTypeValues.goods && $scope.currentCouponKind.getOnGoodRewType().indexOf(OnGoodRewType.COUNT) >=0 ) {
                        $scope.ruleItem.couponRule.reward.reward.rewType = 'goods';
                        $scope.ruleItem.couponRule.reward.onGood = $scope.ruleItem.couponRule.reward.onGood || {};
                        $scope.ruleItem.couponRule.reward.onGood.rewType = OnGoodRewType.COUNT;
                    }

                });

                $scope.$watch('ruleItem.couponRule.issueSettings.customPeriod', newVal => {

                    if ( !newVal ) {
                        if ($scope.ruleItem &&
                            $scope.ruleItem.couponRule &&
                            $scope.ruleItem.couponRule.issueSettings) {
                            $scope.ruleItem.couponRule.issueSettings.customPeriod = false;
                            delete $scope.ruleItem.couponRule.issueSettings.customPeriodType;
                            delete $scope.ruleItem.couponRule.issueSettings.customValue1;
                            delete $scope.ruleItem.couponRule.issueSettings.customValue2;
                        }

                        if ( !$scope.ruleItem.couponRule.issueSettings.startPeriod && !!$scope.currentIssueType.getStartPeriod().length ) {
                            $scope.ruleItem.couponRule.issueSettings.startPeriod = $scope.currentIssueType.getStartPeriod()[0]
                        }

                        if ( !$scope.ruleItem.couponRule.issueSettings.stopPeriod && !!$scope.currentIssueType.getStopPeriod().length ) {
                            $scope.ruleItem.couponRule.issueSettings.stopPeriod = $scope.currentIssueType.getStopPeriod()[0]
                        }

                    } else {
                        delete $scope.ruleItem.couponRule.issueSettings.startPeriod;
                        delete $scope.ruleItem.couponRule.issueSettings.stopPeriod;
                    }

                });

                $scope.$watch('currentCouponKind.hasRewardWhat', newVal => {

                    if ( !newVal ) {
                        $scope.ruleItem = $scope.ruleItem || {};
                        $scope.ruleItem.couponRule = $scope.ruleItem.couponRule || {};
                        $scope.ruleItem.couponRule.reward = $scope.ruleItem.couponRule.reward || {};
                        $scope.ruleItem.couponRule.reward.reward = $scope.ruleItem.couponRule.reward.reward || {};
                        $scope.ruleItem.couponRule.reward.reward.what = $scope.rewardWhatFilter( $scope.currentCouponKind.getRewardWhat() )[0];
                        $scope.ruleItem.couponRule.reward.reward.value = 0;
                    }

                });


                $scope.getCampaignCategoryById = function(id) {
                    if ( !Array.isArray($scope.CampaignCategoryList) || typeof id === "undefined" ) {
                      return undefined;
                    }

                  return $scope.CampaignCategoryList.find(i => i.id === id);
                }


                $scope.maxRewardLimitFilter = (items) => {

                  if ( !loyaPermissions.byPath('campaign.mechanics.bonus').enabled ) {
                    items = Object.assign({}, items);
                    delete items.bonus;
                  }

                  if ($scope.ruleItem.couponRule.issueSettings.issuingType === 'public' &&
                      ($scope.ruleItem.couponRule.issueSettings.couponKind === 'couponRuleBarcode'|| $scope.ruleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode')) {
                    items = Object.assign({}, items);
                    Object.keys(items)
                          .filter(k => k !== 'coupon')
                          .forEach(k => delete items[k])

                  }

                  return items;
                }

                $scope.showMaxRewardValues = function() {

                  if ($scope.ruleItem.couponRule.issueSettings.issuingType === 'public' &&
                      ($scope.ruleItem.couponRule.issueSettings.couponKind === 'couponRuleBarcode' || $scope.ruleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode'))
                    return true

                  return !$scope.isOpenType &&
                         !( $scope.ruleItem.couponRule.issueSettings.issuingType === 'pos' && $scope.ruleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode' )

                }

                $scope.showApplyEvery = function() {
                  if  ( [ 'eCoupon', 'couponWithoutBarcode','referralCouponWithBarcode', 'importedCouponForPrint'].indexOf( $scope.ruleItem?.couponRule?.issueSettings?.couponKind ) >= 0 )
                    return true;

                  if (
                    $scope.ruleItem?.couponRule?.issueSettings?.couponKind ==='couponWithBarcode' &&
                    ['pos', 'uniqueCouponGen'].indexOf($scope.ruleItem.couponRule.issueSettings.issuingType) >= 0
                  )
                    return true;

                  return false;

                }

                $scope.$watch( () => $scope.showApplyEvery, val => {
                  if (!val && !!$scope?.ruleItem?.couponRule?.applySettings?.applyEvery ) {
                    delete $scope.ruleItem.couponRule.applySettings.applyEvery;
                  }
                } );


              /*********************************************/


                //----------- IMAGES ------------


                let imageHandler = new ImageUploaderHandler();
                $scope.campaignImages = [];
                $scope.addImageResource = function( item ) {

                    imageHandler.add(item);

                    if ( $scope.ruleItem.couponRule && typeof $scope.ruleItem.couponRule.id !== "undefined" ) {
                        return $scope.commitImages();
                    }

                };

                $scope.deleteImageResource = function( item ) {

                    imageHandler.delete(item);

                    if ( $scope.ruleItem.couponRule && typeof $scope.ruleItem.couponRule.id !== "undefined" ) {
                        return $scope.commitImages();
                    }
                };

                $scope.changeImageResource = function( before, after ) {

                    imageHandler.change(before, after);

                    if ( $scope.ruleItem.couponRule && typeof $scope.ruleItem.couponRule.id !== "undefined" ) {
                        return $scope.commitImages();
                    }
                };

                $scope.commitImages = function() {

                    let promises = [];

                    promises.concat(

                        imageHandler
                            .getItemsForRemove()
                            .map( item => CouponRuleModel.deleteImage({
                                id      : $scope.ruleItem.couponRule.id,
                                imageId : item.imageId
                            })),

                        imageHandler
                            .getItemsForSave()
                            .map( item => CouponRuleModel.saveImage({
                                    id      : $scope.ruleItem.couponRule.id,
                                    imageId : item.imageId
                                }, item.imageType
                            )),

                    );

                    imageHandler.clear();
                    return $q.all(promises);

                };

                $scope.updateImages = function() {

                    if (!$scope.ruleItem.couponRule || typeof $scope.ruleItem.couponRule.id === "undefined") {
                        $scope.campaignImages = [];
                        return;
                    }

                    CouponRuleModel.queryImages(
                        {
                            id: $scope.ruleItem.couponRule.id
                        },
                        (res) => $scope.campaignImages = res
                    );
                };

                $scope.$watch('ruleItem.couponRule.id', $scope.updateImages());

                $scope.isExternalLoyalty = () => {
                  return $scope.settingsList.externalLoyalty === 'true';
                }

            } ];



import {CashBoxEvents, CashBoxService} from "../service/cash-box.service";

const fixNumber = num => Number(num.toFixed(2));

export class CheckDiscountClass {

  private enabled: boolean = false;

  constructor(
    private cashBoxService: CashBoxService
  ) {
    this.subscribeOnEvents();
  }

  private subscribeOnEvents() {
    this.cashBoxService?.events?.subscribe(event => {

      if ([
          CashBoxEvents.RECALCULATE,
          CashBoxEvents.CREATE,
          CashBoxEvents.CHANGE_ITEMS,
          CashBoxEvents.PRECHECK_END,
        ].indexOf(event.type) >=0) {
        this.recalcDiscount();
      }

    });
  }

  private recalcDiscount() {
    if (this.enabled) {
      this.useDiscount(true);
    }
  }

  totalDiscount()  {
    return this.cashBoxService.items?.reduce((acc, item) => acc + this.discountForItem(item), 0);
  }

  totalUsedDiscount()  {
    return this.cashBoxService.items?.reduce((acc, item) => acc + this.usedDiscountForItem(item), 0);
  }

  totalDiscountOnChange() {
    return this.cashBoxService.items?.reduce((acc, item) => acc + this.discountOnChangeForItem(item), 0);
  }

  useDiscount(use = true) {
    this.enabled = use;
    this.cashBoxService.items?.forEach(item => this.useDiscountForItem(item, use));
  }

  useDiscountForItem(item, use = true) {

    if (use && (this.discountForItem(item) > 0 )) {
        item.usedAwards = item.usedAwards || {};
        item.usedAwards.usedDiscount = ( this.discountForItem(item) || 0 )?.toString();
    } else {
      if (!!item?.usedAwards?.usedDiscount)
        delete item.usedAwards.usedDiscount;
    }

  }

  discountForItem(item) {
    return fixNumber( !!item?.awards?.discount ? parseFloat(item?.awards?.discount) || 0 : 0 );
  }

  discountOnChangeForItem(item) {
    return  fixNumber(  !!item?.awards?.discountOnChange ? parseFloat(item?.awards?.discountOnChange) || 0 : 0 );
  }

  usedDiscountForItem(item) {
    return fixNumber(  !!item?.usedAwards?.usedDiscount ? parseFloat(item?.usedAwards?.usedDiscount) || 0 : 0 );
  }

  calcTotalForItem(item, total) {
    if ( !this.usedDiscountForItem(item) )
      return total;

    return total - this.usedDiscountForItem(item);
  }
}

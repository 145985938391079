

<div *ngIf="isEdit" class="hbox hbox-auto w-full">

    <div class="col w-full">
        <tag-input
            [(ngModel)]="model"
            (ngModelChange)="modelChange.emit($event)"
            [placeholder]="placeholder"
            [modelAsStrings] = "true"
            [minlength]="minLength"
            [maxlength]="maxLength"
            [maxItems]="maxTags"
            [theme]="'bootstrap'"
            replace-spaces-with-dashes = "false"
            add-on-Comma               = "false"
            enable-editing-last-tag    = "true"
            [onlyFromAutocomplete]="false"
        >
            <tag-input-dropdown [showDropdownIfEmpty]="true"
                                [autocompleteObservable]="searchTags"
                                [minimumTextLength]="1"


                                source     = "searchTags($query)"
                                min-length = "1"
                                load-on-down-arrow = "true"
                                select-first-match = "false"

            >
            </tag-input-dropdown>

        </tag-input>
    </div>

    <div class="col text-ellipsis v-middle"
         *ngIf="isEditFromView">

        <button
            class      = "btn btn-success btn-sm m-l-sm inline"
            (click)    = "saveChanges()"
            [disabled] = "isInvalid"
        >
            <i class="fa fa-check"></i>
        </button>

        <button
            class="btn btn-danger btn-sm m-l-xs inline"
            (click)="cancelChanges()"
        >
            <i class="fa fa-times"></i>
        </button>

    </div>

</div>

<div *ngIf = "!isEdit && hasValues"
     class ="p-t-xs"
>
    <div class="m-t-n-sm clearfix"
         (mouseenter) = "showEdit = true"
         (mouseleave) = "showEdit = false"
    >

        <span *ngIf="!highlight">
            <span [ngClass]="tagCss"
                  class="pointer"
                  *ngFor="let tag of model"
                  [innerText]="tag"
                  (click)="searchByTag(tag)"
            >
            </span>
        </span>

        <span *ngIf="highlight">

            <span [ngClass]="tagCss"
                  class="pointer"
                  (click)="searchByTag(tag)"
                  *ngFor="let tag of model"
            >
                <search-highlight
                        [model]="highlight"
                        [text]="tag"
                ></search-highlight>
            </span>

        </span>


        <div class="inline  "
             *ngIf="showEdit && isCanEdit"
        >
            <button class="btn btn-success btn-xs  edit-tags-button"
                    (click)="switchToEdit()"
            >
                <i class="fa fa-pencil"></i>
            </button>

        </div>

    </div>


</div>


<div *ngIf = "!isEdit && !hasValues"
     class ="p-t-xs"
>
    <button class="btn btn-success btn-xs"
            (click)="switchToEdit()"
    >
        <i class="fa fa-plus f-s-8 m-r-xs "></i>
        <span>Добавить теги</span>
    </button>
</div>

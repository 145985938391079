<div class="fixed-tool-panel">

  <filter-checkbox

    class="m-t-xs m-r-sm"
    data-testid="filter.states"
    [name]         = "'Статусы'"
    [showClearButton] = "false"
    [canSelectAll]="true"
    [inputValues]  = "COUPON_RULE_STATE_NAMES"
    [selectedValues] = "couponRuleService.queryParams.getFilterValue('states')"
    (selectedValuesChange) = "couponRuleService.queryParams.setFilterValue(
                    'states',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <filter-checkbox

    class="m-t-xs m-r-sm"
    data-testid="filter.couponKind"
    [name]         = "'Тип купона'"
    [showClearButton] = "false"
    [canSelectAll]="true"
    [inputValues]  = "COUPON_KIND_TYPES_NAMES"
    [selectedValues] = "couponRuleService.queryParams.getFilterValue('couponKind')"
    (selectedValuesChange) = "couponRuleService.queryParams.setFilterValue(
                    'couponKind',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <filter-checkbox

    class="m-t-xs m-r-sm"
    data-testid="filter.issuingType"
    [name]         = "'Тип выдачи'"
    [showClearButton] = "false"
    [canSelectAll]="true"
    [inputValues]  = "COUPON_ISSUING_TYPES_NAMES"
    [selectedValues] = "couponRuleService.queryParams.getFilterValue('issuingType')"
    (selectedValuesChange) = "couponRuleService.queryParams.setFilterValue(
                    'issuingType',
                    $event
                );
            reset();"
  ></filter-checkbox>

  <button class="btn btn-success btn-sm btn-addon pull-right"
          [uiSref]="'^.view'"
          data-testid="cRule.add()"
  >
    <i class="fa fa-plus"></i>
    <span class="btn-name  ">Добавить правило</span>
  </button>


</div>

  <div class="wrapper stick-search-box">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Наименование купонного правила"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>
           Наименование купонного правила
         </th>
         <th>
           Статус купонного правила
         </th>
         <th >
           Тип выдачи
         </th>
         <th>
           Тип купона
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let rule of listCouponRules">
          <tr
              [uiSref]="'^.view({id:'+rule.id+'})'"
              class="pointer"
              data-testid="cRule.item in rulesList"
          >
            <td class="table-big-link">

              <a href="javasript:void(0)"
                 [uiSref]="'^.view({id:' + rule.id + '})'"
                 data-testid="cRule.name">

                <text-highlight
                  [search]="searchString"
                  [text]="rule.name"
                ></text-highlight>

              </a>

              <div class="m-b-sm text-muted f-s-12px clearfix" data-testid="cRule.description">
                {{ rule.description || '' }}
              </div>

            </td>
            <td  data-testid="cRule.state">
              {{ COUPON_RULE_STATE_NAMES[rule.state] }}
            </td>

            <td data-testid="cRule.issuingType">
              {{ COUPON_ISSUING_TYPES_NAMES[rule.issuingType] || rule.issuingType }}
            </td>

            <td  data-testid="cRule.couponType">
              {{ COUPON_KIND_TYPES_NAMES[rule.couponKind] || rule.couponKind  }}
            </td>

          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

<div class="text-center text-warning m-t-lg"
     *ngIf="loader.isLoading('list')"
>
  <div class="">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>

<scroll-up></scroll-up>

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {GoodsSelectorService} from "../../../../component/goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";
import {CashBoxService} from "../../service/cash-box.service";

const KIND_ID = "inputWithPosition";

@Component({
  selector: 'cash-box-input-with-position',
  templateUrl: "./cash-box-input-with-position.component.html",
  styles:[
    ':host { display:block}'
  ],
  providers: [
  ]
})
export class CashboxInputWithPosition implements OnInit, OnChanges, OnDestroy {

    @Input() public awards   : any ;
    @Input() public actions  : any ;
    @Input() public id       : any ;

    public inputCode : string;
    public enableInput : boolean = false;

    constructor(
      public  cashBoxService : CashBoxService,
    ) { }

    public ngOnInit() {

        if (this.hasAward()) {
            this.inputCode = this.getAward().value;
        }

        if ( this.getError() && this.inputCode ) {
            this.enableInput = true;
        }

    }

    public ngOnChanges( obj: SimpleChanges ) {
        if ( obj['id'] && !obj['id'].isFirstChange() ) {

            this.changeAward({
                positionId: obj.id.currentValue
            }, obj.id.previousValue);

        }
    }

    get actionItem() {
      return this.actions?.find( i => i.kind === KIND_ID ) || undefined;
    }

    get isEnabled() {
        return !!this.actionItem;
    }
/*

    public getField = () => this.awards && this.awards[KIND_ID] && this.awards[KIND_ID][0];
*/

    public addToAwards() {

          if ( !this.actionItem ) return;

          this.actionItem.values = this.actionItem.values || [];
          let findedItem = this.actionItem.values?.find( (i:any) => i.positionId === this.id);

          if (findedItem) {
            Object.assign(findedItem,{ value : this.inputCode, error: undefined, success: undefined } );
          } else {
            this.actionItem.values.push({
                           id: this.actionItem.values.length,
                           positionId: this.id,
                           value: this.inputCode
                       });
          }

          this.cashBoxService?.reCalculate();

      }

    public removeAward() {

        if ( !this.hasAward() || !this.actionItem?.values ) return;

        this.actionItem.values = this?.actionItem?.values?.filter( (i:any) => i.positionId !== this.id);

        this.cashBoxService?.reCalculate();

      }

    public getAward() {
        if ( !this.actionItem ) return;

        if ( !this?.actionItem?.values )
            return false;

        return this?.actionItem?.values?.find( (i:any) => i.positionId === this.id);
    }

    public getError() {
        let award = this.getAward();

        if (award && award.error) {
            return  award.error;
        }
    }

    public getSuccess() {
        let award = this.getAward();

        if (award && award.success) {
            return  award.success;
        }
    }

    public hasAward() {
          return !!this.getAward();
      }

    public changeAward( newValue: Object, currentId = this.id ) {

        if ( !this.actionItem ) return;

        this.actionItem.values = this.actionItem.values || [];
        let findedItem = this.actionItem.values?.find( (i:any) => i.positionId === currentId);

        if (findedItem) {
          Object.assign(findedItem,newValue, {  error: undefined, success: undefined } );
        }

        this.cashBoxService?.reCalculate();

      }

    public cancelInput() {
        this.enableInput = false;
        this.inputCode   = '';

        if ( !!this.getAward() ) {
            delete this.getAward().error;
        }
    }

    ngOnDestroy() {
    }

}

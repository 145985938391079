import {Component, OnInit, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import {CashBoxService} from "../../service/cash-box.service";
import {GoodsSelectorService} from "../../../../component/goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";

@Component({
  selector: 'cash-box-operations',
  templateUrl: "./cash-box-operations.component.html",
  styleUrls: ['./cash-box-operations.component.scss'],
  providers: [
  ]
})
 export class CashBoxOperationsComponent
  implements OnInit, OnChanges, OnDestroy {

    public cache = {
      informingByPos: [],
      informingByCheck: [],
      empty: []
    }

    public tmpInput = {}

    constructor(
        public  cashBoxService : CashBoxService,
    )
    {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngOnDestroy() {

    }

    get informationByPos() {

      if ( !this.cashBoxService.currentCheck?.informing('pos')?.length) {
        this.cache.informingByPos = [];
      } else if ( JSON.stringify(this.cache.informingByPos) !== JSON.stringify(this.cashBoxService.currentCheck?.informing('pos') ) ) {
        this.cache.informingByPos = [...this.cashBoxService.currentCheck?.informing('pos')];
      }

      return this.cache.informingByPos;

    }

    get informationByCheck() {

      if ( !this.cashBoxService.currentCheck?.informing('check')?.length) {
        this.cache.informingByCheck= [];
      } else if ( JSON.stringify(this.cache.informingByCheck) !== JSON.stringify(this.cashBoxService.currentCheck?.informing('check') ) ) {
        this.cache.informingByCheck = [...this.cashBoxService.currentCheck?.informing('check')];
      }

      return this.cache.informingByCheck;

    }


  public textToHtml( text = '' ) {
      return text.replace(/\n/ig,'<br/>');
    }

    get getUsableCoupons() {

      return this.cashBoxService
                 ?.currentCheck
                 ?.coupons
                 ?.filter((element) =>  element.ctype === "usable" ) || this.cache.empty;

    };

    get hasActions() {
      return !!this.cashBoxService?.currentCheck?.actions?.length;
    }

    get canUseBonuses() {
      return !!this.cashBoxService?.currentCheck?.client;
    }

    changeUseBonuses(flag) {
      this.cashBoxService?.bonus?.useBonus(!!flag);
      this.cashBoxService?.reCalculate();
    }


    isCouponChecked(coupon) {
      return !!this.cashBoxService.currentCheck?.usedСoupons?.some(c => c.id === coupon.id);
    }

    changeCouponChecked(coupon) {

      if (coupon) {
        return;
      }

      let usedCoupons = this.cashBoxService.currentCheck?.usedСoupons || [];
      if (!this.isCouponChecked(coupon)) {
        usedCoupons.push(coupon.id);
      } else {
        usedCoupons = usedCoupons.filter(c => c.id !== coupon.id);
      }

      this.cashBoxService.currentCheck.usedСoupons = usedCoupons;
      this.cashBoxService.reCalculate();

    }

    isBlockedActionKind(type) {
      return ['inputWithPosition'].indexOf( type )>=0;
    }

    addInputBarcode(action, value) {

      if (!action || !value) {
        return;
      }

      action.values =  action.values || [];
      action.values.push({
        id: action?.values?.reduce( (acc,item) => item.id + 1 > acc ? item.id +1 : acc , 0) || 1,
        value
      });

      this.cashBoxService.reCalculate();
    }

    removeValueBarcode(action, value) {
      if (!action || !value) {
        return;
      }

      action.values =  action.values || [];
      action.values = action.values.filter(v => v.id !== value.id);

      this.cashBoxService.reCalculate();
    }

    couponName(actionCoupon) {

      if (!actionCoupon) {
        return 'Неизвестный купон';
      }

      if ( actionCoupon && !actionCoupon.details ) {
        return actionCoupon.value ? `Купон № ${actionCoupon.value}` : 'Неизвестный купон';
      }

      return  actionCoupon.details.name || actionCoupon.description;
    }

    getGiftName(gift){
      return gift?.sku || '';
    }


}

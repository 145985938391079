<div class="fixed-tool-panel">

  <filter-checkbox

    class="m-t-xs states-filter m-r-sm"
    data-testid="communication.filter.state"

    [name]         = "'Статус'"
    [canSelectAll] = "true"
    [showClearButton] = "false"
    [inputValues]  = "COMMUNICATION_CAMPAIGN_STATE_NAMES_FILTER"
    [selectedValues] = "communicationCampaignService.queryParams.getFilterValue('states')"
    (selectedValuesChange) = "communicationCampaignService.queryParams.setFilterValue(
                    'states',
                    $event
                  );
                  reset();"
  ></filter-checkbox>
<!--

  <filter-communication-period
    class="m-t-xs m-r-sm"
    (onChange)="onFilterDate($event)"
    [modelFrom]="communicationCampaignService.queryParams.getFilterValue('fromDate')"
    [modelTo]="communicationCampaignService.queryParams.getFilterValue('tillDate')"
    [datesKind]="communicationCampaignService.queryParams.getFilterValue('datesKind')"
    [maxPeriodMonth]="360"
    [requiredFrom]="false"
    [requiredTo]="false"
  ></filter-communication-period>

  <filter-goods
    class="m-r-sm"
    (modelChange)="onFilterGoods($event)"
    [showCategories]="['sku', 'category', 'custom']"
    [max]="10"

  ></filter-goods>
-->

  <filter-locations
    (modelChange)="onFilterLocations($event)"
    class="m-r-sm"
  ></filter-locations>


  <button class="btn btn-success btn-sm btn-addon pull-right collapse-sm collapse-xs"
          [uiSref]="'^.view'"
          data-testid="communication.add()"
  >
    <i class="fa fa-plus"></i>
    <span class="btn-name  ">Новая коммуникация</span>
  </button>

</div>

<div class="wrapper">
  <search-input
    [model]="searchString"
    (modelChange)="onSearchChange($event)"
    placeholder="Наименование коммуникации "
  ></search-input>
</div>

<div infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
>
  <responsive-table>
    <table class="table m-b-none"
           [class.communication_preview_table]="isPreview"
    >
      <thead>
        <tr>
          <th  width="1%">
            <span>Статус</span>
          </th>

          <th
            data-testid="communication.name.sort()"
            class="not-hide-communication"
          >
            <sort-view
              column="communicationName"
              [params]="communicationCampaignService.queryParams"
              (onChange)="reset()"
            >
              Наименование комуникации
            </sort-view>
          </th>

          <th data-testid="communication.startDate.sort()"
              width="10%">
            <sort-view
              column="communicationStartDate"
              [params]="communicationCampaignService.queryParams"
              (onChange)="reset()"
            >
              Дата начала
            </sort-view>
          </th>

          <th  data-testid="communication.stopDate.sort()"
              width="10%">
            <sort-view
              column="communicationStopDate"
              [params]="communicationCampaignService.queryParams"
              (onChange)="reset()"
            >
              Дата завершения
            </sort-view>
          </th>


          <th width="15%">
              <span>
                Место проведения
              </span>
          </th>

          <th width="10%">
            <span>
              Целевая аудитория
            </span>
          </th>
          <th  width="10%">
            <span>
              Сообщение
            </span>
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let communication of communicationsList "
            data-testid="communication in communicationsList"
            [class.selected]="isPreview && selectedCommunication === communication"
        >

          <td class="text-nowrap">


            <i class="fa fa-circle m-r-xs"
               [ngClass]=" 'text-' + COMMUNICATION_CAMPAIGN_STATES_COLORS[communication?.campaign?.state]"
            ></i>

            <span [innerText]="COMMUNICATION_CAMPAIGN_STATE_NAMES[communication?.campaign?.state] || communication?.campaign?.state"
                  data-testid="communication.state"></span>

            <span class="clear f-s-12px m-l-md"
                  [ngClass]="{ 'text-danger-dker': communication?.campaign?.completeness==='error','text-muted': communication?.campaign?.completeness!=='error'  }"
                  [innerHTML]="getCompletenessMessage(communication?.campaign?.state, communication?.campaign?.completeness)"></span>

          </td>


          <td class="text-wrap overflow-wrap-break table-big-link not-hide-communication"
              (click)="selectedCommunication = communication"
          >

            <ng-container
              *ngIf="!isPreview"
            >
              <a href
                 [uiSref]="'^.view({id:' + communication.campaign.id + '})'"
                 data-testid="communication.name">

                <text-highlight
                  [search]="searchString"
                  [text]="communication?.campaign?.name"
                ></text-highlight>

              </a>

              <div class=" text-muted f-s-12px "
                   data-testid="communication.description"
              >
                {{ communication?.campaign?.description }}
              </div>
            </ng-container>

            <ng-container
              *ngIf="isPreview"
            >
              <a href="javascript:void(0)"
                 data-testid="communication.name"
              >

                <text-highlight
                  [search]="searchString"
                  [text]="communication?.campaign?.name"
                ></text-highlight>

              </a>
            </ng-container>

          </td>

          <td data-testid="communication.startDate">
            {{communication.campaign.campaignTime.startDate | loyaDate }}
          </td>

          <td data-testid="communication.stopDate">
            {{communication.campaign.campaignTime.stopDate | loyaDate }}
          </td>

          <td class=" overflow-wrap-break" >

            <div *ngIf="communication?.locationGroup?.length>0"
                 class="clearfix  overflow-wrap-break"
                 style="max-width: 250px;">

              <span class="text-muted f-s-9">Для группы:</span>

              <div class="no-padder">
                <div *ngIf="!communication?.$showMoreLocationGroup">

                  <div class="m-l-xs" *ngFor="let t of communication.locationGroup | slice:0:3;" data-testid="communication.locationGroup">
                    {{t.name }}
                  </div>


                  <div *ngIf="communication?.locationGroup?.length>3" class="pos-rlt table-big-link">
                    <a class="f-s-9 m-l-xs text-info"
                       style="font-size:0.9em"
                       href="javascript:void(0)"
                       (click)="communication.$showMoreLocationGroup =!communication?.$showMoreLocationGroup" data-testid="communication.location.showMore">ещё
                      (+{{communication?.locationGroup.length-3}})</a>
                  </div>

                </div>


                <div *ngIf="communication?.$showMoreLocationGroup">
                  <div class="m-l-xs" *ngFor="let t of communication.locationGroup" data-testid="communication.locationGroup.more">
                    {{t.name }}
                  </div>

                  <div *ngIf="communication?.locationGroup?.length>3" class="pos-rlt table-big-link">
                    <a class="f-s-9 m-l-xs text-info"
                       style="font-size:0.9em"
                       href="javascript:void(0)"
                       (click)="communication.$showMoreLocationGroup =!communication?.$showMoreLocationGroup"
                       data-testid="communication.location.hide">скрыть</a>
                  </div>

                </div>


              </div>
            </div>

            <div *ngIf="communication?.location?.length>0"
                 class="clearfix  overflow-wrap-break"
                 style="max-width: 250px;">

              <span class="text-muted f-s-9">Для магазинов:</span>
              <div class="no-padder">
                <div>
                  <div class="m-l-xs" *ngFor="let t of communication.location| slice:0:3;" data-testid="communication.location">
                    {{t.name }}
                  </div>

                  <div *ngIf="communication?.location?.length>3"
                       class="pos-rlt table-big-link">


                    <ng-template #locationTemplate >

                      <div style="max-height:300px; min-width:200px;"
                           class="mini-scroll scroll-y  ">

                        <div class="text-word-break  " *ngFor="let t of communication?.location " data-testid="communication.popup.location">
                          {{t.name }}
                        </div>

                      </div>

                    </ng-template>


                    <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                       href="javascript:void(0)"

                       [ngbPopover]="locationTemplate"
                       [popoverTitle]="'Для магазинов'"
                       [placement]="'bottom'"
                       data-testid="communication.location.more"

                    >
                      + ещё {{communication.location.length-3}}
                    </a>
                  </div>

                </div>

              </div>
            </div>

            <span *ngIf="!communication?.locationGroup?.length && !communication?.location?.length"
                  data-testid="communication.locationGroup.and.location.not">
                      Не задано
                  </span>
          </td>

          <td class=" table-big-link " >

              <span *loyaPermitIf="{path:'segments', permitEnabled: ''}">

                  <span *ngIf="communication?.segment?.name">сегмент -
                      <a class="f-s-10"
                         [uiSref]="'frontend.segments.view({id:' + communication.campaign.segmentId + ', state:\'deploy\'})'">{{communication.segment.name}}</a>
                  </span>

                  <span *ngIf="communication?.group?.name">группа -
                      <a class="f-s-10"
                         [uiSref]="'frontend.segments.groups.view({id:' + communication.campaign.groupId + ', state:\'deploy\'})'">{{communication.group.name}}</a>
                  </span>

                  <span *ngIf="!communication?.segment?.name && !communication?.group?.name">Все</span>

              </span>


            <span *loyaPermitIf="{path:'segments', permitDisabled: ''}">

                 <span *ngIf="communication?.group?.name==='Все УПЛ'" data-testid="group.upl">Участники ПЛ</span>
                 <span *ngIf="communication?.group?.name==='Все неУПЛ'" data-testid="group.nupl">Не участники ПЛ</span>
                 <span *ngIf="communication?.group && communication?.group?.name!=='Все неУПЛ' && communication?.group?.name!=='Все УПЛ'" data-testid="group.name">группа - {{communication?.group?.name}}</span>

                 <span *ngIf="!communication?.group" data-testid="group.all">Все</span>

            </span>

          </td>

          <td>
              <span class="table-big-link">
                  <a [uiSref]="'frontend.campaign.campaigns.templates.view({id:' + communication.campaign.templateId + '})'" data-testid="communication.msgTemplateName"> {{ communication.msgTemplateName }} </a>
              </span>
          </td>

        </tr>

      </tbody>

    </table>

  </responsive-table>


  <div class="text-center text-warning m-t-lg m-b-lg"
       *ngIf="loader.isLoading('list')"
  >
    <div class="">
      <i class="fa fa-3x fa-spin fa-spinner"></i>
    </div>

  </div>

</div>



import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {DateUtils} from "../../../../../shared/class/utils/date.utils";
import {CampaignService} from "../../../../../shared/service/api/Campaign/Campaign.service";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {LOYA_CONFIG} from "../../../../../shared/variables/loya-config.variable";
import {
  LocationsDialogService
} from "../../../../../shared/component/locations-dialog/service/locations-dialog.service";
import {getNg1Service} from "../../../../../shared/class/utils/angularjs.utils";
import {GroupService} from "../../../../../shared/service/api/Group/Group.service";
import {SettingsService} from "../../../../../shared/service/api/Settings/Settings.service";
import {CampaignProtocol} from "../../../../../api/CampaignApi/models/campaign-protocol";
import {CommonCampaignTime} from "../../../../../api/CampaignApi/models/common-campaign-time";
import {CampaignTargetInfo} from "../../../../../api/CampaignApi/models/campaign-target-info";
import {CouponRuleRootClass} from "../class/coupon-rule-root.class";
import {CouponRuleService} from "../../../../../shared/service/api/CouponRule/CouponRule.service";
import {PartnerService} from "../../../../../shared/service/api/Partner/Partner.service";
import {CampaignCategoryService} from "../../../../../shared/service/api/CampaignCategory/CampaignCategory.service";
import {GoodsWatcherService} from "../../../../../shared/component/goods-tree/service/goods-watcher.service";
import {CouponService} from "../../../../../shared/service/api/Coupon/Coupon.service";
import {ISSUING_TYPES} from "../../../../../shared/service/api/CouponRule/CouponRule.values";
import {CKEditorBuilder} from "class/CKEditorBuilder/CKEditorBuilder";
import {
  goodSelectorParams,
  GoodsSelectorService,
  legacyDirectiveParams
} from "../../../../../shared/component/goods-tree/service/goods-selector.service";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import importController from "../../../../../../js/pages/coupons/rules/view/importController";
import * as importView from "../../../../../../js/pages/coupons/rules/view/views/import.html";

@Component({
  selector: 'coupon-rule-import',
  templateUrl: './coupon-rule-import.component.html',
  styleUrls: [
    `./coupon-rule-import.component.scss`
  ],
  providers: [
    CouponRuleService,
    SettingsService,
    PartnerService,
    CampaignCategoryService,
    GoodsWatcherService,
    CouponService,
    LocationsDialogService,
    GoodsSelectorService,
    ngCurrencyL10nService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CouponRuleImportComponent implements OnInit, OnChanges {

  constructor(
    public couponRuleService: CouponRuleService,
    private activeModal: NgbActiveModal,
    private httpClient: HttpClient,
  ) {
  }

  @Input() ruleItem: any

  public currentScreen;

  ngOnInit() {

  }

  public modalInit( fileUrl, ruleItem ) {

    this.ruleItem = ruleItem;
    this.loadedFile = fileUrl;

    this.loadScreen('CONFIG');

    this.updateOptions()
      .then(
        (result) => {

          this.tableRows = result.table;

        }, this.activeModal.dismiss
    );

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  /***** Настройки импорта *******/

  public encodingList={

    'utf8': 'UTF-8',
    'cp1251': 'WIN1251'

  };

  public importOptions: any = {

    encoding   : 'utf8',
    fromString : 1,
    barcodeIndex   : 1,
    rewardIndex: 2,
    delimiter  : ';',
    filenameOrigin : '',

  };

  public delimiters: any = {

    tab: '\\t',
    comma: ',',
    semicolon: ';',
    space: ' ',
    colon: ':',
    other: 'other'

  };

  public delimiterOptions: any = {
    index : 'other',
    otherValue : ''
  };

  public tableRows = {

    "table": [  ]

  };

  /************************/

  public reject() {

    if (this.loadedFile) {
      this.httpClient.delete( this.loadedFile  ).subscribe(() => {
        this.activeModal.dismiss();
      }, (error) => {
        error?.stopPopupError();
        this.activeModal.dismiss();
      });
    } else {
      this.activeModal.dismiss();
    }

  };

  public loadedFile : any  = false;

  public getColumnByIndex( index ) {

    switch (true) {
      case this.importOptions.barcodeIndex    === index :  return 'Штрихкод';
      case this.importOptions.rewardIndex     === index :  return 'Вознаграждение';

      case this.importOptions.SKUIndex        === index :  return 'SKU';
      case this.importOptions.countIndex      === index :  return 'Количество товара';
      case this.importOptions.dateFromIndex   === index :  return 'Дата С';
      case this.importOptions.dateToIndex     === index :  return 'Дата По';

      default : return '--';
    }

  }

  public setColumnIndex( idColumn, index ) {

    ['barcodeIndex', 'rewardIndex'].forEach( column => {
      this.importOptions[column] = this.importOptions[column] === index ? -1 : this.importOptions[column];
    } );

    if (idColumn) {
      this.importOptions[idColumn] = index;
    }

    this.onUpdateImportOptions();
  }

  public isValidColums() {
    if (
      this.importOptions.barcodeIndex === this.importOptions.rewardIndex
    ) return false;

    if (
      this.importOptions.barcodeIndex      === -1
    ) return false;

    if ( this.isRequiredReward() &&
      ( this.importOptions.rewardIndex      === -1 || typeof this.importOptions.rewardIndex  === "undefined" )
    ) return false;

    if ( this.isRequiredDates() &&
      ( this.importOptions.dateFromIndex    === -1 || typeof this.importOptions.dateFromIndex  === "undefined" ||
        this.importOptions.dateToIndex      === -1 || typeof this.importOptions.dateToIndex  === "undefined" )
    ) return false;

    if ( this.isRequiredCount() &&
      ( this.importOptions.countIndex    === -1 || typeof this.importOptions.countIndex  === "undefined"  )
    ) return false;

    if ( this.isRequiredSKU() &&
      ( this.importOptions.SKUIndex    === -1 || typeof this.importOptions.SKUIndex  === "undefined"  )
    ) return false;

    let rowsCount = this.tableRows &&
    this.tableRows.table &&
    this.tableRows.table[0] &&
    this.tableRows.table[0].row ? this.tableRows.table[0].row.length -1 : 0 ;

    if (
      this.importOptions.barcodeIndex > rowsCount
    ) return false;

    return true;
  }

  public initFlag = true;
  public isLoadPreview = false;
  public previewError = false;
  public updateOptions() {

    if ( this.loadedFile === false || typeof this.importOptions.fromString === 'undefined' ) {
      return Promise.resolve( { table: this.tableRows } );
    }

    var params : any = {};

    if (this.importOptions.delimiter !== '')
      params['delimiter']   = this.importOptions.delimiter;

    if (this.importOptions.fromString !== false ) {

      params.pager = {
        limit: 20,
        drop: this.importOptions.fromString - 1
      };

    }

    if (this.importOptions.encoding){
      params['encoding']    = this.importOptions.encoding;
    }

    if (this.importOptions.barcodeIndex !== false) {
      params['barcodeColumn']      = this.importOptions.barcodeIndex;
    }

    if (this.importOptions.rewardIndex !== false && this.importOptions.rewardIndex !== -1 ) {
      params['rewardColumn']    = this.importOptions.rewardIndex;
    }

    if (this.importOptions.dateFromIndex !== false && this.importOptions.dateFromIndex !== -1 ) {
      params['dateFromColumn']    = this.importOptions.dateFromIndex;
    }

    if (this.importOptions.dateToIndex !== false && this.importOptions.dateToIndex !== -1 ) {
      params['dateToColumn']    = this.importOptions.dateToIndex;
    }

    if (this.importOptions.countIndex !== false && this.importOptions.countIndex !== -1 ) {
      params['countColumn']    = this.importOptions.countIndex;
    }

    if (this.importOptions.SKUIndex !== false && this.importOptions.SKUIndex !== -1 ) {
      params['skuColumn']    = this.importOptions.SKUIndex;
    }


    if (this.initFlag) {
      params = {};
    }
    this.isLoadPreview = true;
    return this.couponRuleService.preview$( this.ruleItem.couponRule.id,this.loadedFile.split("/").splice(-1), params )
      .toPromise()
      .then((data) => {

        if (data && data.error) {

          this.previewError = data.error;

        } else {
          this.previewError = false;
        }

        this.isLoadPreview = false;
        if ( this.initFlag ) {

          this.importOptions.extension = data.extension;
          this.importOptions.filename = data.filename;
          this.importOptions.fromString = ( data.settings.pager ? data.settings.pager.drop + 1 : 1 ) ;
          this.importOptions.delimiter = data.settings.delimiter;
          this.importOptions.encoding = data.settings.encoding;
          this.importOptions.barcodeIndex = data.barcodeColumn || 0;
          this.importOptions.rewardIndex  = data.rewardColumn;
          this.importOptions.dateFromIndex  = data.dateFromColumn;
          this.importOptions.dateToIndex    = data.dateToColumn;
          this.importOptions.countIndex     = data.countColumn;
          this.importOptions.SKUIndex       = data.skuColumn;

          this.importOptions.filenameOrigin = data.filenameOrigin;

          if ( data.table && data.table.table ) {

            if ( data.table.table[0] && data.table.table[0].row.length -1 < this.importOptions.barcodeIndex ) {
              this.importOptions.barcodeIndex = 0;
            }

            ['rewardIndex','dateFromIndex','dateToIndex','countIndex','SKUIndex'].forEach( i => {
              if ( data.table.table[0] && data.table.table[0].row.length -1 < this.importOptions[i] ) {
                this.importOptions[i] = -1;
              }
            });

          } else {
            this.importOptions.barcodeIndex = 0;
            this.importOptions.rewardIndex = 1;
          }

          this.delimiterOptions.index = false;
          Object.keys(this.delimiters).forEach( (  index ) => {
            if ( this.delimiters[index] === this.importOptions.delimiter) {
              this.delimiterOptions.index = index;
            }
          });

          if ( this.delimiterOptions.index === false ) {
            this.delimiterOptions.index = 'other';
            this.delimiterOptions.otherValue = this.importOptions.delimiter;
          }

          this.initFlag = false;
          this.onUpdateImportOptions();
          this.onUpdateDelimiterOptions();
        }

        return data;

      }).catch(function(data) {
        this.isLoadPreview = false;
        this.previewError = data.data;
        this.activeModal.dismiss({ error: data.data } );
      } );

  };

  public confirmImport() {

    this.loadScreen('IMPORT');

    const uploader = this.ruleItem.couponRule.state === 'draft' ?
      this.couponRuleService.upload$.bind(this.couponRuleService) :
      this.couponRuleService.addCoupons$.bind(this.couponRuleService);

    uploader( this.ruleItem.couponRule.id, this.importOptions.filename,{

      delimiter : this.importOptions.delimiter,
      encoding  : this.importOptions.encoding,

      barcodeColumn    : this.importOptions.barcodeIndex,
      rewardColumn  : this.importOptions.rewardIndex,

      dateFromColumn : this.importOptions.dateFromIndex,
      dateToColumn : this.importOptions.dateToIndex,
      countColumn  : this.importOptions.countIndex,
      skuColumn    : this.importOptions.SKUIndex,

      pager: {
        drop      : this.importOptions.fromString - 1,
        limit     : -1
      },

    }).subscribe(  (data) => {
      this.activeModal.close(data);
    }, (data) => {
      this.activeModal.dismiss({ error: data.data } );
    });

  };

  public showOption( field ) {

    switch( field ) {
      case "encoding": return this.importOptions.extension === 'csv';
      case "delimiter": return this.importOptions.extension === 'csv';

    }

    return true;
  };

  private oldImportOptions:any  =  JSON.parse(JSON.stringify(this.importOptions));
  public onUpdateImportOptions() {

    if ( typeof this.importOptions !== "object") {
      return;
    }

    if ( this.importOptions.barcodeIndex !== this.oldImportOptions.barcodeIndex ) {

      let otherChanges = false;
      for ( let index in this.importOptions) {
        if (  this.importOptions.hasOwnProperty(index) && this.importOptions[index] !== this.oldImportOptions[index]) {
          otherChanges = true;
          break;
        }
      }

      if ( otherChanges ) {
        this.oldImportOptions = JSON.parse(JSON.stringify(this.importOptions));
        return;
      }
    }

    this.oldImportOptions = JSON.parse(JSON.stringify(this.importOptions));

    if ( this.delimiterOptions.index !== 'other' ) {

      this.delimiterOptions.index = false;
      Object.keys(this.delimiters).forEach( (  index ) => {
        if ( this.delimiters[index] === this.importOptions.delimiter) {
          this.delimiterOptions.index = index;
        }
      });

      if ( this.delimiterOptions.index === false ) {
        this.delimiterOptions.delimiterOptionsns.index = 'other';
        this.delimiterOptions.otherValue = this.importOptions.delimiter;
      }

    }

    this.updateOptions().then(
      (result) => {

        this.tableRows = result.table;

      }, () => {}
    );

  }

  public onUpdateDelimiterOptions() {

      if ( !this.delimiterOptions ) {
        return;
      }

      if ( this.delimiterOptions?.index !== 'other' ) {

        this.importOptions.delimiter = this.delimiters[this.delimiterOptions?.index];

      } else {

        this.importOptions.delimiter = this.delimiterOptions?.otherValue;

      }

      this.onUpdateImportOptions();

  }

  public isRequiredReward() {
    return this.ruleItem?.couponRule?.issueSettings?.couponKind === 'importedCoupon' || this.ruleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcodeWithReceptParams';
  }

  public isRequiredDates() {
    return this.ruleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcodeWithReceptParams';
  }

  public isRequiredSKU() {
    return this.ruleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcodeWithReceptParams';
  }

  public isRequiredCount() {
    return this.ruleItem?.couponRule?.issueSettings?.couponKind === 'couponWithBarcodeWithReceptParams';
  }

  public fileImportResult = false;
  public closeFileImportResult() { this.fileImportResult = false; };

  public loadScreen( id ) {

    let screens = {
      LOAD     : 'load',
      PROGRESS : 'progress',
      CONFIG   : 'config',
      IMPORT   : 'import',
    };

    this.currentScreen = screens[id];

  };



}

  <table  class="table m-b-none  b-b  "
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          [fromRoot]="true"
          (scrolled)="onScroll()"
          data-testid="campaigns-view"
  >
    <!--
          [infiniteScrollContainer]="'campaigns-view'"
          -->
    <thead>
    <tr>
      <th class="indicator-hide">

        <div class="checkbox"
             *ngIf="canCheckAll"
        >
          <label class="i-checks mb-0">
            <input type="checkbox"
                   [checked]="isSelectedAll()"
                   (change)="changeSelectedAll(!!$event?.currentTarget?.checked)"
            >
            <i></i>
          </label>
        </div>

      </th>
      <th class="align-middle">
          Статус
      </th>
      <th class="align-middle">
        <sort-view
          column="campaignName"
          [params]="couponRuleService.queryParams"
          (onChange)="reset()"
        >
          Купонное правило
        </sort-view>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of campaignsItems; let odd = odd"
        data-testid="item of campaignsItems"
        [class.bg-light]=" odd "
        class="pointer"
        (click)="changeSelected(item, !$event?.currentTarget?.querySelector('input')?.checked); $event.stopImmediatePropagation();"
    >

      <td width="2%" align="center" >

        <div class="checkbox">
          <label class="i-checks">
            <input type="checkbox"
                   [checked]="isSelected(item.id)"
                   (change)="changeSelected(item, !!$event?.currentTarget?.checked)"
                   [disabled]="isDisabled(item.id)"
                   (click)="$event.stopPropagation();$event.stopImmediatePropagation(); $event.preventDefault()"
            >
            <i></i>
          </label>
        </div>

      </td>
      <td class=" table-big-link"  data-value="" data-testid="campaignItem.name">
        <text-highlight [search]="searchQuery" [text]="COUPON_RULE_STATE_NAMES[item.state] || item.state" ></text-highlight>
      </td>

      <td class="table-big-link" data-testid="campaignItem.code">
        <text-highlight [search]="searchQuery" [text]="item.name" ></text-highlight>
      </td>

    </tr>

    </tbody>
  </table>

  <div class="w-full text-center padder mt-3"
    *ngIf="loader.isLoading('campaignsItems')"
  >
    <div class="w-100 text-center">
      <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
    </div>

  </div>




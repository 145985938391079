import { BreadcrumbsPathComponent } from './shared/component/breadcrumbs-path/breadcrumbs-path.component';
import { SelectMechanicComponent } from "./shared/component/select-mechanic/select-mechanic.component";
import { Ng1AlertComponent } from "./shared/component/ui/alert/ng1-alert.component";
import { Ng1ProgressbarComponent } from "./shared/component/ui/progressbar/ng1-progressbar.component";
import { Ng1DatepickerComponent } from "./shared/component/ui/datepicker/ng1-datepicker.component";
import { Ng1DropdownComponent } from "./shared/component/ui/dropdown/ng1-dropdown.component";
import { SearchFormComponent } from "./shared/component/search-form/search-form.component";
import { SearchHighlightComponent } from "./shared/component/search-highlight/search-highlight.component";

import { downgradeComponent } from '@angular/upgrade/static';
import {Ng1PopoverComponent} from "./shared/component/ui/popover/ng1-popover.component";
import {ReceiptViewComponent} from "./shared/component/receipt-view/receipt-view.component";
import {HeaderUserComponent} from './shared/component/header-user/header-user.component';
import {HeaderAlertBoxComponent} from "./shared/component/header-alert-box/header-alert-box.component";
import {FeedbackButtonComponent} from "./shared/component/feedback-button/feedback-button.component";
import {ReceitDetailsItemComponent} from "./shared/component/receit-details-item/receit-details-item.component";
import {NavMenuComponent} from "./shared/component/nav-menu/nav-menu.component";

import {MechaniccategoryIndexPageComponent} from "./pages/config/directory/mechaniccategory/index/mechaniccategory-index-page.component";
import {MechaniccategoryViewPageComponent} from "./pages/config/directory/mechaniccategory/view/mechaniccategory-view-page.component";
import {UsersIndexPageComponent} from "./pages/config/directory/users/index/users-index-page.component";
import {UsersViewPageComponent} from "./pages/config/directory/users/view/users-view-page.component";
import {ClientcategoryIndexPageComponent} from "./pages/config/directory/clientcategory/index/clientcategory-index-page.component";
import {ClientcategoryViewPageComponent} from "./pages/config/directory/clientcategory/view/clientcategory-view-page.component";
import {CampaigncategoryIndexPageComponent} from "./pages/config/directory/campaigncategory/index/campaigncategory-index-page.component";
import {CampaigncategoryViewPageComponent} from "./pages/config/directory/campaigncategory/view/campaigncategory-view-page.component";
import {AdditionalMenuComponent} from "./shared/component/additional-menu/additional-menu.component";
import {PartnerMenuComponent} from "./shared/component/partner-menu/partner-menu.component";
import {BrandIndexPageComponent} from "./pages/config/directory/brand/index/brand-index-page.component";
import {BrandViewPageComponent} from "./pages/config/directory/brand/view/brand-view-page.component";
import {TriggersTransactionsPageComponent} from "./pages/triggers/trigger/transactions/triggers-transactions-page.component";
import {GoodgroupIndexPageComponent} from "./pages/config/directory/goodgroup/index/goodgroup-index-page.component";
import {GoodgroupViewPageComponent} from "./pages/config/directory/goodgroup/view/goodgroup-view-page.component";
import {CampaigncategorylimitationsIndexPageComponent} from "./pages/config/directory/campaigncategorylimitations/index/campaigncategorylimitations-index-page.component";
import {ReferrerIndexPageComponent} from "./pages/referrer/index/referrer-index-page.component";
import {ReferrerViewPageComponent} from "./pages/referrer/view/referrer-view-page.component";
import {ReferrerJournalPageComponent} from "./pages/referrer/journal/referrer-journal-page.component";
import {AccountTransactionIndexPageComponent} from "./pages/crm/accaunts/tansaction/index/account-transaction-index-page.component";
import {AccountTransactionByUserPageComponent} from "./pages/crm/accaunts/tansaction/by-user/account-transaction-by-user-page.component";
import {DashboardPageComponent} from "./pages/dashboard/view/dashboard-page.component";
import {
  CampaignlimitationsIndexPageComponent
} from "./pages/config/directory/campaignlimitations/index/campaignlimitations-index-page.component";
import {
  CampaignlimitationsViewPageComponent
} from "./pages/config/directory/campaignlimitations/view/campaignlimitations-view-page.component";
import {SelectCampaignComponent} from "./shared/component/select-campaign/select-campaign.component";
import {
  SelectInformingTemplateComponent
} from "./shared/component/select-informing-template/select-informing-template.component";
import {PartnersIndexPageComponent} from "./pages/config/directory/partners/index/partners-index-page.component";
import {LocationsIndexPageComponent} from "./pages/config/directory/locations/index/locations-index-page.component";
import {LocationsViewPageComponent} from "./pages/config/directory/locations/view/locations-view-page.component";
import {DynamicReportsPageComponent} from "./pages/reports/dynamic/dynamic-reports-page.component";
import {CardsIndexPageComponent} from "./pages/crm/cards/index/cards-index-page.component";
import {CardsViewPageComponent} from "./pages/crm/cards/view/cards-view-page.component";
import {SegmentsIndexPageComponent} from "./pages/segments/index/segments-index-page.component";
import {SegmentsViewPageComponent} from "./pages/segments/view/segments-view-page.component";
import {SegmentsResegmentPageComponent} from "./pages/segments/resegment/segments-resegment-page.component";
import {GateIndexPageComponent} from "./pages/campaign/gate/index/gate-index-page.component";
import {
  LocationsGroupIndexPageComponent
} from "./pages/config/directory/locationsGroup/index/locations-group-index-page.component";
import {
  LocationGroupIndexViewPageComponent
} from "./pages/config/directory/locationsGroup/index/shared/locationGroupIndexView/location-group-index-view-page.component";
import {
  BonusRestrictIndexPageComponent
} from "./pages/config/directory/bonusrestrict/index/bonus-restrict-index-page.component";
import {CampaignsIndexPageComponent} from "./pages/campaign/campaigns/index/campaigns-index-page.component";
import {CampaignTagsComponent} from "./shared/component/campaignTags/campaign-tags.component";
import {CampaignsViewPageComponent} from "./pages/campaign/campaigns/view/campaigns-view-page.component";
import {ReceiptIndexPageComponent} from "./pages/receipt/index/receipt-index-page.component";
import {ScrollUpComponent} from "./shared/component/scroll-up/scroll-up.component";
import {
  CommunicationsIndexPageComponent
} from "./pages/campaign/communications/index/communications-index-page.component";
import {CommunicationsViewPageComponent} from "./pages/campaign/communications/view/communications-view-page.component";


const allowAttribute = directiveFactory => [
  '$injector',
  ($injector: any) =>
    Object.assign($injector.invoke(directiveFactory), {restrict: 'EA'}),
];

angular.module('loya.builder')
  .directive('breadcrumbsPath', downgradeComponent({ component: BreadcrumbsPathComponent }) )
  .directive('selectMechanic', downgradeComponent({ component: SelectMechanicComponent }) )
  .directive('ng1Alert', downgradeComponent({ component: Ng1AlertComponent }) )
  .directive('ng1Progressbar', downgradeComponent({ component: Ng1ProgressbarComponent, inputs: ['value'] }) )
  .directive('ng1Datepicker', downgradeComponent({ component: Ng1DatepickerComponent }) )
  .directive('ng1Dropdown', downgradeComponent({ component: Ng1DropdownComponent }) )
  .directive('ng1Popover', allowAttribute(downgradeComponent({ component: Ng1PopoverComponent })) )
  .directive('searchForm', downgradeComponent({ component: SearchFormComponent }) )
  .directive('searchHighlight', downgradeComponent({ component: SearchHighlightComponent }) )
  .directive('receiptView', downgradeComponent({ component: ReceiptViewComponent }) )
  .directive('headerUser', downgradeComponent({ component: HeaderUserComponent }) )
  .directive('headerAlertBox', downgradeComponent({ component: HeaderAlertBoxComponent }) )
  .directive('feedbackButton', downgradeComponent({ component: FeedbackButtonComponent }) )
  .directive('receitDetailsItem', downgradeComponent({ component: ReceitDetailsItemComponent }) )
  .directive('navMenu', downgradeComponent({ component: NavMenuComponent }) )
  .directive('additionalMenu', downgradeComponent({ component: AdditionalMenuComponent }) )
  .directive('partnerMenu', downgradeComponent({ component: PartnerMenuComponent }) )
  .directive('selectCampaign', downgradeComponent({ component: SelectCampaignComponent }) )
  .directive('selectInformingTemplate', downgradeComponent({ component: SelectInformingTemplateComponent }) )
  .directive('campaignTags', downgradeComponent({ component: CampaignTagsComponent }) )
  .directive('scrollUp', downgradeComponent({ component: ScrollUpComponent }) )


// pages
  .directive('mechaniccategoryIndexPage', downgradeComponent({ component: MechaniccategoryIndexPageComponent }) )
  .directive('mechaniccategoryViewPage', downgradeComponent({ component: MechaniccategoryViewPageComponent }) )
  .directive('usersIndexPage', downgradeComponent({ component: UsersIndexPageComponent }) )
  .directive('usersViewPage', downgradeComponent({ component: UsersViewPageComponent }) )
  .directive('clientcategoryIndexPage', downgradeComponent({ component: ClientcategoryIndexPageComponent }) )
  .directive('clientcategoryViewPage', downgradeComponent({ component: ClientcategoryViewPageComponent }) )
  .directive('campaigncategoryIndexPage', downgradeComponent({ component: CampaigncategoryIndexPageComponent }) )
  .directive('campaigncategoryViewPage', downgradeComponent({ component: CampaigncategoryViewPageComponent }) )
  .directive('brandIndexPage', downgradeComponent({ component: BrandIndexPageComponent }) )
  .directive('brandViewPage', downgradeComponent({ component: BrandViewPageComponent }) )
  .directive('triggersTransactionsPage', downgradeComponent({ component: TriggersTransactionsPageComponent }) )
  .directive('goodgroupIndexPage', downgradeComponent({ component: GoodgroupIndexPageComponent }) )
  .directive('goodgroupViewPage', downgradeComponent({ component: GoodgroupViewPageComponent }) )
  .directive('campaigncategorylimitationsIndexPage', downgradeComponent({ component: CampaigncategorylimitationsIndexPageComponent }) )
  .directive('campaignlimitationsIndexPage', downgradeComponent({ component: CampaignlimitationsIndexPageComponent }) )
  .directive('campaignlimitationsViewPage', downgradeComponent({ component: CampaignlimitationsViewPageComponent }) )
  .directive('dashboardPage', downgradeComponent({ component: DashboardPageComponent }) )
  .directive('partnersIndexPage', downgradeComponent({ component: PartnersIndexPageComponent }) )

  .directive('locationsIndexPage', downgradeComponent({ component: LocationsIndexPageComponent }) )
  .directive('locationsViewPage', downgradeComponent({ component: LocationsViewPageComponent }) )

  .directive('locationsGroupViewPage', downgradeComponent({ component: LocationsGroupIndexPageComponent }) )

  .directive('referrerIndexPage', downgradeComponent({ component: ReferrerIndexPageComponent }) )
  .directive('referrerViewPage', downgradeComponent({ component: ReferrerViewPageComponent }) )
  .directive('referrerJournalPage', downgradeComponent({ component: ReferrerJournalPageComponent }) )

  .directive('accountTransactionIndexPage', downgradeComponent({ component: AccountTransactionIndexPageComponent }) )
  .directive('accountTransactionByUserPage', downgradeComponent({ component: AccountTransactionByUserPageComponent }) )

  .directive('reportsDynamicPage', downgradeComponent({ component: DynamicReportsPageComponent }) )
  .directive('cardsIndexPage', downgradeComponent({ component: CardsIndexPageComponent }) )
  .directive('cardsViewPage' , downgradeComponent({ component: CardsViewPageComponent  }) )

  .directive('segmentsIndexPage' , downgradeComponent({ component: SegmentsIndexPageComponent  }) )
  .directive('segmentsViewPage' , downgradeComponent({ component: SegmentsViewPageComponent  }) )
  .directive('segmentsResegmentPage' , downgradeComponent({ component: SegmentsResegmentPageComponent  }) )

  .directive('gateIndexPage' , downgradeComponent({ component: GateIndexPageComponent  }) )

  .directive('bonusRestrictIndexPage' , downgradeComponent({ component: BonusRestrictIndexPageComponent }) )

  .directive('campaignsIndexPage', downgradeComponent({ component: CampaignsIndexPageComponent }) )
  .directive('campaignsViewPage', downgradeComponent({ component: CampaignsViewPageComponent }) )

  .directive('communicationsIndexPage', downgradeComponent({ component: CommunicationsIndexPageComponent }) )
  .directive('communicationsViewPage', downgradeComponent({ component: CommunicationsViewPageComponent }) )



;


<div class="fixed-tool-panel">

  <button
          class="btn btn-success btn-sm btn-addon pull-right"
          type="button"
          [uiSref]="'^.view'"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

<div class="wrapper stick-search-box">
  <search-input
    [(model)]="searchString"
    placeholder="Введите название"
  ></search-input>
</div>

 <responsive-table>
   <table
     class="table b-b "
    >
     <thead>
       <tr>
         <th>{{ 'models.MechanicCategory.id.name' | transloco }}</th>
         <th>{{ 'models.MechanicCategory.name.name' | transloco }}</th>
         <th class="table-row-sm table-row-xs ">{{ 'models.MechanicCategory.description.name' | transloco }}</th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listMechanicCategory | callback:filterBySearch">
          <tr
              [uiSref]="'^.view({id:'+item.id+'})'"
              class="pointer"
          >
            <td>
              <span [innerText]="item.id"></span>
            </td>
            <td class="table-big-link">
              <a [innerText]="item.name" href=""
                 [uiSref]="'^.view({id:'+item.id+'})'"></a>
            </td>
            <td>
              <span [innerText]="item.description"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

<scroll-up></scroll-up>

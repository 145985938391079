<div class="row">

  <div class="col-sm-1 no-padder text-center pointer"

  >

      <i
        *ngIf="hasAdditionalInfo()"
        (click)="switchAdditionalInfo()"
        data-testid="cashbox.item.show_rules"
        class="fa text-primary-dker m-r-xs"
        [ngClass]="{ ' fa-chevron-up': showAdditionalInfo, '  fa-chevron-down': !showAdditionalInfo }">
      </i>

      <span [innerText]=" index || '' "></span>

  </div>

  <div class="col-sm-2 wrapper-xs text-center ">

    <span
      data-testid="cashbox.item.sku"
      [innerText]="item?.sku || ''"></span>

  </div>

  <div class="col-sm-4 wrapper-xs text-left">
    <span class="text-ellipsis"
          [innerText]="getItemName(item)"></span>
  </div>

  <div class="col-sm-2 no-padder text-center">

     <span class="text-primary pointer"
           *ngIf="!editable"
    >

        <span class="text-ellipsis">

          <span class="pointer f-s-14 text-warning-lter m-r-xs"
                (click)="countDown(); $event.stopPropagation()"
                data-testid="cashbox.item.count_down"
          >
            <i class="fa fa-minus-circle "></i>
          </span>

          <span (click)="switchEditable()"
                data-testid="cashbox.item.quantity"
          >

            <span class="text-primary pointer">
               <span class="f-s-14"
                     [innerText]="item.quantity || ''"
               ></span>
            </span>

            <span *ngIf="item.weight==='weight'">
              <i class="fa fa-balance-scale text-dark text-muted"></i>
            </span>

          </span>

          <span class="pointer f-s-14 text-warning-lter m-l-xs"
                (click)="countAdd(); $event.stopPropagation()"
                data-testid="cashbox.item.count_add"
          >
            <i class="fa fa-plus-circle "></i>
          </span>

       </span>

    </span>

    <input *ngIf="editable"
           (blur)="commitChangeQuantity()"
           type="text"
           class="w-xxs text-center  no-shadow m-r-n-md m-t-xxs text-lg"
           [(ngModel)]="item.quantity"
           [pattern]="getWeightPattern()"
           (keydown.enter)="commitChangeQuantity()"
           [min]="0.001"
           data-testid="cashbox.item.quantity.input"
    >
  </div>

  <div class="col-sm-2 no-padder text-right">

      <span class="text-ellipsis text-primary m-t-xs pointer"
            *ngIf="!priceEditable"
            (click)="switchPriceEditable()"
            data-testid="cashbox.item.change-price"
      >
        <span [innerText]="item.price | loyaCurrency"></span>
        <i class="fa f-s-9 m-l-xs" ngCurrencyLoya ></i>
      </span>

      <input *ngIf="priceEditable"
             (blur)="commitChangePrice()"
             type="text"
             class="w-xs text-center no-border no-shadow m-r-md-xs  m-t-xxs text-lg"
             [(ngModel)]="item.price"
             pattern="^\d+(\.\d+)?$"
             (keydown.enter)="commitChangePrice()"
             min="1"
             data-testid="cashbox.item.input-price"
      >
  </div>

  <div class="col-sm-1 wrapper-xs text-center">

    <button class="btn btn-xs btn-danger  m-t-n-xs"
            data-testid="cashbox.item.delete"
            (click)="deleteItem()">
      <i class="fa fa-minus"></i>
    </button>

  </div>

  <!--div class="line line-dashed b-b line-lg pull-in  m-t-none m-b-xs  "></div-->
</div>

<div class="row">

  <div class="col-sm-3 no-padder text-center">
    <ng-container
      *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '' }"
    >
      <span class="text-muted f-s-8">
        Начислено бонусов
      </span>
      <span class=" text-ellipsis">
        <span [innerText]="item?.awards?.awardBonus  | loyaNumber:false"></span>
      </span>
    </ng-container>
  </div>

  <div class="col-sm-3 no-padder text-center ">
    <ng-container
      *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: '' }"
    >
      <span class="text-muted f-s-8" >
        Доступно к оплате бонусами
      </span>

      <div class="m-r text-ellipsis">
        <span [innerText]="item?.awards?.allowBonus  | loyaNumber:false"></span>
      </div>
    </ng-container>
  </div>

  <div class="col-sm-2 no-padder text-right ">
    <span class="text-muted f-s-8">Скидка</span>
    <span class="text-ellipsis">
      <span [innerText]="  discountForItem | loyaCurrency"></span>
      <i class="fa f-s-9 m-l-xs" ngCurrencyLoya ></i>
    </span>
  </div>

  <div class="col-sm-2 no-padder text-right pointer">
    <span class="text-muted f-s-8">Итого</span>
    <span class="text-ellipsis">
      <span [innerText]="item?.total | loyaCurrency"></span>
      <i class="fa f-s-9 m-l-xs" ngCurrencyLoya ></i>
    </span>
  </div>

  <div class="col-sm-2 no-padder text-right">
    <div class="m-r-md">
      <span class="text-muted f-s-8">К оплате</span>
      <span class="text-ellipsis">
          <span
            [innerText]="  totalForPay()  | loyaCurrency "
            data-testid="cashbox.item.totalForPay"
          ></span>
          <i class="fa f-s-9 m-l-xs " ng-currency-loya ></i>
      </span>
    </div>

  </div>

</div>

<ng-container
     *ngIf="!!cashBoxService?.currentCheck?.actions"
>
  <cash-box-input-with-position
    class="m-t-xs"
    [actions]="cashBoxService.currentCheck.actions"
    [awards] = "cashBoxService.currentCheck.awards"
    [id]     = "item.id"
  ></cash-box-input-with-position>
</ng-container>

<div class="cash-box-items-row_item-details bg-light dk"
     *ngIf="item?.results && showAdditionalInfo"
>

  <receit-details-item
    [items]="item.results"
  ></receit-details-item>

  <div class="clearfix">
  </div>

  <div class=" m-t-xs ">
    <cash-box-collision-info
      [precheckGetter]="precheckGetter"
      [positionNumber] = "index"
      [precheckUpdate]="0"
      [isVisible]     = "showedCollisions"
    ></cash-box-collision-info>
  </div>

</div>

import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {GoodsTreeComponent} from "./components/goods-tree/goods-tree.component";
import {TreeViewComponent} from "./components/tree-view/tree-view.component";
import {TreeModule} from "@circlon/angular-tree-component";
import {GoodsTreeViewComponent} from "./components/goods-tree-view/goods-tree-view.component";
import {SearchInputModule} from "../search-input/search-input.module";
import {BrandViewComponent} from "./components/brand-view/brand-view.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {GoodgroupViewComponent} from "./components/goodgroup-view/goodgroup-view.component";
import {FavouriteViewComponent} from "./components/favourite-view/favourite-view.component";
import {SelectedViewComponent} from "./components/selected-view/selected-view.component";
import {GoodTreeGetterService} from "./service/good-tree-getter.service";
import {SharedComponentsModule} from "../shared.components.module";
import {GoodsSelectorService} from "./service/goods-selector.service";
import {ManualGoodInputComponent} from "./components/manual-good-input/manual-good-input.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {GoodsWatcherService} from "./service/goods-watcher.service";
import {GoodsWatchComponent} from "./components/goods-watch/goods-watch.component";


@NgModule({
  declarations: [
    GoodsTreeComponent,
    TreeViewComponent,
    GoodsTreeViewComponent,
    BrandViewComponent,
    GoodgroupViewComponent,
    FavouriteViewComponent,
    SelectedViewComponent,
    ManualGoodInputComponent,
    GoodsWatchComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        UpgradeModule,
        TreeModule,
        SearchInputModule,
        InfiniteScrollModule,
        SharedComponentsModule,
        NgbDropdownModule,
    ],
  providers: [
    GoodTreeGetterService,
    GoodsSelectorService,
    GoodsWatcherService
  ],
  entryComponents:[],
  exports:[
    GoodsTreeComponent
  ]
})
export class GoodsTreeModule {

  constructor() {}

}

//-- LEGACY ---

angular.module('loya.builder')
  .factory('GoodsSelector', downgradeInjectable(GoodsSelectorService))
  .directive('goodsTree', downgradeComponent({ component: GoodsTreeComponent }) )

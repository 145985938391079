/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommunicationCampaignApiConfiguration, CommunicationCampaignApiConfigurationInterface } from './communication-campaign-api-configuration';

import { CommunicationCampaignApiService } from './services/communication-campaign-api.service';

/**
 * Provider for all CommunicationCampaignApi services, plus CommunicationCampaignApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CommunicationCampaignApiConfiguration,
    CommunicationCampaignApiService
  ],
})
export class CommunicationCampaignApiModule {
  static forRoot(customParams: CommunicationCampaignApiConfigurationInterface): ModuleWithProviders<CommunicationCampaignApiModule> {
    return {
      ngModule: CommunicationCampaignApiModule,
      providers: [
        {
          provide: CommunicationCampaignApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CouponRuleApiService} from "../../../../api/CouponRuleApi/services";
import {CouponRuleQueryParam} from "../../../../api/CouponRuleApi/models/coupon-rule-query-param";
import CouponRuleFilters from "../../../../../js/models/CouponRuleModel/filters";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {Observable, of} from "rxjs";
import {CampaignProtocol} from "../../../../api/CampaignApi/models/campaign-protocol";
import {ISSUING_TYPE_NAMES} from "./CouponRule.values";
import {map} from "rxjs/operators";


const fixDates = (couponRuleItem: any) => {

  const testDate = dateItem => typeof dateItem === 'string' && /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/.test(dateItem);
  const fixDate = dateItem => dateItem.split(' ')[0];

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.startDate ) ) {
    couponRuleItem.couponRule.issueSettings.startDate = fixDate(couponRuleItem.couponRule.issueSettings.startDate)
  }

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.stopDate ) ) {
    couponRuleItem.couponRule.issueSettings.stopDate = fixDate(couponRuleItem.couponRule.issueSettings.stopDate)
  }

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.issueUntil ) ) {
    couponRuleItem.couponRule.issueSettings.issueUntil = fixDate(couponRuleItem.couponRule.issueSettings.issueUntil)
  }

  if ( /^\d{2}:\d{2}:00$/.test(couponRuleItem?.couponRule?.applySettings?.validTimeFrom ) ) {
    couponRuleItem.couponRule.applySettings.validTimeFrom = couponRuleItem.couponRule.applySettings.validTimeFrom.slice(0,-3)
  }

  if ( /^\d{2}:\d{2}:59$/.test(couponRuleItem?.couponRule?.applySettings?.validTimeTill ) ) {
    couponRuleItem.couponRule.applySettings.validTimeTill = couponRuleItem.couponRule.applySettings.validTimeTill.slice(0,-3)
  }


  return couponRuleItem;
}

const addHoursDates = (couponRuleItem: any) => {

  const testDate = dateItem => typeof dateItem === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(dateItem);
  const fixDate = dateItem => dateItem + " 00:00:00";

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.startDate ) ) {
    couponRuleItem.couponRule.issueSettings.startDate = fixDate(couponRuleItem.couponRule.issueSettings.startDate)
  }

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.stopDate ) ) {
    couponRuleItem.couponRule.issueSettings.stopDate = fixDate(couponRuleItem.couponRule.issueSettings.stopDate)
  }

  if ( testDate(couponRuleItem?.couponRule?.issueSettings?.issueUntil ) ) {
    couponRuleItem.couponRule.issueSettings.issueUntil = fixDate(couponRuleItem.couponRule.issueSettings.issueUntil)
  }

  if ( /^\d{2}:\d{2}$/.test(couponRuleItem?.couponRule?.applySettings?.validTimeFrom ) ) {
    couponRuleItem.couponRule.applySettings.validTimeFrom = couponRuleItem.couponRule.applySettings.validTimeFrom + ':00'
  }

  if ( /^\d{2}:\d{2}$/.test(couponRuleItem?.couponRule?.applySettings?.validTimeTill ) ) {
    couponRuleItem.couponRule.applySettings.validTimeTill = couponRuleItem.couponRule.applySettings.validTimeTill + ':59'
  }

  return couponRuleItem;
}

const fixDescription = (couponRuleItem: any) => {
  let desc = couponRuleItem?.couponRule?.issueSettings?.couponDescription;

  if ( !desc )
    return couponRuleItem;

  if ( desc[desc.length] === "\n" ) {
    desc = desc.slice(0, -1);
  }

  desc = desc.trim();

  if ( desc.startsWith("<p>") ) {
    desc = desc.slice(3);
    if ( desc.endsWith("</p>") ) {
      desc = desc.slice(0, -4);
    }
  }

  couponRuleItem.couponRule.issueSettings.couponDescription = desc;
  return couponRuleItem;
}


@Injectable()
export class CouponRuleService {

  constructor(
    private couponRuleApiService: CouponRuleApiService,
    private httpClient: HttpClient
  ) {
  }


  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.couponKind',
        id: 'couponKind',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.issuingType',
        id: 'issuingType',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      }
    ]
  });

  public query$(params?:CouponRuleQueryParam){

    params.filter = params.filter || {};
    params.filter.couponKind = params.filter.couponKind ||
      Object.values(CouponRuleFilters?.searchCouponRule?.filter?.couponKind?.values || {} )
    params.filter.issuingType = params.filter.issuingType ||
      Object.values(CouponRuleFilters?.searchCouponRule?.filter?.issuingType?.values || {} )

    return this.couponRuleApiService.searchCouponRule(params);
  }

  public get$(id: number ) {
    return this.httpClient.get('/coupon/rule/' + id, {
      responseType: "json"
    })
      .pipe(
        map( i => fixDates(i) )
      )

  }

  public create$( body :any ) {
    body = addHoursDates(body);
    body = fixDescription(body);
    return this.httpClient.put('/coupon/rule', body, {
      responseType: "json"
    });
  }

  public update$( id: number, body :any ) {
    body = addHoursDates(body);
    body = fixDescription(body);
    return this.httpClient.post('/coupon/rule/'+id, body, {
      responseType: "json"
    })
      .pipe(
        map( i => fixDates(i) )
      );
  }

  public delete$( id: number ) {
    return this.httpClient.delete('/coupon/rule/' + id, {
      responseType: "json",
    });
  }

  public getOrCreate$(id?: number): Observable<any> {

    if (!!id || id === 0) {
      return this.get$(id);
    }

    return of(<any> {
      couponRule: {
        description: '',
        plugin: "CouponPlugin",
        state:"draft",

        issueSettings: {
          issuingType: Object.keys(ISSUING_TYPE_NAMES)[0],
          canBeSold: false,
          customPeriod: false
        },

        applySettings: {
          validTimeFrom: '00:00',
          validTimeTill: '23:59',
          validDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
          couponApply: "applyOnRequest",
          minCheckTotal: 0
        },
        usingSettings: {
          useCount: 1,
          usable3rdParties: false
        },
        reward: {
          reward: {
            what        : 'discount',
            rewType     : 'total',
            id          : 0,
            description : "",
            value: 0,
          }
        }
      }
    });

  }

  public createOrUpdate$(params: any): Observable<any>  {
    if ( params?.couponRule?.id >=0)
      return this.update$(params?.couponRule?.id, params)
    else
      return this.create$(params);
  }

  public setState$(id: number, state: any): Observable<any>  {
    return this.httpClient.put('/coupon/rule/' + id + '/' + state, {
      responseType: "json",
    });
  }

  public queryCouponEditions$(id: number, body: any = {}): Observable<any>  {
    return this.httpClient.post('/coupon/rule/' + id + '/queryCouponEditions',
      body,{
      responseType: "json",
    });
  }

  public createGeneratorTask$(id: number, body: any): Observable<any>  {
    return this.httpClient.put('/coupon/rule/' + id + '/createGeneratorTask', body, {
      responseType: "json"
    });
  }

  public runGeneratorTask$(id: number, body: any): Observable<any>  {
    return this.httpClient.post('/coupon/rule/runGeneratorTask/' + id, body, {
      responseType: "json"
    });
  }

  public continueGeneratorTask$(id: number, body: any): Observable<any>  {
    return this.httpClient.post('/coupon/rule/continueGeneratorTask/' + id, body, {
      responseType: "json"
    });
  }

  public deleteGeneratorTask$(id: number, body: any): Observable<any>  {
    return this.httpClient.post('/coupon/rule/deleteGeneratorTask/' + id, body, {
      responseType: "json"
    });
  }

  public queryCouponRuleForCreationRequest$( prefix: any): Observable<any>  {
    return this.httpClient.get('/coupon/rule/isexistwithprefix/' + prefix , {
      responseType: "json"
    });
  }

  public checkBarcode$(params: any): Observable<any> {
    return this.httpClient.get('/coupon/rule/checkbarcode' , {
      responseType: "json",
      params
    });
  }

  public generate$(ruleId:any, body: any): Observable<any> {
    return this.httpClient.put('/coupon/rule/' + ruleId + '/generate', body, {
      responseType: "json"
    })
  }

  public preview$(ruleId:any, fileName:any, body: any): Observable<any> {
    return this.httpClient.post('/coupon/rule/' + ruleId + '/' + fileName + '/preview', body, {
      responseType: "json"
    })
  }

  public upload$(ruleId:any, fileName:any, body: any) {
    return this.httpClient.put('/coupon/rule/' + ruleId + '/' + fileName + '/upload', body, {
      responseType: "json"
    })
  }

  public addCoupons$(ruleId:any, fileName:any, body: any) {
    return this.httpClient.post('/coupon/rule/' + ruleId + '/' + fileName + '/add', body, {
      responseType: "json"
    })
  }

}

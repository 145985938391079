<anchor-menu
  [anchorList]="anchorList"
>

    <div class="fixed-tool-panel ">

      <div class="row justify-content-between align-items-center">
        <div class="col-auto" *ngIf="isCompletenessInProgress">

          <i class="fa fa-spin fa-spinner m-r-xs text-danger"></i>

          <span class="text-danger-dker"
                [innerText]="completenessMessage"
                data-testid="communication.completenessState"></span>

        </div>

        <div class="col p-r">
          <ng-container *ngIf="isCompletenessEnded">

            <button type="button"
                    class="btn btn-sm btn-default pull-right"

                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.cancel)"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.cancel)"

                    (click)="clickChangeState(COMMUNICATION_CAMPAIGN_ACTIONS.cancel)"

                    [triggers]="'mouseenter:mouseleave'"
                    [placement]="'bottom'"
                    [ngbPopover]="'Отменить'"

                    data-testid="communication.cancel()"
            >
              <i class="fa fa-undo"></i>
            </button>

            <button type="button"
                    class="btn btn-sm btn-default pull-right"

                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.pause)"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.pause)"

                    (click)="clickChangeState(COMMUNICATION_CAMPAIGN_ACTIONS.pause)"

                    [triggers]="'mouseenter:mouseleave'"
                    [placement]="'bottom'"
                    [ngbPopover]="'Приостановить'"
                    data-testid="communication.pause()"
            >
              <i class="fa fa-pause"></i>
            </button>

            <button type="button"
                    class="btn btn-sm btn-add btn-default pull-right"

                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.completed)"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.completed)"

                    (click)="clickChangeState(COMMUNICATION_CAMPAIGN_ACTIONS.completed)"

                    [triggers]="'mouseenter:mouseleave'"
                    [placement]="'bottom'"
                    [ngbPopover]="'Завершить'"
                    data-testid="communication.completed()"
            >
              <i class="fa fa-stop"></i>
            </button>

            <button type="button"
                    class="btn btn-sm btn-success pull-right"

                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.run)"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.run)"

                    (click)="clickChangeState(COMMUNICATION_CAMPAIGN_ACTIONS.run)"

                    [triggers]="'mouseenter:mouseleave'"
                    [placement]="'bottom'"
                    [ngbPopover]="'Запустить'"
                    data-testid="communication.run()"
            >
              <i class="fa fa-play"></i>
            </button>

            <button type="button"
                    class="btn btn-sm btn-add btn-default pull-right"

                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.archive)"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.archive)"

                    (click)="clickChangeState(COMMUNICATION_CAMPAIGN_ACTIONS.archive)"

                    data-testid="communication.archive()">
                <i class="fa fa-archive"></i>
                В архив
            </button>

            <button type="button"
                    class="btn btn-sm btn-success btn-addon pull-right"
                    data-testid="communication.edit()"
                    *ngIf="isEnabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.edit) && !statesChangerService?.isActive"
                    [disabled]="isDisabledButton(COMMUNICATION_CAMPAIGN_ACTIONS.edit)"

                    (click)="switchToEdit()"
            >

              <i class=" fa fa-pencil">
              </i>
              <span class="btn-name m-l-xs hidden-xxs">
                 {{ 'interface.buttons.edit' | transloco }}
              </span>
            </button>


            <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
                    *ngIf="statesChangerService.hasButton('save')"
                    (click)="statesChangerService.click('save')"
                    [disabled]="campaignForm?.invalid"

                    data-testid="communication.save()"
            >
              <i class=" fa fa-check text-primary">
              </i>
              <span class="btn-name m-l-xs hidden-xxs">
                {{ 'interface.buttons.save' | transloco }}
              </span>
            </button>

            <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
                    *ngIf="statesChangerService.hasButton('cancel')"
                    (click)="statesChangerService.click('cancel')"

                    data-testid="communication.cancel()"
            >

              <i class=" fa fa-remove text-danger">
              </i>
              <span class="btn-name m-l-xs hidden-xxs">
                {{ 'interface.buttons.cancel' | transloco }}
              </span>
            </button>

          </ng-container>

          <button type="button" class="btn  btn-success btn-sm btn-addon pull-right"
                  *ngIf="statesChangerService.state === 'view'"
                  uiSref="^.index"
                  data-testid="communication.back()"
          >

            <i class="fa fa-arrow-left"></i>
            <span class="btn-name hidden-xxs">
                   {{ 'interface.buttons.to_list' | transloco }}
            </span>
          </button>
        </div>

      </div>
    </div>

    <form name="campaignForm" #campaignForm="ngForm">

      <div
        *ngIf="!loader.isLoading('loading') && communicationItem"
      >

        <communication-edit
          *ngIf="!!communicationItem && !!statesChangerService?.isActive"
          [communicationItem]="communicationItem"
          (anchorList)="setAnchorList($event)"
        ></communication-edit>

        <div class="campaign-view-page_container">

          <div class="clear"></div>
          <communication-view
            *ngIf="!!communicationItem && !statesChangerService?.isActive"
            [communicationItem]="communicationItem"
            (anchorList)="setAnchorList($event)"
          ></communication-view>

          <div class="clear"></div>
        </div>

      </div>

      <ng-container
        *ngIf="loader.isLoading('loading')"
      >
        <div class="m-t-xxl p-t-xxl w-full text-center">
          <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
        </div>
      </ng-container>

    </form>

</anchor-menu>






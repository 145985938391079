import {Directive, Input, forwardRef, ElementRef, Renderer2, OnChanges, SimpleChanges} from '@angular/core'
import {NG_VALIDATORS, Validator, AbstractControl, Validators, NgModel} from '@angular/forms'
import {formatDate} from "@angular/common";
import {FORMATTER_FIELD} from "component/input/helpers/InputErrorFormatter";



@Directive({
  selector: '[customValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomValidatorDirective,
    multi: true
  }]
})
export class CustomValidatorDirective implements Validator, OnChanges {

  constructor(
  ) {
  }

  @Input() customValidator: any;
  @Input() customValidatorUpdater: any;

  private control: AbstractControl;

  validate(control: AbstractControl) : {[key: string]: any} | null {
    this.control = control;
    if ( this.customValidator === false )
      return;

    if ( !Array.isArray(this.customValidator) ) {
      this.customValidator = [this.customValidator];
    }

    if ( this.customValidator.some( validator => !validator( control.value )) ) {
      return { 'customValidator': true };
    }

    return null;

  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes['customValidatorUpdater'] && !changes['customValidatorUpdater'].firstChange ) {
      setTimeout(() => {
        this.control?.updateValueAndValidity();
      });
    }
  }


}



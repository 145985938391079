'use strict';

import module from 'frontendModule';

import 'ng-infinite-scroll';
import 'angular-footable';

import 'model/CouponRule';
import {UserSettingsStorage} from "class/user-settings-storage.service";
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.coupons.rules.index2';
module.requires = module.requires.concat([
    ,
    'infinite-scroll',
    'ui.footable'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )

                .template(   () => import( /* webpackChunkName: "assets/pages/coupons.rules.index" */ "./views/index.html"  ))
                .controller( () => import( /* webpackChunkName: "assets/pages/coupons.rules.index" */ "./controllers"       ))

                .breadcrumbs( [ "Купоны", "Купонные правила" ] )

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],

                })

                .resolve('ruleStates', ['ngCurrencyL10n', 'CouponRule', '$timeout', function (ngCurrencyL10n, CouponRule, $timeout) {
                    let _t = new CouponRule();
                    return ngCurrencyL10n.getShortName()
                        .then(() => $timeout() )
                        .then(() => _t.getField('couponRule.state','values') );

                }]  )

                .resolve('filterSettings', ['ngCurrencyL10n', 'CouponRule', '$timeout', function (ngCurrencyL10n, CouponRule, $timeout) {
                    return UserSettingsStorage
                        .get('coupons.rules.index.filter')
                        .then( ( val : any ) => {
                            try {
                                return JSON.parse(val);
                            } catch(e) {
                                return {
                                    'filter': { states : ['draft', 'active'] }
                                };
                            }
                        }, () => ({
                                'filter': { states : ['draft', 'active'] }
                            })
                        );

                }]  )



        }]);

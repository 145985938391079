
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {GoodsSelectorService, legacyDirectiveParams} from "../../goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../service/api/GoodModel/GoodModel.service";
import {CategoryModelService} from "../../../service/api/CategoryModel/CategoryModel.service";
import {GoodgroupService} from "../../../service/api/Goodgroup/Goodgroup.service";

@Component({
  selector: 'filter-goods',
  templateUrl: './filter-goods.component.html',
  providers: [
    GoodsSelectorService,
    GoodModelService
  ]
})
export class  FilterGoodsComponent implements OnInit, OnChanges {

  @Input() public model;
  @Output() public modelChange = new EventEmitter();

  @Input() public partnerId;
  @Output() public partnerIdChange  = new EventEmitter();
            private changedPartnerId;

  @Input() public showCategories;
  @Input() public max;

  @Input() public initType;
  @Input() public initList;

  public isPending = false;
  public type = '';
  public list = [];

  get hasSelectedGoods() {
    return !!this.countSelected;
  }

  get countSelected() {
    return Array.isArray(this.list) ? this.list?.length : 0;
  }

  constructor(
    private goodsSelectorService: GoodsSelectorService,
    private goodModelService: GoodModelService,
    private categoryModelService: CategoryModelService,
    private goodgroupService: GoodgroupService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (
      changes['partnerId'] &&
      !!changes['partnerId']?.currentValue &&
      !!this.changedPartnerId &&
      changes['partnerId']?.currentValue !== this.changedPartnerId
    ) {
      this.clear();
    }

    if ( !changes['initType']?.previousValue && !!changes['initType']?.currentValue ) {
      this.changeInitType(changes['initType'].currentValue);
    }

    if ( !changes['initList']?.previousValue && !!changes['initList']?.currentValue ) {
      this.changeInitList(changes['initList'].currentValue);
    }

  }

  openDialog() {
    this.goodsSelectorService.open(this.getParams())
      .then( this.changeResult.bind(this), () => {})
  }

  private getParams() {

    let options: legacyDirectiveParams = <any>{};

    if (this.partnerId) {
      options.initPartner = this.partnerId;
    }

    if (this.showCategories) {
      options.showCategories = this.showCategories;
    }

    if (!!this.max) {
      options.max = this.max;
    }

    return options;
  }

  public changeResult(result) {

    this.partnerId = result.partnerId;
    this.changedPartnerId = result.partnerId;
    this.partnerIdChange.emit( this.partnerId );

    this.list = result.list;
    this.type = result.type;

    this.emitModel();

  }

  private emitModel() {

    this.modelChange.emit(
      !this.list?.length ?
        undefined :
        {
          list: this.list.map(i => ({...i, partnerId: this.partnerId})),
          type: this.type,
          partnerId: this.partnerId
        });

  }

  public getName(item) {
    return typeof  item === "object" ? item?.label : item;
  }

  public removeItem(item) {
    this.list = this.list.filter( i => i !== item);
    this.emitModel();
  }

  public clear() {
    this.list = [];
    this.emitModel();
  }

  private changeInitType(type) {
    this.type = type;
  }

  private changeInitList(list) {

    if (!Array.isArray(list)) {
      return
    }

    let checkArray = list.slice();

    const hasUniqIds = this.list.some( item => {
      if (!checkArray.includes(item.id))
        return true;

      checkArray = checkArray.filter( i => i !== item.id);
      return false;
    })

    if ( checkArray.length || hasUniqIds ) {
      this.populateNewList(list);
    }

  }

  private populateNewList(newList) {

    const labelPrefix = {
      'sku': 'Товар с ID: ',
      'category': 'Категория с ID: ',
      'custom': 'Список с ID: ',
    }

    this.list = newList.map( id => ({id, label: (labelPrefix[this.type] || '') + id, description: ''}) );

    switch (this.type) {
      case 'sku':
        newList.map( id => {
          this.goodModelService.get$(id, this.partnerId).subscribe( res => {
            let finded = this.list.find( item => item.id === id);
            finded.label = res.name;
            finded.description = '';
          }, error =>  error?.stopPopupError())
        });
        break;
      case 'category':
        newList.map( id => {
          this.categoryModelService.get$(id, this.partnerId).subscribe( res => {
            let finded = this.list.find( item => item.id === id);
            finded.label = res.name;
            finded.description = '';
          }, error =>  error?.stopPopupError())
        });
        break;
      case 'custom':
        newList.map( id => {
          this.goodgroupService.getGoodgroup$(id, this.partnerId?.toString()).subscribe( (res:any) => {
            let finded = this.list.find( item => item.id === id);
            finded.label = res.name;
            finded.description = '';
          }, error =>  error?.stopPopupError())
        });
      break;
    }

  }

}

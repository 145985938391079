import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {
  COUPON_ISSUING_TYPES_NAMES,
  COUPON_KIND_TYPES_NAMES,
} from "../../../../../shared/service/api/Coupon/Coupon.values";
import {
  COUPON_APPLY_NAMES,
  COUPON_KIND_NAMES,
  COUPON_RULE_STATE_COLORS,
  COUPON_RULE_STATE_NAMES,
  COUPON_RULE_STATES,
  CUSTOM_PERIOD_NAMES,
  CUSTOM_PERIOD_TYPE_NAMES,
  ISSUE_SETTINGS_CAN_BE_SOLD,
  ISSUE_SETTINGS_CAN_BE_SOLD_NAMES,
  ISSUE_SETTINGS_START_SCHEDULED_NAMES,
  ISSUING_TYPE_NAMES,
  MAX_REWARD_LIMIT_MEASURE_TYPE_NAMES,
  MAX_REWARD_LIMIT_MEASURE_TYPES,
  MAX_REWARD_LIMIT_MEASURE_VALUES, MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE,
  MAX_REWARD_LIMIT_SUBTYPES,
  MAX_REWARD_LIMIT_SUBTYPES_NAMES,
  MAX_REWARD_LIMIT_TYPES_SPLIT,
  REWARD_IGNORE_LIMIT_TYPES_NAMES,
  REWARD_MEASURE_RELATION,
  REWARD_MEASURES_TYPES,
  REWARD_MEASURES_TYPES_VALUES,
  REWARD_ON_GOOD_ITEMS_SORTING_NAMES,
  REWARD_ON_GOOD_ITEMS_SORTING_TYPES,
  REWARD_ONGOOD_REWTYPE_TYPES,
  REWARD_ONGOOD_REWTYPE_TYPES_NAMES,
  REWARD_REWTYPE_TYPES,
  REWARD_REWTYPE_TYPES_NAMES,
  REWARD_WHAT_TYPES,
  REWARD_WHAT_TYPES_NAMES,
  START_PERIOD_NAMES,
  STOP_PERIOD_NAMES,
  VALID_DAYS,
  VALID_DAYS_NAMES
} from "../../../../../shared/service/api/CouponRule/CouponRule.values";
import {
  CustomPeriodTypes,
  IssuingType,
  StartPeriodTypes,
  StopPeriodTypes
} from "../../../../../shared/service/api/CouponRule/IssuingTypes/IssuingType";
import {CouponKind} from "../../../../../shared/service/api/CouponRule/couponKinds/CouponKind";
import {timeWords} from "../class/utils.class";
import {daysOfWeekDict} from "../../../../campaign/campaigns/shared/values/campaign.values";
import {ngCurrencyL10nService} from "../../../../../shared/service/ngCurrencyL10n/ngCurrencyL10n.service";

export class CouponRuleRootClass  {

  public couponRuleItem: any;

  public loader = new LoaderHandlerClass();

  public COUPON_RULE_STATES = COUPON_RULE_STATES;
  public COUPON_RULE_STATE_NAMES = COUPON_RULE_STATE_NAMES;

  public COUPON_KIND_TYPES_NAMES = COUPON_KIND_TYPES_NAMES;
  public COUPON_ISSUING_TYPES_NAMES = COUPON_ISSUING_TYPES_NAMES;

  public COUPON_RULE_STATE_COLORS = COUPON_RULE_STATE_COLORS;

  public CUSTOM_PERIOD_NAMES  = CUSTOM_PERIOD_NAMES;
  public CUSTOM_PERIOD_TYPE_NAMES  = CUSTOM_PERIOD_TYPE_NAMES;
  public CustomPeriodTypes = CustomPeriodTypes;

  public COUPON_APPLY_NAMES = COUPON_APPLY_NAMES;

  public MAX_REWARD_LIMIT_SUBTYPES = MAX_REWARD_LIMIT_SUBTYPES;
  public MAX_REWARD_LIMIT_SUBTYPES_NAMES = MAX_REWARD_LIMIT_SUBTYPES_NAMES;
  public MAX_REWARD_LIMIT_MEASURE_TYPE_NAMES = MAX_REWARD_LIMIT_MEASURE_TYPE_NAMES;

  public MAX_REWARD_LIMIT_MEASURE_VALUES = Object.assign({}, MAX_REWARD_LIMIT_MEASURE_VALUES);
  public MAX_REWARD_LIMIT_MEASURE_TYPES = MAX_REWARD_LIMIT_MEASURE_TYPES;
  public MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE = MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE;

  public REWARD_MEASURES_TYPES = REWARD_MEASURES_TYPES;
  public REWARD_MEASURES_TYPES_VALUES = Object.assign({}, REWARD_MEASURES_TYPES_VALUES);

  public REWARD_IGNORE_LIMIT_TYPES_NAMES = REWARD_IGNORE_LIMIT_TYPES_NAMES;

  public REWARD_REWTYPE_TYPES = REWARD_REWTYPE_TYPES;
  public REWARD_REWTYPE_TYPES_NAMES = REWARD_REWTYPE_TYPES_NAMES;

  public REWARD_ONGOOD_REWTYPE_TYPES = REWARD_ONGOOD_REWTYPE_TYPES;
  public REWARD_ONGOOD_REWTYPE_TYPES_NAMES = REWARD_ONGOOD_REWTYPE_TYPES_NAMES;


  public StartPeriodTypes = StartPeriodTypes;
  public StopPeriodTypes = StopPeriodTypes;

  public START_PERIOD_NAMES = START_PERIOD_NAMES;
  public STOP_PERIOD_NAMES = STOP_PERIOD_NAMES;

  public ISSUE_SETTINGS_START_SCHEDULED_NAMES = ISSUE_SETTINGS_START_SCHEDULED_NAMES;
  public ISSUE_SETTINGS_STOP_SCHEDULED_NAMES = ISSUE_SETTINGS_START_SCHEDULED_NAMES;

  public ISSUING_TYPE_NAMES = ISSUING_TYPE_NAMES;
  public COUPON_KIND_NAMES = COUPON_KIND_NAMES;

  public ISSUE_SETTINGS_CAN_BE_SOLD = ISSUE_SETTINGS_CAN_BE_SOLD;
  public ISSUE_SETTINGS_CAN_BE_SOLD_NAMES = Object.assign({}, ISSUE_SETTINGS_CAN_BE_SOLD_NAMES);

  public REWARD_WHAT_TYPES = REWARD_WHAT_TYPES;
  public REWARD_WHAT_TYPES_NAMES = REWARD_WHAT_TYPES_NAMES;

  public REWARD_MEASURE_RELATION = REWARD_MEASURE_RELATION;

  public REWARD_ON_GOOD_ITEMS_SORTING_TYPES = REWARD_ON_GOOD_ITEMS_SORTING_TYPES;
  public REWARD_ON_GOOD_ITEMS_SORTING_NAMES = REWARD_ON_GOOD_ITEMS_SORTING_NAMES;


  public daysOfWeekDict = daysOfWeekDict;
  public timeWords = timeWords;

  public VALID_DAYS = VALID_DAYS;
  public VALID_DAYS_NAMES = VALID_DAYS_NAMES;

  public currentIssueType: IssuingType;
  public currentCouponKind: CouponKind;

  public settingsObj:any = {}

  public hasRuleImportedCoupons = false;

  private keyNameStorage = new Map();

  constructor(
    public ngCurrencyL10nService : ngCurrencyL10nService,
  ) {
    ngCurrencyL10nService.getShortName().then( (fullName: string) => {
      this.MAX_REWARD_LIMIT_MEASURE_VALUES[MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE] = fullName;
      this.MAX_REWARD_LIMIT_MEASURE_VALUES[MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT + MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE] = fullName;

      this.REWARD_MEASURES_TYPES_VALUES[REWARD_MEASURES_TYPES.MONEY] = fullName;
      this.ISSUE_SETTINGS_CAN_BE_SOLD_NAMES[ISSUE_SETTINGS_CAN_BE_SOLD.CURRENCY] = fullName;
    })
  }

  get isStartScheduled() {
    return this.couponRuleItem?.couponRule?.issueSettings?.startPeriod === this.StartPeriodTypes.START_PERIOD_SCHEDULED;
  }

  get isStopScheduled() {
    return this.couponRuleItem?.couponRule?.issueSettings?.stopPeriod === this.StopPeriodTypes.STOP_PERIOD_SCHEDULED;
  }

  get usable3rdPartiesDisabled() {

    if ( [ 'virtualRuleBarcode','sticker' ].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.couponKind ) < 0 ) {
      return false;
    }

    return true;
  }

  get useCountEnabled()  {

    let newVal = [
      this.couponRuleItem?.couponRule?.reward?.reward?.what,
      this.couponRuleItem?.couponRule?.reward?.reward?.measure,
      this.couponRuleItem?.couponRule?.issueSettings?.issuingType,
      this.couponRuleItem?.couponRule?.issueSettings?.couponKind,
      this.couponRuleItem?.couponRule?.applySettings?.couponApply,
      this.couponRuleItem?.couponRule?.reward.onGood?.rewType,
    ];

    if ( ( !newVal[0] || !newVal[1] ) && newVal[2] !== 'public' ) {
      return false;
    }

    if (  newVal[3] === 'sticker' || newVal[3] === 'couponWithBarcodeWithReceptParams' ) {
      return false;

    }

    if ( newVal[0] === "gift" )
      if ( this.couponRuleItem.couponRule.applySettings.couponApply === "applyInFirstSuitedCheck" ) {
        //
      } else {
        return true;
      }

    switch (true) {

      case  newVal[5] === 'count' :
      case  newVal[3] === 'virtualRuleBarcode' :
      case  newVal[1] === 'money' || newVal[1] === 'count' || newVal[2] === 'public':
        return false;

      case  newVal[1] === 'percent' :
        return true;

    }

    return false;

  }

  get showMaxRewardValues() {

    if (this.couponRuleItem.couponRule.issueSettings.issuingType === 'public' &&
      (this.couponRuleItem.couponRule.issueSettings.couponKind === 'couponRuleBarcode' || this.couponRuleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode'))
      return true

    return this.couponRuleItem.couponRule.issueSettings.issuingType !== 'public' &&
      !( this.couponRuleItem.couponRule.issueSettings.issuingType === 'pos' &&
        this.couponRuleItem.couponRule.issueSettings.couponKind === 'couponWithoutBarcode' )

  }

  get maxRewardLimitValues() {

    if ( !this?.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit )
      return {};

    return MAX_REWARD_LIMIT_TYPES_SPLIT[this?.couponRuleItem?.couponRule?.applySettings?.maxRewardLimit] || {};

  }

  get isCouponGeneratorRule() {
    return this.couponRuleItem?.couponRule?.issueSettings?.issuingType === 'uniqueCouponGen'
  };

  public isBarcodeGenerated = (barcode) => typeof this.couponRuleItem.couponRule.id !== "undefined" && ( barcode && barcode.indexOf('22')=== 0 );
}

import {Injectable} from "@angular/core";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {of, throwError} from "rxjs";
import {CardApiService} from "../../../../api/CardApi/services/card-api.service";
import {RawCard} from "../../../../api/CardApi/models/raw-card";
import CreateCardParams = CardApiService.CreateCardParams;
import {Card} from "../../../../api/CardApi/models/card";
import {CARD_STATES} from "./Card.values";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CardService {

  constructor(
    private cardApiService:CardApiService,
  ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: 'string',
      dropField  : "pagerDrop",
      limitField : "pagerLimit",
      limit: 100
    },
    search: {
      field: "sentence"
    },
    sort: {
      type: 'string',
      sortField  : 'sortingField',
      orderField : 'sortingOrder'
    },
    filters: [
      {
        field: 'filterStates',
        id: 'states',
        currentValue: undefined
      },
      {
        field: 'filterClientId',
        id: 'clientId',
        currentValue: undefined
      },
      {
        field: 'usePrefix',
        id: 'usePrefix',
        currentValue: undefined
      },
    ]
  });

  public query$(params: CardApiService.QueryCardsParams ) {

    params = Object.assign({}, params);

    if ( Array.isArray(params.filterStates) ) {
      params.filterStates = <any>[params.filterStates.join(',')];
    }

    if (!params.filterStates?.length || !params.filterStates[0])
      return of([]);

    if ( !!(<any>params).sentence )
      return  this.search$({
        drop: params.pagerDrop,
        limit: params.pagerLimit,
        sentence: (<any>params).sentence,
        state: (<any>params).filterStates,
        usePrefix: (<any>params).usePrefix,
      })

    return this.cardApiService.queryCards(params)
  }

  public search$(params: CardApiService.SearchCardsParams ) {
    return this.cardApiService.searchCards(params)
  }

  getOrCreate$(id?) {

    if (typeof id !== "undefined" && id !=="") {
      return this.get$(id);
    }

    return of(<Card>{
      state: CARD_STATES.nonactive
    });

  }

  public get$(id) {
    return this.cardApiService.getCard(id)
  }

  public getForClient$(clientId) {
    return this.cardApiService.queryCards({
      filterClientId: clientId
    })
  }

  public updateStatus$( cardOrId: Card | number, state = "") {
    return this.cardApiService.changeCardStatus(<any>{
      cardId:  (typeof  cardOrId !== "number") ? cardOrId.id : cardOrId,
      status: (typeof  cardOrId !== "number") ?  (<Card>cardOrId)?.state : state
    })
      .pipe(
        switchMap(() => this.get$((typeof  cardOrId !== "number") ? cardOrId.id : cardOrId) )
      )
  }

  public create$( card: CreateCardParams['card'], prefix?: CreateCardParams['prefix'],  generator?: CreateCardParams['generator'] ) {
    return this.cardApiService.createCard({
      card,
      generator,
      prefix
    });
  }

  public delete$( id: number) {
    return this.cardApiService.deleteCard(id)
  }

  public createOrUpdate$( card: Card ) {
    if ( typeof card?.id !== "undefined")
      return this.updateStatus$(card)
    else
      return this.create$(card)
  }
}

export const timeWords = function( type, value ) {

  var types = {

    'day'   : [ "день"  , "дня"   , "дней" ],
    'week'  : [ "неделя", "недели" , "недель" ],
    'month' : [ "месяц" , "месяца" , "месяцев" ],
    'year'  : [ "год"   , "года"   , "лет" ]

  };

  if (!value && value !== 0 ) {
    return types[type][0];
  }

  switch (true) {

    case ["11","12","13","14","15","16","17","18","19"].indexOf( value.toString().slice(-2) ) >= 0: return types[type][2];

    case ["0","5","6","7","8","9"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][2];

    case ["1"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][0];

    case ["2", "3", "4"].indexOf( value.toString().slice(-1) ) >=0  : return types[type][1];

  }

};

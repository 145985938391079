'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';
import {RoleType} from "module/permissions/conf/roles";

import 'ng-infinite-scroll';
import 'ui-select';
import 'angular-footable';

import ckeditor_loader from 'agModules/ckeditor';

let routePath = module.name + '.coupons.coupons.view2';
module.requires = module.requires.concat([
    ,
    'infinite-scroll',
    'ui.footable',
    'ui.select'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id/:clientId')

                .template(template)
                .controller(controller)

                .resolve('$ckePrepare', ckeditor_loader)
                .resolve('couponRulesList',   ['CouponRule', function (CouponRule) {

                    var params = {};
                    params['filter.states'] = 'active';
                    params['filter.issuingType'] = 'manual';
                    // При переходе на новый ангуляр вынести в норм компонент
                    params['pager.drop'] = 0;
                    params['pager.limit'] = 999999;

                    return CouponRule.query(params).$promise;
                }])

                .resolve('rawValues', ['Coupon', 'couponRulesList', function (Coupon, couponRulesList) {

                    var _t = new Coupon();
                    var rawValues: any  = {};

                    angular.forEach(_t.$fields, function (item, key) {

                        if (!angular.isUndefined(item.values)) {
                            rawValues[key] = angular.copy(item.values);
                        }

                    });

                    rawValues.couponRuleId = couponRulesList;

                    return rawValues;

                }])

                .resolve('partnerList', ['PartnerModel', function (PartnerModel) {

                    return PartnerModel.query().$promise.then(function( data ){
                        var result = {};

                        angular.forEach(data, function(item) {
                            result[item.id] = item;
                        });

                        data = null;

                        return result;
                    });

                }] )

                .resolve('couponItem',  ['$stateParams', 'Coupon', '$q',
                    function ($stateParams, Coupon, $q ) {

                        if (!$stateParams.id && $stateParams.id !== 0) {

                            var result = new Coupon({
                                state: 'active',
                                barcode: '000',
                                couponType: 'award',
                                couponKind: 'eCoupon',
                                clientId:  parseInt( $stateParams.clientId ) || undefined
                            });

                            return $q.resolve(result);

                        }

                        return Coupon.get($stateParams).$promise;
                    }])
                .resolve('couponIssueInfo',  ['$stateParams', 'Coupon', '$q', 'ClientModel', 'ClientTransactionsModel',
                  function ($stateParams, Coupon, $q, ClientModel, ClientTransactionsModel ) {

                    if (!$stateParams.id && $stateParams.id !== 0) {

                      return $q.resolve({});

                    }

                    return Coupon.getCouponIssueInfo({id: $stateParams.id })
                      .$promise
                      .then( result => {

                          if ( typeof result.checkId === "undefined" ) {
                            return false;
                          }

                          return result
                        /*$q.all([
                            ClientTransactionsModel.findCheckById({checkId: result.checkId}).$promise.then(res => Array.isArray(res) && res.length ? res[0] : res, () => {}),
                            ClientModel.get({id: result.clientId}).$promise.catch( () => {} ),
                            LocationModel.getLocationByById({id: result.locationId}).$promise.catch( () => {} )
                          ]).then( result => ({
                            check    : result[0],
                            client   : result[1],
                            location : result[2],
                          })
                          )*/

                      }, e => false)

                  }])

                .resolve('clientItem', ['ClientModel', 'CardModel', 'couponItem', '$q', function ( ClientModel, CardModel, couponItem, $q ) {

                    if ( angular.isUndefined(couponItem.clientId) ) {
                        return $q.resolve();
                    }

                    return ClientModel.get({ id: couponItem.clientId })
                        .$promise
                        .then( function( data ) {
                            var params = {};
                            params['filter.clientId'] = couponItem.clientId;
                            data.cards = CardModel.queryCards(params);
                            return data;
                        });

                }] )

                .permissions( {
                    only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter],

                })

                .breadcrumbs( ["Купоны", "Купоны " , "Инфо о Купоне"] );

        }]);

export const MECHANIC_EVENTS = [
/*

  { id:'daysfromregistry', name:'Первая покупка, выполненная новым клиентом', tpl:"event/client/daysfromregistry", icon: 'male',  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'daysfromlast', name:'Кол-во дней с момента последней покупки', tpl:"event/client/daysfromlast" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent' , categoryId: 'client'},

  { id:'maxcheck', name:'Максимальная покупка выполненная клиентом', tpl:"event/client/maxcheck" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'total', name:'Сумма покупок в текущем периоде', tpl:"event/client/total" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id: 'lifevalue', name: 'Общая сумма покупок клиента (CLV)', tpl: "event/client/lifevalue" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'profilestate', name:'Статус регистрации', tpl:"event/client/profilestate" , icon: 'male',  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'availablechannel', name:'Доступные коммуникации', tpl:"event/client/availablechannel" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'disablechannel', name:'Недоступные коммуникации', tpl:"event/client/disablechannel" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'afterfullregistration', name:'Давность полной регистрации', tpl:"event/client/afterfullregistration", icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'afterexpressregistration', name:'Давность экспресс регистрации', tpl:"event/client/afterexpressregistration" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},

  { id:'totalPerSeason', name:'Сумма товаров со скидкой за сезон', tpl:"event/client/totalperseason" , icon: 'male' ,  group: 'События для клиента' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},
  { id:'birthday', name:'Покупка в интервале дня рождения',         tpl:"event/client/birthday" , icon: 'rub' ,  group: 'Общие события для покупки' , fieldsForSave: [ 'clientEvent', 'goodList'  ], eventField: 'clientEvent', categoryId: 'client'},

*/

  { id:'common', name:'Наличие в чеке товара из списка', tpl:"event/goods/common" , icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent', categoryId: 'goods'},
  { id: 'listQuantity', name: 'Наличие товаров из списка в указанном кол-ве', tpl: "event/goods/listQuantity", icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent', categoryId: 'goods'},
  /*{ id:'uniq', name:'Наличие в чеке количества уникальных SKU', tpl:"event/goods/quantity" , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent' },*/
  { id:'commonQuantity', name:'Наличие в чеке суммарного количества товаров', tpl:"event/goods/quantity", icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent' , categoryId: 'goods'},
  { id:'commonAmount', name: 'Наличие в чеке товаров на сумму', tpl: "event/goods/quantity_total" , icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent' , categoryId: 'goods'},
  { id: 'list', name: 'Наличие в чеке заданного набора товаров', tpl: "event/goods/list" , icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent' , categoryId: 'goods'},
  { id: 'uniq', name: 'Наличие в чеке количества уникальных SKU', tpl: "event/goods/uniq" , icon: 'tag' ,  group: 'События для товаров в покупке' , fieldsForSave: [ 'goodEvent', 'goodList' ], eventField: 'goodEvent' , categoryId: 'goods'},
/*

  { id: 'total', name: 'Сумма покупки', tpl: "event/total/total", icon: 'rub' ,  group: 'Общие события для покупки' , fieldsForSave: [ 'totalEvent' ], eventField: 'totalEvent' , categoryId: 'total'},
  { id: 'firstPurchase', name: 'Первая покупка, выполненная новым клиентом', tpl: "event/client/firstPurchase" , icon: 'rub' ,  group: 'Общие события для покупки' , fieldsForSave: [ 'totalEvent' ], eventField: 'totalEvent' , categoryId: 'total'},
  { id: 'maxPurchase', name: 'Максимальная покупка, выполненная клиентом', tpl: "event/client/maxPurchase" , icon: 'rub' ,  group: 'Общие события для покупки' , fieldsForSave: [ 'totalEvent' ], eventField: 'totalEvent' , categoryId: 'total'},
*/

];

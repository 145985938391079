import {Component, OnInit, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import {CashBoxEvents, CashBoxService} from "../../service/cash-box.service";
import {GoodsSelectorService} from "../../../../component/goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";
import {ClientService} from "../../../../service/api/Client/Client.service";
import {catchError, switchMap} from "rxjs/operators";
import {AccountService} from "../../../../service/api/Account/Account.service";
import {SegmentationService} from "../../../../service/api/Segment/Segment.service";
import {forkJoin, of} from "rxjs";
import {CreditAccountService} from "../../../../service/api/CreditAccount/CreditAccount.service";
import {CardService} from "../../../../service/api/Card/Card.service";

@Component({
  selector: 'cash-box-client',
  templateUrl: "./cash-box-client.component.html",
  styleUrls: ['./cash-box-client.component.scss'],
  providers: [
    ClientService,
    SegmentationService,
    AccountService,
    CreditAccountService,
    CardService
  ]
})
 export class CashBoxClientComponent implements OnInit, OnChanges, OnDestroy {

  public searchText: string = '';
  public clientList;

  public account;
  public creditAccount;
  public cards;
  public segment;
  public client;

  private unsubscribers = {
    client: null
  }

  public isLoading = false;

  get isTmpClient() {
    return this.client && this.client?.client?.id !== this.cashBoxService?.currentCheck?.client?.id;
  }

  get canAddToCheck() {
    return this.client && ( this.cards?.length || this.client?.client?.phone );
  }

  get CLV() {
    if (!this.client)
      return -1;

    const behaviorID = Object.keys(this.client?.profiles).find(i => i.toLowerCase().startsWith('behavior'));
    return typeof this.client?.profiles?.[behaviorID]?.behavior?.total?.lifeValue !== undefined ?
                  this.client?.profiles?.[behaviorID]?.behavior?.total?.lifeValue : -1;
  }

  get discount() {
    if (!this.client)
      return -1;

    const promotionID = Object.keys(this.client?.profiles).find(i => i.toLowerCase().startsWith('promotion'));
    return typeof this.client?.profiles?.[promotionID]?.promotion?.discount !== undefined ?
      this.client?.profiles?.[promotionID]?.behavior?.promotion?.discount : -1;
  }

  constructor(
      public cashBoxService : CashBoxService,
      public clientService  : ClientService,
      public accountService  : AccountService,
      public creditAccountService  : CreditAccountService,
      public segmentationService  : SegmentationService,
      public cardService  : CardService,
  )
  {
  }

  ngOnInit() {
    this.subscribeOnEvents();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {

  }

  subscribeOnEvents() {
    const that= this;
    let startWithClient = false;
    this.cashBoxService.events.subscribe( (event) => {

      if ( [CashBoxEvents.RESET, CashBoxEvents.CREATE].indexOf(event.type) >=0 ) {
        this.removeClient();
      }

      if ( [CashBoxEvents.PRECHECK_START].indexOf(event.type) >=0 ) {
        startWithClient = !!event?.value?.client || !!event?.value?.cardNumber;
      }

      if ( [CashBoxEvents.PRECHECK_END].indexOf(event.type) >=0 ) {

        if ( ( !!startWithClient || !!this.client ) && !event?.value?.client ) {
          that.removeClient();
        }

        startWithClient = false;
      }

    });
  }

  onSearchChange(newValue: string) {
    this.searchText = newValue;

    if (!this.searchText) {
      this.client = undefined;
      this.account = undefined;
      this.cards = undefined;
      this.creditAccount = undefined;

      this.clientList = undefined;
      this.cashBoxService.addClient(false);
      return;
    }

    this.isLoading = true;

    this.clientService.search$({
      drop: 0,
      limit: 10,
      sentence: this.searchText,
    }).subscribe({
      next: (data) => {
        this.clientList = data;
        this.isLoading = false;
      },
      error: (err) => {
        err?.stopPopupError();
        this.isLoading = false;
      }
    })
  }

  async selectClient(client: any) {
    if (!client?.client?.id)
      return;

    this.isLoading = true;

    const result = await this.cashBoxService.addClient(
        client?.client?.id,
        client?.cards?.filter( i => i.state === 'active').map(i => i.number),
        client?.client?.phone
    )

    if (result)
      await this.prepareClient(client?.client?.id);

    this.isLoading = false;
  }

  async prepareClient(clientId) {

    if (!clientId)
      return;

    this?.unsubscribers?.client?.unsubscribe();

    await new Promise( r =>
      this.unsubscribers.client = this.getClientService(clientId)
        .subscribe( (data) => {

            this.client = data?.client;
            this.account = data?.account;
            this.segment = data?.segment;
            this.creditAccount = data?.creditAccount;
            this.cards = data?.cards?.filter( i => i.card.state === 'active')

            this.clearSearch();
            r();
        }, () => r(false))
    );

  }

  private getClientService(clientId) {

    const stopError = (err: any) => {
      err?.stopPopupError();
      return of(undefined);
    }


    return this.clientService
      .getClient$(clientId)
      .pipe(
        switchMap( (client: any ) =>
          forkJoin({
            client: of(client),
            account: client?.client?.accountId >=0 ? this.accountService.get$(client?.client?.accountId).pipe(catchError(stopError)) : of(undefined),
            creditAccount: client?.client?.accountId >=0 ? this.creditAccountService.get$(client?.client?.accountId).pipe(catchError(stopError)) : of(undefined),
            segment: client?.client?.segmentId >=0 ? this.segmentationService.get$(client?.client?.segmentId).pipe(catchError(stopError)) : of(undefined),
            cards:  this.cardService.getForClient$(clientId).pipe(catchError(stopError))
          })
        )
      );

  }

  private clearSearch() {
    this.searchText = '';
    this.clientList = undefined;
    this.isLoading = false;
  }

  public async removeClient() {
    this?.unsubscribers?.client?.unsubscribe();
    let res = this.cashBoxService.addClient(false);
    if (res) {
      this.clearSearch();
      this.client = undefined;
      this.account = undefined;
      this.creditAccount  = undefined;
      this.cards = undefined;
      this.segment = undefined;
    }

  }


}

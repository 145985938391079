import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {MechanicService} from "../../service/api/Mechanic/Mechanic.service";
import {pipe, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";
import {InformingTemplateService} from "../../service/api/InformingTemplate/InformingTemplate.service";
import {InformingTemplateApiService} from "../../../api/InformingTemplateApi/services/informing-template-api.service";
import {PluginInstallService} from "../../service/api/PluginInstall/PluginInstall.service";
import {MessageTemplateService} from "../../service/api/MessageTemplate/MessageTemplate.service";
import {ControlContainer, NgForm} from "@angular/forms";

@Component({
  selector: 'select-message-template',
  templateUrl: './select-message-template.component.html',
  styleUrls: ['./select-message-template.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]

})
export class SelectMessageTemplateComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();

  private initCurrentId;
  @Input() set currentId(value) {
    if (typeof this.initCurrentId === "undefined")
      this.initCurrentId = value
  }

  @Input() status: any;

  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  drop    = 0;
  limit   = 20;
  isValid = true;

  digestSubscribe;

  constructor(
    private messageTemplateService: MessageTemplateService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }

  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.validate();
  }

  pagerReset() {
    this.drop = 0;
  }

  pagerNext( searchText:string | boolean ) {

    let params : any = {
      'pager.drop'  : this.drop,
      'pager.limit' : this.limit
    }

    if (searchText) {
      params.search = searchText;
    }

    if (typeof this.initCurrentId !== "undefined" ) {
      params.selectedId = this.initCurrentId;
    }

    params.status = this.status || 'template';

    return this.messageTemplateService
                    .getList$(params)
                    .pipe(
                      tap( r =>{
                        if (this.drop === 0 && this.required && !this.id) {
                          let finded = r.find( i => i?.name?.toLowerCase() === "пустой шаблон");
                          if (finded) {
                            this.id = finded?.id;
                            setTimeout(() => this.onChange());
                          }
                        }
                        return r;
                      }),
                      tap( data => {
                        this.drop += data.length;

                        if (this.initCurrentId)
                          data?.forEach( i => {
                            if (i.id === this.initCurrentId) {
                              i.name += ' (текущая)';
                              i.$isCurrent = true;
                            }
                          })

                        return data;
                      }),
                      tap( r => {
                        this.checkDigest();
                        return r;
                      })
                    );

  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }

  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }


  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}

import {Injectable, NgZone} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CouponApiService} from "../../../../api/CouponApi/services";
import {CouponRuleQueryParam} from "../../../../api/CouponRuleApi/models/coupon-rule-query-param";
import CouponRuleFilters from "../../../../../js/models/CouponRuleModel/filters";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {catchError, exhaustMap, map, switchMap} from "rxjs/operators";
import {interval, Observable, of} from "rxjs";

import {CampaignStateGetResult} from "../../../../api/CampaignApi/models/campaign-state-get-result";
import {CAMPAIGN_COMPLETENESS} from "../Campaign/Campaign.values";
import {
  CommunicationCampaignApiService
} from "../../../../api/CommunicationCampaignApi/services/communication-campaign-api.service";
import {
  InformingCampaignQueryParam
} from "../../../../api/CommunicationCampaignApi/models/informing-campaign-query-param";
import {InformingCampaignProtocol} from "../../../../api/CommunicationCampaignApi/models/informing-campaign-protocol";
import {CHANNEL_TYPES} from "./CommunicationCampaign.values";

@Injectable()
export class CommunicationCampaignService {

  constructor(
    private httpClient: HttpClient,
    private communicationCampaignApiService: CommunicationCampaignApiService,
    private ngZone: NgZone,
  ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.ids',
        id: 'ids',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'selectedId',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.datesKind',
        id: 'datesKind',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.locations',
        id: 'locations',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.skuList',
        id: 'skuList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.categoryList',
        id: 'categoryList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupsList',
        id: 'groupsList',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public query$(params: InformingCampaignQueryParam){
    return this.communicationCampaignApiService.query(params);
  }

  public get$(id: number){
    return this.communicationCampaignApiService
      .get(id)
      .pipe(
        map(campaignItem => {
          campaignItem?.campaign?.campaignTime?.periods?.forEach( period => {

            ['startTime', 'stopTime'].forEach( id => {
              if ( /^\d\d:\d\d:\d\d$/.test(period?.[id]) ) {
                period[id] = period[id].split(':').splice(0,2).join(':')
              }
            })

          })

          return campaignItem;
        })
      )

  }

  public getOrCreate$(id?: number): Observable<InformingCampaignProtocol> {

    if (!!id || id === 0) {
      return this.get$(id);
    }

    return of(<any> {
      "campaign": {

        "campaignTime": {
          "periods": [
            {
              "startTime": "00:00",
              "stopTime": "23:59",
              "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]
            }
          ]
        },
        "state": "draft",
        "channel": CHANNEL_TYPES.BOTH
      },
      "mechanic": {
      }
    });

  }

  public create$(params: InformingCampaignProtocol){

    params?.campaign?.campaignTime?.periods?.forEach( period => {
      period.startTime = /^\d\d:\d\d$/.test(period.startTime) ? `${period.startTime}:00` : period.startTime;
      period.stopTime = /^\d\d:\d\d$/.test(period.stopTime) ? `${period.stopTime}:59` : period.stopTime;
    })

    return this.communicationCampaignApiService.create(params)
  }

  public update$( id: number, body: InformingCampaignProtocol){

    body?.campaign?.campaignTime?.periods?.forEach( period => {
      period.startTime = /^\d\d:\d\d$/.test(period.startTime) ? `${period.startTime}:00` : period.startTime;
      period.stopTime = /^\d\d:\d\d$/.test(period.stopTime) ? `${period.stopTime}:59` : period.stopTime;
    })

    return this.communicationCampaignApiService.update({
            id,
            campaignProtocol: body
         });

  }

  public createOrUpdate$(params: InformingCampaignProtocol) {
    if ( params?.campaign?.id >=0)
      return this.update$(params.campaign.id, params)
    else
      return this.create$(params);
  }

  public delete$(id: number){
    return this.communicationCampaignApiService.delete(id);
  }

  public campaignTargetInfo$(params:CommunicationCampaignApiService.TargetGroupInfoParams){

    if (params?.excludeGroupIds?.length) {
      params.excludeGroupIds = [<any>params?.excludeGroupIds?.join(',')];
    }


    return this.communicationCampaignApiService.targetGroupInfo(params)
  }

  public setState$(id: number | string, state: string) {

    return this.communicationCampaignApiService.setState({
      id: Number(id),
      state: state
    })
  }

  public getState$(id: number | string) {
    return this.communicationCampaignApiService.getState(Number(id) )
  }

  public stateUpdater$(id: number | string, period = 3 * 1000):Observable<CampaignStateGetResult> {

    let result;
    this.ngZone.runOutsideAngular(() => {
      result = interval(period)
        .pipe(
          exhaustMap( () =>
            this.getState$(id)
              .pipe(
                catchError(result => {
                  result?.stopPopupError();
                  return of(<any>{ state: undefined, completeness: CAMPAIGN_COMPLETENESS.error })
                })
              )
          ),
        )
    });

    return result;
  }

}

import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {map} from "rxjs/operators";
import {CouponRuleService} from "../../service/api/CouponRule/CouponRule.service";
import {ControlContainer, NgForm} from "@angular/forms";

@Component({
  selector: 'select-coupon-rule',
  templateUrl: './select-coupon-rule.component.html',
  styleUrls: ['./select-coupon-rule.component.scss'],
  providers: [
    CouponRuleService
  ]

})
export class SelectCouponRuleComponent
  extends InfiniteSelectClass {

  @Input() id: number;
  @Output() idChange = new EventEmitter<number>();

  @Input() addItems: Array<any> = [];

  @Input() filterMechanic: Function;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;

  drop    = 0;
  limit   = 20;
  isValid = true;

  digestSubscribe;

  constructor(
    private couponRuleService: CouponRuleService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }
  }

  digestValidate() {

    if (!this.filterMechanic)
      this.filteredItems = this.items;
    else {
      let needUpdate = false;
      let f_index = 0;
      let filteredArray = this.items.filter( (value, index) => {
        let res = this.filterMechanic(value);
        if (!res)
          return res;

        if (this.filteredItems[f_index++] !== value)
          needUpdate = true;

        return res;
      });
      this.filteredItems = needUpdate ? filteredArray : this.filteredItems;


      if (( this.filteredItems.length > 0 || (this.filteredItems.length === 0 && this.items.length )) && this.filteredItems.length < 20 ) {
        this.fetch();
      }
    }

    this.validate();
  }

  onChange() {
    this.idChange.emit(this.id);
    this.validate();
  }

  pagerReset() {
    this.drop = 0;
  }


  pagerNext( searchText:string | boolean ) {

    let params : any = {
      pager: {
        drop  : this.drop,
        limit : this.limit
      },
      filter: {
        "issuingType": ["pos", "public", "external", "manual", "uniqueCouponGen"],
        "couponKind": ["eCoupon", "couponWithBarcode", "couponWithoutBarcode", "virtualRuleBarcode", "importedCouponForPrint", "sticker", "referralCouponWithBarcode", "couponRuleBarcode", "importedCoupon", "couponWithBarcodeWithReceptParams"],
        "states": ["draft", "active"]
      },
    }


    if (searchText) {
      params.search = searchText;
    }


    return this.couponRuleService
      .query$(params)
      .pipe(
        map(data => {

          this.drop += data.length;

          this.checkDigest();

          return data.map( item => ({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    state: item.state
                  })

          );
        })
      );


  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }


  validate() {

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.id === "undefined" || this.id === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.id); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.id); break;

    }

  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}


import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {ControlContainer, NgForm, NgModel} from "@angular/forms";
import {LoaderHandlerClass} from "../../../../../shared/class/loader-handler.class";
import {CouponService} from "../../../../../shared/service/api/Coupon/Coupon.service";

@Component({
  selector: 'coupon-transactions',
  templateUrl: './coupon-transactions.component.html',
  providers: [
    CouponService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CouponTransactionsComponent  implements OnInit, OnChanges {

  @Input() id: any;

  public loader = new LoaderHandlerClass();

  transactions: any = [];

  constructor(
    private couponService: CouponService,
  ) {
  }

  ngOnInit() {
    this.transactions= [];
    this.couponService.transactionsParams.reset();
  }

  ngOnChanges(changes:SimpleChanges) {
    if (!!changes.id) {
      this.couponService.transactionsParams.setFilterValue('id', parseInt(changes.id.currentValue) );
      this.reset();
    }
  }

  public getList() {

    this.couponService
      .transactions$(this.couponService.transactionsParams.params)
      .pipe(
        this.loader.waitLoading('list'),
        this.couponService.transactionsParams.pagerPipe()
      )
      .subscribe( {
        next: (result: any) => {
          this.transactions = this.transactions.concat(result)
        }
      })

  }

  public reset() {
    this.transactions = [];
    this.couponService.transactionsParams.reset();
    this.getList();
  }

  public onScroll() {

    if ( this.loader.isLoading('list'))
      return;

    this.couponService.transactionsParams.next();
    this.getList();
  }

}

<div class="fixed-tool-panel">

  <filter-checkbox

    class="m-t-xs states-filter"
    data-testid="campaign.filter.state"

    [name]         = "'Статус'"
    [canSelectAll] = "true"
    [showClearButton] = "false"
    [inputValues]  = "CAMPAIGN_STATE_NAMES_FILTER"
    [selectedValues] = "campaignService.queryParams.getFilterValue('states')"
    (selectedValuesChange) = "campaignService.queryParams.setFilterValue(
                    'states',
                    $event
                  );
                  reset();"
  ></filter-checkbox>

  <filter-campaign-period
    class="m-t-xs m-r-sm"
    (onChange)="onFilterDate($event)"
    [modelFrom]="campaignService.queryParams.getFilterValue('fromDate')"
    [modelTo]="campaignService.queryParams.getFilterValue('tillDate')"
    [datesKind]="campaignService.queryParams.getFilterValue('datesKind')"
    [maxPeriodMonth]="360"
    [requiredFrom]="false"
    [requiredTo]="false"
  ></filter-campaign-period>

  <filter-goods
    class="m-r-sm"
    [partnerId]="campaignService.queryParams.getFilterValue('partnerId')"
    (modelChange)="onFilterGoods($event)"
    [showCategories]="['sku', 'category', 'custom']"
    [max]="10"

    [initList]="goodFilterList"
    [initType]="goodFilterType"

  ></filter-goods>

  <filter-locations
    (modelChange)="onFilterLocations($event)"
    [partnerId]="campaignService.queryParams.getFilterValue('partnerId')"

    [initList]="locationFilterList"
    class="m-r-sm"
  ></filter-locations>


  <button class="btn btn-success btn-sm btn-addon pull-right collapse-sm collapse-xs"
          [uiSref]="'^.view'"
          data-testid="campaign.add()"
  >
    <i class="fa fa-plus"></i>
    <span class="btn-name  ">Новая кампания</span>
  </button>

  <button type="button" class="btn btn-addon btn-sm btn-info pull-right hidden-xs collapse-sm collapse-md"
          [disabled]="!campaignsList || campaignsList.length === 0"
          data-testid="campaign.preview()"
          [class.active]="isPreview"
          (click)="changePreview()"
  >
    <i class="fa fa-magic"></i>
    <span class="btn-name  "> Предпросмотр кампании </span>
  </button>

  <button type="button" class="btn btn-addon btn-sm btn-info pull-right collapse-xs collapse-sm collapse-md"
          [uiSref]="'^.calendar'"
          data-testid="campaign.calendar()">
    <i class="fa fa-calendar"></i>
    <span class="btn-name  "> Календарь </span>
  </button>


</div>

<div class="d-flex w-full">

  <div class="col">

    <div class="wrapper stick-search-box">
      <search-input
        [model]="searchString"
        (modelChange)="onSearchChange($event)"
        placeholder="Наименование кампании / Описание / Тэг / Механика"
      ></search-input>
    </div>

    <div infiniteScroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         [infiniteScrollContainer]="'#app-container'"
         [fromRoot]="true"
         (scrolled)="onScroll()"
    >
      <responsive-table>
        <table class="table m-b-none"
               [class.campaign_preview_table]="isPreview"
        >
          <thead>
            <tr>
              <th>
                <span>Статус</span>
              </th>

              <th
                data-testid="campaign.name.sort()"
                class="not-hide-campaign"
              >
                <sort-view
                  column="campaignName"
                  [params]="campaignService.queryParams"
                  (onChange)="reset()"
                >
                  Наименование кампании
                </sort-view>
              </th>

              <th width="10%">
                  Категория
              </th>

              <th data-testid="campaign.startDate.sort()"
                  width="10%">
                <sort-view
                  column="campaignStartDate"
                  [params]="campaignService.queryParams"
                  (onChange)="reset()"
                >
                  Дата начала
                </sort-view>
              </th>

              <th  data-testid="campaign.stopDate.sort()"
                  width="10%">
                <sort-view
                  column="campaignStopDate"
                  [params]="campaignService.queryParams"
                  (onChange)="reset()"
                >
                  Дата завершения
                </sort-view>
              </th>

              <th width="10%">
                  <span>
                    Рассылка
                  </span>
              </th>

              <th width="15%">
                  <span>
                    Место проведения
                  </span>
              </th>

              <th data-testid="campaign.mechanic.sort()"
                  width="10%">
                <sort-view
                  column="mechanicName"
                  [params]="campaignService.queryParams"
                  (onChange)="reset()"
                >
                  Механика
                </sort-view>
              </th>

              <th width="10%">
                <span>
                  Целевая аудитория
                </span>
              </th>
              <th  width="10%">
                <span>
                  Сообщение
                </span>
              </th>

            </tr>
          </thead>

          <tbody>

            <tr *ngFor="let campaign of campaignsList "
                data-testid="campaign in campaignsList"
                [class.selected]="isPreview && selectedCampaign === campaign"
            >

              <td class="text-nowrap">


                <i class="fa fa-circle m-r-xs"
                   [ngClass]=" 'text-' + CAMPAIGN_STATES_COLORS[campaign?.campaign?.state]"
                ></i>

                <span [innerText]="CAMPAIGN_STATE_NAMES[campaign?.campaign?.state] || campaign?.campaign?.state"
                      data-testid="campaign.state"></span>

                <span class="clear f-s-12px m-l-md"
                      [ngClass]="{ 'text-danger-dker': campaign?.campaign?.completeness==='error','text-muted': campaign?.campaign?.completeness!=='error'  }"
                      [innerHTML]="getCompletenessMessage(campaign?.campaign?.state, campaign?.campaign?.completeness)"></span>

              </td>


              <td class="text-wrap overflow-wrap-break table-big-link not-hide-campaign"
                  (click)="selectedCampaign = campaign"
              >

                <ng-container
                  *ngIf="!isPreview"
                >
                  <a href
                     [uiSref]="'^.view({id:' + campaign.campaign.id + '})'"
                     data-testid="campaign.name">

                    <text-highlight
                      [search]="searchString"
                      [text]="campaign?.campaign?.name"
                    ></text-highlight>

                  </a>

                  <div class=" text-muted f-s-12px "
                       data-testid="campaign.description"
                  >
                    {{ campaign?.campaign?.description }}
                  </div>

                  <div class="clearfix"></div>

                  <div *ngIf="!!campaign?.campaign?.listOfTags?.length"
                       data-testid="campaign.listOfTags">

                    <campaign-tags
                      [model]="campaign.campaign.listOfTags"
                      [isView]="true"
                      [tagCss]="'badge f-s-6 no-select bg-info pull-left disabled m-r-xs m-t-xs '"
                    ></campaign-tags>

                  </div>
                </ng-container>

                <ng-container
                  *ngIf="isPreview"
                >
                  <a href="javascript:void(0)"
                     data-testid="campaign.name"
                  >

                    <text-highlight
                      [search]="searchString"
                      [text]="campaign?.campaign?.name"
                    ></text-highlight>

                  </a>
                </ng-container>

              </td>

              <td class=" overflow-wrap-break">
                <div class="overflow-wrap-break"
                     style="max-width: 240px;"
                     data-testid="campaign.category">
                  {{campaign?.campaign?.categoryName }}
                </div>
              </td>

              <td data-testid="campaign.startDate">
                {{campaign.campaign.campaignTime.startDate | loyaDate }}
              </td>

              <td data-testid="campaign.stopDate">
                {{campaign.campaign.campaignTime.stopDate | loyaDate }}
              </td>

              <td data-testid="campaign.informationDate">
                за {{campaign.campaign.campaignTime.informationDate }} дней
              </td>

              <td class=" overflow-wrap-break" >

                <div *ngIf="campaign?.locationGroup?.length>0"
                     class="clearfix  overflow-wrap-break"
                     style="max-width: 250px;">

                  <span class="text-muted f-s-9">Для группы:</span>

                  <div class="no-padder">
                    <div *ngIf="!campaign?.$showMoreLocationGroup">

                      <div class="m-l-xs" *ngFor="let t of campaign.locationGroup | slice:0:3;" data-testid="campaign.locationGroup">
                        {{t.name }}
                      </div>


                      <div *ngIf="campaign?.locationGroup?.length>3" class="pos-rlt table-big-link">
                        <a class="f-s-9 m-l-xs text-info"
                           style="font-size:0.9em"
                           href="javascript:void(0)"
                           (click)="campaign.$showMoreLocationGroup =!campaign?.$showMoreLocationGroup" data-testid="campaign.location.showMore">ещё
                          (+{{campaign?.locationGroup.length-3}})</a>
                      </div>

                    </div>


                    <div *ngIf="campaign?.$showMoreLocationGroup">
                      <div class="m-l-xs" *ngFor="let t of campaign.locationGroup" data-testid="campaign.locationGroup.more">
                        {{t.name }}
                      </div>

                      <div *ngIf="campaign?.locationGroup?.length>3" class="pos-rlt table-big-link">
                        <a class="f-s-9 m-l-xs text-info"
                           style="font-size:0.9em"
                           href="javascript:void(0)"
                           (click)="campaign.$showMoreLocationGroup =!campaign?.$showMoreLocationGroup"
                           data-testid="campaign.location.hide">скрыть</a>
                      </div>

                    </div>


                  </div>
                </div>

                <div *ngIf="campaign?.location?.length>0"
                     class="clearfix  overflow-wrap-break"
                     style="max-width: 250px;">

                  <span class="text-muted f-s-9">Для магазинов:</span>
                  <div class="no-padder">
                    <div>
                      <div class="m-l-xs" *ngFor="let t of campaign.location| slice:0:3;" data-testid="campaign.location">
                        {{t.name }}
                      </div>

                      <div *ngIf="campaign?.location?.length>3"
                           class="pos-rlt table-big-link">


                        <ng-template #locationTemplate >

                          <div style="max-height:300px; min-width:200px;"
                               class="mini-scroll scroll-y  ">

                            <div class="text-word-break  " *ngFor="let t of campaign?.location " data-testid="campaign.popup.location">
                              {{t.name }}
                            </div>

                          </div>

                        </ng-template>


                        <a class="f-s-9 m-l-xs text-info" style="font-size:0.9em"
                           href="javascript:void(0)"

                           [ngbPopover]="locationTemplate"
                           [popoverTitle]="'Для магазинов'"
                           [placement]="'bottom'"
                           data-testid="campaign.location.more"

                        >
                          + ещё {{campaign.location.length-3}}
                        </a>
                      </div>

                    </div>

                  </div>
                </div>

                <span *ngIf="!campaign?.locationGroup?.length && !campaign?.location?.length"
                      data-testid="campaign.locationGroup.and.location.not">
                          Не задано
                      </span>
              </td>

              <td class=" overflow-wrap-break">

                <div class="table-big-link overflow-wrap-break" style="width: 250px;">
                  <a [uiSref]="'^.^.mechanics.config({mechanicId:' + campaign.campaign.mechanicId + '})'" data-testid="campaign.mechanic">
                    <text-highlight [search]="searchString"
                                    [text]="campaign.mechanic.name"></text-highlight>
                  </a>

                </div>
              </td>

              <td class=" table-big-link " >

                  <span *loyaPermitIf="{path:'segments', permitEnabled: ''}">

                      <span *ngIf="campaign?.segment?.name">сегмент -
                          <a class="f-s-10"
                             [uiSref]="'frontend.segments.view({id:' + campaign.campaign.segmentId + ', state:\'deploy\'})'">{{campaign.segment.name}}</a>
                      </span>

                      <span *ngIf="campaign?.group?.name">группа -
                          <a class="f-s-10"
                             [uiSref]="'frontend.segments.groups.view({id:' + campaign.campaign.groupId + ', state:\'deploy\'})'">{{campaign.group.name}}</a>
                      </span>

                      <span *ngIf="!campaign?.segment?.name && !campaign?.group?.name">Все</span>

                  </span>


                <span *loyaPermitIf="{path:'segments', permitDisabled: ''}">

                     <span *ngIf="campaign?.group?.name==='Все УПЛ'" data-testid="group.upl">Участники ПЛ</span>
                     <span *ngIf="campaign?.group?.name==='Все неУПЛ'" data-testid="group.nupl">Не участники ПЛ</span>
                     <span *ngIf="campaign?.group && campaign?.group?.name!=='Все неУПЛ' && campaign?.group?.name!=='Все УПЛ'" data-testid="group.name">группа - {{campaign?.group?.name}}</span>

                     <span *ngIf="!campaign?.group" data-testid="group.all">Все</span>

                </span>

              </td>

              <td>
                  <span class="table-big-link">
                      <a [uiSref]="'frontend.campaign.campaigns.templates.view({id:' + campaign.campaign.templateId + '})'" data-testid="campaign.msgTemplateName"> {{ campaign.msgTemplateName }} </a>
                  </span>
              </td>

            </tr>

          </tbody>

        </table>

      </responsive-table>


      <div class="text-center text-warning m-t-lg m-b-lg"
           *ngIf="loader.isLoading('list')"
      >
        <div class="">
          <i class="fa fa-3x fa-spin fa-spinner"></i>
        </div>

      </div>

    </div>

  </div>

  <div class="col-md-9 col-sm-7  no-padder b-l"
       *ngIf="isPreview"
  >
    <campaign-preview
      [campaignId]="selectedCampaign?.campaign?.id"
      [campaignItem]="selectedCampaign"
    >
    </campaign-preview>
  </div>

</div>

<scroll-up></scroll-up>

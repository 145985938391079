import {PosTypeChannel} from "../../../../api/CommunicationCampaignApi/models/pos-type-channel";

export const COMMUNICATION_CAMPAIGN_STATES = {
  'draft'    : "draft",
  'suspended': "suspended",
  'unsuspend': "unsuspend",
  'started'  : "started",
  'active'   : "active",
  'completed': "completed",
  'archive'  : "archive"
}

export const COMMUNICATION_CAMPAIGN_STATE_NAMES = {

  [COMMUNICATION_CAMPAIGN_STATES.draft]: "Черновик",
  [COMMUNICATION_CAMPAIGN_STATES.suspended]: "Приостановлена",
  [COMMUNICATION_CAMPAIGN_STATES.unsuspend]: "Разморожена",
  [COMMUNICATION_CAMPAIGN_STATES.started]  : "Запланирована",
  [COMMUNICATION_CAMPAIGN_STATES.active]   : "Активна",
  [COMMUNICATION_CAMPAIGN_STATES.completed]: "Завершена",
  [COMMUNICATION_CAMPAIGN_STATES.archive]  : "В архиве"

}

export const COMMUNICATION_CAMPAIGN_STATES_COLORS = {
  [COMMUNICATION_CAMPAIGN_STATES.draft]: "warning",
  [COMMUNICATION_CAMPAIGN_STATES.suspended]: "primary",
  [COMMUNICATION_CAMPAIGN_STATES.unsuspend]: "primary",
  [COMMUNICATION_CAMPAIGN_STATES.started]  : "success",
  [COMMUNICATION_CAMPAIGN_STATES.active]   : "success",
  [COMMUNICATION_CAMPAIGN_STATES.completed]: "info",
  [COMMUNICATION_CAMPAIGN_STATES.archive]  : "danger"
}

export enum COMMUNICATION_CAMPAIGN_COMPLETENESS  {
  'error' = 'error',
  'unknown' = 'unknown',
  'started' = 'started',
  'finished' = 'finished'
}

export const COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES = {
  'error': 'Ошибка',
  'unknown': 'Неизвестно',
  'started': 'Начато',
  'finished': 'Закончено'
}

export const COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES =  {
  [COMMUNICATION_CAMPAIGN_STATES.draft]: {
    'error': 'В процессе...',
    'unknown': 'В процессе...',
    'started': 'В процессе...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.suspended]: {
    'error': 'Останавливается...',
    'unknown': 'Останавливается...',
    'started': 'Останавливается...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.unsuspend]: {
    'error': 'Запускается...',
    'unknown': 'Запускается...',
    'started': 'Запускается...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.started]: {
    'error': 'Запускается...',
    'unknown': 'Запускается...',
    'started': 'Запускается...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.active]: {
    'error': 'Активизируется...',
    'unknown': 'Активизируется...',
    'started': 'Активизируется...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.completed]: {
    'error': 'Завершается...',
    'unknown': 'Завершается...',
    'started': 'Завершается...',
    'finished': ''
  },
  [COMMUNICATION_CAMPAIGN_STATES.archive]: {
    'error': 'Архивируется...',
    'unknown': 'Архивируется...',
    'started': 'Архивируется...',
    'finished': ''
  }
}

export enum COMMUNICATION_CAMPAIGN_ACTIONS {

  "cancel" = "cancel",
  "run"= "run",
  "pause" = "pause",
  "completed" = "completed",
  "archive" = "archive",

  "clone" = "clone",
  "disabled" = "disabled",

  "edit" = "edit"

}


export const COMMUNICATION_CAMPAIGN_STATES_TREE = {

  [COMMUNICATION_CAMPAIGN_STATES.draft]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.run] : COMMUNICATION_CAMPAIGN_STATES.started,
    [COMMUNICATION_CAMPAIGN_ACTIONS.pause] : false,   //  Просто для показа выключенных кнопок
    [COMMUNICATION_CAMPAIGN_ACTIONS.edit] : true,  //  Показ включенных кнопок
  },
  [COMMUNICATION_CAMPAIGN_STATES.suspended]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.completed] : COMMUNICATION_CAMPAIGN_STATES.completed,
    [COMMUNICATION_CAMPAIGN_ACTIONS.run] : COMMUNICATION_CAMPAIGN_STATES.unsuspend,
    [COMMUNICATION_CAMPAIGN_ACTIONS.clone] : COMMUNICATION_CAMPAIGN_STATES.draft,
    [COMMUNICATION_CAMPAIGN_ACTIONS.edit] : true,
  },
  [COMMUNICATION_CAMPAIGN_STATES.started]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.pause] : COMMUNICATION_CAMPAIGN_STATES.suspended,
    [COMMUNICATION_CAMPAIGN_ACTIONS.run] : false,
    [COMMUNICATION_CAMPAIGN_ACTIONS.clone] : COMMUNICATION_CAMPAIGN_STATES.draft,
  },
  [COMMUNICATION_CAMPAIGN_STATES.active]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.pause] : COMMUNICATION_CAMPAIGN_STATES.suspended,
    [COMMUNICATION_CAMPAIGN_ACTIONS.run] : false,
    [COMMUNICATION_CAMPAIGN_ACTIONS.clone] : COMMUNICATION_CAMPAIGN_STATES.draft,
  },
  [COMMUNICATION_CAMPAIGN_STATES.completed]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.archive] : COMMUNICATION_CAMPAIGN_STATES.archive,
    [COMMUNICATION_CAMPAIGN_ACTIONS.clone] : COMMUNICATION_CAMPAIGN_STATES.draft,
  },
  [COMMUNICATION_CAMPAIGN_STATES.archive]: {
    [COMMUNICATION_CAMPAIGN_ACTIONS.clone] : COMMUNICATION_CAMPAIGN_STATES.draft,
  }

}


export const CHANNEL_TYPES = {
  "BOTH" : PosTypeChannel.BOTH,
  "POS" : PosTypeChannel.POS,
  "SCO" : PosTypeChannel.SCO
}

export const CHANNEL_TYPES_NAMES = {
  [CHANNEL_TYPES.BOTH]: "Касса и КСО",
  [CHANNEL_TYPES.POS]: "Касса",
  [CHANNEL_TYPES.SCO]: "КСО"
}

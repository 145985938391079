import { OnInit, Injectable,Input } from '@angular/core';
import {Observable, Subscriber} from "rxjs";

@Injectable()
export abstract class InfiniteSelectClass
  implements OnInit  {

    addItems: Array<any> = [];

    protected PAGER_PRELOAD_COUNT = 20;

    items: Array<any>;
    filteredItems: Array<any> = [];
    loading: boolean;
    lastSearchText: string | boolean;

    request : any;

    private isEnded = false;
    private queryQueue = [];
    abstract pagerNext(searchText: string | boolean): Observable<Array<any>>;
    abstract pagerReset();

    constructor() {}

    onScroll($event: any = false) {

      if (
        this.loading ||
        $event?.start === 0 ||
        $event?.start / $event?.end > 0.85
      ) return;

      if ($event && $event.end + this.PAGER_PRELOAD_COUNT > this.filteredItems.length )
        this.fetch();

    }

    onScrollToEnd() {
      this.fetch();
    }

    ngOnInit() {

      if (Array.isArray(this.addItems)) {
        this.addItems = this.addItems.filter( i => typeof i !== 'undefined');
      }

      if (this.addItems) {
        this.items =  [...this.addItems];
      } else {
        this.items = [];
      }

      this.fetch();
    }

    lastSearchId: any = -1;
    onSearch($event: any) {

      this.clearQueue();

      this.items = [];
      this.filteredItems = [];

      clearTimeout(this.lastSearchId);
      this.lastSearchId = setTimeout(() => {
            this.clear($event.term);
      },400);

    }

    clearQueue() {

      this.queryQueue.map( i => {
        i.cancel = true;
        if (i.subscriber && typeof i.subscriber.unsubscribe === "function" )
          i.subscriber.unsubscribe();
      });

      this.queryQueue.splice(0,this.queryQueue.length);

    }

    clear(searchText : string | boolean = false) {

      this.lastSearchText = searchText;
      this.clearQueue();
      this.items = [];
      this.filteredItems = [];
      this.isEnded = false;
      this.loading = false;
      this.pagerReset();
      this.onScrollToEnd();
    }

    fetch(searchText : string | boolean = false) {

 //   = searchText;
      if (this.isEnded || this.loading) return;

      this.loading = true;
      let queryItem: any = {
        cancel: false,
      }

      let that = this;

      queryItem.subscriber = this.pagerNext(this.lastSearchText)
        .subscribe( result => {

          this.loading = false;

          that.queryQueue = that.queryQueue.filter( i => i!==queryItem);
          that.isEnded = Array.isArray(result) && !result.length;
          if (queryItem.cancel)
            return;

          that.items = that.items || [...this.addItems];
          that.items = that.items || [];
          that.items = that.items.concat(result);


        }, () => {
          this.loading = false;
        });

      this.queryQueue.push(queryItem);

    }

}

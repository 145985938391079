import {Component, OnInit, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import {CashBoxEvents, CashBoxService} from "../../service/cash-box.service";
import {GoodsSelectorService} from "../../../../component/goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";

@Component({
  selector: 'cash-box-items',
  templateUrl: "./cash-box-items.component.html",
  styleUrls: ['./cash-box-items.component.scss'],
  providers: [
    GoodsSelectorService,
    GoodModelService
  ]
})
 export class CashBoxItemsComponent implements OnInit, OnChanges, OnDestroy {

  public skuInputWidget = {
    skuForAdd: '',
    errorText: '',
    isLoading: false,
  }

  public cache = {
    goodNames: new Map<string, string>(),
    showAdditional: new Map<string, boolean>(),
  }

  public showTotalAdditional = false

  public informingMessages = [];

  constructor(
      public  cashBoxService : CashBoxService,
      private goodsSelectorService: GoodsSelectorService,
      private goodModelService: GoodModelService,
  )
  {
  }

  ngOnInit() {
    this.subscribeOnEvents();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {

  }

  subscribeOnEvents() {
    this.cashBoxService.events.subscribe(event => {

      if ( [CashBoxEvents.PRECHECK_END, CashBoxEvents.PAYMENTTRY_END, CashBoxEvents.CHANGE_ITEMS ,CashBoxEvents.CREATE].indexOf(event.type) >= 0 ) {
        this.informingMessages = []
      }

      if ([CashBoxEvents.CREATE, CashBoxEvents.RESET].indexOf(event.type) >= 0) {
        this.skuInputWidget.skuForAdd = '';
        this.skuInputWidget.errorText = '';
        this.skuInputWidget.isLoading = false;
      }

      if (event.type === CashBoxEvents.CHECK_CONFIRM_END) {
        this.checkInformMessages(event.value)
      }
    });
  }

  searchBySku() {

    this.skuInputWidget.errorText = '';

    if ( !this.skuInputWidget.skuForAdd)
      return;

    this.skuInputWidget.isLoading = true;

    this.goodModelService
        .get$(this.skuInputWidget.skuForAdd, this.cashBoxService.partnerId)
        .subscribe({
          next: data => {
            this.addGoodItems(data);
            this.skuInputWidget.isLoading = false;
            this.skuInputWidget.errorText = '';
            this.skuInputWidget.skuForAdd = '';
          },
          error: err => {
            err?.stopPopupError();
            this.skuInputWidget.errorText = `SKU ${this.skuInputWidget.skuForAdd} не найден`;
            this.skuInputWidget.isLoading = false;
          }
        });

  }

  addFromPopup() {
    this.goodsSelectorService.open(
      <any>{
        forPartner: this.cashBoxService.partnerId,
        resultDirective: () => {},
        showCategories: ['sku']
      }
    ).then(result => {

      if (Array.isArray(result?.list))
        this.addGoodItems(result?.list)

    }, () => false);
  }

  addGoodItems(item: any) {
    if (!item) {
      return;
    }

    const items = Array.isArray(item) ? item : [item];

    items.forEach(item => {
      this.cache.goodNames.set(`${this.cashBoxService.partnerId}:${item?.sku || item?.id}` , item.name || item.label);
    })

    this.cashBoxService.addItems(items);
  }

  getName(item: any) {
    const id = `${this.cashBoxService.partnerId}:${item?.sku}`;

    if (this.cache.goodNames.has(id)) {
      return this.cache.goodNames.get(id);
    }

    return ;
  }

  hasTotal() {
    return this.cashBoxService.currentCheck.items.length > 0;
  }

  total() {
    return this.cashBoxService.total
  }

  hasTotalAdditional() {
    return !!this.cashBoxService?.currentCheck?.results;
  }

  deleteItem(item: any) {
    this.cashBoxService.removeItems(item);
    if (this.cache.showAdditional.has(item?.sku + item?.sku)) {
      this.cache.showAdditional.delete(item?.sku + item?.sku);
    }
  }

  checkInformMessages(check: any) {
    this.informingMessages = [];

    if ( !!check?.informing('pos')?.length ) {
      this.informingMessages = check?.informing('pos')
        ?.map( i => i.info ) || [];
    }
  }

  removeInforming( item ) {
    if (this.informingMessages.indexOf( item) >=0 ) {
      this.informingMessages.splice( this.informingMessages.indexOf( item), 1 );
    }
  }


}

import {Inject, Injectable, Injector} from "@angular/core";
import {VIEW_TYPES} from "../goods-tree.variables";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GoodsWatchComponent} from "../components/goods-watch/goods-watch.component";

export type legacyDirectiveParams = {
  forPartner?: number | string,
  canDelete?: boolean | string,
  listType: string,
  list: any []
}

@Injectable({
  providedIn: "root",
})
export class GoodsWatcherService {

  constructor(
    private modalService: NgbModal
  ) {
  }

  public open(options: legacyDirectiveParams) {
    const modalRef = this.modalService.open(
      GoodsWatchComponent,
      {
        size: "md",
        windowClass: 'good-tree-popup-modal p-0'
      }
    );

    this.legacySetOptions(modalRef.componentInstance, options);

    return modalRef.result;

  }


  private legacySetOptions(modalInstance: GoodsWatchComponent, options: legacyDirectiveParams) {

    if ( typeof options.forPartner !==  'undefined')
      modalInstance.forPartner = options.forPartner;

    if ( typeof options.canDelete !==  'undefined')
      modalInstance.canDelete = options.canDelete;

    if ( typeof options.listType !==  'undefined')
      modalInstance.listType = <any>options.listType;

    if (options.list) {
      modalInstance.setList(options.list);
    }

  }

  private legacyConvertTypes( inputType ) {

    inputType = inputType.toLowerCase();

    switch (true) {
      case inputType === 'goodgroups' : return VIEW_TYPES.GOODGROUP;
      case inputType === 'goods'      : return VIEW_TYPES.SKU;
      case inputType === 'categories' : return VIEW_TYPES.CATEGORY;
      case inputType === 'brands'     : return VIEW_TYPES.BRAND;
    }

    return inputType;
  }


}

import { RuleNames } from '../../../../../../app/pages/config/directory/campaigncategorylimitations/index/shared/component/campaignCategoryRule/campaignCategoryRule-index-page.component';
import { RuleTypes } from '../../../../../../app/pages/config/directory/campaigncategorylimitations/index/shared/class/CampaignCategoryMatrix';

import 'model/ProcessingLoyaDetails/resource';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {GoodsSelectorService} from "../../../../component/goods-tree/service/goods-selector.service";
import {GoodModelService} from "../../../../service/api/GoodModel/GoodModel.service";
import {ProcessingService} from "../../service/processing.service";

@Component({
  selector: 'cash-box-collision-info',
  templateUrl: "./cash-box-collision-info.component.html",
  providers: [
    ProcessingService
  ]
})
export class CashboxCollisionInfo implements OnChanges {

  @Input() public precheckGetter   : Function ;
  @Input() public positionNumber   : number   ;
  @Input() public precheckUpdate   : any ;

  public rulesForPosition : Array<any> ;
  public categoryDB       : any  = {};

  @Input() public isVisible : boolean ;
  @Output() public isVisibleChange  = new EventEmitter<boolean>();

  public isLoading : boolean = false ;

  public emptyMinimized= {};
  public showRowCamp = {};
  public showColCamp = {};


  get isEmpty() {
    return !this.rulesForPosition || ( Array.isArray(this.rulesForPosition) && this.rulesForPosition.length === 0 );
  }


  constructor(
    private processingService: ProcessingService
  ) { }


  public ngOnChanges( changesObj : SimpleChanges) {
    if ( changesObj.precheckUpdate
      && !changesObj.precheckUpdate.isFirstChange()
      && this.isVisible
    ) {
      this.getInfoByItem();
    }
  }

  public setVisible( newVal : boolean = !this.isVisible ) {
    this.isVisible = !!newVal;
    this.isVisibleChange.emit( this.isVisible );
    this.getInfoByItem();
  }

  public getInfoByItem() {

    let precheck = this.precheckGetter();

    this.isLoading = true;
    return this.processingService.preCheckDetails$( precheck )
      .toPromise()
      .then( this.parseCategories.bind(this) )
      .then( this.parseForItem.bind(this) )
      .then( () => this.isLoading = false, () => this.isLoading = false )
      .catch( () => this.isLoading = false )

  }

  private parseForItem( rusult : any ) {

    if ( rusult && Array.isArray(rusult.items) ) {
      rusult.items.forEach( ( result: any ) => {

        if ( result.position === this.positionNumber ) {
          this.rulesForPosition = result.rules;
        }

      });
    }

    return rusult;
  }

  private parseCategories(rusult : any) {

    if ( rusult && Array.isArray(rusult.categories) ) {
      rusult.categories.forEach( ( result: any ) => {

        this.categoryDB[result.groupId] = result;

      });
    }

    return rusult;

  }

  public getCategoryName( id : number ) {
    return this.categoryDB[id] && this.categoryDB[id].groupName;
  }

  public getCategoryRule( id : number ) {
    return this.categoryDB[id] && this.getRuleName(this.categoryDB[id].groupCollision);
  }

  public getRuleName( RuleNameId : any ) {
    return RuleNames[RuleNameId] || '';
  }

  public ruleDetails( details: any, ryleType: any ) {
    if ( ryleType === RuleTypes.byPriority ) {
      return this.getCategoryName( details );
    }

    return details;
  }

  public isWinner( id: number, winners: Array<any> ) {
    return winners.indexOf(id)>=0;
  }

  public isLooser( id: number, winners: Array<any>, members: Array<any> ) {
    return members.indexOf(id)>=0 && !this.isWinner(id, winners);
  }

  public isPriority( id: number, idWinner: number ) {
    return id === idWinner;
  }

  public initRules(rulesArray: any[]) {

    Array.isArray(rulesArray) && rulesArray?.forEach( rule => {
      if ( typeof this.emptyMinimized[rule] === 'undefined' ) {
        this.emptyMinimized[rule] = rule.isEmpty;
      }
    });

    return rulesArray;
  }

}

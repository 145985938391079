import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'filter-checkbox',
  template:`

    <div
      class="  dropdown "
      ngbDropdown
      [autoClose]="'outside'"
      container="body"
    >
      <div class="btn-group ">
        <button
          type="button"
          data-testid="filter.open()"
          class="btn btn-default btn-addon btn-sm"
          ngbDropdownToggle>

          <i class="fa fa-filter"
              *ngIf="showIcon"
              [class.text-danger]="!selectedAll()"
          ></i>

          <span class="btn-name" [innerText]="name"></span>
          <span class="caret m-l-xs"></span>

        </button>

        <button
          type="button"
          data-testid="filter.clear()"
          class="btn btn-danger btn-sm"
          *ngIf="!selectedAll() && showClearButton"
          (click)="selectAll(true)"
        >

          <i class="fa fa-times"
          ></i>

        </button>
      </div>
      <ul class="dropdown-menu" ngbDropdownMenu>

        <li
          class="wrapper-xs padder text-ellipsis b-b p-b-none m-b-xs"
          *ngIf="canSelectAll"
        >

            <label class="i-checks" [for]=" id + 'all' ">
              <input class="form-check-input"
                     type="checkbox"
                     data-testid="filter.select.all"
                     [checked]="selectedAll()"
                     (change)="selectAllChange($event)"
                     [id]=" id + 'all' ">
              <i></i>
              Выбрать все
            </label>


        </li>

        <li
          class="wrapper-xs padder text-ellipsis"
          data-testid="filter.select.item"
          *ngFor="let item of preparedInputValues; let i = index;">

          <label class="i-checks"  [for]="id + 'filter' + i">
            <input class="form-check-input"
                   type="checkbox"
                   [attr.data-testid-state]="item.id"
                   [checked]="inFilter( item.id)"
                   (change)="switchFilter($event, item.id)"
                   [id]=" id + 'filter' + i">
            <i></i>
            {{ item.value }}
          </label>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./filter-checkbox.component.scss'],
  providers: [],
})
export class FilterCheckboxComponent implements OnInit, OnChanges {

  @Input() canSelectAll = false;
  @Input() name = 'Фильтр';
  @Input() inputValues: {};
  @Input() selectedValues: Array<any>;

  @Input() showIcon = true;
  @Input() showClearButton = true;

  public _selectedValues = new Set();
  public id = '';

  public preparedInputValues: any = [];

  @Output() selectedValuesChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {

    this.id =  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });

    this.prepareInputValues();

  }

  ngOnChanges(changes: SimpleChanges) {

    if (
      changes['selectedValues'] &&
      Array.isArray(changes['selectedValues'].currentValue) &&
      (
        this._selectedValues.size !== changes['selectedValues'].currentValue.length ||
        changes['selectedValues'].currentValue.some(i => !this._selectedValues.has(i))
      )

    ) {
      this._selectedValues.clear();
      changes['selectedValues'].currentValue.forEach(this._selectedValues.add, this._selectedValues);
      this.emitValues();
    }

    if (changes['inputValues'] && !changes['inputValues'].firstChange)
      this.prepareInputValues();

  }

  prepareInputValues() {
    this.preparedInputValues = [];

    for (let key in this.inputValues) {
      let id: any = key;

      if ( /^\d+$/.test(id) && !!this.inputValues[ parseInt(id) ] )
        id = parseInt(id);

      this.preparedInputValues.push({
        id: id,
        value: this.inputValues[key]
      })

    }

  }

  emitValues() {
    setTimeout(() => {
      let emitValues = Array.from(this._selectedValues.values());

      if (!Array.isArray(this.selectedValues))
        return

      if (JSON.stringify([...this.selectedValues].sort()) !== JSON.stringify(emitValues.sort()))
        this.selectedValuesChange.emit( emitValues );
    });
  }

  selectedAll() {
    return this._selectedValues.size === this.preparedInputValues.length;
    this.emitValues();
  }

  selectAll(checked) {

    setTimeout(() => {
      if (checked) {
        this._selectedValues.clear();
        this.preparedInputValues.forEach( i => {
          this._selectedValues.add(i.id);
        });
      } else {
        this._selectedValues.clear();
      }
      this.emitValues();
    });

  }

  selectAllChange($event: Event) {

    const checked = (<any>$event?.target)?.checked;

    this.selectAll(checked);

  }

  inFilter( id ) {
    return this._selectedValues.has(id);
  }

  switchFilter($event: Event, key) {

    const checked = (<any>$event?.target)?.checked;

    setTimeout(() => {
      if (checked) {
        this._selectedValues.add(key);
      } else {
        this._selectedValues.delete(key);
      }

      this.emitValues();
    })
  }


}

<form name="importOptionsForm"
      #importOptionsForm="ngForm"
      novalidate=""
      class="w-full h-full"
>

  <div class="vbox">

      <div class="row-row " style="height: auto;">

        <div class="cell b-b">

          <div class="wrapper-sm  ">
            <div class="row ">

              <div class="col-sm-6">

                <div class="h4 relative">

                  <div class="pull-left m-t-n-xxs ">
                    <i class="fa fa-upload rfm-t1 m-r-sm f-s-12"> </i>
                  </div>

                  <div class="m-t-xs">Импорт из <span
                    [innerHtml]="importOptions.filenameOrigin || 'файла'"></span>
                  </div>

                </div>

              </div>

              <div class="col-sm-6 ">

                <button type="button"
                        *ngIf="currentScreen === 'config' "
                        class="btn btn-sm btn-success pull-right m-l btn-addon"
                        [disabled]="!isValidColums() ||isLoadPreview || previewError || (importOptionsForm && importOptionsForm.invalid)"
                        (click)="confirmImport()"
                >
                  <i class=" fa fa-check"></i>
                  <span class="btn-name">Импортировать</span>
                </button>

                <button type="button" class="btn btn-sm btn-default pull-right  btn-addon" (click)="reject()"
                        *ngIf="currentScreen !== 'import'">
                  <i class=" fa fa-remove text-danger"></i>
                  <span class="btn-name">Отмена</span>
                </button>

              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="row-row">

        <div class="cell">

           <div class="cell-inner bg-light"
               [ngSwitch]="currentScreen"
          >

            <ng-container
              *ngSwitchCase="'load'"
            >

              <div class="vbox">

                <div class="row-row" style="height: 80%">

                  <div class="cell ">

                    <div class="cell-inner" nv-file-over="" over-class="b-dashed b b-dark b-2x" uploader="uploader">
                      <span class="text-muted h3">Поместите файлы сюда</span>
                    </div>
                  </div>

                </div>


                <div class="row-row">

                  <div class="cell v-middle text-center" style="display: table-cell;">


                    <button class="btn btn-addon btn-success btn-large" (click)="selectFile()">
                      <i class="fa fa-plus"></i>
                      <span class="btn-name">
                            Выбрать файл
                        </span>
                    </button>

                    <input type="file" nv-file-select="" uploader="uploader" class="none" id="excelUploader"/>

                  </div>

                </div>

              </div>

            </ng-container>

            <ng-container
              *ngSwitchCase="'progress'"
            >

              <div class="vbox">

                <div class="row-row" style="height: 2%">

                  <div class="cell">
                  </div>
                </div>
                <div class="row-row" style="height: 60%">

                  <div class="cell ">
                    <div class="cell-inner logo-container ">

                    </div>
                  </div>
                </div>

                <div class="row-row" style="height: 10%">

                  <div class="cell">
                  </div>
                </div>

                <div class="row-row">

                  <div class="cell">
                    <div class="clearfix">
                      <div class="col-sm-8 offset-sm-2 text-center">

                        <ng1-progressbar
                          [value]="progressPercent"
                          type="success"
                          class="progress-striped active"
                        >
                          {{progressPercent}}
                        </ng1-progressbar>

                        <div class="clearfix m-t-xs font-bold text-muted">Загрузка файла</div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </ng-container>

            <ng-container
              *ngSwitchCase="'config'"
            >
              <div class="vbox">

                  <div class="row-row" style="height: auto">

                    <div class="cell">

                      <ngb-alert
                        *ngIf="previewError"
                        [type]="'danger'"
                        class="m-t m-r m-l"
                      >
                        <i class="fa fa-warning text-danger m-r-xs"></i>
                        <span class="text-danger" data-testid="previewError"[innerHtml]="previewError"></span>
                      </ngb-alert>


                      <div class="card no-back  m-t-n-xxs">

                        <div class="card-header b-t ">
                          <i class="fa fa-gear m-r-xs"></i> <span class="">  Импорт </span>
                        </div>

                        <div class="card-body p-t-none">

                          <div class="row m-t-sm">


                            <div class="col-sm-6 col-lg-6 m-b-xs" *ngIf="showOption('encoding')">
                              <div class="row">

                                <div class=" col-sm-4 text-right text-ellipsis">
                                  <div class="m-t-xs">
                                    Кодировка <i class="fa fa-font m-l-xs text-muted"></i>
                                  </div>
                                </div>

                                <div class=" col-sm-8">

                                  <div class="editable-rec">
                                    <select class="form-control form-control-edittable"
                                            options="k as v for (k, v) in encodingList"
                                            [(ngModel)]="importOptions.encoding"
                                            name="importOptionsForm_encoding"
                                            (ngModelChange)="onUpdateImportOptions()"
                                            [required]="true"
                                            placeholder="Выбор кодировки"
                                    >
                                      <option *ngFor="let opt of encodingList | keyvalue"
                                              [value]="opt.key"
                                      >
                                        {{ opt.value }}
                                      </option>
                                    </select>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 m-b-xs">
                              <div class="row">

                                <div class=" col-sm-4 text-right text-ellipsis">
                                  <div class="m-t-xs">
                                    Со строки <i class="fa fa-align-justify m-l-xs text-muted"></i>
                                  </div>
                                </div>

                                <div class=" col-sm-8">

                                  <div class="editable-rec">
                                    <input type="number"
                                           class="form-control form-control-edittable"
                                           [(ngModel)]="importOptions.fromString"
                                           (ngModelChange)="onUpdateImportOptions()"
                                           name="importOptionsForm_fromString"
                                           placeholder="Позиция"
                                           [required]="true"
                                           [min]="1"
                                           [max]="65535"
                                           [required]="true">
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div class="card no-back" *ngIf="showOption('delimiter')">

                        <div class="card-header ">
                          <i class="fa fa-th-large m-r-xs"></i> <span class=" ">  Разделитель </span>
                          {{delimiterOptions.index}}
                        </div>

                        <div class="card-body p-t-none">

                          <div class="row m-t-sm align-items-end">

                            <div class="col-sm-10 offset-sm-2 offset-sm-2 col-sm-4 offset-lg-2 col-md-3">

                              <div class="radio">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index" value="tab" name="importOptionsForm_delimiter1" (ngModelChange)="onUpdateDelimiterOptions()">
                                  <i></i>
                                  Табуляция
                                </label>
                              </div>

                            </div>

                            <div class="col-sm-10  col-sm-4 offset-sm-0 col-md-3">

                              <div class="radio">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index" [value]="'comma'" name="importOptionsForm_delimiter2"  (ngModelChange)="onUpdateDelimiterOptions()">
                                  <i></i>
                                  Запятая
                                </label>
                              </div>

                            </div>


                            <div class="col-sm-10 offset-sm-2 offset-sm-2 col-sm-4 col-md-3 offset-md-0">

                              <div class="radio">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index"
                                         [value]="'semicolon'"
                                         name="importOptionsForm_delimiter3"
                                         (ngModelChange)="onUpdateDelimiterOptions()"
                                  >
                                  <i></i>
                                  Точка с запятой
                                </label>
                              </div>

                            </div>


                            <div class="col-sm-10 offset-sm-2   col-sm-4 offset-sm-0 col-md-3 offset-md-2">

                              <div class="radio">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index"
                                         [value]="'space'"
                                         name="importOptionsForm_delimiter4"
                                         (ngModelChange)="onUpdateDelimiterOptions()"
                                  >
                                  <i></i>
                                  Пробел
                                </label>
                              </div>

                            </div>

                            <div class="col-sm-10 offset-sm-2  offset-sm-2 col-sm-4 offset-md-0 offset-md-0 col-md-3 ">

                              <div class="radio">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index" [value]="'colon'" name="importOptionsForm_delimiter5"  (ngModelChange)="onUpdateDelimiterOptions()">
                                  <i></i>
                                  Двоеточие
                                </label>
                              </div>

                            </div>

                            <div class="col-sm-10 offset-sm-2  offset-sm-0 col-sm-; offset-md-0 offset-md-0 col-md-4 ">

                              <div class="checkbox inline m-r-sm">
                                <label class="i-checks">
                                  <input type="radio" [(ngModel)]="delimiterOptions.index" [value]="'other'" name="importOptionsForm_delimiter6"  (ngModelChange)="onUpdateDelimiterOptions()">
                                  <i></i>
                                  Другое
                                </label>
                              </div>

                              <div class="editable-rec inline w-xs m-t-xs">
                                <input type="text"
                                       [disabled]=" delimiterOptions.index !== 'other' "
                                       [required]=" delimiterOptions.index === 'other' "
                                       class="form-control form-control-edittable"
                                       [(ngModel)]="delimiterOptions.otherValue"
                                       (ngModelChange)="onUpdateDelimiterOptions()"
                                       name="delimiterOptions_otherValue"
                                       placeholder="разделитель"
                                       [maxlength]="1"
                                >
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>

                      <div class="card no-back">

                        <div class="card-header p-b-none">
                          <i class="fa fa-th m-r-xs"></i> <span class=" ">  Поля </span>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="row-row">

                    <div class="cell">

                      <div class="cell-inner ">

                        <div class="card-body p-t-none no-padder pos-rlt">

                          <table class="table table-bordered table-striped m-b-none">

                            <thead>

                            <tr class="border-none">
                              <ng-container *ngIf="!!tableRows?.table?.length">
                                <th class="text-center " *ngFor="let cell of tableRows?.table[0]?.row || []; let $index = index">


                                <!--    <div class="clearfix m-b-xs">SKU</div>

                                    <label class="i-checks">
                                        <input type="radio" name="barcodeIndex" [(ngModel)]="importOptions.barcodeIndex" [value]="$index" >
                                        <i></i>
                                    </label>
            -->
                                <div class="btn-group w-full" ngbDropdown>
                                  <button type="button"
                                          class="btn w-full btn-default btn-sm"
                                          [ngClass]=" { 'b-danger ng-invalid' : !isValidColums() }"
                                          ngbDropdownToggle>
                                    {{ getColumnByIndex($index) }} <span class="caret"></span>
                                  </button>

                                  <ul class="dropdown-menu" ngbDropdownMenu role="menu"
                                      aria-labelledby="simple-btn-keyboard-nav">
                                    <li role="menuitem" class="no-padder" ngbDropdownItem><a href="javascript:void(0);" (click)="setColumnIndex( false, $index)"> -- </a></li>
                                    <li role="menuitem" class="no-padder" ngbDropdownItem *ngIf="isRequiredSKU()"><a href="javascript:void(0);" (click)="setColumnIndex( 'SKUIndex', $index)">SKU</a></li>
                                    <li role="menuitem" class="no-padder"  ngbDropdownItem><a href="javascript:void(0);" (click)="setColumnIndex( 'barcodeIndex', $index)">Штрихкод</a> </li>
                                    <li role="menuitem" class="no-padder"  ngbDropdownItem *ngIf="isRequiredDates()"><a href="javascript:void(0);" (click)="setColumnIndex( 'dateFromIndex', $index)">Дата С <span class="text-muted f-s-9">(ГГГГ-ММ-ДД)</span></a> </li>
                                    <li role="menuitem" class="no-padder"  ngbDropdownItem *ngIf="isRequiredDates()"><a href="javascript:void(0);" (click)="setColumnIndex( 'dateToIndex', $index)">Дата По <span class="text-muted f-s-9">(ГГГГ-ММ-ДД)</span></a> </li>
                                    <li role="menuitem" class="no-padder"  ngbDropdownItem *ngIf="isRequiredCount()"><a href="javascript:void(0);" (click)="setColumnIndex( 'countIndex', $index)">Количество товара</a> </li>
                                    <li role="menuitem" class="no-padder"  ngbDropdownItem *ngIf="isRequiredReward()"><a href="javascript:void(0);" (click)="setColumnIndex( 'rewardIndex', $index)">Вознаграждение</a> </li>


                                  </ul>

                                </div>


                              </th>
                              </ng-container>
                            </tr>
                            </thead>
                            <tbody>

                            <tr *ngFor="let rowItem of tableRows?.table || []" style="min-height: 37px;">
                              <td *ngFor="let  cell of  rowItem?.row || []; let $index = index"
                                  [ngClass]="{ 'text-muted' : $index != importOptions.barcodeIndex && $index != importOptions.rewardIndex }"
                                  [innerHtml]=" cell.cell || '&nbsp;'"></td>
                            </tr>

                            </tbody>

                          </table>

                          <div class="text-center bg-light item-overlay block" *ngIf="isLoadPreview">
                            <i class="fa fa-spin fa-spinner text-warning fa-4x" style="margin-top: 40px "></i>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>

            </ng-container>

            <ng-container
              *ngSwitchCase="'import'"
            >

              <div class="vbox">

                <div class="row-row" style="height: 2%">

                  <div class="cell">
                  </div>
                </div>
                <div class="row-row" style="height: 60%">

                  <div class="cell ">
                    <div class="cell-inner logo-container ">

                    </div>
                  </div>
                </div>

                <div class="row-row" style="height: 10%">

                  <div class="cell">
                  </div>
                </div>

                <div class="row-row">

                  <div class="cell">
                    <div class="clearfix">
                      <div class="col-sm-8 offset-sm-2 text-center">
                        <!--
                                                <uib-progressbar class="progress-striped active" value="progressPercent" type="success">{{progressPercent}}</uib-progressbar>-->

                        <div class="clearfix m-t-xs font-bold text-muted">Импорт SKU из файла</div>
                        <div class="clearfix m-t-xs text-muted f-s-9">Ожидайте</div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </ng-container>



          </div>

        </div>

      </div>

  </div>

</form>


import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy, NgZone, ViewChildren, QueryList
} from "@angular/core";
import { getNg1Service } from '../../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../shared/service/confirmAction/confirmAction.service";

import "./coupons-rules-view-page.legacy";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {CouponRuleService} from "../../../../shared/service/api/CouponRule/CouponRule.service";
import {COUPON_RULE_STATES} from "../../../../shared/service/api/CouponRule/CouponRule.values";
import {NgForm} from "@angular/forms";
import {
  CampaignRowViewComponent
} from "../../../campaign/campaigns/shared/components/campaign-row-view/campaign-row-view.component";
import {CouponRuleEditComponent} from "../components/coupon-rule-edit/coupon-rule-edit.component";
import {CouponRuleViewComponent} from "../components/coupon-rule-view/coupon-rule-view.component";

@Component({
  selector: 'coupons-rules-view-page',
  templateUrl: './coupons-rules-view-page.component.html',
  styleUrls:[`./coupons-rules-view-page.component.scss`],
  providers: [
    StatesChangerService,
    CouponRuleService
  ]
})
export class CouponsRulesViewPageComponent implements OnInit, OnChanges, OnDestroy{

  @Input() id: string;
  private uiRouterState;

  public couponRuleItem:any;
  public couponRuleResources;

  public COUPON_RULE_STATES = COUPON_RULE_STATES;

  public currentIssueType;
  public currentCouponKind;
  public hasRuleImportedCoupons = false;

  public anchorList;

  public loader = new LoaderHandlerClass();
  public unsubscribers: any = {}

  public formStates;
  public curState;
  public $state = getNg1Service('$state');

  @ViewChildren(CouponRuleEditComponent)
  public couponRuleEditComponent: QueryList<CouponRuleEditComponent>;
  @ViewChildren(CouponRuleViewComponent)
  public couponRuleViewComponent: QueryList<CouponRuleViewComponent>;

  constructor(
    public statesChangerService: StatesChangerService,
    public confirmActionService: ConfirmActionService,
    public couponRuleService: CouponRuleService,
    private ngZone: NgZone,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }

  isLocationsError() {
    return !this.couponRuleItem?.locationGroups?.length && !this.couponRuleItem?.locations?.length;
  }

  isRuleUnicCouponRule() {
    return this.couponRuleItem?.couponRule?.state !==  COUPON_RULE_STATES.draft  &&
      [  'couponWithBarcode', 'importedCouponForPrint', 'importedCoupon' ].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.couponKind) >=0 &&
      [  'pos', 'external', 'uniqueCouponGen'  ].indexOf( this.couponRuleItem?.couponRule?.issueSettings?.issuingType )  >=0;
  };


  isValidRule() {

    if (
      !!this.couponRuleViewComponent.length &&
      this.couponRuleViewComponent.first.currentCouponKind?.hasImportCoupons &&
      !this.hasRuleImportedCoupons) {
      return false;
    }

    if (
      !!this.couponRuleEditComponent.length &&
      this.couponRuleEditComponent.first.currentCouponKind?.hasImportCoupons &&
      !this.hasRuleImportedCoupons) {
      return false;
    }

    return true;
  };

  exportGenerated() {
    window.location.href = window.location.protocol + '//' + window.location.host +
      `/coupon/rule/${this.couponRuleItem?.couponRule?.id}/exportGeneratedCoupons` ;
  };

  ngOnInit() {
    this.bindButtons();
    this.statesChangerService.changeState('view');
  }

  setFormState(state) {

    if (this.formStates[state] !== undefined) {
      this.curState = this.formStates[state];
      this.curState.stateName = state;
    } else
      this.curState = false;

  };

  bindButtons() {
    this.statesChangerService.createButton('view','edit'   , this.onEdit.bind(this)  );
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
  }

  checkRunButtonDisabled() {
    return this.couponRuleItem?.mechanic?.status=='template' || this.couponRuleItem?.informing?.status=='template'
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getOrCreateCouponRule(changes.id.currentValue)
        .then( result => {

            this.statesChangerService.changeState(this?.couponRuleItem?.couponRule?.id >=0 ?
              'view' :
              'edit');

        })
      ;
    }
  }

  getOrCreateCouponRule( id:any ) {

    return this.couponRuleService
          .getOrCreate$(parseInt(id))
          .pipe(
            this.loader.waitLoading('loading')
          )
          .toPromise()
          .then( result => {
              this.couponRuleItem = result
          })
  }

  switchToEdit() {
    this.statesChangerService?.changeState('edit');
  }


  onSave() {
/*

    this.couponRuleItem?.couponRule?.couponRuleTime?.periods?.forEach( i => {
        i.startTime = /\d{2}:\d{2}/.test(i.startTime) ? i.startTime + ":00" : i.startTime;
        i.stopTime = /\d{2}:\d{2}/.test(i.stopTime) ? i.stopTime + ":59" : i.stopTime;
    })
*/

    this.loader.startLoading('loading')

    this.couponRuleService
      .createOrUpdate$(this.couponRuleItem)
      .subscribe({
        next: result => {

          this.couponRuleItem = result;
          this.uiRouterState.go('^.view', {id: result?.couponRule?.id}, {reload: true});
        },
        error: er => {
          this.loader.stopLoading('loading')
        }
      })
  }

  onCancel() {
    if (! (this.couponRuleItem?.couponRule?.id >=0 )) {
      this.uiRouterState.go('^.index');
      return
    }

    this.uiRouterState.go('^.view', {id: this.couponRuleItem?.couponRule?.id}, {reload: true});

  }

  onDelete() {
     if ( !this.couponRuleItem?.couponRule?.id )
      return;

    this.confirmActionService.confirm(
      'Вы действительно хотите удалить купонное правило ' + this.couponRuleItem.couponRule.name + ' ?',
      () => {

        this.couponRuleService.delete$(this.couponRuleItem?.couponRule?.id)
          .subscribe(() => {
            this.$state.go('^.index');
          });

      }
    )

  }

  onEdit() {
    this.statesChangerService.changeState('edit');
  }

  setAnchorList(value) {
    this.anchorList = typeof value === "object" ? Object.assign({}, value) : value;
  }

  setRuleState( state ) {

    if (  Object.keys(this.COUPON_RULE_STATES).indexOf(state) <0 ) {
      return false;
    }

    return this.couponRuleService.setState$(
      this.couponRuleItem?.couponRule?.id, state
    )
     .toPromise()
     .then( result => {
       this.couponRuleItem = result;
     });

  };

  duplicateRule() {

    this.couponRuleItem = Object.assign({}, this.couponRuleItem );

    this.couponRuleItem.couponRule.state = 'draft';
    this.couponRuleItem.couponRule.name += " (копия)";

    delete this.couponRuleItem.couponRule.id;
    delete this.couponRuleItem.couponRule.barcode;
    if (this.couponRuleItem?.couponRule?.issueSettings?.generatedCount)
      delete this.couponRuleItem.couponRule.issueSettings.generatedCount

    this.statesChangerService.changeState( 'edit' );

  };

// TODO remove
  showErrors(form: NgForm) {
    return Object.keys(form?.controls)?.filter( key => !!form?.controls[key].errors )?.map( key =>[key, form?.controls[key].errors] )
  }

  ngOnDestroy(): void {
    Object.values( this.unsubscribers )
      .forEach(
        (i: any) => typeof i?.unsubscribe === "function" && i.unsubscribe()
      )
  }

}


<div class="details-screen goods-popup-details">

  <div class="p-t-none ">
    <div class="m-b-sm m-t-sm row padder">

      <div class="col-sm-12 ">
        <div class="h4 relative pull-left">
          <div class="text-ellipsis">

            <i class="fa fa-list pull-left rfm-t1 m-t-xs m-r-sm f-s-12"></i>

            <div class="relative m-t-xs pull-left">
              Выбраны
              <span [ngSwitch]="listType">
                <span *ngSwitchCase="VIEW_TYPES.SKU">товары</span>
                <span *ngSwitchCase="VIEW_TYPES.CATEGORY">категории</span>
                <span *ngSwitchCase="VIEW_TYPES.BRAND">бренды</span>
                <span *ngSwitchCase="VIEW_TYPES.GOODGROUP">списки</span>
                <span *ngSwitchCase="VIEW_TYPES.FAVOURITE">любимые товары</span>
              </span>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-sm btn-default  pull-right " (click)="close()">
          <i class=" fa fa-remove text-danger"></i>
        </button>


      </div>
    </div>
    <div class="m-b"
         infiniteScroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         [infiniteScrollContainer]="'ngb-modal-window'"
         [fromRoot]="true"
         (scrolled)="onScroll()"
    >

      <table *ngIf="listType === VIEW_TYPES.SKU"
             class="table m-b-none">
        <thead>
          <tr>
            <th> SKU  </th>
            <th> Наименование </th>
            <th class="text-center" *ngIf="canDelete">
                Удаление
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let skuItem of items; let index = index" data-testid="skuItem in items">

            <td class=" table-big-link"
                data-testid="item.sku"
            >
              {{ skuItem?.sku || '' }}
            </td>

            <td class="table-big-link" data-testid="item.name">
              {{ skuItem.name || ''}}
            </td>

            <td class="text-center" *ngIf="canDelete">
              <button class="btn btn-danger btn-xs "
                      (click)=" removeSkuById(skuItem, index); $event.stopImmediatePropagation()"
                      data-testid="item.removeSkuById()">
                <i class="fa fa-times"></i>
              </button>

            </td>

          </tr>

        </tbody>
      </table>

      <table *ngIf="listType === VIEW_TYPES.CATEGORY"
         class="table m-b-none  "
      >
        <thead>
          <tr>
            <th>
              Наименование
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let categoryItem of items"
              data-testid="categoryItem in items"
          >

            <td class="table-big-link">
              <div class="clearfix" data-testid="categoryItem.name">
                {{ categoryItem.name }}
              </div>

              <ul class="clearfix padder f-s-8 category-details"
                  *ngIf="categoriesPath[categoryItem.categoryId]"
              >

                <li class="level text-muted"
                    *ngFor=" let item of categoriesPath[categoryItem.categoryId] ">
                  <span [innerHTML]="item.name"></span>
                </li>

              </ul>

            </td>

          </tr>
        </tbody>
      </table>

      <table *ngIf="listType === VIEW_TYPES.BRAND"
             class="table m-b-none  "
      >
        <thead>
          <tr>
            <th>
              Наименование
            </th>

            <th>
              Описание
            </th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let brandItem of items" data-testid="brandItem in items">

          <td class="table-big-link" data-testid="brandItem.name">
            {{ brandItem.name  || ''}}
          </td>

          <td class="table-big-link" data-testid="brandItem.description">
            {{ brandItem.description || '' }}
          </td>


        </tr>

        </tbody>
      </table>

      <table *ngIf="listType === VIEW_TYPES.GOODGROUP "
             class="table m-b-none  "
      >
        <thead>
          <tr>
            <th>
              Наименование
            </th>

            <th>
              Описание
            </th>

          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let customItem of items"
              data-testid="customItem in items"
          >

            <td class="table-big-link" data-testid="customItem.name">
              {{ customItem.name || '' }}
            </td>

            <td class="table-big-link" data-testid="customItem.description">
              {{ customItem.description || '' }}
            </td>

          </tr>
        </tbody>
      </table>

      <table *ngIf="listType === VIEW_TYPES.GOODGROUP_SKU"
             class="table m-b-none  "
      >
        <thead>
          <tr>
            <th>
                Наименование
            </th>

            <th>
              Описание
            </th>

          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let customItem of items"
            data-testid="customItem in items"
        >

          <td class="table-big-link"
              data-testid="customItem.name"
          >
            {{ customItem.name || '' }}
          </td>

          <td class="table-big-link"
              data-testid="customItem.description"
          >
            {{ customItem.description || '' }}
          </td>

        </tr>

        </tbody>
      </table>

      <div *ngIf="listType === VIEW_TYPES.FAVOURITE"
           class="f-s-12 text-center wrapper-md padder" data-testid="favouriteItem"
      >
        Список любимых товаров определяется индивидуально для каждого клиента
      </div>

    </div>

    <div class="clearfix wrapper-md text-center"
         *ngIf="isLoading"
    >
      <i class="fa fa-spin fa-spinner fa-3x text-success"></i>
    </div>

  </div>

</div>


import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserApiService} from "../../../../api/UserApi/services";
import {Observable} from "rxjs";
import {ClientApiService} from "../../../../api/ClientApi/services/client-api.service";

@Injectable()
export class ClientService {

  constructor( private clientApiService:ClientApiService ) {
  }


  public getClient$( id? :number ){
    if (!id)
      return  new Observable(s => {
        s.next({});
        s.complete();
      });

    return this.clientApiService.get(id);
  }

  public query$(params: ClientApiService.QueryParams | ClientApiService.SearchParams ) {
    if (!!(<any>params).sentence)
      return this.search$(params);

    return this.clientApiService.query(params);
  }

  public search$(params: ClientApiService.SearchParams ) {

    params.params = params.params || {};

    return this.clientApiService.search(
      params
    );
  }

}

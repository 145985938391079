import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {downgradeComponent, downgradeInjectable, UpgradeModule} from "@angular/upgrade/static";
import {CouponRuleDialogComponent} from "./coupon-rule-dialog.component";

import {TreeModule} from "@circlon/angular-tree-component";
import {SearchInputModule} from "../search-input/search-input.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {SharedComponentsModule} from "../shared.components.module";
import {CouponRuleDialogService} from "./service/coupon-rule-dialog.service";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {CouponRuleViewComponent} from "./components/coupon-rule-view/coupon-rule-view.component";
import {DirectiveModule} from "../../directive/directive.module";
import {PipesComponentsModule} from "../../pipes/pipes.components.module";


@NgModule({
  declarations: [
    CouponRuleViewComponent,
    CouponRuleDialogComponent,
  ],
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        UpgradeModule,
        TreeModule,
        SearchInputModule,
        InfiniteScrollModule,
        SharedComponentsModule,
        NgbDropdownModule,
        DirectiveModule,
        PipesComponentsModule,
    ],
  providers: [
    CouponRuleDialogService
  ],
  entryComponents:[],
    exports: [
        CouponRuleDialogComponent,
        CouponRuleViewComponent
    ]
})
export class CouponRuleDialogModule {

  constructor() {}

}

//-- LEGACY ---

angular.module('loya.builder')
  .factory('CouponRuleDialog', downgradeInjectable(CouponRuleDialogService))

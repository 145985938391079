<div class="cash-box-items_header bg-light b-b b-dashed">

  <div class="cash-box-items_header_input">

    <div class="input-group" >
      <span class="input-group-addon">
        <i class="fa fa-spin fa-spinner mr-1"
           *ngIf="skuInputWidget.isLoading"
        ></i>
        SKU
      </span>
      <input type="text"
             class="form-control "
             placeholder="SKU для добавления"
             [(ngModel)]="skuInputWidget.skuForAdd"
             maxlength="500"
             [disabled]="skuInputWidget.isLoading || !cashBoxService.partnerId"
             (keydown.enter)="searchBySku()"
             data-testid="cashbox.skuForAdd.input"
      />
      <span class="input-group-btn ">
        <button class="btn btn-small btn-success "
                [disabled]="skuInputWidget.isLoading || !cashBoxService.partnerId"
                (click)="searchBySku()"
                data-testid="cashbox.skuForAdd.button"
        >
          <i class="fa fa-plus"></i> Добавить
        </button>
      </span>

    </div>

  </div>

  <div class="cash-box-items_header_catalog">

    <button class="btn btn-small btn-success btn-addon"
            (click)="addFromPopup()"
    >
      <i class="fa fa-plus"></i>
      Добавить из каталога
    </button>

  </div>

</div>

<div class="cash-box-items_header_error bg-light b-b b-dashed pointer text-danger"
     *ngIf="!!skuInputWidget.errorText"
     [innerText]="skuInputWidget.errorText"
>
</div>

<div class="cash-box-items_items">

  <div class="cash-box-items_items_header bg-light b-b">
    <div class="row">
      <div class="col-sm-1 wrapper-xs padder-md text-center">
        №
      </div>

      <div class="col-sm-2 wrapper-xs text-center">
        SKU
      </div>

      <div class="col-sm-4 wrapper-xs text-left">
        Наименование
      </div>

      <div class="col-sm-2 wrapper-xs text-center">
        Кол-во
      </div>

      <div class="col-sm-2 wrapper-xs text-right ">
        Цена
      </div>

      <div class="col-sm-1">
      </div>

    </div>
  </div>

  <div class="cash-box-items_items_row-container">

    <div class="cash-box-items_items_row-container mini-scroll bg-white">

      <ng-container
        *ngFor="let item of cashBoxService.items; let index = index"
      >
        <cash-box-items-row
          [item]="item"
          [index]="index + 1"
          [label]="getName(item)"
          [showAdditionalInfo]="cache.showAdditional.has(item?.sku + item?.sku ) ? cache.showAdditional.get(item?.sku + item?.sku ) : false"
          (showAdditionalInfoChange)="cache.showAdditional.set(item?.sku + item?.sku, $event )"
          (onDelete)="deleteItem($event)"
          data-testid="cashbox.items.row"
        ></cash-box-items-row>
      </ng-container>

      <div class=" wrapper padder w-full"
           *ngIf="!!informingMessages?.length"
      >

        <div class="card m-t-sm m-b-sm pointer"
             *ngFor="let info of informingMessages"
             (click)="removeInforming(info)"
        >
          <div class="hbox hbox-auto " >

            <div class="col v-middle text-center wrapper-xs w-xxs bg-light b-r">
              <i class="fa fa-info-circle fa-lg text-info" aria-hidden="true"></i>
            </div>

            <div class="col text-left padder f-s-11 wrapper-sm">
              <span [innerHTML]="info"></span>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</div>


<div class="cash-box-items_footer bg-light b-t f-s-15 "
     *ngIf="hasTotal()"
     (click)="showTotalAdditional = !showTotalAdditional"
     [class.pointer]="hasTotalAdditional()"
>

    <i class="fa text-primary-dker m-r-xs"
       *ngIf="hasTotalAdditional()"
       [ngClass]="{ 'fa-chevron-up': showTotalAdditional , 'fa-chevron-down': !showTotalAdditional  }"> </i>

    <span
      class="text-primary m-r-xxs"
      [innerText]="total()  | loyaCurrency"></span>

    <i class="fa text-primary" ngCurrencyLoya ></i>

</div>

<div class="cash-box-items_total-details bg-light dk "
     *ngIf="hasTotalAdditional() && showTotalAdditional && hasTotal()"
>
  <receit-details-item
    [items]="cashBoxService.currentCheck.results"
    [usedAwards]="cashBoxService.currentCheck.usedAwards"
  ></receit-details-item>
</div>

import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {CashBoxEvents, CashBoxService} from "../../service/cash-box.service";
import {PartnerService} from "../../../../service/api/Partner/Partner.service";
import {Partner} from "../../../../../api/PartnerApi/models/partner";
import {LocationsService} from "../../../../service/api/Locations/Locations.service";
import {LocationsDialogService} from "../../../../component/locations-dialog/service/locations-dialog.service";

@Component({
  selector: 'cash-box-header',
  templateUrl: "./cash-box-header.component.html",
  styleUrls: ['./cash-box-header.component.scss'],
  providers: [
    PartnerService
  ]
})
 export class CashBoxHeaderComponent implements OnInit, OnChanges, OnDestroy {

  public partnerList: Partner[];
  public currentPartner: string;

  public cache = {
    merchant: new Map<string, string>()
  }

  public loading = {
    partner: false,
    merchant: false
  }

  public unsubscribers: any = {};

  constructor(
      public cashBoxService : CashBoxService,
      public partnerService : PartnerService,
      public locationsService : LocationsService,
      public locationsDialogService: LocationsDialogService,
  )
  {
  }

  get hasMerchant() {
    return !!this.cashBoxService?.currentCheck?.merchant;
  }

  get hasPartner() {
    return !!this.cashBoxService?.partnerId;
  }

  ngOnInit() {

    this.getPartnerList();
    this.unsubscribers.cashBoxServiceEvents =
        this.cashBoxService
          .events
          .subscribe(
            (event) => {

              switch (event.type) {
                case CashBoxEvents.CHANGE_PARTNER:
                  this.selectDefaultMerchant();
                  break;
                case CashBoxEvents.CREATE:
                  this.checkPartner();
                  break;
                case CashBoxEvents.CHANGE_MERCHANT:
                  this.unsubscribers.location?.unsubscribe();
                  break;
              }
          })

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  getPartnerList() {
    this.loading.partner = true;
    this.partnerService
        .query$()
        .subscribe(
      (res) => {
              this.partnerList = res;
              this.loading.partner = false;
              this.checkPartner();
            }, () => {
            this.loading.partner = false;
          }
    )
  }

  checkPartner() {
    if (!this.cashBoxService.partnerId) {
      this.selectPartner(this.partnerList?.[0]);
    }
  }

  getPartnerName(id: string) {
    const findPartner = this.partnerList?.find(partner => partner.id.toString() === id.toString() );
    return findPartner?.name || 'Пфртнер ID:'+ id;
  }

  selectPartner(partnerItem: Partner) {
    if (!partnerItem)
      return;

    this.cashBoxService.partnerId = partnerItem?.id?.toString() || undefined;
  }

  selectDefaultMerchant() {
    if (!this.cashBoxService.partnerId)
      return;

    this.unsubscribers?.location?.unsubscribe();

    this.loading.merchant = true;
    this.unsubscribers.location = this.locationsService
        .getList$({
          filter: {
            partnerId: Number(this.cashBoxService.partnerId)
          },
          pager: {
            drop: 0,
            limit: 1,
          }
        })
        .subscribe(
          (res) => {
            this.loading.merchant = false;

            let store = res?.[0];
            if (!store) {
              this.cashBoxService.merchantId = undefined;
              return;
            }

            this.cache.merchant.set(`${this.cashBoxService.partnerId}:${store?.code}`, store?.name);
            this.cashBoxService.merchantId = store?.code?.toString() || undefined;
          }, () => {
            this.loading.merchant = false;
          }
        )
  }

  getMerchantName( merchantCode: string ) {

    const storageId  = `${this.cashBoxService.partnerId}:${merchantCode}`

    if (this.cache?.merchant?.has(storageId)) {
      return this.cache.merchant.get(storageId);
    }

    this.cache.merchant.set( storageId, 'Магазин ID:' + merchantCode );
    this.unsubscribers.locationName = this.locationsService
        .get$(merchantCode)
        .subscribe({
          next: (res) => {
            this.cache.merchant.set(storageId, res?.name);
          },
          error: () => {}
        });

    return this.cache.merchant.get(storageId);
  }

  selectStore() {
    let params: any = {
      initPartner: this.cashBoxService.partnerId,
      canCheckAll: false,
      checkOne: true,
    };

/*    if (this.cashBoxService.merchantId) {
      params.initSelectedItems =  [this.cashBoxService.merchantId]
    }*/

    this.locationsDialogService
        .open(params)
        .then( result => {
          let merchant = result.locations?.[0];
          if (!merchant)
            return;

          this.cashBoxService.partnerId = merchant?.partnerId?.toString();
          this.cashBoxService.merchantId = merchant?.code?.toString();

          this.cache.merchant.set(`${this.cashBoxService.partnerId}:${merchant?.code}`, merchant?.name);

          this.loading.merchant = false;
          this.loading.partner = false;

        }, () => {});
  }

  ngOnDestroy() {
    Object.keys(this.unsubscribers)?.forEach(key => typeof this.unsubscribers[key] === "function" && this.unsubscribers[key]() );
  }

}

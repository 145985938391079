import { Component, Input, Output, EventEmitter} from '@angular/core';
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {InfiniteSelectClass} from "../../class/utils/infinite-select.class";
import {CampaignService} from "../../service/api/Campaign/Campaign.service";
import {map} from "rxjs/operators";
import {CAMPAIGN_STATES} from "../../service/api/Campaign/Campaign.values";
import {CardService} from "../../service/api/Card/Card.service";
import {CardState} from "../../../api/CardApi/models";
import {ControlContainer, NgForm} from "@angular/forms";
import {ClientService} from "../../service/api/Client/Client.service";

@Component({
  selector: 'select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss'],
  providers: [
    CardService
  ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SelectClientComponent
  extends InfiniteSelectClass {

  @Input() model: number;
  @Output() modelChange = new EventEmitter<number>();

  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() placeholder = "Выбор клиента";

  @Input() validator: Function;

  @Input() digest: EventEmitter<any>;
 // @Input() filterStates = [ CardState.ACTIVE, CardState.BLOCKED, CardState.ARCHIVE, CardState.NONACTIVE ]

  drop    = 0;
  limit   = 20;
  isValid = true;

  digestSubscribe;
  dummy;

  constructor(
    private clientService: ClientService,
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.digest) {
      this.digestSubscribe = this.digest.subscribe(this.digestValidate.bind(this));
    }

  }

  digestValidate() {

    this.filteredItems = this.items;

    this.validate();
  }

  onChange() {
    this.modelChange.emit(this.model);
  }

  pagerReset() {
    this.drop = 0;
  }

  pagerNext( searchText:string | boolean ) {

    let that = this;
    let params : any = {
      drop  : this.drop,
      limit : this.limit,
    //  filterStates: this.filterStates
    }

    if (searchText) {
      params.sentence = searchText;
    }

    return this.clientService
      .query$(params)
      .pipe(
        map(data => {

            that.drop += data.length;

            this.checkDigest();
            return data;

          })
        )

  }

  checkDigest() {
    if (this.digest)
      return;

    setTimeout(() => {
      this.digestValidate();
    });

  }


  validate(){

    this.isValid = true;

    switch (true) {

      case this.required && ( typeof this.model === "undefined" || this.model === null ): this.isValid = false; break;
      case typeof this.validator === "function": this.isValid = this.validator(this.model); break;
      case Array.isArray(this.validator) && typeof this.validator[0] === "function": this.isValid = this.validator[0](this.model); break;

    }

  }

  get isEmpty() {
    return typeof this.model === "undefined" || this.model === null || <any>this.model === '';
  }

  searchFunction()  {
    return true;
  }

  ngOnDestroy() {
    if (this.digestSubscribe)
      this.digestSubscribe.unsubscribe();
  }

}


<ng-container *ngFor="let item of mechanicItem.conditionList; let i_cond = index">

  <div [form-item-row] = "item?.condition?.description"
       [form-item-row-input]="isEdit"
  >
    <ng-container *ngFor="let eventItem of item?.eventList; let i = index">
      <div class="form-group"
           *ngIf="isEdit"
      >

        <div class="d-flex w-full">

          <ng-select [ngModel]="getEvent(eventItem)"
                   (ngModelChange)="setEvent($event, eventItem)"
                   [items]="MECHANIC_EVENTS"
                   bindLabel="name"
                   data-testid="communication.input.mechanic"

                   [name]="getNameEvent(eventItem)"

                   [placeholder]="'Выбор условия'"
                   [required]="true"
                   [multiple]="false"
                   [clearable]="false"
                   [groupBy]="'group'"

                   [disabled]="!communicationItem.campaign?.partnerId"

                   class="w-full"
        >

          <ng-template ng-label-tmp let-item="item">

            <div class="hbox hbox-auto">
              <div class="col w-full">
                <div class="clearfix">
                  <i
                    [ngClass]="{ 'text-success':(item.icon==='tag'), 'text-info': (item.icon!=='tag') }"
                    class="{{ 'm-r-xs fa fa-' + item.icon }}"></i>
                  {{ item.name }}
                </div>

              </div>

            </div>

          </ng-template>

          <ng-template ng-multi-label-tmp>
            <div class="inline-block m-t-n-xs m-l-xs m-r-xs">
              Выбрано групп ({{id?.length || 0}})
            </div>
          </ng-template>

          <ng-template ng-option-tmp
                       let-item="item"
                       let-index="index"
          >

            <div class="hbox hbox-auto">


              <div class="col">
                <icon class="rounded inline text-center m-r-xs m-l-none f-s-7"
                      [ngClass]="{ 'bg-success':(item.icon==='tag'), 'bg-info': (item.icon!=='tag') }"
                      style="width: 2em; height: 2em;  margin: -3px 5px -3px 10px; line-height: 2em;">
                  <i class="{{ 'fa fa-' + item.icon }}"></i>
                </icon>
              </div>

              <div class="col w-full">

                <div class="clearfix">
                  <search-highlight
                    [model]="lastSearchText || ''"
                    [text]="item.name"
                  ></search-highlight>
                </div>

              </div>

            </div>

          </ng-template>
        </ng-select>

          <div class="col-auto">
            <button
              type="button"
              class="btn  btn-danger"
              data-testid = "communication.deleteCondition"
              (click)="deleteEvent(eventItem, item)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>

      </div>

      <div class="w-full"
           *ngIf="!isEdit"
           data-testid="communicationMechanicName"
      >
        <i
          [ngClass]="{ 'text-success':(getEvent(eventItem)?.icon==='tag'), 'text-info': (getEvent(eventItem)?.icon!=='tag') }"
          class="{{ 'm-r-xs fa fa-' + getEvent(eventItem)?.icon }}" ></i> {{ getEvent(eventItem)?.name || ''}}
      </div>

      <div class="w-full m-b p-r"
           [ngSwitch]="getEvent(eventItem)?.tpl"
           [ngClass]="{'p-l-md': !isEdit}"
      >

        <communication-mechanic-daysfromregistry
          *ngSwitchCase="'event/client/daysfromregistry'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-daysfromregistry>

        <communication-mechanic-daysfromlast
          *ngSwitchCase="'event/client/daysfromlast'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-daysfromlast>

        <communication-mechanic-maxcheck
          *ngSwitchCase="'event/client/maxcheck'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-maxcheck>

        <communication-mechanic-total
          *ngSwitchCase="'event/client/total'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-total>

        <communication-mechanic-lifevalue
          *ngSwitchCase="'event/client/lifevalue'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-lifevalue>

        <communication-mechanic-profilestate
          *ngSwitchCase="'event/client/profilestate'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-profilestate>

        <communication-mechanic-availablechannel
          *ngSwitchCase="'event/client/availablechannel'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-availablechannel>

        <communication-mechanic-disablechannel
          *ngSwitchCase="'event/client/disablechannel'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        >
        </communication-mechanic-disablechannel>

        <communication-mechanic-afterfullregistration
          *ngSwitchCase="'event/client/afterfullregistration'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-afterfullregistration>

        <communication-mechanic-afterexpressregistration
          *ngSwitchCase="'event/client/afterexpressregistration'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        >
        </communication-mechanic-afterexpressregistration>

        <communication-mechanic-birthday
          *ngSwitchCase="'event/client/birthday'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-birthday>


        <communication-mechanic-common
          *ngSwitchCase="'event/goods/common'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
          [partnerId]="communicationItem.campaign.partnerId"
        ></communication-mechanic-common>

        <communication-mechanic-listQuantity
          *ngSwitchCase="'event/goods/listQuantity'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
          [partnerId]="communicationItem.campaign.partnerId"
        ></communication-mechanic-listQuantity>

        <communication-mechanic-commonQuantity
          *ngSwitchCase="'event/goods/quantity'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
          [partnerId]="communicationItem.campaign.partnerId"
        ></communication-mechanic-commonQuantity>

        <communication-mechanic-commonAmount
          *ngSwitchCase="'event/goods/quantity_total'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
          [partnerId]="communicationItem.campaign.partnerId"
        ></communication-mechanic-commonAmount>

        <communication-mechanic-list
          *ngSwitchCase="'event/goods/list'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
          [partnerId]="communicationItem.campaign.partnerId"
        ></communication-mechanic-list>

        <communication-mechanic-uniq
          *ngSwitchCase="'event/goods/uniq'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-uniq>

        <communication-mechanic-total-total
          *ngSwitchCase="'event/total/total'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-total-total>

        <communication-mechanic-firstPurchase
          *ngSwitchCase="'event/client/firstPurchase'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-firstPurchase>

        <communication-mechanic-maxPurchase
          *ngSwitchCase="'event/client/maxPurchase'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-maxPurchase>

        <communication-mechanic-totalPerSeason
          *ngSwitchCase="'event/client/totalperseason'"
          [isEdit]="isEdit"
          [eventItem]="eventItem"
        ></communication-mechanic-totalPerSeason>

      </div>

      <div class="w-full  text-xs text-muted  m-t-n-sm m-b-xs text-center"
           *ngIf="isEdit && (item?.eventList?.length > 1 && i < item?.eventList?.length - 1)"
      >
        и
      </div>

    </ng-container>

    <div class="d-flex w-full"
         *ngIf="isEdit"
    >
      <div class="w-full text-right">
        <div class="text-muted text-xs m-t-sm">
          Добавить условие
        </div>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn  btn-success btn-sm m-r-xxs"
          data-testid = "communication.addCondition"
          [disabled]="item?.eventList?.length > 9"
          (click)="addEvent(item)"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="w-full"
       *ngIf="isEdit && (mechanicItem?.conditionList?.length >= 2 && i_cond < mechanicItem?.conditionList?.length - 1)"
  >
    <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>
    <div class="text-center text-muted text-xs">или</div>
    <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>
  </div>

</ng-container>

<div [form-item-row] = "' '"
     form-item-row-input="true"
     *ngIf="isEdit"
>

  <button
    type="button"
    class="btn btn-sm btn-addon btn-success"
    data-testid = "communication.addRangeCondition"
    (click)="addCondition()"
    [disabled]="mechanicItem.conditionList?.length > 2"
  >
      <i class="fa fa-plus"></i>
      <span> Добавить набор условий </span>
  </button>

</div>

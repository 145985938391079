<anchor-menu
  [anchorList]="anchorList"
>
  <form name="campaignForm" #campaignForm="ngForm">

    <div class="fixed-tool-panel ">

      <button type="button" class="btn btn-sm btn-addon  btn-default pull-right"
              [disabled]="campaignForm.invalid || isLocationsError() "
              *ngIf="statesChangerService.hasButton('save')"
              (click)="statesChangerService.click('save')"
              data-testid="cRule.save()">
        <i class=" fa fa-check text-primary">
        </i>
        <span class="btn-name m-l-xs  ">Сохранить</span>
      </button>

      <button type="button" class="btn btn-sm btn-addon btn-default pull-right"
              *ngIf="statesChangerService.hasButton('cancel')"
              (click)="statesChangerService.click('cancel')"
              data-testid="cRule.cancel()">

        <i class=" fa fa-remove text-danger">
        </i>
        <span class="btn-name">Отменить</span>
      </button>



      <div class="editable-rec"
           *ngIf="!statesChangerService.isActive && !!couponRuleItem?.couponRule?.id">
         <span [ngSwitch]="couponRuleItem?.couponRule?.state">

             <span *ngSwitchCase="COUPON_RULE_STATES.draft">

                  <button type="button" class="btn btn-sm btn-default pull-right"
                          [disabled]="true"

                          [ngbPopover]="'Остановить'"
                          [triggers]="'mouseenter:mouseleave'"
                          [placement]="'bottom'"
                          [container]="'body'"

                          data-testid="cRule.archive()"
                  >
                      <i class="fa fa-stop"></i>
                  </button>

                  <button type="button" class="btn btn-sm btn-success pull-right"

                          [ngbPopover]="'Запустить'"
                          [triggers]="'mouseenter:mouseleave'"
                          [placement]="'bottom'"
                          [container]="'body'"

                          [disabled]="!isValidRule()"
                          (click)="setRuleState('active')"
                          data-testid="cRule.active()"
                  >
                      <i class="fa fa-play"></i>
                  </button>

             </span>

             <span *ngSwitchCase="COUPON_RULE_STATES.active">
                 <button type="button" class="btn btn-sm btn-default pull-right"
                         (click)="setRuleState('archive')"
                         data-testid="cRule.archive()"

                         [ngbPopover]="'Остановить'"
                         [triggers]="'mouseenter:mouseleave'"
                         [placement]="'bottom'"
                         [container]="'body'"
                 >
                     <i class="fa fa-stop"></i>
                 </button>
                 <button type="button" class="btn btn-sm btn-success pull-right"

                         [ngbPopover]="'Запустить'"
                         [triggers]="'mouseenter:mouseleave'"
                         [placement]="'bottom'"
                         [container]="'body'"

                         data-testid="cRule.active()"
                         [disabled]="true"><i
                   class="fa fa-play"></i>
                 </button>
             </span>

             <span *ngSwitchCase="COUPON_RULE_STATES.archive">
                  <button type="button"
                          class="btn btn-sm btn-add btn-success pull-right"
                          (click)="duplicateRule()"
                          data-testid="cRule.clone()">
                      <i class="fa fa-files-o"></i>
                      Клонировать
                  </button>
             </span>


        </span>
      </div>


      <button type="button"
              class="btn btn-sm btn-addon btn-success pull-right collapse-xs collapse-sm collapse-md"
              *ngIf="statesChangerService.hasButton('edit') && couponRuleItem?.couponRule?.state === 'draft'"
              (click)="statesChangerService.click('edit')"
              data-testid="cRule.edit()"
      >

        <i class=" fa fa-pencil"></i>
        <span class="btn-name">
          Редактировать
        </span>
      </button>

      <button type="button"
              class="btn btn-sm btn-primary btn-addon pull-right collapse-xs collapse-sm collapse-md"
              *ngIf="!statesChangerService.isActive && isRuleUnicCouponRule()"
              (click)="exportGenerated()"
              data-testid="cRule.export()">
        <i class="fa fa-share-square">
        </i>
        <span class="btn-name   ">
          Выгрузить купоны в CSV
        </span>
      </button>

      <button type="button"
              class="btn btn-sm btn-success btn-addon pull-right collapse-xs"
              *ngIf="!statesChangerService.isActive "
              (click)="$state.go('^.index')"
              data-testid="cRule.back()">
        <i class="fa fa-arrow-left">
        </i>
        <span class="btn-name   ">
          К списку
        </span>
      </button>


      <button type="button"
              class="btn btn-sm btn-danger btn-addon pull-left collapse-xs collapse-sm collapse-md"
              *ngIf="statesChangerService.hasButton('delete') &&
                      couponRuleItem?.couponRule?.state !== COUPON_RULE_STATES.active &&
                      couponRuleItem?.couponRule?.state !== COUPON_RULE_STATES.archive"
              (click)="statesChangerService.click('delete')"
              data-testid="cRule.delete()">
        <i class=" m-r-xs fa fa-remove"></i>
        <span class="btn-name   ">
          Удалить
       </span>
      </button>


    </div>

    <div
      *ngIf="!loader.isLoading('loading') && couponRuleItem"
    >
<!--

      <campaign-edit
        *ngIf="!!campaignItem && !!statesChangerService?.isActive"
        [campaignItem]="campaignItem"
        [campaignResources]="campaignResources"
        (anchorList)="setAnchorList($event)"
      ></campaign-edit>
-->
      <coupon-rule-edit
        *ngIf="!!couponRuleItem && !!statesChangerService?.isActive"
        [couponRuleItem]="couponRuleItem"
        (anchorList)="setAnchorList($event)"
        [campaignForm]="campaignForm"
      ></coupon-rule-edit>

      <div class="coupons-rules-view-page_container">

        <div class="clear"></div>

        <coupon-rule-view
          *ngIf="!!couponRuleItem && !statesChangerService?.isActive"
          [couponRuleItem]="couponRuleItem"
          (anchorList)="setAnchorList($event)"
          (hasRuleImportedCouponsChange)="hasRuleImportedCoupons = $event"
        ></coupon-rule-view>


        <div class="clear"></div>
      </div>

    </div>

    <ng-container
      *ngIf="loader.isLoading('loading')"
    >
      <div class="m-t-xxl p-t-xxl w-full text-center">
        <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
      </div>
    </ng-container>

  </form>

</anchor-menu>





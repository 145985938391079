<div class="col-sm-12 no-padder">

  <div class="card b-a m-b-xs">
    <div class="card-header pointer "  (click)="setVisible(); $event.stopPropagation()" data-testid="casbox.collision.show()">

      Детализация по коллизиям групп кампаний

      <i class="fa fa-angle-down pull-right text-muted "
         [ngClass]="{ 'fa-angle-down': !isVisible, 'fa-angle-up': isVisible }"
         aria-hidden="true"></i>

    </div>

    <div class="card-body no-padder  scroll-x mini-scroll" *ngIf="isVisible">


      <table class="table m-b-none w-full m-t-n-xxs"  *ngIf="!isLoading">
        <thead>
        <tr>
          <th class="text-center">Шаг</th>
          <th>Участники</th>
          <th>Правило</th>
          <th>Сработавшие</th>
          <th>Победитель</th>
          <th>Остались</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor=" let rule of initRules(rulesForPosition) "
            [ngClass]="{'bg-light text-muted' : rule.isEmpty }"
            data-testid="casbox.collision.rule in rulesForPosition"

        >
          <td class="text-center"
              [ngClass]="{ 'pointer' : rule.isEmpty }"
              (click)="emptyMinimized[rule] = rule.isEmpty ? !emptyMinimized[rule] : false"
              data-testid="rule.step">
            {{ rule.step }}
            <i class="fa fa-angle-down m-l-xs  "
               *ngIf="rule.isEmpty"
               [ngClass]="{ 'fa-angle-down': emptyMinimized[rule], 'fa-angle-up': !emptyMinimized[rule] }"
               aria-hidden="true"></i>
          </td>
          <td *ngIf="emptyMinimized[rule]"
              colspan="99"
              class="pointer"
              (click)="emptyMinimized[rule] = rule.isEmpty ? !emptyMinimized[rule] : false"
              data-testid="rule.step.nonactive">
            <span class="text-muted">Шаг не активен</span>
          </td>
          <td *ngIf="!emptyMinimized[rule]" data-testid="rule.player">
            <div class="text-ellipsis clearfix f-s-9">

              <div class="clearfix pointer" (click)="showRowCamp[rule] = !showRowCamp[rule]" data-testid="rule.player.showCampaign()">
                                <span [ngClass]="{ 'text-success': isWinner(rule.rowId,rule.winners ) && !rule.isEmpty, 'text-danger':  !isWinner(rule.rowId,rule.winners ) && !rule.isEmpty, 'font-bold' : isPriority(rule.rowId,rule.winId ) && !rule.isEmpty  }"
                                      [innerHTML]="getCategoryName( rule.rowId )"
                                ></span>
                <span class="text-muted f-s-8">(<span [innerHTML]="rule.rowTotal | loyaCurrency"></span>  <span ngCurrencyNameLoya></span>)</span>
                <i class="fa" [ngClass]="{'fa-angle-down': !showRowCamp[rule], 'fa-angle-up': showRowCamp[rule] }"></i>
              </div>

              <div class="clearfix f-s-8 text-muted">
                {{ getCategoryRule( rule.rowId ) }}
              </div>

              <div class="clearfix" *ngIf="showRowCamp[rule]">
                <ul class="list-group no-bg no-borders  m-b-none  ">
                  <li class="list-group-item no-padder m-l-sm m-t-xs " *ngFor="let camp of rule.rowCampaigns">
                    <i class="fa fa-rocket text-success pull-left"></i>
                    <span class="clearfix text-ellipsis" [innerHTML]="camp.name"></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.discount" >Скидка: {{camp.discount | loyaCurrency }}  <span ngCurrencyNameLoya></span> </span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.award" >Начислено бонусов: {{camp.award}}</span>
                  </li>

                  <li class="list-group-item no-padder m-l-sm m-t-xs"  *ngFor="let camp of rule.rowCouponRules">
                    <i class="fa fa-barcode text-success pull-left"></i>
                    <span class="clearfix text-ellipsis" [innerHTML]="camp.name"></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.discount" >Скидка: {{camp.discount  | loyaCurrency }}  <span ngCurrencyNameLoya></span></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.award" >Начислено бонусов: {{camp.award}}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="w-full text-left f-s-8 text-muted clearfix">
              <i class="fa fa-exchange fa-rotate-90 m-l" aria-hidden="true"></i>
            </div>

            <div class="text-ellipsis clearfix f-s-9" >

              <div class="clearfix pointer" (click)="showColCamp[rule] = !showColCamp[rule]">
                                <span [ngClass]="{ 'text-success': isWinner(rule.colId,rule.winners ) && !rule.isEmpty, 'text-danger':  !isWinner(rule.colId,rule.winners ) && !rule.isEmpty, 'font-bold' :  isPriority(rule.colId,rule.winId ) && !rule.isEmpty  }"
                                      [innerHTML]="getCategoryName( rule.colId )"
                                ></span>
                <span class="text-muted f-s-8">(<span [innerHTML]="rule.colTotal | loyaCurrency"></span>  <span ngCurrencyNameLoya></span>)</span>
                <i class="fa" [ngClass]="{'fa-angle-down': !showColCamp[rule], 'fa-angle-up': showColCamp[rule] }"></i>
              </div>

              <div class="clearfix f-s-8 text-muted">
                {{ getCategoryRule( rule.colId ) }}
              </div>

              <div class="clearfix" *ngIf="showColCamp[rule]">
                <ul class="list-group no-bg no-borders  m-b-none  ">
                  <li class="list-group-item no-padder m-l-sm m-t-xs " *ngFor="let camp of rule.colCampaigns">
                    <i class="fa fa-rocket text-success pull-left"></i>
                    <span class="clearfix text-ellipsis" [innerHTML]="camp.name"></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.discount" >Скидка: {{camp.discount  | loyaCurrency }}  <span ngCurrencyNameLoya></span></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.award" >Начислено бонусов: {{camp.award}}</span>
                  </li>

                  <li class="list-group-item no-padder m-l-sm m-t-xs"  *ngFor="let camp of rule.colCouponRules">
                    <i class="fa fa-barcode text-success pull-left"></i>
                    <span class="clearfix text-ellipsis" [innerHTML]="camp.name"></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.discount" >Скидка: {{camp.discount  | loyaCurrency }}  <span ngCurrencyNameLoya></span></span>
                    <span class="clearfix text-muted  text-ellipsis" *ngIf="camp.award" >Начислено бонусов: {{camp.award}}</span>
                  </li>
                </ul>
              </div>

            </div>

          </td>
          <td *ngIf="!emptyMinimized[rule]" data-testid="rule.collisionType">
            <div class="f-s-8 text-ellipsis" [innerHTML]="getRuleName( rule.rule ) "></div>
            <div class="text-muted f-s-8" *ngIf="!!rule.ruleDetails">
              (<span [innerHTML]="rule.ruleDetails"></span>)
            </div>

            <div class=" f-s-8 "
                 *ngIf="rule.winId!==undefined"
                 [ngClass]="{ 'text-success font-bold' : !rule.isEmpty } "
            >
              <span [innerHTML]="getCategoryName(rule.winId)"></span>
            </div>

          </td>
          <td *ngIf="!emptyMinimized[rule]" data-testid="rule.worked">
            <div class="text-ellipsis clearfix f-s-9"
                 *ngFor="let  live of rule.liveCategories">
              <span [ngClass]="{ 'text-success': isWinner(live,rule.winners ) && !rule.isEmpty, 'text-danger': isLooser(live,rule.winners, [rule.rowId, rule.colId]) && !rule.isEmpty }">
                  {{ getCategoryName( live ) }}
              </span>
            </div>
          </td>
          <td *ngIf="!emptyMinimized[rule]" data-testid="rule.winner">
            <div class="text-ellipsis clearfix f-s-9 text-success"
                 *ngFor="let winner of rule.winners">
              {{ getCategoryName( winner ) }}
            </div>
          </td>
          <td *ngIf="!emptyMinimized[rule]" data-testid="rule.survived">
            <div class="text-ellipsis clearfix f-s-9"
                 *ngFor="let  survived of rule.survived">
              <span [ngClass]="{ 'text-success': isWinner(survived,rule.winners ) && !rule.isEmpty }">
                 {{ getCategoryName( survived ) }}
              </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="w-full text-center" *ngIf="isLoading">
        <i class="fa fa-spin fa-spinner fa-3x text-warning m-t-lg m-b-lg"></i>
      </div>

      <div class="w-full text-center text-muted fa-lg p-t-md p-b-md wrapper " *ngIf="isEmpty && !isLoading" data-testid="casbox.collision.empty">
        Коллизии групп кампаний отсутствуют
      </div>

    </div>

  </div>

</div>

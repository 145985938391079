'use strict';

import module from 'frontendModule';

import * as  template     from  './views/index.html';
import controller from './controllers';

import ckeditor_loader from 'agModules/ckeditor';

import 'ng-infinite-scroll';
import 'ui-select';
import 'angular-footable';

import '../../../campaign/campaigns/templates/components/posPrinter/main';

import {ReferralCouponWithBarcodeId} from "./couponKinds/CouponKind";
import {RoleType} from "module/permissions/conf/roles";

let routePath = module.name + '.coupons.rules.view2';
module.requires = module.requires.concat([
    ,
    'infinite-scroll',
    'ui.footable',
    'ui.select'
]);

export default
module
    .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

            routeBuilderProvider
                .route( routePath )
                .url('/:id')

                .template(template)
                .controller(controller)

                .resolve('$ckePrepare', ckeditor_loader)
                .resolve('isReferralCouponWithBarcodeEnabled', ['Settings',function (Settings) {

                    return true;
                   /* return Settings.getByKeys(['frontend.coupon.referralCouponWithBarcode']).$promise.then( ( res : any ) =>
                         !!(res && res.length && res[0].value.toLowerCase() === 'true')
                    );
*/
                }])
                .resolve('rawValues',  ['CouponRule', '$injector', 'ngCurrencyL10n', '$timeout', 'isReferralCouponWithBarcodeEnabled',
                    function (CouponRule, $injector, ngCurrencyL10n, $timeout, isReferralCouponWithBarcodeEnabled) {

                        return  ngCurrencyL10n
                                .getShortName()
                                .then( () => $timeout())
                                .then( () => {

                                    if ( $injector.has('loyaPermissions') ) {
                                        var loyaPermission = $injector.get('loyaPermissions');
                                    }

                                    var _t = new CouponRule();
                                    var rawValues = {};

                                    angular.forEach(_t.$fields, function (item, key) {

                                        if (!angular.isUndefined(item.values)) {

                                            if (loyaPermission) {

                                                switch (true) {

                                                    case key === 'couponRule.issueSettings.issuingType':

                                                        rawValues[key] = loyaPermission.byPath('coupons.rule.issuingType').filterObject(item.values);

                                                        break;

                                                    case key === 'couponRule.issueSettings.couponKind':

                                                        rawValues[key] = angular.copy(item.values);
                                                        for( let index in rawValues[key]) {
                                                            if (rawValues[key].hasOwnProperty( index ) ) {
                                                                rawValues[key][index] = loyaPermission.byPath('coupons.rule.couponKind').filterObject(rawValues[key][index])

                                                                if ( !isReferralCouponWithBarcodeEnabled && rawValues[key][index][ReferralCouponWithBarcodeId] ) {
                                                                    delete rawValues[key][index][ReferralCouponWithBarcodeId];
                                                                }

                                                            }
                                                        }

                                                        break;

                                                    default:
                                                        rawValues[key] = item.values;
                                                }

                                            } else {
                                                rawValues[key] = angular.copy(item.values);
                                            }
                                        }

                                    });

                                    return rawValues;
                                });


                }] )

                .resolve('partnerList',  ['PartnerModel', function (PartnerModel) {

                    return PartnerModel.query().$promise.then(function( data ){
                        var result = {};

                        angular.forEach(data, function(item) {
                            result[item.id] = item;
                        });

                        data = null;

                        return result;
                    });

                }] )

                .resolve('CampaignCategoryList', ['CampaignCategoryModel', function (CampaignCategoryModel) {

                    return CampaignCategoryModel.query({"pager": {
                        "drop": 0,
                        "limit": 99999
                      }});
                }]  )

                .resolve('isLoyaStart', ['loyaPermissions', function (loyaPermissions) {

                  return !loyaPermissions.byPath('global.clients').enabled;
                }]  )

                .resolve('ruleItem',  ['$stateParams', 'CouponRule', '$q', 'rawValues', 'partnerList',
                    function ($stateParams, CouponRule, $q, rawValues, partnerList) {

                        if (!$stateParams.id && $stateParams.id !== 0) {

                            var result = new CouponRule({
                                couponRule: {
                                    description: '',
                                    partnerId: partnerList[Object.keys(partnerList)[0]].id,

                                    issueSettings: {
                                        issuingType: Object.keys(rawValues['couponRule.issueSettings.issuingType'])[0],
                                        canBeSold: false,
                                        customPeriod: false
                                    },

                                    applySettings: {
                                        validTimeFrom: new Date("1970-01-01T00:00:00Z"),
                                        validTimeTill: new Date("1970-01-01T23:59:00Z"),
                                        validDays: [],
                                        couponApply: "applyOnRequest",
                                        minCheckTotal: 0
                                    },
                                    usingSettings: {
                                        useCount: 1,
                                        usable3rdParties: false
                                    },
                                    reward: {
                                        reward: {
                                            what        : 'discount',
                                            rewType     : 'total',
                                            id          : 0,
                                            description : ""
                                        }
                                    }
                                }
                            });

                            angular.forEach(rawValues['couponRule.applySettings.validDays'], function (item, key) {
                                result.couponRule.applySettings.validDays.push(key);
                            });

                            return $q.resolve(result);
                        }

                        return CouponRule.get($stateParams).$promise;
                    }] )


                .resolve('settingsList', ['Settings',
                function (Settings) {

                  return new Promise( resolve => {

                    let res = {};

                    Settings.getByKeys(['externalLoyalty'],result => {

                      if (
                        !Array.isArray(result)
                      ) {
                        return resolve({});
                      }

                      result.forEach( i => {
                        res[i.key] = i.value;
                      });

                      return resolve(res);
                    });

                  })

                }
              ])



              .breadcrumbs( ["Купоны", "Купонные правила", "Инфо о правиле"])

                .permissions( {
                    only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],

                })

        }]);

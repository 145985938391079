<form-container>

  <div [form-item-row] = "'Название'"
       form-item-row-input="true"
       id="anchorI1"
  >
    <input
      type="text"
      class="form-control form-control-edittable"
      [(ngModel)]="communicationItem.campaign.name"
      data-testid="communication.input.name"
      name="CampaignItem_campaign_name"
      placeholder="Введите название коммуникации"
      [required]="true"
      maxlength="100"
      [disabled]="isSuspend"
    >
  </div>

  <div [form-item-row] = "'Описание'"
       form-item-row-input="true"
  >
     <textarea
       class="form-control form-control-edittable"
       [(ngModel)]="communicationItem.campaign.description"
       data-testid="communication.input.description"
       name="CampaignItem_campaign_description"
       placeholder="Описание коммуникации"
       [required]="false"
       maxlength="500"
     > </textarea>
  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted">Параметры</span>
  </div>

  <div [form-item-row] = "'Партнер'"
       form-item-row-input="true"
  >
    <select-partner
      [(id)]="communicationItem.campaign.partnerId"
      (idChange)="onChangePartner()"
      data-testid="communication.select.partner"
      [required]="true"
      [disabled]="isSuspend"
      [selectFirst]= "true"
    ></select-partner>
  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI2">Период и место действия</span>
  </div>

  <div [form-item-row] = "'Период действия'"
       form-item-row-input="true"
  >

    <div class="inline m-r">
      <div class="input-group m-b w w-full-md  w-full-sm w-full-xs m-n">

        <span class="input-group-addon">с</span>

        <input type="date"
               class="inline form-control"
               [(ngModel)]="communicationItem.campaign.campaignTime.startDate"
               (ngModelChange)="recalcCampaignTime();"
               data-testid="communication.input.campaignTime.startDate"
               name="CampaignItem_campaign_campaignTime_startDate"
               placeholder="Дата начала"
               [required]="true"
               [disabled]="isSuspend"
        >

      </div>
    </div>

    <div class="inline">
      <div class="input-group m-b w w-full-md  w-full-sm w-full-xs m-n">

        <span class="input-group-addon">по</span>

        <input type="date"
               class="inline form-control"
               [(ngModel)]="communicationItem.campaign.campaignTime.stopDate"
               (ngModelChange)="recalcCampaignTime(); recalcCouponUntil()"
               data-testid="communication.input.campaignTime.stopDate"
               name="CampaignItem_campaign_campaignTime_stopDate"
               placeholder="Дата завершения"
               [required]="true"
               [min]="communicationItem.campaign.campaignTime.startDate"
               [minMaxValidateOnChange]="true"
        >
      </div>
    </div>

  </div>

  <div [form-item-row] = "'Время действия'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.4rem;"
  >
      <div *ngFor="let period of communicationItem.campaign.campaignTime.periods; let last = last; let first = first; let periodIndex = index;"
      >
        <div class="hbox hbox-auto w-auto"
             [ngClass]="{ 'card': communicationItem.campaign.campaignTime.periods?.length>1, 'm-l-n' : communicationItem.campaign.campaignTime.periods?.length<=1 }">

          <div class="col padder wrapper-xs pos-rlt">

            <div class="clearfix ">

              <div class="clearfix f-s-8 text-muted  m-b-xs"> Время дня</div>

              <div class="inline m-r-sm w-full-md  w-full-sm w-full-xs">

                <div
                  class="input-group m-b w w-full-md w-full-sm w-full-xs m-n">
                  <span class="input-group-addon">с</span>
                  <input
                    type="time"
                    class=" inline form-control"
                    [(ngModel)]="period.startTime"
                    (ngModelChange)="recalcCampaignTime()"
                    data-testid="communication.input.period.startTime"
                    [name]="'CampaignItem_campaign_campaignTime_startTime' + periodIndex"
                    placeholder="Время начала"
                    [required]="true"
                    step="60"
                  >
                </div>
              </div>

              <div class="inline w-full-md  w-full-sm w-full-xs">
                <div  class="input-group m-b w w-full-md w-full-sm w-full-xs m-n">
                  <span class="input-group-addon">по</span>
                  <input
                    type="time"
                    class=" inline form-control"
                    [(ngModel)]="period.stopTime"
                    (ngModelChange)="recalcCampaignTime()"
                    data-testid="communication.input.period.stopTime"
                    step="60"
                    [name]="'CampaignItem_campaign_campaignTime_stopTime' + periodIndex"
                    placeholder="Время завершения"
                    [required]="true"
                  >
                </div>
              </div>

            </div>

            <div class="clearfix ">

              <div class="clearfix f-s-8 text-muted m-b-xs "> День недели</div>
              <div
                style="width: 3.7em"
                class="checkbox inline m-t-none m-b-xs m-r-sm"
                *ngFor="let weekDayItem of daysOfWeekDict"
                data-testid="weekDayItem in daysOfWeekDics"
              >
                <label class="i-checks">

                  <input type="checkbox"
                         [checked]="isEnabledInArray(weekDayItem.key, period.daysOfWeek)"
                         (change)="changeWeekDay($event?.currentTarget?.checked, weekDayItem.key, period.daysOfWeek); recalcCampaignTime();"
                         data-testid="communication.input.daysOfWeekList"
                  >
                  <i></i>
                  {{ weekDayItem.name }}
                </label>
              </div>

            </div>

            <div class="clearfix "
            >

              <div class="clearfix f-s-8  m-t-xs m-b-xs">

                <a href="javascript:void(0)"
                   (click)="showDays[periodIndex] = (showDays[periodIndex] && !isDaysOfMonthChanged(period) ) ? false : true  "
                   data-testid="communication.daysOfMonth.show()">

                  День месяца
                  <i class="fa"
                     *ngIf="!showDays[periodIndex] || (showDays[periodIndex] && !isDaysOfMonthChanged(period) )"
                     [ngClass]="{ 'fa-angle-down' : !showDays[periodIndex], 'fa-angle-up' : showDays[periodIndex] }"></i>
                </a>

              </div>

              <div *ngIf="showDays[periodIndex]">
                <span *ngFor="let monthDayItem of daysOfMonthDics"
                      data-testid="monthDayItem in daysOfMonthDics">
                <div
                  style="width: 3.7em"
                  class="checkbox inline m-t-none m-b-xs m-r-sm"
                >
                  <label class="i-checks" >

                    <input type="checkbox"
                           [checked]="isEnabledInArray(monthDayItem.key, period.daysOfMonth)"
                           [ngClass]="{ 'checked ': !isDaysOfMonthChanged(period) }"
                           (change)="changeMonthDay($event?.currentTarget?.checked, monthDayItem.key, period); ecalcCampaignTime()"
                           data-testid="communication.input.daysOfMonthList"
                    >
                    <i
                      [ngClass]="{ 'text-muted ': !isDaysOfMonthChanged(period) }"
                    ></i>
                    {{monthDayItem.name}}
                  </label>
                </div>
                <br *ngIf="monthDayItem.key % 7 === 0" />
              </span>
              </div>

            </div>

          </div>

          <div class="col v-middle bg-light padder b-l "
               *ngIf="communicationItem?.campaign?.campaignTime?.periods?.length>1"
          >

            <button type="button"
                    (click)="communicationItem.campaign.campaignTime.periods.splice(periodIndex, 1)"
                    data-testid="communication.period.remove()"
                    class="btn btn-sm btn-icon btn-danger ">
              <i class="fa Example of trash-o fa-trash-o"></i>
            </button>

          </div>
          </div>
      </div>

      <div class="clearfix text-left text-center-sm text-center-md text-center-xs w-full  ">
        <button type="button"
                (click)="createCampaignPeriod()"
                data-testid="communication.createPeriod()"
                class="btn  btn-sm btn-addon btn-success">
          <i class="fa fa-plus"></i>
          <span> Добавить период </span>
        </button>
      </div>

  </div>

  <div [form-item-row] = "'Место проведения'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.1rem;"
  >
    <span *ngIf="!isPartnerSelected"
          class="text-danger d-inline-block m-t-lg">Для выбора места проведения, требуется выбрать партнёра</span>

    <ng-container
      *ngIf="isPartnerSelected"
    >
      <div class="clearfix f-s-8 text-muted m-b-xs">Локация</div>

      <select-location-group
        data-testid="communication.select.locationGroup"
        [partnerId]="communicationItem?.campaign?.partnerId"
        [(id)]="locationGroupsId"
        [multiple]="true"
        [name]="'selectLocationGroup'"
      ></select-location-group>

      <div class="clearfix f-s-8 text-muted m-b-xs m-t-xs">Магазин</div>

      <input type="hidden" [(ngModel)]="settingsObj.emptyLocationsDummy" [required]="true" name="emptyLocationsDummy"
             *ngIf="!hasSelectedLocation()"
      >

      <div class="btn-group" ngbDropdown>

        <button data-testid="location.choose()"
                class="btn btn-default "
                (click)="selectLocations()"
                [ngClass]="{ 'b b-danger': !hasSelectedLocation() }"
        >
          <span *ngIf="communicationItem?.location?.length">Выбрано магазинов:
              <span [innerText]="communicationItem?.location?.length" data-testid="location.length"></span>
          </span>
          <span class="" *ngIf="!communicationItem?.location?.length">Выбрать магазины</span>
        </button>


        <ul class="scroll-y"
            ngbDropdownMenu
            *ngIf="!!communicationItem?.location?.length"
            style="max-height: 411px;"
        >

          <li *ngFor="let location of communicationItem.location"
              class="d-flex align-items-center m-b-xs m-t-xs">

            <div class="col p-l-sm">
              <span [innerText]="location.name" data-testid="locationChosen.name"></span>
            </div>

            <div class="col-auto no-padder text-center">
              <button class="btn btn-danger btn-xs m-l-xs m-r-sm  "
                      (click)="removeLocation(location); $event.stopImmediatePropagation()"
                      data-testid="locationChosen.remove()">
                <i class="fa fa-times"></i>
              </button>
            </div>

          </li>

        </ul>


        <button *ngIf="!!communicationItem?.location?.length"
                class="btn btn-default"
                ngbDropdownToggle
                aria-expanded="false"
                data-testid="locationChosen.show()">
          <span class="caret"></span>
        </button>

      </div>

    </ng-container>

  </div>

  <div [form-item-row] = "'Ограничения места проведения'"
       form-item-row-input="true"
       form-item-row-label-style="margin-top: 2.1rem;"
  >
    <span *ngIf="!isPartnerSelected"
          class="text-danger d-inline-block m-t-lg">Для выбора ограничения места проведения, требуется выбрать партнёра</span>

    <ng-container
      *ngIf="isPartnerSelected"
    >
      <div class="clearfix f-s-8 text-muted m-b-xs">Локация</div>

      <select-location-group
        data-testid="communication.select.exceptLocationGroup"
        [partnerId]="communicationItem?.campaign?.partnerId"
        [placeholder]="'Выбор локации для исключения'"
        [(id)]="expectLocationGroupsId"
        [name]="'selectExpectLocationGroup'"
        [multiple]="true"
      ></select-location-group>

      <div class="clearfix f-s-8 text-muted m-t-xs m-b-xs">Магазин</div>

      <div class="btn-group" ngbDropdown>

        <button data-testid="exceptLocation.choose()"
                class="btn btn-default "
                (click)="selectLocations('exceptLocation')"
        >
          <span *ngIf="communicationItem?.exceptLocation?.length">Исключено магазинов:
              <span [innerText]="communicationItem?.exceptLocation?.length" data-testid="exceptLocation.length"></span>
          </span>
          <span class="" *ngIf="!communicationItem?.exceptLocation?.length">Магазины для исключения</span>
        </button>


        <ul class="scroll-y"
            ngbDropdownMenu
            *ngIf="!!communicationItem?.exceptLocation?.length"
            style="max-height: 411px;"
        >

          <li *ngFor="let location of communicationItem.exceptLocation"
              class="d-flex align-items-center m-b-xs m-t-xs">

            <div class="col p-l-sm">
              <span [innerText]="location.name" data-testid="exceptLocationChosen.name"></span>
            </div>

            <div class="col-auto no-padder text-center">
              <button class="btn btn-danger btn-xs m-l-xs m-r-sm  "
                      (click)="removeLocation(location, 'exceptLocation'); $event.stopImmediatePropagation()"
                      data-testid="exceptLocationChosen.remove()">
                <i class="fa fa-times"></i>
              </button>
            </div>

          </li>

        </ul>


        <button *ngIf="!!communicationItem?.exceptLocation?.length"
                class="btn btn-default"
                ngbDropdownToggle
                aria-expanded="false"
                data-testid="exceptLocationChosen.show()">
          <span class="caret"></span>
        </button>

      </div>

    </ng-container>

  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI3">Аудитория</span>
  </div>

  <div [form-item-row] = "'Целевая аудитория'"
       form-item-row-input="true"
  >
    <div class="d-flex w-full">
      <ng-select [(ngModel)]="targetAudience"
                 (ngModelChange)="recalcAudience()"
                 [items]="targetDist"
                 bindLabel="name"
                 bindValue="key"
                 data-testid="communication.targetAudience"
                 name="CampaignItem_targetAudience"
                 (change) = "onChangeTarget()"
                 [class.ng-invalid]="!targetAudience"
                 [searchable]="false"
                 [required]="true"
                 [disabled]="isSuspend"
                 [clearable]="false"
                 class="w-md  col-auto m-r-sm no-padder"

      >

        <ng-template ng-label-tmp let-item="item">
          {{ item.name }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index">
          {{item.name}}
        </ng-template>

      </ng-select>

      <select-segment
        *ngIf="targetAudience==='segment'"
        [(id)]="communicationItem.campaign.segmentId"
        (idChange)="recalcAudience()"
        [required]="true"
        [disabled]="isSuspend"
        [name]="'CampaignItem_targetAudience_segment'"
        class="col"
      ></select-segment>

      <select-group
        *ngIf="targetAudience==='group'"
        [(id)]="communicationItem.campaign.groupId"
        (idChange)="recalcAudience()"
        [required]="true"
        [disabled]="isSuspend"
        [name]="'CampaignItem_targetAudience_group'"
        class="col"
      ></select-group>


    </div>
  </div>

  <ng-container
    *loyaPermitIf="{path:'campaign.settings.exceptionAudience', permitEnabled: ''}"
  >
    <div [form-item-row] = "'Ограничения целевой аудитории'"
         form-item-row-input="true"
    >
      <div class="d-flex w-full">
        <ng-select [(ngModel)]="exceptionAudience"
                   (ngModelChange)="recalcAudience()"
                   [items]="targetDist"
                   bindLabel="name"
                   bindValue="key"
                   data-testid="communication.exceptionAudience"
                   name="CampaignItem_exceptionAudience"
                   (change) = "onChangeTarget(true)"
                   [class.ng-invalid]="!exceptionAudience"
                   [searchable]="false"
                   [disabled]="isSuspend"
                   [required]="true"
                   [clearable]="false"
                   class="w-md  col-auto m-r-sm no-padder"

        >

          <ng-template ng-label-tmp let-item="item">
            {{ item.name ==='Все' ? 'Без ограничений' : item.name }}
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ item.name ==='Все' ? 'Без ограничений' : item.name }}
          </ng-template>

        </ng-select>

        <select-segment
          *ngIf="exceptionAudience==='segment'"
          [(id)]="communicationItem.campaign.exceptSegmentId"
          (idChange)="recalcAudience()"
          [required]="true"
          [disabled]="isSuspend"
          class="col"
          [name]="'CampaignItem_exceptionAudience_segment'"
        ></select-segment>

        <select-group
          *ngIf="exceptionAudience==='group'"
          [(id)]="communicationItem.campaign.exceptGroupIds"
          (idChange)="recalcAudience()"
          [required]="true"
          [disabled]="isSuspend"
          [multiple]="true"
          [name]="'CampaignItem_exceptionAudience_group'"
          [max]="15"
          class="col"
        ></select-group>

      </div>
    </div>
  </ng-container>

  <div [form-item-row] = "'Итоговое кол-во участников'"
       form-item-row-input="true"
  >

    <ng-container
      *ngIf="!loader.isLoading('campaignTargetInfo')"
    >
      <span [innerHTML]="targetGroupInfo?.clientCount || 0 "
            *ngIf="!!targetGroupInfo?.clientCount"
            data-testid="communication.planValues.clientsCount"></span>

      <span class="text-danger"
            *ngIf="!targetGroupInfo?.clientCount"
            data-testid="communication.targetAudience.warning"
      >
        <i class="fa fa-warning m-r-xs"></i> Внимание! В целевой аудитории никого не осталось
      </span>
    </ng-container>

    <i
      *ngIf="loader.isLoading('campaignTargetInfo')"
      class="fa fa-spin fa-spinner text-warning"
    ></i>

  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI8">Условия срабатывания</span>
  </div>

  <communication-mechanic
    [communicationItem]="communicationItem"
    [isEdit]= "!isCommunicationStarted"
  >
  </communication-mechanic>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI4">Информирование</span>
  </div>


  <div class="form-item-row clean"></div>

</form-container>

<form-container>

  <div [form-item-row] = "'Шаблон сообщений'"
       form-item-row-input="true"
  >
    <select-message-template
      [currentId]="communicationItem.campaign.templateId"
      [(id)]="communicationItem.campaign.templateId"
      [required]="true"
      [disabled]="isSuspend"
    ></select-message-template>
  </div>

  <div [form-item-row] = "'Канал информирования'"
       form-item-row-input="true"
  >
    <ng-select [(ngModel)]="communicationItem.campaign.channel"
               [items]="CHANNEL_TYPES_NAMES"
               bindLabel="name"
               bindValue="key"
               data-testid="communication.campaign.channel"
               name="CampaignItem_campaign_channel"
               [class.ng-invalid]="!communicationItem.campaign.channel"
               [searchable]="false"
               [required]="true"
               [disabled]="isSuspend"
               [clearable]="false"
               class="  w-full  no-padder"

    >

      <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.name}}
      </ng-template>

    </ng-select>

  </div>



  <div [form-item-row] = "'   '"
       form-item-row-input="true"
  >

  </div>


</form-container>

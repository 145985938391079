import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy, NgZone
} from "@angular/core";
import { getNg1Service } from '../../../../shared/class/utils/angularjs.utils'

import {StatesChangerService} from "../../../../shared/service/statesChanger/statesChanger.service";
import {ConfirmActionService} from "../../../../shared/service/confirmAction/confirmAction.service";

import "./communications-view-page.legacy";

import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {
  COMMUNICATION_CAMPAIGN_ACTIONS, COMMUNICATION_CAMPAIGN_COMPLETENESS, COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES,
  COMMUNICATION_CAMPAIGN_STATES,
  COMMUNICATION_CAMPAIGN_STATES_TREE
} from "../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.values";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";
import {CampaignState} from "../../../../api/CampaignApi/models/campaign-state";
import {InformingCampaignProtocol} from "../../../../api/CommunicationCampaignApi/models/informing-campaign-protocol";
import {
  CommunicationCampaignService
} from "../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.service";

@Component({
  selector: 'communications-view-page',
  templateUrl: './communications-view-page.component.html',
  styleUrls: [`./communications-view-page.component.scss`],
  providers: [
    StatesChangerService,
    CommunicationCampaignService,
  ]
})
export class CommunicationsViewPageComponent implements OnInit, OnChanges, OnDestroy{

  @Input() id: string;
  private uiRouterState;

  public communicationItem: InformingCampaignProtocol;

  public anchorList;

  public COMMUNICATION_CAMPAIGN_ACTIONS = COMMUNICATION_CAMPAIGN_ACTIONS;
  public COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES = COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES;

  public loader = new LoaderHandlerClass();
  public unsubscribers: any = {}

  constructor(
    public statesChangerService: StatesChangerService,
    public confirmActionService: ConfirmActionService,
    public communicationCampaignService: CommunicationCampaignService,
    private ngZone: NgZone,
  ) {
    this.uiRouterState = getNg1Service('$state');
  }


  get isCompletenessEnded() {
    return !this?.communicationItem?.campaign?.completeness ||
      this?.communicationItem?.campaign?.completeness === COMMUNICATION_CAMPAIGN_COMPLETENESS.finished
  }

  get isCompletenessInProgress() {
    return !!this?.communicationItem?.campaign?.completeness && this?.communicationItem?.campaign?.completeness !== COMMUNICATION_CAMPAIGN_COMPLETENESS.finished
  }

  get completenessMessage() {
    return COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES?.[this?.communicationItem?.campaign?.state]?.[this?.communicationItem?.campaign?.completeness]
  }

  ngOnInit() {
    this.bindButtons();
  }

  bindButtons() {
    this.statesChangerService.createButton('view','delete' , this.onDelete.bind(this)  );
    this.statesChangerService.createButton('edit','save'   , this.onSave.bind(this)    );
    this.statesChangerService.createButton('edit','cancel' , this.onCancel.bind(this)  );
    this.statesChangerService.createButton('edit','preview', this.onPreview.bind(this) );
    this.statesChangerService.createButton('preview','preview', this.onPreview.bind(this) );
  }

  isEnabledButton(id: COMMUNICATION_CAMPAIGN_ACTIONS) {

    if (!this.communicationItem?.campaign || this.statesChangerService?.isActive)
      return false;

    const value =  COMMUNICATION_CAMPAIGN_STATES_TREE?.[this.communicationItem?.campaign?.state]?.[id];
    return  !!value || value === false;
  }

  isDisabledButton(id: COMMUNICATION_CAMPAIGN_ACTIONS) {

    if (!this.isEnabledButton(id))
      return false;

    if(this.loader.isLoading('loading')) {
      return true;
    }

    return COMMUNICATION_CAMPAIGN_STATES_TREE?.[this.communicationItem?.campaign?.state]?.[id] === false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.id ) {
      this.getOrCreateCampaign(changes.id.currentValue)
        .then( result => {

            this.statesChangerService.changeState(this?.communicationItem?.campaign?.id >=0 ?
              'view' :
              'edit');

        })
      ;
    }
  }

  getOrCreateCampaign( id:any ) {

    return this.communicationCampaignService
          .getOrCreate$(parseInt(id))
          .pipe(
            this.loader.waitLoading('loading')
          )
          .toPromise()
          .then( result => {
              this.communicationItem = result

            if ( this.communicationItem?.campaign?.id >=0 ) {

              if ( typeof this.unsubscribers.stateUpdater?.unsubscribe === "function")
                this.unsubscribers.stateUpdater?.unsubscribe();
              let updateStatus = (value) => {
                if (this.statesChangerService?.isActive)
                  return;

                this.communicationItem.campaign.state = value.state || this.communicationItem.campaign.state;
                this.communicationItem.campaign.completeness = value.completeness || this.communicationItem.campaign.completeness;
              }

              this.ngZone.runOutsideAngular(() => {

                this.unsubscribers.stateUpdater =
                  this.communicationCampaignService?.stateUpdater$(this.communicationItem?.campaign?.id).subscribe({
                    next: value => {
                      this.ngZone.run(() => { updateStatus(value);} );
                    }
                  })

              });
            }

          })
  }

  clickChangeState(inState) {

    let state = COMMUNICATION_CAMPAIGN_STATES_TREE?.[this.communicationItem?.campaign?.state]?.[inState];
    if ( typeof state !== "string" )
      return;

    if ( inState === COMMUNICATION_CAMPAIGN_ACTIONS.completed ) {
      this.confirmActionService.confirm('Вы действительно хотите завершить коммуникацию ?', () => {
        this.changeCampaignState(state);
      })
    } else
      this.changeCampaignState(state);
  }

  changeCampaignState(state) {

    if ( !(this.communicationItem?.campaign?.id >=0 ) || !state )
      return;

    this.communicationCampaignService.setState$(
      this?.communicationItem?.campaign?.id,
      state
    ).subscribe( result => {
      this.communicationItem = result;
    })

  }

  cloneCampaign() {
    this.communicationItem = Object.assign({}, this.communicationItem);
    delete this.communicationItem.campaign.id;
    this.communicationItem.campaign.state = CampaignState.DRAFT;

    this.switchToEdit();
  }

  switchToEdit() {
    this.statesChangerService?.changeState('edit');
  }

  getClientCategoryById( id:string = '' ) {
/*
    if (id === '') {
      this.currentClientCategory = {};
      return <any>Promise.resolve(this.currentClientCategory);
    }

    return this.clientCategoryService
      .get(parseInt(id))
      .toPromise()
      .then( result => this.currentClientCategory = result );*/
  }

  onSave() {
/*

    this.communicationItem?.communication?.campaignTime?.periods?.forEach( i => {
        i.startTime = /\d{2}:\d{2}/.test(i.startTime) ? i.startTime + ":00" : i.startTime;
        i.stopTime = /\d{2}:\d{2}/.test(i.stopTime) ? i.stopTime + ":59" : i.stopTime;
    })
*/

    this.loader.startLoading('loading')

    this.communicationCampaignService
      .createOrUpdate$(this.communicationItem)
      .subscribe({
        next: result => {

          this.communicationItem = result;
          this.uiRouterState.go('^.view', {id: result?.campaign?.id}, {reload: true});
        },
        error: er => {
          this.loader.stopLoading('loading')
        }
      })
  }

  onCancel() {
    if (! (this.communicationItem?.campaign?.id >=0 )) {
      this.uiRouterState.go('^.index');
      return
    }

    this.getOrCreateCampaign(this.communicationItem?.campaign?.id)
      .then( () =>  this.statesChangerService?.changeState('view') )
  }

  onDelete() {
   /* if (typeof this.currentClientCategory.id === "undefined")
      return;

    this.clientCategoryService
      .deleteCategory(this.currentClientCategory.id)
      .subscribe(() => {
        this.uiRouterState.go('^.index');
      })*/
  }

  onPreview() {
  /*  this.statesChangerService.state = this.statesChangerService.state === 'edit'? 'preview' : 'edit' ;*/
  }

  setAnchorList(value) {
    this.anchorList = typeof value === "object" ? Object.assign({}, value) : value;
  }

  ngOnDestroy(): void {
    Object.values( this.unsubscribers )
      .forEach(
        (i: any) => typeof i?.unsubscribe === "function" && i.unsubscribe()
      )
  }

}

<div class="d-flex align-items-center communication-row">
  <div class="col-auto p-l-none p-r-xs">
    <span class="badge"
          [ngClass]="badgeColor"
    >{{index}}</span>
  </div>
  <div class="col-auto p-l-none p-r-xs communication-row_item-name">
    {{name}}
  </div>
  <div class="col p-r-none communication-row_item-value">
    <ng-content></ng-content>
  </div>
</div>

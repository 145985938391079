import {COUPON_STATES} from "../Coupon/Coupon.values";
import {External} from "./IssuingTypes/External";
import {Manual} from "./IssuingTypes/Manual";
import {Pos} from "./IssuingTypes/Pos";
import {Public} from "./IssuingTypes/Public";
import {Trigger} from "./IssuingTypes/Trigger";
import {UniqueCouponGen} from "./IssuingTypes/UniqueCouponGen";
import {
  CouponRuleBarcodeId,
  CouponWithBarcodeId, CouponWithBarcodeWithReceptParamsId,
  CouponWithoutBarcodeId,
  ECouponId,
  ImportedCouponForPrintId, ImportedCouponId, ReferralCouponWithBarcodeId,
  StickerId,
  VirtualRuleBarcodeId
} from "./couponKinds/CouponKind";
import {StartPeriodTypes, StopPeriodTypes} from "./IssuingTypes/IssuingType";



export const COUPON_RULE_STATES = {
  'draft'    : "draft",
  'active'   : "active",
  'archive'  : "archive"
}

export const COUPON_RULE_STATE_NAMES = {

  [COUPON_RULE_STATES.draft]: "Черновик",
  [COUPON_RULE_STATES.active]   : "Активное",
  [COUPON_RULE_STATES.archive]  : "В архиве"

}

export const COUPON_RULE_STATE_COLORS = {
  [COUPON_RULE_STATES.draft]: "warning",
  [COUPON_RULE_STATES.active]: "success",
  [COUPON_RULE_STATES.archive]: "danger"
}

export const ISSUING_TYPES = {
  [External.getId()]        : External,
  [Manual.getId()]          : Manual,
  [Pos.getId()]             : Pos,
  [Public.getId()]          : Public,
  [Trigger.getId()]         : Trigger,
  [UniqueCouponGen.getId()] : UniqueCouponGen
};

export const ISSUING_TYPE_NAMES = {
  [Pos.getId()]: "Выдача на кассе",
  [Public.getId()]: "Публикация в открытых источниках",
  [External.getId()]: "По запросу из внешней ИС",
  [Manual.getId()]: "Ручной ввод",
  [UniqueCouponGen.getId()]: "Генерация уникальных купонов",
};

export const COUPON_KIND_NAMES = {
  [ECouponId] : 'Электронный купон',
  [CouponWithBarcodeId] : 'Печатный купон с ШК',
  [CouponRuleBarcodeId] : 'Печатный купон с ШК купонного правила',
  [CouponWithoutBarcodeId]: 'Печатный купон без ШК',
  [ImportedCouponForPrintId] : 'Загруженный набор купонов с ШК для печати',
  [ImportedCouponId]: 'Загруженный набор напечатанных ШК',
  [ReferralCouponWithBarcodeId]  : 'Печатный реферальный купон с ШК',
  [CouponWithBarcodeWithReceptParamsId] : 'Загруженный набор напечатанных ШК с параметрами приёма',
  [StickerId]: 'Купон-наклейка c ШК на позицию',
  [VirtualRuleBarcodeId]: 'Каталог с ШК купонного правила',
}


export const CUSTOM_PERIOD_NAMES = {
  false: 'По времени купона',
  true: 'По параметрам клиента',
}


export const CUSTOM_PERIOD_TYPE_NAMES = {
  clientBirthday : 'По интервалу дня рождения'
}

export const COUPON_APPLY_NAMES  =  {
  'applyInFirstCheck': "Автоприменение без вознаграждения",
  'applyInFirstSuitedCheck': "Автоприменение при наличии вознаграждения",
  'applyOnRequest': "Без автоприменения"
}

export const VALID_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]

export const VALID_DAYS_NAMES = {
  "Monday": "Пн",
  "Tuesday": "Вт",
  "Wednesday": "Ср",
  "Thursday": "Чт",
  "Friday": "Пт",
  "Saturday": "Сб",
  "Sunday": "Вс"
}


export enum MAX_REWARD_LIMIT_TYPES {
  COUPON_COUNT = "couponCount",
  DISCOUNT_PERCENT = "discountPercent",
  DISCOUNT_VALUE  = "discountValue",
  BONUS_PERCENT = "bonusPercent",
  BONUS_VALUE = "bonusValue"
}

export enum MAX_REWARD_LIMIT_SUBTYPES {
  COUPON = "coupon",
  DISCOUNT = "discount",
  BONUS = "bonus"
}

export enum MAX_REWARD_LIMIT_MEASURE_TYPES {
  COUNT = "count",
  PERCENT = "percent",
  VALUE = "value"
}

export const MAX_REWARD_LIMIT_TYPES_SPLIT= {
  [MAX_REWARD_LIMIT_TYPES.COUPON_COUNT] : { type: MAX_REWARD_LIMIT_SUBTYPES.COUPON, measure: MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT },
  [MAX_REWARD_LIMIT_TYPES.DISCOUNT_PERCENT]  :  { type: MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT, measure: MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT  },
  [MAX_REWARD_LIMIT_TYPES.DISCOUNT_VALUE]  :  { type: MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT, measure: MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE  },
  [MAX_REWARD_LIMIT_TYPES.BONUS_PERCENT]  :  { type: MAX_REWARD_LIMIT_SUBTYPES.BONUS, measure: MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT  },
  [MAX_REWARD_LIMIT_TYPES.BONUS_VALUE]  :  { type: MAX_REWARD_LIMIT_SUBTYPES.BONUS, measure: MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE  }
}

export const MAX_REWARD_LIMIT_SUBTYPES_NAMES = {
  [MAX_REWARD_LIMIT_SUBTYPES.COUPON]: "кол-во купонов",
  [MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT]: "макс. скидки",
  [MAX_REWARD_LIMIT_SUBTYPES.BONUS]: "макс. бонусов"
}

export const MAX_REWARD_LIMIT_MEASURE_TYPE_NAMES = {
  [MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT]: "кол-во",
  [MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE]: "сумма",
  [MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT]: "процент"
}

export const MAX_REWARD_LIMIT_MEASURE_VALUES = {
  [MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT]: "шт.",
  [MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE]: "",
  [MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT]: "%",


  [MAX_REWARD_LIMIT_SUBTYPES.COUPON + MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT]: "шт.",

  [MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT + MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE]: "",
  [MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT + MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT]: "%",

  [MAX_REWARD_LIMIT_SUBTYPES.BONUS + MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE]: "шт.",
  [MAX_REWARD_LIMIT_SUBTYPES.BONUS + MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT]: "%"

}

export const MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE = {
  [MAX_REWARD_LIMIT_SUBTYPES.COUPON]: [MAX_REWARD_LIMIT_MEASURE_TYPES.COUNT],
  [MAX_REWARD_LIMIT_SUBTYPES.DISCOUNT]: [MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT, MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE],
  [MAX_REWARD_LIMIT_SUBTYPES.BONUS]: [MAX_REWARD_LIMIT_MEASURE_TYPES.PERCENT, MAX_REWARD_LIMIT_MEASURE_TYPES.VALUE],
}

export enum REWARD_MEASURES_TYPES  {
  COUNT = "count",
  PERCENT = "percent",
  MONEY = "money"
}

export const REWARD_MEASURES_TYPES_VALUES  = {
  [REWARD_MEASURES_TYPES.COUNT]: "Шт.",
  [REWARD_MEASURES_TYPES.MONEY]: "",
  [REWARD_MEASURES_TYPES.PERCENT]: "%"
}

export enum REWARD_IGNORE_LIMIT_TYPES  {
  DISCOUNT  =  "discount",
  BONUS   =   "bonus",
  FIXPRICE  =   "fixprice"
}

export const REWARD_IGNORE_LIMIT_TYPES_NAMES  =  {
  [REWARD_IGNORE_LIMIT_TYPES.DISCOUNT]: "ограничения скидок LOYA",
  [REWARD_IGNORE_LIMIT_TYPES.BONUS]: "ограничения по начислению бонусов LOYA",
  [REWARD_IGNORE_LIMIT_TYPES.FIXPRICE]: "ограничения по фиксированной цене LOYA"
}

export enum REWARD_REWTYPE_TYPES  {
  TOTAL = "total",
  GOODS = "goods",
  SET_OF_GOODS = "setOfGoods",
  POSITION = "position"
}

export const REWARD_REWTYPE_TYPES_NAMES  = {
  [REWARD_REWTYPE_TYPES.TOTAL]: "На чек",
  [REWARD_REWTYPE_TYPES.GOODS]: "На товары",
  [REWARD_REWTYPE_TYPES.SET_OF_GOODS]: "На набор",
  [REWARD_REWTYPE_TYPES.POSITION]: "На позицию"
}

export enum REWARD_ONGOOD_REWTYPE_TYPES {
  EVERY  =  "every",
  COUNT   =   "count",
  ALL    =    "all"
}

export const REWARD_ONGOOD_REWTYPE_TYPES_NAMES  = {
  [REWARD_ONGOOD_REWTYPE_TYPES.EVERY]: 'на каждые',
  [REWARD_ONGOOD_REWTYPE_TYPES.COUNT]: 'на количество',
  [REWARD_ONGOOD_REWTYPE_TYPES.ALL]: 'на все'
}


export const START_PERIOD_NAMES = {
  [StartPeriodTypes.START_PERIOD_FORM_ISSUE]: 'С даты выдачи',
  [StartPeriodTypes.START_PERIOD_FORM_DATE]: 'С конкретной даты',
  [StartPeriodTypes.START_PERIOD_SCHEDULED]: 'Время после выдачи'
}

export const STOP_PERIOD_NAMES = {
  [StopPeriodTypes.STOP_PERIOD_FORM_DATE]: 'Дата окончания (включительно)',
  [StopPeriodTypes.STOP_PERIOD_SCHEDULED]: 'Период действия, включая дату начала действия купонов',
}

export const ISSUE_SETTINGS_START_SCHEDULED_NAMES = {
  'day': "день",
  'week': "неделя",
  'month': "месяц",
  'year': "год"
}

export enum ISSUE_SETTINGS_CAN_BE_SOLD {
  CURRENCY = 'currency',
  BONUS = 'bonus'
}

export const ISSUE_SETTINGS_CAN_BE_SOLD_NAMES = {
  [ISSUE_SETTINGS_CAN_BE_SOLD.CURRENCY]: '',
  [ISSUE_SETTINGS_CAN_BE_SOLD.BONUS]: 'бонус'
}

export enum REWARD_WHAT_TYPES  {
  DISCOUNT  =  'discount',
  BONUS   = 'bonus',
  GIFT  = 'gift',
  FIXPRICE  = 'fixprice'
}

export const REWARD_WHAT_TYPES_NAMES = {
  [REWARD_WHAT_TYPES.DISCOUNT]: "Скидка",
  [REWARD_WHAT_TYPES.BONUS]: "Бонус",
  [REWARD_WHAT_TYPES.GIFT]: "Подарок",
  [REWARD_WHAT_TYPES.FIXPRICE]: "Фиксированная цена",
}

export const REWARD_MEASURE_RELATION = {
  [REWARD_WHAT_TYPES.DISCOUNT]: [REWARD_MEASURES_TYPES.PERCENT, REWARD_MEASURES_TYPES.MONEY],
  [REWARD_WHAT_TYPES.BONUS]:  [REWARD_MEASURES_TYPES.PERCENT, REWARD_MEASURES_TYPES.COUNT],
  [REWARD_WHAT_TYPES.GIFT]:  [REWARD_MEASURES_TYPES.COUNT],
  [REWARD_WHAT_TYPES.FIXPRICE]:  [REWARD_MEASURES_TYPES.MONEY],
}

export const REWARD_ON_GOOD_ITEMS_SORTING_TYPES = {
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc'
}

export const REWARD_ON_GOOD_ITEMS_SORTING_NAMES = {
    [REWARD_ON_GOOD_ITEMS_SORTING_TYPES.PRICE_ASC]: 'наименьшие по цене',
    [REWARD_ON_GOOD_ITEMS_SORTING_TYPES.PRICE_DESC]: 'наибольшие по цене'
}

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ProcessingProxyApiService} from "../../../../api/ProcessingProxyApi/services/processing-proxy-api.service";
import {RawCheck} from "../../../../api/ProcessingProxyApi/models/raw-check";

@Injectable({
  providedIn: "root"
})
export class ProcessingService {

  constructor(
    private httpClient:HttpClient,
    private processingProxyApiService: ProcessingProxyApiService,
  ) {
  }

  private user = '';
  private password  = '';
  private apikey = '';

  public setAuth(user: string, password: string, apikey: string) {
    this.user = user;
    this.password = password;
    this.apikey = apikey;
  }

  private getAuth( ) {
    return  'Basic ' + btoa(this.user + ':' + this.password + ':' + this.apikey);
  }

  public paymentTry$(body: any ) {

    return this.httpClient.put('/processing/paymenttry', body,{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
  //      'Authorization': this.getAuth()
      }
    })
  }

  public preCheck$(body: any ) {

    return this.httpClient.put('/processing/precheck', body,{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
 //       'Authorization': this.getAuth()
      }
    })
  }

  public checkConfirm$(body: any ) {

    return this.httpClient.put('/processing/checkconfirm', body,{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
   //     'Authorization': this.getAuth()
      }
    })
  }

  public checkCancel$(body: any ) {

    return this.httpClient.put('/processing/checkcancel', body,{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
   //     'Authorization': this.getAuth()
      }
    })
  }

  public preCheckDetails$(rawCheck: RawCheck ) {
    this.processingProxyApiService.rootUrl = '/processing';
    return this.processingProxyApiService
      .preCheckDetails(rawCheck)
  }

}


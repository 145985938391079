'use strict';

import 'model/EditionModel/resource';
import 'model/LocationModel/resource';

export default ['$scope', 'editionItem', 'EditionModel', '$state', 'toaster', 'partnerList', '$timeout', 'historyItem', 'UserModel', 'PartnerModel', 'GoodModel', 'LocationModel',
      function ($scope, editionItem, EditionModel, $state, toaster, partnerList, $timeout, historyItem, UserModel, PartnerModel, GoodModel, LocationModel ) {

        $scope._t = new EditionModel();
        $scope.editionItem = editionItem;
        $scope.partnerList = partnerList;
        $scope.historyItem = historyItem;

        $scope.options = {
          locationIdsEnabled : Array.isArray($scope.editionItem.locationIds) && !!$scope.editionItem.locationIds.length
        };

        let backupItem;
        var formStates = {

          draft: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.editionItem);
                $scope.setFormState('edit');
              },
              'remove': function () {

                return EditionModel.delete({id: $scope.editionItem.id}, function() {
                  $state.go('^.index');
                }).$promise;

              },
              inProgress: function() {
                $scope.setState('inProgress')
              }
            },
            activeForm: false
          },

          inProgress: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.editionItem);
                $scope.setFormState('edit');
              },
              draft: function() {
                $scope.setState('draft')
              },
              active: function() {
                $scope.setState('active')
              }
            },
            activeForm: false
          },

          active: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.editionItem);
                $scope.setFormState('edit');
              },
              inProgress: function() {
                $scope.setState('inProgress')
              },
              archive: function() {
                $scope.setState('archive')
              }
            },
            activeForm: false
          },

          archive: {
            buttons: {
              'edit': function () {
                backupItem = angular.copy($scope.editionItem);
                $scope.setFormState('edit');
              },
              active: function() {
                $scope.setState('active')
              }
            },
            activeForm: false
          },

          edit: {
            buttons: {

              'save': function () {

                if ( angular.isUndefined( $scope.editionItem.id ) ) {

                    EditionModel.createEdition($scope.editionItem,
                      function( createdEdition ) {
                        $scope.editionItem = new EditionModel(createdEdition);
                        $state.go('^.view', {id: $scope.editionItem.id}, {reload: true});
                      }, function (data) {
                        toaster.error( 'Ошибка сохранения', data.data.text || data.data  );
                      }
                    );

                } else {

                  $scope.editionItem.$edit({id: $scope.editionItem.id}, function () {
                    $scope.setFormState($scope.editionItem.status);
                    $state.go('^.view', {id: $scope.editionItem.id}, {reload: true});
                  });

                }

              },

              'cancel': function () {

                if ( angular.isUndefined( $scope.editionItem.id ) ) {
                    $state.go('^.index');
                } else {
                  if (backupItem) {
                    $scope.editionItem = angular.copy(backupItem);
                  }

                  $scope.setFormState($scope.editionItem.status);
                }

              }
            },
            activeForm: true

          }

        };

        $scope.statuses = {
          'draft'      : "Черновик",
          'inProgress' : "В подготовке",
          'active'     : "Активный",
          'archive'    : "Архивный"
        };

        $scope.setFormState = function (state) {

          if (formStates[state] !== undefined) {
            let prevState = $scope.curState ? $scope.curState.stateName : '';
            $scope.curState = formStates[state];
            $scope.curState.prevState = prevState;
            $scope.curState.stateName = state;


          } else
            $scope.curState = false;

        };

        $scope.setState = function(state) {

          EditionModel.changeStatus({id: $scope.editionItem.id}, { newStatus: state }, function( edition ) {
            $state.go('^.view', {id: edition.id}, {reload: true});
          });

        }

        $scope.setFormState( angular.isUndefined( $scope.editionItem.id )  ? 'edit'  : $scope.editionItem.status  );

        $scope.fixedOptions = [{
            name: 'Фиксированный номинал',
            id: true
          },{
            name : 'Произвольный номинал',
            id: false
          }
        ];

        $scope.fixedName = function(value) {
          value = !!value;
          return $scope.fixedOptions.filter( i=> i.id === value )[0].name;
        }

        $scope.callbackGoods = function(result) {
          $scope.editionItem.partnerId = result.partnerId;
          $scope.editionItem.sku = result.list[0].id;
        }

        $scope.callbackValidGoods = function(result) {
          $scope.editionItem.partnerId = result.partnerId;
          $scope.editionItem.validSkuList = result.list.map(i=> i.id);
        }

        $scope.disabledInputs = function( field ) {

          switch ($scope.curState.prevState) {
            case "draft"      : return false;
            case "inProgress" :
              return field === 'minNumber' || field === 'copiesQuantity' || field === 'encrypted';
            case "active" :
                return field === 'minNumber' || field === 'copiesQuantity' || field === 'encrypted' || field === 'issueType';

            case "archive" :
              return field !== 'description';
          }

        }

        $scope.maxRangeCalc = function(minRange, copiesQuantity) {
          return parseInt(minRange) +  copiesQuantity - 1;
        }

        $scope.intersectionNotValid = -1;
        let intersectionTimer:any = false;
        $scope.checkIntersection = function() {

          if (intersectionTimer) {
            intersectionTimer.$cancelRequest();
          }

          if (!$scope.editionItem.minNumber || !$scope.editionItem.copiesQuantity) return;

            $scope.intersectionNotValid = 0;
            intersectionTimer = EditionModel.checkRange({
              id: $scope.editionItem.id || -1 ,
              minRange: $scope.editionItem.minNumber,
              maxRange: $scope.maxRangeCalc( $scope.editionItem.minNumber ,  $scope.editionItem.copiesQuantity ),
            });
             intersectionTimer.$promise
              .then(
                (result) => {
                  if (result && typeof result.id !== "undefined" ) {
                    $scope.intersectionNotValid = 1;
                    $scope.intersectionId = result.id;
                    $scope.intersectionName = result.name;
                  } else {
                    $scope.intersectionNotValid = -1;
                  }
                  intersectionTimer = false;
                },
                (result) => {
                  $scope.intersectionNotValid = 2;
                  intersectionTimer = false;
                }
              )

        }

        $scope.minNumber = {};
        $scope.minNumberPrefixes = ['2','98','99'];
        $scope.filterByParams = { 'filter.dimensions' :  'piece' };

        $scope.$watch('editionItem.minNumber', function( value ) {

            if ( typeof value === "undefined" ) {
              $scope.minNumber = {
                head: $scope.minNumberPrefixes[0],
                body: undefined,
                tail: '000'
              };
              return;
            }

            const parseResult = ( new RegExp('^(' + $scope.minNumberPrefixes.join('|') + ')(\\d*)(000)(?:\\.00)?$' ) ).exec(value);
            if (!parseResult || (Array.isArray(parseResult) && parseResult.length < 2 ) )
              return;

            $scope.minNumber = {
              head: parseResult[1],
              body: parseResult.length === 4 ? ("000000000000"+parseResult[2]).slice(-12 + parseResult[1].length + parseResult[parseResult.length - 1 ].length ) : undefined,
              tail: parseResult[parseResult.length - 1 ]
            };

        });

        $scope.$watch('minNumber', function( value ) {

          if (!value.head || typeof value.body === "undefined" ) return;

          $scope.editionItem.minNumber = value.head + ("000000000000"+value.body).slice(-12 + value.head.length + value.tail.length ) + value.tail ;

        }, true);

        $scope.$watchGroup(['editionItem.minNumber', 'editionItem.copiesQuantity'], $scope.checkIntersection );

        $scope.onDateChange = function( id) {
            $scope.editionItem.period[id] = new Date();
            setTimeout(() => $scope.editionItem.period[id] = undefined, 0);

        }

        $scope.$watchGroup([
          'editionItem.period.issueStartDate',
          'editionItem.period.issueStopDate',
          'editionItem.period.useStartDate',
          'editionItem.period.useStopDate'
        ], (newVal) => {

          for( let item of ['issueStartDate','issueStopDate','useStartDate','useStopDate'] ) {
            if ( typeof $scope.editionItem.period[item] === "string" && Date.parse(  $scope.editionItem.period[item] ) > 0 ) {
              $scope.editionItem.period[item] = new Date(Date.parse(  $scope.editionItem.period[item] ) );
            }
          }

        });

        $scope.$watch('editionItem.fixed', function( value, oldVal ) {

          if ( typeof oldVal === "undefined" || value === oldVal ) return;

          delete $scope.editionItem.ratingValue;

        })

        $scope.updateZeroes = function ($event) {
          let newValue = $event.target.value.trim();

          if ( newValue.length > 9 - $scope.minNumber.head.length  && newValue[0] === '0') {
            newValue = newValue.slice(-(9 - $scope.minNumber.head.length) );
            $scope.minNumber.body = newValue;
          }

        }

        $scope.getMaxCopiesQuantity = function() {
          const maxNumbersConst = {
            '2'  : 299999999999,
            '98' : 989999999999,
            '99' : 999999999999
          };

          return maxNumbersConst[$scope.minNumber.head] - parseInt($scope.editionItem.minNumber) > 1000000 ? 1000000:
             (maxNumbersConst[$scope.minNumber.head] - parseInt($scope.editionItem.minNumber) + 1 ) <1000 ? 1000: maxNumbersConst[$scope.minNumber.head] - parseInt($scope.editionItem.minNumber) + 1
        }

        $scope.skuCache = {};
        $scope.getSkuName = function(id, partner) {

          if ($scope.skuCache[id])
            return $scope.skuCache[id];

          $scope.skuCache[id] = GoodModel.get({ partnerId: partner, sku: id }, function( sku ) {
            $scope.skuCache[id] = sku.name;
          }).$promise;

          return $scope.skuCache[id];
        }


        $scope.selectValidLocationList = function( data ) {
          if (data) {
            $scope.editionItem.locationIds = data.ids.slice();
            $scope.editionItem.partnerId = data.partnerId;
          }
        }

        $scope.locationsCache = {};
        $scope.getNameLocationById = function( id : number ) {

          if (typeof $scope.locationsCache[id] === "undefined")
            $scope.locationsCache[id] = LocationModel
              .getLocationByById({id: id})
              .$promise
              .then( ( res: any ) => $scope.locationsCache[id].name = res.name );



          return $scope.locationsCache[id];

        }

        $scope.removeLocationById = function(id) {
          $scope.editionItem.locationIds = $scope.editionItem.locationIds.filter( i => i !== id );
        }

        $scope.changelocationIds = function() {
          if (!$scope.options.locationIdsEnabled) {
            delete $scope.editionItem.locationIds;
          }
        }

      }] ;



import {NgModule} from "@angular/core";

import {SearchFormComponent} from "./search-form/search-form.component";
import {SearchHighlightComponent} from "./search-highlight/search-highlight.component";
import {ResponsiveTableComponent} from "./responsive-table/responsive-table.component";
import {GridContainerComponent} from "./grid-container/grid-container.component";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {UpgradeModule} from "@angular/upgrade/static";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import {BreadcrumbsPathComponent } from './breadcrumbs-path/breadcrumbs-path.component';
import {SelectMechanicComponent} from "./select-mechanic/select-mechanic.component";
import {Ng1AlertComponent} from "./ui/alert/ng1-alert.component";
import {Ng1ProgressbarComponent} from "./ui/progressbar/ng1-progressbar.component";
import {Ng1DatepickerComponent} from "./ui/datepicker/ng1-datepicker.component";
import { Ng1DropdownComponent } from "./ui/dropdown/ng1-dropdown.component";
import {Ng1PopoverComponent} from "./ui/popover/ng1-popover.component";
import {ReceiptViewComponent} from "./receipt-view/receipt-view.component";
import {NavMenuComponent} from "./nav-menu/nav-menu.component";
import {HeaderUserComponent} from './header-user/header-user.component';
import {ReceitDetailsItemComponent} from "./receit-details-item/receit-details-item.component";
import {HeaderAlertBoxComponent} from "./header-alert-box/header-alert-box.component";
import {FeedbackButtonComponent} from "./feedback-button/feedback-button.component";
import {PhotoLoaderComponent} from "./photo-loader/photo-loader.component";
import {AdditionalMenuComponent} from "./additional-menu/additional-menu.component";
import {PartnerMenuComponent} from "./partner-menu/partner-menu.component";
import {SortViewComponent} from "./sort-view/sort-view.component";
import {TextHighlightComponent} from "./text-highlight/text-highlight.component";
import {GridItemFormDirective} from "./grid-container/grid-item-form.directive";
import {TranslocoRootModule} from "../../transloco-root.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgSelectModule} from "@ng-select/ng-select";
import {PipesComponentsModule} from "../pipes/pipes.components.module";
import {DirectiveModule} from "../directive/directive.module";
import {SelectCampaignComponent} from "./select-campaign/select-campaign.component";
import {SelectCouponRuleComponent} from "./select-coupon-rule/select-coupon-rule.component";
import {SearchInputModule} from "./search-input/search-input.module";
import {SelectInformingTemplateComponent} from "./select-informing-template/select-informing-template.component";
import {SparklineGraphComponent} from "./sparkline-graph/sparkline-graph.component";
import {HighchartsChartModule} from "highcharts-angular";
import { CampaignTagsComponent} from "./campaignTags/campaign-tags.component";
import {TagInputModule} from "ngx-chips";
import {AnchorMenuComponent} from "./anchor-menu/anchor-menu.component";
import {FormContainerComponent} from "./form-container/form-container.component";
import {FormItemRowDirective} from "./form-container/form-item-row.directive";
import {NoopAnimationsModule} from "@angular/platform-browser/animations";
import {SelectPartnerComponent} from "./select-partner/select-partner.component";
import {SelectCampaignCategoryComponent} from "./select-campaign-category/select-campaign-category.component";
import {SelectLocationGroupComponent} from "./select-location-group/select-location-group.component";
import {SelectSegmentComponent} from "./select-segment/select-segment.component";
import {SelectGroupComponent} from "./select-group/select-group.component";
import {SelectCampaignLimitationsComponent} from "./select-campaign-limitations/select-campaign-limitations.component";
import {SelectMessageTemplateComponent} from "./select-message-template/select-message-template.component";
import {SelectCardComponent} from "./select-card/select-card.component";
import {PosPrinterTemplateComponent} from "./pos-printer-template/pos-printer-template.component";
import {SelectClientComponent} from "./select-client/select-client.component";
import {BarcodeComponent} from "./barcode/barcode.component";

@NgModule({
  declarations: [
    SearchFormComponent,
    SearchHighlightComponent,
    ResponsiveTableComponent,
    GridContainerComponent,
    BreadcrumbsPathComponent,
    SelectMechanicComponent,
    Ng1AlertComponent,
    Ng1ProgressbarComponent,
    Ng1DatepickerComponent,
    Ng1DropdownComponent,
    Ng1PopoverComponent,
    ReceiptViewComponent,
    NavMenuComponent,
    HeaderUserComponent,
    ReceitDetailsItemComponent,
    HeaderAlertBoxComponent,
    FeedbackButtonComponent,
    PhotoLoaderComponent,
    AdditionalMenuComponent,
    PartnerMenuComponent,
    SortViewComponent,
    TextHighlightComponent,
    GridItemFormDirective,
    SelectCampaignComponent,
    SelectClientComponent,
    SelectCouponRuleComponent,
    SelectInformingTemplateComponent,
    SparklineGraphComponent,
    SelectCardComponent,
    CampaignTagsComponent,
    AnchorMenuComponent,
    FormContainerComponent,
    FormItemRowDirective,
    SelectPartnerComponent,
    SelectCampaignCategoryComponent,
    SelectLocationGroupComponent,
    SelectSegmentComponent,
    SelectGroupComponent,
    SelectCampaignLimitationsComponent,
    SelectMessageTemplateComponent,
    PosPrinterTemplateComponent,
    BarcodeComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    NgbModule,
    TranslocoRootModule,
    InfiniteScrollModule,
    NgSelectModule,
    PipesComponentsModule,
    DirectiveModule,
    SearchInputModule,
    HighchartsChartModule,
    TagInputModule,
    NoopAnimationsModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[],
  exports: [
    SearchFormComponent,
    SearchHighlightComponent,
    ResponsiveTableComponent,
    GridContainerComponent,
    BreadcrumbsPathComponent,
    SelectMechanicComponent,
    Ng1AlertComponent,
    Ng1ProgressbarComponent,
    Ng1DatepickerComponent,
    Ng1DropdownComponent,
    Ng1PopoverComponent,
    ReceiptViewComponent,
    NavMenuComponent,
    HeaderUserComponent,
    ReceitDetailsItemComponent,
    HeaderAlertBoxComponent,
    FeedbackButtonComponent,
    PhotoLoaderComponent,
    AdditionalMenuComponent,
    PartnerMenuComponent,
    SortViewComponent,
    TextHighlightComponent,
    GridItemFormDirective,
    SelectCampaignComponent,
    SelectCouponRuleComponent,
    SelectInformingTemplateComponent,
    SparklineGraphComponent,
    SelectCardComponent,
    CampaignTagsComponent,
    AnchorMenuComponent,
    FormContainerComponent,
    FormItemRowDirective,
    SelectPartnerComponent,
    SelectClientComponent,
    SelectCampaignCategoryComponent,
    SelectLocationGroupComponent,
    SelectSegmentComponent,
    SelectGroupComponent,
    SelectCampaignLimitationsComponent,
    SelectMessageTemplateComponent,
    PosPrinterTemplateComponent,
    BarcodeComponent
  ]
})
export class SharedComponentsModule {

  constructor() {}

}

<partner-menu
  [selectedPartner]="selectedPartner"
  (selectedPartnerChange)="changeSelectedPartner($event)"
>

<div class="fixed-tool-panel">

  <button
          *ngIf="selectedPartner"
          class="btn btn-success btn-sm btn-addon pull-right"
          data-testid="location.add()"
          type="button"
          [uiSref]="'^.view({partnerId:'+selectedPartner.id+'})'"
  >
    <i class="fa fa-plus "></i>
    <span class="btn-name ">  {{ 'interface.buttons.add' | transloco }}</span>
  </button>

</div>

  <div class="wrapper stick-search-box">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Название/Адрес/Описание/Код"
    ></search-input>
  </div>
 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>
         <th>
           <sort-view
             column="name"
             [params]="locationsService.getListParams"
             (onChange)="reset()"
             data-testid="location.name.sort()"
           >
             Точка продаж
           </sort-view>
         </th>
         <th>
           <sort-view
             column="address"
             [params]="locationsService.getListParams"
             (onChange)="reset()"
             data-testid="location.address.sort()"
           >
          адрес
           </sort-view>
         </th>
         <th class="table-row-sm table-row-xs ">
           <sort-view
             column="description"
             [params]="locationsService.getListParams"
             (onChange)="reset()"
             data-testid="location.description.sort()"
           >
            описание
           </sort-view>
         </th>
         <th class="table-row-sm table-row-xs ">
           <sort-view
             column="code"
             [params]="locationsService.getListParams"
             (onChange)="reset()"
             data-testid="location.code.sort()"
           >
             код
           </sort-view>
         </th>
       </tr>
     </thead>
      <tbody>
        <ng-container  *ngFor="let item of listLocations">
          <tr
              [uiSref]="'^.view({id:'+item.id+', partnerId:'+selectedPartner.id+'})'"
              class="pointer" data-testid="item of locations"
          >
            <td class="table-big-link">
              <a  href="javascript:void(0)" class="overflow-wrap-break" data-testid="location.index.name">
                <text-highlight [search]="searchString" [text]="item.name" ></text-highlight>
              </a>
            </td>

            <td class="table-big-link"  >
              <a  href="javascript:void(0)" class="overflow-wrap-break" data-testid="location.index.address">
                <text-highlight [search]="searchString" [text]="item.address" ></text-highlight>
              </a>
            </td>

            <td class="table-big-link">
              <a  href="javascript:void(0)"  class="overflow-wrap-break" data-testid="location.index.description">
                <text-highlight [search]="searchString" [text]="item.description" ></text-highlight>
              </a>
            </td>

            <td class="table-big-link">
              <a  href="javascript:void(0)"  class="overflow-wrap-break" data-testid="location.index.code">
                <text-highlight [search]="searchString" [text]="item.code" ></text-highlight>
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

  <div class="text-center text-warning m-t-lg"
       *ngIf="isLocationsLocations"
  >
    <div class="">
      <i class="fa fa-3x fa-spin fa-spinner"></i>
    </div>

  </div>


  <scroll-up></scroll-up>

</partner-menu>

import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'bonus-config-ng1'
})
export class BonusConfigMechanicDirective extends UpgradeComponent {

  @Input()  model       : any;
  @Input()  readOnly    : any;
  @Input()  fieldPath   : any;
  @Input()  initPartner : any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('bonusConfig', elementRef, injector);
  }
}


import {Directive, ElementRef, Injector, SimpleChanges, Input, Output} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'client-log-history-ng1'
})
export class ClientLogHistoryDirective extends UpgradeComponent {

  @Input() clientId  : any;
  @Input() cardId    : any;
  @Input() accountId : any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('clientLogHistory', elementRef, injector);
  }

}





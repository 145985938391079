'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";
import ckeditor_loader from "agModules/ckeditor";

let routePath   = module.name + '.coupons.coupons.view';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )
            .url('/:id/:clientId')

            .template( '<coupons-view-page [id]="id" [client-id]="clientId"></coupons-view-page>', true)
            .controller(['$scope', '$stateParams',
              function($scope, $stateParams) {
                $scope.id = $stateParams.id;
                $scope.clientId = $stateParams.clientId;
              } ])

            .resolve('$ckePrepare', ckeditor_loader)

            .permissions( {
              only: [RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter],
            })


            .breadcrumbs( ["Купоны", "Купоны " , "Инфо о Купоне"] );

        }]);

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LevelByChecksPluginApiConfiguration as __Configuration } from '../level-by-checks-plugin-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BonusLevel } from '../models/bonus-level';
import { PartnerSettingsApi } from '../models/partner-settings-api';
import { Record } from '../models/record';
import { RecordsQuery } from '../models/records-query';
@Injectable({
  providedIn: 'root',
})
class LevelByChecksPluginApiService extends __BaseService {
  static readonly getBonusLevelPath = 'client/{clientId}';
  static readonly updateProfilePath = 'client/{clientId}';
  static readonly getSettingsPath = 'settings';
  static readonly updateSettingsPath = 'settings';
  static readonly queryRecordsPath = 'records';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Получить level
   * @param clientId ID клиента
   * @return level
   */
  getBonusLevelResponse(clientId: number): __Observable<__StrictHttpResponse<BonusLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `client/${encodeURIComponent(String(clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BonusLevel>;
      })
    );
  }
  /**
   * Получить level
   * @param clientId ID клиента
   * @return level
   */
  getBonusLevel(clientId: number): __Observable<BonusLevel> {
    return this.getBonusLevelResponse(clientId).pipe(
      __map(_r => _r.body as BonusLevel)
    );
  }

  /**
   * Изменение профиля клиента
   *
   * Изменение профиля клиента
   * @param params The `LevelByChecksPluginApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `profile`:
   *
   * - `clientId`: ID клиента
   *
   * @return profile updated
   */
  updateProfileResponse(params: LevelByChecksPluginApiService.UpdateProfileParams): __Observable<__StrictHttpResponse<BonusLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.profile;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `client/${encodeURIComponent(String(params.clientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BonusLevel>;
      })
    );
  }
  /**
   * Изменение профиля клиента
   *
   * Изменение профиля клиента
   * @param params The `LevelByChecksPluginApiService.UpdateProfileParams` containing the following parameters:
   *
   * - `profile`:
   *
   * - `clientId`: ID клиента
   *
   * @return profile updated
   */
  updateProfile(params: LevelByChecksPluginApiService.UpdateProfileParams): __Observable<BonusLevel> {
    return this.updateProfileResponse(params).pipe(
      __map(_r => _r.body as BonusLevel)
    );
  }

  /**
   * Запрос настроек
   *
   * Запрос настроек
   * @return Настройки
   */
  getSettingsResponse(): __Observable<__StrictHttpResponse<Array<PartnerSettingsApi>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PartnerSettingsApi>>;
      })
    );
  }
  /**
   * Запрос настроек
   *
   * Запрос настроек
   * @return Настройки
   */
  getSettings(): __Observable<Array<PartnerSettingsApi>> {
    return this.getSettingsResponse().pipe(
      __map(_r => _r.body as Array<PartnerSettingsApi>)
    );
  }

  /**
   * Изменение настроек
   *
   * Изменение настроек
   * @param params undefined
   */
  updateSettingsResponse(params: Array<PartnerSettingsApi>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Изменение настроек
   *
   * Изменение настроек
   * @param params undefined
   */
  updateSettings(params: Array<PartnerSettingsApi>): __Observable<null> {
    return this.updateSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Список записей об изменениях
   *
   * Список записей об изменениях
   * @param params Параметры запроса
   * @return Список записей
   */
  queryRecordsResponse(params?: RecordsQuery): __Observable<__StrictHttpResponse<Array<Record>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Record>>;
      })
    );
  }
  /**
   * Список записей об изменениях
   *
   * Список записей об изменениях
   * @param params Параметры запроса
   * @return Список записей
   */
  queryRecords(params?: RecordsQuery): __Observable<Array<Record>> {
    return this.queryRecordsResponse(params).pipe(
      __map(_r => _r.body as Array<Record>)
    );
  }
}

module LevelByChecksPluginApiService {

  /**
   * Parameters for updateProfile
   */
  export interface UpdateProfileParams {
    profile: BonusLevel;

    /**
     * ID клиента
     */
    clientId: number;
  }
}

export { LevelByChecksPluginApiService }

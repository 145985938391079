import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  OnChanges,
  SimpleChanges,
  ElementRef
} from "@angular/core";
import { getNg1Service } from './../../class/utils/angularjs.utils'
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'photo-loader',
  template: `

    <div class="m-b text-center">
      <div class=" img-container">
        <ng-container *ngIf="model">
        <div
          [ngStyle]="{'background-image':'url(' + model + ')'}"
             class="img-rounded"></div>
        </ng-container>
        <div *ngIf="!model"
             style="background-image:url('/assets/img/user-default.jpg')"
             class="img-rounded"
        ></div>

        <input type="file"
               #inputFile
               id="inputFile"
               class="none"
               (change)="onChange($event)"
               filters="*.png,*.jpg"/>
      </div>

      <div class="m-t-xs f-s-9"
           *ngIf="isActive"
      >
        <a href="javascript:void(0)" class="m-b"
           (click)="openFileDialog()">
          <i class=" fa fa-pencil m-r-xs text-muted">
          </i>
          Изменить фото
        </a>
        <br/>
        <a href="javascript:void(0)" *ngIf="model" class="m-b"
           (click)="removePhoto()">
          <i class=" fa fa-remove m-r-xs text-muted">
          </i>
          Удалить фото
        </a>
      </div>
    </div>

    `
  ,
  styleUrls: ['./photo-loader.component.scss'],
})
export class PhotoLoaderComponent implements OnChanges{

  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file

  @Input() model: any;
  @Output() modelChange = new EventEmitter<string>();

  @Input() isActive: any;
  @Input() size: any;

  @ViewChildren('inputFile') inputFile: any;

  constructor(
    private http:HttpClient,
    private el: ElementRef,
  ) {
  }

  openFileDialog() {
    this.inputFile.first.nativeElement.click();
  }

  removePhoto() {
    this.model = undefined;
    this.modelChange.emit(this.model);
  }
  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
    if (this.file)
      this.onUpload();
  }

  // OnClick of button Upload
  onUpload() {
    this.loading = !this.loading;
    this.upload(this.file).subscribe(
      (event: string) => {
        this.model = event;
        this.modelChange.emit(this.model);
      }
    );
  }

  upload(file):Observable<any> {


    const formData = new FormData();
    formData.append("file", file, file.name);

    return this.http.post('/files', formData, {
      responseType: 'text'
    })
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['size']) {
      this.el.nativeElement.style.setProperty('--photo-loader-size', changes['size']?.currentValue || '200px');
    }
  }

}

/*
    <div ng-show="curState.activeForm">
      <div class="m-b text-center" nv-file-drop uploader="uploader"
           options="uploaderOptions">
        <div class="thumb-lg">
          <img ng-if="selectedUser.model" ng-src="{{ selectedUser.model }}"
               class="img-rounded">
          <img ng-if="!selectedUser.model" src='/assets/img/user-default.jpg'
               class="img-rounded">
          <input type="file" id="fileUploaderInp" class="none" nv-file-select
                 uploader="uploader"
                 options="uploaderOptions" filters="*.png,*.jpg"/>
        </div>
        <div class="m-t-xs f-s-9">
          <a href class="m-b" ng-click="openFileDialog()">
            <i class=" fa fa-pencil m-r-xs text-muted">
            </i>
            Изменить фото
          </a>
          <br/>
          <a href ng-if="selectedUser.model" class="m-b" ng-click="removePhoto()">
            <i class=" fa fa-remove m-r-xs text-muted">
            </i>
            Удалить фото
          </a>
        </div>
      </div>
    </div>
 */

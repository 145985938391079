<div class="p_container">
    <span [class]="{'pre-hidden':!isPrepared}">
        <div class="s_check">
          <pre class="no-select"
               [innerHTML]="messagePrepared"
               data-testid="msgTemplate.pos.template.message"></pre>

        </div>

        <div class=s_printer></div>
    </span>

</div>

import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {UpgradeModule} from "@angular/upgrade/static";
import {ScrollUpComponent} from "./scroll-up.component";

@NgModule({
  declarations: [
    ScrollUpComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
  ],
  providers: [],
  bootstrap: [],
  exports:[
    ScrollUpComponent,
  ]
})
export class ScrollUpModule {

  constructor() {}

}

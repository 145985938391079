<div class="row">

  <div class=""
       [ngClass]="{'col-md-12 col-lg-6': isCirculationGenerated(circulation), 'col-md-12 col-lg-12':!isCirculationGenerated(circulation)}"
       *ngFor="let circulation of circulationList; let $index=index" data-testid="item in editions">
    <div class="card">

      <div class="card-header">
        <span class="font-bold">
            Тираж {{ $index+1 }}
        </span>

        <button type="button"
                *ngIf="isRuleActive && !isCirculationLaunched(circulation) && !isCirculationNew(circulation)"
                (click)="removeCirculation(circulation, $index+1)"
                data-testid="cRule.edition.delete()"
                class="btn btn-sm m-t-n-xs pull-right bg-danger "
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>

      <form name="newCirculationForm"
            #newCirculationForm="ngForm"
            class="form-horizontal ">

        <div class="card-body"
             *ngIf="!isCirculationGenerated(circulation)">


            <div class="form-group">
              <div class="row">
                <label class="col-sm-12 col-md-2 control-label">Начальное значение</label>
                <div class="col-sm-12 col-md-10">
                  <div class="input-group"
                       *ngIf="circulationList.length === 1"
                  >
                    <span class="input-group-addon" [innerText]="rulePrefix" data-testid="cRule.edition.prefix"></span>

                    <span class="bg-white input-group-addon m-l-n-xxs m-r-n-xxs"
                          *ngIf="countZeroes(rulePrefix, tmpNum)"
                          [innerText]="countZeroes(rulePrefix, tmpNum)"></span>

                    <input type="number"
                           class="form-control text-right m-l-n-xxs "
                           [ngModel]="tmpNum"
                           (ngModelChange)="tmpNum = $event; startNumberChanged(circulation, tmpNum)"
                           name="tmpNum_c"
                           data-testid="cRule.edition.input.start"
                           [required]="true"
                           [min]="0"
                           [maxlength]="getMaximumDigits()"
                           [max]="getMaximumValue()"
                    >
                  </div>

                  <div class=""
                       *ngIf="circulationList.length > 1"
                  >
                    <input type="text"
                           class="form-control text-right"
                           [value]="padZeroes(rulePrefix, circulation.startNumber )"
                           data-testid="cRule.edition.input.disable.start"
                           [disabled]="true"
                    >
                  </div>


                  <span class="help-block text-right m-b-none"
                        *ngIf="newCirculationForm?.controls['circulation']?.valid && circulationList?.length === 1">Диапазон начального значения
                      <span
                        class="font-bold"
                        [innerText]="padZeroes(rulePrefix, circulation.startNumber ) "
                        data-testid="cRule.edition.start"
                      ></span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-12 col-md-2 control-label">Тираж</label>
              <div class="col-sm-12 col-md-10">

                <input type="number"
                       style="min-width:2em;"
                       name="circulation"
                       class="form-control text-right"
                       [ngModel]="circulation.$circulation"
                       (ngModelChange)="circulation.$circulation = $event; circulationChanged(circulation);"
                       data-testid="cRule.edition.input.count"
                       (change)="circulationChanged(circulation)"
                       [required]="true"
                       [maxlength]="getMaximumDigits()"
                       [max]="getMaximumCirculation(circulation)"
                >

                <span class="help-block text-right m-b-none"
                      *ngIf="newCirculationForm?.controls['circulation']?.valid">Диапазон конечного значения
                    <span
                      class="font-bold"
                      [innerText]="padZeroes(rulePrefix, circulation.stopNumber )"
                      data-testid="cRule.edition.stop"
                    ></span>
                </span>
              </div>
            </div>



        </div>

        <div class="card-footer text-right"
             *ngIf="!isCirculationGenerated(circulation)"
        >
          <button
            class="btn btn-sm btn-addon btn-default m-r-sm"
            (click)="rejectCirculation(circulation)"
            data-testid="cRule.edition.cancel()"
          >
            <i class="fa fa-times text-danger"></i>
            <span class="btn-name">Отмена</span>
          </button>

          <button
            class="btn btn-sm btn-addon  btn-default"
            (click)="saveCirculation(circulation, $index + 1)"
            data-testid="cRule.edition.save()"
            [disabled]="newCirculationForm.invalid"
          >
            <i class="fa fa-check text-primary"></i>
            <span class="btn-name">Сохранить</span>
          </button>

        </div>

      </form>

      <div class="card-body no-padder"
           *ngIf="isCirculationGenerated(circulation)">

        <ul class="list-group m-b-none no-radius f-s-9 bg-light lter detail-group">
          <li class="list-group-item">
            <span class="pull-right font-bold">
                <span [innerText]="padZeroes(rulePrefix, circulation.startNumber)" data-testid="cRule.edition.range.start"></span> - <span
              [innerText]="padZeroes(rulePrefix, circulation.stopNumber)" data-testid="cRule.edition.range.stop"></span>
            </span>
            Диапазон тиража
          </li>

          <li class="list-group-item">
            <span class="pull-right  font-bold">
                <span [innerText]="circulation.$circulation" data-testid="cRule.edition.count"></span> шт.
            </span>
            Тираж
          </li>

          <li class="list-group-item clearfix" *ngIf="!!circulation?.runningUserName">
            <div class="pull-right text-right">
              <span class="font-bold" [innerText]="circulation.runningUserName" data-testid="cRule.edition.user"></span>
            </div>
            Инициатор
          </li>
        </ul>

      </div>


      <div class="card-footer m-t-n-xxs text-right"
           *ngIf="canCirculationRun(circulation)">

        <button
          class="btn btn-sm btn-addon  btn-success"
          (click)="runCirculation(circulation)"
          data-testid="cRule.edition.run()"
        >
          <i class="fa fa-play "></i>
          <span class="btn-name">Выпустить тираж</span>
        </button>

      </div>

      <div class="card-footer m-t-n-xxs f-s-8 text-muted text-right"
           *ngIf="isCirculationLaunched(circulation) && !isCirculationRunning(circulation)">

                  <span> Тираж выпущен
                      <span [innerText]="circulation.runningDateTime | loyaDateTime" data-testid="cRule.edition.runningDateTime"></span>
                  </span>

      </div>

      <div class="card-footer m-t-n-xxs   text-left"
           *ngIf="isCirculationError(circulation)">

                  <span class="text-muted">
                      <span class=" f-s-8 text-danger" data-testid="cRule.edition.error">Ошибка выпуска</span>
                  </span>

        <button
          *ngIf="isRuleActive"
          class="btn btn-sm btn-addon  pull-right btn-success"
          (click)="continueCirculation(circulation)"
          data-testid="cRule.edition.reissue()"
        >
          <i class="fa fa-play "></i>
          <span class="btn-name">Перевыпустить</span>
        </button>

        <div class="clearfix"></div>

      </div>

      <div class="card-footer m-t-n-xxs f-s-8  text-right"
           *ngIf="isCirculationLaunched(circulation) && isCirculationRunning(circulation)">

        <span class="text-muted" data-testid="cRule.edition.success">Тираж выпускается</span>
        <i class="fa fa-spin fa-lg m-l-sm fa-spinner text-success"></i>

      </div>

    </div>
  </div>

</div>

<div class="row"
     *ngIf="canAddNewCirculation()"
>
  <div class="col-md-12 col-lg-12 text-right">
    <button
      class="btn btn-sm btn-addon btn-success"
      (click)="addNewCirculation()"
      data-testid="cRule.edition.add()"
    >
      <i class="fa fa-plus"></i>
      <span class="btn-name">Добавить тираж</span>
    </button>
  </div>
</div>

<div class="row"
     *ngIf="!circulationList || !circulationList.length"
>

  <div class="col-md-12 text-center m-b">
    <h5>
      <i class="text-warning-dker fa fa-warning m-r-sm"></i>Тиражи по купонному правилу не заданы
    </h5>
  </div>

</div>

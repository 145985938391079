import {Injectable, NgZone} from "@angular/core";
import {CampaignApiService} from "../../../../api/CampaignApi/services";
import {CampaignQueryParam} from "../../../../api/CampaignApi/models/campaign-query-param";
import {ParamsHandler} from "../ParamsHandler/Params.handler";
import {CampaignCommonApiService} from "../../../../api/CampaignCommonApi/services/campaign-common-api.service";
import {interval, Observable, of} from "rxjs";
import {Card} from "../../../../api/CardApi/models/card";
import {CARD_STATES} from "../Card/Card.values";
import {CampaignProtocol} from "../../../../api/CampaignApi/models/campaign-protocol";
import {catchError, exhaustMap, map, mergeMap, switchMap} from "rxjs/operators";
import {CAMPAIGN_COMPLETENESS} from "./Campaign.values";
import {CampaignStateGetResult} from "../../../../api/CampaignApi/models/campaign-state-get-result";
import {CampaignResourceInfo} from "../../../../api/CampaignCommonApi/models/campaign-resource-info";
import {CampaignTime} from "../../../../api/CampaignApi/models/campaign-time";
import {
  CheckCouponRuleIssueUntilRequest
} from "../../../../api/CampaignApi/models/check-coupon-rule-issue-until-request";
import {InformingTemplateService} from "../InformingTemplate/InformingTemplate.service";
import {InformingTemplate} from "../../../../api/InformingTemplateApi/models/informing-template";


@Injectable()
export class CampaignService {

  constructor(
    private campaignApiService: CampaignApiService,
    private campaignCommonApiService :CampaignCommonApiService,
    private informingTemplateService: InformingTemplateService,
    private ngZone: NgZone
  ) {
  }

  public queryParams = new ParamsHandler({
    pager: {
      type: "object",
      dropField  : "pager.drop",
      limitField : "pager.limit",
      limit: 200
    },
    search: {
      field: "search"
    },
    sort: {
      type: "object",
      sortField  : 'sorting.field',
      orderField : 'sorting.order'
    },
    filters: [
      {
        field: 'filter.ids',
        id: 'ids',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.partnerId',
        id: 'partnerId',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'selectedId',
        currentValue: undefined
      },
      {
        field: 'filter.states',
        id: 'states',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.fromDate',
        id: 'fromDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.tillDate',
        id: 'tillDate',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.datesKind',
        id: 'datesKind',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.locations',
        id: 'locations',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.skuList',
        id: 'skuList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.categoryList',
        id: 'categoryList',
        type: 'object',
        currentValue: undefined
      },
      {
        field: 'filter.groupsList',
        id: 'groupsList',
        type: 'object',
        currentValue: undefined
      },
    ]
  });

  public query$(params:CampaignQueryParam){
    return this.campaignApiService.query(params);
  }

  public get$(id: number){
    return this.campaignApiService
      .get(id)
      .pipe(
        map(campaignItem => {
            campaignItem?.campaign?.campaignTime?.periods?.forEach( period => {

              ['startTime', 'stopTime'].forEach( id => {
                if ( /^\d\d:\d\d:\d\d$/.test(period?.[id]) ) {
                  period[id] = period[id].split(':').splice(0,2).join(':')
                }
              })

            })

          return campaignItem;
        })
      )

  }

  public getOrCreate$(id?: number): Observable<CampaignProtocol> {

    if (!!id || id === 0) {
      return this.get$(id);
    }

    return of(<any> {
      "campaign": {

        "campaignTime": {
          "informationDate": 1,
          "periods": [
            {
              "startTime": "00:00",
              "stopTime": "23:59",
              "daysOfWeek": [1, 2, 3, 4, 5, 6, 7]
            }
          ]
        },
        "communication": {
          "useCheck": false,
          "usePos": false,
          "useViber": false,
          "useEmail": false,
          "useSms": false,
          "usePrefer": false,
          "notUse": true,
        },
        "listOfTags": [],
        "state": "draft"
      },
      "mechanic": {
        "name": "test designer",
        "description": "test desc"
      },
      planValues: {
        "clientsCount": 0,
        "operationCount": 0,
        "responseCount": 0,
        "responsePercent": 0,
        "emailTotal": "0.00",
        "smsTotal": "0.00",
        "discountTotal": "0.00",
        "bonusTotal": 0,
        "giftTotal": "0.00",
        "additionalTotal": "0.00",
        "totalCost": "0.00",
        "totalByClient": "0.00",
        "clientTotal": "0.00",
        "clientTotalPercent": 0,
        "clientSelling": "0.00",
        "clientSellingTotal": "0.00",
        "clientIncome": "0.00",
        "clientIncomePercent": 0,
        "prevTotal": 0
      },
      elSettings: {}
    });

  }

  public create$(params: CampaignProtocol){

    params?.campaign?.campaignTime?.periods?.forEach( period => {
      period.startTime = /^\d\d:\d\d$/.test(period.startTime) ? `${period.startTime}:00` : period.startTime;
      period.stopTime = /^\d\d:\d\d$/.test(period.stopTime) ? `${period.stopTime}:59` : period.stopTime;
    })

    return this.decorateInformingTemplate(params)
      .pipe(
        switchMap( result => this.campaignApiService.create(result))
      )
  }

  public update$( id: number, body: CampaignProtocol){

    body?.campaign?.campaignTime?.periods?.forEach( period => {
      period.startTime = /^\d\d:\d\d$/.test(period.startTime) ? `${period.startTime}:00` : period.startTime;
      period.stopTime = /^\d\d:\d\d$/.test(period.stopTime) ? `${period.stopTime}:59` : period.stopTime;
    })

    return this.decorateInformingTemplate(body)
      .pipe(
        switchMap( result =>
          this.campaignApiService.update({
            id,
            campaignProtocol: body
          })
        )
      );

  }

  public createOrUpdate$(params: CampaignProtocol) {
    if ( params?.campaign?.id >=0)
      return this.update$(params.campaign.id, params)
    else
      return this.create$(params);
  }

  public delete$(id: number){
    return this.campaignApiService.delete(id);
  }

  public markCampaignByIdWithListOfTags$(params: CampaignApiService.MarkCampaignByIdWithListOfTagsParams) {
    return this.campaignApiService.markCampaignByIdWithListOfTags(params);
  }

  public campaignDetails$(campaignId: number){
    return this.campaignCommonApiService.campaignDetails(campaignId);
  }

  public campaignResourcesInfo$(campaignId: number){
    return this.campaignCommonApiService.campaignResourcesInfo(campaignId);
  }

  public campaignTargetInfo$(params:CampaignApiService.TargetGroupInfoParams){

    if (params?.excludeGroupIds?.length) {
      params.excludeGroupIds = [<any>params?.excludeGroupIds?.join(',')];
    }

    return this.campaignApiService.targetGroupInfo(params)
  }

  public campaignTimeCount$(campaignTime: CampaignTime){

    campaignTime = Object.assign({}, campaignTime);
    campaignTime.periods = campaignTime?.periods.map( period => {
      period = Object.assign({}, period);
      period.startTime = /^\d\d:\d\d$/.test(period.startTime) ? `${period.startTime}:00` : period.startTime;
      period.stopTime = /^\d\d:\d\d$/.test(period.stopTime) ? `${period.stopTime}:59` : period.stopTime;

      return period;
    })

    return this.campaignApiService.campaigntimecount(campaignTime)
  }

  public updateResources$( id: number, resources: Array<CampaignResourceInfo>){
    return this.campaignCommonApiService.postCampaignResourcesInfo({
      id,
      resources
    })
      .pipe(
        switchMap( i => this.campaignResourcesInfo$(id))
      )
  }


  public setState$(id: number | string, state: string) {

    return this.campaignApiService.setState({
      id: Number(id),
      state: state
    }).pipe(
      switchMap(result => this.get$(result.id))
    )
  }

  public getState$(id: number | string) {
    return this.campaignApiService.getState(Number(id) )
  }

  public stateUpdater$(id: number | string, period = 3 * 1000):Observable<CampaignStateGetResult> {

    let result;
    this.ngZone.runOutsideAngular(() => {
      result = interval(period)
        .pipe(
          exhaustMap( () =>
            this.getState$(id)
              .pipe(
                catchError(result => {
                  result?.stopPopupError();
                  return of(<any>{ state: undefined, completeness: CAMPAIGN_COMPLETENESS.error })
                })
              )
          ),
        )
    });

    return result;
  }

  public checkCouponRuleIssueUntil$(params: CheckCouponRuleIssueUntilRequest) {
    return this.campaignApiService.checkCouponRuleIssueUntil(params)
  }

  private decorateInformingTemplate(body: CampaignProtocol) {

    if ( !body?.campaign?.informingId || typeof body?.campaign?.informingId !== "object")
      return of(body);

    return this.informingTemplateService.create$(
      <InformingTemplate>body?.campaign?.informingId
    ).pipe(
      map( result => {
        body.campaign.informingId = result.id;
        return body
      })
    )
  }
}

import {CAMPAIGN_STATES} from "../Campaign/Campaign.values";

export enum COUPON_STATES {
  ACTIVE            = "active",
  PARTIAL_APPLY     =  "partialApply",
  FULLY_APPLY       = "fullyApply",
  EXPIRED           = "expired",
  EXPIRED_WITH_REST = "expiredWithRest",
  MANUAL_DELETED    =  "manualDeleted",
}

export const COUPON_STATE_NAMES = {

  [COUPON_STATES.ACTIVE]: "Активный",
  [COUPON_STATES.PARTIAL_APPLY]: "Частичное использование",
  [COUPON_STATES.FULLY_APPLY]: "Полное использование",
  [COUPON_STATES.EXPIRED]: "Истек срок действия",
  [COUPON_STATES.EXPIRED_WITH_REST]: "Истек срок действия (частичное использование)",
  [COUPON_STATES.MANUAL_DELETED]: "Удален оператором"
}


export const COUPON_KIND_TYPES = {
  'eCoupon': 'eCoupon',
  'couponWithBarcode': 'couponWithBarcode',
  'couponWithoutBarcode': 'couponWithoutBarcode',
  'virtualRuleBarcode': 'virtualRuleBarcode',
  'importedCouponForPrint': 'importedCouponForPrint',
  'sticker': 'sticker',
  'referralCouponWithBarcode': 'referralCouponWithBarcode',
  'couponRuleBarcode': 'couponRuleBarcode',
  'importedCoupon': 'importedCoupon',
  'couponWithBarcodeWithReceptParams': 'couponWithBarcodeWithReceptParams'
}

export const COUPON_KIND_TYPES_NAMES = {
  [COUPON_KIND_TYPES.eCoupon] : 'Электронный купон',
  [COUPON_KIND_TYPES.couponWithBarcode] : 'Печатный купон с ШК',
  [COUPON_KIND_TYPES.couponWithoutBarcode]: 'Печатный купон без ШК',
  [COUPON_KIND_TYPES.virtualRuleBarcode] : 'Каталог с ШК купонного правила',
  [COUPON_KIND_TYPES.importedCouponForPrint] : 'Загруженный набор купонов с ШК для печати',
  [COUPON_KIND_TYPES.sticker] : 'Купон-наклейка c ШК на позицию',
  [COUPON_KIND_TYPES.referralCouponWithBarcode]  : 'Печатный реферальный купон с ШК',
  [COUPON_KIND_TYPES.couponRuleBarcode] : 'Печатный купон с ШК купонного правила',
  [COUPON_KIND_TYPES.importedCoupon]: 'Загруженный набор напечатанных ШК',
  [COUPON_KIND_TYPES.couponWithBarcodeWithReceptParams] : 'Загруженный набор напечатанных ШК с параметрами приёма',

}

export const COUPON_ISSUING_TYPES = {

  'pos': 'pos',
  'public': 'public',
  'external': 'external',
  'manual': 'manual',
  'uniqueCouponGen': 'uniqueCouponGen',

}

export const COUPON_ISSUING_TYPES_NAMES = {
  [COUPON_ISSUING_TYPES.pos]: "Выдача на кассе",
  [COUPON_ISSUING_TYPES.public]: "Публикация в открытых источниках",
  [COUPON_ISSUING_TYPES.external]: "По запросу из внешней ИС",
  [COUPON_ISSUING_TYPES.manual]: "Ручной ввод",
  [COUPON_ISSUING_TYPES.uniqueCouponGen]: "Генерация уникальных купонов",
}



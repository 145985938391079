'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";

let routePath   = module.name + '.coupons.coupons.index';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )
            .url('/:clientId/:couponRuleId')

            .template( '<coupons-index-page [client-id]="clientId" [coupon-rule-id]="couponRuleId"></coupons-index-page>', true)
            .controller(['$scope', '$stateParams',
              function($scope, $stateParams) {
                $scope.clientId = $stateParams.clientId;
                $scope.couponRuleId = $stateParams.couponRuleId;
              } ])

            .permissions( {
              only: [ RoleType.Administrator, RoleType.MarketingManager, RoleType.GiftMarketingManager, RoleType.HeadOperator, RoleType.Operator, RoleType.Infocenter ],

            })
            .breadcrumbs( [ "Купоны", "Купоны " ]);

        }]);

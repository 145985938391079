import {Component, OnInit, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import {CashBoxService, CheckState} from "../../service/cash-box.service";

@Component({
  selector: 'cash-box',
  templateUrl: "./cash-box.component.html",
  styleUrls: ['./cash-box.component.scss'],
  providers: [
    CashBoxService
  ]
})
 export class CashBoxComponent implements OnInit, OnChanges, OnDestroy {

  public CheckState = CheckState;

  constructor(
      public cashBoxService : CashBoxService,
  )
  {
  }

  ngOnInit() {
    this.cashBoxService.createNewCheck();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    this.cashBoxService.destroy();
  }



}

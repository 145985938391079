<div class="padder m-t-sm">
  <div class="row">
    <div class="col padder">
      <search-input
        [model]="searchText"
        (modelChange)="onSearchChange($event)"
        [isPending]="isLoading"
        [isEnabled]="!isLoading"
        placeholder="Имя/Фамилия/Телефон/e-mail/№ Карты"
      ></search-input>
    </div>

    <div class="col-auto p-l-none"
         *ngIf="!!client"
    >
      <button class="btn btn-danger"
              data-testid="cashbox.client.remove"
              (click)="removeClient()"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>

<div
  class="cash-box-client_client-list padder mini-scroll"
  *ngIf="clientList && !isLoading"
>

  <ng-container
    *ngFor="let client of clientList">

    <div class="pointer d-flex"
        (click)="selectClient( client )"
         data-testid="cashbox.client.search-row"
    >

      <div class="m-r-sm"
           [ngSwitch]="client.client.state" >

        <i class="fa fa-circle text-danger"
           *ngSwitchCase="'archive'"

           [ngbPopover]="'В архиве'"></i>

        <i class="fa fa-circle text-primary"
           *ngSwitchCase="'autogeneration'"

           [ngbPopover]="'Автогенерация'"></i>

        <i class="fa fa-circle text-warning"
           *ngSwitchCase="'express'"

           [ngbPopover]="'Экспресс регистрация'"></i>

        <i class="fa fa-circle text-success"
           *ngSwitchCase="'active'"

           [ngbPopover]="'Активный клиент'"></i>

        <i class="fa fa-check-circle text-success-lter"
           *ngSwitchCase="'fullProfile'"

           [ngbPopover]="'Анкета заполнена'"></i>

      </div>

      <div class="w-100 d-flex justify-content-between">

        <div class="m-r-sm">

          <div class="clear ">
            <span class="text-primary m-r-sm"
                  *ngIf="!!client?.client?.surname"
                  [innerHtml]=" client.client.surname"></span>
            <span class="text-muted m-r-sm"
                  *ngIf="!!client?.client?.name"
                  [innerHtml]=" client.client.name"></span>
            <span class="text-muted m-r-sm"
                  *ngIf="!!client?.client?.patronymic"
                  [innerHtml]=" client.client.patronymic"></span>
          </div>

          <div class="clear ">
            <ng-container
              *ngIf="!!client?.cards?.length">
              <div class=" small"
                   *ngFor="let card of client.cards"
              >
                <i class="fa m-r-xs fa-credit-card"
                   [ngClass]=" { 'text-success ': card.state === 'active', 'text-danger ': card.state !== 'active' }"></i>
                <span class="text-primary" [innerHtml]=" card.number"></span>
              </div>
            </ng-container>

            <ng-container
              *ngIf="!client?.cards?.length">
              <div class=" small ">
                <i class="fa fa-warning text-danger m-r-xs"></i>
                <span class="text-muted">Карты отсутствуют</span>
              </div>
            </ng-container>

          </div>

        </div>


        <div class="   text-right">

          <div *ngIf="client.client.phone" class="text-muted text-ellipsis ">
            тел.
            <small class="font-bold " [innerHtml]="client.client.phone"></small>
          </div>

          <div *ngIf="client.client.email" class="text-muted text-ellipsis ">
            e-mail
            <small class="font-bold" [innerHtml]="client.client.email"></small>
          </div>
        </div>


      </div>

    </div>

    <div class="line line-dashed b-b line-lg w-100 m-t-xs m-b-xs"></div>

  </ng-container>

</div>


<div class="bg-light lter wrapper-sm padder-md b-b ng-scope" style="min-height:150px"
     *ngIf="!client && !isLoading"
>
  <div class="m-t-xl col-sm-12 text-center">
    <p class="text-muted text-2x" style="opacity:.3">
      <span>Клиент не выбран</span>
    </p>
  </div>
</div>


<div class=" bg-light lter wrapper-sm padder-md b-b"
     *ngIf="client && !isLoading"
>
  <div class="row">

    <div class="col-auto">
      <photo-loader
        [isActive]="false"
        [(model)]="client.client.systemParams.photoUrl"
        [size]="'5.5em'"
      ></photo-loader>
    </div>

    <div class="col cash-box-client_name-block">

      <div *ngIf="!!client?.client?.surname"
           [innerText]="client?.client?.surname || ''"></div>

      <div *ngIf="!!client?.client?.name"
           [innerText]="client?.client?.name || ''"></div>

      <div *ngIf="!!client?.client?.patronymic"
           [innerText]="client?.client?.patronymic || ''"></div>

    </div>

    <div class="col-auto text-center">

      <div class="col-lg-12 col-md-12 no-padder"
           *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
      >
        <div class="fa-2x text-success"
             [innerText]="account?.account?.balance || 0"
        ></div>
        <div class="h5">БАЛАНС</div>
      </div>

      <div class="col-lg-12 col-md-12 f-s-9 no-padder m-t-xxs"
          *ngIf="!!creditAccount"
      >
        Кредит: <span class="font-bold" [innerText]="creditAccount?.balance|| 0"></span>
      </div>

    </div>
  </div>

  <ngb-alert
    *ngIf="!canAddToCheck"
    [dismissible]="false"
    [animation]="true"
    [type]="'danger'"
    class="d-flex align-items-center"
  >
    <div>
      <i class="fa fa-2x fa-warning text-warning m-r-sm"></i>
    </div>

    <div class="w-100 text-center">
      Клиент не имеет карт и не может быть привязан к чеку
    </div>

  </ngb-alert>

  <ng-container
    *ngIf="!!cards?.length">

    <ng-container
      *ngFor="let card of cards"
    >
      <div class="line line-dashed b-b line-lg w-100 m-t-xs m-b-xs"></div>

      <div class="col-sm-12 ">
        <span class="text-muted">Карта:</span>
        <span class=" text-primary-dk pull-right">
            <i class="fa fa-credit-card m-r-xs "></i>
            <span [innerText]="card?.card?.number || ''"></span>
        </span>
      </div>
    </ng-container>
  </ng-container>


  <ng-container
    *ngIf="segment && segment?.name">

    <div class="line line-dashed b-b line-lg w-100 m-t-xs m-b-xs"></div>

    <div class="col-sm-12 ">
      <span class="text-muted">Сегмент:</span>
      <span class=" text-primary-dk pull-right">
          <span [innerText]="segment.name"></span>
      </span>
    </div>

  </ng-container>

  <ng-container
    *ngIf="CLV>=0">

    <div class="line line-dashed b-b line-lg w-100  m-t-xs m-b-xs"></div>

    <div class="col-sm-12 ">
      <span class="text-muted">CLV:</span>
      <span class=" text-primary-dk pull-right">
          <span [innerText]="CLV | loyaNumber"></span>
      </span>
    </div>
  </ng-container>

  <ng-container
    *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
  >

    <ng-container
      *ngIf="!!account?.account"
    >

      <div class="line line-dashed b-b line-lg w-100  m-t-xs m-b-xs"></div>

      <div class="col-sm-12 ">
        <span class="text-muted">Начисленные бонусы:</span>
        <span class=" text-primary-dk pull-right">
            <span [innerText]="account?.account?.awardAmount | loyaNumber:false"></span>
        </span>
      </div>

      <div class="line line-dashed b-b line-lg w-100  m-t-xs m-b-xs" ></div>

      <div class="col-sm-12 ">
        <span class="text-muted">Списанные бонусы:</span>
        <span class=" text-primary-dk pull-right">
            <span [innerText]="account?.account?.salesAmount | loyaNumber:false"></span>
        </span>
      </div>

    </ng-container>

  </ng-container>

  <ng-container
    *ngIf="discount >=0"
  >
    <div class="line line-dashed b-b line-lg w-100  m-t-xs m-b-xs"></div>

    <div class="col-sm-12">
      <span class="text-muted">Выдано скидок:</span>
      <span class=" text-primary-dk pull-right">
          <span [innerText]="discount | loyaCurrency"></span>
          <i class="fa " ngCurrencyLoya ></i>
      </span>
    </div>
  </ng-container>

</div>

<div class="cash-box-client_loading text-center wrapper-lg padder"
     *ngIf="isLoading"
>
  <i class="fa fa-spin fa-spinner text-warning fa-3x"></i>
</div>

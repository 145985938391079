import {CheckItemType} from "../types/check-item.type";
import {Subject} from "rxjs";
import {CashBoxEvents} from "../service/cash-box.service";

export enum CHECK_TYPES {
  CHECK = 1
}

export class CheckItemClass {

  private innerItem: CheckItemType;

  get id() {
    return this.innerItem?.id;
  }

  set id(id:string) {
    this.innerItem = this.innerItem || {};
    this.innerItem.id = id;
    this.innerItem.number = id;
  }

  get items(): any[]{
    return this.innerItem?.items || undefined;
  }

  set items(items:any[]) {
    this.innerItem = this.innerItem || {};
    this.innerItem.items = items;
  }

  set item(itemValue: any) {
    let items: any[] = this.items || [];
    items.push(itemValue);
    this.items = items;
  }

  set date(date: Date) {

    if (!date && this.innerItem) {
      this.innerItem.date = undefined;
      return;
    }

    if (date instanceof Date)
      this.innerItem.date =
          date.getFullYear() + '-' +
          ('0' + ( date.getMonth() + 1) ).slice(-2) + '-' +
          ('0' + date.getDate()       ).slice(-2) + ' ' +
          ('0' + date.getHours()      ).slice(-2) + ':' +
          ('0' + date.getMinutes()    ).slice(-2) + ':' +
          ('0' + date.getSeconds()    ).slice(-2);

    if ( typeof date ==='string')
      this.innerItem.date = date;

  }

  get date() {

    if (!this.innerItem?.date)
      return undefined;

    return this.innerItem.date;

  }

  get pos() {
    return this.innerItem?.pos || undefined;
  }

  get number() {
    return this.innerItem?.number || undefined;
  }

  get merchant() {
    return this.innerItem?.merchantId || undefined;
  }

  set merchant(id:string) {
    this.innerItem = this.innerItem || {};
    this.innerItem.merchantId = id;
  }

  get partner() {
    return this.innerItem?.partnerId || undefined;
  }

  set partner(id:string) {
    this.innerItem = this.innerItem || {};
    this.innerItem.partnerId = id.toString();
  }

  get results() {
    return this.innerItem?.results || undefined;
  }

  get usedAwards() {
    return this.innerItem?.usedAwards || undefined;
  }

  get cardNumber() {
    return this.innerItem?.cardNumber || undefined;
  }

  set cardNumber(cardNumber: string | number) {
    this.innerItem = this.innerItem || {};

    if (!cardNumber) {
      delete this.innerItem.cardNumber;
      delete this.innerItem.client;
    } else {
      this.innerItem.cardNumber = cardNumber.toString();
    }

  }

  get phoneNumber() {
    return this.innerItem?.cardNumber || undefined;
  }

  set phoneNumber(phoneNumber: string | number) {
    this.innerItem = this.innerItem || {};

    if (!phoneNumber) {
      delete this.innerItem.phoneNumber;
      delete this.innerItem.client;
    } else {
      this.innerItem.phoneNumber = phoneNumber.toString();
    }

  }

  get client() {
    return this.innerItem?.client || undefined;
  }

  set client(value: any) {
    this.innerItem.client = value;
  }

  get awardBonuses() {
    return !this?.innerItem?.awardBonus ? false : this?.innerItem?.awardBonus;
  };

  get gifts() {
    return this.innerItem?.awards?.giftList || undefined;
  }

  set gifts(newGifts: any) {
    this.innerItem.awards = this.innerItem?.awards || {};
    this.innerItem.awards.giftList = newGifts;
  }

  get usedGifts() {
    return this.innerItem?.usedAwards?.usedGiftList || undefined;
  }

  set usedGifts( newGifts: any ) {
    this.innerItem.usedAwards = this.innerItem?.usedAwards || {};
    if (!newGifts) {
      delete this.innerItem.usedAwards.usedGiftList;
    } else {
      this.innerItem.usedAwards.usedGiftList = newGifts;
    }

  }

  get coupons() {
    return this.innerItem?.awards?.coupons || undefined;
  }

  set coupons(newСoupons: any) {
    this.innerItem.awards = this.innerItem?.coupons || {};
    this.innerItem.awards.coupons = newСoupons;
  }

  get usedСoupons() {
    return this.innerItem?.usedAwards?.usedCoupons || undefined;
  }

  set usedСoupons( newCoupons: any ) {
    this.innerItem.usedAwards = this.innerItem?.usedAwards || {};
    this.innerItem.usedAwards.usedCoupons = newCoupons;
  }

  get actions() {
    return this.innerItem?.actions || undefined;
  }

  get total() {
    return this.innerItem?.total || 0;
  }

  get awards() {
    return this.innerItem?.awards || undefined;
  }

  constructor( rawItem:CheckItemType, private eventsSubject: Subject<any>) {
    this.parseItem(rawItem);
  }

  private parseItem(rawItem:CheckItemType) {
    this.innerItem = rawItem || {};
    this.setDefaults();

    this.createId();
    this.createItems();

    if (!this.date)
      this.date = new Date();
  }

  private createId() {
    if (!this.innerItem)
      return ;

    if (!!this.innerItem?.id)
      return;

    const block = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    this.innerItem.id = block() + block() + '-' + block() + '-' + block() + '-' +
      block() + '-' + block() + block() + block();

    this.innerItem.number = this.innerItem.id;
  }

  private createItems() {
    if (!this.innerItem)
      return ;

    if (!!Array.isArray(this.innerItem?.items))
      return;

    this.innerItem.items = [];
  }

  private setDefaults() {
    this.innerItem.pos = this.innerItem?.pos || '777';
    this.innerItem.chType = this.innerItem.chType || CHECK_TYPES.CHECK;
  }
/*

  private recalcGifts() {

    if (!this.innerItem?.awards?.giftList?.length)
      return;

    let giftIDs = [];

    that.$gifts = that.$gifts || {};

    angular.forEach(check.awards.giftList, function (campaignGift) {
      const id = `${campaignGift.campaignId},${campaignGift.listId}`;
      giftIDs.push(id);
      that.$gifts[id] = {
        'campaignId': campaignGift.campaignId,
        'listId': campaignGift.listId,
        'quantity': campaignGift.quantity,
        'count': 0,
        'skuList': {}
      };

      angular.forEach(campaignGift.skuList, function (giftItem) {

        that.$gifts[id].skuList[giftItem.sku] = new GiftItem(that.$gifts[id], giftItem, that);

      });

    });

    if (check.usedAwards && Array.isArray( check.usedAwards.usedGiftList) ) {
      check.usedAwards.usedGiftList = check.usedAwards.usedGiftList.filter( campaignGift => giftIDs.indexOf( `${campaignGift.campaignId},${campaignGift.listId}` )>=0 );
    }



  }
*/

  //-----------------------------------------------------------------

  public informing(id?:string) {

    if (!Array.isArray( this.innerItem.informing ) || !this.innerItem?.informing?.length )
      return undefined;

    return this.innerItem.informing.filter( item =>
       typeof id === "undefined" ? true : item?.communicationChannel?.toLowerCase()=== id?.toLowerCase()
    );

  }

  public async json() {

    this.recalc();

    return JSON.stringify(this.innerItem);
  }

  public async recalc() {

    this.innerItem?.items?.forEach(item => {
      item.total = item.price * item.quantity;
    });

    this.innerItem.total = this.innerItem.items
      .reduce( (acc, item) => acc + item.total, 0);

    return true;
  }

  public getCheck() {

    return this.innerItem;
  }

  public validate() {

    if (!this.innerItem ||
      ['id', 'total', 'date', 'number', 'pos', 'chType', 'merchantId', 'items']
        .some(item => typeof this.innerItem[item] === 'undefined') ||
      !Array.isArray(this.innerItem?.items) || !this.innerItem?.items?.length
    )
      return false;

    return !this.innerItem?.items.some(item =>
      ['id', 'sku', 'quantity', 'price', 'weight', 'total'].some(index => typeof item[index] === 'undefined')
    );

  }

  public async reset() {

    if (!this.innerItem)
      return;

    this.innerItem.items = [];

    ['client', 'pos', 'chType', 'phoneNumber', 'card', 'id', 'number', 'extensions', 'result', 'usedAwards', 'awards']
      .forEach( i => {
        if ( typeof this.innerItem[i] !== 'undefined' )
          delete this.innerItem[i];
      });

    this.parseItem(this.innerItem);

  }

}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CommunicationCampaignApiConfiguration as __Configuration } from '../communication-campaign-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InformingCampaignProtocol } from '../models/informing-campaign-protocol';
import { InformingCampaignQueryParam } from '../models/informing-campaign-query-param';
import { CommunicationStateGetResult } from '../models/communication-state-get-result';
import { InformingCampaignTargetInfo } from '../models/informing-campaign-target-info';
@Injectable({
  providedIn: 'root',
})
class CommunicationCampaignApiService extends __BaseService {
  static readonly queryPath = '/informingcampaign';
  static readonly createPath = '/informingcampaign';
  static readonly getPath = '/informingcampaign/{id}';
  static readonly updatePath = '/informingcampaign/{id}';
  static readonly deletePath = '/informingcampaign/{id}';
  static readonly getStatePath = '/informingcampaignstate/{id}';
  static readonly setStatePath = '/informingcampaignstate/{id}/{state}';
  static readonly targetGroupInfoPath = '/informingcampaigntargetinfo';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Запрос инфлрмационных кампаний
   * @param params Фильтры запроса
   * @return Данные коммуникаций
   */
  queryResponse(params?: InformingCampaignQueryParam): __Observable<__StrictHttpResponse<Array<InformingCampaignProtocol>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/informingcampaign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InformingCampaignProtocol>>;
      })
    );
  }
  /**
   * Запрос инфлрмационных кампаний
   * @param params Фильтры запроса
   * @return Данные коммуникаций
   */
  query(params?: InformingCampaignQueryParam): __Observable<Array<InformingCampaignProtocol>> {
    return this.queryResponse(params).pipe(
      __map(_r => _r.body as Array<InformingCampaignProtocol>)
    );
  }

  /**
   * Создать новую коммуникацию
   * @param campaignProtocol Параметры создаваемой коммуникации
   * @return Созданная коммуникация
   */
  createResponse(campaignProtocol: InformingCampaignProtocol): __Observable<__StrictHttpResponse<InformingCampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = campaignProtocol;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/informingcampaign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InformingCampaignProtocol>;
      })
    );
  }
  /**
   * Создать новую коммуникацию
   * @param campaignProtocol Параметры создаваемой коммуникации
   * @return Созданная коммуникация
   */
  create(campaignProtocol: InformingCampaignProtocol): __Observable<InformingCampaignProtocol> {
    return this.createResponse(campaignProtocol).pipe(
      __map(_r => _r.body as InformingCampaignProtocol)
    );
  }

  /**
   * Данные о коммуникации по ID
   * @param id ID коммуникации
   * @return Данные о коммуникации
   */
  getResponse(id: number): __Observable<__StrictHttpResponse<InformingCampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/informingcampaign/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InformingCampaignProtocol>;
      })
    );
  }
  /**
   * Данные о коммуникации по ID
   * @param id ID коммуникации
   * @return Данные о коммуникации
   */
  get(id: number): __Observable<InformingCampaignProtocol> {
    return this.getResponse(id).pipe(
      __map(_r => _r.body as InformingCampaignProtocol)
    );
  }

  /**
   * Обновление данных коммуникации
   * @param params The `CommunicationCampaignApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID коммуникации
   *
   * - `campaignProtocol`: Данные о коммуникации
   *
   * @return Данные коммуникации
   */
  updateResponse(params: CommunicationCampaignApiService.UpdateParams): __Observable<__StrictHttpResponse<InformingCampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.campaignProtocol;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/informingcampaign/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InformingCampaignProtocol>;
      })
    );
  }
  /**
   * Обновление данных коммуникации
   * @param params The `CommunicationCampaignApiService.UpdateParams` containing the following parameters:
   *
   * - `id`: ID коммуникации
   *
   * - `campaignProtocol`: Данные о коммуникации
   *
   * @return Данные коммуникации
   */
  update(params: CommunicationCampaignApiService.UpdateParams): __Observable<InformingCampaignProtocol> {
    return this.updateResponse(params).pipe(
      __map(_r => _r.body as InformingCampaignProtocol)
    );
  }

  /**
   * Удаление коммуникации по ID
   * @param id ID коммуникации
   */
  deleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/informingcampaign/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Удаление коммуникации по ID
   * @param id ID коммуникации
   */
  delete(id: number): __Observable<null> {
    return this.deleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Запросить статус коммуникации
   * @param id ID коммуникации
   * @return Данные о статусе коммуникации
   */
  getStateResponse(id: number): __Observable<__StrictHttpResponse<CommunicationStateGetResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/informingcampaignstate/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommunicationStateGetResult>;
      })
    );
  }
  /**
   * Запросить статус коммуникации
   * @param id ID коммуникации
   * @return Данные о статусе коммуникации
   */
  getState(id: number): __Observable<CommunicationStateGetResult> {
    return this.getStateResponse(id).pipe(
      __map(_r => _r.body as CommunicationStateGetResult)
    );
  }

  /**
   * Выставить коммуникации новый статус
   * @param params The `CommunicationCampaignApiService.SetStateParams` containing the following parameters:
   *
   * - `state`: Статус коммуникации
   *
   * - `id`: ID коммуникации
   *
   * @return Данные о коммуникации
   */
  setStateResponse(params: CommunicationCampaignApiService.SetStateParams): __Observable<__StrictHttpResponse<InformingCampaignProtocol>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/informingcampaignstate/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.state))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InformingCampaignProtocol>;
      })
    );
  }
  /**
   * Выставить коммуникации новый статус
   * @param params The `CommunicationCampaignApiService.SetStateParams` containing the following parameters:
   *
   * - `state`: Статус коммуникации
   *
   * - `id`: ID коммуникации
   *
   * @return Данные о коммуникации
   */
  setState(params: CommunicationCampaignApiService.SetStateParams): __Observable<InformingCampaignProtocol> {
    return this.setStateResponse(params).pipe(
      __map(_r => _r.body as InformingCampaignProtocol)
    );
  }

  /**
   * Запросить информацию по аудитории коммуникации
   * @param params The `CommunicationCampaignApiService.TargetGroupInfoParams` containing the following parameters:
   *
   * - `include.segmentId`: Сегмент, который входят в аудиторию коммуникации
   *
   * - `include.groupId`: Группа, которая входит в аудиторию коммуникации
   *
   * - `exclude.segmentId`: Сегмент, который не входит в аудиторию коммуникации
   *
   * - `exclude.groupIds`: Группа, которая не входит в аудиторию коммуникации
   *
   * @return Данные об аудитории коммуникации
   */
  targetGroupInfoResponse(params: CommunicationCampaignApiService.TargetGroupInfoParams): __Observable<__StrictHttpResponse<InformingCampaignTargetInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeSegmentId != null) __params = __params.set('include.segmentId', params.includeSegmentId.toString());
    if (params.includeGroupId != null) __params = __params.set('include.groupId', params.includeGroupId.toString());
    if (params.excludeSegmentId != null) __params = __params.set('exclude.segmentId', params.excludeSegmentId.toString());
    (params.excludeGroupIds || []).forEach(val => {if (val != null) __params = __params.append('exclude.groupIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/informingcampaigntargetinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InformingCampaignTargetInfo>;
      })
    );
  }
  /**
   * Запросить информацию по аудитории коммуникации
   * @param params The `CommunicationCampaignApiService.TargetGroupInfoParams` containing the following parameters:
   *
   * - `include.segmentId`: Сегмент, который входят в аудиторию коммуникации
   *
   * - `include.groupId`: Группа, которая входит в аудиторию коммуникации
   *
   * - `exclude.segmentId`: Сегмент, который не входит в аудиторию коммуникации
   *
   * - `exclude.groupIds`: Группа, которая не входит в аудиторию коммуникации
   *
   * @return Данные об аудитории коммуникации
   */
  targetGroupInfo(params: CommunicationCampaignApiService.TargetGroupInfoParams): __Observable<InformingCampaignTargetInfo> {
    return this.targetGroupInfoResponse(params).pipe(
      __map(_r => _r.body as InformingCampaignTargetInfo)
    );
  }
}

module CommunicationCampaignApiService {

  /**
   * Parameters for update
   */
  export interface UpdateParams {

    /**
     * ID коммуникации
     */
    id: number;

    /**
     * Данные о коммуникации
     */
    campaignProtocol: InformingCampaignProtocol;
  }

  /**
   * Parameters for setState
   */
  export interface SetStateParams {

    /**
     * Статус коммуникации
     */
    state: string;

    /**
     * ID коммуникации
     */
    id: number;
  }

  /**
   * Parameters for targetGroupInfo
   */
  export interface TargetGroupInfoParams {

    /**
     * Сегмент, который входят в аудиторию коммуникации
     */
    includeSegmentId?: number;

    /**
     * Группа, которая входит в аудиторию коммуникации
     */
    includeGroupId?: number;

    /**
     * Сегмент, который не входит в аудиторию коммуникации
     */
    excludeSegmentId?: number;

    /**
     * Группа, которая не входит в аудиторию коммуникации
     */
    excludeGroupIds?: Array<number>;
  }
}

export { CommunicationCampaignApiService }

import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {CashBoxEvents, CashBoxService} from "../../service/cash-box.service";
import {CheckItemClass} from "../../class/check-item.class";

@Component({
  selector: 'cash-box-check',
  templateUrl: "./cash-box-check.component.html",
  styleUrls: ['./cash-box-check.component.scss'],
  providers: [
  ]
})
 export class CashBoxCheckComponent
  implements OnInit, OnChanges, OnDestroy {

    public finalCheck: CheckItemClass | undefined;
    public totalResult = 0;
    public coupons;
    private cacheGroupVirtual = new WeakMap();

    constructor(
        public  cashBoxService : CashBoxService,
    )
    {
    }

    ngOnInit() {
      this.cashBoxService.events.subscribe({
        next: (data) => {

          if (data.type === CashBoxEvents.CHECK_CONFIRM_END) {
            this.finalCheck = data.value;
            this.parse();
          }

          if (data.type === CashBoxEvents.CHANGE_ITEMS) {
            this.clean();
          }

        }
      })
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    ngOnDestroy() {
    }

    parse() {

      this.totalResult = parseFloat(this.finalCheck?.total || 0);

      if (this.finalCheck.usedAwards) {

        if (this.finalCheck.usedAwards.usedBonusInCurrency) {
          this.totalResult -= parseFloat(this.finalCheck.usedAwards.usedBonusInCurrency);
        }

        if (this.finalCheck.usedAwards.usedDiscount) {
          this.totalResult -= parseFloat(this.finalCheck.usedAwards.usedDiscount);
        }

      }

      if (this.totalResult < 0)
        this.totalResult = 0;

      this.coupons = this.getAwardCouponsObj();

    }

  getAwardCouponsObj() {

    if ( !this.finalCheck?.awards?.coupons?.length ) {
      return {};
    }

    let returnObj = {};

    this.finalCheck?.awards?.coupons?.forEach(
      ( coupon : any ) => {

        let ckind = coupon.ckind;

        if (ckind === 'referralCouponWithBarcode') {
          ckind = 'couponWithBarcode';
        }

        if ( !Array.isArray( returnObj[ckind] ) ) {
          returnObj[ckind] = [];
        }

        returnObj[ckind].push( coupon );

    });

    return returnObj;

  };


  groupVirtualRule( inputArray ) {

    if ( this.cacheGroupVirtual.has(inputArray) ) {
      return this.cacheGroupVirtual.get(inputArray);
    }

    if ( !Array.isArray(inputArray) ) {
      return undefined;
    }

    let tmpFiltred = {};

    inputArray.forEach( rule => {

      if ( !tmpFiltred[ rule.couponRuleId ] ) {
        tmpFiltred[ rule.couponRuleId ] = Object.assign({}, rule);
        tmpFiltred[ rule.couponRuleId ].$count = 1;
      } else {
        tmpFiltred[ rule.couponRuleId ].$count++;
      }

    });

    this.cacheGroupVirtual.set(inputArray, tmpFiltred);
    return tmpFiltred;
  }

  clean() {
    this.finalCheck = undefined;
    this.totalResult = undefined;
    this.coupons = undefined;
    this.cacheGroupVirtual = new WeakMap();
  }

  reset() {
    this.clean();
    this.cashBoxService.reset(true)
  }
}

<responsive-table>
  <table class="table m-b-none b-b "
         infiniteScroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         [infiniteScrollContainer]="'#app-container'"
         [fromRoot]="true"
         (scrolled)="onScroll()"
  >

    <thead>
      <tr>
        <th>ID чека</th>

        <ng-container
          *loyaPermitIf="{ path:'global.clients', permitEnabled: ''}"
        >
          <th>Клиент</th>
        </ng-container>

        <th>Купонное правило</th>

        <th>Скидка</th>

        <ng-container
          *loyaPermitIf="{ path:'crm.accounts', permitEnabled: ''}"
        >
          <th>Начислено</th>
        </ng-container>

        <th>Точка продаж</th>

        <th>Статус операции</th>

      </tr>
    </thead>

    <tbody>

    <tr *ngFor="let transaction of transactions "
        data-testid="coupon.item in transactions"
    >

      <td class="text-wrap table-big-link" >

        <a  *ngIf="!!transaction?.transaction?.checkNumber"
            [uiSref]="'frontend.receipt.index({number: \'' + transaction?.transaction?.checkNumber + '\', id: \'' + transaction?.transaction?.checkId + '\'})'"
            data-testid="coupon.transaction.checkNumber"
        >{{transaction?.transaction?.checkNumber || ''}}</a>

        <span  *ngIf="!transaction?.transaction?.checkNumber"
               class="text-muted text-xs"
               data-testid="coupon.transaction.checkNumber.empty"
        >ID чека отсутствует</span>

        <div class="clearfix text-muted f-s-8"
             [innerText]="transaction?.transaction?.date | loyaDateTime"
             data-testid="coupon.transaction.date">
        </div>
      </td>

      <ng-container
        *loyaPermitIf="{ path:'global.clients', permitEnabled: ''}"
      >
        <td class="text-wrap">

          <div class="table-big-link">
            <a href="javascript:void(0)"
               [uiSref]="'frontend.crm.clients.view({id:' + transaction.transaction.clientId + '})'"
               data-testid="coupon.transaction.client"
            >{{ transaction.clientFio }}</a>
          </div>

        </td>
      </ng-container>

      <td class="text-wrap">

        <div class="table-big-link">
          <a href="javascript:void(0)"
             [uiSref]="'frontend.coupons.rules.view({id:' + transaction.transaction.couponRuleId + '})'"
             data-testid="coupon.transaction.couponRule"
          >{{transaction?.couponRuleName || ''}}</a>
        </div>


      </td>

      <td class="text-wrap"
          data-testid="coupon.transaction.discount">
        {{ transaction?.transaction?.discountAmount | loyaCurrency }}
      </td>

      <ng-container
        *loyaPermitIf="{ path:'crm.accounts', permitEnabled: ''}"
      >
        <td class="text-wrap"
            data-testid="coupon.transaction.bonus">

          {{ transaction?.transaction?.bonusAmount | loyaNumber:0 }}

        </td>
      </ng-container>

      <td class="text-wrap"
          data-testid="coupon.transaction.shop">
        {{ transaction?.locationName }}
      </td>

      <td class="text-wrap">

        <span [ngSwitch]="transaction?.transaction?.state"
              data-testid="coupon.transaction.state"
        >

            <span *ngSwitchCase="'created'">создана</span>
            <span *ngSwitchCase="'confirmed'">подтверждена</span>
            <span *ngSwitchCase="'cancel'">отмена</span>

        </span>

      </td>
    </tr>

    </tbody>
  </table>
</responsive-table>

<div class=" m-b-sm m-t-sm padder text-center "
     *ngIf="!transactions && !transactions?.length"
>
  <span class="text-muted f-s-12">Транзакций по купонам нет</span>
</div>

<div class="text-center text-warning m-t-lg"
     *ngIf="loader.isLoading('list')"
>
  <div>
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>


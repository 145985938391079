import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule} from "@angular/common/http";
import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {downgradeComponent} from "@angular/upgrade/static";
import {CashboxPageComponent} from "./cashbox-page.component";
import {CashBoxModule} from "../../shared/modules/cash-box/cash-box.module";

@NgModule({
  declarations: [
    CashboxPageComponent,
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    HttpClientModule,
    InfiniteScrollModule,
    CommonModule,
    NgbNavModule,
    NgbPopoverModule,
    CashBoxModule,
  ],
  providers: [],
  bootstrap: [],
  entryComponents:[]
})
export class CashboxPageModule {

  constructor() {}

}

angular.module('loya.builder')
  .directive('cashboxPage' , downgradeComponent({ component: CashboxPageComponent  }) )



<div class="fixed-tool-panel">

  <filter-checkbox
    class="m-t-xs m-r-sm"
    [name]         = "'Статусы'"
    [showClearButton] = "false"
    [canSelectAll] = "true"
    [inputValues]  = "COUPON_STATE_NAMES"
    [selectedValues] = "couponService.queryParams.getFilterValue('filter.states')"
    (selectedValuesChange) = "couponService.queryParams.setFilterValue(
        'filter.states',
        $event
    );
    reset();"
  ></filter-checkbox>

  <ng-container
    *loyaPermitIf="{path:'model.coupon', permitType: 'create'}"
  >
    <ng-container
      *loyaPermitIf="{ path:'coupons.rule.issuingType', permitEnabled: '',  permitInFilter:'manual' }">

        <button *ngIf="!!clientItem"
                class="btn btn-success btn-sm btn-addon pull-right"
                [uiSref]="'^.view({clientId: ' + clientItem?.client?.id + ' })'"

                data-testid="coupon.add()"
        >
            <i class="fa fa-plus"></i>
            <span class="btn-name hidden-xxs">Добавить купон</span>
        </button>

    </ng-container>
  </ng-container>

  <button *ngIf="!!clientItem"
          class="btn btn-success btn-sm btn-addon pull-right"
          [uiSref]="'frontend.crm.clients.view({id: ' + clientItem.client.id + ' })'"
          data-testid="coupon.toClient()">
    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">К клиенту</span>
  </button>

  <button *ngIf="couponRuleItem"
          class="btn btn-success btn-sm btn-addon pull-right"
          [uiSref]="'frontend.coupons.rules.view({id: ' + couponRuleItem.couponRule.id + '})'"
          data-testid="coupon.toRule()"
  >
    <i class="fa fa-arrow-left"></i>
    <span class="btn-name hidden-xxs">К купонному правилу</span>
  </button>

</div>

<div *ngIf="clientItem">
  <div class="row">

    <div class="col-lg-6 col-sm-12 pointer"
         [uiSref]="'frontend.crm.clients.view({id: ' + clientItem.client.id + '})'">
      <div class="row">
        <div class="col-lg-6 m-b m-t-sm text-center clearfix">
          <div class="inline text-center ">

            <div class="thumb-lg avatar"
                 style="margin:1px 0px 0 0">

              <div style="border-radius: 500px; width:95px; height:95px; overflow: hidden; margin-top: 4px; ">
                <img *ngIf="clientItem?.client?.systemParams?.photoUrl"
                     [src]=" clientItem.client.systemParams.photoUrl"
                     alt="..."
                     style="margin-top: -11px; width: 100%;">
                <img *ngIf="!clientItem?.client?.systemParams?.photoUrl"
                     src='/assets/img/user-default.jpg'
                     style="width:95px; height:95px">
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-6 m-t-sm text-center-xs text-center-sm text-center-md text-left padder">

          <a href="javascript:void(0)" class="h4 block clearfix  m-t-xs m-b-xs"
             [uiSref]="'frontend.crm.clients.view({id: ' + clientItem.client.id + '} )'"
             *ngIf="clientItem.client.surname">
            {{ clientItem.client.surname }}
          </a>

          <a href class="h4 block clearfix  m-t-xs m-b-xs"
             [uiSref]="'frontend.crm.clients.view({id: ' + clientItem.client.id + '} )' "
             *ngIf="clientItem.client.name">
            {{ clientItem.client.name }}
          </a>

          <a href class="h4 block clearfix  m-t-xs m-b-xs"
             [uiSref]="'frontend.crm.clients.view({id: ' + clientItem.client.id + '})'"
             *ngIf="clientItem.client.patronymic">
            {{ clientItem.client.patronymic }}
          </a>

          <div class="m-b-xs f-s-9"
               *ngIf="clientItem.client.phone">
            <i class=" fa fa-mobile m-r-xs text-muted">
            </i>
            {{ clientItem.client.phone }}
          </div>

          <div class="m-b-xs f-s-9"
               *ngIf="clientItem.client.email">
            <i class="fa fa-envelope-o  m-r-xs text-muted">
            </i>
            {{ clientItem.client.email }}
          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="couponRuleItem"
     class="padder m-t m-b-xs">

  <div class="row">

    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="w inline v-top text-right text-muted p-r-xs">

        Купонное правило:
      </div>
      <div class="inline">
        <a class="clearfix overflow-wrap-break"
           [innerText]="couponRuleItem.couponRule.name || ''"
           data-testid="coupon.rule.name"
           [uiSref]="'^.^.rules.view({id: ' + couponRuleItem.couponRule.id + '})' "></a>
        <span class="clearfix text-muted f-s-9 overflow-wrap-break"
              [innerText]="couponRuleItem.couponRule.description"
              data-testid="coupon.rule.description"></span>

      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="w inline v-top text-right text-muted p-r-xs">
        Тип выдачи купонов:
      </div>
      <div class="inline">
                <span  [innerText]="COUPON_ISSUING_TYPES_NAMES[couponRuleItem.couponRule.issueSettings.issuingType] || ''" data-testid="coupon.rule.issuingType"></span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6">
      <div class="w inline v-top text-right text-muted p-r-xs">
        Тип купона:
      </div>
      <div class="inline">
                <span [innerText]="COUPON_KIND_TYPES_NAMES[couponRuleItem.couponRule.issueSettings.couponKind]" data-testid="coupon.rule.couponType"></span>
      </div>
    </div>

  </div>


</div>


  <div class="wrapper stick-search-box">
    <search-input
      [model]="searchString"
      (modelChange)="onSearchChange($event)"
      placeholder="Поиск купона"
    ></search-input>
  </div>

 <responsive-table>
   <table
     class="table b-b "
     infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     [infiniteScrollContainer]="'#app-container'"
     [fromRoot]="true"
     (scrolled)="onScroll()"
    >
     <thead>
       <tr>

         <th >
           <sort-view
             column="barcode"
             [params]="couponService.queryParams"
             (onChange)="reset()"
           >
             Номер купона
           </sort-view>
         </th>

         <th>
            Наименование купонного правила
         </th>

         <th>
           <sort-view
             column="couponKind"
             [params]="couponService.queryParams"
             (onChange)="reset()"
           >
             Тип купона
           </sort-view>
         </th>

         <th>
           <sort-view
             column="state"
             [params]="couponService.queryParams"
             (onChange)="reset()"
           >
             Статус
           </sort-view>
         </th>

         <th
           *loyaPermitIf="{ path:'global.clients', permitEnabled: ''}"
         >
            <span>
              ФИО клиента
            </span>
         </th>

       </tr>
     </thead>
      <tbody>
        <ng-container
          *ngFor="let coupon of listCoupons"
        >
          <tr    data-testid="coupon in coupons">
            <td class="text-wrap table-big-link">
                <a href="javascript:void(0)"
                   [uiSref]="'^.view({id:' + coupon.id + '})'"
                   data-testid="coupon.barcode">{{ coupon?.barcode || '' }}</a>
            </td>

            <td class=" text-wrap table-big-link overflow-wrap-break">
                <a href="javascript:void(0)"
                   [uiSref]="'frontend.coupons.rules.view({id: ' + coupon.couponRuleId + '})'"
                   data-testid="coupon.couponRule"
                >{{coupon.couponRuleName }}</a>
            </td>

            <td [uiSref]="'^.view({id: ' + coupon.id + '})'"
                class="pointer"
                data-testid="coupon.type">
              {{  COUPON_KIND_TYPES_NAMES[coupon.couponKind] || ''}}
            </td>

            <td  [uiSref]="'^.view({id:' + coupon.id + '})'"
                 class="pointer"
            >
              <span class="clearfix"
                    data-testid="coupon.state">
                {{  COUPON_STATE_NAMES[coupon.state] }}
              </span>

              <div *ngIf="coupon.state==='manualDeleted' && coupon.removingReason" class="clearfix m-l-xs ">
                      <span class="pull-left m-r-sm text-danger-dker"><i
                        class="fa fa-warning text-danger-dker m-t-xxs m-b-n-xxs "></i> Причина:</span>
                <span class="text-muted"
                      [innerText]="coupon.removingReason"
                      data-testid="coupon.removingReason"
                ></span>
              </div>

            </td>

            <td
              *loyaPermitIf="{ path:'global.clients', permitEnabled: ''}"
            >
              <div class="table-big-link">
                <a href="javascript:void(0)"
                   [uiSref]="'frontend.crm.clients.view({id:' + coupon.clientId + '})'"
                   data-testid="coupon.client"
                >{{coupon.clientFIO }}</a>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
   </table>
 </responsive-table>

<div class="text-center text-warning m-t-lg"
     *ngIf="loader.isLoading('list')"
>
  <div class="">
    <i class="fa fa-3x fa-spin fa-spinner"></i>
  </div>

</div>

<scroll-up></scroll-up>

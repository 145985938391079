<ng-select [(ngModel)]="model"
           [items]="filteredItems"
           [virtualScroll]="true"
           [loading]="loading"
           bindValue="client.id"
           name="selectClientComponent"
           [placeholder]="placeholder"
           (scroll)="onScroll($event)"
           (scrollToEnd)="onScrollToEnd()"
           (search) = "onSearch($event)"
           (change) = "onChange()"
           (clear) = "clear()"
           (close) = "clear()"
           [disabled]="disabled"
           [required]="required"
           [class.ng-invalid]="!isValid || (isEmpty && required)"
           [searchFn]="searchFunction"
>

  <ng-template ng-label-tmp let-item="item">

    {{ item?.client?.name || '' }}
    {{ item?.client?.surname || '' }}
    {{ item?.client?.patronymic || ''}}

  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index">

     <span>
       {{ item?.client?.name || '' }}
       {{ item?.client?.surname || '' }}
       {{ item?.client?.patronymic || ''}}
     </span>

  </ng-template>

</ng-select>

<input type="hidden" [required]="required" [name]="'selectClientDummy'" [(ngModel)]="model">

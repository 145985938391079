import {Component, Input, OnInit, Output, EventEmitter, OnDestroy, HostListener, Inject} from "@angular/core";
import './cashbox-page.legacy';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'cashbox-page',
  templateUrl: './cashbox-page.component.html',
  styles: [`
    .static_container {
      width: 100%;
      position: relative;
    }
    .static_container_inner {
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: auto;
      z-index: 1;
    }
  `],
  providers: [
  ]
})
export class CashboxPageComponent implements OnInit, OnDestroy{

  height= '100%';

  appContentBody : any;
  appHeader : any;
  appFooter : any;

  constructor(
    @Inject(DOCUMENT) document: Document
  ) {
  }

  ngOnInit() {

    this.appHeader = document.querySelector('.app-header');
    this.appFooter = document.querySelector('.app-footer');

    this.onResize();

  }

  ngOnDestroy() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(){

    if (this.appContentBody)
      return;

    this.height =
      document?.body?.clientHeight - this?.appHeader?.clientHeight - this?.appFooter?.clientHeight <0 ? '100%' :
        (document?.body?.clientHeight - this?.appHeader?.clientHeight - this?.appFooter?.clientHeight) + 'px';

  }

}

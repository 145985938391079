import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from "@angular/core";
import {LoaderHandlerClass} from "../../../../shared/class/loader-handler.class";

import "./communications-index-page.legacy";
import {CommunicationCampaignService} from "../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.service";
import {
  COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES, COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES,
  COMMUNICATION_CAMPAIGN_STATE_NAMES, COMMUNICATION_CAMPAIGN_STATES,
  COMMUNICATION_CAMPAIGN_STATES_COLORS
} from "../../../../shared/service/api/CommunicationCampaign/CommunicationCampaign.values";
import {Subscription} from "rxjs";


let listFilters;

@Component({
  selector: 'communications-index-page',
  templateUrl: './communications-index-page.component.html',
  styleUrls: [
    './communications-index-page.component.scss'
  ],
  providers: [
    CommunicationCampaignService
  ]
})
export class CommunicationsIndexPageComponent implements OnInit, OnDestroy{

  @Input() search;

  public loader = new LoaderHandlerClass();

  public communicationsList = [];

  public searchString;

  public COMMUNICATION_CAMPAIGN_STATES_COLORS = COMMUNICATION_CAMPAIGN_STATES_COLORS;
  public COMMUNICATION_CAMPAIGN_STATE_NAMES = COMMUNICATION_CAMPAIGN_STATE_NAMES;
  public COMMUNICATION_CAMPAIGN_STATE_NAMES_FILTER = Object.assign({}, COMMUNICATION_CAMPAIGN_STATE_NAMES);
  public COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES = COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES;
  public COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES = COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES;

  public isPreview = false;
  public selectedCommunication;

  public queryList: Subscription;

  constructor(
    public communicationCampaignService: CommunicationCampaignService
  ) {
  }

  ngOnInit() {

    delete this.COMMUNICATION_CAMPAIGN_STATE_NAMES_FILTER[COMMUNICATION_CAMPAIGN_STATES.unsuspend];
    this.communicationCampaignService.queryParams.setFilterValue('states',
      Object.values(COMMUNICATION_CAMPAIGN_STATES).filter( i =>
        i !== COMMUNICATION_CAMPAIGN_STATES.archive && i !== COMMUNICATION_CAMPAIGN_STATES.completed && i !== COMMUNICATION_CAMPAIGN_STATES.unsuspend
      )
    );
    this.loadFilters();
    this.communicationCampaignService.queryParams.onParamChange.subscribe(value => {
      listFilters = this.communicationCampaignService.queryParams.params;
    })

    this.communicationCampaignService.queryParams.reset()
    this.getList(this.communicationCampaignService.queryParams.params);
  }

  loadFilters() {
    if (!listFilters)
      return;

    if (listFilters?.filter) {
      ['states','fromDate','tillDate', 'datesKind']
        .filter(key => !!listFilters?.filter?.[key])
        .forEach(key => {
          this.communicationCampaignService.queryParams.setFilterValue(key, listFilters?.filter[key]);
        });
    }

    if (!!listFilters?.search) {
      this.communicationCampaignService.queryParams.search(listFilters.search);
      this.searchString = listFilters.search;
    }

/*

    if (!!listFilters?.pager) {
      this.communicationCampaignService.queryParams.setPager(( listFilters?.pager?.drop || 0 ) + ( listFilters?.pager?.limit || 100 ));
    }
*/

  }

  getList(params) {
    if (this.queryList?.unsubscribe) {
      this.queryList?.unsubscribe()
    }

    this.queryList = <any>this.communicationCampaignService.query$(params)
      .pipe(
        this.loader.waitLoading('list')
      )
      .subscribe( result => {
        this.communicationsList = this.communicationsList.concat(result);
      });
  }

  onScroll() {

    if ( this.loader.isLoading('list') ) {
      return ;
    }

    this.communicationCampaignService.queryParams.next();
    this.getList(this.communicationCampaignService.queryParams.params)
  }

  reset() {
    this.communicationCampaignService.queryParams.reset();
    this.communicationsList=[];
    this.getList(this.communicationCampaignService.queryParams.params);
  }

  onSearchChange(value) {
    this.searchString = value;
    this.communicationCampaignService.queryParams.search(this.searchString);
    this.reset();
  }

  changeFilter( $event ) {
    this.communicationCampaignService.queryParams.setFilterValue( $event.target.name, $event.target.value);
    this.reset();
  }

  getCompletenessMessage(state, completeness) {
    if (completeness === "finished")
      return "";

    return ( COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES[state] && COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES[state][completeness] ) ? COMMUNICATION_CAMPAIGN_COMPLETENESS_VALUES_STATES[state][completeness] : '';
  };

  onFilterGoods($event) {

    this.communicationCampaignService.queryParams.setFilterValue('filter.skuList', undefined);
    this.communicationCampaignService.queryParams.setFilterValue('filter.categoryList', undefined);
    this.communicationCampaignService.queryParams.setFilterValue('filter.groupsList', undefined);

    this.communicationCampaignService.queryParams.setFilterValue('filter.partnerId', $event?.list?.[0]?.partnerId || undefined );

    switch($event?.type) {
      case 'sku':
        this.communicationCampaignService.queryParams.setFilterValue('filter.skuList', $event?.list?.map(i => i.id) || undefined );
        break;
      case 'category':
        this.communicationCampaignService.queryParams.setFilterValue('filter.categoryList', $event?.list?.map(i => i.id) || undefined );
        break;
      case 'custom':
        this.communicationCampaignService.queryParams.setFilterValue('filter.groupsList', $event?.list?.map(i => i.id) || undefined);
         break;
    }

    this.reset();
  }

  onFilterLocations($event) {
    this.communicationCampaignService.queryParams.setFilterValue('filter.locations', $event || undefined);
    this.reset();
  }

  onFilterDate($event) {

    if (!$event?.datesKind) {
      let [from, to, datesKind] = [
        this.communicationCampaignService.queryParams.getFilterValue('fromDate'),
        this.communicationCampaignService.queryParams.getFilterValue('tillDate'),
        this.communicationCampaignService.queryParams.getFilterValue('datesKind')
      ]
      this.communicationCampaignService.queryParams.setFilterValue('fromDate', undefined);
      this.communicationCampaignService.queryParams.setFilterValue('tillDate', undefined);
      this.communicationCampaignService.queryParams.setFilterValue('datesKind', undefined);
      if (!!from || !!to || !!datesKind )
        this.reset();
      return
    }

    this.communicationCampaignService.queryParams.setFilterValue('datesKind', $event.datesKind);
    $event.from && this.communicationCampaignService.queryParams.setFilterValue('fromDate', $event.from + 'T00:00:00.000Z');
    $event.to && this.communicationCampaignService.queryParams.setFilterValue('tillDate', $event.to  + 'T00:00:00.000Z');

    if (!!$event.from || !!$event.to )
      this.reset();
  }

  changePreview() {

    this.isPreview = !this.isPreview;
    if ( !this.selectedCommunication ) {
      this.selectedCommunication = this.communicationsList?.[0];
    }

  }

  ngOnDestroy() {
    listFilters = this.communicationCampaignService.queryParams.params;
  }
}



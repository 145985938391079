<form-container
  *ngIf="!!coupon"
>

  <div [form-item-row] = "'Наименование купона'"
       form-item-row-input="true"
  >
    <input
      type="text"
      class="form-control form-control-edittable"
      [(ngModel)]="coupon.name"
      data-testid="coupon.input.name"
      name="couponItem_couponRule_name"
      placeholder="Введите наименование купона"
      [required]="true"
      maxlength="250"
    >
  </div>

  <div [form-item-row] = "'Описание купона'"
       form-item-row-input="true"
  >

     <textarea [ckeditor]="ckeOptions.optionsDescription"
               class="form-control"
               [(ngModel)]="coupon.description"
               data-testid="coupon.input.description"
               [required]="true"
               [maxlength]="500"
               name="ruleItem_couponRule_issueSettings_couponDescription"
               [customValidator]="customCkeValidator"></textarea>

    <div class="clearfix">
      <div class="f-s-8 pull-right d-none d-sm-block m-t-xs">
        <a (click)="settingsObj.showPreview = !settingsObj.showPreview "
           data-testid="cRule.coupon.preview()"
        >
          <i class="fa fa-eye"></i> Предпросмотр
          <i class="fa" [ngClass]="{'fa-angle-up': settingsObj.showPreview, 'fa-angle-down': !settingsObj.showPreview }"></i>
        </a>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 d-none d-sm-block no-padder"
         *ngIf="settingsObj.showPreview"
    >
      <pos-printer-template
        [message]=" coupon?.description || '' "
      ></pos-printer-template>
    </div>


  </div>

  <div [form-item-row] = "'Купонное правило'"
       form-item-row-input="true"
  >
    <input type="hidden"
           [(ngModel)]="coupon.couponRuleId"
           name="coupon_couponRuleId_dummy"
           [required]="true">

    <ng-select [(ngModel)]="coupon.couponRuleId"
               [items]="couponRuleList"
               bindLabel="name"
               bindValue="id"
               data-testid="coupon.select.couponRule"
               name="couponRuleItem_couponType"
               [class.ng-invalid]="!coupon?.couponRuleId"
               [searchable]="false"
               [required]="true"
               [clearable]="false"

               class="w-100  col-auto m-r-sm no-padder"

    >

      <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.name}}
      </ng-template>

    </ng-select>

    <span class="text-danger"
          *ngIf="!couponRuleList || couponRuleList?.length === 0" data-testid="coupon.couponRule.warning.manual">
          Требуются активные купонные правила с типом выдачи купонов "Ручной ввод"
      </span>

    <span class="text-danger"
          *ngIf="isRuleDateExpired"
          data-testid="coupon.couponRule.warning.date"
    >
        Для купонного правила истекла дата окончания выдачи купонов ( {{ couponRuleItem.couponRule.issueSettings.issueUntil | loyaDate }} )
      <input type="hidden" [(ngModel)]="dummy" [required]="true" />
    </span>

  </div>

  <div
      *ngIf="!clientItem && !hasClient"
      [form-item-row] = "'Выбор клиента'"
      form-item-row-input="true"
  >
    <select-client
      [(model)]="coupon.clientId"
      [required]="true"
    ></select-client>
  </div>

</form-container>

/* tslint:disable */
type PosTypeChannel =
  'pos' |
  'sco' |
  'both';
module PosTypeChannel {
  export const POS: PosTypeChannel = 'pos';
  export const SCO: PosTypeChannel = 'sco';
  export const BOTH: PosTypeChannel = 'both';
  export function values(): PosTypeChannel[] {
    return [
      POS,
      SCO,
      BOTH
    ];
  }
}

export { PosTypeChannel }
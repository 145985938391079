import {Injectable} from "@angular/core";
import {FileUploadApiService} from "../../../../api/FileUploadApi/services";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: "root"
})
export class FileUploaderService {

  constructor(
    private fileUploadApiService: FileUploadApiService,
    private http: HttpClient,
    ) {
  }

  public uploadFile$( accept= '', postOptions:any = {} ) {

    let removeInput = () => {

      const finded = document.body.querySelector('#fileSelectInput');
      if ( finded ) {
        document.body.removeChild(finded)
      }

    }

    return new Observable( (sub) => {

      removeInput();

      let inputElem = document.createElement('input');
      inputElem.setAttribute("type", "file");
      inputElem.setAttribute("name", "fileUploadDummy");
      inputElem.setAttribute("id", "fileSelectInput");

      if (!!accept) {
        inputElem.setAttribute("accept", accept.toString() );
      }

      inputElem.onchange = ( e: any ) => {
        let file = !!e?.target?.files ? e.target?.files[0] : undefined;

        inputElem.onchange = undefined;
        inputElem = undefined;

        removeInput();

        if (!file) {
          sub.error(false);
          return;
        }

        const formData = new FormData();
        formData.append("file", file, file.name);

        return this.http.post(
            '/files',
            formData,
            <any>Object.assign(
              {
                responseType: 'text',
                }, postOptions
            )
        )
          .subscribe(
            {
              complete: sub.complete.bind(sub),
              error: sub.error.bind(sub),
              next: sub.next.bind(sub),
            }
          )
      }

      inputElem.onerror = ( e: any ) =>  {removeInput()};
      inputElem.onabort = ( e: any ) =>  {removeInput()};
      inputElem.oncancel = ( e: any ) =>  {
        removeInput();
        sub.error(false);
      }

      document.body.appendChild(inputElem);

      inputElem.click();

    })
  }

  public previewFile$(filename :string, params: FileUploadApiService.PreviewParams["params"]) {
      return this.fileUploadApiService.preview({
        filename,
        params
      })
  }

  public parseFile$(filename :string, params: FileUploadApiService.UploadParams["params"]) {
    return  this.fileUploadApiService.upload({
      filename,
      params
    })
  }

}

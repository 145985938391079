
<form-container
  *ngIf="!!couponRuleItem"
>

  <div [form-item-row] = "'Название'"
       form-item-row-input="true"
  >
    <input
      type="text"
      class="form-control form-control-edittable"
      [(ngModel)]="couponRuleItem.couponRule.name"
      data-testid="cRule.input.name"
      name="ruleItem_couponRule_name"
      placeholder="Введите название правила"
      [required]="true"
      maxlength="250"
    >
  </div>

  <div [form-item-row] = "'Описание'"
       form-item-row-input="true"
  >
     <textarea
       class="form-control form-control-edittable"
       [(ngModel)]="couponRuleItem.couponRule.description"
       name="ruleItem_couponRule_description"
       data-testid="cRule.input.description"
       placeholder="Описание правила"
       [required]="true"
       maxlength="500"
     > </textarea>
  </div>

  <div [form-item-row] = "'Группа кампании'"
       form-item-row-input="true"
  >
    <select-campaign-category
      [(id)]="couponRuleItem.couponRule.categoryId"
      data-testid="cRule.select.campaignGroup"
      [required]="true"
    ></select-campaign-category>
  </div>

  <div [form-item-row] = "'Тип выдачи купонов'"
       form-item-row-input="true"
  >
    <ng-select [(ngModel)]="couponRuleItem.couponRule.issueSettings.issuingType"
               [items]="ISSUING_TYPE_LIST"
               bindLabel="name"
               bindValue="key"
               data-testid="cRule.select.issuingType"
               name="couponRuleItem_issuingType"
               (change) = "onChangeIssuingType($event)"
               [class.ng-invalid]="!couponRuleItem?.couponRule?.issueSettings?.issuingType"
               [searchable]="false"
               [required]="true"
               [clearable]="false"
               class="w-100  col-auto m-r-sm no-padder"

    >

      <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.name}}
      </ng-template>

    </ng-select>
  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI2">Купон</span>
  </div>

  <div [form-item-row] = "'Наименование купона'"
       form-item-row-input="true"
  >
    <input
      type="text"
      class="form-control form-control-edittable"
      [(ngModel)]="couponRuleItem.couponRule.issueSettings.couponName"
      name="ruleItem_couponRule_issueSettings_couponName"
      data-testid="cRule.coupon.input.name"
      placeholder="Введите наименование купона"
      [required]="true"
      maxlength="250"
    >
  </div>

  <div [form-item-row] = "'Описание купона'"
       form-item-row-input="true"
  >
    <textarea [ckeditor]="ckeOptions.optionsDescription"
              class="form-control"
              [(ngModel)]="couponRuleItem.couponRule.issueSettings.couponDescription"
              #inputDescriptionControl="ngModel"
              data-testid="cRule.coupon.input.description"
              [required]="true"
              name="ruleItem_couponRule_issueSettings_couponDescription"
              [customValidator]="ckeOptions.maxDescriptionValidator"></textarea>

    <div class="row m-l-none m-r-none">

      <div class="col">

        <div class="f-s-8 m-t-xs">
          <span class="text-muted m-r-xxs">Количество символов:</span>
          <span [innerText]="ckeOptions.getCountChars(couponRuleItem.couponRule.issueSettings.couponDescription) || 0" data-testid="cRule.coupon.description.charsCount"></span>
        </div>

        <div class="f-s-9 clearfix text-danger m-t-xs m-b-xxs"
             *ngIf="!!inputDescriptionControl?.errors?.required" data-testid="cRule.coupon.warning">
          <i class="fa fa-warning m-r-xs"></i>Поле обязательно для ввода
        </div>

        <div class="f-s-9 clearfix text-danger m-t-xs m-b-xxs"
             *ngIf="!!inputDescriptionControl?.errors?.customValidator" data-testid="cRule.coupon.description.warning">
          <i class="fa fa-warning m-r-xs"></i>Превышен допустимый лимит в 2000
          символов
        </div>
      </div>

      <div class="col-auto text-right p-r-none">
        <div class="f-s-8 m-t-xs  d-none d-sm-block">
          <a (click)="settingsObj.showPreview = !settingsObj.showPreview "
             data-testid="cRule.coupon.preview()"
          >
            <i class="fa fa-eye text-success"></i> Предпросмотр
            <i class="fa" [ngClass]="{'fa-angle-up': settingsObj.showPreview, 'fa-angle-down': !settingsObj.showPreview }"></i>
          </a>
        </div>
      </div>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 d-none d-sm-block no-padder"
         *ngIf="settingsObj.showPreview"
    >
      <pos-printer-template
        [message]=" couponRuleItem?.couponRule?.issueSettings?.couponDescription || '' "
      ></pos-printer-template>
    </div>


  </div>

  <div [form-item-row] = "'Тип выдачи купонов'"
       form-item-row-input="true"
  >
    <ng-select [(ngModel)]="couponRuleItem.couponRule.issueSettings.couponKind"
               [items]="COUPON_KIND_LIST"
               bindLabel="name"
               bindValue="key"
               data-testid="cRule.coupon.select.couponType"
               name="couponRuleItem_couponType"
               (change) = "onChangeCouponKind($event)"
               [class.ng-invalid]="!couponRuleItem?.couponRule?.issueSettings?.couponKind"
               [searchable]="false"
               [required]="true"
               [clearable]="false"

               class="w-100  col-auto m-r-sm no-padder"

    >

      <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{item.name}}
      </ng-template>

    </ng-select>
  </div>

  <div
    *ngIf="currentCouponKind?.hasPrefix"
      [form-item-row] = "'Префикс'"
      form-item-row-input="true"
  >

    <div class="input-group w-sm">

      <div class="input-group-btn dropdown"
           *ngIf="!settingsObj?.prefix?.checkInProgress"
           ngbDropdown
      >
        <button type="button"
                class="btn btn-default"
                ngbDropdownToggle
                aria-haspopup="true"
                aria-expanded="false"
                data-testid="cRule.coupon.prefix.head"
        >
          <span [innerText]="settingsObj?.prefix?.head"></span>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu w-xxs"
            ngbDropdownMenu
            data-testid="cRule.coupon.prefix.menu"
        >
          <li ngbDropdownItem class="no-padder">
            <a href="javascript:void(0)"
               (click)="settingsObj.prefix.head = '2'; !!settingsObj?.prefix?.tmp && checkCurrentPrefix(settingsObj?.prefix?.head + settingsObj?.prefix?.tmp)"
            >2</a>
          </li>
          <li ngbDropdownItem class="no-padder">
            <a href="javascript:void(0)"
               (click)="settingsObj.prefix.head = '99'; !!settingsObj?.prefix?.tmp && checkCurrentPrefix(settingsObj?.prefix?.head + settingsObj?.prefix?.tmp)"
            >99</a>
          </li>
        </ul>
      </div>

      <span class="input-group-addon"
            *ngIf="!!settingsObj?.prefix?.checkInProgress">

        <span class="clearfix"><i
          class="fa fa-spin fa-spinner text-danger-lt"></i>
        </span>
      </span>

      <input
        type="text"
        class="form-control form-control-edittable m-b-xs text-right"
        [ngStyle]="prefixPad(settingsObj?.prefix) ? { 'border-right':'none', 'padding-right': '0px' } : {}"
        [ngModel]="settingsObj.prefix.tmp"
        (ngModelChange)="settingsObj.prefix.tmp = $event; checkCurrentPrefix(settingsObj?.prefix?.head + settingsObj?.prefix?.tmp)"
        data-testid="cRule.coupon.input.prefix"
        id="prefix_tmp"
        [ngClass]="{ 'b-danger b-b b-t b-l b-r' : ( !!prefixCouponRule ) }"
        name="ruleItem_couponRule_prefix"
        [required]="true"
        pattern="^[0-9]+$"
        (change)="checkCurrentPrefix(settingsObj?.prefix?.head + settingsObj?.prefix?.tmp)"
        [maxlength]="maxLenPrefix"
      />
      <span class="input-group-addon bg-white text-left"
            *ngIf="prefixPad(settingsObj?.prefix)"
            style="padding-left: 3px;"
            (mousedown)="setActivePrefix()"
      >
          <span class="text-muted"
                [innerText]="prefixPad(settingsObj?.prefix)"></span>
      </span>

    </div>

    <div class="text-muted f-s-8">
      <span class="clearfix">Префикс купона - начинается на {{settingsObj?.prefix?.head}}</span>
    </div>

    <span class="clearfix f-s-9 text-danger"
          *ngIf="!!prefixCouponRule " data-testid="cRule.prefix.warning">

        <input
          type="hidden"
          class="form-control form-control-edittable "
          name="settingsObj_prefixDummy"
          [(ngModel)]="settingsObj.prefixDummy" [required]="true" />

        Внимание! Префикс уже задан для купонного правила
        <a  href="javascript:void(0)" [uiSref]="'^.view({id:' + prefixCouponRule?.id + '})'"
           class="overflow-wrap-break">{{prefixCouponRule?.name}}</a>

    </span>

  </div>


  <div
    *ngIf="currentCouponKind?.hasGenereteBarcode"
    [form-item-row] = "'ШК купонного правила'"
     form-item-row-input="true"
  >

    <div class="input-group ">
      <span class="input-group-addon no-padder">
          <div class="checkbox inline m-b-none m-t-xs m-l-sm m-r-xs">
              <label class="i-checks"
              >
                  <input type="checkbox"
                         [(ngModel)]="barcodeFlag"
                         name="cRule_coupon_input_barcodeFlag"
                         data-testid="cRule.coupon.input.barcodeFlag"
                         (change)="checkBarcodeInput(barcodeFlag)"
                  ><i></i>
              </label>
          </div>
      </span>

      <input
        type="text"
        class="form-control form-control-edittable m-b-xs"
        [(ngModel)]="couponRuleItem.couponRule.barcode"
        (ngModelChange)="checkBarcode(couponRuleItem.couponRule.barcode)"
        data-testid="cRule.coupon.input.barcode"
        id="barcode_tmp"
        name="ruleItem_couponRule_barcode"
        data-testId="cRule.coupon.input.barcode"
        placeholder="{{ barcodeFlag ? 'Введите штрихкод' : 'Автогенерация штрихкода' }}"
        [required]="barcodeFlag"
        [disabled]="!barcodeFlag"
        [pattern]="patternBarcodeInput"
        (change)="checkBarcode(couponRuleItem.couponRule.barcode)"
        [customValidator]="barcodeValidator()"
        [customValidatorUpdater]="isBarcodeIsInvalid !== false && isBarcodeIsInvalid !== true"
        [maxlength]="13"
      />

    </div>

    <span class="clearfix f-s-9 text-danger"
          *ngIf="isBarcodeIsInvalid !== false && isBarcodeIsInvalid !== true ">
        Внимание! <span [innerText]="isBarcodeIsInvalid"></span>
    </span>

    <span class="clearfix f-s-9 text-danger"
          *ngIf="campaignForm.form.controls['ruleItem_couponRule_barcode']?.invalid && campaignForm.form.controls['ruleItem_couponRule_barcode'].value?.indexOf('22') === 0 && barcodeFlag">
        Внимание! ШК купонного правила заданный вручную не может начинаться с символов "22"
    </span>

    <span class="clearfix f-s-9 text-muted"
          *ngIf="isBarcodeGenerated(this.couponRuleItem.couponRule.barcode) && !barcodeFlag">
        ШК купонного правила автосгенерирован
    </span>


  </div>

  <div
    *ngIf="currentCouponKind?.hasBarcode"
    [form-item-row] = "'ШК купонного правила'"
    form-item-row-input="true"
  >
    <input
      type="text"
      class="form-control form-control-edittable m-b-xs"
      [(ngModel)]="couponRuleItem.couponRule.barcode"
      (ngModelChange)="checkBarcode(couponRuleItem.couponRule.barcode)"
      id="barcode_tmp"
      name="ruleItem_couponRule_barcode"
      data-testid="cRule.coupon.input.barcode"
      placeholder="Введите штрихкод"
      [required]="true"
      pattern="^(?!22)[0-9]+$"
      (change)="checkBarcode(couponRuleItem.couponRule.barcode)"
      [maxlength]="13"
    />

    <input
      type="hidden"
      *ngIf="!!isBarcodeIsInvalid"
      class="form-control form-control-edittable "
      name="ruleItem_couponRule_barcode_Dummy"
      [(ngModel)]="settingsObj.prefixDummy" [required]="true" />

    <span class="clearfix f-s-9 text-danger"
          *ngIf="isBarcodeIsInvalid !== false && isBarcodeIsInvalid!== true">
        Внимание! <span [innerText]="isBarcodeIsInvalid"></span>
    </span>

    <span class="clearfix f-s-9 text-danger"
          *ngIf="campaignForm.form.controls['ruleItem_couponRule_barcode']?.invalid && campaignForm.form.controls['ruleItem_couponRule_barcode'].value?.indexOf('22') === 0 ">
        Внимание! ШК купонного правила заданный вручную не может начинаться с символов "22"
    </span>

  </div>

  <ng-container
    *ngIf="!!currentIssueType?.getStartPeriod()?.length || !!currentIssueType?.getStopPeriod()?.length"
  >

    <div class="form-item-row form-item-row_clean_hr clean">
      <span class="text-muted" id="anchorI3">Время действия</span>
    </div>

    <ng-container
      *loyaPermitIf="{path:'global.clients', permitEnabled: ''}"
    >
      <ng-container
        *ngIf="currentIssueType?.getCustomPeriodTypes()?.length"
      >
        <div [form-item-row] = "'Тип периода действия'"
             form-item-row-input="true"
        >

          <label class="i-switch w50 bg-success m-r">
            <input type="checkbox"
                   [(ngModel)]="couponRuleItem.couponRule.issueSettings.customPeriod"
                   name="cRule_periodTime_input_type"
                   data-testid="cRule.periodTime.input.type"
                   (change)="onChangeCustomPeriod()"
            >
            <i></i>
            <span class="sign w-xxl">
              {{ CUSTOM_PERIOD_NAMES[!!couponRuleItem.couponRule.issueSettings.customPeriod] }}
            </span>
          </label>

        </div>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="!couponRuleItem?.couponRule?.issueSettings?.customPeriod">

      <div
        *ngIf="!!currentIssueType?.getStartPeriod()?.length"
        [form-item-row] = "'Начало действия купонов'"
        form-item-row-input="true"
      >
        <div class="input-group">

          <div class="input-group-btn dropdown" ngbDropdown>

            <button type="button"
                    class="btn btn-default "
                    ngbDropdownToggle
                    data-testid="cRule.periodTime.start()"
            >
              <span [innerText]=" START_PERIOD_NAMES[couponRuleItem?.couponRule?.issueSettings?.startPeriod]"></span>
              <i class="fa fa-caret-down m-l-xs "></i>
            </button>

            <ul class="dropdown-menu "
                ngbDropdownMenu
            >
              <li *ngFor="let id of currentIssueType?.getStartPeriod()"
                  ngbDropdownItem class="no-padder"
              >
                <a href="javascript:void(0)"
                   [innerText]="START_PERIOD_NAMES[id]"
                   data-testid="cRule.periodTime.start.item"
                   (click)="couponRuleItem.couponRule.issueSettings.startPeriod=id; onChangePeriod('startPeriod')"
                ></a>
              </li>
            </ul>

          </div>

          <input
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.startPeriod ===  StartPeriodTypes.START_PERIOD_FORM_DATE "
            class="form-control"
            [(ngModel)]="couponRuleItem.couponRule.issueSettings.startDate"
            data-testid="cRule.periodTime.input.start"
            type="date"
            name="ruleItem_couponRule_issueSettings_startDate"
            placeholder="Дата начала действия купонов"
            [required]="true"
            [max]="couponRuleItem.couponRule.issueSettings.stopDate"
          >

          <span class="input-group-addon"
                *ngIf="couponRuleItem?.couponRule?.issueSettings?.startPeriod === StartPeriodTypes.START_PERIOD_SCHEDULED ">через</span>

          <input  *ngIf="couponRuleItem?.couponRule?.issueSettings?.startPeriod === StartPeriodTypes.START_PERIOD_SCHEDULED "
                 class="form-control "
                 [(ngModel)]="couponRuleItem?.couponRule?.issueSettings?.startScheduled.value"
                 data-testid="cRule.periodTime.input.startScheduled"
                 type="number"
                 name="ruleItem_couponRule_issueSettings_startScheduled_value"
                 placeholder="Через какое время начинает действовать"
                 [required]="true"
                 [maxlength]="7"

                 [min]="1"
                 [max]="999"
                 pattern="^[0-9]+$"
          />

          <div *ngIf="couponRuleItem?.couponRule?.issueSettings?.startPeriod === StartPeriodTypes.START_PERIOD_SCHEDULED "
               class="input-group-btn dropdown"
               ngbDropdown>

            <button
              type="button"
              class="btn btn-default "
              data-testid="cRule.periodTime.startScheduledTime()"
              ngbDropdownToggle>

              <span [innerText]="timeWords(couponRuleItem?.couponRule?.issueSettings?.startScheduled?.dimension,couponRuleItem?.couponRule?.issueSettings?.startScheduled?.value)"></span>
              <i class="fa fa-caret-down m-l-xs "></i>

            </button>

            <ul class="dropdown-menu pull-right" ngbDropdownMenu>
              <li *ngFor="let item of ISSUE_SETTINGS_START_SCHEDULED_NAMES | keyvalue"
                  ngbDropdownItem class="no-padder"
              >
                <a href="javascript:void(0)"
                   [innerText]="timeWords(item.key,couponRuleItem?.couponRule?.issueSettings?.startScheduled?.value)"
                   (click)="couponRuleItem.couponRule.issueSettings.startScheduled.dimension=item.key"
                   data-testid="cRule.periodTime.startScheduledTime.item"></a>
              </li>
            </ul>

          </div>

        </div>

      </div>

      <div
        *ngIf="!!currentIssueType?.getStopPeriod()?.length"
        [form-item-row] = "'Окончание действия купонов'"
        form-item-row-input="true"
      >
        <div class="input-group">

          <div class="input-group-btn dropdown"
               ngbDropdown
          >

            <button
              type="button"
              class="btn btn-default "
              data-testid="cRule.periodTime.stop()"
              ngbDropdownToggle>
                <span [innerText]="STOP_PERIOD_NAMES[couponRuleItem?.couponRule?.issueSettings?.stopPeriod]"></span>
                <i class="fa fa-caret-down m-l-xs "></i>
            </button>

            <ul class="dropdown-menu " ngbDropdownMenu>
              <li *ngFor="let id of currentIssueType?.getStopPeriod()"
                  ngbDropdownItem class="no-padder"
              >
                <a  href="javascript:void(0)"
                   [innerText]="STOP_PERIOD_NAMES[id]"
                   (click)="couponRuleItem.couponRule.issueSettings.stopPeriod=id; onChangePeriod('stopPeriod')"
                   data-testid="cRule.periodTime.stop.item"></a>
              </li>
            </ul>

          </div>


          <input
            *ngIf="couponRuleItem?.couponRule?.issueSettings?.stopPeriod === StopPeriodTypes.STOP_PERIOD_SCHEDULED"
            class="form-control  m-b-xs"
            [(ngModel)]="couponRuleItem.couponRule.issueSettings.stopScheduled.value"
            data-testid="cRule.periodTime.input.stopScheduled"
            type="number"
            name="ruleItem_couponRule_issueSettings_stopScheduled_value"
            placeholder="Количество дней"
            [required]="true"
            [maxlength]="5"

            [min]="1"
            [max]="999"

            pattern="^[0-9]+$"
          />

          <div *ngIf="couponRuleItem?.couponRule?.issueSettings?.stopPeriod === StopPeriodTypes.STOP_PERIOD_SCHEDULED"
               class="input-group-btn dropdown"
               ngbDropdown
          >

            <button
              type="button"
              class="btn btn-default "
              data-testid="cRule.periodTime.stopScheduledTime()"
              ngbDropdownToggle>

              <span [innerText]="timeWords(couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.dimension, couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.value)"></span>
              <i class="fa fa-caret-down m-l-xs "></i>

            </button>

            <ul class="dropdown-menu pull-right" ngbDropdownMenu>
              <li *ngFor="let item of ISSUE_SETTINGS_STOP_SCHEDULED_NAMES | keyvalue"
                  ngbDropdownItem class="no-padder"
              >
                <a  href="javascript:void(0)"
                   [innerText]="timeWords(item.key,couponRuleItem?.couponRule?.issueSettings?.stopScheduled?.value)"
                   (click)="couponRuleItem.couponRule.issueSettings.stopScheduled.dimension = item.key"></a>
              </li>
            </ul>

          </div>

          <input *ngIf="couponRuleItem.couponRule.issueSettings.stopPeriod=== StopPeriodTypes.STOP_PERIOD_FORM_DATE "
                 type="date"

                 class="form-control m-b-xs"
                 [(ngModel)]="couponRuleItem.couponRule.issueSettings.stopDate"
                 data-testid="cRule.periodTime.input.stop"
                 [min]="maxDate(couponRuleItem.couponRule?.issueSettings?.startDate, today)"
                 name="ruleItem_couponRule_issueSettings_stopDate"
                 [disabled]="!!couponRuleItem.couponRule?.issueSettings?.stopScheduled?.value"
                 placeholder="Дата окончания"
                 [required]="true"/>
        </div>
      </div>

    </ng-container>

    <ng-container
      *ngIf="!!couponRuleItem?.couponRule?.issueSettings?.customPeriod"
    >

      <div
        [form-item-row] = "'Период действия'"
        form-item-row-input="true"
      >

        <ng-select [(ngModel)]="couponRuleItem.couponRule.issueSettings.customPeriodType"
                   [items]="currentIssueType?.getCustomPeriodTypes() | keyvalue"
                   bindValue="value"
                   data-testid="cRule.periodTime.select.period"
                   name="couponRuleItem_periodTime_issueSettings_customPeriodType"
                   [class.ng-invalid]="!couponRuleItem?.couponRule?.issueSettings?.customPeriodType"
                   [searchable]="false"
                   [required]="true"
                   [clearable]="false"
                   class="w-100  col-auto m-r-sm no-padder"

        >

          <ng-template ng-label-tmp let-item="item">
            {{ CUSTOM_PERIOD_TYPE_NAMES[item.value] }}
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{CUSTOM_PERIOD_TYPE_NAMES[item.value]}}
          </ng-template>

        </ng-select>


      </div>

      <ng-container
        *ngIf="couponRuleItem?.couponRule?.issueSettings?.customPeriodType === 'clientBirthday'">

        <div
        [form-item-row] = "'Дней до дня рождения'"
        form-item-row-input="true">

          <div class="row">
            <div class="col-sm-6 m-b-xs">
              <div class="input-group">
                <input
                  class="form-control"
                  [(ngModel)]="couponRuleItem.couponRule.issueSettings.customValue1"
                  data-testid="cRule.customPeriodType.input.before"
                  type="number"
                  name="ruleItem_couponRule_issueSettings_customValue1"
                  placeholder="до"
                  [required]="true"
                  [min]="0"
                  [max]="999"
                />
                <span class="input-group-addon">дней</span>
              </div>
            </div>

            <div class="col-sm-6 m-b-xs">
              <div class="input-group">
                <input
                  class="form-control"
                  [(ngModel)]="couponRuleItem.couponRule.issueSettings.customValue2"
                  type="number"
                  name="ruleItem_couponRule_issueSettings_customValue2"
                  data-testid="cRule.customPeriodType.input.after"
                  placeholder="после"
                  [required]="true"
                  [min]="0"
                  [max]="999"
                />
                <span class="input-group-addon">дней</span>
              </div>
            </div>
          </div>


        </div>

      </ng-container>

    </ng-container>

    <div
      [form-item-row] = "'Дата окончания выдачи купонов'"
      form-item-row-input="true"
    >
      <input class="form-control m-b-xs w-full"
             [(ngModel)]="couponRuleItem.couponRule.issueSettings.issueUntil"
             data-testid="cRule.periodTime.input.stopIssueDate"
             type="date"
             name="ruleItem_couponRule_issueSettings_issueUntil"
             placeholder="Дата окончания выдачи купонов"
             [required]="true"
             [max]="couponRuleItem.couponRule.issueSettings.stopDate || virtualStopDate"
             [min]="yesterday"
      />
    </div>
  </ng-container>

  <ng-container
      *ngIf="!(!currentCouponKind?.hasSameClientCount && !currentCouponKind?.hasCanBeSold && !currentCouponKind?.hasGeneratedCount && !currentCouponKind?.hasUsable3rdParties && !currentCouponKind?.isUniqueCouponGen)"
    >
      <div class="form-item-row form-item-row_clean_hr clean">
        <span class="text-muted" id="anchorI4">Выдача</span>
      </div>

      <div
        [form-item-row] = "'Выдавать'"
        form-item-row-input="true"
        *ngIf=" currentCouponKind?.hasSameClientCount"
      >
        <div class="input-group">
          <span class="input-group-addon">не больше</span>
          <input
            type="number"
            class="form-control form-control-edittable m-b-xs"
            [(ngModel)]="couponRuleItem.couponRule.issueSettings.sameClientCount"
            data-testid="cRule.issue.input.sameClientCount"
            name="ruleItem_couponRule_issueSettings_sameClientCount"

            [disabled]="sameClientCountDisabled"
            [min]="1"

            placeholder="Сколько раз одному клиенту может выдаваться купон по данному правилу"
            [required]="true"
            [maxlength]="5"
          >
          <span class="input-group-addon">раз</span>
        </div>
      </div>

      <div
        [form-item-row] = "'Продажа'"
        form-item-row-input="true"
        *ngIf=" currentCouponKind?.hasCanBeSold"
      >
        <div class="input-group">
          <span class="input-group-addon no-padder">

            <div class="checkbox inline m-b-none m-t-xs m-l-sm m-r-xs">
              <label class="i-checks">
                <input type="checkbox"
                       [(ngModel)]="couponRuleItem.couponRule.issueSettings.canBeSold"
                       name="couponRuleItem_couponRule_issueSettings_canBeSold"
                       data-testid="cRule.issue.input.canBeSold"><i></i>
              </label>
            </div>

          </span>

          <input
            *ngIf="couponPriceType === 'currency'"
            class="form-control form-control-edittable m-b-xs"
            [(ngModel)]="couponRuleItem.couponRule.issueSettings.priceInCurrency"
            data-testid="cRule.issue.input.priceInCurrency"
            type="text"
            name="ruleItem_couponRule_issueSettings_priceInCurrency"
            [disabled]="!couponRuleItem?.couponRule?.issueSettings?.canBeSold"
            pattern="^[0-9]+(.[0-9]{1,2})?$"
            placeholder="Стоимость купона"
            [required]="false"
            [maxlength]="12"
          >

          <input
            *ngIf="couponPriceType === 'bonus'"
            class="form-control form-control-edittable m-b-xs"
            [(ngModel)]="couponRuleItem.couponRule.issueSettings.priceInBonus"
            data-testid="cRule.issue.input.priceInCurrency"
            type="number"
            name="ruleItem_couponRule_issueSettings_priceInBonus"
            [disabled]="!couponRuleItem.couponRule.issueSettings.canBeSold"
            pattern="^[0-9]+(.[0-9]{1,2})?$"
            placeholder="Количество бонусов"
            [required]="false"
            [maxlength]="5"
          >

          <div class="input-group-btn dropdown" ngbDropdown>

            <button type="button" class="btn btn-default "
                    [disabled]="!couponRuleItem?.couponRule?.issueSettings?.canBeSold"
                    data-testid="cRule.issue.canBeSold.priceType()"
                    ngbDropdownToggle>
              <span [innerHTML]="ISSUE_SETTINGS_CAN_BE_SOLD_NAMES[couponPriceType]"></span>
              <i class="fa fa-caret-down m-l-xs "></i>
            </button>

            <ul class="dropdown-menu pull-right" ngbDropdownMenu>
              <li *ngFor="let item of ISSUE_SETTINGS_CAN_BE_SOLD_NAMES | keyvalue"
                  ngbDropdownItem class="no-padder"
              >
                <a  href="javascript:void(0)" [innerText]="item.value"
                   (click)="couponPriceType=item.key"
                   data-testid="cRule.issue.canBeSold.item"></a>
              </li>
            </ul>

          </div>

        </div>
      </div>

      <div
        [form-item-row] = "'Количество купонов'"
        form-item-row-input="true"
        *ngIf=" currentCouponKind?.hasGeneratedCount"
      >
        <input
          type="number"
          class="form-control form-control-edittable m-b-xs"
          [(ngModel)]="couponRuleItem.couponRule.issueSettings.generatedCount"
          data-testid="cRule.issue.input.generatedCount"
          name="ruleItem_couponRule_issueSettings_generatedCount"

          [min]="1"
          placeholder="Количество купонов"
          [required]="true"
          [maxlength]="7"
        >
      </div>

      <div
        [form-item-row] = "'Можно передавать 3-им лицам'"
        form-item-row-input="true"
        *ngIf=" currentCouponKind?.hasUsable3rdParties"
      >

        <div class="checkbox inline m-b-sm m-r-sm m-t-n-sm ">
          <label class="i-checks">
            <input type="checkbox"
                   [(ngModel)]="couponRuleItem.couponRule.usingSettings.usable3rdParties"
                   name="couponRuleItem_couponRule_usingSettings_usable3rdParties"
                   data-testid="cRule.issue.input.usable3rdParties"
                   [disabled]="usable3rdPartiesDisabled"
            ><i class="m-l-xs"></i>
            Можно передавать 3-им лицам
          </label>
        </div>

      </div>

      <div
        [form-item-row] = "' '"
        form-item-row-input="true"
        *ngIf=" currentCouponKind?.isUniqueCouponGen"
      >
        <h5 class="text-muted f-s-12 m-t-sm "
            data-testid="cRule.issue.isUniqueCouponGen.warning">
          Генерация купонов возможна после запуска купонного правила
        </h5>
      </div>
  </ng-container>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI5">Приём</span>
  </div>

  <div
    [form-item-row] = "'Партнер'"
    form-item-row-input="true"
  >
    <select-partner
      [(id)]="couponRuleItem.couponRule.partnerId"
      (idChange)="onChangePartner()"
      data-testid="cRule.apply.select.partner"
      [required]="true"
      [selectFirst]="true"
    ></select-partner>
  </div>

  <div
    [form-item-row] = "'Параметры приёма'"
    form-item-row-input="true"
  >
    <ng-select [ngModel]=" initAndReturn('couponRuleItem.couponRule.applySettings.couponApply', currentCouponKind?.getCouponApply() )"
               (ngModelChange)="couponRuleItem.couponRule.applySettings.couponApply = $event"
               [items]="currentCouponKind?.getCouponApply() | keyvalue"
               data-testid="cRule.apply.select.auto"
               name="couponRuleItem_periodTime_applySettings_couponApply"
               bindValue="value"
               [class.ng-invalid]="!couponRuleItem?.couponRule?.applySettings?.couponApply"
               [searchable]="false"
               [required]="true"
               [clearable]="false"
               class="w-100  col-auto m-r-sm no-padder"

    >

      <ng-template ng-label-tmp let-item="item">
        {{ COUPON_APPLY_NAMES[item.value] }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{COUPON_APPLY_NAMES[item.value]}}
      </ng-template>

    </ng-select>

  </div>

  <div
    [form-item-row] = "'Мин. вознаграждение'"
    form-item-row-input="true"
    *ngIf="couponRuleItem.couponRule.applySettings.couponApply==='applyInFirstSuitedCheck' && couponRuleItem.couponRule.reward.reward.what!=='gift' "
  >
    <div class="input-group">
      <input
        type="text"
        class="form-control form-control-edittable m-b-xs"
        [(ngModel)]="couponRuleItem.couponRule.applySettings.minRewardInCurrency"
        data-testid="cRule.apply.input.minRewardInCurrency"
        name="ruleItem_couponRule_applySettings_minRewardInCurrency"
        placeholder="Мин. вознаграждение"
        [required]="true"
        [maxlength]="15"
        pattern="^[0-9]+(.[0-9]+)?$"
      >
      <span class="input-group-addon" ngCurrencyNameLoya></span>
    </div>
  </div>

  <div
    [form-item-row] = "'Дни приёма купона'"
  >
    <div class="checkbox inline  m-r-sm"
         *ngFor="let item of VALID_DAYS; let index = index">
      <label class="i-checks">
        <input type="checkbox"
               [ngModel]="getDayById(item)"
               (ngModelChange)="setDayById(item, $event)"
               [name]="'valid_days' + index"
        ><i></i>
        {{ VALID_DAYS_NAMES[item] }}
      </label>
    </div>
  </div>

  <div
    [form-item-row] = "'Время приёма купона'"
    form-item-row-input="true"
  >

    <div class="row">
      <div class="col-sm-6 m-b-xs">
        <div class="input-group">
          <span class="input-group-addon">с</span>
          <input
            class="form-control"
            [(ngModel)]="couponRuleItem.couponRule.applySettings.validTimeFrom"
            data-testid="cRule.apply.input.validTimeFrom"
            type="time"
            name="ruleItem_couponRule_applySettings_validTimeFrom"
            placeholder="Время приёма купона"
            [required]="true"
          />
        </div>
      </div>

      <div class="col-sm-6 m-b-xs">
        <div class="input-group">
          <span class="input-group-addon">по</span>
          <input
            class="form-control"
            [(ngModel)]="couponRuleItem.couponRule.applySettings.validTimeTill"
            data-testid="cRule.apply.input.validTimeTill"
            type="time"
            name="ruleItem_couponRule.applySettings.validTimeTill"
            placeholder="Время приёма купона"
            [required]="true"/>
        </div>
      </div>
    </div>

  </div>

  <div
    [form-item-row] = "'Приём в точках продаж'"
    form-item-row-input="true"
  >
    <ng-container
      *ngIf="!couponRuleItem.couponRule.partnerId"
    >
      <span class="text-danger m-b-sm">Для выбора точки продаж, требуется выбрать партнёра</span>
    </ng-container>

    <ng-container
      *ngIf="!!couponRuleItem.couponRule.partnerId"
    >

      <div class="clearfix f-s-8 text-muted m-b-xs">Локация</div>

      <select-location-group
        data-testid="cRule.apply.select.locationGroup"
        [partnerId]="couponRuleItem.couponRule.partnerId"
        [(id)]="locationGroupId"
        [multiple]="false"
        [name]="'selectLocationGroup'"
      ></select-location-group>

      <div class="clearfix f-s-8 text-muted m-b-xs m-t-xs">Магазин</div>

      <div class="btn-group" ngbDropdown>

        <button data-testid="cRule.apply.shop.choose()"
                class="btn btn-default "
                (click)="selectLocations()"
                [ngClass]="{ 'b b-danger': !hasSelectedLocation }"
        >
          <span *ngIf="couponRuleItem?.locations?.length">Выбрано магазинов:
              <span [innerText]="couponRuleItem?.locations?.length" data-testid="cRule.shop.length"></span>
          </span>
          <span class="" *ngIf="!couponRuleItem?.locations?.length">Выбрать магазины</span>
        </button>

        <ul class="scroll-y"
            ngbDropdownMenu
            *ngIf="!!couponRuleItem?.locations?.length"
            style="max-height: 411px;"
        >

          <li *ngFor="let location of couponRuleItem?.locations"
              class="d-flex align-items-center m-b-xs m-t-xs no-padder"
              ngbDropdownItem
          >

            <div class="col p-l-sm">
              <span [innerText]="location.name" data-testid="cRule.shop.name"></span>
            </div>

            <div class="col-auto no-padder text-center">
              <button class="btn btn-danger btn-xs m-l-xs m-r-sm  "
                      (click)="removeLocation(location); $event.stopImmediatePropagation()"
                      data-testid="locationChosen.remove()">
                <i class="fa fa-times"></i>
              </button>
            </div>

          </li>

        </ul>

        <button *ngIf="!!couponRuleItem?.locations?.length"
                class="btn btn-default"
                ngbDropdownToggle
                aria-expanded="false"
                data-testid="locationChosen.show()">
          <span class="caret"></span>
        </button>

      </div>


    </ng-container>

  </div>

  <div
    [form-item-row] = "'Количество использований'"
    form-item-row-input="true"
    *ngIf="!!useCountEnabled"
  >
    <input
      type="number"
      class="form-control form-control-edittable m-b-xs"
      [(ngModel)]="couponRuleItem.couponRule.usingSettings.useCount"
      data-testid="cRule.apply.input.useCount"
      name="ruleItem_couponRule_usingSettings_useCount"
      placeholder="Количество использований"
      [required]="false"
      [disabled]="!useCountEnabled"
      [min]="1"
      [max]="999"
      pattern="^[0-9]+$"
    >
  </div>

  <div
    [form-item-row] = "'Ограничение приёма по сумме'"
    form-item-row-input="true"
    *ngIf="showApplyEvery"
  >

    <div class="input-group">

      <span class="input-group-addon">на каждые</span>

      <input
        class="form-control form-control-edittable m-b-xs "
        [(ngModel)]="couponRuleItem.couponRule.applySettings.applyEvery"
        data-testid="cRule.limit.input.applyEvery"
        type="text"
        name="ruleItem_couponRule_applySettings_applyEvery"
        placeholder=""
        [min]="0"
        [max]="10000000000"
        pattern="^((10000000000(\.0{1,2})?)|[0-9]{1,10}(\.[0-9]{1,2})?)$"
        [required]="false"
        (ngModelChange)="checkForDeleteApplyGoods()"
      >
      <span class="input-group-addon" ngCurrencyNameLoya></span>

    </div>


    <div class="clearfix"
         *ngIf="couponRuleItem.couponRule.applySettings.applyEvery > 0"
    >

      <div class="pull-left m-r-sm">
        <div class="text-muted f-s-8 clearfix  m-t-sm m-b-xs">
          Товары, участвующие в ограничении
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType || undefined  },
              filterByParams: filterGoodByParams,
              forPartner: couponRuleItem?.couponRule?.partnerId
            }, 'whiteGoodsApply')"

            data-testid="cRule.reward.applySettings.choose()"
            class="btn btn-default w-md"
            [disabled]="!couponRuleItem?.couponRule?.partnerId"
          >
                  <span [ngSwitch]="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType">
                  <span *ngSwitchCase="'sku'">
                    Выбрано товаров
                  </span>
                  <span *ngSwitchCase="'category'">
                    Выбрано категорий
                  </span>
                  <span *ngSwitchCase="'brand'">
                    Выбран бренд
                  </span>
                  <span *ngSwitchCase="'favourite'">
                    Выбраны любимые товары
                  </span>
                  <span *ngSwitchCase="'custom'">
                    Выбраны списки
                  </span>
                  <span *ngSwitchDefault >
                    <span>Выбраны все товары</span>
                  </span>
                </span>

            <span *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList?.length>0">
                  ( {{ couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList?.length }} )
                </span>

            <span *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList?.length>0">
                  ( {{ couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList?.length }} )
                </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList?.length>0"

            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteSKUList,
              forPartner: couponRuleItem?.couponRule?.partnerId,
              listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList?.length>0"

            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteIdList,
              forPartner: couponRuleItem?.couponRule?.partnerId,
              listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType
            })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.whiteListType  "
            (click)="
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'whiteListType' );
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'whiteIdList' );
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'whiteSKUList' );
            ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div class="pull-left">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Товары, исключенные из ограничения
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType || undefined  },
              filterByParams: filterGoodByParams,
              forPartner: couponRuleItem?.couponRule?.partnerId
            }, 'blackGoodsApply')"
            [disabled]="!couponRuleItem?.couponRule?.partnerId"
            data-testid="cRule.applySettings.blackGoods.choose()"
            class="btn btn-default w-md"
          >
                      <span [ngSwitch]="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType">
                          <span *ngSwitchCase="'sku'">
                          Исключено товаров
                        </span>
                        <span *ngSwitchCase="'category'">
                          Исключено категорий
                        </span>
                        <span *ngSwitchCase="'brand'">
                          Исключён бренд
                        </span>
                        <span *ngSwitchCase="'favourite'">
                          Исключены любимые товары
                        </span>
                        <span *ngSwitchCase="'custom'">
                          Исключены списки
                        </span>
                        <span *ngSwitchDefault>
                          Без исключений
                        </span>
                      </span>

            <span *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList?.length>0">
                        ( {{ couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList?.length }} )
                      </span>

            <span *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList?.length>0">
                        ( {{ couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList?.length }} )
                      </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackSKUList,
              forPartner: couponRuleItem?.couponRule?.partnerId,
              listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackIdList,
              forPartner: couponRuleItem?.couponRule?.partnerId,
              listType: couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList?.blackListType  "
            (click)="
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'blackListType' );
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'blackIdList' );
              deleteField(couponRuleItem?.couponRule?.applySettings?.rawSettings?.goodList, 'blackSKUList' );
            ">
            <i class=" fa fa-remove"></i>
          </button>

        </div>
      </div>

    </div>



  </div>

  <div
    [form-item-row] = "'Ограничение приёма в чеке'"
    form-item-row-input="true"
    *ngIf="showMaxRewardValues"
  >

    <div class="input-group">

      <span class="input-group-addon no-padder">

        <div class="checkbox inline m-b-none m-t-xs m-l-sm m-r-xs">
            <label class="i-checks"
                   data-testid="cRule.limit.input.maxReward.i-checks"
            >
                <input type="checkbox"
                       [(ngModel)]="maxRewardLimitEnabled"
                       name="maxRewardLimit_enabled"
                       data-testid="cRule.limit.input.maxReward"
                ><i></i>
            </label>
        </div>

      </span>

      <ng-container
        *ngIf="maxRewardLimitEnabled"
      >
        <div class="input-group-btn dropdown"
           ngbDropdown
      >
        <button
          type="button"
          class="btn btn-default w-full no-radius"
          [disabled]="!maxRewardLimitEnabled"
          data-testid="cRule.limit.maxReward.type()"
          ngbDropdownToggle>
          <span [innerText]="MAX_REWARD_LIMIT_SUBTYPES_NAMES[maxRewardLimitType]"></span>
          <i class="fa fa-caret-down m-l-xs "></i>
        </button>

        <ul class="dropdown-menu " ngbDropdownMenu>
          <li *ngFor="let item of maxRewardLimitFilter(MAX_REWARD_LIMIT_SUBTYPES_NAMES) | keyvalue"
              ngbDropdownItem class="no-padder"
          >
            <a  href="javascript:void(0)"  [innerText]="item.value"
               data-testid="cRule.limit.maxReward.item"
               (click)="maxRewardLimitType=item.key; onChangeMaxRewardLimit()"></a>
          </li>
        </ul>

      </div>
      </ng-container>

      <input
        class="form-control form-control-edittable m-b-xs "
        [(ngModel)]="couponRuleItem.couponRule.applySettings.maxRewardValue"
        data-testid="cRule.limit.maxReward.input.value"
        type="number"
        name="applySettings_maxRewardValue"
        placeholder="Максимальный размер"
        [min]="0"
        [required]="maxRewardLimitEnabled"
        [disabled]="!maxRewardLimitEnabled"
        [max]="getValidatesForMaxRewardLimit(couponRuleItem?.couponRule?.applySettings?.maxRewardLimit, 'max')"
        [pattern]="getValidatesForMaxRewardLimit(couponRuleItem?.couponRule?.applySettings?.maxRewardLimit, 'pattern')"
      >

      <ng-container
        *ngIf="maxRewardLimitEnabled"
      >
        <div
          class="input-group-btn dropdown"
          ngbDropdown>

          <button type="button" class="btn btn-default "
                  [disabled]="!maxRewardLimitEnabled"
                  data-testid="cRule.limit.maxReward.measure()"
                  ngbDropdownToggle>
            <span [innerText]="MAX_REWARD_LIMIT_MEASURE_VALUES[maxRewardLimitType + maxRewardLimitMeasure]"></span>
            <i class="fa fa-caret-down m-l-xs "></i>

          </button>

          <ul class="dropdown-menu pull-right" ngbDropdownMenu>
            <li *ngFor="let item of MAX_REWARD_LIMIT_MEASURE_VALUES_BY_TYPE[maxRewardLimitType] "
                ngbDropdownItem
                class="no-padder"
            >
              <a  href="javascript:void(0)"
                 [innerText]="MAX_REWARD_LIMIT_MEASURE_VALUES[maxRewardLimitType + item]"
                 (click)="maxRewardLimitMeasure=item;"
                 data-testid="cRule.reward.measure.item"></a>
            </li>
          </ul>

        </div>
      </ng-container>

    </div>
  </div>

  <div
    [form-item-row] = "'Мин. сумма чека'"
    form-item-row-input="true"
  >
    <div class="input-group">
      <input
        type="text"
        class="form-control form-control-edittable loya-input-money m-b-xs"
        [(ngModel)]="couponRuleItem.couponRule.applySettings.minCheckTotal"
        data-testid="cRule.limit.input.minCheckTotal"
        name="ruleItem_couponRule_applySettings_minCheckTotal"
        placeholder="Минимальная сумма чека"
        [required]="true"
        [min]="0"
      >
      <span class="input-group-addon" ngCurrencyNameLoya></span>
    </div>

  </div>

  <div class="form-item-row form-item-row_clean_hr clean">
    <span class="text-muted" id="anchorI6">Вознаграждение</span>
  </div>

  <ng-container
    *ngIf="currentCouponKind?.hasReferalReward"
  >
    <div
      [form-item-row] = "'Вознаграждения рефера'"
      form-item-row-input="true"
    >
      <div class="input-group">

      <span class="input-group-addon"
            [innerText]="REWARD_WHAT_TYPES_NAMES['bonus']"></span>

        <input  class="form-control form-control-edittable m-b-xs"
                [(ngModel)]="couponRuleItem.couponRule.reward.referalProgramData.referReward.value"
                data-testid="cRule.referReward.input.value"
                type="text"
                name="ruleItem_referalProgramData_referReward_rewardval"
                placeholder="Величина вознаграждения"
                [required]="true"
                [pattern]="numberPercentPattern( couponRuleItem.couponRule.reward.referalProgramData.referReward.measure, 'bonus' )"
        >

        <div
          class="input-group-btn dropdown"
          ngbDropdown>

          <button type="button" class="btn btn-default " data-testid="cRule.referReward.measure()"
                  ngbDropdownToggle>
            <span [innerText]="REWARD_MEASURES_TYPES_VALUES[initAndReturn('couponRuleItem.couponRule.reward.referalProgramData.referReward.measure', REWARD_MEASURE_RELATION['bonus'] )]"></span>
            <i class="fa fa-caret-down m-l-xs "></i>

          </button>

          <ul class="dropdown-menu pull-right" ngbDropdownMenu>
            <li *ngFor="let item of REWARD_MEASURE_RELATION['bonus']"
                ngbDropdownItem class="no-padder"
            >
              <a  href="javascript:void(0)"
                 [innerText]="REWARD_MEASURES_TYPES_VALUES[item]"
                 (click)="couponRuleItem.couponRule.reward.referalProgramData.referReward.measure=item"
                 data-testid="cRule.referReward.measure.item"></a>
            </li>
          </ul>

        </div>

      </div>
    </div>

    <div
      [form-item-row] = "'Вознаграждение реферала'"
      form-item-row-input="true"
    >
      <label class="i-switch"
      >
        <input type="checkbox"
               [(ngModel)]="couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal"
               name="couponRuleItem_couponRule_reward_referalProgramData_needRewardReferal"
               data-testid="cRule.referralReward.input.need"
               class="ng-hide">
        <i></i>
        <span  class="sign w-xxl"
               [innerText]="couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal ? 'Вознаграждать' : 'Не вознаграждать'"
        >
				</span>
      </label>
    </div>
  </ng-container>

  <div class="form-item-row form-item-row_clean_hr clean"
       *ngIf="currentCouponKind?.hasReferalReward && couponRuleItem.couponRule.reward.referalProgramData.needRewardReferal"
  >
    <span class="text-muted" id="anchorI7">Вознаграждение реферала</span>
  </div>

  <ng-container
    *ngIf="!(currentCouponKind?.hasReferalReward && !couponRuleItem?.couponRule?.reward?.referalProgramData?.needRewardReferal)"
  >
    <div
      [form-item-row] = "'Вознаграждение'"
      form-item-row-input="true"
      *ngIf="currentCouponKind?.hasRewardWhat"
    >

      <div class="input-group">

        <div class="input-group-btn dropdown"
             ngbDropdown
             [autoClose]="true"
        >

          <button type="button"
                  class="btn btn-default "
                  data-testid="cRule.reward.what()"
                  ngbDropdownToggle
                  id="couponRuleRewardWhatId"
          >
            <span [innerText]="REWARD_WHAT_TYPES_NAMES[
              initAndReturn('couponRuleItem.couponRule.reward.reward.what', rewardWhatFilter( currentCouponKind?.getRewardWhat() ) )
            ]"></span>
            <i class="fa fa-caret-down m-l-xs "></i>
          </button>

          <ul class="dropdown-menu "
              ngbDropdownMenu
          >
            <ng-container
              *ngFor="let item of rewardWhatFilter( currentCouponKind?.getRewardWhat() )"
            >
              <li
                ngbDropdownItem class="no-padder"
                data-testid="cRule.reward.what.item"
                *ngIf="!(['importedCoupon','sticker'].indexOf(couponRuleItem.couponRule.issueSettings.couponKind) >=0 && id === 'gift') "
              >
                <a
                   [innerText]="REWARD_WHAT_TYPES_NAMES[item]"
                   (click)="onChangeRewardRewardWhat(item)"></a>
              </li>
            </ng-container>
          </ul>

        </div>

        <input *ngIf="couponRuleItem.couponRule.reward.reward.what!=='gift'"
               class="form-control form-control-edittable m-b-xs"
               [(ngModel)]="couponRuleItem.couponRule.reward.reward.value"
               data-testid="cRule.reward.what.input.value"
               type="text"
               name="ruleItem_rewardval"
               [disabled]="couponRuleItem.couponRule.reward.reward.fromMechanic || couponRuleItem.couponRule.issueSettings.couponKind === 'importedCoupon'"
               placeholder="Величина вознаграждения"
               [required]="couponRuleItem.couponRule.issueSettings.couponKind !== 'importedCoupon'"
               [pattern]="numberPercentPattern( couponRuleItem.couponRule.reward.reward.measure, couponRuleItem.couponRule.reward.reward.what )"
        >

        <div *ngIf="couponRuleItem.couponRule.reward.reward.what!=='gift'"
             class="input-group-btn dropdown"
             ngbDropdown>

          <button type="button" class="btn btn-default "
                  data-testid="cRule.reward.measure()"
                  ngbDropdownToggle
          >
            <span [innerText]="REWARD_MEASURES_TYPES_VALUES[initAndReturn('couponRuleItem.couponRule.reward.reward.measure', currentCouponKind?.getRewardMeasureList() )]"></span>
            <i class="fa fa-caret-down m-l-xs "></i>

          </button>

          <ul class="dropdown-menu pull-right" ngbDropdownMenu>
            <li *ngFor="let item of currentCouponKind?.getRewardMeasureList()"
                ngbDropdownItem class="no-padder"
            >
              <a  href="javascript:void(0)"
                 [innerText]="REWARD_MEASURES_TYPES_VALUES[item]"
                 (click)="couponRuleItem.couponRule.reward.reward.measure=item"
                 data-testid="cRule.referralReward.measure.item"></a>
            </li>
          </ul>

        </div>

      </div>

      <div class="clearfix text-danger m-t-xs f-s-9"
           *ngIf="couponRuleItem.couponRule.issueSettings.couponKind === 'importedCoupon'">
        Величина вознаграждения задаётся через файл/API для каждого купона
      </div>

      <div *ngIf="couponRuleItem.couponRule.reward.reward.what==='gift'" class="m-t-sm">

        <input type="hidden"
               [ngModel]="couponRuleItem?.couponRule?.reward?.giftList?.length"
               name="couponRuleItem_couponRule_reward_giftList_length"
               [required]="true"
               [min]="1"/>

        <div class="btn-group ">


          <button
                  (click)="selectGoods({
                      showCategories: [ 'sku' ],
                      initSelectedItems: { type: 'sku'  },
                      forPartner: couponRuleItem.couponRule.partnerId
                  }, 'giftList')"
                  class="btn btn-default w-md "
                  [ngClass]="{'b b-danger no-shadow': !( couponRuleItem?.couponRule?.reward?.giftList?.length > 0 ) }"
                  ng-disabled="ruleItem.couponRule.partnerId===undefined"

                  data-testid="cRule.reward.gift.choose()"
          >

            <span *ngIf="couponRuleItem?.couponRule?.reward?.giftList?.length>0">
              Выбрано подарков ( {{ couponRuleItem?.couponRule?.reward?.giftList?.length }} )
            </span>
            <span *ngIf="!couponRuleItem?.couponRule?.reward?.giftList?.length">
              Выбрать подарки
            </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.reward?.giftList?.length>0"
            (click)="viewGoodsPopup({
                       viewGoodsPopup:couponRuleItem?.couponRule.reward.giftList,
                       forPartner:couponRuleItem?.couponRule?.partnerId,
                       listType:'sku'
                    })"
            >
            <i class="fa fa-eye text-success"></i>
          </button>
          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.reward?.giftList?.length>0 "
            (click)="deleteField(couponRuleItem.couponRule.reward, 'giftList')">
            <i class=" fa fa-remove ">
            </i>
          </button>


        </div>
      </div>

    </div>

    <div
      [form-item-row] = "'Параметры вознаграждения'"
      form-item-row-input="true"
      *ngIf="REWARD_IGNORE_LIMIT_TYPES_NAMES[couponRuleItem.couponRule.reward.reward.what] !== undefined "
    >
      <div class="checkbox ">
        <label class="i-checks ">
          <input type="checkbox"
                 [(ngModel)]="couponRuleItem.couponRule.reward.ignoreLimits"
                 name="couponRuleItem_couponRule_reward_giftList_length"
                 data-testid="cRule.reward.input.ignoreLimits"

          >
          <i></i>
          Игнорировать {{ REWARD_IGNORE_LIMIT_TYPES_NAMES[couponRuleItem.couponRule.reward.reward.what]  }}
        </label>
      </div>
    </div>

    <div
      [form-item-row] = "'На что дается вознаграждение'"
      form-item-row-input="true"
      *ngIf=" currentCouponKind?.hasRewardRewType "
    >

      <div class="row">

        <div class="col-auto ">
          <ng-select [ngModel]="initAndReturn('couponRuleItem.couponRule.reward.reward.rewType', rewTypeValues )  "
                     (ngModelChange)="couponRuleItem.couponRule.reward.reward.rewType = $event; recalcReward()"
                     [items]="rewTypeValues | keyvalue"
                     bindValue="value"
                     data-testid="cRule.reward.select.rewType"
                     name="couponRuleItem_periodTime_issueSettings_customPeriodType"
                     [class.ng-invalid]="!couponRuleItem?.couponRule.reward.reward.rewType"
                     [searchable]="false"
                     [required]="true"
                     [clearable]="false"
                     [disabled]="isRewardRewardRewTypeDisabled"
                     class="w"

          >

            <ng-template ng-label-tmp let-item="item">
              {{ REWARD_REWTYPE_TYPES_NAMES[item.value] }}
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{REWARD_REWTYPE_TYPES_NAMES[item.value]}}
            </ng-template>

          </ng-select>
        </div>

        <div class="col-auto  "
             *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType == REWARD_REWTYPE_TYPES.GOODS ">

          <div class="w w-full-sm m-b-sm w-full-md">
            <ng-select [ngModel]="initAndReturn('couponRuleItem.couponRule.reward.onGood.rewType', currentCouponKind?.getOnGoodRewType() ) "
                       (ngModelChange)="couponRuleItem.couponRule.reward.onGood.rewType = $event;  recalcReward()"
                       [items]="currentCouponKind?.getOnGoodRewType()"
                       data-testid="cRule.reward.onGood.select.rewType"
                       name="couponRuleItem_reward_onGood_select_rewType"
                       [class.ng-invalid]="!couponRuleItem?.couponRule.reward.reward.rewType"
                       [searchable]="false"
                       [required]="true"
                       [clearable]="false"
                       [disabled]="isRewardOnGoodRewTypeDisabled"
                       class="w-100"

            >

              <ng-template ng-label-tmp let-item="item">
                {{ REWARD_ONGOOD_REWTYPE_TYPES_NAMES[item] }}
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{REWARD_ONGOOD_REWTYPE_TYPES_NAMES[item]}}
              </ng-template>

            </ng-select>
          </div>
        </div>

        <div class="col-auto   ">
          <div class="input-group w-sm no-padder pull-left m-b-sm w-full-sm w-full-md  "
               *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType ==REWARD_REWTYPE_TYPES.GOODS &&
                    (
                      couponRuleItem?.couponRule?.reward?.onGood?.rewType == REWARD_ONGOOD_REWTYPE_TYPES.EVERY ||
                      couponRuleItem?.couponRule?.reward?.onGood?.rewType == REWARD_ONGOOD_REWTYPE_TYPES.COUNT
                    ) ">

            <input type="number" class="form-control inline "
                   [(ngModel)]="couponRuleItem.couponRule.reward.onGood.quantity"
                   name="couponRuleItem_couponRule_reward_onGood_quantity"
                   [min]="0.001"
                   [max]="999.999"
                   [(ngGoodDimension)]="couponRuleItem.couponRule.reward.onGood.dimension"
                   [required]="true"
                   data-testid="good.value"
            >
            <span class="input-group-addon ng-scope"
                  data-testid="good.measure"
                  [ngGoodDimensionName]="couponRuleItem.couponRule.reward.onGood.dimension"
            >шт.</span>
          </div>
        </div>

        <div class="col-auto "
             *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType === REWARD_REWTYPE_TYPES.GOODS &&
               couponRuleItem?.couponRule?.reward?.onGood?.rewType === REWARD_ONGOOD_REWTYPE_TYPES.COUNT "
        >
          <ng-select [ngModel]="initAndReturn('couponRuleItem.couponRule.reward.onGood.itemsSorting', REWARD_ON_GOOD_ITEMS_SORTING_TYPES )"
                     (ngModelChange)="couponRuleItem.couponRule.reward.onGood.itemsSorting = $event"
                     [items]="REWARD_ON_GOOD_ITEMS_SORTING_TYPES | keyvalue"
                     bindValue="value"
                     data-testid="cRule.reward.onGood.select.sorting"
                     name="couponRuleItem_reward_onGood_select_sorting"
                     [class.ng-invalid]="!couponRuleItem?.couponRule.reward.reward.rewType"

                     [searchable]="false"
                     [required]="true"
                     [clearable]="false"
                     class="w-100  col-auto m-r-sm no-padder"

          >

            <ng-template ng-label-tmp let-item="item">
              {{ REWARD_ON_GOOD_ITEMS_SORTING_NAMES[item.value] }}
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{REWARD_ON_GOOD_ITEMS_SORTING_NAMES[item.value]}}
            </ng-template>

          </ng-select>
        </div>

        <div class="col-auto ">
          <div  class=" w-xl bg-light padder wrapper-xs b r p-t-sm  text-center m-b-sm w-full-sm col-auto"
            *ngIf=" couponRuleItem.couponRule.reward.reward.rewType=== REWARD_REWTYPE_TYPES.SET_OF_GOODS">

            на все в чеке
          </div>
        </div>

      </div>
    </div>

    <div
      [form-item-row] = "' '"
      form-item-row-input="true"
      *ngIf="currentCouponKind?.getId() === 'couponWithBarcodeWithReceptParams'"
      class="text-danger"
    >
      Вознаграждения задаются через импорт купонов
    </div>

    <div
      [form-item-row] = "' '"
      form-item-row-input="true"
      *ngIf="currentCouponKind?.hasRewardRewType"
    >
      <div *ngIf=" couponRuleItem?.couponRule?.reward?.reward?.rewType === REWARD_REWTYPE_TYPES.GOODS ||
                   couponRuleItem?.couponRule?.reward?.reward?.rewType === REWARD_REWTYPE_TYPES.POSITION "
      >
        <div class="text-muted f-s-8 clearfix  m-b-xs">
          Товары, участвующие в вознаграждении
        </div>
        <div class="btn-group ">

          <button

            (click)="selectGoods({
              initSelectedItems: { type: couponRuleItem?.couponRule?.reward?.goodList?.whiteListType || undefined  },
              filterByParams: filterGoodByParams,
              forPartner: couponRuleItem?.couponRule?.partnerId
            }, 'whiteGoods')"

            data-testid="cRule.reward.whiteGoods.choose()"
            class="btn btn-default w-md"
            [disabled]="!couponRuleItem?.couponRule?.partnerId"
          >
              <ng-container [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.whiteListType">
                <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault>
                  <span>Выбраны все товары</span>
                </span>
            </ng-container>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0">
										( {{ couponRuleItem.couponRule.reward.goodList.whiteIdList.length }} )
									</span>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0">
										( {{ couponRuleItem.couponRule.reward.goodList.whiteSKUList.length }} )
									</span>

          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.whiteSKUList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.whiteListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.whiteIdList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.whiteListType
            })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteListType  "
            (click)="
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteListType' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteIdList' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteSKUList' );
            ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div *ngIf=" couponRuleItem?.couponRule?.reward?.reward?.rewType === REWARD_REWTYPE_TYPES.GOODS ||
                   couponRuleItem?.couponRule?.reward?.reward?.rewType === REWARD_REWTYPE_TYPES.POSITION">
        <div class="text-muted f-s-8 clearfix m-t-sm m-b-xs">
          Товары, исключенные из вознаграждения
        </div>
        <div class="btn-group ">

          <button
            (click)="selectGoods({
              initSelectedItems: { type: couponRuleItem?.couponRule?.reward?.goodList?.blackListType || undefined  },
              filterByParams: filterGoodByParams,
              forPartner: couponRuleItem.couponRule.partnerId
            }, 'blackGoods')"

            data-testid="cRule.reward.blackGoods.choose()"
            class="btn btn-default w-md"
            [disabled]="!couponRuleItem?.couponRule?.partnerId"
          >
            <ng-container
              [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.blackListType"
            >
              <span *ngSwitchCase="'sku'">
                Исключено товаров
              </span>
              <span *ngSwitchCase="'category'">
                Исключено категорий
              </span>
              <span *ngSwitchCase="'brand'">
                Исключён бренд
              </span>
              <span *ngSwitchCase="'favourite'">
                Исключены любимые товары
              </span>
              <span *ngSwitchCase="'custom'">
                Исключены списки
              </span>
              <span *ngSwitchDefault>
                Без исключений
              </span>
            </ng-container>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.blackIdList?.length>0">
              ( {{ couponRuleItem.couponRule.reward.goodList.blackIdList.length }} )
            </span>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0">
              ( {{ couponRuleItem.couponRule.reward.goodList.blackSKUList.length }} )
            </span>

          </button>
          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.blackSKUList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.blackSKUList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.blackListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.blackIdList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.blackIdList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.blackListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.blackListType  "
            (click)="
              deleteField(couponRuleItem.couponRule.reward.goodList, 'blackListType' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'blackIdList' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'blackSKUList' );
            ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

      <div *ngIf="couponRuleItem?.couponRule?.reward?.reward?.rewType=== REWARD_REWTYPE_TYPES.SET_OF_GOODS">

        <input type="hidden"
               [ngModel]="couponRuleItem?.couponRule?.reward?.goodList?.whiteListType?.length"
               name="couponRuleItem_couponRule_reward_goodList_whiteListType_length"
               [required]="true"/>

        <div class="btn-group ">

          <button

            (click)="selectGoods({
              initSelectedItems: { type: couponRuleItem?.couponRule?.reward?.goodList?.whiteListType || undefined  },
              forPartner: couponRuleItem.couponRule.partnerId,
              showCategories: [ 'sku' , 'custom' ]
            }, 'whiteGoods')"

            data-testid="cRule.reward.kit.choose()"
            class="btn btn-default w-md"
            [disabled]="!couponRuleItem?.couponRule?.partnerId"
            [ngClass]="{ 'b-danger': !couponRuleItem?.couponRule?.reward?.goodList?.whiteListType?.length }"
          >
            <ng-container [ngSwitch]="couponRuleItem?.couponRule?.reward?.goodList?.whiteListType">
                 <span *ngSwitchCase="'sku'">
                  Выбрано товаров
                </span>
                <span *ngSwitchCase="'category'">
                  Выбрано категорий
                </span>
                <span *ngSwitchCase="'brand'">
                  Выбран бренд
                </span>
                <span *ngSwitchCase="'favourite'">
                  Выбраны любимые товары
                </span>
                <span *ngSwitchCase="'custom'">
                  Выбраны списки
                </span>
                <span *ngSwitchDefault>
                  <span>Выбраны все товары</span>
                </span>
            </ng-container>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0">
										( {{ couponRuleItem.couponRule.reward.goodList.whiteIdList.length }} )
									</span>

            <span *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0">
										( {{ couponRuleItem.couponRule.reward.goodList.whiteSKUList.length }} )
									</span>

          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteSKUList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.whiteSKUList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.whiteListType
            })"
          >
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            *ngIf="couponRuleItem?.couponRule?.reward?.goodList?.whiteIdList?.length>0"
            (click)="viewGoodsPopup({
              viewGoodsPopup: couponRuleItem.couponRule.reward.goodList.whiteIdList,
              forPartner: couponRuleItem.couponRule.partnerId,
              listType: couponRuleItem.couponRule.reward.goodList.whiteListType
            })">
            <i class="fa fa-eye text-success"></i>
          </button>

          <button
            type="button"
            class="btn btn-danger"
            *ngIf=" couponRuleItem?.couponRule?.reward?.goodList?.whiteListType  "
            (click)="
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteListType' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteIdList' );
              deleteField(couponRuleItem.couponRule.reward.goodList, 'whiteSKUList' );
            ">
            <i class=" fa fa-remove ">
            </i>
          </button>

        </div>
      </div>

    </div>


  </ng-container>

  <ng-container
    *ngIf="
       (couponRuleItem?.couponRule?.reward?.reward?.what ==='bonus' && !currentCouponKind?.hasReferalReward ) ||
       (
          couponRuleItem?.couponRule?.reward?.reward?.what ==='bonus' && currentCouponKind?.hasReferalReward &&
          couponRuleItem?.couponRule?.reward?.referalProgramData?.needRewardReferal
       )"
  >
    <div class="form-item-row form-item-row_clean_hr clean"  >
      <span class="text-muted" id="anchorI8">Настройка бонусов</span>
    </div>

    <div
      [form-item-row] = "' '"
      form-item-row-input="true"
    >
      <bonus-mechanic-config-ng1
        [model]="couponRuleItem.couponRule.reward"
        [fieldPath]="'bonusSettings'"
        [readOnly]="false"
        [configOnly]="true"
        [enabled]="
            (
              couponRuleItem.couponRule?.reward?.reward?.what ==='bonus' && !currentCouponKind?.hasReferalReward
            ) || (
              couponRuleItem.couponRule?.reward?.reward?.what ==='bonus' && currentCouponKind?.hasReferalReward &&
              couponRuleItem.couponRule?.reward?.referalProgramData?.needRewardReferal
            )"
      ></bonus-mechanic-config-ng1>

    </div>
  </ng-container>

  <ng-container
    *ngIf="isExternalLoyalty"
  >
    <div class="form-item-row form-item-row_clean_hr clean"  >
      <span class="text-muted" id="anchorI9">
        Внешняя система лояльности
      </span>
    </div>

    <div
      [form-item-row] = "'Запрет списания бонусов'"
      form-item-row-input="true"
    >

      <div class="checkbox m-t-none m-b-none">
        <label class="i-checks">
          <input type="checkbox"
               [(ngModel)]="couponRuleItem.elSettings.disableBonusUsed"
               name="couponRuleItem_elSettings_disableBonusUsed"
          >
          <i></i>
          Запретить списание бонусов на товары по этой акции во внешней системе
        </label>
      </div>

    </div>

    <div
      [form-item-row] = "'Запрет начисления бонусных баллов'"
      form-item-row-input="true"
    >

      <div class="checkbox m-t-none m-b-none">
        <label class="i-checks">
          <input type="checkbox"
                 [(ngModel)]="couponRuleItem.elSettings.disableBonusAward"
                 name="couponRuleItem_elSettings_disableBonusAward"
          >
          <i></i>
          Запретить начисление бонусных баллов на товары по этой акции во внешней системе
        </label>
      </div>

    </div>

  </ng-container>

  <div class="form-item-row form-item-row_clean_hr clean"  >
      <span class="text-muted" id="anchorI10">
        Прикреплённые изображения
      </span>
  </div>

  <div class="form-item-row padder-md"  >
    <image-carousel-uploader
      [couponRuleId]="couponRuleItem?.campaign?.id"
    >
    </image-carousel-uploader>
  </div>

</form-container>




import {CashBoxService} from "../service/cash-box.service";


export class CheckGiftClass {

  constructor(
    private cashBoxService: CashBoxService
  ) {
  }

  public async removeGift(campaignGift: any, gift: any) {

    let findedGift = this.cashBoxService?.currentCheck?.usedGifts?.find(g =>
      g.campaignId === campaignGift.campaignId &&
      g.listId === campaignGift.listId &&
      g.sku === gift.sku
    );

    if (!findedGift)
      return;

    findedGift.quantity -= 1;
    if ( findedGift.quantity <= 0 ) {
      this.cashBoxService.currentCheck.usedGifts = this.cashBoxService?.currentCheck.usedGifts.filter(g => g !== findedGift);
      if ( this.cashBoxService?.currentCheck?.usedGifts?.length === 0 ) {
        this.cashBoxService.currentCheck.usedGifts = undefined;
      }
    }

    await this.cashBoxService?.reCalculate()
  }

  public async addGift(campaignGift: any, gift: any) {

    let findedGift = this.cashBoxService?.currentCheck?.usedGifts?.find(g =>
      g.campaignId === campaignGift.campaignId &&
      g.listId === campaignGift.listId &&
      g.sku === gift.sku
    );

    if (this.giftCountForCampaign(campaignGift) >= campaignGift?.quantity )
      return;

    if (!findedGift) {
      findedGift = {
        "campaignId": campaignGift.campaignId,
        "listId": campaignGift.listId,
        "sku": gift.sku,
        "quantity": 1
      }

      this.cashBoxService.currentCheck.usedGifts = this.cashBoxService?.currentCheck?.usedGifts || [];
      this.cashBoxService?.currentCheck?.usedGifts?.push(findedGift);

    } else {
      findedGift.quantity += 1;
    }

    await this.cashBoxService?.reCalculate()
  }

  public giftCountForCampaign(campaignGift) {
    if (!this.cashBoxService.currentCheck?.usedGifts?.length)
      return 0;

    return this.cashBoxService.currentCheck?.usedGifts?.filter(g =>
      g.campaignId === campaignGift.campaignId &&
      g.listId === campaignGift.listId
    ).reduce((a, b) => a + b?.quantity || 0, 0);
  }

  public giftCount(campaignGift, gift) {
    if (!this.cashBoxService.currentCheck?.usedGifts?.length)
      return 0;

    let finded = this.cashBoxService.currentCheck?.usedGifts?.find(g =>
      g.campaignId === campaignGift.campaignId &&
      g.listId === campaignGift.listId &&
      g.sku === gift.sku
    )

    return finded ? finded.quantity: 0;
  }


}

<div class="padder">

  <div *ngIf="!!informationByPos?.length"
       class=" wrapper-sm padder">
    <div class="card m-t-sm m-b-sm"
         *ngFor="let info of informationByPos"
         data-testid="pos.info in check.informationByChannel"
    >

      <div class="d-flex">

        <div class="col-auto v-middle text-center wrapper-sm padde-xs w-xxs bg-light b-r">
          <i class="fa fa-info-circle fa-lg text-info" aria-hidden="true"></i>
        </div>

        <div class="col text-left padder f-s-11 wrapper-sm">
          <span [innerHTML]="textToHtml(info.info)"
                data-testid="cashbox.informationByPos.info"
          ></span>
        </div>

      </div>

    </div>

  </div>

  <ng-container
    *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
  >
    <div class="row padder-md m-t-xs"
         *ngIf="!!cashBoxService.currentCheck.awardBonuses">

      <div class="col-sm-12 m-t-xs m-b-xs f-s-13">
        <span class="">Начислено бонусов:</span>
        <span class=" text-primary-dk pull-right">
          <span [innerText]="cashBoxService.currentCheck.awardBonuses"></span>
        </span>
      </div>

      <div class="line line-dashed b-b line-lg pull-in  m-b-sm"></div>

    </div>
  </ng-container>

  <div class="wrapper-sm padder-md m-t-xs"
       *ngIf="!!cashBoxService?.currentCheck?.gifts?.length">

    <div *ngFor="let campaignGift of cashBoxService?.currentCheck?.gifts">

      <span class="text-muted">Подарков выбрано:</span> {{ cashBoxService?.gift?.giftCountForCampaign(campaignGift) || 0 }} из {{ campaignGift?.quantity }}


      <div *ngFor="let gift of campaignGift.skuList"
           data-testid="cashbox.gifts.row"
           class="m-l">
        <span class="pointer m-r-xs text-warning-lter"
              (click)="cashBoxService?.gift?.removeGift(campaignGift, gift)"
              data-testid="cashbox.gifts.remove"
        >
          <i class="fa fa-minus-circle "></i>
        </span>

        <span class="text-primary"
              [innerText]="cashBoxService?.gift?.giftCount(campaignGift, gift)"
        ></span>

        <span class="pointer m-l-xs  text-warning-lter"
              (click)="cashBoxService?.gift?.addGift(campaignGift, gift)"
              data-testid="cashbox.gifts.add"
        >
          <i class="fa fa-plus-circle "></i>
        </span>

        <i class="fa fa-gift text-success m-l-sm m-r-xxs"></i>

        <span [innerText]="getGiftName(gift)"></span>

      </div>
    </div>

  </div>

  <div class="row padder-md"
       *ngIf="getUsableCoupons?.length">
    <div class="col-sm-12">
      <span class="m-l-xs">Доступные купоны:</span>
    </div>
    <div class="col-sm-12">
      <div *ngFor="let campaignCoupon of getUsableCoupons" class="m-l-sm">

        <label class="checkbox-inline i-checks">

          <input class="pull-left" type="checkbox"
                 [checked]="isCouponChecked(campaignCoupon)"
                 (change)="changeCouponChecked(campaignCoupon)">
          <i class="v-top" style="margin-left: -25px; margin-right: 0;"></i>

          <div class="inline m-t-n-xxs m-l-xs v-top">
            <div class="clear">
              <div class="text-info-dker clean"
                   [innerText]="campaignCoupon?.name || ''"
              ></div>

              <div class="clean text-muted f-s-9">
                <span [innerText]="campaignCoupon?.from || ''"></span>
                -
                <span [innerText]="campaignCoupon?.till || ''"></span>
              </div>

            </div>

            <!--span class="clear" ng-bind="campaignCoupon.desc"></span-->
            <div class="clear text-muted">
              <span [innerText]="campaignCoupon?.details || ''"></span>
            </div>


            <!--span class="text-success m-l-sm m-r-xxs"> {{ campaignCoupon | json }}</span-->
          </div>


        </label>

      </div>
    </div>
  </div>

  <div class=" padder-md m-b-sm"
       *ngIf="hasActions"
  >
    <ng-container
      *ngFor="let action of cashBoxService?.currentCheck?.actions"
    >
      <div *ngIf="!isBlockedActionKind(action?.kind)"
           class="row"
      >
        <div class="col-sm-12">
          <div class=" m-b-xs m-t-xs" [innerText]="action?.description"></div>
        </div>
        <div class="col-sm-12"
             [ngSwitch]="action?.kind"
        >

          <div *ngSwitchCase="'input'"
               class=""
          >

            <div class="input-group clearfix  m-b-xs ">

              <input class="form-control"
                     [(ngModel)]="tmpInput['kind_input' + action?.id + action?.reason]"
                     data-testid="cash.action.inputedValue"
              />

              <span class="input-group-btn">
                <button class="btn btn-success "
                        type="button"
                        [disabled]="!tmpInput['kind_input' + action?.id + action?.reason]"
                        data-testid="cash.addBarcode()"
                        (click)="addInputBarcode(action,  tmpInput['kind_input' + action?.id + action?.reason])"> Отправить
                </button>
              </span>

            </div>

            <ng-container
              *ngIf="!!action?.values?.length"
            >
              <div *ngFor="let value of action?.values"
                   class="clearfix m-t-xs m-b-xs"
              >
                <div class=" clearfix ">
                  <i class="fa"
                     [ngClass]=" {  'fa-check-circle text-success': !!value?.success, 'fa-times-circle text-danger': !!value?.error  }"
                  ></i>

                  <span >{{value?.value || ''}}</span>
                  <span *ngIf="value.success" class="f-s-9 m-l-xs text-success">{{value.success.description}}</span>
                  <span *ngIf="value.error"
                        class="f-s-9 m-l-xs text-danger"
                        data-testid="cashbox.error.description"
                  >{{value.error.description}}</span>


                  <a href="javascript:void(0)" class="text-danger pull-right"
                     (click)="removeValueBarcode(action, value)"
                  >удалить</a>
                </div>
                <div class="line line-dashed b-b line-lg pull-in  m-t-xs m-b-xs "></div>

              </div>
            </ng-container>

          </div>

          <ng-container *ngSwitchCase="'select'">
            <ng-container *ngIf="!!action?.items?.length">
              <div *ngFor="let actionCoupon of action?.items "
                   data-testid="cashbox.actionCoupon.row"
                 class="m-l m-b-xs"
            >
              <label class="checkbox-inline w-full i-checks">

                <input class="pull-left"
                       type="checkbox"
                       [(ngModel)]="actionCoupon.selected"
                       (change)="cashBoxService.reCalculate()"
                       data-testid="cashbox.actionCoupon.selected"
                >

                <i class="v-top" style="margin-left: -25px; margin-right: 0px;"></i>

                <div class="inline m-t-n-xxs m-l-xs v-top">
                  <div class="clear">

                    <div class="hbox w-full">
                      <div class="col text-info-dker" data-testId="check.info.coupon"
                      >
                        <pre class="mini-scroll no-bg no-padder m-b-none f-s-10 font-bold no-border">{{ couponName(actionCoupon)}}
                        </pre>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <span class=" text-muted f-s-9"
                     *ngIf="actionCoupon?.details?.from || actionCoupon?.details?.till"> (
                        <span [innerText]="actionCoupon?.details?.from | loyaDate"></span>
                        -
                        <span [innerText]="actionCoupon?.details?.till | loyaDate"></span> )
                      </span>

                    <span class="clearfix text-muted f-s-9" *ngIf="actionCoupon?.error && actionCoupon?.error?.description">
                      <i class="fa fa-warning m-r-xxs text-danger"></i>
                      <span class="text-danger" [innerText]="actionCoupon?.error?.description"></span>
                    </span>

                  </div>

                  <div class="hbox w-full">
                    <div class="col text-muted" data-testId="check.info.coupon">
                      <pre class="mini-scroll no-bg no-padder m-b-none no-border">{{ actionCoupon?.details?.text || '' }}</pre>
                    </div>
                  </div>

                </div>

              </label>
            </div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'present'">
            <ng-container *ngIf="!!action?.items?.length">
              <div *ngFor="let actionCoupon of action?.items"
                   class="m-l m-b-xs"
                   data-testid="cashbox.actionCoupon.row"
              >

                <label class="checkbox-inline w-full i-checks">

                  <input class="pull-left"
                         type="checkbox"
                         [(ngModel)]="actionCoupon.selected"
                         (change)="cashBoxService?.reCalculate()"
                         data-testid="cashbox.actionCoupon.selected"
                  >

                  <i class="v-top" style="margin-left: -25px; margin-right: 0;"></i>

                  <div class="inline m-t-n-xxs m-l-xs v-top">
                    <div class="clear">
                      <div class="hbox w-full">
                        <div class="col text-info-dker"
                             data-testId="check.info.coupon"
                        >
                          <pre class="mini-scroll no-bg no-padder m-b-none f-s-10 font-bold no-border">{{ couponName(actionCoupon)}}</pre>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <span class=" text-muted f-s-9"
                            *ngIf="actionCoupon?.details?.from || actionCoupon?.details?.till"> (
                          <span [innerText]="actionCoupon?.details?.from | loyaDate"></span>
                          -
                          <span [innerText]="actionCoupon?.details?.till | loyaDate"></span>)
                        </span>

                      <span class="clearfix text-muted f-s-9"
                            *ngIf="actionCoupon?.error && actionCoupon?.error?.description">
                            <i class="fa fa-warning m-r-xxs text-danger"></i>
                            <span class="text-danger" *ngIf="actionCoupon?.error?.description || ''">
                        </span>
                       </span>

                    </div>

                    <div class="clear text-muted f-s-9">
                      <span [innerText]="actionCoupon?.details?.barcode"></span>
                    </div>

                  </div>
                </label>

              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </ng-container>

  </div>

  <div class="row  padder-md m-t-xs">

    <div class="col-sm-12 f-s-13">
      <span class="">Сумма чека:</span>
      <span class=" text-primary-dk pull-right">
        <span [innerText]="cashBoxService?.currentCheck?.total | loyaCurrency"></span>
        <i class="fa le m-l-xs" ngCurrencyLoya ></i>
      </span>
    </div>

    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-sm"></div>

    <div class="col-sm-12 f-s-13">
      <span class=" pos-rlt " style="top: -5px;">Скидка:</span>
      <span class=" text-primary-dk pull-right">
        <span [innerText]=" cashBoxService?.discount?.totalDiscount() | loyaCurrency"
              data-testid="cashbox.discount.totalDiscount"
        ></span>
        <i class="fa le m-l-xs" ngCurrencyLoya ></i>
      </span>
    </div>

    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-sm"></div>

    <div class="col-sm-12 f-s-13">

      <span class=" pos-rlt " style="top: -5px;">Скидка на мелочь:</span>
      <span class=" text-primary-dk pull-right">
        <span [innerText]="cashBoxService?.discount?.totalDiscountOnChange() | loyaCurrency"></span>
          <i class="fa le m-l-xs" ngCurrencyLoya ></i>
      </span>
    </div>

    <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-sm"></div>

  </div>

  <ng-container
    *loyaPermitIf="{path:'campaign.mechanics.bonus', permitEnabled: ''}"
  >
    <div class="row padder-md"
       *ngIf="canUseBonuses"
      >
        <div class="col-sm-12 f-s-13">
          <span class=" pos-rlt " style="top: -5px;">Доступно к оплате бонусами:</span>
          <span class=" text-primary-dk pull-right">
            <span
              class="m-r-xs"
              [innerText]="cashBoxService.bonus.allowBonuses | loyaNumber"
              data-testid="cashbox.bonus.allowBonuses"
            ></span>
              <b>бонусов</b><span> = </span>
            <span
              class="m-r-xs"
              [innerText]="cashBoxService.bonus.allowBonusesInCurrency | loyaCurrency"
              data-testid="cashbox.bonus.allowBonusesInCurrency"
            ></span>
              <i class="fa le" ngCurrencyLoya ></i>
          </span>
        </div>

        <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-sm"></div>
    </div>


    <div class="row padder-md"
         *ngIf="canUseBonuses">
      <div class="col-sm-12 f-s-13">

        <label class="i-switch  m-r">
          <input type="checkbox"
                 (change)="changeUseBonuses(!!$event?.currentTarget?.checked)"
                 data-testid="cashbox.useAllBonus"
          >
          <i></i>
        </label>

        <span class=" pos-rlt " style="top: -5px;">Оплата бонусами:</span>
        <span class=" text-primary-dk pull-right">
          <span class="m-r-xs"
                [innerText]="(cashBoxService?.bonus?.usedBonuses || 0) | loyaNumber"></span>
            <b>бонусов</b>
            <span> = </span>
          <span class="m-r-xs"
                [innerText]="(cashBoxService?.bonus?.usedBonusesInCurrency || 0) | loyaCurrency"></span>
            <i class="fa le" ngCurrencyLoya ></i>
        </span>

      </div>

      <div class="line line-dashed b-b line-lg pull-in m-t-xs m-b-sm"></div>

    </div>

  </ng-container>

  <div *ngIf="!!informationByCheck?.length" class="row padder">

    <div *ngFor="let info of informationByCheck"
         data-testid="check.info in check.informationByChannel"
         class="hbox">
      <div class="text-info-dk  m-l-xl m-b col ">
        <pre class="no-bg no-border no-padder mini-scroll "
             [innerText]="info?.info || ''"
             data-testid="cahsbox.informationByCheck.info"
        ></pre>
      </div>
    </div>

  </div>

  <div class="row padder-md m-b-sm">
    <div class="col-sm-12 f-s-17">
      <span class=" ">К оплате:</span>
      <span class=" text-primary-dk pull-right">
        <span class="m-r-sm"
              [innerText]=" cashBoxService.totalForPay | loyaCurrency"
              data-testid="cash.total"></span>
          <i class="fa le" ngCurrencyLoya ></i>
      </span>
    </div>
  </div>

</div>

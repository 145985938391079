'use strict';

import module from 'frontendModule';
import {RoleType} from "module/permissions/conf/roles";
import {RECEIPT_ROLES} from "component/nav/menu";

let routePath   = module.name + '.coupons.rules.index';
export default module
      .config(
        ['routeBuilderProvider', (routeBuilderProvider : any) => {

          routeBuilderProvider
            .route( routePath )

            .template( '<coupons-rules-index-page></coupons-rules-index-page>', true)
            .breadcrumbs( [ "Купоны", "Купонные правила" ] )

            .permissions( {
              only: ['Administrator', 'MarketingManager', RoleType.GiftMarketingManager],
            })

        }]);
